import { useState } from 'react';

import { method } from '~/screens/_shared/useApi/apiConstants';
import { usePostApi, useDeleteApi, useGetApi, usePutApi, usePatchApi, useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import {
	formatIdentifiers,
	isOfflineCredential,
	numberToFormatString,
} from '~/components/features/people/People.utils';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import { systemLimits } from '~/screens/_shared/systemLimits';
import { blockListState } from '~/components/features/people/constants/blockListState';
import { useBlockPerson as useBlockListPerson } from '~/components/features/people/hooks/useBlockPerson';
import mappers from '~/screens/_shared/mappers';
import { waitTimeout } from '~/shared';
import { showErrorToast } from '~/screens/_shared/toast';
import {
	isSupportedRole,
	isSystemGuard,
	isSystemInstaller,
	isSystemOperator,
	isSystemOwner,
} from '~/screens/_shared/userRoleConstants';
import moment from 'moment';
import {
	canCurrentAdminEditAdministrators,
	createRoleIfNeeded,
	useCreateAdministrator,
} from '~/components/features/people/hooks/useCreatePerson';
import useGetAccessGroups from '~/screens/AccessGroups/hooks/useGetAccessGroups';
import { CredentialRecognitionType } from '~/constants/Credential';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const defaultValuesToUpdate = ['firstName', 'lastName', 'phone'];

export const useUpdateAdministrator = () => {
	let {
		data: { system, site },
		mutate: mutateSystemSite,
	} = useCurrentSystemSite();
	const [updateOcaSystemRole] = usePatchApi('/customer/:customerId/system/:systemId');
	const [getAdministratorRoles] = useListApi('/administration/administrator/:administratorId/role');
	const [updateAdministrator] = usePatchApi('/administration/administrator/:email');
	const [addAdministratorToRole] = usePutApi('/administration/role/:roleId/administrator/:administratorId');
	const [removeAdministratorFromRole] = useDeleteApi('/administration/role/:roleId/administrator/:administratorId');
	const [updateSystem] = usePatchApi(
		'/administration/business-entity/:businessEntityId/customer/:endCustomerId/system/:systemId/',
		() => {},
		{
			removeDomainKey: true,
		}
	);

	const [updateAdminRole] = usePutApi('/administration/role/:roleId/administrator/:administratorId/', () => {}, {
		removeDomainKey: true,
	});

	const [createOrUpdateEmeaRole] = usePutApi('/administration/system/:systemId/role/:roleId', {
		removeDomainKey: true,
	});

	const replaceCurrentSystemOwner = async (administrator) => {
		let canDelete = true;
		let newAlternative = null;

		let adminIsPrimaryOwner = system.primarySystemOwner?.administratorId === administrator.administratorId;

		if (!adminIsPrimaryOwner && system.systemOwner?.id === administrator.administratorId) {
			adminIsPrimaryOwner = true;
		}

		if (adminIsPrimaryOwner) {
			newAlternative = system.administrators.find(
				(admin) => admin.administratorId !== administrator.administratorId && admin.roles.some(isSystemOwner)
			);

			if (newAlternative) {
				try {
					await updateSystem({
						__arrayBody: [
							{
								op: 'replace',
								path: '/systemOwner',
								value: {
									id: newAlternative.administratorId,
								},
							},
						],
						businessEntityId: system.businessEntityId,
						endCustomerId: system.endCustomerId,
						systemId: system.systemId,
						__contentType: 'application/json-patch+json',
						ignoreGlobalHandlers: true,
					});
				} catch {
					try {
						await updateAdminRole({
							roleId: newAlternative.roles.find(isSystemOwner)?.roleId,
							administratorId: newAlternative.administratorId,
							sendNotificationCopy: false,
							__contentType: 'application/vnd.assaabloy.msfss.administration-12.0+json',
						});
						canDelete = true;
					} catch {
						canDelete = false;
					}
				}
			} else {
				canDelete = false;
			}
		}

		return [canDelete, newAlternative];
	};

	return [
		async (formData) => {
			if (!system.businessEntityId) {
				return;
			}

			const canUpdate = await canCurrentAdminEditAdministrators(getAdministratorRoles);

			if (!canUpdate) {
				return;
			}

			const administrator = system.administrators.find((admin) => admin.administratorId === formData.administratorId);

			if (!administrator) {
				return;
			}
			// update
			if (formData.isAdministrator) {
				const values = defaultValuesToUpdate
					.map((key) => ({ key, value: formData[key] }))
					.filter((field) => field.value !== administrator[field.key])
					.map((field) => ({ op: 'replace', path: '/' + field.key, value: field.value }))
					.filter((op) => !!op.value);

				let shouldUpdateSystem = false;

				if (values.length > 0) {
					await updateAdministrator({
						email: administrator.username || administrator.email,
						__arrayBody: values,
						ignoreGlobalHandlers: true,
					});

					administrator.firstName = formData.firstName || '';
					administrator.lastName = formData.lastName || '';
					administrator.phone = formData.phone || '';

					shouldUpdateSystem = true;
				}

				let selectedRole = system.roles.find((role) => role.roleId === formData.selectedRoleId);

				const results = await createRoleIfNeeded({ selectedRole, formData, system, createOrUpdateEmeaRole });

				selectedRole = results.selectedRole;
				formData = results.formData;
				system = results.system;

				const rolesToDelete = administrator.roles.filter(
					(role) => isSupportedRole(role) && !isSystemOwner(role) && role.roleId !== selectedRole.roleId
				);

				if (administrator.roles.some(isSystemOwner) && !isSystemOwner(selectedRole)) {
					let [canDelete] = await replaceCurrentSystemOwner(administrator);

					if (canDelete) {
						rolesToDelete.push(administrator.roles.find(isSystemOwner));
					}
				}

				await Promise.allSettled(
					rolesToDelete.map((role) =>
						removeAdministratorFromRole({
							roleId: role.roleId,
							administratorId: administrator.administratorId,
							ignoreGlobalHandlers: true,
						})
					)
				);

				if (rolesToDelete.length > 0) {
					administrator.roles = administrator.roles.filter(
						(role) => !rolesToDelete.some((other) => other.roleId === role.roleId)
					);
					shouldUpdateSystem = true;
				}

				if (!administrator.roles.some((other) => other.roleId === selectedRole.roleId)) {
					await addAdministratorToRole({
						roleId: selectedRole.roleId,
						administratorId: administrator.administratorId,
						ignoreGlobalHandlers: true,
						__contentType: 'application/vnd.assaabloy.msfss.administration-12.0+json',
					});

					shouldUpdateSystem = true;
					administrator.roles = administrator.roles.concat([selectedRole]);
				}

				if (shouldUpdateSystem) {
					mutateSystemSite({ system: { ...system }, site });
				}
			}
			// delete
			else {
				const rolesToDelete = administrator.roles.filter((role) => isSupportedRole(role) && !isSystemOwner(role));

				let [canDelete, altAdmin] = await replaceCurrentSystemOwner(administrator);

				if (canDelete) {
					rolesToDelete.push(administrator.roles.find(isSystemOwner));
				}

				await Promise.allSettled(
					rolesToDelete
						.filter((role) => !!role)
						.map((role) =>
							removeAdministratorFromRole({
								roleId: role.roleId,
								administratorId: administrator.administratorId,
								ignoreGlobalHandlers: true,
							})
						)
				);

				let pendingItems = [];
				let paths = [];
				// Due to a bug in the backend, the API will fail with a 404 but still remove the role from the system.
				// Thus we ignore it.
				if (administrator.roles.some(isSystemOwner) && canDelete && altAdmin) {
					pendingItems.push(
						updateOcaSystemRole({
							__arrayBody: [
								{
									op: 'replace',
									path: '/systemOwner',
									value: {
										username: altAdmin.email,
									},
								},
							],
							customerId: system.customerId,
							systemId: system.systemId,
							ignoreGlobalHandlers: true,
							__contentType: 'application/json-patch+json',
						})
					);
					paths.push('/administrator');
				}

				if (administrator.roles.some(isSystemInstaller)) {
					paths.push('/systemInstallers');
				}

				if (administrator.roles.some(isSystemOperator)) {
					paths.push('/systemOperators');
				}

				if (administrator.roles.some(isSystemGuard)) {
					paths.push('/systemGuards');
				}

				if (!paths.includes('/administrator')) {
					paths.push('/administrator');
				}

				pendingItems = pendingItems.concat(
					paths.map((rolePath) =>
						updateOcaSystemRole({
							__arrayBody: [
								{
									op: 'remove',
									path: rolePath,
									value: {
										username: formData.email,
									},
								},
							],
							customerId: system.customerId,
							systemId: system.systemId,
							ignoreGlobalHandlers: true,
							__contentType: 'application/json-patch+json',
						})
					)
				);

				Promise.allSettled(pendingItems).catch(() => {});

				system.administrators = (system.administrators || []).filter(
					(admin) => admin.administratorId !== administrator.administratorId
				);
				system.installers = (system.installers || []).filter((installer) => installer.email !== administrator.username);

				mutateSystemSite({ system: { ...system }, site });
			}
		},
	];
};

export const useUpdatePerson = () => {
	const { translate } = useLocale();
	const {
		data: { system, site },
	} = useCurrentSystemSite();

	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [updateUser] = usePutApi(mappers.user);
	const [getUser] = useGetApi(mappers.user);

	const [updateCredential] = usePutApi(mappers.credential);
	const [deleteCredential] = useDeleteApi(mappers.credential);
	const [updateCredentialHolder] = usePutApi(mappers.credentialHolder);
	const [createCredential] = usePostApi(mappers.credential);
	const [getCredential] = useGetApi(mappers.credential);
	const [blockPerson] = useBlockListPerson();
	const [createAdministrator] = useCreateAdministrator();
	const [updateAdministrator] = useUpdateAdministrator();
	const [getAccessGroups] = useGetAccessGroups();
	const [getCredentialHolder] = useGetApi(mappers.credentialHolder);

	const cacheKey = getCacheKey(method.list, mappers.credential, {
		params: {
			'page-size': systemLimits.BlockList,
			page: 1,
			'detail-level': 'FULL',
			'credential-states': blockListState.BLOCKED,
			credential_states: blockListState.BLOCKED,
		},
	});

	const deleteCredentials = async (credentialsToDelete) => {
		setCurrentStep(translate.byKey('deleting_credentials'));

		const blockListCacheKey = getCacheKey(method.list, mappers.credential, {
			params: {
				'page-size': systemLimits.BlockList,
				page: 1,
				'detail-level': 'FULL',
				'credential-states': blockListState.BLOCKED,
				credential_states: blockListState.BLOCKED,
				'in-offline-blocklist': true,
			},
		});

		for (const credentialId of [...new Set(credentialsToDelete)]) {
			await deleteCredential({ credentialId }, { removeExistingKey: blockListCacheKey });
		}
	};

	const updateCredentials = async (formData) => {
		const { credentialHolderId } = formData;
		const credentials = formData.credentials || [];

		const errorResponses = [];

		await Promise.allSettled(
			credentials.map(async (credential) => {
				let fullCredential;
				let currentMainIdentifierFormat;

				try {
					fullCredential = await getCredential({ credentialId: credential.credentialId, ignoreGlobalHandlers: true });
					currentMainIdentifierFormat = fullCredential?.credentialIdentifiers?.find(
						(credentialIdentifier) =>
							credentialIdentifier.recognitionType === CredentialRecognitionType.CREDENTIAL_NUMBER &&
							credentialIdentifier.identifierFormat !== 'SIMPLE_NUMBER56'
					)?.identifierFormat;
				} catch (error) {}

				const identifiers = formatIdentifiers(credential, formData.pin, currentMainIdentifierFormat);

				if (
					credential.credentialId &&
					formData.credentialsToDelete.findIndex(
						(credentialToDelete) => credentialToDelete === credential.credentialId
					) < 0
				) {
					try {
						setCurrentStep(translate.byKey('updating_credentials'));

						await updateCredential(
							{
								credentialTypeId: credential.credentialTypeId,
								name: updatePersonCredentialName(formData.firstName, formData.lastName, credential.credentialTypeId),
								firstName: formData.firstName,
								lastName: formData.lastName,
								startDateTime: formData.startDateTime,
								endDateTime: formData.endDateTime,
								credentialHolderId,
								credentialId: credential.credentialId,
								version: credential.version,
								description: credential.description,
								identifiers,
							},
							{ removeExistingKey: cacheKey }
						);

						if (identifiers.find((indent) => !isOfflineCredential(indent.identifierFormat))) {
							setCurrentStep(translate.byKey('updating_credential_state_v2'));

							if (
								(!credential.isBlocked && credential.isNewCredentialBlocked) ||
								(credential.isBlocked && !credential.isNewCredentialBlocked)
							) {
								const fullCredential = await getCredential({ credentialId: credential.credentialId });
								await blockPerson(fullCredential, !credential.isNewCredentialBlocked);
							}
						}
					} catch (response) {
						errorResponses.push(response);
					}
				} else if (identifiers.length > 0) {
					try {
						const { credentialId } = await createCredential({
							...credential,
							firstName: formData.firstName,
							lastName: formData.lastName,
							startDateTime: formData.startDateTime,
							endDateTime: formData.endDateTime,
							credentialHolderId,
							identifiers,
						});

						const identifier = identifiers.find((indent) => !isOfflineCredential(indent.identifierFormat));
						if (identifier && identifier.exemptedFromAuthentication) {
							setCurrentStep(translate.byKey('blocking_credential_v2'));
							const fullCredential = await getCredential({ credentialId: credentialId });
							await blockPerson(fullCredential, false);
						}
					} catch (response) {
						errorResponses.push(response);
					}
				}
			})
		);

		const conflictResponses = errorResponses.filter((response) => response.status === 409);

		if (errorResponses.length === 1) {
			if (conflictResponses.length === errorResponses.length) {
				showErrorToast(
					translate.byKey(
						'an_existing_credential_exists_somewhere_else_in_the_system_the_credential_specified_is_being_ignored'
					)
				);
			} else {
				showErrorToast(
					translate.byKey(
						'an_error_occurred_when_saving_the_credential_please_check_your_network_connection_or_if_there_is_another_credential_in_the_system_with_the_same_value'
					)
				);
			}
			throw errorResponses;
		} else if (conflictResponses.length > 0) {
			showErrorToast(translate.byKey('several_credentials_that_were_saved_already_exist'));
			throw errorResponses;
		} else if (errorResponses.length > 0) {
			showErrorToast(translate.byKey('several_credentials_could_not_be_created'));
			throw errorResponses;
		}
	};

	const getCredentialsToDelete = (formData) => {
		const credentialsRemovedFromCredentialsList = formData.credentialsToDelete || [];
		const credentialsWithoutFormatIdentifiers = (formData.credentials || []).filter((credential) => {
			if (credential.credentialId && formatIdentifiers(credential, formData.pin).length === 0) {
				return credential.credentialId;
			}
			return false;
		});

		return [...credentialsRemovedFromCredentialsList, ...credentialsWithoutFormatIdentifiers];
	};

	const updatePerson = async (formData) => {
		setIsLoading(true);

		try {
			setCurrentStep(translate.byKey('updating_person_details'));
			const {
				firstName,
				lastName,
				phone,
				email = '',
				userVersion,
				userId,
				credentialHolderVersion,
				credentialHolderId,
				startDateTime,
				endDateTime,
			} = formData;

			const updatedUser = {
				firstName,
				lastName,
				version: userVersion,
				userId,
				phone: typeof phone === 'string' && phone.trim().length ? phone : undefined,
				email: email.length ? email : undefined,
			};

			const existingUser = await getUser(
				{ userId },
				{
					expiry: moment().add(5, 'minutes'),
					shared: true,
				}
			);

			// Administrators from the system don't have their phone numbers.
			// It makes sense to set it here prior to triggering the updateAdministrator hook.
			const administrator = system.administrators.find((admin) => admin.administratorId === formData.administratorId);

			if (administrator) {
				administrator.phone = administrator.phone || existingUser.phone;
			}

			await updateUser(updatedUser, {
				removeExistingKey: getCacheKey(method.get, mappers.user, {
					userId,
				}),
			});

			const credentialsToDelete = getCredentialsToDelete(formData);

			if (credentialsToDelete.length > 0) {
				await deleteCredentials(credentialsToDelete);
			}

			// await updateCredentials(formData);

			const credentialHolder = await getCredentialHolder({ credentialHolderId });

			const offlineAccessProfileIds = Array.isArray(formData.offlineAccessProfileId)
				? [...formData.offlineAccessProfileId]
				: [formData.offlineAccessProfileId];

			const groups = [formData.onlineAccessProfileId, ...offlineAccessProfileIds]
				.flat()
				.filter((id) => !!id)
				.map((id) => ({ accessProfileId: id, systemId: system.systemId }));

			setCurrentStep(translate.byKey('updating_credential_holder'));
			if (formData.pin) {
				formData.pin = numberToFormatString(formData.pin, 'hex');
			}

			const siteAccessGroups = await getAccessGroups('ALL', site);
			const accessProfiles = (credentialHolder.accessProfiles || []).filter(
				(profile) => !siteAccessGroups.some((group) => group.accessProfileId === profile.accessProfileId)
			);

			await Promise.all([
				Promise.resolve(null).then(() => {
					if (formData.administratorId) {
						setCurrentStep(translate.byKey('updating_administrator'));
						return updateAdministrator(formData);
					} else if (formData.isAdministrator) {
						setCurrentStep(translate.byKey('creating_admin'));
						return createAdministrator(formData);
					}
					return null;
				}),
				Promise.resolve(null).then(async () => {
					await waitTimeout(() => {}, 1000);

					const descriptionData = { pinCode: formData.pin || '', email: formData.email || '' };

					if (JSON.stringify(descriptionData).length > 50) {
						delete descriptionData.email;
					}

					await updateCredentialHolder({
						firstName,
						lastName,
						startDateTime,
						endDateTime,
						userId,
						accessProfiles: accessProfiles.concat(groups),
						credentialHolderId,
						description: JSON.stringify(descriptionData),
						version: credentialHolderVersion,
					});
				}),
			]);

			await updateCredentials(formData);

			return {
				firstName,
				lastName,
				name: `${firstName} ${lastName}`,
				label: `${firstName} ${lastName}`,
				credentialHolderId,
			};
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	};

	const updatePersonCredentialName = (firstName, lastName, credentialTypeId) => {
		return `${firstName} ${lastName} ${credentialTypeId}`;
	};

	return [updatePerson, isLoading, currentStep];
};
