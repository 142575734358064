import React, { useEffect, useState } from 'react';
import { Checkbox, Divider } from 'antd';
import isEqual from 'lodash/isEqual';

import { ResetApplyFilterPanel } from '~/components/shared/filters/panels/ResetApplyFilterPanel/ResetApplyFilterPanel';
import { BodyFilterPanel } from '~/components/shared/filters/panels/BodyFilterPanel/BodyFilterPanel';
import { FilterItem } from '~/components/shared/filters/FilterItem/FilterItem';
import { FilterItemCategory } from '../FilterItemCategory/FilterItemCategory';

const areSelectedOptionsEqual = (optionsOne, optionsTwo) => {
	const optionsOneSortedCopy = [...optionsOne].sort();
	const optionsTwoSortedCopy = [...optionsTwo].sort();

	return isEqual(optionsOneSortedCopy, optionsTwoSortedCopy);
};

export const CategorizedMultipleChoiceFilter = ({ antdFilterProps, options }) => {
	const {
		setSelectedKeys: setAntdSelectedKeys,
		selectedKeys: antdSelectedKeys,
		confirm,
		visible,
		clearFilters,
	} = antdFilterProps;
	const [checkedValues, setCheckedValues] = useState(antdSelectedKeys || []);
	const resetDisabled = !Boolean(antdSelectedKeys.length);
	const applyDisabled =
		areSelectedOptionsEqual(checkedValues, antdSelectedKeys) ||
		(!Boolean(checkedValues.length) && !Boolean(antdSelectedKeys.length));

	useEffect(() => {
		if (visible && antdSelectedKeys.length) {
			setCheckedValues(antdSelectedKeys);
		} else if (!visible) {
			setCheckedValues([]);
		}
	}, [visible, antdSelectedKeys, setCheckedValues]);

	const onItemCheck = (newValues) => {
		setCheckedValues(newValues);
	};

	const onReset = () => {
		clearFilters({ confirm: true, closeDropdown: true });
	};

	const onApply = () => {
		setAntdSelectedKeys(checkedValues);
		confirm();
	};

	const onEnterPress = (event) => {
		if (event.key === 'Enter' && !applyDisabled) {
			onApply();
		}
	};

	return (
		<div style={{ minWidth: '200px' }} onKeyDown={onEnterPress}>
			<BodyFilterPanel>
				<Checkbox.Group onChange={onItemCheck} value={checkedValues} style={{ width: '100%' }}>
					{options.map(({ category, items }) => (
						<React.Fragment key={category}>
							<FilterItemCategory>{category}</FilterItemCategory>

							{items.map(({ label, value, count }) => (
								<FilterItem key={value}>
									<Checkbox value={value}>{count !== undefined ? `${label} (${count})` : label}</Checkbox>
								</FilterItem>
							))}
						</React.Fragment>
					))}
				</Checkbox.Group>
			</BodyFilterPanel>

			<Divider style={{ margin: '0px' }} />

			<ResetApplyFilterPanel
				resetDisabled={resetDisabled}
				applyDisabled={applyDisabled}
				onReset={onReset}
				onApply={onApply}
			/>
		</div>
	);
};
