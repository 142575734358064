import { useLocale } from '~/screens/_shared/AppLocale';
import { Col, Input, Row } from 'antd';
import { Item } from '~/components';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getReadersFromController } from '~/screens/Controllers/shared';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { isAperioDoor, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import * as moment from 'moment';
import { getReadableName } from '~/screens/_shared/getReadableName';
import systemConfig from '~/screens/_shared/systemConfig';
import { translator as translate } from '~/screens/_shared/AppLocale';
import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import { validateMinNumberOfCharacters } from '~/utils/validation';
import { getReaderDefaults } from '~/utils/doors/Doors.utils';

export function BasicInfoInputs({ selected, doors, zones, form, loadingAssets, isOfflineDoor, disabled }) {
	const { translate } = useLocale();
	const { Option } = Select;
	const [doorGroups, setDoorGroups] = useState([]);
	const [getAssets] = useListApi(mappers.asset);

	useEffect(() => {
		if (isOfflineDoor) {
			getAssets({
				params: { type: 'BASIC_ASSET_GROUP', 'detail-level': 'FULL', page: 1, 'page-size': 50 },
			}).then((doorGroups) => setDoorGroups(doorGroups?.assets));
		}
	}, [isOfflineDoor]);

	return (
		<Row gutter={[12, 12]}>
			<Col lg={24}>
				<Item
					name="name"
					label={translate.byKey('door_name')}
					shouldUpdate
					dependencies={['shouldDelete']}
					rules={[
						{
							required: !form.getFieldValue('shouldDelete'),
							whitespace: true,
							message: translate.byKey('name_is_required'),
						},
						{
							pattern: validateMinNumberOfCharacters(3),
							message: translate.byKeyFormatted('field_value_cannot_be_shorter_than_message', {
								fieldName: translate.byKey('door_name'),
								minLength: 3,
							}),
						},
						{
							max: 100,
							message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
								fieldName: translate.byKey('door_name'),
								maxLength: 100,
							}),
						},
						{
							validator(rule, value) {
								const temp = (value || '').toLowerCase().trim();
								const otherDoor = doors.find((d) => (d.name || '').trim().toLowerCase().localeCompare(temp) === 0);

								if (otherDoor && selected && otherDoor.portalId === selected.portalId) {
									return Promise.resolve();
								}

								if (otherDoor) {
									return Promise.reject(
										translate.byKey(
											'there_is_another_door_with_the_same_name_in_the_site_please_change_this_door_name_to_something_more_unique'
										)
									);
								}

								return Promise.resolve();
							},
						},
					]}
				>
					<Input
						name="door_name"
						placeholder={translate.byKey('door_name')}
						autoFocus={true}
						disabled={disabled}
						maxLength={100}
						showCount
					/>
				</Item>
				<Item name="version" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
				<Item name="portalId" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
				<Item name="description" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
				<Item name="authenticationProfile" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
				<Item name="originalReaders" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
				<Item name="shouldDelete" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
			</Col>

			<Col lg={24}>
				<Item name="zoneId" label={translate.byKey('door_zone_header_v2')} shouldUpdate>
					<Select
						loading={loadingAssets}
						name="zoneId"
						placeholder={translate.byKey('select_a_zone_v2')}
						allowClear
						onChange={(value) => {
							form.setFieldsValue({
								zoneName: value ? zones.find((zone) => zone.assetId === value).name : '',
							});
						}}
						disabled={disabled}
					>
						{zones.map((zone) => {
							return (
								<Option key={zone.assetId + zone.name} value={zone.assetId}>
									{zone.name}
								</Option>
							);
						})}
					</Select>
				</Item>
				<Item name="zoneName" shouldUpdate noStyle>
					<Input type="hidden" />
				</Item>
			</Col>

			{isOfflineDoor && (
				<Col lg={24}>
					<Item name="doorGroups" label={translate.byKey('door_groups_v2')} shouldUpdate>
						<Select
							placeholder={translate.byKey('select_door_groups_v2')}
							showArrow
							allowClear
							mode="multiple"
							style={{ width: '100%' }}
							optionLabelProp="label"
							loading={loadingAssets}
							filterOption={(input, option) => {
								return option.label.toLowerCase().includes(input.toLowerCase());
							}}
							filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
							disabled={disabled}
						>
							{doorGroups?.map((asset, index) => {
								return (
									<Option key={index} value={asset.assetId} label={asset.name}>
										<div>
											<span style={{ float: 'left' }} aria-label={asset.name}>
												{asset.name}
											</span>
											<span
												style={{
													float: 'right',
													marginRight: '0.5em',
												}}
											>
												{asset.description}
											</span>
										</div>
									</Option>
								);
							})}
						</Select>
					</Item>
				</Col>
			)}

			<Item name="doorAssetId" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Row>
	);
}

export function formatSelectedReaders(allReaders, portal) {
	return allReaders
		.filter((reader) => reader.mapping?.portal?.portalId === portal.portalId)
		.sort((readerA, readerB) => {
			let portalSideA = 1;
			let portalSideB = 1;

			readerA.logicalDevices = readerA.logicalDevices || [];
			readerB.logicalDevices = readerB.logicalDevices || [];

			if (readerA.logicalDevices.length > 0) {
				portalSideA = readerA.logicalDevices[0].portalSide === 'OUTSIDE' ? 0 : 1;
			}

			if (readerB.logicalDevices.length > 0) {
				portalSideB = readerB.logicalDevices[0].portalSide === 'OUTSIDE' ? 0 : 1;
			}
			return portalSideA - portalSideB;
		})
		.map((reader) => convertPeripheralDevice(reader, portal));
}

export function convertPeripheralDevice(peripheralDevice, currentPortal, readerType = null) {
	peripheralDevice.logicalDevices = peripheralDevice.logicalDevices || [];
	if (!readerType && peripheralDevice.logicalDevices.length > 0) {
		readerType = peripheralDevice.logicalDevices[0].portalSide === 'OUTSIDE' ? 'ENTRY' : 'EXIT';
	}

	const isEntry = readerType === 'ENTRY';
	let { mapping, configuration, version, deviceInfo } = peripheralDevice;

	deviceInfo = deviceInfo || {};
	configuration.additionalSettings = configuration.additionalSettings || {};

	const {
		deniedBuzVol,
		doorOpenDuration,
		deniedLedType,
		deniedLedDuration,
		allowedLedDuration,
		allowedBuzVol,
		allowedLedType,
		allowedBuzDuration,
		deniedBuzDuration,
		strikeDuration,
		fixedAddress,
		input1DOSType,
		input1DOSMode,
		input2RTEMode,
		accessPointId,
	} = Object.assign(getReaderDefaults(), configuration.additionalSettings);

	const result = {
		peripheralDeviceId: peripheralDevice.deviceId,
		accessPointId,
		isEntry,
		portalName: currentPortal.name,
		portId: mapping.port ? mapping.port.portId : null,
		controllerId: mapping.port ? mapping.port.controllerId : null,
		portalId: mapping.portal ? mapping.portal.portalId : null,
		deniedBuzVol,
		doorOpenDuration: Number(doorOpenDuration) / 10,
		deniedLedType,
		deniedLedDuration: Number(deniedLedDuration) / 10,
		allowedLedDuration: Number(allowedLedDuration) / 10,
		allowedBuzVol,
		allowedLedType,
		allowedBuzDuration: Number(allowedBuzDuration) / 10,
		deniedBuzDuration: Number(deniedBuzDuration) / 10,
		strikeDuration: Number(strikeDuration) / 10,
		input1DOSType,
		input1DOSMode,
		input2RTEMode,
		fixedAddress,
		version,
		cylinderLength: configuration.additionalSettings.cylinderLength,
		customField: configuration.additionalSettings.customField,
		serialNumber: deviceInfo.serialNumber || '',
		zoneId: configuration.additionalSettings.zoneId || configuration.additionalSettings.zoneAssetId,
		zoneName: configuration.additionalSettings?.zoneName || configuration.additionalSettings.zoneAssetName,
		lockSpecification: configuration.additionalSettings.lockSpecification,
		macAddress: configuration.additionalSettings.macAddress,
		openTime: currentPortal.configuration ? moment.duration(currentPortal.configuration.openTime).asSeconds() : null,
		offlineSync: configuration.additionalSettings?.offlineSync,
	};

	if (peripheralDevice.deviceInfo) {
		result.deviceInfo = JSON.stringify(peripheralDevice.deviceInfo);
	}

	if (peripheralDevice.logicalDevices) {
		result.logicalDevices = JSON.stringify(peripheralDevice.logicalDevices);
	}

	if (peripheralDevice.configuration) {
		const temp = Object.assign({}, peripheralDevice.configuration);
		delete temp.additionalSettings;
		result.configuration = JSON.stringify(temp);
	}

	return result;
}

export function getAvailableReaders(selectedController, allReaders, selectedDoor, readerType, doorType = 'hid_online') {
	let controllerReaders = getReadersFromController(selectedController);

	controllerReaders = controllerReaders.filter(
		(availableReader) =>
			!allReaders.some((otherReader) => {
				return (
					otherReader &&
					otherReader.configuration &&
					otherReader.configuration.additionalSettings &&
					otherReader.configuration.additionalSettings.fixedAddress === availableReader.fixedAddress
				);
			})
	);

	readerType = readerType === 'ENTRY' ? 'OUTSIDE' : 'INSIDE';

	let selectedReader = allReaders
		.filter(
			(reader) =>
				reader.mapping.portal.portalId === selectedDoor.portalId && reader.logicalDevices && reader.logicalDevices[0]
		)
		.find((reader) => reader.logicalDevices[0].portalSide === readerType || doorType === 'aperio_online');

	const selectedFixedAddress = getSelectedFixedAddress(selectedReader);

	if (selectedFixedAddress) {
		if (selectedReader.mapping?.port?.controllerId === selectedController.controllerId) {
			controllerReaders.push({ fixedAddress: selectedFixedAddress });
		}

		if (isAperioDevice(selectedFixedAddress)) {
			controllerReaders = controllerReaders.filter(isAperioDevice);
		}
	}

	if (doorType === 'aperio_online') {
		controllerReaders = controllerReaders.filter(isAperioDevice);
	}

	controllerReaders = controllerReaders.sort(createSorter('fixedAddress'));

	return controllerReaders;
}

export function getSelectedFixedAddress(selectedReader) {
	if (
		selectedReader &&
		selectedReader.configuration &&
		selectedReader.configuration.additionalSettings &&
		selectedReader.configuration.additionalSettings.fixedAddress
	) {
		return selectedReader.configuration.additionalSettings.fixedAddress;
	}

	return null;
}

export function setSelectedFixedAddress(selectedReader, selectedFixedAddress) {
	selectedReader = selectedReader || {};
	selectedReader.configuration = selectedReader.configuration || {};
	selectedReader.configuration.additionalSettings = selectedReader.configuration.additionalSettings || {};
	selectedReader.configuration.additionalSettings.fixedAddress = selectedFixedAddress;

	return Object.assign({}, selectedReader);
}

export function readerIsSaved(selectedReader) {
	return !!(selectedReader && selectedReader.deviceId);
}

export function getReaderFormDefaults(readerType, controllerId, portalId, doorType = null) {
	const base = {
		isEntry: readerType === 'ENTRY',
		controllerId,
		portalId,
	};

	const results = {
		pulse_offline: base,
		aperio_offline: base,
	};

	return results[doorType] || Object.assign(base, getReaderDefaults());
}

export function isAperioDevice(assignedReader, doorType = null) {
	if (isAperioDoor(doorType)) {
		return true;
	}

	if (assignedReader && assignedReader.fixedAddress) {
		return (assignedReader.fixedAddress || '').startsWith('17');
	} else if (assignedReader && assignedReader.startsWith) {
		return assignedReader.startsWith('17');
	}

	return false;
}

export function buildOptions(readerList, addressesToExclude) {
	const version = systemConfig.devVersion;
	addressesToExclude = addressesToExclude || [];

	return readerList
		.filter((data) => data.readers.length > 0)
		.map((data, index) => {
			let hasSelectedOptions = false;
			if (addressesToExclude.length > 0 && data.hasSelectedOptions === false) {
				hasSelectedOptions = true;
			}

			const options = data.readers.map((reader) => {
				return {
					key: reader.fixedAddress,
					id: reader.fixedAddress,
					label: reader.fixedAddress,
					value: reader.fixedAddress,
					disabled: hasSelectedOptions,
				};
			});

			let controllerName = getReadableName(data.controller.name);

			if (version === 1 && options.some((option) => option.key.startsWith('17'))) {
				controllerName = `${getReadableName(data.controller.name)} (${translate.byKey('aperio_online')})`;
			}

			return {
				key: `${getReadableName(data.controller.name)} ${index}`,
				label: controllerName,
				options: options,
			};
		});
}

export const hasAssignedReadersCheck = (allReaders, selectedDoor) => {
	const assignedReaders = allReaders?.filter((reader) => reader?.mapping?.portal?.portalId === selectedDoor?.portalId);
	const assignedAperioReaders = assignedReaders?.filter(isAperioDevice);

	return (
		assignedReaders?.some(getSelectedFixedAddress) ||
		(assignedAperioReaders?.some(getSelectedFixedAddress) && isOnline(selectedDoor?.doorType || 'hid_online'))
	);
};
