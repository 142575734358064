import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message, Spin, Switch } from 'antd';
import moment from 'moment/moment';
import Text from 'antd/lib/typography/Text';

import {
	getSystemOfflineSettingsKey,
	useSystemOfflineSettings,
} from '~/hooks/data/system-revalidation-period/useSystemOfflineSettings';
import { useSystemOfflineSettingsUpdate } from '~/hooks/data/system-revalidation-period/useSystemOfflineSettingsUpdate';
import { useLocale } from '~/screens/_shared/AppLocale';
import { currentAdminHasRequiredRole, getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const revalidationPeriodMinValue = 1;
const revalidationPeriodMaxValue = 20000;

const getDaysFromRevalidationPeriod = (revalidationPeriod) =>
	revalidationPeriod ? moment.duration(revalidationPeriod * 1000).asDays() : 0;

const getRevalidationPeriodHoursStringFromDays = (revalidationPeriodAsDays) =>
	moment.duration(revalidationPeriodAsDays * 24, 'hours').toISOString();

export const OfflineSettings = ({ onClose }) => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();
	const isSystemOwner = currentAdminHasRequiredRole(getCurrentAdministrator(system), 'SYSTEM_OWNER');
	const { customerId, systemId } = system;

	const systemRevalidationPeriodKey =
		customerId && systemId
			? getSystemOfflineSettingsKey({
					customerId,
					systemId,
				})
			: null;
	const { data: systemOfflineSettingsData, isLoading: isSystemOfflineSettingsDataLoading } =
		useSystemOfflineSettings(systemRevalidationPeriodKey);
	const { trigger: updateSystemOfflineSetting, isMutating: isSystemOfflineSettingsUpdating } =
		useSystemOfflineSettingsUpdate(systemRevalidationPeriodKey);
	const [form] = Form.useForm();

	useEffect(() => {
		if (form && systemOfflineSettingsData) {
			form.setFieldsValue({
				defaultValidityPeriod: getDaysFromRevalidationPeriod(systemOfflineSettingsData.revalidationTime),
				defaultCollectAccessEvents: systemOfflineSettingsData.collectAccessEvents,
			});
		}
	}, [form, systemOfflineSettingsData]);

	const onSubmit = async ({ defaultValidityPeriod, defaultCollectAccessEvents }) => {
		const revalidationTime = getRevalidationPeriodHoursStringFromDays(defaultValidityPeriod);
		const collectAccessEvents = defaultCollectAccessEvents;

		if (
			defaultValidityPeriod !== systemOfflineSettingsData?.revalidationTime ||
			defaultCollectAccessEvents !== systemOfflineSettingsData?.collectAccessEvents
		) {
			await updateSystemOfflineSetting({ revalidationTime, collectAccessEvents });
		}

		onClose();
		message.success(translate.byKey('offline_settings_updated_correctly'));
	};

	return isSystemOfflineSettingsDataLoading ? (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Spin indicator={<LoadingOutlined style={{ fontSize: '36px' }} spin />} />
		</div>
	) : (
		<>
			<Form form={form} name="offline-settings" size="medium" layout="vertical" onFinish={onSubmit}>
				<Text strong>{translate.byKey('re_validation')}</Text>

				<Form.Item name="defaultValidityPeriod" label={translate.byKey('default_validity_period')}>
					<InputNumber
						style={{ width: '100%' }}
						min={revalidationPeriodMinValue}
						max={revalidationPeriodMaxValue}
						disabled={!isSystemOwner}
					/>
				</Form.Item>

				<Text>
					<strong>{translate.byKey('enable_collection_of_audits_from_pulse_cylinder')}</strong>
				</Text>

				<Form.Item name="defaultCollectAccessEvents" valuePropName="checked">
					<Switch
						checkedChildren={translate.byKey('yes')}
						unCheckedChildren={translate.byKey('no')}
						disabled={!isSystemOwner}
					/>
				</Form.Item>

				{isSystemOwner ? (
					<Form.Item>
						<Button
							loading={isSystemOfflineSettingsUpdating}
							disabled={isSystemOfflineSettingsUpdating}
							type="primary"
							htmlType="submit"
						>
							{translate.byKey('update')}
						</Button>
					</Form.Item>
				) : null}
			</Form>
		</>
	);
};
