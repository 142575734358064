import React from 'react';

export const viewBox = '-137 -33 1065 1065.003';

const path = (
	<g fill="currentColor">
		<path d="M723.36 44.813H650.5c-8.074 0-14.625 6.55-14.625 14.624 0 8.079 6.55 14.626 14.625 14.626h9.04v807.593h-546V74.063h60.87v33.222c0 25.469 20.715 46.184 46.184 46.184h69.144c6.914 38.164 40.39 67.191 80.504 67.191h32.586c40.11 0 73.586-29.027 80.5-67.191h69.145c25.472 0 46.195-20.719 46.195-46.184V44.168c0-25.469-20.723-46.188-46.191-46.188H220.594c-25.469 0-46.184 20.72-46.184 46.188v.645H49.71c-32.163 0-58.327 26.171-58.327 58.324v587.117c0 8.082 6.547 14.625 14.625 14.625 8.07 0 14.625-6.543 14.625-14.625V103.137c0-16.028 13.054-29.075 29.078-29.075h34.57V896.27c0 8.082 6.555 14.625 14.625 14.625h575.258c8.082 0 14.625-6.543 14.625-14.625V74.063h34.57c16.036 0 29.075 13.046 29.075 29.074v834.949c0 16.039-13.04 29.086-29.075 29.086H49.711c-16.024 0-29.078-13.047-29.078-29.086V759.19c0-8.07-6.555-14.625-14.625-14.625-8.078 0-14.625 6.555-14.625 14.625v178.895c0 32.168 26.164 58.336 58.328 58.336h673.648c32.164 0 58.325-26.168 58.325-58.336v-834.95c0-32.152-26.16-58.324-58.325-58.324zm-519.7-.645c0-9.34 7.594-16.934 16.934-16.934h331.883c9.34 0 16.933 7.594 16.933 16.934v63.117c0 9.34-7.594 16.934-16.933 16.934h-82.454c-8.082 0-14.625 6.55-14.625 14.625 0 28.988-23.585 52.566-52.57 52.566h-32.586c-28.984 0-52.566-23.578-52.566-52.566 0-8.074-6.547-14.625-14.625-14.625h-82.457c-9.34 0-16.934-7.594-16.934-16.934zm0 0" />
		<path d="M572.836 398.168h-77.332l-49.43-134.969c-4.906-13.418-24.367-12.508-27.902 1.43l-53.09 209.035-51.254-149.105c-4.098-11.918-20.41-13.399-26.586-2.399l-42.64 76.016h-44.368c-8.086 0-14.625 6.543-14.625 14.625 0 8.074 6.54 14.625 14.625 14.625h52.934c5.293 0 10.168-2.86 12.762-7.473l30.847-55.012 56.578 164.586c4.711 13.707 24.446 12.899 28.008-1.152l53.52-210.695 36.668 100.144a14.616 14.616 0 0013.734 9.594h87.547c8.082 0 14.625-6.543 14.625-14.625 0-8.074-6.543-14.625-14.621-14.625zm0 0M572.836 639.355H200.234c-8.078 0-14.625 6.543-14.625 14.625 0 8.079 6.547 14.625 14.625 14.625h372.602c8.078 0 14.625-6.546 14.625-14.625 0-8.082-6.547-14.625-14.625-14.625zm0 0M572.836 768.207H200.234c-8.078 0-14.625 6.55-14.625 14.625 0 8.078 6.547 14.625 14.625 14.625h372.602c8.078 0 14.625-6.547 14.625-14.625 0-8.074-6.547-14.625-14.625-14.625zm0 0" />
	</g>
);

const reports = {
	path,
	viewBox,
};

export default reports;
