import { useState } from 'react';
import mappers from '~/screens/_shared/mappers';
import { useDeleteApi, useGetApi, useListApi, usePutApi } from '~/screens/_shared/useApi';
import {
	getAccessAreaParams,
	getAssetsForPortal,
	getBasicAssetParams,
	useDefaultAccessGroupUpdate,
} from '~/screens/Doors/hooks/createOrUpdateDefaultAccessGroup';
import { useLocale } from '~/screens/_shared/AppLocale';
import { cacheKeys } from '~/screens/Doors/hooks/doorHelper';
import { isOnline } from '~/screens/Doors/hooks/doorDefaults';

export function useDeleteDoor() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const [getAsset] = useGetApi(mappers.asset);
	const [updateAsset] = usePutApi(mappers.asset);

	const [deletePeripheralDevice] = useDeleteApi(mappers.peripheralDevice);
	const [deleteAuthenticationProfile] = useDeleteApi(mappers.authenticationProfile);
	const [deleteSchedule] = useDeleteApi(mappers.schedule);
	const [deleteCalendar] = useDeleteApi(mappers.calendar);

	const { translate } = useLocale();

	const [getAccessPoints] = useListApi(mappers.accessPoint);
	const updateDefaultAccessGroup = useDefaultAccessGroupUpdate();

	/**
	 * We watch for an error in any of our calls and update our local state accordingly
	 */

	const [deletePortal] = useDeleteApi(mappers.portal);

	async function deleteDoor(formData, doorType) {
		const {
			portal: { portalId },
			authenticationProfile,
		} = formData;

		setIsLoading(true);
		setCurrentStep(translate.byKey('deleting_door'));
		try {
			let portalData = { accessPoints: [], assets: [] };
			if (isOnline(doorType)) {
				setCurrentStep(translate.byKey('fetching_assets'));
				portalData = await getAssetsForPortal(getAccessPoints, portalId);
				await updateDefaultAccessGroup(setCurrentStep, portalData.assets, true);
			}

			setCurrentStep(translate.byKey('deleting_readers'));

			const peripheralDevices = formData.readers || [];
			// Delete the peripheral devices
			for (const device of peripheralDevices) {
				if (device.peripheralDeviceId) {
					await deletePeripheralDevice(
						{ peripheralDeviceId: device.peripheralDeviceId },
						{
							removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
						}
					);
				}
			}

			setCurrentStep(translate.byKey('deleting_door'));

			// checkDependencies is not the clearest name for what it actually does. In the words of the backend team:
			// “If the message header checkDependencies is set to false,
			// then deleting a portal will also cascade delete all access points and cascade update all portal mode profiles using it.”
			// This saves the UI from having to delete the access points created for the portal.
			await deletePortal(
				{ portalId, __additionalHeaders: { checkDependencies: false } },
				{
					removeExistingKey: cacheKeys.portalIndexKey(),
				}
			);

			if (formData.doorAssetId) {
				getAsset({ assetId: formData.doorAssetId })
					.then(async (asset) => {
						let removeListCache = asset.type === 'BASIC_ASSET' ? [getBasicAssetParams()] : [getAccessAreaParams()];
						await updateAsset(
							{
								assetId: asset.assetId,
								name: asset.name + '__deleted',
								description: 'deleted',
								type: asset.type,
								version: asset.version,
								mainParent: null,
								additionalParents: null,
							},
							{
								removeListCache,
							}
						);
					})
					.catch(() => {});
			}

			//TODO add some step information here
			if (isOnline(doorType)) {
				try {
					if (
						portalData.accessPoints.every((accessPoint) =>
							authenticationProfile.accessPoints.some((point) => point.accessPointId === accessPoint.accessPointId)
						)
					) {
						const schedules = authenticationProfile.authenticationPolicies.reduce((schedules, policy) => {
							if (!schedules.some((schedule) => schedule.scheduleId === policy.schedule.scheduleId)) {
								schedules.push(policy.schedule);
							}

							return schedules;
						}, []);

						const calendars = schedules.reduce((calendars, schedule) => {
							if (!calendars.some((calendar) => calendar.calendarId === schedule.calendarId)) {
								calendars.push(schedule);
							}
							return calendars;
						}, []);

						await deleteAuthenticationProfile({
							authenticationProfileId: authenticationProfile.authenticationProfileId,
						});

						for (const schedule of schedules) {
							await deleteSchedule({ calendarId: schedule.calendarId, scheduleId: schedule.scheduleId });
						}

						for (const calendar of calendars) {
							await deleteCalendar({ calendarId: calendar.calendarId });
						}
					}
				} catch {
					// if we can't delete the profile, it could be linked to another set of access points.
				}
			}
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	}

	return [deleteDoor, isLoading, currentStep];
}
