import { useLocale } from '~/screens/_shared/AppLocale';
import { Col, InputNumber, Row, Select, Input as BaseInput } from 'antd';
import { Flex, Input, Item } from '~/components';
import React from 'react';
import { css } from '@emotion/core';
import moment from 'moment/moment';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const OPEN_TIME_MIN_VALUE = 1;
const OPEN_TIME_MAX_VALUE = 65;

const notANumberRegex = /\D+/g;

export function AperioConfigurationInputs({ fieldPrefix, form, currentTab, disabled }) {
	const { translate } = useLocale();

	return (
		<Row gutter={[12, 12]}>
			<Col lg={24}>
				<Item name={fieldPrefix + 'isEntry'} label={translate.byKey('reader_type_label')}>
					<Select placeholder={translate.byKey('reader_type_placeholder')} disabled={disabled}>
						<Select.Option key="entry" value={true}>
							{translate.byKey('entry_reader_tab_title')}
						</Select.Option>
						<Select.Option key="exit" value={false}>
							{translate.byKey('exit_reader_tab_title')}
						</Select.Option>
					</Select>
				</Item>
			</Col>
			<Col lg={24}>
				<Item
					name="openTime"
					label={`${translate.byKey('open_time')} (${OPEN_TIME_MIN_VALUE}-${OPEN_TIME_MAX_VALUE}s)`}
					shouldUpdate
					dependencies={['shouldDelete']}
					rules={[
						{
							required: currentTab === 'entry_reader_tab' && !form.getFieldValue('shouldDelete'),
							message: translate.byKey('open_time_is_required_v2'),
						},
					]}
				>
					<InputNumber
						parser={(displayValue) => displayValue.replace(notANumberRegex, '')}
						style={{ width: '170px' }}
						disabled={disabled}
						min={OPEN_TIME_MIN_VALUE}
						max={OPEN_TIME_MAX_VALUE}
						placeholder={`${translate.byKey('open_time')} (${OPEN_TIME_MIN_VALUE}-${OPEN_TIME_MAX_VALUE}s)`}
					/>
				</Item>
			</Col>
			<Col lg={24}>
				<Item
					name={fieldPrefix + 'lockSpecification'}
					label={translate.byKey('lock_specification_aperio_offline_v2')}
					shouldUpdate
					rules={[
						{
							max: 50,
							message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
								fieldName: translate.byKey('lock_specification_aperio_offline_v2'),
								maxLength: 50,
							}),
						},
					]}
				>
					<BaseInput
						placeholder={translate.byKey('lock_specification_aperio_offline_v2')}
						disabled={disabled}
						maxLength={50}
						showCount
					/>
				</Item>
			</Col>

			<Col lg={24}>
				<Item name={fieldPrefix + 'macAddress'} label={translate.byKey('mac_address_label')} shouldUpdate>
					<Input
						spellCheck={false}
						disabled={disabled}
						placeholder={translate.byKey('mac_address_label')}
						mask="mm:mm:mm:mm:mm:mm"
					/>
				</Item>
			</Col>
			<Col lg={24}>
				<Item name={fieldPrefix + 'customField'} label={translate.byKey('custom_field_label')} shouldUpdate>
					<Input.TextArea
						disabled={disabled}
						maxLength={20}
						allowClear
						placeholder={translate.byKey('custom_field_label')}
						showCount
					/>
				</Item>
			</Col>

			<Item name={fieldPrefix + 'portalName'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'portalId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'peripheralDeviceId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'version'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'accessPointId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'logicalDevices'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'deviceInfo'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Row>
	);
}

export function PulseConfigurationInputs({ currentDoor, fieldPrefix, disabled }) {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();

	const {
		auditCollectedDateTime,
		'auditCollectedBy.name': administratorName,
		'auditCollectedBy.administratorId': administratorId,
	} = currentDoor?.assignedReaders?.[0]?.configuration?.additionalSettings || {};

	const findAdministratorNameById = (administratorId) => {
		const administrator = system.administrators.find((admin) => admin.administratorId === administratorId);
		return `${administrator.firstName} ${administrator.lastName}`;
	};

	return (
		<Row gutter={[12, 12]}>
			{auditCollectedDateTime ? (
				<Col lg={24}>
					<span css={auditCollectionLabelStyles}>{translate.byKey('last_audit_collection')}</span>
					<Flex
						justifyContent="space-between"
						backgroundColor="#f5f5f5"
						color="rgba(0, 0, 0, 0.35)"
						border="1px solid #d9d9d9"
						borderRadius="2px"
						padding="3px 11px"
					>
						<span>{moment(auditCollectedDateTime).format('L LT')}</span>
						<span>{administratorName || findAdministratorNameById(administratorId)}</span>
					</Flex>
				</Col>
			) : null}
			<Col lg={24}>
				<Item name={fieldPrefix + 'isEntry'} label={translate.byKey('reader_type_label')}>
					<Select placeholder={translate.byKey('reader_type_placeholder')} disabled={disabled}>
						<Select.Option key="entry" value={true}>
							{translate.byKey('entry_reader_tab_title')}
						</Select.Option>
						<Select.Option key="exit" value={false}>
							{translate.byKey('exit_reader_tab_title')}
						</Select.Option>
					</Select>
				</Item>
			</Col>
			<Col lg={24}>
				<Item
					name={fieldPrefix + 'cylinderLength'}
					label={translate.byKey('cylinder_length_label')}
					shouldUpdate
					rules={[
						{
							pattern: /^\d{2}\/\d{2}$/,
							message: translate.byKey('the_value_should_contain_one_or_more_numbers_on_both_sides'),
						},
					]}
				>
					<Input
						mask="99/99"
						placeholder={translate.byKey('cylinder_length_label')}
						autoFocus={true}
						disabled={disabled}
					/>
				</Item>
			</Col>
			<Col lg={24}>
				<Item name={fieldPrefix + 'serialNumber'} label={translate.byKey('serial_number_label')} shouldUpdate>
					<Input disabled={true} placeholder={translate.byKey('serial_number_label')} />
				</Item>
			</Col>
			<Col lg={24}>
				<Item name={fieldPrefix + 'customField'} label={translate.byKey('custom_field_label')} shouldUpdate>
					<Input.TextArea
						maxLength={20}
						allowClear
						placeholder={translate.byKey('custom_field_label')}
						disabled={disabled}
						showCount
					/>
				</Item>
			</Col>

			<Item name={fieldPrefix + 'portalName'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'portalId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'peripheralDeviceId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'version'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'accessPointId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'logicalDevices'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'deviceInfo'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Row>
	);
}

const auditCollectionLabelStyles = (theme) =>
	css({
		color: theme.colors.gray[400],
		padding: '0 0 2px',
		fontSize: '12px',
		lineHeight: 1,
	});
