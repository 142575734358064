import { useState } from 'react';
import mappers from '~/screens/_shared/mappers';
import { securityLevels as securityLevelsConstants } from '../entityConstants';
import { usePostApi, useGetApi, usePutApi } from '~/screens/_shared/useApi';
import { authenticationModes } from '../entityConstants';
import { mapAccessPolicies, normalizeTimeSlots } from './helpers';
import {
	getInnerAssetName,
	getCalendarName,
	getCalendarDesc,
	getScheduleName,
	getScheduleDesc,
	getAuthenticationProfileName,
	getAuthenticationProfileDesc,
	getAccessPointName,
	getPortalName,
	cacheKeys,
} from './doorHelper';
import { useDefaultAccessGroupUpdate } from '~/screens/Doors/hooks/createOrUpdateDefaultAccessGroup';
import { translator, useLocale } from '~/screens/_shared/AppLocale';
import { useCreateReader } from '~/screens/Doors/hooks/useCreateReader';
import { doorTypeNumberOfReaders, isOffline, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import moment from 'moment';
import { getLimits } from '~/screens/_shared/entitlement';
import { Modal } from '~/components';
import useGetEntitlements from '~/screens/_shared/entitlement/useGetEntitlements';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { useSecurityLevels } from './useSecurityLevels';

const scheduleStates = {
	[securityLevelsConstants.LOCKED]: 'Access is not allowed',
	[securityLevelsConstants.CARD_AND_PIN]: 'Access is allowed',
	[securityLevelsConstants.UNLOCKED]: 'Unlocked',
	[securityLevelsConstants.PAC]: 'PAC',
	[securityLevelsConstants.OPEN_ON_FIRST_CARD]: 'Open on first card',
	[securityLevelsConstants.CARD]: 'Card',
	[securityLevelsConstants.OFFICE_MODE]: 'Office Mode',
};

export function checkDoorLimits(system, doorType, selectedReaders) {
	const limits = getLimits(system, doorType);
	if (selectedReaders && limits.usedCount >= limits.hardLimit) {
		Modal.warn({
			title: translator.byKey('limit_reached_title_v2'),
			content: translator.byKeyFormatted('door_limit_message_v2', [translator.byKey(doorType + '_label')]),
			okText: translator.byKey('ok'),
		});
		return true;
	}
	return false;
}

export function useCreateDoor() {
	const {
		data: { site },
	} = useCurrentSystemSite();
	const { data: securityLevels } = useSecurityLevels();
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const { translate } = useLocale();
	const [createPortal] = usePostApi(mappers.portal);
	const [createAsset] = usePostApi(mappers.asset);
	const [createAccessPoint] = usePostApi(mappers.accessPoint);
	const [createCalendar] = usePostApi(mappers.calendar);
	const [createSchedule] = usePostApi(mappers.schedule);
	const [getPortal] = useGetApi(mappers.portal);
	const [updatePortal] = usePutApi(mappers.portal);
	const [getEntitlements] = useGetEntitlements();
	const [createReader] = useCreateReader();
	const [createAuthenticationProfile] = usePostApi(mappers.authenticationProfile);
	const updateDefaultAccessGroup = useDefaultAccessGroupUpdate();

	/**
	 * Create a new Door
	 */

	async function createDoor(formData, doorType) {
		formData = formData || {};
		formData.readers = formData.readers || [];
		setCurrentStep(translate.byKey('creating_new_door_entry'));
		setIsLoading(true);

		const numberOfReadersToCreate = doorTypeNumberOfReaders[doorType];
		try {
			const { name, timeSlots: formTimeSlots, openTime } = formData;
			const convertedOpenTime =
				isOffline(doorType) && openTime
					? moment.duration(Number.parseInt(openTime), 'seconds').toISOString()
					: isOnline(doorType)
					  ? 'PT0S'
					  : 'PT20S';

			const additionalParents = (formData.doorGroups || []).map((assetId) => {
				return { key: { assetId, systemId: site.systemId, siteId: site.siteId } };
			});

			setCurrentStep(translate.byKey('creating_areas'));
			const { zoneId } = formData || '';

			const innerAsset = await createAsset({
				name: getInnerAssetName(name),
				type: 'BASIC_ASSET',
				mainParent: zoneId
					? {
							key: {
								systemId: site.systemId,
								siteId: site.siteId,
								assetId: zoneId,
							},
					  }
					: undefined,
				additionalParents: additionalParents ? additionalParents : undefined,
			});

			const { portalId } = await createPortal(
				{
					name: name,
					description: '',
					configuration: {
						openTime: convertedOpenTime,
						additionalSettings: {
							additionalData: {
								zoneAssetId: formData.zoneId,
								zoneAssetName: formData.zoneName,
								doorType: doorType,
								doorAssetId: innerAsset.assetId,
								additionalParents: JSON.stringify(additionalParents),
							},
						},
					},
				},
				{
					removeExistingKey: cacheKeys.portalIndexKey(),
				}
			);

			const portal = await getPortal({ portalId });
			const { systemId, siteId } = portal;

			let accessPointsToCreate = formData.readers.length || numberOfReadersToCreate;

			const pendingAccessPoints = [];

			for (let i = 0; i < accessPointsToCreate; ++i) {
				let type;

				if (!!formData.readers[i] && 'isEntry' in formData.readers[i]) {
					type = formData.readers[i].isEntry ? 'ENTRY' : 'EXIT';
				} else {
					type = i === 0 ? 'ENTRY' : 'EXIT';
				}

				if (type === 'ENTRY') {
					setCurrentStep(translate.byKey('creating_entry_access_point'));
				} else {
					setCurrentStep(translate.byKey('creating_exit_access_point'));
				}

				pendingAccessPoints.push(
					createAccessPoint({
						type,
						portalName: getAccessPointName(portal.name),
						systemId,
						siteId,
						fromAssetId: innerAsset.assetId,
						toAssetId: innerAsset.assetId,
						portalId: portal.portalId,
					})
				);
			}

			const defaultState = securityLevelsConstants.CARD;
			let policies = null;

			if (!isOffline(doorType)) {
				const timeSlots = normalizeTimeSlots(formTimeSlots);
				const { dayTypes, standardWeek } = timeSlots;

				setCurrentStep(translate.byKey('creating_calendar'));

				const calendarData = await createCalendar({
					name: getCalendarName(portal.name),
					description: getCalendarDesc(portal.name),
					dayTypes,
					standardWeek,
				});

				const { timeIntervals } = timeSlots;
				const { calendarId } = calendarData;

				setCurrentStep(translate.byKey('creating_schedule'));

				const schedule = await createSchedule({
					calendarId,
					name: getScheduleName(),
					description: getScheduleDesc(),
					scheduleStates,
					defaultState,
					timeIntervals,
				});

				const { scheduleId } = schedule;
				policies = mapAccessPolicies({
					scheduleStates,
					systemId,
					calendarId,
					scheduleId,
					securityLevels,
					authenticationModes,
				});

				setCurrentStep(translate.byKey('creating_authentication_profile'));
				const authenticationProfileData = await createAuthenticationProfile({
					name: getAuthenticationProfileName(name),
					description: getAuthenticationProfileDesc(name),
					points: (await Promise.all(pendingAccessPoints)).map((accessPoint) => ({
						systemId,
						siteId,
						portalId,
						accessPointId: accessPoint.accessPointId,
					})),
					policies,
					defaultSecurityLevelId: securityLevels[defaultState].securityLevelId,
				});

				const { version, configuration } = portal;
				const { authenticationProfileId } = authenticationProfileData;
				setCurrentStep(translate.byKey('updating_door_data'));
				const pendingUpdates = [
					updatePortal({
						name: getPortalName(name),
						version,
						portalId,
						configuration: {
							...configuration,
							additionalSettings: {
								authenticationProfileId: authenticationProfileId,
								timeSlots: JSON.stringify(timeSlots),
								additionalData: {
									zoneAssetId: formData.zoneId,
									zoneAssetName: formData.zoneName,
									doorType,
									doorAssetId: innerAsset.assetId,
									additionalParents: JSON.stringify(additionalParents),
								},
							},
						},
					}),
				];

				try {
					pendingUpdates.push(updateDefaultAccessGroup(setCurrentStep, [innerAsset]));
					await Promise.all(pendingUpdates);
				} catch (ex) {
					//TODO add some logic to deal with this exception
					throw ex;
				}
			}

			const promiseFunction = isOnline(doorType) ? Promise.allSettled.bind(Promise) : Promise.all.bind(Promise);

			await promiseFunction(
				formData.readers.map((reader) => {
					if (reader.isEntry === null || reader.isEntry === undefined) {
						reader.isEntry = formData.readers.length === 1;
					}
					return createReader(
						{
							portal: portal,
							isEntry: JSON.parse(reader.isEntry),
							formattedValues: reader,
						},
						doorType
					);
				})
			);
		} catch (error) {
			if (error.status === 403) {
				const updatedSystem = await getEntitlements();
				if (checkDoorLimits(updatedSystem, doorType)) return;
			}

			throw error;
		} finally {
			setCurrentStep('');
			setIsLoading(false);
		}
	}

	return [createDoor, isLoading, currentStep];
}
