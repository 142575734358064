function getReadersFromController(updatedController) {
	const configuration = updatedController?.configuration || { additionalSettings: {} };

	return Object.keys(configuration.additionalSettings)
		.filter((k) => k.startsWith('reader.'))
		.reduce((results, key) => {
			results.push(configuration.additionalSettings[key]);
			return results;
		}, [])
		.map((a) => a.split('|').filter((f) => f.startsWith('fixedAddress:'))[0])
		.map((f) => {
			return { fixedAddress: (f || '').replace('fixedAddress:', '') };
		})
		.sort((a, b) => a.fixedAddress.localeCompare(b.fixedAddress));
}

function getFlimsyStates() {
	return [
		'assetStates',
		'portStates',
		'portalStates',
		'accessPointStates',
		'calendarStates',
		'scheduleStates',
		'accessProfileStates',
		'credentialStates',
		'credentialHolderStates',
		'peripheralDeviceStates',
		'authenticationProfileStates',
		'securityLevelStates',
		'specialDayStates',
	];
}

function getItemSyncTotals(allSyncState, ignoreFlimsyStates = false) {
	const result = {
		itemsToBeUpdated: 0,
		itemsUpdated: 0,
		itemUpdateFailures: 0,
	};

	if (allSyncState.controllerInfoSyncState === 'UPDATED') {
		result.itemsUpdated += 1;
	} else if (allSyncState.controllerInfoSyncState === 'PENDING_UPDATE') {
		result.itemsToBeUpdated += 1;
	} else if (
		allSyncState.controllerInfoSyncState === 'UPDATE_FAILED' ||
		allSyncState.controllerInfoSyncState === 'UPDATE_GLITCH'
	) {
		result.itemUpdateFailures += 1;
	}

	for (const key of Object.keys(allSyncState)) {
		if (ignoreFlimsyStates && getFlimsyStates().includes(key)) {
			continue;
		}

		const element = allSyncState[key];
		if (Array.isArray(element)) {
			for (const item of element) {
				if (item.syncState === 'UPDATED' || item.syncState === 'DELETED') {
					result.itemsUpdated += 1;
				} else if (item.syncState === 'PENDING_UPDATE' || item.syncState === 'PENDING_DELETE') {
					result.itemsToBeUpdated += 1;
				} else if (
					item.syncState === 'UPDATE_FAILED' ||
					item.syncState === 'DELETE_FAILED' ||
					item.syncState === 'UPDATE_GLITCH' ||
					item.syncState === 'DELETE_GLITCH'
				) {
					result.itemUpdateFailures += 1;
				}
			}
		}
	}

	return result;
}

function getCurrentControllerState(allSyncState, ignoreFlimsyStates = false) {
	const totals = getItemSyncTotals(allSyncState, ignoreFlimsyStates);

	if (totals.itemsToBeUpdated > 0) {
		return 'PENDING_UPDATE';
	}

	if (totals.itemUpdateFailures > 0) {
		return 'UPDATE_FAILED';
	}

	if (totals.itemsToBeUpdated === 0 && totals.itemsUpdated > 0) {
		return 'UPDATED';
	}

	return allSyncState.controllerInfoSyncState;
}

export { getReadersFromController, getItemSyncTotals, getCurrentControllerState };
