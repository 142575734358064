import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Col, Input as AInput, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const ANTD_INPUT_VALID_PROPS = ['suffix'];

const StyledInput = styled(AInput, {
	shouldForwardProp: (prop) => isPropValid(prop) || ANTD_INPUT_VALID_PROPS.includes(prop),
})`
	display: flex;
	color: ${(props) => props.theme.colors.gray[400]};
	border-color: ${(props) => props.theme.colors.gray[200]};
	margin: 0;

	.ant-input-prefix {
		margin-right: 10px;
	}
	svg {
		color: ${(props) => props.theme.colors.gray[600]};
	}

	.ant-input-group-addon {
		padding: 0;
	}
	&::placeholder {
		color: ${(props) => props.theme.colors.gray[300]};
	}
	.ant-input::placeholder {
		color: ${(props) => props.theme.colors.gray[300]};
	}
	${layout}
	${space}
  ${flexbox}
  ${size}
  ${background}
  ${grid}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
`;

const maskFormatExpressions = {
	9: '[0-9]',
	a: '[a-z]',
	A: '[A-Z]',
	'*': '[A-Za-z0-9]',
	m: '[a-z0-9]',
	s: '[A-Z0-9]',
};

const MaskedInput = (props) => {
	const { hasCustomAddon, isAddon, onChange, value, disabled, maskCustomChar, ...newProps } = props;
	const css = {
		borderRadius: hasCustomAddon ? '0 4px 4px 0' : '4px',
		...(hasCustomAddon && { marginLeft: 0 }),
		...(isAddon && { borderRadius: '4px 0 0 4px' }),
	};

	const onInputChange = useCallback((value) => {
		if (onChange) {
			onChange(value);
		}
	});

	return props.mask ? (
		<InputMask
			{...newProps}
			value={value}
			allowClear
			onChange={onInputChange}
			maskChar={maskCustomChar ? maskCustomChar : '_'}
			formatChars={maskFormatExpressions}
			disabled={disabled}
		>
			{(inputProps) => (
				<StyledInput
					css={css}
					{...newProps}
					{...inputProps}
					value={value}
					disabled={disabled}
					onChange={onInputChange}
				/>
			)}
		</InputMask>
	) : (
		<StyledInput css={css} {...newProps} value={value} onChange={onInputChange} disabled={disabled} showCount />
	);
};

const Input = (props) => {
	const [newProps, setNewProps] = useState({});
	const [customAddon, setCustomAddon] = useState(false);

	const { addonBefore, onChange, onBlur, value, maskChar } = props;

	useEffect(() => {
		if (addonBefore && typeof addonBefore !== 'string') {
			setCustomAddon(props.addonBefore);
			const { addonBefore, ...newPropsObject } = props;
			setNewProps({ ...newPropsObject, hasCustomAddon: true });
		} else {
			setNewProps(props);
		}
	}, [addonBefore, props]);

	const onInputChange = useCallback((value) => {
		if (onChange) {
			onChange(value);
		}
	});

	const onInputBlur = useCallback((value) => {
		if (onBlur) {
			onBlur(value);
		}
	});

	return customAddon ? (
		<AInput.Group>
			<Row>
				<Col md={6}>{addonBefore}</Col>
				<Col md={18}>
					<MaskedInput
						{...newProps}
						autoComplete="off"
						maskCustomChar={maskChar}
						value={value}
						onChange={onInputChange}
						onBlur={onInputBlur}
						formatChars={maskFormatExpressions}
					/>
				</Col>
			</Row>
		</AInput.Group>
	) : (
		<MaskedInput
			{...newProps}
			value={value}
			autoComplete="off"
			maskCustomChar={maskChar}
			onChange={onInputChange}
			formatChars={maskFormatExpressions}
		/>
	);
};

Input.TextArea = AInput.TextArea;

export default Input;
