import React, { useEffect, useState } from 'react';

import { Page } from '~/components';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { BlocklistHeader } from '~/components/features/blocklist/BlocklistHeader/BlocklistHeader';
import { BlockCredentialModal } from '~/components/features/blocklist/modals/BlockCredentialModal/BlockCredentialModal';
import { UnblockCredentialModal } from '~/components/features/blocklist/modals/UnblockCredentialModal/UnblockCredentialModal';
import { BlocklistClientDataTable } from '~/components/features/blocklist/table/BlocklistClientDataTable/BlocklistClientDataTable';
import { BlocklistDataTable } from '~/components/features/blocklist/table/BlocklistDataTable/BlocklistDataTable';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const BlocklistPage = () => {
	const {
		data: {
			system: { customerId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { person },
		setQueryParams,
		clearAllQueryParams,
	} = useQueryParams();
	const [selectedCredential, setSelectedCredential] = useState(null);
	const [isBlockCredentialModalVisible, setIsBlockCredentialModalVisible] = useState(false);

	useEffect(() => {
		const clearSpecificQueryParamsOnCustomerLevelChange = () => {
			if (customerId) {
				setQueryParams({
					pageNumber: '',
					person: '',
				});
			}
		};

		clearSpecificQueryParamsOnCustomerLevelChange();
	}, [customerId]);

	useEffect(() => {
		clearAllQueryParams();

		return clearAllQueryParams;
	}, []);

	const onSelectedCredential = (credential) => setSelectedCredential(credential);

	return (
		<>
			{isBlockCredentialModalVisible ? (
				<BlockCredentialModal onClose={() => setIsBlockCredentialModalVisible(false)} />
			) : null}
			{selectedCredential ? (
				<UnblockCredentialModal credential={selectedCredential} onClose={() => setSelectedCredential(null)} />
			) : null}
			<Page>
				<BlocklistHeader onAddNewButtonClick={() => setIsBlockCredentialModalVisible(true)} />
				{person ? (
					<BlocklistClientDataTable onSelectedCredential={onSelectedCredential} />
				) : (
					<BlocklistDataTable onSelectedCredential={onSelectedCredential} />
				)}
			</Page>
		</>
	);
};
