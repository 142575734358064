import React from 'react';
import { Button, Modal, message, Spin, Form } from 'antd';
import { getEmeaSiteKey, useGetEmeaSite } from '~/hooks/data/site-settings/useGetEmeaSite';
import {
	SiteSettingsForm,
	SITE_SETTINGS_FORM_NAME,
} from '~/components/features/site-settings/SiteSettingsForm/SiteSettingsForm';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCode, getName } from 'country-list';
import { getCurrentAdministrator, currentAdminHasRequiredRole } from '~/screens/_shared/userRoleConstants';
import { useUpdateEmeaSite } from '~/hooks/features/site-settings/useUpdateEmeaSite';
import { useCreateEmeaSite } from '~/hooks/features/site-settings/useCreateEmeaSite';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { City } from 'country-state-city';

export const SiteSettingsModal = ({ onClose }) => {
	const { translate } = useLocale();
	const [form] = Form.useForm();

	const {
		data: {
			system: { endCustomerId, businessEntityId, systemId },
			site: { siteId, siteName },
		},
	} = useCurrentSystemSite();

	const currentAdmin = getCurrentAdministrator();
	const requiredRoles = ['SYSTEM_OWNER', 'SYSTEM_INSTALLER'];
	const hasRequiredRoles = currentAdminHasRequiredRole(currentAdmin, requiredRoles);

	const { data, isLoading, isValidating, mutate } = useGetEmeaSite(
		getEmeaSiteKey({
			endCustomerId,
			businessEntityId,
			systemId,
			siteId,
		})
	);

	const name = Form.useWatch('name', form);
	const location = Form.useWatch('location', form);
	const timezone = Form.useWatch('timeZone', form);
	const description = Form.useWatch('description', form);

	const isSubmitDisabled =
		!name?.trim() ||
		!location ||
		!timezone ||
		(name === data?.name &&
			location.toString() === getLocation(data?.location)?.toString() &&
			timezone === data?.timeZone &&
			description === data?.description);

	const { trigger: updateSite, isMutating: isUpdateInProgress } = useUpdateEmeaSite();
	const { trigger: createSite, isMutating: isCreateInProgress } = useCreateEmeaSite();

	const onSubmit = async (form) => {
		const { location, name, description, timeZone } = form.getFieldsValue();
		const requestBody = {
			name,
			description: description ?? '',
			location: `${getName(location[0])},${location[1]}`,
			timezone: timeZone,
		};

		if (Boolean(siteId)) {
			await updateSite(requestBody);

			message.success(translate.byKey('site_has_been_updated_successfully'));

			mutate();
		} else {
			await createSite(requestBody);

			message.success(translate.byKey('site_has_been_created_successfully'));
			message.warning(
				translate.byKey('please_wait_refresh_after_60_seconds_so_site_information_can_synchronise_correctly')
			);
		}

		onClose();
	};

	const isSiteLoading = siteId ? isLoading || isValidating : false;

	return (
		<Modal
			destroyOnClose
			open
			onCancel={onClose}
			title={siteName ?? translate.byKey('add_site')}
			footer={
				<>
					<Button onClick={onClose} disabled={isUpdateInProgress || isCreateInProgress}>
						{translate.byKey('cancel')}
					</Button>
					{hasRequiredRoles ? (
						<Button
							htmlType="submit"
							type="primary"
							form={SITE_SETTINGS_FORM_NAME}
							loading={isUpdateInProgress || isCreateInProgress}
							disabled={isSubmitDisabled}
						>
							{translate.byKey(Boolean(siteId) ? 'update' : 'create')}
						</Button>
					) : null}
				</>
			}
		>
			<Spin spinning={isSiteLoading}>
				{!isSiteLoading ? <SiteSettingsForm selectedSite={data} form={form} onFormSubmit={onSubmit} /> : null}
			</Spin>
		</Modal>
	);
};

export const getLocation = (location) => {
	if (!location) {
		return undefined;
	}

	const city = City.getAllCities().find((x) => x?.name === location);
	if (city) {
		return [city.countryCode, city.name];
	}

	const locationArray = location.split(',');
	if (getCode(locationArray[0])) {
		return [getCode(locationArray[0]), locationArray[1]];
	}

	return null;
};
