import React, { useEffect, useState } from 'react';

import { SelectPeople } from '~/components/features/access-profiles/modals/form/AddPeople/SelectPeople';
import { SelectedPeople } from '~/components/features/access-profiles/modals/form/AddPeople/SelectedPeople';

export const AddPeople = ({ onStepTransitionEnable }) => {
	const [isSelectingModeEnabled, setIsSelectingModeEnabled] = useState(false);

	useEffect(() => {
		onStepTransitionEnable?.(!isSelectingModeEnabled);
	}, [isSelectingModeEnabled, onStepTransitionEnable]);

	return isSelectingModeEnabled ? (
		<SelectPeople onDisableSelectingMode={() => setIsSelectingModeEnabled(false)} />
	) : (
		<SelectedPeople onEnableSelectingMode={() => setIsSelectingModeEnabled(true)} />
	);
};
