import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const useClientDelete = (key, config) => {
	const ref = useRef(
		new ApiRequester({
			entity: '/clients/:clientId:',
			method: method.delete,
		})
	);
	const fetcher = async (_, { arg: { customerId, systemId, clientId } }) => {
		return ref.current.fetchData({
			clientId,
			params: {
				customerId,
				systemId,
			},
			ignoreGlobalHandlers: true,
			removeProperties: ['ignoreGlobalHandlers'],
		});
	};

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getClientDeleteKey = ({ customerId, systemId, clientId }) =>
	customerId && systemId && clientId
		? `${customerId}${separator}\
${systemId}${separator}\
${clientId}${separator}\
deleteClient`
		: null;
