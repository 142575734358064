import styled from '@emotion/styled';
import { Form } from 'antd';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const { Item: AItem } = Form;

/** @type {AItem} */
const Item = styled(AItem)`
	display: flex;
	margin-bottom: 0;
	.ant-form-item-explain {
		padding-top: 4px;
		font-size: 11px;
		line-height: 11px;
	}
	label {
		color: ${(props) => props.theme.colors.gray[400]};
		padding: 0 0 2px;
		font-size: 12px;
		line-height: 12px;
		height: 12px;
	}

	.ant-form-item-label {
		color: ${(props) => props.theme.colors.gray[300]};
		padding: 0 0 2px;
		font-size: 11px;
	}

	.ant-form-item-label > label.ant-form-item-required::before {
		display: inline-block;
		margin-right: 4px;
		color: #ff0000;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
		fontweight: 600;
	}

	&>.ant-form-item-row {
		width: 100%;
	},

	${layout}
	${space}
  ${flexbox}
  ${size}
  ${background}
  ${grid}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
`;
export default Item;
