import React, { useState } from 'react';
import { AddButton, PageHeader, ReloadButton } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { screens } from '~/screens/_shared/userRoleConstants';
import { AddHolidayModal } from '~/components/features/holidays/AddHolidayModal/AddHolidayModal';
import { useHolidays } from '~/hooks/features/holidays/useHolidays';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { checkIfSystemLimitsReached, systemLimits } from '~/screens/_shared/systemLimits';
import { Modal } from 'antd';

export const HolidaysPageHeader = () => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const [isHolidayAddModalOpen, setIsHolidayAddModalOpen] = useState(false);

	const { mutate, isLoading, isValidating, totalRecords } = useHolidays();

	const onAddNewClick = () => {
		if (checkIfSystemLimitsReached(systemLimits.SPECIAL_DAYS_PER_SYSTEM, totalRecords)) {
			Modal.info({
				title: translate.byKey('holidays_limit_reached'),
				content: translate.byKeyFormatted('a_limit_of_on_system_has_been_reached_formatted', [
					systemLimits.SPECIAL_DAYS_PER_SYSTEM,
					translate.byKey('holidays'),
				]),
				okText: translate.byKey('ok'),
			});

			return;
		}

		setIsHolidayAddModalOpen(true);
	};

	const onClose = () => setIsHolidayAddModalOpen(false);

	const onCreate = () => {
		onClose();
		mutate();
	};

	const hasAddButton = hasActionPermissions(user, userConstants.screens.HOLIDAYS, userConstants.actions.CREATE);

	return (
		<React.Fragment>
			<PageHeader title={translate.byKey('holidays')} icon="holiday" showHelp={false}>
				<ReloadButton disabled={isLoading || isValidating} onReload={mutate} />
				{hasAddButton ? (
					<AddButton disabled={isLoading || isValidating} screen={screens.HOLIDAYS} onAddNewClick={onAddNewClick} />
				) : null}
			</PageHeader>
			<AddHolidayModal open={isHolidayAddModalOpen} onClose={onClose} onCreate={onCreate} />
		</React.Fragment>
	);
};
