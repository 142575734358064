import { Col, Row, Input, Checkbox } from 'antd';
import { PhoneInput, Item } from '~/components';
import React, { useEffect, useState } from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useListApi } from '~/screens/_shared/useApi';
import { canCurrentAdminEditAdministrators } from '~/components/features/people/People.hooks';
import { validateMinNumberOfCharacters } from '~/utils/validation';
import { filterNumbersFromString } from '~/utils/filter';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const FormCheckbox = (props) => {
	const newProps = { ...props, value: props.checked, onChange: (event) => props.onChange(event.target.checked) };

	return <Checkbox {...newProps} />;
};

export const BasicInfoInputs = ({ form, visible, sameAdminAsLogin, isEditDisabled }) => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();
	const [getAdministratorRoles] = useListApi('/administration/administrator/:administratorId/role');
	const [canEditAdministrators, setCanEditAdministrators] = useState(false);

	useEffect(() => {
		if (system && system.businessEntityId && visible) {
			if (!canEditAdministrators) {
				canCurrentAdminEditAdministrators(getAdministratorRoles)
					.then((canEdit) => {
						setCanEditAdministrators(canEdit);
					})
					.catch(() => {
						setCanEditAdministrators(false);
					});
			}
		}
	}, [visible]);

	const validatePinInput = (rule, value) => {
		if (!value) {
			return Promise.resolve();
		}

		if (value.startsWith('0')) {
			return Promise.reject(translate.byKey('pin_code_cannot_start_with_a_leading_zero'));
		}

		let pinCodeRegex = new RegExp(/^\d+$/gm); // numbers no decimal
		let pinCheck = pinCodeRegex.test(value);
		if (!pinCheck) {
			return Promise.reject(translate.byKey('pin_code_is_invalid'));
		}
		return Promise.resolve();
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={12}>
				<Item
					name="firstName"
					label={translate.byKey('first_name')}
					shouldUpdate
					rules={
						isEditDisabled
							? []
							: [
									{
										required: true,
										whitespace: true,
										message: translate.byKey('first_name_is_required'),
									},
									{
										pattern: validateMinNumberOfCharacters(1),
										message: translate.byKey('first_name_must_not_be_an_empty_string'),
									},
									{
										max: 90,
										message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
											fieldName: translate.byKey('first_name'),
											maxLength: 90,
										}),
									},
								]
					}
				>
					<Input
						disabled={isEditDisabled}
						data-testid="v2-firstName"
						placeholder={translate.byKey('first_name')}
						autoFocus={true}
						autoComplete="off"
						aria-autocomplete="none"
					/>
				</Item>
			</Col>
			<Col xs={24} sm={12}>
				<Item
					name="lastName"
					label={translate.byKey('last_name')}
					shouldUpdate
					rules={
						isEditDisabled
							? []
							: [
									{
										required: true,
										whitespace: true,
										message: translate.byKey('last_name_is_required'),
									},
									{
										pattern: validateMinNumberOfCharacters(1),
										message: translate.byKey('last_name_must_not_be_an_empty_string'),
									},
									{
										max: 90,
										message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
											fieldName: translate.byKey('last_name'),
											maxLength: 90,
										}),
									},
								]
					}
				>
					<Input
						disabled={isEditDisabled}
						data-testid="v2-lastName"
						placeholder={translate.byKey('last_name')}
						autoComplete="off"
						aria-autocomplete="none"
					/>
				</Item>
			</Col>
			<Col span={24}>
				<Item
					name="email"
					label={translate.byKey('email_address')}
					shouldUpdate
					dependencies={['isAdministrator']}
					normalize={(value) => value.toLowerCase()}
					rules={
						isEditDisabled
							? []
							: [
									{
										required: form.getFieldValue('isAdministrator'),
										type: 'email',
										message: translate.byKey('the_email_address_entered_is_not_valid'),
									},
								]
					}
				>
					<Input
						disabled={
							(form.getFieldValue('isAdministrator') && form.getFieldValue('administratorId')) || isEditDisabled
						}
						data-testid="v2-email"
						placeholder={translate.byKey('email_address')}
						autoComplete="off"
						aria-autocomplete="none"
					/>
				</Item>
			</Col>
			<Col span={24}>
				<Item
					shouldUpdate
					name="phone"
					dependencies={['isAdministrator']}
					label={translate.byKey('phone_number')}
					rules={
						isEditDisabled
							? []
							: [
									{
										required: form.getFieldValue('isAdministrator'),
										message: translate.byKey('admin_phone_required_validation_message'),
									},
								]
					}
				>
					<PhoneInput disabled={isEditDisabled} />
				</Item>
			</Col>

			<Col span={4}>
				<Item
					style={{ width: '85px' }}
					shouldUpdate
					name="pin"
					label={translate.byKey('pin_code')}
					rules={
						isEditDisabled
							? []
							: [
									{
										required: false,
										validator: validatePinInput,
									},
								]
					}
					normalize={filterNumbersFromString}
				>
					<Input.Password
						disabled={isEditDisabled}
						data-testid="v2-pin"
						maxLength={4}
						autoComplete="new-password"
						aria-autocomplete="none"
					/>
				</Item>
			</Col>
			<Col span={24} style={{ visibility: canEditAdministrators ? 'visible' : 'hidden' }}>
				<Item
					name="isAdministrator"
					label={translate.byKey('is_administrator_label')}
					valuePropName="checked"
					shouldUpdate
				>
					<FormCheckbox disabled={sameAdminAsLogin || isEditDisabled} />
				</Item>
			</Col>
			<Item name="administratorId" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Row>
	);
};
