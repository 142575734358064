import React, { useEffect } from 'react';
import { Dropdown, Modal } from 'antd';
import { ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useTheme } from 'emotion-theming';
import { Button, Page, PageHeader, Spin } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getAccessTransactionsKey, useAccessTransactions } from '~/hooks/data/transactions/useAccessTransactions';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getReadableName } from '~/screens/_shared/getReadableName';
import systemConfig from '~/screens/_shared/systemConfig';
import { SiteControllerFilterDropdown } from '~/features/reports/controller-filter/SiteControllerFilterDropdown/SiteControllerFilterDropdown';
import { useAllAccessTransactions } from '~/hooks/data/transactions/useAllAccessTransactions';
import { downloadDataAsFile } from '~/exporters';
import { SystemControllerFilterDropdown } from '~/features/reports/controller-filter/SystemControllerFilterDropdown/SystemControllerFilterDropdown';
import { SingleChoiceFilter } from '~/components/shared/filters/SingleChoiceFilter/SingleChoiceFilter';
import { formatHexValuesAsString } from '~/components/features/people/People.utils';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useSiteDeletedControllers } from '~/hooks/data/controllers/useSiteDeletedControllers';
import {
	defaultEventName,
	defaultEventType,
	defaultPageNumber,
	defaultPerson,
	formatCredentialHolderName,
	getAccessTransactionEventNameFilter,
	getAccessTransactionEventTypeFilter,
	defaultControllerId,
	personColumnRenderer,
	defaultEventSource,
	defaultSiteName,
} from '~/screens/Reports/AccessTransactions/AccessTransactionsPage.utils';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { useCurrentUser } from '~/hooks/features/useCurrentUser/useCurrentUser';
import { getSiteControllersKey, useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getSystemControllersKey, useSystemControllers } from '~/hooks/data/controllers/useSystemControllers';
import {
	DateTimeRangeFilter,
	getDateTimeRangeFilterValueLabel,
} from '~/components/shared/filters/DateTimeRangeFilter/DateTimeRangeFilter';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { generateSiteControllerEnabledFilterProps, generateSystemControllerEnabledFilterProps } from '../help';
import { getScrollHeightOptions } from '~/constants/Table';
import { EVENTS_VISIBLE_MONTHS_THRESHOLD } from '~/constants/transactions';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const DEFAULT_DATE_RANGE = {
	start: moment().startOf('week'),
	end: moment().endOf('week'),
};

export const AccessTransactionsPage = () => {
	const { translate } = useLocale();
	const { queryParams, setQueryParams, clearAllQueryParams } = useQueryParams();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId, name },
		},
	} = useCurrentSystemSite();
	const isSiteSelected = Boolean(siteId);
	const siteControllersKey =
		customerId && systemId && isSiteSelected
			? getSiteControllersKey({
					customerId,
					systemId,
					siteId,
				})
			: null;
	const { data: siteControllers } = useSiteControllers(siteControllersKey);
	const systemControllersKey =
		customerId && systemId
			? getSystemControllersKey({
					customerId,
					systemId,
				})
			: null;
	const { data: controllers } = useSystemControllers(systemControllersKey);
	const { data: deletedControllers } = useSiteDeletedControllers({
		customerId,
		systemId,
		siteId,
	});
	const siteAccessTransactionKey =
		customerId && systemId && isSiteSelected
			? getAccessTransactionsKey({
					customerId,
					systemId,
					pageSize: parseInt(queryParams?.pageSize) || DEFAULT_PAGE_SIZE,
					pageNumber: parseInt(queryParams?.pageNumber) || defaultPageNumber,
					siteId,
					eventName: queryParams?.eventName || defaultEventName,
					reasonCode: queryParams?.reasonCode || defaultEventType,
					controllerId: queryParams?.controllerId || defaultControllerId,
					accessPointName: queryParams?.eventSource || defaultEventSource,
					credentialHolderName: formatCredentialHolderName(queryParams?.person) || defaultPerson,
					startTime: queryParams?.startTime || DEFAULT_DATE_RANGE.start.toISOString(),
					endTime: queryParams?.endTime || DEFAULT_DATE_RANGE.end.toISOString(),
				})
			: null;
	const systemAccessTransactionKey =
		customerId && systemId
			? getAccessTransactionsKey({
					customerId,
					systemId,
					pageSize: parseInt(queryParams?.pageSize) || DEFAULT_PAGE_SIZE,
					pageNumber: parseInt(queryParams?.pageNumber) || defaultPageNumber,
					eventName: queryParams?.eventName || defaultEventName,
					reasonCode: queryParams?.reasonCode || defaultEventType,
					siteName: queryParams?.siteName || defaultSiteName,
					controllerId: queryParams?.controllerId,
					accessPointName: queryParams?.eventSource || defaultEventSource,
					credentialHolderName: formatCredentialHolderName(queryParams?.person) || defaultPerson,
					startTime: queryParams?.startTime || DEFAULT_DATE_RANGE.start.toISOString(),
					endTime: queryParams?.endTime || DEFAULT_DATE_RANGE.end.toISOString(),
				})
			: null;
	const {
		data: transactions,
		isLoading: isLoadingTransactions,
		isValidating: isValidatingTransactions,
		mutate: getNewTransactions,
		error: loadTransactionsError,
	} = useAccessTransactions(isSiteSelected ? siteAccessTransactionKey : systemAccessTransactionKey);
	const { isLoading: isLoadingAllTransactions, fetchAllAccessTransactions } = useAllAccessTransactions();
	const theme = useTheme();
	const { currentUser } = useCurrentUser();

	const version = systemConfig.devVersion;
	const isLoadingOrValidatingTransactions = isLoadingTransactions || isValidatingTransactions;
	const eventNameFilters = getAccessTransactionEventNameFilter();
	const eventTypeFilters = getAccessTransactionEventTypeFilter();

	useEffect(() => {
		const clearSpecificQueryParamsOnSiteOrSystemChange = () => {
			clearAllQueryParams();
		};

		return clearSpecificQueryParamsOnSiteOrSystemChange;
	}, [siteId, systemId]);

	const onTableChange = (pagination, filters) => {
		const isPageSizeChanged = queryParams?.pageSize
			? pagination.pageSize !== queryParams.pageSize
			: pagination.pageSize !== DEFAULT_PAGE_SIZE;

		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: isPageSizeChanged ? 1 : pagination.current,
			eventName: filters?.['eventName']?.[0] || defaultEventName, // the backend only allows one at the moment
			reasonCode: filters?.['reasonCode']?.[0] || defaultEventType,
			siteName: filters?.['siteName']?.[0] || defaultSiteName,
			controllerId: filters?.['controllerName']?.[0] || defaultControllerId,
			eventSource: filters?.['accessPointName']?.[0] || defaultEventSource,
			person: filters?.['credentialHolderName']?.[0] || defaultPerson,
			startTime: filters?.['dateTime']?.[0]?.['start']?.toISOString() || DEFAULT_DATE_RANGE.start.toISOString(),
			endTime: filters?.['dateTime']?.[0]?.['end']?.toISOString() || DEFAULT_DATE_RANGE.end.toISOString(),
		});
	};

	useEffect(() => {
		const eventsOlderThanTreshold = moment().subtract(EVENTS_VISIBLE_MONTHS_THRESHOLD, 'months');

		if (moment(queryParams.startTime).isBefore(eventsOlderThanTreshold)) {
			const newStartTime = eventsOlderThanTreshold.add(1, 'day');
			const newEndTime = moment(queryParams.endTime).isBefore(eventsOlderThanTreshold)
				? moment()
				: moment(queryParams.endTime);

			Modal.warning({
				title: translate.byKey('operation_impossible'),
				content: translate.byKeyFormatted('no_events_visible_before_months', [
					EVENTS_VISIBLE_MONTHS_THRESHOLD,
					`${newStartTime.format('L LT')} - ${newEndTime.format('L LT')}`,
				]),
			});

			setQueryParams({
				startTime: newStartTime.toISOString(),
				endTime: newEndTime.toISOString(),
			});
		}
	}, [queryParams.startTime, queryParams.endTime]);

	const onDataReload = () => {
		getNewTransactions();
	};

	const onExport = async (type) => {
		const { eventName, reasonCode, controllerId, eventSource, person, startTime, endTime } = queryParams;

		const allAccessTransactions = await fetchAllAccessTransactions({
			siteId,
			eventName,
			reasonCode,
			controllerId,
			eventSource,
			credentialHolderName: formatCredentialHolderName(person),
			startTime: startTime ? moment(startTime) : DEFAULT_DATE_RANGE.start,
			endTime: endTime ? moment(endTime) : DEFAULT_DATE_RANGE.end,
		});

		const isSiteNotSelectedOrTypeIsCSV = !isSiteSelected || type === 'csv';

		downloadDataAsFile({
			type,
			theme,
			title: translate.byKey('access_transaction_report'),
			list: allAccessTransactions.map((transaction) => {
				return transaction.eventSource ? transaction : { ...transaction, eventSource: 'blank' };
			}),
			columns: [
				{
					dataKey: 'eventName',
					header: translate.byKey('event_name'),
					formatter: (value) => translate.byKey(value.toLocaleLowerCase()),
				},
				{
					dataKey: 'reasonCode',
					header: translate.byKey('event_type'),
					formatter: (value) => (value ? translate.byKey(value.toLocaleLowerCase()) : ''),
				},
				{
					dataKey: 'dateTime',
					header: translate.byKey('date_time'),
					formatter: (value) => (value ? moment(value).format('L LT') : ''),
				},
				...(isSiteNotSelectedOrTypeIsCSV
					? [
							{
								dataKey: 'siteName',
								header: translate.byKey('site'),
							},
						]
					: []),
				{
					dataKey: 'controllerName',
					header: translate.byKey(version >= 2 ? 'connected_device' : 'controller'),
					formatter: (value) => getReadableName(value),
				},
				{
					dataKey: 'accessPointName',
					header: translate.byKey('event_source'),
				},
				{
					dataKey: 'credentialHolderName',
					header: translate.byKey('person'),
					formatter: personColumnRenderer,
				},
				{
					dataKey: 'cardNumber',
					header: translate.byKey(version >= 2 ? 'credential' : 'card'),
					formatter: formatHexValuesAsString,
				},
			],
			siteName: name,
			currentUser,
			totalNumberOfRecords: transactions?.totalRecordCount,
		});
	};

	return (
		<Page>
			<PageHeader title={translate.byKey('access_transactions')} icon="reports">
				<Button
					disabled={isLoadingOrValidatingTransactions}
					type="primary"
					icon={<ReloadOutlined />}
					onClick={onDataReload}
				>
					{translate.byKey('refresh')}
				</Button>
				<Dropdown
					disabled={isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError}
					menu={{
						items: [
							{
								key: 'exportButtonPdf',
								disabled: isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError,
								onClick: () => onExport('pdf'),
								label: translate.byKey('pdf'),
							},
							{
								key: 'exportButtonCsv',
								disabled: isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError,
								onClick: () => onExport('csv'),
								label: translate.byKey('csv'),
							},
						],
					}}
				>
					<Button loading={isLoadingAllTransactions} icon={<ExportOutlined />} type="primary">
						{translate.byKey('export')}
					</Button>
				</Dropdown>
			</PageHeader>

			<TableWithFiltersSection
				rowKey="eventLogId"
				dataSource={transactions?.events}
				onChange={onTableChange}
				loading={{
					spinning: isLoadingOrValidatingTransactions,
					indicator: <Spin active={true} />,
				}}
				columns={[
					{
						dataIndex: 'eventName',
						title: translate.byKey('event_name'),
						render: (value) => translate.byKey(value.toLocaleLowerCase()),
						filterDropdown: (props) => (
							<SingleChoiceFilter antdFilterProps={props} options={eventNameFilters} showSearch={false} />
						),
						filteredValue: queryParams?.eventName ? [queryParams?.eventName] : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => eventNameFilters?.find((x) => x.value === value)?.label ?? value,
							clearable: value !== defaultEventName,
							tooltip:
								Boolean(defaultEventName) &&
								translate.byKeyFormatted('reset_to', {
									value: eventNameFilters?.find((x) => x.value === defaultEventName)?.label ?? defaultEventName,
								}),
						}),
					},
					{
						dataIndex: 'reasonCode',
						title: translate.byKey('event_type'),
						render: (value) => (value ? translate.byKey(value.toLocaleLowerCase()) : ''),
						filterDropdown: (props) => (
							<SingleChoiceFilter antdFilterProps={props} options={eventTypeFilters} minWidth="240px" />
						),
						filteredValue: queryParams?.reasonCode ? [queryParams?.reasonCode] : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => eventTypeFilters?.find((x) => x.value === value)?.label ?? value,
							clearable: value !== defaultEventType,
							tooltip:
								Boolean(defaultEventType) &&
								translate.byKeyFormatted('reset_to', {
									value: eventTypeFilters?.find((x) => x.value === defaultEventType)?.label,
								}),
						}),
					},
					{
						dataIndex: 'dateTime',
						title: translate.byKey('date_time'),
						render: (value) => (value ? moment(value).format('L LT') : ''),
						filterDropdown: (props) => (
							<DateTimeRangeFilter antdFilterProps={props} defaultDateTimes={[DEFAULT_DATE_RANGE]} />
						),
						filteredValue: [
							{
								start: queryParams?.startTime ? moment(queryParams.startTime) : DEFAULT_DATE_RANGE.start,
								end: queryParams?.endTime ? moment(queryParams.endTime) : DEFAULT_DATE_RANGE.end,
							},
						],
						enabledFilterProps: (value) => ({
							getValueLabel: () => getDateTimeRangeFilterValueLabel(value),
							clearable:
								!moment(value?.start).isSame(DEFAULT_DATE_RANGE.start) ||
								!moment(value?.end).isSame(DEFAULT_DATE_RANGE.end),
							tooltip: translate.byKeyFormatted('reset_to', {
								value: translate.byKey('this_week'),
							}),
						}),
					},
					...(!isSiteSelected
						? [
								{
									dataIndex: 'siteName',
									title: translate.byKey('site'),
									filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
									filteredValue: queryParams?.siteName ? [queryParams.siteName] : null,
									filterIcon: <SearchFilterIcon />,
								},
							]
						: []),
					{
						dataIndex: 'controllerName',
						title: translate.byKey(version >= 2 ? 'connected_device' : 'controller'),
						render: (value) => getReadableName(value),
						filterDropdown: isSiteSelected
							? (props) => <SiteControllerFilterDropdown antdFilterProps={props} allowDeselect />
							: (props) => <SystemControllerFilterDropdown antdFilterProps={props} />,
						filteredValue: queryParams?.controllerId ? [queryParams.controllerId] : null,
						enabledFilterProps: (value) =>
							isSiteSelected
								? {
										...generateSiteControllerEnabledFilterProps(
											siteControllers,
											deletedControllers,
											value,
											defaultControllerId
										),
									}
								: { ...generateSystemControllerEnabledFilterProps(controllers, value, defaultControllerId) },
					},
					{
						title: translate.byKey('event_source'),
						dataIndex: 'accessPointName',
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: queryParams?.eventSource ? [queryParams.eventSource] : null,
						filterIcon: <SearchFilterIcon />,
					},
					{
						dataIndex: 'credentialHolderName',
						title: translate.byKey('person'),
						render: personColumnRenderer,
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: queryParams?.person ? [queryParams.person] : null,
						filterIcon: <SearchFilterIcon />,
					},
					{
						dataIndex: 'cardNumber',
						title: translate.byKey(version >= 2 ? 'credential' : 'card'),
						render: formatHexValuesAsString,
					},
				]}
				pagination={{
					showSizeChanger: true,
					showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
					position: ['bottomCenter'],
					pageSize: queryParams?.pageSize || DEFAULT_PAGE_SIZE,
					total: transactions?.totalRecordCount,
					current: parseInt(queryParams?.pageNumber) || defaultPageNumber,
				}}
				scroll={getScrollHeightOptions()}
			/>
		</Page>
	);
};
