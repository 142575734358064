import { message } from 'antd';

import { useLocale } from '~/screens/_shared/AppLocale';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { getPortalsKey, usePortals } from '~/hooks/data/portals/usePortals';
import { getAssetKey, useAsset } from '~/hooks/data/assets/useAsset';
import { getUpdatePortalKey, useUpdatePortal } from '~/hooks/data/portals/useUpdatePortal';
import { getUpdateAssetWithoutIdKey, useUpdateAsset } from '~/hooks/data/assets/useUpdateAsset';
import { AssetTypes } from '~/constants/AssetTypes';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const useProcessDoors = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();

	const {
		data: portalAssets,
		isLoading: isLoadingAssets,
		mutate: reloadDoorsAssets,
	} = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			pageSize: 1000,
			sortBy: 'offline_reference_id',
			sortDirection: 'DESCENDING',
			assetType: AssetTypes.BASIC_ASSET,
		})
	);
	const {
		data: { portals } = { portals: [] },
		isLoading: isLoadingPortals,
		mutate: reloadPortals,
	} = usePortals(
		getPortalsKey({
			customerId,
			systemId,
		})
	);
	const { trigger: getAsset, isMutating: isLoadingAsset } = useAsset(
		getAssetKey({
			customerId,
			systemId,
			siteId,
		})
	);
	const { trigger: updatePortal, isMutating: isUpdatingPortal } = useUpdatePortal(
		getUpdatePortalKey({
			customerId,
			systemId,
			siteId,
		})
	);
	const { trigger: updateAsset, isMutating: isUpdatingAsset } = useUpdateAsset(
		getUpdateAssetWithoutIdKey({
			customerId,
			systemId,
			siteId,
		})
	);

	const offlinePortals = portals?.filter((portal) =>
		['pulse_offline', 'aperio_offline'].includes(portal.configuration?.additionalSettings?.doorType)
	);

	const processDoorsOnDoorGroupDelete = async (assets, doorGroupId) => {
		return Promise.all(
			assets.map(async (doorAssetId) => {
				const offlinePortal = offlinePortals.find(
					(offlinePortal) => offlinePortal.configuration?.additionalSettings?.doorAssetId === doorAssetId
				);

				const additionalParents = offlinePortal?.configuration?.additionalSettings?.additionalParents
					? JSON.parse(offlinePortal?.configuration?.additionalSettings?.additionalParents)
					: [];

				const portalAsset = portalAssets.assets.find((asset) => asset.assetId === doorAssetId);

				const newAdditionalParents = additionalParents.filter(({ key }) => key.assetId !== doorGroupId);

				try {
					const newPortalObject = {
						...offlinePortal,
						configuration: {
							...offlinePortal?.configuration,
							additionalSettings: {
								...offlinePortal?.configuration?.additionalSettings,
								additionalParents: JSON.stringify(newAdditionalParents),
							},
						},
					};

					const newPortalAssetObject = {
						assetId: portalAsset.assetId,
						name: portalAsset.name,
						description: portalAsset.description,
						type: portalAsset.type,
						version: portalAsset.version,
						additionalParents: newAdditionalParents.map((additionalParent) => ({
							key: {
								assetId: additionalParent.key.assetId,
								systemId: additionalParent.key.systemId,
								siteId: additionalParent.key.siteId,
							},
						})),
					};

					return await Promise.all([updatePortal(newPortalObject), updateAsset(newPortalAssetObject)]);
				} catch (e) {
					console.error(e);
					message.error(translate.byKey('failed_to_add_door_to_door_group'));
				}
			})
		);
	};

	const processDoors = async (assets, doorGroupId) => {
		return Promise.all(
			assets.map(async (doorAssetId) => {
				const [createdAsset] = await Promise.all([getAsset({ assetId: doorGroupId })]);

				const offlinePortal = offlinePortals.find(
					(offlinePortal) => offlinePortal.configuration?.additionalSettings?.doorAssetId === doorAssetId
				);

				const additionalParents = offlinePortal?.configuration?.additionalSettings?.additionalParents
					? JSON.parse(offlinePortal?.configuration?.additionalSettings?.additionalParents)
					: [];

				const portalAsset = portalAssets.assets.find((asset) => asset.assetId === doorAssetId);

				const isDoorGroupPartOfAdditionalParents = additionalParents.some(({ key }) => key.assetId === doorGroupId);

				const getDoorGroupAdditionalParent = (asset = undefined) => {
					asset = asset ? asset : createdAsset;
					return {
						key: {
							assetId: asset.assetId,
							systemId: asset.systemId,
							siteId: asset.siteId,
						},
					};
				};

				if (!isDoorGroupPartOfAdditionalParents && offlinePortal) {
					try {
						additionalParents.push(getDoorGroupAdditionalParent());

						const newPortalObject = {
							...offlinePortal,
							configuration: {
								...offlinePortal?.configuration,
								additionalSettings: {
									...offlinePortal?.configuration?.additionalSettings,
									additionalParents: JSON.stringify(additionalParents),
								},
							},
						};

						const newPortalAssetObject = {
							assetId: portalAsset.assetId,
							name: portalAsset.name,
							description: portalAsset.description,
							type: portalAsset.type,
							version: portalAsset.version,
							additionalParents: [
								...(portalAsset.additionalParents
									? portalAsset.additionalParents.map((parentAsset) => getDoorGroupAdditionalParent(parentAsset))
									: []),
								getDoorGroupAdditionalParent(),
							],
						};

						return await Promise.all([updatePortal(newPortalObject), updateAsset(newPortalAssetObject)]);
					} catch (e) {
						console.error(e);
						message.error(translate.byKey('failed_to_add_door_to_door_group'));
					}
				}
			})
		);
	};

	return {
		processDoors,
		processDoorsOnDoorGroupDelete,
		isProcessingDoors: isLoadingAssets || isLoadingPortals || isLoadingAsset || isUpdatingPortal || isUpdatingAsset,
		reloadDoorsAssets,
		reloadPortals,
	};
};
