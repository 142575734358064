import { PlusCircleOutlined, ReloadOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { message, Dropdown, Typography, Space } from 'antd';
import React from 'react';

import { Flex, Button, Icon } from '~/components';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useLocale } from '~/screens/_shared/AppLocale';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { EmergencyLockdownButtons } from '~/components/features/doors/EmergencyLockdownButtons';
import HelpForm from '~/screens/_shared/ContextualHelp/HelpForm';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { useModals } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { MODAL_TYPES } from '../layouts/MainLayout/ModalsContainer/constants';

const { Title } = Typography;

export function TypedAddButton({ screen, hasSiteDependency, onAddNewClick, items }) {
	const { translate } = useLocale();
	const theme = useTheme();
	const { data: user } = useUserAuthData();
	const {
		data: {
			site: { siteId },
		},
	} = useCurrentSystemSite();

	return (
		hasActionPermissions(user, screen, userConstants.actions.CREATE) && (
			<Dropdown
				menu={{
					onClick: (event) => {
						if (hasSiteDependency && !(siteId?.length > 0)) {
							message.warn(translate.byKey('please_select_a_site_before_adding_a_new_item'));
							return;
						}

						onAddNewClick(event.key);
					},
					items: items,
				}}
				css={css`
					color: ${theme.colors.white};
				`}
			>
				<Button type="primary" icon={<PlusCircleOutlined />}>
					{translate.byKey('add_new')}
				</Button>
			</Dropdown>
		)
	);
}

export function ReloadButton({ onReload, ...rest }) {
	const { translate } = useLocale();

	return (
		<Button
			{...rest}
			type="primary"
			onClick={() => {
				onReload();
			}}
			icon={<ReloadOutlined />}
		>
			{translate.byKey('refresh')}
		</Button>
	);
}

export function AddButton({ screen, hasSiteDependency, onAddNewClick, ...rest }) {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const {
		data: {
			site: { siteId },
		},
	} = useCurrentSystemSite();

	return (
		hasActionPermissions(user, screen, userConstants.actions.CREATE) && (
			<Button
				type="primary"
				onClick={() => {
					if (hasSiteDependency && !(siteId?.length > 0)) {
						message.warn(translate.byKey('please_select_a_site_before_adding_a_new_item'));
						return;
					}

					onAddNewClick();
				}}
				icon={<PlusCircleOutlined />}
				{...rest}
			>
				{translate.byKey('add_new')}
			</Button>
		)
	);
}

export const PageHeader = ({
	icon,
	title,
	children,
	suffixProps,
	showHelp = true,
	hideEmergencyLockdownButtons = false,
}) => {
	const { toggleModal } = useModals();

	const onHelpClick = () => {
		toggleModal(MODAL_TYPES.HELP_FORM);
	};

	const hideHelpForm = () => {
		toggleModal();
	};

	return (
		<>
			<Flex alignItems="center" justifyContent="space-between" padding="16px 0">
				<Flex center>
					<Icon mr={3} color="gray.600" size="26px" name={icon} />
					<Title level={2} css={pageTitleStyles}>
						{title}
					</Title>
				</Flex>

				<Space align="center" size="large" {...suffixProps}>
					{!hideEmergencyLockdownButtons ? <EmergencyLockdownButtons /> : null}
					{children}
					{showHelp ? (
						<Button type="primary" onClick={onHelpClick} icon={<QuestionCircleFilled />} css={helpButtonStyles} />
					) : null}
				</Space>
			</Flex>
			{showHelp ? <HelpForm loading={false} onCancel={hideHelpForm} /> : null}
		</>
	);
};

const pageTitleStyles = (theme) =>
	css({
		color: `${theme.colors.gray[600]} !important`,
		fontSize: '26px !important',
		marginBottom: '0 !important',
	});

const helpButtonStyles = css({
	padding: 0,
	marginLeft: 0,
});
