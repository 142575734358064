import React from 'react';

export const viewBox = '0 0 24 24';

const path = (
	<g fill="currentColor">
		<path
			stroke="currentColor"
			stroke-linecap="round"
			stroke-width="2"
			d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3a2.5 2.5 0 1 1 2-4.5M19.5 17h.5c.6 0 1-.4 1-1a3 3 0 0 0-3-3h-1m0-3a2.5 2.5 0 1 0-2-4.5m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3c0 .6-.4 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
		/>
	</g>
);

const accessGroups = {
	path,
	viewBox,
};

export default accessGroups;
