import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { useLocale } from '~/screens/_shared/AppLocale';
import useGetAccessGroups from '~/screens/AccessGroups/hooks/useGetAccessGroups';
import { Input, Item } from '~/components';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { useGetEnrichedPortal } from '~/screens/AccessGroups/hooks/useGetEnrichedPortal';
import mappers from '~/screens/_shared/mappers';
import LocalStorageService from '~/services/localStorage';
import { formatAssetName } from '~/utils/asset/asset';
import { useSiteInformation } from '~/hooks/features/useSiteInformation/useSiteInformation';
import { OnlineAccessGroupsPerUser } from '~/hooks/features/useSiteInformation/useSiteInformation.utils';
import { filterTextByPartialText } from '~/utils/filter';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

// TODO, this should probably go into a file where all the other product specific constants are kept.
const MAXIMUM_OFFLINE_ASSETS = 70;

const isDeletedAsset = (asset) => asset.endsWith('__deleted');

export const AccessGroupsInputs = ({ form, currentPerson, isEditDisabled }) => {
	const { translate } = useLocale();
	const {
		data: { site },
	} = useCurrentSystemSite();
	const [onlineAccessGroups, setOnlineAccessGroups] = useState([]);
	const [offlineAccessGroups, setOfflineAccessGroups] = useState([]);
	const [doorsForExtraAccess, setDoorsForExtraAccess] = useState([]);
	const [getAccessGroups, isBusy] = useGetAccessGroups();
	const [inputTypes, setInputTypes] = useState('');
	const [getPortals] = useGetEnrichedPortal(mappers.portal);
	const [offlineDoorLimitMessage, setOfflineDoorLimitMessage] = useState('');
	const { onlineAccessGroupsPerUser } = useSiteInformation();
	const [selectedOnlineAccessGroups, setSelectedOnlineAccessGroups] = useState([]);

	const loadOnlineAccessGroups = () => {
		getAccessGroups('ONLINE', site)
			.then(([accessProfiles]) => {
				setOnlineAccessGroups(accessProfiles);
			})
			.catch(console.error);
	};

	const loadOfflineAccessGroups = () => {
		getAccessGroups('OFFLINE', site)
			.then(([accessProfiles]) => {
				accessProfiles = accessProfiles.map((profile) => {
					const allAssets = [
						...profile.accessPolicies
							.reduce((assets, policy) => {
								const policyAssets = policy.assets || [];
								for (const asset of policyAssets) {
									if (!assets.has(asset.assetId)) {
										assets.set(asset.assetId, asset);
									}
								}

								return assets;
							}, new Map())
							.values(),
					].map((asset) => {
						if (asset.type === 'BASIC_ASSET' || asset.type === 'ACCESS_AREA') {
							asset.name = formatAssetName(asset.name);
						}

						return asset;
					});

					profile.allAssets = allAssets;
					const isGroup = (asset) => (asset.type || '').toLocaleLowerCase().endsWith('group');
					const byName = (a, b) => a.localeCompare(b);
					const doorGroups = allAssets
						.filter((asset) => isGroup(asset) && !isDeletedAsset(asset.name))
						.map((asset) => asset.name.trim())
						.sort(byName)
						.join(', ');
					const doors = allAssets
						.filter((asset) => !isGroup(asset) && !isDeletedAsset(asset.name))
						.map((asset) => asset.name.trim())
						.sort(byName)
						.join(', ');

					let doorGroupsTitle = doorGroups ? translate.byKeyFormatted('door_groups_title_grouping', [doorGroups]) : '';
					let doorsTitle = doors ? translate.byKeyFormatted('door_title_grouping', [doors]) : '';

					profile.allAssetsTitle = [doorGroupsTitle, doorsTitle].filter((val) => !!val).join('\n');

					return profile;
				});

				setOfflineAccessGroups(accessProfiles);
				LocalStorageService.addItem('offlineAccessProfiles', JSON.stringify(accessProfiles));

				if (form.getFieldValue('offlineAccessProfileId')) {
					const accessProfileId = form.getFieldValue('offlineAccessProfileId');
					const accessProfile = accessProfiles.find(
						(accessProfile) => accessProfile.accessProfileId === accessProfileId
					);

					if (accessProfile) {
						form.setFieldsValue({ extraOfflineAccessProfile: accessProfile });
						setDoorsForExtraAccess([]);
					}
				}
			})
			.catch(console.error);
	};

	const loadPortals = () => {
		getPortals()
			.then(({ offlinePortals }) => {
				setDoorsForExtraAccess(offlinePortals);
			})
			.catch(console.error);
	};

	useEffect(() => {
		loadOnlineAccessGroups();

		loadOfflineAccessGroups();

		loadPortals();

		const badAccessProfiles = form.getFieldValue('accessProfiles');

		if (badAccessProfiles && badAccessProfiles.length > 0) {
			getAccessGroups('ALL', site)
				.then((data) => {
					const accessProfiles = form.getFieldValue('accessProfiles') || [];
					const siteAccessGroups = data.filter((group) =>
						accessProfiles.find((profile) => profile.accessProfileId === group.accessProfileId)
					);
					let groups;
					if (accessProfiles.length === 1) {
						let online = form.getFieldValue('onlineAccessProfileId');
						if (!online) {
							groups = [...onlineAccessGroups, ...siteAccessGroups];
							form.setFieldsValue({ onlineAccessProfileId: siteAccessGroups[0].accessProfileId });
							setOnlineAccessGroups(groups);
							setInputTypes('onlineDeleted');
						} else {
							groups = [...offlineAccessGroups, ...siteAccessGroups];
							form.setFieldsValue({ offlineAccessProfileId: siteAccessGroups[0].accessProfileId });
							setOfflineAccessGroups(groups);
							setInputTypes('offlineDeleted');
						}
					} else if (accessProfiles.length === 2) {
						groups = [...onlineAccessGroups, ...offlineAccessGroups, ...siteAccessGroups];
						form.setFieldsValue({ onlineAccessProfileId: siteAccessGroups[0].accessProfileId });
						form.setFieldsValue({ offlineAccessProfileId: siteAccessGroups[1].accessProfileId });
						setInputTypes('allDeleted');
					}

					form.setFieldsValue({ siteAccessGroups: groups });
				})
				.catch((error) => console.log(error));
		}
	}, [site, currentPerson]);

	const offlineAccessGroupChanged = (accessProfileIds) => {
		const accessProfile = offlineAccessGroups.find((accessProfile) =>
			accessProfileIds.some((id) => id === accessProfile.accessProfileId)
		);

		if (accessProfile) {
			setDoorsForExtraAccess(
				doorsForExtraAccess.filter((door) =>
					accessProfile.allAssets.some((asset) => asset.assetId !== door.doorAssetId)
				)
			);
			form.setFieldsValue({ extraOfflineAccessProfile: accessProfile });
		}
	};

	const renderInputLabels = (label) => {
		switch (label) {
			case 'online':
				if (inputTypes === 'onlineDeleted') {
					return 'access_group';
				}
				return 'online_access_group_v2';
			case 'offline':
				if (inputTypes === 'offlineDeleted') {
					return 'access_group';
				}
				return 'offline_access_group_v2';
			case 'allDeleted':
				return 'access_group';
			default: {
				return '';
			}
		}
	};

	const offlineAccessGroupFilterOption = (input, option) => {
		return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	return (
		<Row gutter={[12, 12]}>
			{selectedOnlineAccessGroups.length > onlineAccessGroupsPerUser ? (
				<Col
					style={{
						display: 'flex',
						backgroundColor: '#00a0d0',
						borderRadius: '5px',
						margin: '10px 0',
						width: '100%',
						padding: '10px',
					}}
					span={24}
				>
					<WarningOutlined style={{ fontSize: 22, color: '#fff', display: 'inline-table' }} />
					<div
						style={{
							marginLeft: '8px',
							color: '#fff',
						}}
					>
						{onlineAccessGroupsPerUser === OnlineAccessGroupsPerUser.level1
							? translate.byKeyFormatted('online_access_group_hardware_restriction_info', {
									limit: onlineAccessGroupsPerUser,
							  })
							: translate.byKeyFormatted('online_access_group_limit_reached', { limit: onlineAccessGroupsPerUser })}
					</div>
				</Col>
			) : null}
			<Col span={24}>
				<Item
					name="onlineAccessProfileId"
					label={translate.byKey(renderInputLabels('online'))}
					shouldUpdate
					rules={[
						({ getFieldValue }) => ({
							validator: () => {
								const onlineAccessGroupsCount = getFieldValue('onlineAccessProfileId')?.length || 0;

								return onlineAccessGroupsCount <= onlineAccessGroupsPerUser ? Promise.resolve() : Promise.reject('');
							},
						}),
					]}
				>
					<Select
						showArrow
						disabled={isEditDisabled}
						placeholder={translate.byKey('select_an_access_group')}
						allowClear
						loading={isBusy}
						mode="multiple"
						dropdownMatchSelectWidth={400}
						dropdownStyle={{ minWidth: 'unset' }}
						onChange={(value) => {
							setSelectedOnlineAccessGroups(value);
						}}
						filterOption={(inputValue, { children }) => filterTextByPartialText(children.props.children, inputValue)}
					>
						{onlineAccessGroups.sort(createSorter('name')).map((item, index) => {
							const onlineAccessGroupDoors = item.accessPolicies
								.flatMap((accessPolicy) => accessPolicy.assets.flatMap((asset) => formatAssetName(asset.name)))
								.filter((asset) => !isDeletedAsset(asset));
							const title = `${translate.byKey('doors')}: ${onlineAccessGroupDoors.join(', ')}`;

							return (
								<Select.Option key={index} value={item.accessProfileId}>
									<Tooltip title={title}>{item.name}</Tooltip>
								</Select.Option>
							);
						})}
					</Select>
				</Item>
			</Col>

			<Col span={24}>
				<Item
					name="offlineAccessProfileId"
					help={offlineDoorLimitMessage || undefined}
					rules={
						isEditDisabled
							? []
							: [
									{
										validator(rule, value) {
											value = value || [];
											const accessProfiles = offlineAccessGroups.filter(
												(profile) => Array.isArray(profile.allAssets) && value.includes(profile.accessProfileId)
											);

											const allAssets = accessProfiles.reduce((assets, profile) => {
												assets = assets || new Set();
												profile.allAssets.forEach((asset) => {
													if (!assets.has(asset.assetId)) {
														assets.add(asset.assetId);
													}
												});
												return assets;
											}, new Set());

											const exceededMaxAssets = allAssets.size > MAXIMUM_OFFLINE_ASSETS;

											if (exceededMaxAssets) {
												setOfflineDoorLimitMessage(undefined);
												return Promise.reject(
													translate.byKeyFormatted('access_group_validation_message', {
														selectedAssetCount: allAssets.size,
														maxAssetCount: MAXIMUM_OFFLINE_ASSETS,
													})
												);
											}

											setOfflineDoorLimitMessage(
												translate.byKeyFormatted('offline_door_count_message', [allAssets.size, MAXIMUM_OFFLINE_ASSETS])
											);

											return Promise.resolve();
										},
									},
							  ]
					}
					label={translate.byKey(renderInputLabels('offline'))}
					shouldUpdate
				>
					<Select
						disabled={isEditDisabled}
						showArrow
						allowClear
						optionFilterProp="children"
						mode="multiple"
						dropdownMatchSelectWidth={400}
						dropdownStyle={{ minWidth: 'unset' }}
						loading={isBusy}
						optionLabelProp="label"
						placeholder={translate.byKey('select_an_access_group')}
						onChange={offlineAccessGroupChanged}
						filterOption={offlineAccessGroupFilterOption}
						filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
					>
						{offlineAccessGroups.sort(createSorter('name')).map((item, index) => {
							return (
								<Select.Option key={item.accessProfileId + index} value={item.accessProfileId} label={item.name}>
									<Tooltip key={'tooltip' + item.accessProfileId + index} title={item.allAssetsTitle}>
										<div>
											<span style={{ float: 'left' }} aria-label={item.name}>
												{item.name}
											</span>
										</div>
									</Tooltip>
								</Select.Option>
							);
						})}
					</Select>
				</Item>
			</Col>

			<Item name="siteAccessGroups" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>

			<Item name="extraPortalName" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>

			<Item name="extraOfflineAccessProfile" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Row>
	);
};
