import React from 'react';

const viewBox = '0 0 512.002 512.002';

const path = (
	<g fill="currentColor">
		<circle cx={364} cy={140.062} r={32} />
		<path d="M506.478 165.937c-10.68-27.194-30.264-66.431-62.915-98.927-32.535-32.384-71.356-51.408-98.194-61.666-29.464-11.261-62.945-4.163-85.295 18.082l-78.538 78.17c-23.281 23.171-29.991 58.825-16.698 88.72a369.522 369.522 0 0013.395 27.103L5.858 389.793A20 20 0 000 403.936v88c0 11.046 8.954 20 20 20h88c11.046 0 20-8.954 20-20v-36l36-.001c11.046 0 20-8.954 20-20v-35.999h36c11.046 0 20-8.954 20-20s-8.954-20-20-20h-56c-11.046 0-20 8.954-20 20v35.999l-36 .001c-11.046 0-20 8.954-20 20v36H40V412.22l177.355-177.354a20 20 0 002.958-24.517c-6.931-11.424-13.298-23.632-18.923-36.285-6.599-14.841-3.237-32.57 8.366-44.119l78.537-78.169c11.213-11.159 28.011-14.718 42.798-9.068 23.222 8.876 56.69 25.214 84.256 52.652 27.735 27.604 44.62 61.567 53.9 85.197a39.716 39.716 0 01-8.965 42.687l-79.486 79.114c-11.575 11.519-28.851 14.887-44.016 8.58-12.507-5.202-24.62-11.382-36-18.367-9.413-5.778-21.729-2.83-27.507 6.584-5.778 9.414-2.831 21.73 6.583 27.508 13.152 8.072 27.136 15.207 41.562 21.207 30.142 12.539 64.525 5.8 87.595-17.161l79.486-79.113c22.545-22.439 29.602-56.062 17.979-85.659z" />
	</g>
);

const key = {
	path,
	viewBox,
};

export default key;
