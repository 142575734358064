import { usePostEmeaSite, postEmeaSiteKey } from '~/hooks/data/site-settings/usePostEmeaSite';
import { useTreeSelectorData } from '~/components/features/site-selection/hooks/useTreeSelectorData';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const useCreateEmeaSite = () => {
	const {
		data: {
			system: { endCustomerId, businessEntityId, systemId },
		},
	} = useCurrentSystemSite();
	const { mutate: mutateTreeSelectorData } = useTreeSelectorData();

	const { trigger: createSiteAction, ...rest } = usePostEmeaSite(
		postEmeaSiteKey({
			businessEntityId,
			endCustomerId,
			systemId,
		})
	);

	const trigger = async (arg) => {
		await createSiteAction({ ...arg });
		mutateTreeSelectorData();
	};

	return {
		...rest,
		trigger,
	};
};
