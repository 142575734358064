const assetTypes = {
	INNER_ASSET: 'Inner Zone',
	OUTER_ASSET: 'Outer Zone',
};

const generateMatchAssetTypeRegex = (assetType) => new RegExp(` ${assetType}$`);

export const formatAssetName = (name) => {
	if (!name) return '';

	for (const asset in assetTypes) {
		if (name.endsWith(assetTypes[asset])) {
			return name.replace(generateMatchAssetTypeRegex(assetTypes[asset]), '');
		}
	}
	return name;
};
