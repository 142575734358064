import { translator as translate } from '~/screens/_shared/AppLocale';

const generalHelp = () => `
<p><strong><u> ${translate.byKey('common_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('site_selection')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'you_can_switch_between_sites_by_changing_the_drop_down_on_the_top_left_of_the_page_if_all_sites_is_selected_then_on_some_pages_it_may_show_data_from_every_site_on_the_system'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('logout')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'logout_will_exit_the_system__the_system_will_also_automatically_log_out_based_on_the_session_time_limit_'
)} </p>
<p><strong><u> ${translate.byKey('page_specific_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('general')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'controllers_manage_and_control_all_access_and_authentication_to_a_site'
)} </p>
<p style="padding-left: 30px;"> ${translate.byKey('displays_controller_name_serial_number_and_mac_address')} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('view_detail')} <br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_view_a_controllers_detail_click_on_the_controllers_name_in_the_table_list'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} <br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'typing_in_the_search_field_will_filter_through_the_current_table_for_similar_values_from_what_was_typed'
)} </p>

`;

export default generalHelp;
