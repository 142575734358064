import { usePutEmeaSite, putEmeaSiteKey } from '~/hooks/data/site-settings/usePutEmeaSite';
import { useTreeSelectorData } from '~/components/features/site-selection/hooks/useTreeSelectorData';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const useUpdateEmeaSite = () => {
	const {
		data: { system, site },
		mutate: mutateSystemSite,
	} = useCurrentSystemSite();
	const { mutate: mutateTreeSelectorData } = useTreeSelectorData();

	const { endCustomerId, businessEntityId, systemId } = system;

	const { trigger: updateSiteAction, ...rest } = usePutEmeaSite(
		putEmeaSiteKey({
			businessEntityId,
			endCustomerId,
			systemId,
			siteId: site?.siteId,
		})
	);

	const trigger = async (arg) => {
		await updateSiteAction({ ...arg });

		mutateSystemSite({ system, site: { ...site, name: arg.name, siteName: arg.name } });

		mutateTreeSelectorData();
	};

	return {
		...rest,
		trigger,
	};
};
