import React, { useState } from 'react';
import { message, Modal } from 'antd';

import { AddUsbDeviceModalFooter } from '~/components/features/usb-devices/modals/add-usb-device/AddUsbDeviceModalFooter/AddUsbDeviceModalFooter';
import { UsbDeviceForm } from '~/components/features/usb-devices/UsbDeviceForm/UsbDeviceForm';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCreateControllerKey, useCreateController } from '~/hooks/data/controllers/useCreateController';
import { useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getUsbDevicesKey } from '~/components/features/usb-devices/UsbDevicesTable/UsbDevicesTable.utils';
import { ControllerProtocols } from '~/constants/ControllerProtocols';
import { ControllerTypes } from '~/constants/ControllerTypes';
import {
	generateUsbDeviceFakeMacAddress,
	generateUsbDeviceFakeSerialNumber,
} from '~/components/features/usb-devices/utils/usb-devices.utils';
import { FeatureTypes } from '~/constants/FeatureTypes';
import { getLimits } from '~/screens/_shared/entitlement';
import { UsbDevicesLimitsReachedModal } from '~/components/features/usb-devices/modals/UsbDevicesLimitsReachedModal/UsbDevicesLimitsReachedModal';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const AddDesktopUpdaterModal = ({ onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { trigger: createUsbDevice, isMutating: isCreatingUsbDevice } = useCreateController(
		getCreateControllerKey({
			customerId,
			systemId,
			siteId,
		})
	);
	const { mutate: reloadUsbDevices } = useSiteControllers(
		getUsbDevicesKey({
			customerId,
			systemId,
			siteId,
		}),
		{
			revalidateOnMount: false,
		}
	);
	const [isFormValid, setIsFormValid] = useState(false);

	const { hardLimit, usedCount } = getLimits(null, FeatureTypes.USB_DEVICE);
	const isDesktopUpdaterLimitReached = usedCount >= hardLimit;

	const onFormChange = async (values, form) => {
		let isAnyFieldInvalid = false;

		await form.validateFields().catch(({ errorFields }) => {
			if (errorFields.length) {
				isAnyFieldInvalid = true;
			}
		});

		setIsFormValid(!isAnyFieldInvalid);
	};

	const onFormSubmit = ({ name, description }) => {
		createUsbDevice(
			{
				name: name.trim(),
				description: description?.trim(),
				protocol: ControllerProtocols.PULSE,
				type: ControllerTypes.DESKTOP_UPDATER,
				mountedStatus: 'MOUNTED',
				serialNumber: generateUsbDeviceFakeSerialNumber(),
				macAddress: generateUsbDeviceFakeMacAddress(),
				ignoreGlobalHandlers: true, // to ignore default error handling
			},
			{
				onError: () => {
					message.error(translate.byKey('usb_device_generic_error_message'));
				},
				onSuccess: () => {
					onClose();
					message.success(translate.byKey('usb_device_created_message'));
					reloadUsbDevices();
				},
			}
		);
	};

	return isDesktopUpdaterLimitReached ? (
		<UsbDevicesLimitsReachedModal onClose={onClose} />
	) : (
		<Modal
			open
			title={translate.byKey('add_desktop_usb_device')}
			onCancel={onClose}
			maskClosable={false}
			width={600}
			footer={
				<AddUsbDeviceModalFooter
					usbDeviceFeatureType={FeatureTypes.USB_DEVICE}
					isSubmitDisabled={isCreatingUsbDevice || !isFormValid}
					isSubmitting={isCreatingUsbDevice}
					onClose={onClose}
				/>
			}
		>
			<UsbDeviceForm disabled={isCreatingUsbDevice} onFormChange={onFormChange} onFormSubmit={onFormSubmit} />
		</Modal>
	);
};
