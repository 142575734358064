import { translator as translate } from '~/screens/_shared/AppLocale';

const formHelp = () => `
<table style="border-spacing: 2em ; border-collapse: separate">
   <tr>
      <td>
         <strong> ${translate.byKey('fixed_address')} </strong>
         <p> ${translate.byKey('dropdown_selection_are_the_fixed_addresses_for_each_reader')} </p>
      </td>
      <td>
         <strong> ${translate.byKey('door_contact_type')} </strong>
         <p> ${translate.byKey('door_contact_type_message')} </p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('allowed_denied_led_type')} </strong>
         <p> ${translate.byKey('allowed_denied_led_type_message')} </p>
      </td>
      <td>
         <strong> ${translate.byKey('door_contact_mode')} </strong>
         <p> ${translate.byKey('door_contact_mode_message')} </p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('allowed_denied_led_duration')} </strong>
         <p> ${translate.byKey('allowed_denied_led_duration_message')} </p>
      </td>
      <td>
         <strong> ${translate.byKey('rte_mode')} </strong>
         <p> ${translate.byKey('rte_mode_message')} </p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('allowed_denied_buzzer_volume')} </strong>
         <p> ${translate.byKey('allowed_denied_buzzer_volume_message')} </p>
      </td>
      <td>
         <strong>${translate.byKey('delete')}</strong>
         <p>${translate.byKey('clicking_delete_button_will_delete_the_reader_from_the_door')}</p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('allowed_denied_buzzer_duration')} </strong>
         <p> ${translate.byKey('allowed_denied_buzzer_duration_message')} </p>
      </td>
      <td>
         <strong>${translate.byKey('cancel')} </strong>
         <p >${translate.byKey('cancel_button_closes_the_window_no_changes_are_saved')} </p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('strike_duration')} </strong>
         <p> ${translate.byKey('strike_duration_message')} </p>
      </td>
      <td>
         <strong>${translate.byKey('submit')}</strong>
         <p >${translate.byKey('submit_buttons_closes_the_window_saving_any_changes_made_to_property_fields')}</p>
      </td>
   </tr>
   <tr>
      <td>
         <strong> ${translate.byKey('door_open_duration')} </strong>
         <p> ${translate.byKey('door_open_duration_message')} </p>
      </td>
   </tr>
</table>
`;

export default formHelp;
