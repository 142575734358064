import styled from '@emotion/styled';
import React from 'react';
import { background, border, flexbox, layout, size, space, system } from 'styled-system';

const StyledImage = styled.div`
	background-position: left;
	background-repeat: no-repeat;
	background-size: 100%;
	border-top: none !important;
	${({ themeImage, theme, src }) =>
		src
			? `background-image: url("${src}");`
			: themeImage
			  ? `background-image: url("${theme.brand.images[themeImage]}");`
			  : ''}
	${({ position }) => (position ? `position: ${position};` : '')}      
      ${({ top }) => (top ? `top: ${top}px;` : '')}    
  ${layout}
  ${space}
  ${border}
  ${flexbox}
  ${size}
  ${background}
  ${system({
		clipPath: {
			property: 'clipPath',
		},
	})}
`;

const Image = (props) => {
	return <StyledImage {...props} {...(props.src && { src: window.location.origin + props.src })} />;
};

export default Image;
