import moment from 'moment';
import { StatusTransactionEventNames } from '~/constants/transactions';
import { useMemo } from 'react';

import { getSiteControllersKey, useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getStatusTransactionsKey, useStatusTransactions } from '~/hooks/data/transactions/useStatusTransactions';

const pageSize = 100;
const pageNumber = 1;
const eventName = StatusTransactionEventNames.CONTROLLER_IOT_CONNECTED;
const startTime = moment().subtract(1, 'year').startOf('day');
const endTime = moment().endOf('day');

const findDeletedControllers = (siteId, transactions, controllers = []) => {
	const deletedControllers = [];
	const siteControllers = controllers.filter((controller) => controller.siteId === siteId);

	transactions.events
		.filter((transaction) => transaction.siteId === siteId)
		.forEach((transaction) => {
			const statusTransactionControllerInSiteControllers = siteControllers.some(
				(controller) => controller.controllerId === transaction.controllerId
			);

			if (!statusTransactionControllerInSiteControllers) {
				const statusTransactionControllerInDeletedControllers = deletedControllers.some(
					(deletedController) => deletedController.controllerId === transaction.controllerId
				);

				if (!statusTransactionControllerInDeletedControllers) {
					deletedControllers.push(transaction);
				}
			}
		});

	return deletedControllers;
};

/**
 * This is really a bad h(oo|ac)k. The backend has no way to return the deleted controllers, but we need to list them for the filters,
 * and we are getting their info from the device logs, and we compare them with the current list to filter out only the deleted ones.
 * This will not work for the bigger difference between startTime and endTime.
 */
export const useSiteDeletedControllers = ({ customerId, systemId, siteId }) => {
	const siteControllersKey =
		customerId && systemId && siteId
			? getSiteControllersKey({
					customerId,
					systemId,
					siteId,
			  })
			: null;
	const { data: controllers, isLoading: isLoadingControllers } = useSiteControllers(siteControllersKey);
	const transactionsKey =
		customerId && systemId && siteId
			? getStatusTransactionsKey({
					customerId,
					systemId,
					pageSize,
					pageNumber,
					eventName,
					startTime,
					endTime,
			  })
			: null;
	const { data: transactions, isLoading: isLoadingTransactions } = useStatusTransactions(transactionsKey);

	const data = useMemo(() => {
		if (!transactions?.events?.length) {
			return [];
		}

		return findDeletedControllers(siteId, transactions, controllers);
	}, [controllers, transactions?.events]);

	return {
		data,
		isLoading: isLoadingControllers || isLoadingTransactions,
	};
};
