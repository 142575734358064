import React from 'react';

const viewBox = '0 0 512 512';

const path = (
	<g fill="currentColor">
		<path d="M256 85.333c-23.531 0-42.667 19.135-42.667 42.667s19.135 42.667 42.667 42.667 42.667-19.135 42.667-42.667S279.531 85.333 256 85.333zm0 64c-11.76 0-21.333-9.573-21.333-21.333s9.573-21.333 21.333-21.333 21.333 9.573 21.333 21.333-9.573 21.333-21.333 21.333zM288 192h-85.333A10.66 10.66 0 00192 202.667v42.667a10.66 10.66 0 0010.667 10.667h10.667v160a10.66 10.66 0 0010.667 10.667h64a10.66 10.66 0 0010.667-10.667V202.667A10.662 10.662 0 00288 192zm-10.667 213.333h-42.667v-160a10.66 10.66 0 00-10.667-10.667h-10.667v-21.333h64v192z" />
		<path d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm0 490.667C126.604 490.667 21.333 385.396 21.333 256S126.604 21.333 256 21.333 490.667 126.604 490.667 256 385.396 490.667 256 490.667z" />
	</g>
);

const about = {
	path,
	viewBox,
};

export default about;
