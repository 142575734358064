import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

const componentStyle = StyleSheet.create({
	display: 'table',
	width: '100%',
	borderRight: 1,
	borderBottom: 1,
	borderColor: '#f0f0f0',
});

export const Table = ({ children, style, ...rest }) => {
	return (
		<View
			style={{
				...componentStyle,
				...style,
			}}
			{...rest}
		>
			{children}
		</View>
	);
};
