export const StatusTransactionEventNames = {
	ACTIVATOR: 'activator',
	BATTERY: 'battery',
	BATTERY_REPLACED: 'battery_replaced',
	BLACKLISTED_CARD_DETECTED: 'blacklisted_card_detected',
	DEVICE: 'device',
	DEVICE_TAMPER: 'device_tamper',
	FAILED_TO_UNLOCK: 'failed_to_unlock',
	LOCK_JAMMED: 'lock_jammed',
	LOCK_STATE: 'lock_state',
	PORTAL_ALARM: 'portal_alarm',
	PORTAL_FAULT: 'portal_fault',
	PORTAL_MODE: 'portal_mode', // Door Mode
	PORTAL_STATE: 'portal_state',
	SECURITY_LOCK_STATE: 'security_lock_state',
	SYSTEM_EVENT: 'system_event',
	// BE event names
	IOT_HEARTBEAT_STATE: 'iot_heartbeat_state',
	IOT_CONTROLLER_STATE: 'iot_controller_state',
};

export const StatusTransactionEventTypes = {
	ACTIVE: 'active',
	APERIO_ONLINE: 'aperio_online',
	AUTHENTICATION_FAILURE: 'authentication_failure',
	BATTERY_MON_LOW: 'battery_mon_low',
	BATTERY_MON_OK: 'battery_mon_ok',
	CLOSED: 'closed',
	EMERGENCY_MODE_OFF: 'emergency_mode_off',
	EMERGENCY_MODE_ON: 'emergency_mode_on',
	FAULT_DETECTED: 'fault_detected',
	FORCED_OPEN: 'forced_open',
	LEVEL_FLAT: 'level_flat',
	LEVEL_LOW: 'level_low',
	LEVEL_OK: 'level_ok',
	LOCKED: 'locked',
	LOCKED_DOWN: 'locked_down', // Lockdown Mode On
	LOCKED_OPEN: 'locked_open', // Lockdown Mode Off
	NOT_ACTIVE: 'not_active',
	NOT_IN_TAMPER: 'not_in_tamper',
	NOT_OPENED: 'not_opened',
	OFFLINE: 'offline',
	ONLINE: 'online',
	OPEN: 'open',
	OPEN_TOO_LONG: 'open_too_long',
	POWER_SENSING_POWER_IN_ACTIVE: 'power_sensing_power_in_active',
	POWER_SENSING_POWER_IN_NOT_ACTIVE: 'power_sensing_power_in_not_active',
	POWER_SENSING_AUX1_ACTIVE: 'power_sensing_aux1_active',
	POWER_SENSING_AUX1_NOT_ACTIVE: 'power_sensing_aux1_not_active',
	POWER_SENSING_AUX2_ACTIVE: 'power_sensing_aux2_active',
	POWER_SENSING_AUX2_NOT_ACTIVE: 'power_sensing_aux2_not_active',
	POWER_SENSING_PORT1_ACTIVE: 'power_sensing_port1_active',
	POWER_SENSING_PORT1_NOT_ACTIVE: 'power_sensing_port1_not_active',
	POWER_SENSING_PORT2_ACTIVE: 'power_sensing_port2_active',
	POWER_SENSING_PORT2_NOT_ACTIVE: 'power_sensing_port2_not_active',
	POWER_UP: 'power_up',
	PSU_MON_FAILURE: 'psu_mon_failure',
	PSU_MON_OK: 'psu_mon_ok',
	SYSTEM_RESTART: 'system_restart',
	TABLES_INITIALISED: 'tables_initialised',
	TAMPER_DETECTED: 'tamper_detected',
	TERMINAL_ONLINE: 'terminal_online',
	TERMINAL_TIMEOUT: 'terminal_timeout',
	UNDEFINED: 'undefined',
	UNKNOWN: 'unknown',
	UNLOCKED: 'unlocked',
	// BE event types
	HEART_BEAT_ALIVE: 'heart_beat_alive',
	HEART_BEAT_LOST: 'heart_beat_lost',
	CONNECTED: 'connected',
	DISCONNECTED: 'disconnected',
};
