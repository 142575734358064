import { currentAdminHasRequiredRole, getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';
import { MENU_CONFIG } from './menuConfig';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useNavigate } from 'react-router-dom';
import { useModals } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { MenuItem } from '~/components/shared/layouts/MainLayout/Navigation/shared/MenuItem';
import { NAVIGATION_MENU_POPUP_CLASSNAME } from '~/components/shared/layouts/MainLayout/Navigation/shared/NavigationMenu';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const useMenuItems = (onChange) => {
	const { translate } = useLocale();
	const navigate = useNavigate();

	const { toggleModal } = useModals();

	const {
		data: {
			system,
			site: { siteId },
		},
	} = useCurrentSystemSite();

	const isSiteSelected = Boolean(siteId);

	const shouldBeVisible = (item) =>
		(isSiteSelected ? item.siteLevel : item.systemLevel) &&
		(item.forbiddenRoles ? !currentAdminHasRequiredRole(getCurrentAdministrator(system), item.forbiddenRoles) : true);

	const generateItemsProps = (item) => ({
		key: item.key,
		label: <MenuItem label={translate.byKey(item.title)} icon={item.icon} />,
		popupClassName: NAVIGATION_MENU_POPUP_CLASSNAME,
		onClick: () => {
			if (item.link) {
				navigate(item.link);
				onChange(item.key);
			}

			if (item.modal) {
				toggleModal(item.modal);
			}
		},
		children: item?.children?.filter(shouldBeVisible)?.map(generateItemsProps),
		'data-testid': item.testId,
	});

	return MENU_CONFIG.filter(shouldBeVisible).map(generateItemsProps);
};
