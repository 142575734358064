import styled from '@emotion/styled';
import { Input } from 'antd';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const StyledInput = styled(Input.Password)`
	display: flex;
	border-radius: 4px;
	color: ${(props) => props.theme.colors.gray[400]};
	border-color: ${(props) => props.theme.colors.gray[200]};
	svg {
		color: ${(props) => props.theme.colors.gray[600]};
	}
	.ant-input-prefix {
		margin-right: 10px;
	}
	&::placeholder {
		color: ${(props) => props.theme.colors.gray[300]};
	}
	.ant-input::placeholder {
		color: ${(props) => props.theme.colors.gray[300]};
	}
	${layout}
	${space}
    ${flexbox}
    ${size}
    ${background}
    ${grid}
    ${color}
    ${typography}
    ${border}
    ${position}
    ${shadow};
`;
export default StyledInput;
