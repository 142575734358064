import { checkDoorLimits, useCreateDoor, useDeleteDoor, useUpdateDoor } from '~/screens/Doors/hooks';
import { useLocale } from '~/screens/_shared/AppLocale';
import { Modal } from '~/components';
import { doorTypeNumberOfReaders, isOffline, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import moment from 'moment';
import { showErrorToast, showSuccessToast, showWarningToast } from '~/screens/_shared/toast';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export function formatTimeSlotData(timeSlotData, translate) {
	let newTimeSlotData = timeSlotData;

	if (timeSlotData && timeSlotData.timeSlots) {
		for (let slot of timeSlotData.timeSlots) {
			if (slot.modeTimeIntervals) {
				const badTimes = slot.modeTimeIntervals.filter((i) => i.startTime === i.endTime);

				if (badTimes.length > 0) {
					const startTimes = badTimes.map((t) => t.startTime).join(', ');
					const message =
						badTimes.length === 1
							? translate.byKeyFormatted('your_schedule_for_name_needs_to_be_longer_formatted', startTimes)
							: translate.byKeyFormatted('your_schedules_for_name_needs_to_be_longer_formatted', startTimes);
					Modal.error({
						title: badTimes.length === 1 ? translate.byKey('bad_schedule') : translate.byKey('bad_schedules'),
						content: message,
					});
					return;
				}
			}

			if (slot.modeTimeIntervals.length <= 1) {
				continue;
			}

			const temp = [...slot.modeTimeIntervals];
			const lowestInterval = temp.sort((a, b) => b.endTime.localeCompare(a.endTime))[0];
			const highestInterval = temp.sort((a, b) => a.startTime.localeCompare(b.startTime))[0];

			if (highestInterval === lowestInterval) {
				Modal.error({
					title: translate.byKey('cannot_have_intersecting_schedules'),
					content: translate.byKeyFormatted(
						'under_starttime_to_endtime_overlaps_with_other_times_in_the_schedule_please_resize_move_or_remove_it_formatted',
						[slot.name, highestInterval.startTime, highestInterval.endTime]
					),
				});
				return;
			} else {
				for (const interval of temp) {
					const hasIntersections = temp.some(
						(other) => interval.startTime < other.endTime && interval.endTime > other.endTime
					);

					if (hasIntersections) {
						Modal.error({
							title: translate.byKey('cannot_have_intersecting_schedules'),
							content: translate.byKeyFormatted(
								'under_starttime_to_endtime_overlaps_with_other_times_in_the_schedule_please_resize_move_or_remove_it_formatted',
								[slot.name, highestInterval.startTime, highestInterval.endTime]
							),
						});
						return;
					}
				}
			}
		}

		newTimeSlotData = Object.assign({}, timeSlotData);
		newTimeSlotData.timeSlots = timeSlotData.timeSlots.map((slot) => {
			slot = Object.assign({}, slot);
			slot.modeTimeIntervals = slot.modeTimeIntervals.map((i) => {
				i = Object.assign({}, i);
				if (i.endTime === '24:00') {
					i.endTime = '23:59';
				}

				return i;
			});

			return slot;
		});
	}

	return newTimeSlotData;
}

const useSaveDoor = (doorType, currentDoor, timeSlotData, onUpdated, setCurrentDoor) => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();
	const [createDoor, creating, createStep] = useCreateDoor();
	const [updateDoor, updating, updateStep] = useUpdateDoor();
	const [deleteDoor, deleting, deleteStep] = useDeleteDoor();

	async function onDoorUpdate(formData) {
		const originalReaders = formData.originalReaders ? JSON.parse(formData.originalReaders) : [];
		if (
			originalReaders.length === 0 &&
			checkDoorLimits(
				system,
				doorType,
				formData.readers.some((reader) => reader.fixedAddress)
			)
		)
			return;

		if (formData.shouldDelete) {
			let authenticationProfile;
			if (!isOffline(doorType) && formData.authenticationProfile) {
				authenticationProfile = JSON.parse(formData.authenticationProfile);
			}

			const data = {
				...formData,
				...timeSlotData,
				portal: currentDoor,
				authenticationProfile,
				readers: formData.readers,
			};

			try {
				const offlineReaderConfigurationStatus = isOffline(doorType)
					? currentDoor.assignedReaders.map((x) => x.configuration['configurationStatus']).pop()
					: '';
				if (
					isOnline(doorType) ||
					offlineReaderConfigurationStatus === 'UNKNOWN' ||
					offlineReaderConfigurationStatus === 'PENDING_CONFIGURATION' ||
					!offlineReaderConfigurationStatus
				) {
					await deleteDoor(data, doorType);
					showSuccessToast(translate.byKeyFormatted('successfully_deleted_formatted', [data.portal.name]));
				} else {
					showWarningToast(
						translate.byKey(
							'before_you_are_able_to_delete_the_door_please_first_reset_the_offline_lock_using_the_aadc_app'
						)
					);
				}

				onUpdated(true);
				setCurrentDoor({});
			} catch (ex) {
				console.error(ex);
				showErrorToast(translate.byKey('failed_to_delete_door'));
			}
		} else if (formData.portalId && formData.version) {
			if (!isOffline(doorType)) {
				const openTimeForPortal = getOpenTimeForPortal(formData);
				formData.calendar = formData.calendar ? JSON.parse(formData.calendar) : null;
				formData.schedule = formData.schedule ? JSON.parse(formData.schedule) : null;
				formData = {
					...formData,
					configuration: {
						openTime: moment.duration(Number.parseInt(openTimeForPortal), 'seconds').toISOString(),
					},
				};
			}

			try {
				if (isOffline(doorType)) {
					formData = {
						...formData,
						configuration: formData.openTime
							? { openTime: moment.duration(Number.parseInt(formData.openTime), 'seconds').toISOString() }
							: 'PT20S',
					};
				}
				await updateDoor(Object.assign({}, formData, formatTimeSlotData(timeSlotData)), doorType);
				showSuccessToast(translate.byKeyFormatted('updated_successfully_formatted', [formData.name]));
				onUpdated(true);
			} catch (error) {
				console.error(error);
				if (error.status === 403) {
					showErrorToast(translate.byKey('failed_to_create_door_limit_reached'));
				} else {
					showErrorToast(translate.byKey('failed_to_create_door'));
				}
				onUpdated();
			}
		} else {
			try {
				await createDoor(Object.assign({}, formData, formatTimeSlotData(timeSlotData)), doorType);
				showSuccessToast(translate.byKeyFormatted('created_successfully_formatted', [formData.name]));
				if (isOffline(doorType)) {
					showWarningToast(
						translate.byKeyFormatted(
							'this_door_is_currently_not_configured_please_use_the_aadc_to_configure_the_lock_for_this_door'
						)
					);
				}

				onUpdated(true);
			} catch (error) {
				if (error.status === 403) {
					showErrorToast(translate.byKey('failed_to_create_door_limit_reached'));
				} else {
					showErrorToast(translate.byKey('failed_to_create_door'));
				}
				onUpdated();
			}
		}
	}

	const maxNumberOfReaders = 2;

	function onFinish(values) {
		values.name = (values.name || '').trim();

		let readers = [...Array(maxNumberOfReaders)];
		for (let i = 0; i < maxNumberOfReaders; ++i) {
			for (const [key, value] of Object.entries(values)) {
				if (key.startsWith(`readers[${i}].`)) {
					const readerKey = key.replace(`readers[${i}].`, '');
					readers[i] = readers[i] || {};
					readers[i][readerKey] = value;
					delete values[key];
				}
			}
		}
		const numberOfReaders = doorTypeNumberOfReaders[doorType] || 1;
		readers = readers.filter((reader) => !!reader).slice(0, numberOfReaders);
		if (readers.length === 0 && values.originalReaders) {
			readers = JSON.parse(values.originalReaders);
		}

		values.readers = readers.map((reader) => {
			if (isOnline(doorType)) {
				reader.deniedLedDuration = Number(reader.deniedLedDuration) * 10;
				reader.allowedLedDuration = Number(reader.allowedLedDuration) * 10;
				reader.allowedBuzDuration = Number(reader.allowedBuzDuration) * 10;
				reader.deniedBuzDuration = Number(reader.deniedBuzDuration) * 10;
				reader.doorOpenDuration = Number(reader.doorOpenDuration) * 10;
				reader.strikeDuration = Number(reader.strikeDuration) * 10;
			}

			if (reader.logicalDevices && reader.logicalDevices.startsWith) {
				reader.logicalDevices = JSON.parse(reader.logicalDevices);
			}

			if (reader.deviceInfo && reader.deviceInfo.startsWith) {
				reader.deviceInfo = JSON.parse(reader.deviceInfo);
			}

			if (reader.configuration && reader.configuration.startsWith) {
				reader.configuration = JSON.parse(reader.configuration);
			}

			return reader;
		});

		onDoorUpdate(values).catch(console.error);
	}

	function getOpenTimeForPortal(formData) {
		const [entryReader, exitReader] = formData.readers;
		const isEntryReaderTypeDOS = entryReader?.fixedAddress ? entryReader.input1DOSType === '00' : undefined;
		const isExitReaderTypeDOS = exitReader?.fixedAddress ? exitReader.input1DOSType === '00' : undefined;

		if (entryReader && exitReader) {
			if (isEntryReaderTypeDOS) {
				return entryReader.doorOpenDuration;
			}

			if (isExitReaderTypeDOS) {
				return exitReader.doorOpenDuration;
			}
		}

		return entryReader?.doorOpenDuration || 0;
	}

	return [onFinish, creating || updating || deleting, createStep || updateStep || deleteStep];
};

export default useSaveDoor;
