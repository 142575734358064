import { ContainerOutlined, EditOutlined, MinusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';

import { Button, Flex, Input, Item, Spin, Text, Checkbox } from '~/components';
import { usePostApi, useDeleteApi, useGetApi, usePutApi } from '~/screens/_shared/useApi';
import { identifierFormat } from '~/components/features/people/constants/identifierFormat';
import { numberToFormatString, isCredentialBitLengthValid } from '../../../../People.utils';
import WSEnrolmentService from '~/services/WSEnrolmentService';
import cardIdentifierFormatToUse from '~/services/WSEnrolmentService/cardIdentifierFormatToUse';
import cardIdentifierFormatToUseByBits from '~/services/WSEnrolmentService/cardIdentifierFormatToUseByBits';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';

import { Card } from './StyledCard';
import { css } from '@emotion/core';
import { getCredentialIdentifier } from '~/components/features/people/modals/PeopleModal/tabs/CredentialsTab/CardList';
import { useLocale } from '~/screens/_shared/AppLocale';
import mappers from '~/screens/_shared/mappers';
import { showErrorToast, showSuccessToast } from '~/screens/_shared/toast';
import { blockListState } from '~/components/features/people/constants/blockListState';
import { removeUnwantedCharacters, ensureCredentialValueUpperLimit } from '../../../../constants/credentialConstraints';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const removeSpaces = (value) =>
	String(value || '')
		.split(' ')
		.join('');

const getPin = (credential) => {
	if (credential?.credentialIdentifiers) {
		return credential.credentialIdentifiers.find((cred) => cred.recognitionType === 'PIN');
	}

	return null;
};

const CardForm = ({ field, form, onUpdate, onCancelCard, sharedPinCredential, credentialHolderId }) => {
	const [editing, setEditing] = useState(false);
	const [hint, setHint] = useState('');
	const [enrolMsg, setEnrolMsg] = useState(undefined); // for ws enrol
	const [credential, setCredential] = useState(null);
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const [getCredentialHolder] = useGetApi(mappers.credentialHolder);
	const [updateCredentialHolder] = usePutApi(mappers.credentialHolder);
	const [createCredential, loadingCreateCredential] = usePostApi(mappers.credential);
	const [updateCredential, loadingUpdateCredential] = usePutApi(mappers.credential);
	const [deleteCredential, loadingDeleteCredential] = useDeleteApi(mappers.credential);
	const [updateCredentialState] = usePutApi(mappers.credentialState);
	const [getCredentialState] = useGetApi(mappers.credentialState);

	const handleErrorResponse = (response) => {
		if (response.status === 409) {
			showErrorToast(
				translate.byKey(
					'an_existing_credential_exists_somewhere_else_in_the_system_please_update_the_credential_and_try_again'
				)
			);
		} else {
			showErrorToast(
				translate.byKey(
					'an_error_occurred_when_saving_the_credential_please_check_your_network_connection_or_if_there_is_another_credential_in_the_system_with_the_same_value'
				)
			);
		}
	};

	useEffect(() => {
		const formFields = form.getFieldsValue();

		if (formFields.credentials && formFields.credentials[field.key]) {
			const currentCredential = formFields.credentials[field.key];

			if (currentCredential) {
				const { credential } = currentCredential;

				// Existing credential, passed to us from CredentialsForm. No need to reload.
				if (credential && credential.credentialId) {
					setCredential(credential);
					setHint(getHint(getCurrentCredentialType(credential)));
				} else {
					if (sharedPinCredential) {
						sharedPinCredential.credentialIdentifiers = sharedPinCredential.credentialIdentifiers || [];

						const identifier = getCredentialIdentifier(sharedPinCredential);

						if (!identifier) {
							sharedPinCredential.credentialIdentifiers.push({
								hexValue: '',
								recognitionType: 'CREDENTIAL_NUMBER',
							});
						}

						setCredential(sharedPinCredential);
					} else {
						getCredentialHolder({ credentialHolderId }).then((credentialHolder) => {
							setCredential({
								credentialHolder: credentialHolder,
								credentialIdentifiers: [
									{
										hexValue: '',
										recognitionType: 'CREDENTIAL_NUMBER',
									},
								],
							});
						});
					}
				}
			}
		} else {
			setEditing(true);
		}
	}, []);

	const getCurrentCredentialType = (credential) => {
		const identifier = getCredentialIdentifier(credential);

		if (credential && identifier) {
			return getCredentialType(credential.credentialId, identifier.identifierFormat);
		}

		return getCredentialType(credential ? credential.credentialId : null, 'NONE');
	};

	/**
	 * Credential has finally loaded.
	 */
	useEffect(() => {
		if (credential?.credentialId) {
			const identifier = getCredentialIdentifier(credential);
			getCredentialState({ credentialId: credential.credentialId }).then(async (credentialState) => {
				let { credentials } = form.getFieldsValue();

				if (identifier) {
					const credType = getCredentialType(credentials[field.key].credentialId, identifier.identifierFormat);

					if (credType.data[1].numeric && credType.data[1].numeric === true) {
						credentials[field.key].hexValue = numberToFormatString(identifier.hexValue, 'int');
					} else {
						credentials[field.key].hexValue = identifier.hexValue;
					}

					credentials[field.key].suspended = credentialState.reason === 'BLOCKED';

					form.setFieldsValue({ credentials });
				}
			});
		}
	}, [credential]);

	/**
	 * Show a hint for card number format based on
	 * the credential type selected.
	 * @param {*} format
	 */
	const getHint = (format) => {
		let hintText = '';
		if (format && format.data) {
			let value = format.data[1].hint;
			if (value !== '') {
				hintText = `(${value})`;
			}
		}

		return hintText;
	};

	const onCredentialChange = () => {
		let { credentials } = form.getFieldsValue();
		const thisCredential = credentials[field.key];
		const sanitizedCredentialHexValue = ensureCredentialValueUpperLimit(
			removeUnwantedCharacters(removeSpaces(thisCredential.hexValue))
		);

		thisCredential.hexValue = sanitizedCredentialHexValue;

		const identifier = getCredentialIdentifier(credential);
		const identifierFormatType = cardIdentifierFormatToUseByBits();
		identifier.identifierFormat = identifierFormatType[0];

		setHint(getHint(getCurrentCredentialType(credential)));
	};

	const onSuspendedChange = (event) => {
		let { credentials } = form.getFieldsValue();
		credentials[field.key].suspended = event.target.checked;
		form.setFieldsValue({ credentials });
	};

	const getCredentialType = (credentialId, key) => {
		for (let obj of Object.entries(identifierFormat)) {
			if (obj[0] === key) {
				return { credentialId: credentialId, data: obj };
			}
		}
		// default is the highest 48 bit
		return {
			credentialId: '',
			data: [
				'FACILITY16_CARD32',
				{
					text: 'Facility Code 16 Card 32',
					mask: new RegExp('^[0-9]*$'),
					bitlength: 48,
					numeric: true,
					hint: 'DEC',
				},
			],
		};
	};

	/**
	 * On cancel clicked
	 * - set editing false
	 * - reload the credential if it was edited
	 * - reset the form if it was a new credential that was cancelled
	 */
	const onClickCancel = () => {
		setEditing(false);
		onCancelCard();
	};

	/**
	 * to keep the enrol messages being accepted we need to make sure that the message state remains
	 * changing, so we set it to something that is obviously going to be ignored in the enrolMsg
	 * state change (we must handle this in the state change effect, don't want a loop happening)
	 */
	useEffect(() => {
		// it has text, therefore set to undefined again
		if (enrolMsg !== undefined) {
			setEnrolMsg(undefined);
		}
	}, [enrolMsg]);

	/**
	 * send enrol message to plugin manager
	 */
	const sendWSMessage = () => {
		setEnrolMsg('{"enrollType":"omnikey","formatType":"ANY"}');
	};

	const onMessageReceived = (value) => {
		const identifier = getCredentialIdentifier(sharedPinCredential);

		if (value !== undefined) {
			// clean up
			let credentials = form.getFieldsValue().credentials;

			if (identifier) {
				identifier.identifierFormat = undefined;
			}

			credentials[field.key].hexValue = undefined;
			form.setFieldsValue({
				credentials: credentials,
			});

			if (!value.statusMsg.toLowerCase().includes('error') && value.data !== undefined) {
				const identifierFormatType = cardIdentifierFormatToUse(value);

				if (identifier) {
					identifier.identifierFormat = identifierFormatType[0];
				}

				credentials[field.key].hexValue = value.data;
				form.setFieldsValue({
					credentials: credentials,
				});
			} else {
				// toast error
				if (value.statusMsg.toLowerCase().includes('error')) {
					showErrorToast(value.statusMsg);
				} else {
					showErrorToast(translate.byKey('it_appears_there_may_be_no_card_on_the_device'));
				}
			}
		}
	};

	const onWebSocketError = (error) => {
		showErrorToast(error);
		console.error(error);
	};

	/**
	 * this does 2 checks, first if Any is selected, dont enable submit.
	 * second, if the type does not match its regex, dont enable submit
	 */
	const checkCredentialTypeNotAnyOrInvalid = () => {
		if (!form.getFieldsValue().credentials[field.key]) {
			return false;
		}

		const selectedCredentialType = getCurrentCredentialType(credential);

		if (selectedCredentialType.data !== undefined && selectedCredentialType.data[1] !== undefined) {
			let isValid = selectedCredentialType.data[1].text !== 'Any'; // 1st check Any
			const cardNumber = removeSpaces(form.getFieldsValue().credentials[field.key].hexValue);
			if (!isValid || cardNumber === undefined || cardNumber === '') {
				return true;
			} else {
				// validation returns true if valid, so we use the opposite for disabling Submit button
				isValid = isCredentialBitLengthValid(cardNumber, false);
			}
			return !isValid;
		} else {
			return true;
		}
	};

	return (
		<Spin active={loadingDeleteCredential || loadingCreateCredential || loadingUpdateCredential}>
			<WSEnrolmentService
				sendEnrolMessage={translate.byValue(enrolMsg)}
				onMessage={onMessageReceived}
				onError={onWebSocketError}
			/>
			<Card
				width="auto"
				justifyContent="space-evenly"
				css={css`
					padding-top: 1rem;
					padding-bottom: 1rem;

					.card-form-input {
						padding-bottom: 0.5rem;
					}

					.card-form-button-group {
						display: grid;
						grid-auto-flow: column;
						grid-gap: 0.5rem;
						margin-left: -1.5rem;
						margin-right: -1.5rem;
					}

					.card-form-hidden {
						visibility: hidden;
					}

					.card-form-hidden.no-height {
						height: 0;
					}
				`}
			>
				{editing &&
				credential &&
				credential.credentialId &&
				hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.DELETE) ? (
					<Popconfirm
						placement="topLeft"
						title={translate.byKey('delete_this_card_v2')}
						onConfirm={() => {
							const { credentialId } = credential;
							deleteCredential({ credentialId })
								.then(() => {
									setEditing(false);
									showSuccessToast(translate.byKey('deleted_credential_successfully'));
									onUpdate();
								})
								.catch(() => {
									showSuccessToast(
										translate.byKey('could_not_delete_the_credential_please_check_your_network_settings_and_try_again')
									);
								});
						}}
						okText={translate.byKey('delete')}
						cancelText={translate.byKey('cancel')}
					>
						<MinusCircleOutlined className="anticon-remove" />
					</Popconfirm>
				) : editing ? (
					<CloseOutlined
						onClick={() => {
							onCancelCard();
						}}
						className="anticon-remove"
					/>
				) : editing === false &&
				  hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.WRITE) ? (
					<EditOutlined
						onClick={() => {
							setEditing(true);
						}}
						className="anticon-edit"
					/>
				) : (
					<ContainerOutlined
						onClick={() => {
							setEditing(false);
						}}
						className="anticon-edit"
					/>
				)}
				<Item noStyle required={false} key={field.key}>
					<Item name={[field.name, 'credentialId']} fieldKey={[field.fieldKey, 'credentialId']} noStyle>
						<Input type="hidden" />
					</Item>
					<Item name={[field.name, 'version']} fieldKey={[field.fieldKey, 'version']} noStyle>
						<Input type="hidden" />
					</Item>
					{!editing && (
						<Flex
							grow
							alignItems="center"
							justifyContent="center"
							padding-top="14px"
							style={{ visibility: editing ? 'hidden' : 'visible' }}
						>
							<Text color="gray.400" fontFamily="Verdana" fontSize="16px" paddingTop="24px">
								{form.getFieldsValue().credentials[field.key]
									? form.getFieldsValue().credentials[field.key].hexValue
									: 'New Card'}
							</Text>
						</Flex>
					)}

					<Item
						className={editing ? 'card-form-input' : 'card-form-input card-form-hidden no-height'}
						name={[field.name, 'hexValue']}
						fieldKey={[field.fieldKey, 'hexValue']}
						validateTrigger={['onChange']}
						label={translate.byKeyFormatted('credential_number_formatted_v2', [hint])}
						rules={[
							{
								required: true,
								whitespace: true,
								message: ' ',
							},
							() => ({
								validator(rule, value) {
									if (isCredentialBitLengthValid(removeSpaces(value), false) === true) {
										return Promise.resolve();
									}
									return Promise.reject(translate.byKey('value_is_invalid_empty_or_too_long'));
								},
							}),
						]}
					>
						<Input placeholder={translate.byKey('credential_number_v2')} onChange={onCredentialChange} />
					</Item>

					<Checkbox
						className={editing ? 'card-form-input' : 'card-form-input card-form-hidden no-height'}
						checked={(form.getFieldsValue().credentials[field.key] || {}).suspended}
						onChange={onSuspendedChange}
					>
						{translate.byKey('suspended')}
					</Checkbox>
				</Item>
				<Flex className={editing ? 'card-form-button-group' : 'card-form-button-group card-form-hidden no-height'}>
					<Button onClick={sendWSMessage} type="primary" size="small" className="anticon-cancel-button">
						{translate.byKey('enrol_card')}
					</Button>
					<Button style={{ visibility: 'hidden' }} size="small" className="anticon-cancel-button" />

					<Button
						onClick={() => {
							onClickCancel();
						}}
						size="small"
						className="anticon-cancel-button"
					>
						{translate.byKey('cancel')}
					</Button>
					<Button
						disabled={checkCredentialTypeNotAnyOrInvalid()}
						onClick={() => {
							const { name, validity, credentialHolderId } = credential.credentialHolder;
							const credentials = form.getFieldsValue().credentials[field.key];
							let { hexValue } = credentials;
							hexValue = removeSpaces(hexValue);

							const selectedCredentialType = getCurrentCredentialType(credential);
							// Format requires this value to be a numeric
							if (selectedCredentialType.data[1].numeric && selectedCredentialType.data[1].numeric === true) {
								hexValue = parseInt(hexValue).toString(16).toUpperCase();
							}

							const identifier = getCredentialIdentifier(credential);

							const formData = {
								firstName: name,
								lastName: '',
								startDateTime: validity.startDateTime,
								endDateTime: validity.endDateTime,
								credentialHolderId,
								credentialIdentifiers: [
									{
										identifierFormat: identifier ? identifier.identifierFormat : 'NONE',
										hexValue,
										recognitionType: 'CREDENTIAL_NUMBER',
										exemptedFromAuthentication: credentials.suspended,
									},
								],
							};

							const pin = getPin(credential);

							if (pin) {
								formData.credentialIdentifiers.push(pin);
							}

							if (credential && credential.credentialId) {
								const { version, credentialId } = credential;
								updateCredential({ ...formData, version, credentialId })
									.then(async () => {
										let { credentialHolder } = credential;

										if (credentials.suspended) {
											await updateCredentialState({
												reason: blockListState.BLOCKED,
												description: translate.byKey('blocked_credential_number_v2'),
												credentialId: credential.credentialId,
											});
										} else {
											await updateCredentialState({
												reason: blockListState.ENABLED,
												description: translate.byKey('unblocking_credential_v2'),
												credentialId: credential.credentialId,
											});
										}

										await updateCredentialHolder({
											name,
											validity: credentialHolder.validity,
											user: credentialHolder.user,
											accessProfiles: credentialHolder.accessProfiles,
											credentialHolderId,
											description: credentialHolder.description || '{}',
											version: credentialHolder.version,
										});

										setEditing(false);
										showSuccessToast(translate.byKey('updated_credential_successfully'));
										onUpdate();
									})
									.catch(handleErrorResponse);
							} else {
								createCredential(formData)
									.then(async () => {
										if (credential.suspended) {
											await updateCredentialState({
												reason: blockListState.BLOCKED,
												description: translate.byKey('blocked_credential_number_v2'),
												credentialId: credential.credentialId,
											});
										}

										let { credentialHolder } = credential;
										await updateCredentialHolder({
											name,
											validity: credentialHolder.validity,
											user: credentialHolder.user,
											accessProfiles: credentialHolder.accessProfiles,
											credentialHolderId,
											description: credentialHolder.description || '{}',
											version: credentialHolder.version,
										});

										setEditing(false);
										showSuccessToast(translate.byKey('created_credential_successfully'));
										onUpdate();
									})
									.catch(handleErrorResponse);
							}
						}}
						type="primary"
						size="small"
						className="anticon-save-button"
					>
						{translate.byKey('submit')}
					</Button>
				</Flex>
			</Card>
		</Spin>
	);
};

export default CardForm;
