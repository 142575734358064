import React from 'react';

import { Flex, Logo, Overlay, Progress, Text } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCustomers } from './components/features/site-selection/hooks/useCustomers';
import { useUserAuthData } from './components/features/auth/hooks/useUserAuthData';
import { useTreeSelectorData } from './components/features/site-selection/hooks/useTreeSelectorData';

const Loading = () => {
	const { translate } = useLocale();
	const { isLoading: isUserLoading } = useUserAuthData();
	const { isLoading: isCustomersLoading } = useCustomers();
	const { isLoading: isTreeDataLoading } = useTreeSelectorData();

	return isUserLoading || isCustomersLoading || isTreeDataLoading ? (
		<Overlay center visible data-testid="loading-screen">
			<Flex flexDirection="column">
				<Flex grow alignItems="center" flexDirection="column" width="350px">
					<Logo width={250} height={109} pl="30px" pr="30px" />
					<Text color="gray.400" fontSize="16px" pt="30px" pb="15px" fontWeight={500}>
						{translate.byKey('getting_things_ready')}
					</Text>
				</Flex>
				<Progress />
			</Flex>
		</Overlay>
	) : null;
};

export default Loading;
