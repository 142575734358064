import moment from 'moment';
import { identifierFormat } from '~/components/features/people/constants/identifierFormat';
import { isOfflineCredential } from '~/components/features/people/People.utils';
import getJsonValue from '~/screens/Reports/getJsonValue';
import { translator } from '~/screens/_shared/AppLocale';
import { getReadableName } from '~/screens/_shared/getReadableName';

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_DATE_RANGE = {
	start: moment().startOf('week'),
	end: moment().endOf('week'),
};

const mapActions = [
	{ term: 'POST', name: 'Create' },
	{ term: 'DELETE', name: 'Delete' },
	{ term: 'PUT', name: 'Update' },
	{ term: 'GET', name: 'List' },
	{ term: 'LOGIN', name: 'Authentication' },
];

export const getActionType = (value) => {
	if (value === undefined) {
		return value;
	}

	const matchingAction = mapActions.find((item) => item.term.includes(value));

	return matchingAction ? matchingAction.name : 'List';
};

const mapTerminology = [
	{ term: 'access-schedule', name: translator.byKey('access_schedule') },
	{ term: 'asset', name: 'Zone' },
	{ term: 'portal', name: 'Door' },
	{ term: 'special-day', name: 'Holiday' },
	{ term: 'peripheral-device', name: 'Reader' },
	{ term: 'access-point', name: 'Device' },
	{ term: 'calendar', name: 'Calendar' },
	{ term: 'schedule', name: 'Schedule' },
	{ term: 'authentication-profile', name: 'Profile' },
	{ term: 'access-profile', name: 'Access Group' },
	{ term: 'controller', name: 'Controller' },
	{ term: 'credential', name: 'Card' },
	{ term: 'credential-holder', name: 'Person' },
	{ term: 'user', name: 'User' },
	{ term: 'login', name: 'Login' },
	{ term: 'controller-onboard', name: 'Controller Onboarding' },
	{ term: 'port', name: 'Port' },
	{ term: 'state', name: 'State' },
];

export const getCategory = (url) => {
	const parts = url.split('/');
	const lastPart = parts[parts.length - 1];

	const category = /[0-9]/.test(lastPart) || lastPart === '' ? parts[parts.length - 2] : lastPart;

	const foundTerm = mapTerminology.find((item) => item.term === category);

	return foundTerm ? foundTerm.name : category;
};

const getReqBodyValue = (value, row) => {
	if (!value) {
		return '';
	}

	let parsedValue = {};

	try {
		parsedValue = JSON.parse(value);
	} catch {
		parsedValue = {};
	}

	const { name = '', credentialIdentifiers } = parsedValue;
	const category = getCategory(row.requestUrl);

	if (credentialIdentifiers) {
		const filteredIdentifiers = credentialIdentifiers.filter(
			(identifier) => !isOfflineCredential(identifier.identifierFormat)
		);
		const formattedIdentifiers = filteredIdentifiers.map((identifier) => {
			let isNumeric = !isOfflineCredential(identifier.identifierFormat);
			if (identifier.identifierFormat in identifierFormat) {
				isNumeric = identifierFormat[identifier.identifierFormat].numeric || false;
			}
			return isNumeric ? parseInt(identifier.hexValue, 16) : identifier.hexValue;
		});
		return formattedIdentifiers.length > 0 ? `${name}: ${formattedIdentifiers.join(', ')}`.trim() : name;
	}

	const isUserCategory = mapTerminology.find((val) => val.name === category && val.term === 'user');

	if (isUserCategory) {
		const firstName = getJsonValue(value, 'firstName', false, category);
		const lastName = getJsonValue(value, 'lastName', false, category);
		return [firstName, lastName].filter(Boolean).join(' ');
	}

	return getJsonValue(value, 'name', false, category);
};

export const getDescription = (value, row) => {
	let description = '';

	// Provided with a name. Only in OCA2.0 onwards.
	if (row && row.name) {
		description = getReadableName(row.name);
	} else if (value) {
		description = getReqBodyValue(value, row);
	}

	return description;
};
