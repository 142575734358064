import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import mappers from '~/screens/_shared/mappers';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const useDeleteAsset = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.asset,
				method: method.delete,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => ref.current.fetchData({ ...arg });

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getDeleteAssetKey = ({ customerId, systemId, siteId, assetId }) =>
	customerId && systemId && siteId && assetId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${assetId}${separator}\
delete-asset`
		: null;
