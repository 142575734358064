import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';
export const BlocklistMode = {
	OFFLINE: 'offline',
	ONLINE: 'online',
};

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useCredentials = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.credential,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , pageSize, pageNumber, mode, sortBy, sortDirection, credentialStates] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': pageSize,
					page: pageNumber,
					'in-offline-blocklist': Object.values(BlocklistMode).includes(mode) ? mode === BlocklistMode.OFFLINE : '',
					'sort-by': sortBy,
					'sort-order': sortDirection,
					'credential-states': credentialStates,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getCredentialsKey = ({
	customerId,
	systemId,
	pageSize,
	pageNumber,
	mode,
	sortBy,
	sortDirection,
	credentialStates,
	person,
}) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${mode}${separator}\
${sortBy}${separator}\
${sortDirection}${separator}\
${credentialStates}${separator}\
${person}${separator}\
credentials`
		: null;
