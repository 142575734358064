import styled from '@emotion/styled';

export const Card = styled.div`
	width: ${(props) => props.width || '315px'};
	display: flex;
	position: relative;
	flex-direction: column;
	margin-left: 8px;
	padding: 0 40px;
	min-height: 180px;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: ${(props) => props.theme.shadows.shadow_4};
	justify-content: ${(props) => props.justifyContent || 'center'};
	transition: box-shadow 0.3s;

	&:hover {
		transition: box-shadow 0.3s;
		box-shadow: ${(props) => props.theme.shadows.shadow_6};
	}
	.anticon-edit {
		font-size: 20px !important;
		position: absolute !important;
		top: 15px !important;
		right: 15px !important;
		cursor: pointer;
		color: ${(props) => props.theme.colors.gray[300]} !important;
	}

	.anticon-remove {
		font-size: 20px !important;
		position: absolute !important;
		top: 15px !important;
		right: 15px !important;
		cursor: pointer;
		color: ${(props) => props.theme.colors.red[400]} !important;
	}

	.action-save-button {
		position: absolute;
		padding: 6px;
		height: 24px;
		bottom: 10px;
		right: 10px;
	}

	.action-cancel-button {
		position: absolute;
		padding: 6px;
		height: 24px;
		left: 10px;
	}
`;
