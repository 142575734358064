import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { Button, Modal, Spin } from '~/components';
import { useGetApi, useListApi } from '~/screens/_shared/useApi';

import CardForm from './CardForm';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { getPinFromCredentialHolder } from '~/components/features/people/People.hooks';
import { useLocale } from '~/screens/_shared/AppLocale';
import mappers from '~/screens/_shared/mappers';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const getCredentialIdentifier = (credential) => {
	if (credential?.credentialIdentifiers) {
		return credential.credentialIdentifiers.find((cred) => cred.recognitionType === 'CREDENTIAL_NUMBER');
	}

	return null;
};

export const CardList = ({ visible, onCreate, onCancel, selected, saving, onClose }) => {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [personName, setPersonName] = useState('Add Person');
	const [sharedPinCredential, setPinCredential] = useState(null);
	const { data: user } = useUserAuthData();
	const [originalCredentials, setOriginalCredentials] = useState([]);
	const [getCredentials, loadingCredentials] = useListApi(mappers.credential);
	const [getCredentialHolder] = useGetApi(mappers.credentialHolder);
	const { translate } = useLocale();

	const refresh = (credentialHolderId) => {
		setIsLoading(true);
		getCredentials({ params: { 'detail-level': 'FULL', credential_holder_id: credentialHolderId } })
			.then(async ({ credentials }) => {
				let formCredentials = [];

				let pinCredential = credentials.find((credential) => {
					return (
						credential.credentialIdentifiers &&
						credential.credentialIdentifiers.length === 1 &&
						credential.credentialIdentifiers[0].recognitionType === 'PIN'
					);
				});

				const credentialHolder = await getCredentialHolder({ credentialHolderId });

				if (!pinCredential) {
					const possiblePin = getPinFromCredentialHolder(credentialHolder);

					if (possiblePin) {
						pinCredential = {
							credentialHolder: credentialHolder,
							credentialIdentifiers: [possiblePin],
						};
					}
				}

				setPinCredential(pinCredential);

				for (const credential of credentials) {
					if (credential === pinCredential) {
						continue;
					}

					credential.credentialIdentifiers = credential.credentialIdentifiers || [];
					credential.credentialHolder = credentialHolder;

					const { credentialId, version } = credential;
					let formCredential = { credentialId, version, hexValue: '', credential: credential };
					const identifier = getCredentialIdentifier(credential);

					if (identifier) {
						formCredential.hexValue = parseInt(identifier.hexValue, 16).toString();
					}

					if (pinCredential) {
						credential.credentialIdentifiers.push(pinCredential.credentialIdentifiers[0]);
					}

					formCredentials.push(formCredential);
				}

				setIsLoading(false);
				setOriginalCredentials(formCredentials);
				form.setFieldsValue({
					credentials: formCredentials,
				});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (visible && selected.credentialHolderId) {
			const { name, credentialHolderId } = selected;
			form.resetFields();
			setPersonName(name);
			refresh(credentialHolderId);
		}
	}, [selected, visible]);

	const onUpdate = () => {
		form.resetFields();
		const { credentialHolderId } = selected;
		refresh(credentialHolderId);
	};

	/**
	 * Cancel an edit / add of a card
	 */
	const onCancelCard = () => {
		form.resetFields();
		form.setFieldsValue({
			credentials: originalCredentials,
		});
	};

	return (
		<Modal
			showHelpButton={true}
			isCardForm={true}
			style={{ top: '30px' }}
			open={visible}
			maskClosable={false}
			forceRender={true}
			loading={loadingCredentials || isLoading}
			saving={saving}
			hideOk={true}
			cancelText={translate.byKey('close')}
			title={translate.byKeyFormatted('credentials_formatted_v2', [personName])}
			showDelete={false}
			onClose={onClose}
			onCancel={() => {
				onClose();
				onCancel();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form layout="vertical" size={'medium'} name="people-form" form={form} scrollToFirstError>
				<Spin active={loadingCredentials || isLoading}>
					<Form.List name="credentials">
						{(fields, { add, remove }) => {
							return (
								<div>
									{fields.map((field) => (
										<Row key={field.key} gutter={[12, 12]}>
											<Col
												md={24}
												style={{
													justifyContent: 'center',
													display: 'flex',
												}}
											>
												<CardForm
													field={field}
													remove={remove}
													form={form}
													credentialHolderId={selected ? selected.credentialHolderId : null}
													sharedPinCredential={sharedPinCredential}
													onUpdate={onUpdate}
													onCancelCard={onCancelCard}
												/>
											</Col>
										</Row>
									))}
									{fields.length < 3 &&
										hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.CREATE) && (
											<Row gutter={[12, 12]}>
												<Col
													md={24}
													style={{
														justifyContent: 'center',
														display: 'flex',
														paddingTop: '24px',
													}}
												>
													<Form.Item style={{ width: '50%' }}>
														<Button
															type="dashed"
															style={{ width: '100%' }}
															onClick={() => {
																add();
															}}
														>
															<PlusOutlined /> {translate.byKey('add_credential_v2')}
														</Button>
													</Form.Item>
												</Col>
											</Row>
										)}
								</div>
							);
						}}
					</Form.List>
				</Spin>
			</Form>
		</Modal>
	);
};
