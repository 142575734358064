export const securityLevels = {
	CARD: 'CARD ONLY',
	CARD_AND_PIN: 'CARD+PIN',
	LOCKED: 'LOCKED',
	UNLOCKED: 'OPEN',
	OPEN_ON_FIRST_CARD: 'OPEN_ON_FIRST_CARD',
	PAC: 'PAC',
	OFFICE_MODE: 'OFFICE_MODE',
};

export const standardWeek = {
	MONDAY: 'MONDAY',
	TUESDAY: 'TUESDAY',
	WEDNESDAY: 'WEDNESDAY',
	THURSDAY: 'THURSDAY',
	FRIDAY: 'FRIDAY',
	SATURDAY: 'SATURDAY',
	SUNDAY: 'SUNDAY',
};

export const authenticationModes = {
	SINGLE_CREDENTIAL: 'SINGLE_CREDENTIAL',
};
