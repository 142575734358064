import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

export const ButtonContentShow = ({ showInitial, renderContentOnClick, children, ...rest }) => {
	const [isContentRendered, setIsContentRendered] = useState(false);

	return isContentRendered || showInitial ? (
		renderContentOnClick()
	) : (
		<Button size="small" type="text" onClick={() => setIsContentRendered(true)} icon={<PlusOutlined />} {...rest}>
			{children}
		</Button>
	);
};
