import React, { useState, useEffect } from 'react';
import { useCalendars } from '~/hooks/features/calendars/useCalendars';
import { usePostHoliday, getPostHolidayKey } from '~/hooks/data/holidays/usePostHoliday';
import { Space, Button, message, Modal } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { HolidayForm, HOLIDAY_FORM_NAME } from '~/components/features/holidays/HolidayForm/HolidayForm';
import moment from 'moment';
import { css } from '@emotion/core';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AddHolidayModal = ({ open, onClose, onCreate }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();

	const { defaultCalendarId } = useCalendars();

	const { trigger: createHoliday, isMutating: isHolidayCreationLoading } = usePostHoliday(
		getPostHolidayKey({
			customerId,
			systemId,
			calendarId: defaultCalendarId,
		})
	);

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	useEffect(() => setIsSubmitDisabled(true), [open]);

	const onFormChange = (form) => {
		form
			.validateFields({ validateOnly: true })
			.then(() => setIsSubmitDisabled(false))
			.catch(({ errorFields }) => setIsSubmitDisabled(errorFields.length));
	};

	const onFormSubmit = async (form) => {
		const { name, description, dates, recurring } = form.getFieldsValue();
		await createHoliday({
			name,
			description: description,
			calendarId: defaultCalendarId,
			start: moment.utc(dates[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
			end: moment.utc(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
			recurring,
		});

		message.success(translate.byKeyFormatted('holiday_created_successfully'));
		onCreate();
	};

	return (
		<Modal
			open={open}
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			loading={isHolidayCreationLoading}
			title={translate.byKey('add_holiday')}
			onCancel={onClose}
			footer={[
				<Space align="center" css={spaceStyle}>
					<Button key="cancel" disabled={isHolidayCreationLoading} onClick={onClose}>
						{translate.byKey('cancel')}
					</Button>

					<Button
						key="submit"
						htmlType="submit"
						type="primary"
						form={HOLIDAY_FORM_NAME}
						disabled={isSubmitDisabled}
						loading={isHolidayCreationLoading}
					>
						{translate.byKey('create')}
					</Button>
				</Space>,
			]}
		>
			{open ? <HolidayForm onFormChange={onFormChange} onFormSubmit={onFormSubmit} /> : null}
		</Modal>
	);
};

const spaceStyle = css({
	width: '100%',
	justifyContent: 'flex-end',
});
