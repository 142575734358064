import useSWR from 'swr';

const fakeData = [
	{
		id: '1',
		location: 'London',
		name: 'Office Worker role',
		validFrom: '2023-09-21T15:00:00.000Z',
		validUntil: null,
	},
	{
		id: '2',
		location: 'London',
		name: 'Cleaner',
		validFrom: '2023-09-21T15:00:00.000Z',
		validUntil: '2023-09-22T15:00:00.000Z',
	},
];

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// TODO MM change when API is ready
export const useAccessProfiles = (key, config) => {
	const fetcher = async (key) => {
		// const [customerId, systemId, pageSize, pageNumber, name] = key.split(separator);
		return fakeData;
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessProfilesKey = ({ customerId, systemId, pageSize, pageNumber, name }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${name}${separator}\
accessProfiles`
		: null;
