import { EnrichedPortalStatus } from '~/constants/EnrichedPortalStatus';
import moment from 'moment';
import { securityLevels } from '~/screens/Doors/entityConstants';

export const getDoorScheduleStatus = (portal) => {
	const timeSlots = Boolean(portal?.configuration?.additionalSettings?.timeSlots)
		? JSON.parse(portal.configuration.additionalSettings.timeSlots)
		: {};

	const { standardWeek, timeIntervals } = timeSlots;
	if (!standardWeek || !timeIntervals) {
		return EnrichedPortalStatus.LOCKED;
	}

	const currentDay = moment().locale('en').format('dddd').toUpperCase();
	if (!(currentDay in standardWeek)) {
		return EnrichedPortalStatus.LOCKED;
	}

	const dayType = standardWeek[currentDay];
	if (!(dayType in timeIntervals)) {
		return EnrichedPortalStatus.LOCKED;
	}

	const intervals = timeIntervals[dayType];
	if (!Array.isArray(intervals)) {
		return EnrichedPortalStatus.LOCKED;
	}

	if (intervals.find((interval) => interval.state === securityLevels.CARD)) {
		return EnrichedPortalStatus.DEFAULT;
	}

	const unlockedIntervals = intervals.filter(
		(interval) => interval.state === securityLevels.UNLOCKED && interval.startTime && interval.endTime
	);
	if (
		unlockedIntervals.some((interval) =>
			moment().isBetween(moment().startOf('day').add(interval.startTime), moment().startOf('day').add(interval.endTime))
		)
	) {
		return EnrichedPortalStatus.UNLOCKED;
	}

	return EnrichedPortalStatus.LOCKED;
};

export const getReaderDefaults = () => {
	return {
		deniedBuzVol: '1',
		allowedBuzVol: '1',
		deniedLedType: '3',
		allowedLedType: '2',
		deniedBuzDuration: '1',
		deniedLedDuration: '1',
		allowedBuzDuration: '1',
		allowedLedDuration: '1',
		doorOpenDuration: '0',
		strikeDuration: '4',
		input1DOSType: '10',
		input1DOSMode: '1',
		input2RTEMode: '1',
	};
};

export const getStrikeDurationForDevice = (device) => {
	const result =
		Number(device.configuration?.additionalSettings?.strikeDuration || getReaderDefaults().strikeDuration) / 10;

	return isNaN(result) ? 5 : result;
};
