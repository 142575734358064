import { ControllerLockState } from '~/constants/ControllerEmergencyStatus';
import moment from 'moment';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency';
import { showWarningToast } from '~/screens/_shared/toast';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getPutControllerUpdateKey, usePutControllerUpdate } from '~/hooks/data/controllers/usePutControllerUpdate';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const actionTypes = {
	emergencyUnlock: 'EMERGENCY_UNLOCK',
	emergencyLock: 'LOCKDOWN_MODE',
	resetEmergency: 'NORMAL_MODE',
};

const controllerEventNames = {
	emergencyModeOn: 'EMERGENCY_MODE_ON',
	emergencyModeOff: 'EMERGENCY_MODE_OFF',
	lockdownModeOn: 'LOCKDOWN_MODE_ON',
	lockdownModeOff: 'LOCKDOWN_MODE_OFF',
};

const generateUpdateControllerBody = (controller, actionType, controllerLockState, timestamp = null) => {
	return {
		...controller,
		mountedStatus: controller.configuration.additionalSettings.mountedStatus,
		macAddress: controller.configuration.additionalSettings.macAddress,
		serialNumber: controller.configuration.additionalSettings.serialNumber,
		region: controller.configuration.additionalSettings.region,
		additionalSettings: {
			...controller.configuration.additionalSettings,
			requestType: actionType,
			controllerEmergencyStatus: controllerLockState,
			controllerEmergencyStatusTimeStamp: timestamp || moment().startOf('second').toISOString(),
		},
	};
};

export const useSiteEmergencyActions = () => {
	const { translate } = useLocale();

	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();

	const { trigger: updateController } = usePutControllerUpdate(
		getPutControllerUpdateKey({
			customerId,
			systemId,
			siteId: siteId,
		})
	);

	const {
		installedControllers,
		getControllerEmergencyState,
		updateControllersState,
		updateControllerState,
		getControllersIdsWithEventTypes,
		filterControllersWithProcessedRequest,
	} = useSiteControllersEmergencyRecords();

	const updateControllerAction = (body) => {
		return updateController({
			ignoreGlobalHandlers: true,
			...body,
		});
	};

	const resetController = (controller) =>
		updateControllerAction(
			generateUpdateControllerBody(controller, actionTypes.resetEmergency, ControllerLockState.NormalMode)
		);

	/**
	 * process for locking site:
	 * 1. update all controllers on site to: lockState: ControllerLockState.EmergencyLocked
	 * 2. check if "lockdownModeOn" event appeared on controllers
	 * 3. if no "lockdownModeOn" event check if controller request type processed
	 * 4. controller has no request type processed => reset controller because lockdown failed
	 * @param {*} siteId
	 * @returns
	 */
	const lock = async (siteId) => {
		const siteControllers = installedControllers?.filter((controller) => controller.siteId === siteId);
		if (!Boolean(siteControllers?.length)) {
			return;
		}

		const startTime = moment().startOf('second');
		updateControllersState({
			controllerIds: siteControllers.map((controller) => controller.controllerId),
			state: ControllerLockState.EmergencyLockPending,
		});

		const updatedControllers = await Promise.all(
			siteControllers.map(async (controller) => {
				try {
					await updateControllerAction(
						generateUpdateControllerBody(
							controller,
							actionTypes.emergencyLock,
							ControllerLockState.EmergencyLocked,
							startTime
						),
						startTime.clone().add(5, 'seconds')
					);

					return controller;
				} catch (ex) {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});

					// if update fails later it will be reset to default
					console.error(ex);
				}
			})
		);

		if (!updatedControllers?.filter(Boolean)?.length) {
			return;
		}

		const lockedControllersIds = await getControllersIdsWithEventTypes(startTime, [
			controllerEventNames.lockdownModeOn,
		]);

		updateControllersState({ controllerIds: [...lockedControllersIds], state: ControllerLockState.EmergencyLocked });

		const unlockedControllers = updatedControllers
			.filter(Boolean)
			.filter((controller) => !lockedControllersIds.includes(controller.controllerId));

		if (!unlockedControllers.length) {
			return;
		}

		const controllersWithUnprocesssedRequest = await filterControllersWithProcessedRequest(unlockedControllers);
		const controllerIdsWithUnprocessedRequest = controllersWithUnprocesssedRequest.map(
			(controller) => controller.controllerId
		);

		const controllersWithProcessedRequest = unlockedControllers.filter(
			(unlockedController) => !controllerIdsWithUnprocessedRequest.includes(unlockedController.controllerId)
		);

		updateControllersState({
			controllerIds: [...controllersWithProcessedRequest.map((controller) => controller.controllerId)],
			state: ControllerLockState.EmergencyLocked,
		});

		if (!controllersWithUnprocesssedRequest.length) {
			return;
		}

		for (const controller of controllersWithUnprocesssedRequest) {
			resetController(controller)
				.then(() => {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});
				})
				.catch(() => {
					showWarningToast(translate.byKey('controller_comms_failure_title'));
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});
				});
		}
	};

	/**
	 * process for unlocking site:
	 * 1. update all controllers on site to: lockState: ControllerLockState.EmergencyUnlocked
	 * 2. check if "emergencyModeOn" event appeared on controllers
	 * 3. if no "emergencyModeOn" event check if controller request type processed
	 * 4. controller has no request type processed => reset controller because unlocking failed
	 * @param {*} siteId
	 * @returns
	 */
	const unlock = async (siteId) => {
		const siteControllers = installedControllers?.filter((controller) => controller.siteId === siteId);
		if (!Boolean(siteControllers?.length)) {
			return;
		}

		const startTime = moment().startOf('second');
		updateControllersState({
			controllerIds: siteControllers.map((controller) => controller.controllerId),
			state: ControllerLockState.EmergencyUnlockPending,
		});

		const updatedControllers = await Promise.all(
			siteControllers.map(async (controller) => {
				try {
					await updateControllerAction(
						generateUpdateControllerBody(
							controller,
							actionTypes.emergencyUnlock,
							ControllerLockState.EmergencyUnlocked,
							startTime
						),
						startTime.clone().add(5, 'seconds')
					);

					return controller;
				} catch (ex) {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});

					// if update fails later it will be reset to default
					console.error(ex);
				}
			})
		);

		if (!updatedControllers?.filter(Boolean)?.length) {
			return;
		}

		const unlockedControllerIds = await getControllersIdsWithEventTypes(startTime, [
			controllerEventNames.emergencyModeOn,
		]);

		updateControllersState({ controllerIds: [...unlockedControllerIds], state: ControllerLockState.EmergencyUnlocked });

		const lockedControllers = updatedControllers
			.filter(Boolean)
			.filter((controller) => !unlockedControllerIds.includes(controller.controllerId));

		if (!lockedControllers.length) {
			return;
		}

		const controllersWithUnprocesssedRequest = await filterControllersWithProcessedRequest(lockedControllers);
		const controllerIdsWithUnprocessedRequest = controllersWithUnprocesssedRequest.map(
			(controller) => controller.controllerId
		);

		const controllersWithProcessedRequest = lockedControllers.filter(
			(unlockedController) => !controllerIdsWithUnprocessedRequest.includes(unlockedController.controllerId)
		);

		updateControllersState({
			controllerIds: [...controllersWithProcessedRequest.map((controller) => controller.controllerId)],
			state: ControllerLockState.EmergencyUnlocked,
		});

		if (!controllersWithUnprocesssedRequest.length) {
			return;
		}

		for (const controller of controllersWithUnprocesssedRequest) {
			resetController(controller)
				.then(() => {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});
				})
				.catch(() => {
					showWarningToast(translate.byKey('controller_comms_failure_title'));
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});
				});
		}
	};

	/**
	 * flow for reseting site:
	 * 1. update all controllers on site to: lockState: ControllerLockState.NormalMode
	 * 2. check if events "emergencyModeOff" or "lockdownModeOff" appear on controllers
	 * 3. if no events appear on controller => reset it (what is basically same as this without event checking)
	 * @param {*} siteId
	 * @returns
	 */
	const reset = async (siteId) => {
		const siteControllers = installedControllers?.filter((controller) => controller.siteId === siteId);
		if (!Boolean(siteControllers?.length)) {
			return;
		}

		const initialControllerState = getControllerEmergencyState(siteControllers[0].controllerId);

		const startTime = moment().startOf('second');
		updateControllersState({
			controllerIds: siteControllers.map((controller) => controller.controllerId),
			state: ControllerLockState.ResetEmergencyPending,
		});

		const updatedControllers = await Promise.all(
			siteControllers.map(async (controller) => {
				try {
					await updateControllerAction(
						generateUpdateControllerBody(
							controller,
							actionTypes.resetEmergency,
							ControllerLockState.NormalMode,
							startTime
						),
						startTime.clone().add(5, 'seconds')
					);

					return controller;
				} catch (ex) {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});

					// if update fails later it will be reset to default
					console.error(ex);
				}
			})
		);

		if (!updatedControllers?.filter(Boolean)?.length) {
			return;
		}

		const resetedControllerIds = await getControllersIdsWithEventTypes(startTime, [
			controllerEventNames.emergencyModeOff,
			controllerEventNames.lockdownModeOff,
		]);

		updateControllersState({ controllerIds: [...resetedControllerIds], state: ControllerLockState.NormalMode });

		const failedToResetControllers = updatedControllers
			.filter(Boolean)
			.filter((controller) => !resetedControllerIds.includes(controller.controllerId));

		if (!failedToResetControllers.length) {
			return;
		}

		for (const controller of failedToResetControllers) {
			resetController(controller)
				.then(() => {
					updateControllerState({
						controllerId: controller.controllerId,
						state: ControllerLockState.NormalMode,
					});
				})
				.catch(() => {
					showWarningToast(translate.byKey('controller_comms_failure_title'));
					updateControllerState({
						controllerId: controller.controllerId,
						state: initialControllerState,
					});
				});
		}
	};

	return {
		lock,
		unlock,
		reset,
	};
};
