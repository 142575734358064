import styled from '@emotion/styled';
import { background, border, color, position, shadow, size, space, typography } from 'styled-system';

const Text = styled.span`
	${space}
	${size}
  ${background}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
  font-family: ${(props) => props.theme.fonts.body};
`;

export default Text;
