import React from 'react';
import { Button, Divider, Form, Modal, Select, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';

import { accessScheduleField, doorField, doorsField } from '~/components/features/access-profiles/modals/form/fields';
import { useLocale } from '~/screens/_shared/AppLocale';
import { ButtonContentShow } from '~/components/shared/ButtonContentShow/ButtonContentShow';
import { getDuplicatedItemsIndexesInArray } from '~/utils/array';
import { WeeklyCalendar } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar';
import { getAccessSchedulesKey, useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { getDoorsKey, useDoors } from '~/hooks/data/doors/useDoors';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const DOORS_LIMIT = 5; // TODO MM soft limit

export const AddDoors = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { data: doors } = useDoors(getDoorsKey({ customerId, systemId }));
	const { data: accessSchedules } = useAccessSchedules(getAccessSchedulesKey({ customerId, systemId }));
	const form = Form.useFormInstance();

	const doorsDuplicationValidator = ({ field }) => {
		const index = parseInt(field.split('.')[1]);
		const fieldName = field.split('.')[2];
		const defaultAccessSchedule = accessSchedules?.accessSchedules?.find(({ name }) => name === '24/7'); // TODO MM maybe it will be a fixed id, so lets wait on BE
		const doors = form
			.getFieldValue(doorsField)
			.filter(({ [doorField]: door, [accessScheduleField]: accessSchedule } = {}) => door || accessSchedule)
			.map((door) => ({
				...door,
				accessSchedule: door.accessSchedule || defaultAccessSchedule?.accessScheduleId,
			}));

		return getDuplicatedItemsIndexesInArray(doors).includes(index) && fieldName === doorField
			? Promise.reject(translate.byKey('duplicated_door_message'))
			: Promise.resolve();
	};

	const onAccessScheduleButtonClick = (accessScheduleId) => {
		Modal.info({
			icon: null,
			title: translate.byKey('periods'),
			content: <WeeklyCalendar periods={[]} />,
		});
	};

	return (
		<Form.List name={doorsField}>
			{(fields, { add, remove }) => (
				<>
					{fields.map(({ key, name, ...rest }) => {
						return (
							<div css={fieldStyles} key={rest?.fieldKey}>
								<Space align="center" size={24}>
									<Form.Item
										{...rest}
										label={translate.byKey('door')}
										name={[name, doorField]}
										dependencies={fields.flatMap((field) => [
											[doorsField, field.name, doorField],
											[doorsField, field.name, accessScheduleField],
										])}
										rules={[
											{
												required: true,
												message: translate.byKeyFormatted('field_is_required_message', {
													fieldName: translate.byKey('door'),
												}),
											},
											{
												validator: doorsDuplicationValidator,
											},
										]}
									>
										<Select
											css={doorSelectStyles}
											placeholder={translate.byKey('select_door')}
											options={doors?.map(({ id, name }) => ({ value: id, label: name }))}
										/>
									</Form.Item>

									<Form.Item dependencies={[[doorsField, name, doorField]]} noStyle>
										{({ getFieldValue }) => {
											const isDoorSelected = Boolean(getFieldValue([doorsField, name, doorField]));
											const isAccessScheduleSelected = Boolean(getFieldValue([doorsField, name, accessScheduleField]));

											return isDoorSelected ? (
												<ButtonContentShow
													css={addMoreStyles}
													showInitial={isAccessScheduleSelected}
													renderContentOnClick={() => (
														<Form.Item
															{...rest}
															label={translate.byKey('access_schedule')}
															name={[name, accessScheduleField]}
															dependencies={fields.flatMap((field) => [
																[doorsField, field.name, doorField],
																[doorsField, field.name, accessScheduleField],
															])}
															rules={[
																{
																	required: true,
																	message: translate.byKeyFormatted('field_is_required_message', {
																		fieldName: translate.byKey('access_schedule'),
																	}),
																},
															]}
														>
															<Select
																css={accessScheduleSelectStyles}
																placeholder={translate.byKey('select_access_schedule')}
																options={accessSchedules?.accessSchedules?.map(({ accessScheduleId, name }) => ({
																	value: accessScheduleId,
																	label: name,
																}))}
															/>
														</Form.Item>
													)}
												>
													{translate.byKey('add_access_schedule')}
												</ButtonContentShow>
											) : null;
										}}
									</Form.Item>

									<Form.Item
										dependencies={[
											[doorsField, name, doorField],
											[doorsField, name, accessScheduleField],
										]}
										noStyle
									>
										{({ getFieldValue }) => {
											const selectedDoorId = getFieldValue([doorsField, name, doorField]);
											const selectedAccessScheduleId = getFieldValue([doorsField, name, accessScheduleField]);

											if (Boolean(selectedDoorId)) {
												return Boolean(selectedAccessScheduleId) ? (
													<Tooltip title={translate.byKey('show_periods')}>
														<InfoCircleOutlined
															css={iconStyles}
															onClick={() => {
																onAccessScheduleButtonClick(selectedAccessScheduleId);
															}}
														/>
													</Tooltip>
												) : (
													<Tooltip title={translate.byKey('default_access_schedule_message')}>
														<InfoCircleOutlined css={iconStyles} />
													</Tooltip>
												);
											}

											return null;
										}}
									</Form.Item>

									<Tooltip title={translate.byKey('remove')}>
										<MinusCircleOutlined
											css={iconStyles}
											onClick={() => {
												remove(name);

												setTimeout(() => {
													form.validateFields();
												}, 100);
											}}
										/>
									</Tooltip>
								</Space>
							</div>
						);
					})}

					{fields.length > 0 && fields.length < DOORS_LIMIT ? <Divider css={dividerStyles} /> : null}

					{fields.length < DOORS_LIMIT ? (
						<div>
							<Button size="small" type="text" onClick={() => add()} icon={<PlusOutlined />}>
								{translate.byKey('add_access_to_door')}
							</Button>
						</div>
					) : null}
				</>
			)}
		</Form.List>
	);
};

const fieldStyles = css({ width: '100%' });

const doorSelectStyles = css({ width: '200px !important' });

const iconStyles = css({
	fontSize: '20px',
	display: 'block',
	position: 'relative',
	top: '3px',
});

const addMoreStyles = css({ top: '3px', width: '200px' });

const accessScheduleSelectStyles = css({ width: '200px !important' });

const dividerStyles = css({ margin: '0 0 16px 0' });
