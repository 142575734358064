import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

// To get status of controllers e.g. and is it online/connected
export const useDeviceStatus = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/site/:siteId:/device-status',
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async () => {
		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': 1000,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getDeviceStatusKey = ({ customerId, systemId, siteId }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
device-status`
		: null;
