import React from 'react';
import { useTheme } from 'emotion-theming';

import { Image } from '~/components';

const Logo = (props) => {
	const theme = useTheme();
	return (
		<Image
			width={props.width || theme.brand.logo.width}
			height={props.height || theme.brand.logo.height}
			src={theme.brand.images.logo}
			alt={theme.siteName}
			{...props}
		/>
	);
};

export default Logo;
