import React, { useContext, useReducer, createContext, useEffect } from 'react';
import { useSiteControllers, getSiteControllersKey } from '~/hooks/data/controllers/useSiteControllers';
import { useReaders } from '~/hooks/data/readers/useReaders';
import { getReadersForDoorsDefaultKey } from '~/components/features/doors/Doors.utils';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { isControllerOnboarded } from '~/screens/Controllers';
import { controllerRequestTypes } from '~/screens/Controllers/config';
import {
	getStatusTransactionsMutationKey,
	useStatusTransactionsMutation,
} from '~/hooks/data/transactions/useStatusTransactionsMutation';
import { waitTimeout } from '~/shared';
import moment from 'moment';
import {
	initializeRecordsAction,
	resetAction,
	updateControllerStateAction,
	updateControllersStateAction,
} from './SiteControllersEmergencyRecords.actions';
import { siteControllersEmergencyRecordsReducer, INITIAL_STATE } from './SiteControllersEmergencyRecords.reducer';
import { ControllerLockState } from '~/constants/ControllerEmergencyStatus';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const siteControllersEmergencyRecordsContext = createContext({});

export const useSiteControllersEmergencyRecords = () => useContext(siteControllersEmergencyRecordsContext);

const refreshInterval = 1000 * 60; // 60 seconds

export const getSiteControllersEmergencyKey = ({ systemId, customerId, siteId }) =>
	customerId && systemId && siteId
		? `${getSiteControllersKey({
				systemId,
				customerId,
				siteId,
				detailLevel: 'FULL',
			})}emergencyRecords`
		: null;

export const getStatusTransactionsEmergencyKey = ({ systemId, customerId, siteId }) =>
	customerId && systemId && siteId
		? `${getStatusTransactionsMutationKey({
				systemId,
				customerId,
				siteId,
				detailLevel: 'FULL',
				pageSize: 100,
				pageNumber: 1,
			})}emergencyRecords`
		: null;

export const SiteControllersEmergencyRecordsProvider = ({ children }) => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();

	const [siteControllersEmergencyRecordsState, dispatchSiteControllersEmergencyRecords] = useReducer(
		siteControllersEmergencyRecordsReducer,
		INITIAL_STATE
	);

	const { trigger: fetchStatusTransactions } = useStatusTransactionsMutation(
		getStatusTransactionsEmergencyKey({ systemId, customerId, siteId })
	);

	const siteControllersKey = getSiteControllersEmergencyKey({
		systemId,
		customerId,
		siteId,
	});
	const { data: allControllers, mutate: fetchSiteControllers } = useSiteControllers(siteControllersKey, {
		refreshInterval,
	});

	const assignedReadersKey =
		customerId && systemId && siteId ? getReadersForDoorsDefaultKey({ customerId, systemId, siteId }) : null;
	const { data: assignedReaders } = useReaders(assignedReadersKey);

	useEffect(() => {
		dispatchSiteControllersEmergencyRecords(resetAction());
	}, [siteId]);

	useEffect(() => {
		if (
			Array.isArray(allControllers) &&
			Array.isArray(assignedReaders) &&
			!Boolean(siteControllersEmergencyRecordsState.availableControllers)
		) {
			const installedControllers = allControllers
				.filter(
					(controller) =>
						controller.type === ControllerTypes.ACCESS_CONTROL_UNIT || controller.type === ControllerTypes.CLOUD_UPDATER
				)
				.filter(isControllerOnboarded)
				.filter((controller) =>
					assignedReaders.some((reader) => reader.mapping?.port?.controllerId === controller.controllerId)
				);

			dispatchSiteControllersEmergencyRecords(initializeRecordsAction(installedControllers));
		}
	}, [allControllers, assignedReaders, siteControllersEmergencyRecordsState.availableControllers]);

	const filterControllersWithProcessedRequest = async (controllers) => {
		try {
			const controllersWithUnprocessedRequest = new Set();

			const updatedControllers = await fetchSiteControllers();

			controllers.forEach((controller) => {
				const thisController = updatedControllers.find(
					(updatedController) => updatedController.controllerId === controller.controllerId
				);

				const requestType = thisController?.configuration?.additionalSettings?.requestType;

				if (
					requestType !== controllerRequestTypes.processedSuccess.value &&
					requestType !== controllerRequestTypes.processedNa.value
				) {
					controllersWithUnprocessedRequest.add(thisController);
				}
			});

			return [...controllersWithUnprocessedRequest];
		} catch {
			return [];
		}
	};

	const getControllersIdsWithEventTypes = async (startTime, eventTypes) => {
		const interval = 4000;
		const attempts = 3;

		let timeout = 1000;

		const controllersIdsWithEvents = new Set();
		while (timeout <= attempts * interval) {
			try {
				await waitTimeout(() => {}, timeout);

				const result = await fetchStatusTransactions({
					startTime,
					endTime: moment().endOf('minute').add(timeout, 'milliseconds'),
				});

				for (const event of eventTypes.filter((event) => result.events.includes(event))) {
					controllersIdsWithEvents.add(event.controllerId);
				}
			} finally {
				timeout += interval;
			}
		}

		return [...controllersIdsWithEvents];
	};

	const getControllerEmergencyState = (controllerId) =>
		siteControllersEmergencyRecordsState.controllersLockRecords[controllerId];

	const areControllersInEmergencyState = (state) =>
		Object.keys(siteControllersEmergencyRecordsState.controllersLockRecords).length
			? Object.keys(siteControllersEmergencyRecordsState.controllersLockRecords).every(
					(controllerId) => siteControllersEmergencyRecordsState.controllersLockRecords[controllerId] === state
				)
			: false;

	const isInEmergencyMode =
		areControllersInEmergencyState(ControllerLockState.EmergencyLocked) ||
		areControllersInEmergencyState(ControllerLockState.EmergencyUnlocked);

	const updateControllersState = ({ controllerIds, state }) =>
		dispatchSiteControllersEmergencyRecords(updateControllersStateAction({ controllerIds, state }));

	const updateControllerState = ({ controllerId, state }) =>
		dispatchSiteControllersEmergencyRecords(updateControllerStateAction({ controllerId, state }));

	return (
		<siteControllersEmergencyRecordsContext.Provider
			value={{
				installedControllers: siteControllersEmergencyRecordsState.availableControllers,

				areControllersInEmergencyState,
				getControllerEmergencyState,
				isInEmergencyMode,

				updateControllerState,
				updateControllersState,

				filterControllersWithProcessedRequest,

				getControllersIdsWithEventTypes,
			}}
		>
			{children}
		</siteControllersEmergencyRecordsContext.Provider>
	);
};
