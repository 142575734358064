import moment from 'moment';
import {
	DateTimeRangeFilter,
	getDateTimeRangeFilterValueLabel,
} from '~/components/shared/filters/DateTimeRangeFilter/DateTimeRangeFilter';
import { SingleChoiceFilter } from '~/components/shared/filters/SingleChoiceFilter/SingleChoiceFilter';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { getScrollHeightOptions } from '~/constants/Table';
import { getAuditTransactionsKey, useAuditTransactions } from '~/hooks/data/transactions/useAuditTransactions';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useLocale } from '~/screens/_shared/AppLocale';
import {
	DEFAULT_DATE_RANGE,
	DEFAULT_PAGE_NUMBER,
	getActionType,
	getCategory,
	getDescription,
} from '../AuditTransactionsEvents.utils';
import { LoadingIcon } from '~/components/shared/LoadingIcon/LoadingIcon';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const DEFAULT_HTTP_METHOD = null;

const httpMethods = [
	{ method: 'POST', action: 'create_authentication' },
	{ method: 'DELETE', action: 'delete' },
	{ method: 'PUT', action: 'update' },
];

export const AuditTransactionsEventsTable = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { queryParams, setQueryParams } = useQueryParams();

	const auditTransactionsKey =
		customerId && systemId
			? getAuditTransactionsKey({
					customerId,
					systemId,
					pageSize: parseInt(queryParams?.pageSize) || DEFAULT_PAGE_SIZE,
					pageNumber: parseInt(queryParams?.pageNumber) || DEFAULT_PAGE_NUMBER,
					action: queryParams?.action,
					startTime: queryParams?.startTime || DEFAULT_DATE_RANGE.start.toISOString(),
					endTime: queryParams?.endTime || DEFAULT_DATE_RANGE.end.toISOString(),
			  })
			: null;
	const {
		data: transactions,
		isLoading: isLoadingTransactions,
		isValidating: isValidatingTransactions,
	} = useAuditTransactions(auditTransactionsKey);

	const isLoadingOrValidatingTransactions = isLoadingTransactions || isValidatingTransactions;

	const actionFilterOptions = httpMethods.map((ev) => ({ label: translate.byKey(ev.action), value: ev.method }));

	const onTableChange = (pagination, filters) => {
		const isPageSizeChanged = queryParams?.pageSize
			? pagination.pageSize !== queryParams.pageSize
			: pagination.pageSize !== DEFAULT_PAGE_SIZE;

		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: isPageSizeChanged ? 1 : pagination.current,
			action: filters?.['httpMethod']?.[0],
			startTime: filters?.['sourceTimeStamp']?.[0]?.['start']?.toISOString() || DEFAULT_DATE_RANGE.start.toISOString(),
			endTime: filters?.['sourceTimeStamp']?.[0]?.['end']?.toISOString() || DEFAULT_DATE_RANGE.end.toISOString(),
		});
	};

	return (
		<TableWithFiltersSection
			loading={{
				spinning: isLoadingOrValidatingTransactions,
				indicator: <LoadingIcon />,
			}}
			rowKey="auditLogId"
			columns={[
				{
					title: translate.byKey('action'),
					dataIndex: 'httpMethod',
					render: (value) => {
						return translate.byValue(getActionType(value));
					},
					filterDropdown: (props) => (
						<SingleChoiceFilter antdFilterProps={props} options={actionFilterOptions} showSearch={false} />
					),
					filteredValue: queryParams.action ? [queryParams.action] : null,
					enabledFilterProps: (value) => ({
						getValueLabel: () => actionFilterOptions?.find((x) => x.value === value)?.label ?? value,
						clearable: value !== DEFAULT_HTTP_METHOD,
						tooltip:
							Boolean(DEFAULT_HTTP_METHOD) &&
							translate.byKeyFormatted('reset_to', {
								value: actionFilterOptions?.find((x) => x.value === DEFAULT_HTTP_METHOD)?.label,
							}),
					}),
				},
				{
					title: translate.byKey('category'),
					dataIndex: 'requestUrl',
					render: (value) => translate.byValue(getCategory(value)),
				},
				{
					dataIndex: 'sourceTimeStamp',
					title: translate.byKey('date_time'),
					render: (value) => (value ? moment(value).format('L LT') : ''),
					filterDropdown: (props) => (
						<DateTimeRangeFilter antdFilterProps={props} defaultDateTimes={[DEFAULT_DATE_RANGE]} />
					),
					filteredValue: [
						{
							start: queryParams?.startTime ? moment(queryParams.startTime) : DEFAULT_DATE_RANGE.start,
							end: queryParams?.endTime ? moment(queryParams.endTime) : DEFAULT_DATE_RANGE.end,
						},
					],
					enabledFilterProps: (value) => ({
						getValueLabel: () => getDateTimeRangeFilterValueLabel(value),
						clearable:
							!moment(value?.start).isSame(DEFAULT_DATE_RANGE.start) ||
							!moment(value?.end).isSame(DEFAULT_DATE_RANGE.end),
						tooltip: translate.byKeyFormatted('reset_to', {
							value: translate.byKey('this_week'),
						}),
					}),
				},
				{
					title: translate.byKey('is_administrator_label'),
					dataIndex: 'userId',
				},
				{
					title: translate.byKey('description'),
					dataIndex: 'requestBody',
					render: getDescription,
				},
			]}
			onChange={onTableChange}
			dataSource={transactions?.auditDetails}
			scroll={getScrollHeightOptions()}
			pagination={{
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
				position: ['bottomCenter'],
				showSizeChanger: true,
				pageSize: queryParams?.pageSize || DEFAULT_PAGE_SIZE,
				total: transactions?.totalCount,
				current: parseInt(queryParams?.pageNumber) || DEFAULT_PAGE_NUMBER,
			}}
		/>
	);
};
