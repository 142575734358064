import { useMemo } from 'react';
import moment from 'moment';

import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getAccessTransactionsKey, useAccessTransactions } from '~/hooks/data/transactions/useAccessTransactions';
import { getStatusTransactionsKey, useStatusTransactions } from '~/hooks/data/transactions/useStatusTransactions';
import { useCurrentSystemSite } from '../site-selection/hooks/useCurrentSystemSite';

const refreshInterval = 1000 * 60; // 60 sec

export const useLiveEvents = () => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { queryParams } = useQueryParams();
	const {
		data: accessTransactions,
		isLoading: isAccessTransactionsLoading,
		isValidating: isAccessTransactionsValidating,
		mutate: refetchAccessTransactions,
	} = useAccessTransactions(
		customerId && systemId ? getAccessTransactionsKey(getLiveEventsKey({ customerId, systemId, siteId })) : null,
		{
			refreshInterval,
		}
	);
	const {
		data: statusTransactions,
		isLoading: isStatusTransactionsLoading,
		isValidating: isStatusTransactionsValidating,
		mutate: refetchStatusTransactions,
	} = useStatusTransactions(
		customerId && systemId ? getStatusTransactionsKey(getLiveEventsKey({ customerId, systemId, siteId })) : null,
		{
			refreshInterval,
		}
	);
	const events = useMemo(() => {
		return [...(accessTransactions?.events || []), ...(statusTransactions?.events || [])].sort(
			(one, two) => moment(one.dateTime) - moment(two.dateTime)
		);
	}, [accessTransactions?.events, statusTransactions?.events, queryParams?.eventNames, queryParams?.eventTypes]);

	const mutate = async () => {
		await Promise.all([refetchAccessTransactions(), refetchStatusTransactions()]);
	};

	return {
		data: events,
		isLoading: isAccessTransactionsLoading || isStatusTransactionsLoading,
		isValidating: isAccessTransactionsValidating || isStatusTransactionsValidating,
		mutate,
	};
};

const getLiveEventsKey = ({ customerId, systemId, siteId }) => ({
	customerId,
	systemId,
	siteId,
	pageNumber: 1,
	pageSize: DEFAULT_PAGE_SIZE,
	startTime: moment().startOf('day').toISOString(),
	endTime: moment().endOf('day').toISOString(),
});
