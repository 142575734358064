import { Tabs, Select, Form, Input } from 'antd';
import { Modal } from '~/components';
import React, { Fragment, useState } from 'react';
import { LocaleContext, supportedLanguages, useLocale } from '~/screens/_shared/AppLocale';
import Button from '~/components/forms/Button';
import Password from './Password';
import { getCurrentAdministrator, getHighestPrivilegedRole } from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const UserProfile = ({ onClose }) => {
	const { translate } = useLocale();
	const [currentOption, setCurrentOption] = useState(window.localStorage.getItem('selectedLanguage'));

	const { data: user } = useUserAuthData();

	const admin = Object.assign({}, getCurrentAdministrator() || {});
	admin.firstName = admin.firstName || '-';
	admin.lastName = admin.lastName || '-';

	const role = getHighestPrivilegedRole() || {};
	role.name = role.name || '-';

	const footer = [
		<Fragment key="user-profile-footer">
			<Button
				onClick={() => {
					onClose(false);
				}}
			>
				{translate.byKey('close')}
			</Button>
		</Fragment>,
	];

	return (
		<Modal
			showHelpButton={false}
			open
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			footer={footer}
			onCancel={() => onClose(false)}
			title={translate.byKey('user_profile_v2')}
			bodyStyle={{ height: '450px' }}
		>
			<Tabs
				defaultActiveKey="1"
				items={[
					{
						label: translate.byKey('user_details_v2'),
						key: '1',
						children: (
							<Form layout="vertical">
								<Form.Item label={translate.byKey('first_name')}>
									<Input value={admin.firstName} disabled={true} />
								</Form.Item>

								<Form.Item label={translate.byKey('last_name')}>
									<Input value={admin.lastName} disabled={true} />
								</Form.Item>

								<Form.Item label={translate.byKey('email_address')}>
									<Input value={user.username || '-'} disabled={true} />
								</Form.Item>

								<Form.Item label={translate.byKey('system_role_v2')}>
									<Input
										value={translate.byKey(role.name) || translate.byValue(role.name) || role.name}
										disabled={true}
									/>
								</Form.Item>
							</Form>
						),
					},
					{
						label: translate.byKey('user_preferences_v2'),
						key: '2',
						children: (
							<Form layout="vertical">
								<Form.Item label={translate.byKey('language_V2')}>
									<LocaleContext.Consumer>
										{(context) => (
											<Select
												value={currentOption}
												onChange={(languageCode) => {
													context.setLocale(languageCode);
													setCurrentOption(languageCode);
												}}
												options={supportedLanguages.map(({ name, value }) => ({
													value,
													label: translate.byKey(name),
												}))}
											/>
										)}
									</LocaleContext.Consumer>
								</Form.Item>
							</Form>
						),
					},

					{
						label: translate.byKey('password'),
						key: '3',
						children: <Password emailAddress={user.username} />,
					},
				]}
			/>
		</Modal>
	);
};
