import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Divider } from 'antd';
import { css } from '@emotion/core';

import { filterTextByPartialText } from '~/utils/filter';
import { ResetApplyFilterPanel } from '~/components/shared/filters/panels/ResetApplyFilterPanel/ResetApplyFilterPanel';
import { SearchFilterPanel } from '~/components/shared/filters/panels/SearchFilterPanel/SearchFilterPanel';
import { NoResultsFilterPanel } from '~/components/shared/filters/panels/NoResultsFilterPanel/NoResultsFilterPanel';
import { BodyFilterPanel } from '~/components/shared/filters/panels/BodyFilterPanel/BodyFilterPanel';
import { FilterItem } from '~/components/shared/filters/FilterItem/FilterItem';
import { defaultSorter } from '~/utils/sort';

export const SingleChoiceFilter = ({
	antdFilterProps,
	options,
	placeholder,
	showSearch = true,
	minWidth = '200px',
}) => {
	const {
		setSelectedKeys: setAntdSelectedKeys,
		selectedKeys: antdSelectedKeys,
		confirm,
		visible,
		clearFilters,
	} = antdFilterProps;
	const [searchValue, setSearchValue] = useState('');
	const [checkedValue, setCheckedValue] = useState(antdSelectedKeys[0] || null);
	const resetDisabled = !Boolean(antdSelectedKeys[0]);
	const applyDisabled =
		checkedValue === antdSelectedKeys[0] || (!Boolean(checkedValue) && !Boolean(antdSelectedKeys[0]));

	const panelRef = useRef(null);

	useEffect(() => {
		if (visible && antdSelectedKeys.length) {
			setCheckedValue(antdSelectedKeys[0]);
		} else if (!visible) {
			setCheckedValue(null);
		}
	}, [visible, antdSelectedKeys, setCheckedValue]);

	const onSearch = (event) => {
		setSearchValue(event.target.value);
	};

	const onSearchClear = () => {
		setSearchValue('');
	};

	const onItemCheck = (newValues) => {
		setCheckedValue(newValues?.length ? newValues[newValues.length - 1] : null);
	};

	const onReset = () => {
		setSearchValue('');
		clearFilters({ confirm: true, closeDropdown: true });
		// Timeout added to prevent flicker, smooth scroll doesn't work without it
		setTimeout(() => {
			panelRef.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
		}, 1);
	};

	const onApply = () => {
		setAntdSelectedKeys(checkedValue ? [checkedValue] : []);
		confirm();
	};

	const filteredOptions = options
		.filter(({ label }) => filterTextByPartialText(label, searchValue))
		.sort(({ label: labelOne }, { label: labelTwo }) => defaultSorter(labelOne, labelTwo));

	const onEnterPress = (event) => {
		if (event.key === 'Enter' && !applyDisabled) {
			onApply();
		}
	};

	return (
		<div style={{ minWidth }} onKeyDown={onEnterPress}>
			{showSearch ? (
				<>
					<SearchFilterPanel
						value={searchValue}
						placeholder={placeholder}
						onSearch={onSearch}
						onClear={onSearchClear}
					/>

					<Divider style={{ margin: '0px' }} />
				</>
			) : null}

			<BodyFilterPanel ref={panelRef}>
				{filteredOptions.length ? (
					<Checkbox.Group onChange={onItemCheck} value={[checkedValue]} style={{ width: '100%' }}>
						{filteredOptions.map(({ label, value, count }) => (
							<FilterItem key={value}>
								<Checkbox
									css={css`
										.ant-checkbox-inner {
											border-radius: 50%;
										}
									`}
									value={value}
								>
									{count !== undefined ? `${label} (${count})` : label}
								</Checkbox>
							</FilterItem>
						))}
					</Checkbox.Group>
				) : (
					<NoResultsFilterPanel />
				)}
			</BodyFilterPanel>

			<Divider style={{ margin: '0px' }} />

			<ResetApplyFilterPanel
				resetDisabled={resetDisabled}
				applyDisabled={applyDisabled}
				onReset={onReset}
				onApply={onApply}
			/>
		</div>
	);
};
