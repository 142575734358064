import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';

import { Button, PageHeader } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useLiveEvents } from '~/components/features/live/LiveEvents.hooks';

export const LivePageHeader = () => {
	const { translate } = useLocale();
	const { isLoading, isValidating, mutate } = useLiveEvents();

	const onDataRefetch = () => {
		mutate();
	};

	return (
		<PageHeader title={translate.byKey('live')} icon="live" showHelp={false}>
			<Button disabled={isLoading || isValidating} type="primary" icon={<ReloadOutlined />} onClick={onDataRefetch}>
				{translate.byKey('refresh')}
			</Button>
		</PageHeader>
	);
};
