/**
 * a fairly generic class for enrol/validation messages from plugin manager. (there is some data in the response we ignore)
 * 'enrollType' is the plugin type (omnikey, mde, morpho etc...depending on what was requested)
 * 'statusMsg' should have something (except validation messages) most times, like 'Success..' or 'Error...'.
 * except when no card is found on the omnikey, in which case statusMsg is empty:
 *    "data=0:formatType=0:statusMsg=:facilityCode=0:cardNumber=0:detectedType=UNKNOWN:cardNumberFullParity=0:rawPacsNumber=0:rawPacsHex=0:rawBits=0:readType=None:cardType=None;"
 * so it should be possible to do some checks on that if need be.
 *
 * 1. first check 'isValidation' is not undefined,
 *    if not then check 'validationResult' to see enrol device state so we know if enrol is even possible
 * 2. if 'isValidation' is undefined, then it means this message is an enrol result. in which case
 *    'data' should have a tag number with values populated in 'rawBits', 'cardType' for mifare/EM cards as well.
 *    For SeOS there will also be 'detectedType', 'facilityCode', 'cardNumber'.
 *
 * ENROLL EXAMPLES:
 *  ERROR: "data=0:formatType=0:statusMsg=Error - Reader does not support this card type!:facilityCode=0:cardNumber=0:detectedType=UNKNOWN:cardNumberFullParity=0:rawPacsNumber=0:rawPacsHex=0:rawBits=0:readType=None:cardType=None;"
 *  MIFARE SUCCESS: "data=1058217598:formatType=9:statusMsg=Success:facilityCode=:cardNumber=:detectedType=0:cardNumberFullParity=:rawPacsNumber=:rawPacsHex=3F131E7E:rawBits=32:readType=UID:cardType=Mifare;"
 *  SEOS 37 BIT SUCCESS: "data=8887014:formatType=14:statusMsg=Success:facilityCode=16:cardNumber=498406:detectedType=H10302_H10304_37BIT:cardNumberFullParity=68737250764:rawPacsNumber=68737250764:rawPacsHex=10010F35CC:rawBits=37:readType=PACS:cardType=Seos;"
 *  SEOS 26 BIT SUCCESS: "data=720921:formatType=14:statusMsg=Success:facilityCode=11:cardNumber=25:detectedType=H10301_26BIT:cardNumberFullParity=34996274:rawPacsNumber=34996274:rawPacsHex=02160032:rawBits=26:readType=PACS:cardType=Seos;"
 *
 * VALIDATION EXAMPLES:
 * {"enrollType":"omnikey","validatePlugin":"valid","version":"1.0.3.8"} <--- you good to go, plugin manager is installed and device is plugged in
 * {"enrollType":"omnikey","validatePlugin":"notvalid","version":"1.0.3.8"} <--- plugin manager is installed but the plugin type is not
 * {"enrollType":"omnikey","validatePlugin":"unplugged","version":"1.0.3.8"} <--- plugin manager is installed, but device is not plugged in
 *
 */
const enrollData = {
	enrollData: ({
		enrollType,
		isValidation,
		validationResult,
		data,
		facilityCode,
		cardNumber,
		rawBits,
		cardType,
		detectedType,
		statusMsg,
	}) => {
		return {
			enrollType,
			isValidation,
			validationResult,
			data,
			facilityCode,
			cardNumber,
			rawBits,
			cardType,
			detectedType,
			statusMsg,
		};
	},
};

export default enrollData;
