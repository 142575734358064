export const AccessTransactionEventNames = {
	ACCESS_DENIED: 'access_denied',
	ACCESS_GRANTED: 'access_granted',
	OFFLINE_ENCODING_STATUS: 'offline_encoding_status',
};

export const AccessTransactionEventTypes = {
	ACCESSED: 'accessed',
	ANONYMOUS: 'anonymous',
	BLOCKLISTED: 'blocklisted',
	DENIED: 'denied',
	DENIED_BLACKLISTED: 'denied_blacklisted',
	DENIED_VALIDITY: 'denied_validity',
	DURESS: 'duress',
	FACILITY_CODE: 'facility_code',
	GRANTED: 'granted',
	GRANTED_DEFAULT_TIME: 'granted_default_time',
	LOCKDOWN: 'lockdown',
	LOCKED: 'locked',
	NOT_FOUND: 'not_found',
	NOT_VALID: 'not_valid',
	OFFLINE_ENCODING_FAILED: 'offline_encoding_failed',
	OFFLINE_ENCODING_SUCCESS: 'offline_encoding_success',
	PORTAL_BLOCKED: 'portal_blocked',
	SCHEDULE: 'schedule',
	TOGGLE_LOCK: 'toggle_lock',
	TOGGLE_UNLOCK: 'toggle_unlock',
	WRONG_PIN: 'wrong_pin',
};
