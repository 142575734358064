export const protocolEnabledHosts = [
	'impro-portal-cloud-dev.azurewebsites.net',
	'impro-portal-cloud-integration.azurewebsites.net',
	'dev.incedo-business.com',
	'dev.incedo-cloud.com',
	'onboarding.api.stage.incedo.aws.assaabloy.net',
];

let themes = ['incedo'];

export default themes;
