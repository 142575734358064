import { translator } from '~/screens/_shared/AppLocale';
import { getLimits } from '~/screens/_shared/entitlement';
import { FeatureTypes } from '~/constants/FeatureTypes';

const licenseMessage = (licenseCount, key) => {
	return translator.byKeyFormatted('licensed_feature_message', [
		licenseCount.usedCount,
		translator.byKey(key),
		licenseCount.hardLimit,
	]);
};

export function getLicensedCount(system, featureType) {
	let licenseCount = getLimits(system, featureType);
	if (!licenseCount.isActive) {
		return null;
	}

	switch (featureType) {
		case FeatureTypes.HID_ONLINE:
		case FeatureTypes.APERIO_ONLINE:
			return licenseMessage(licenseCount, 'online_doors_v2');
		case FeatureTypes.APERIO_OFFLINE:
		case FeatureTypes.PULSE_OFFLINE:
			return licenseMessage(licenseCount, 'offline_doors_v2');
		case FeatureTypes.IMPROX:
			return licenseMessage(licenseCount, 'cluster_controllers');
		case FeatureTypes.PASSTHROUGH:
			return licenseMessage(licenseCount, 'updater_controllers');
		case FeatureTypes.CREDENTIAL_HOLDER:
			return licenseMessage(licenseCount, 'credential_holders_v2');
		case FeatureTypes.USB_DEVICE:
			return licenseMessage(licenseCount, 'usb_devices');
		// TODO MM add for cloud updater here when ready
		default:
			return '';
	}
}
