import { useRef } from 'react';
import useSWR from 'swr';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const separator = '**';

const defaultConfig = {
	revalidateOnFocus: false,
};

export const useGetEmeaSite = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity:
					'/administration/business-entity/:businessEntityId/customer/:endCustomerId/system/:systemId/site/:siteId',
				method: method.get,
			},
			handleDefaultApiError
		)
	);

	const fetcher = () => {
		const [businessEntityId, endCustomerId] = key.split(separator);

		return ref.current.fetchData({
			businessEntityId,
			endCustomerId,
		});
	};

	return useSWR(key, fetcher, { ...config, ...defaultConfig });
};

export const getEmeaSiteKey = ({ businessEntityId, endCustomerId, systemId, siteId }) =>
	businessEntityId && endCustomerId && systemId && siteId
		? `${businessEntityId}${separator}\
${endCustomerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
get-emea-site`
		: null;
