import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

const isExcludedValue = (value) =>
	value === '' || value === 'undefined' || value === 'null' || (Array.isArray(value) && value.length === 0);

export const useUpdateAsset = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: 'customer/:customerId/system/:systemId/site/:siteId/asset/:assetId',
				method: method.put,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg: { ignoreGlobalHandlers, assetId, ...payload } }) =>
		ref.current.fetchData({
			ignoreGlobalHandlers,
			assetId,
			...omitBy({ ...payload }, isExcludedValue),
			removeProperties: ['ignoreGlobalHandlers', 'assetId'],
		});

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getUpdateAssetKey = ({ customerId, systemId, siteId, assetId }) =>
	customerId && systemId && siteId && assetId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${assetId}${separator}\
update-asset`
		: null;

export const getUpdateAssetWithoutIdKey = ({ customerId, systemId, siteId }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
update-without-id-asset`
		: null;
