import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const LoadingFilterPanel = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
			<Spin indicator={<LoadingOutlined style={{ fontSize: '36px' }} spin />} />
		</div>
	);
};
