import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Select as ASelect } from 'antd';
import React, { useCallback } from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const CustomSelect = (props) => {
	const { isAddon, value, onChange, sharpCorners, selectionBox, ...newProps } = props;

	const onSelectChange = useCallback((value) => {
		if (onChange) {
			onChange(value);
		}
	});

	return (
		<ASelect
			css={styles({ isAddon, sharpCorners, selectionBox })}
			{...newProps}
			value={value}
			onChange={onSelectChange}
		/>
	);
};

/**
 * @type {Select}
 */

const Select = styled(CustomSelect)`
	width: 100%;

	.ant-select-selection-placeholder {
		opacity: 0.7;
	}

	.ant-select-item-option-content {
		display: flex;
		align-items: center;
		color: ${(props) => (props.loading || props.selectionBox ? 'white' : props.theme.colors.gray[400])} !important;
	}

	${layout}
	${space}
    ${flexbox}
    ${size}
    ${background}
    ${grid}
    ${color}
    ${typography}
    ${border}
    ${position}
    ${shadow}
`;

const { Option, OptGroup, defaultProps } = ASelect;

Select.Option = Option;
Select.OptGroup = OptGroup;
Select.defaultProps = defaultProps;

export default Select;

const styles = ({ isAddon, sharpCorners, selectionBox }) => {
	return (theme) => css`
		display: flex;
		.ant-select-selector {
			border-radius: ${isAddon ? '4px 0 0 4px' : '4px'} !important;
			${sharpCorners && 'border-radius: 2px !important;'}
			${isAddon && 'border-right: none !important;'}
    color: ${selectionBox ? 'white' : theme.colors.gray[500]} !important;
			border-color: ${theme.colors.gray[200]} !important;
		}
	`;
};
