import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Popconfirm, Space, Table } from 'antd';
import { MinusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { css } from '@emotion/core';

import { ClearButton } from '~/components/shared/ClearButton/ClearButton';
import { peopleField, validFromField, validUntilField } from '~/components/features/access-profiles/modals/form/fields';
import { useLocale } from '~/screens/_shared/AppLocale';

const TABLE_HEIGHT = 250;

export const SelectedPeople = ({ onEnableSelectingMode }) => {
	const { translate } = useLocale();
	const [searchValue, setSearchValue] = useState('');
	const form = Form.useFormInstance();
	const people = Form.useWatch(peopleField, form) || [];
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [validFrom, setValidFrom] = useState(null);
	const [validUntil, setValidUntil] = useState(null);
	const filteredPeople = people.filter((person) =>
		`${person.firstName} ${person.lastName}`.toLowerCase().includes(searchValue)
	);

	const onValidFromApply = () => {
		form.setFieldValue(
			peopleField,
			people.map((person) => ({
				...person,
				validFrom: selectedRowKeys.includes(person.id)
					? validFrom?.startOf('day')?.format?.('YYYY-MM-DD')
					: person.validFrom,
			}))
		);

		form.validateFields();
	};

	const onValidUntilApply = () => {
		form.setFieldValue(
			peopleField,
			people.map((person) => ({
				...person,
				validUntil: selectedRowKeys.includes(person.id)
					? validUntil?.endOf('day')?.format?.('YYYY-MM-DD')
					: person.validUntil,
			}))
		);

		form.validateFields();
	};

	const onRemove = (removedPerson) => {
		form.setFieldValue(
			peopleField,
			people.filter((person) => person.id !== removedPerson.id)
		);
	};

	const validFromRequiredValidator = (_, data) => {
		const peopleWithoutValidFrom = data?.filter((person) => !Boolean(person.validFrom));

		return Boolean(peopleWithoutValidFrom?.length)
			? Promise.reject(
					peopleWithoutValidFrom.map((person) =>
						translate.byKeyFormatted('valid_from_required_for_person_message', {
							name: `${person.firstName} ${person.lastName}`,
						})
					)
			  )
			: Promise.resolve();
	};

	const validFromAfterValidUntilValidator = (_, data) => {
		const peopleWithValidFromAfterValidUntil = data?.filter((person) =>
			Boolean(person.validFrom) && Boolean(person.validUntil)
				? moment(person.validFrom).isAfter(moment(person.validUntil))
				: false
		);

		return Boolean(peopleWithValidFromAfterValidUntil?.length)
			? Promise.reject(
					peopleWithValidFromAfterValidUntil.map((person) =>
						translate.byKeyFormatted('valid_from_after_valid_until_for_person_message', {
							name: `${person.firstName} ${person.lastName}`,
						})
					)
			  )
			: Promise.resolve();
	};

	return (
		<>
			<div css={headerStyles}>
				<Input
					value={searchValue}
					placeholder={translate.byKey('search')}
					onChange={(event) => setSearchValue(event.target.value)}
					css={searchInputStyles}
					suffix={<ClearButton onClick={() => setSearchValue('')} />}
				/>

				<Button icon={<PlusCircleOutlined />} type="primary" onClick={onEnableSelectingMode}>
					{translate.byKey('add_people')}
				</Button>
			</div>

			<Form.List
				name={peopleField}
				rules={[
					{
						validator: validFromRequiredValidator,
					},
					{
						validator: validFromAfterValidUntilValidator,
					},
				]}
			>
				{(_, __, { errors }) => (
					<>
						<Table
							css={tableStyles}
							size="small"
							scroll={{ y: TABLE_HEIGHT }}
							rowKey="id"
							dataSource={filteredPeople}
							rowSelection={{
								selectedRowKeys,
								onChange: setSelectedRowKeys,
								preserveSelectedRowKeys: true,
							}}
							footer={
								people.length !== filteredPeople.length
									? () => (
											<div>{translate.byKeyFormatted('showing_message', [filteredPeople.length, people.length])}</div>
									  )
									: null
							}
							columns={[
								{
									title: translate.byKey('first_name'),
									dataIndex: 'firstName',
								},
								{
									title: translate.byKey('last_name'),
									dataIndex: 'lastName',
								},
								{
									title: translate.byKey('valid_from'),
									dataIndex: 'validFrom',
									render: (value, person, index) => (
										<Form.Item
											name={[index, validFromField]}
											rules={[
												{
													required: true,
													message: '',
												},
											]}
											valuePropName={'date'}
											normalize={(value) => value?.startOf('day')?.format?.('YYYY-MM-DD')}
										>
											<DatePicker
												onChange={() => form.validateFields()}
												value={value ? moment(value) : null}
												format="L"
												allowClear={false}
												disabledDate={(current) => {
													const validUntilValue = form.getFieldValue([peopleField, index, validUntilField]);

													return (
														current?.isBefore(moment().startOf('day')) ||
														(validUntilValue ? current?.isAfter(moment(validUntilValue).endOf('day')) : false)
													);
												}}
											/>
										</Form.Item>
									),
								},
								{
									title: translate.byKey('valid_until'),
									dataIndex: 'validUntil',
									render: (value, person, index) => (
										<Form.Item
											name={[index, validUntilField]}
											valuePropName={'date'}
											normalize={(value) => value?.endOf('day')?.format?.('YYYY-MM-DD')}
										>
											<DatePicker
												onChange={() => form.validateFields()}
												value={value ? moment(value) : null}
												format="L"
												disabledDate={(current) => {
													const validFromValue = form.getFieldValue([peopleField, index, validFromField]);

													return (
														current?.isBefore(moment().startOf('day')) ||
														(validFromValue ? current?.isBefore(moment(validFromValue).startOf('day')) : false)
													);
												}}
											/>
										</Form.Item>
									),
								},
								{
									width: 100,
									align: 'center',
									title: translate.byKey('remove'),
									render: (_, person) => (
										<Button
											icon={<MinusOutlined />}
											type="primary"
											danger
											shape="circle"
											onClick={() => onRemove(person)}
										/>
									),
								},
							]}
							pagination={false}
						/>

						<Form.ErrorList css={errorsStyles} errors={errors} />
					</>
				)}
			</Form.List>

			<Space size={24} css={validityStyles}>
				<Form.Item label={translate.byKey('valid_from')}>
					<Space>
						<DatePicker
							value={validFrom}
							onChange={setValidFrom}
							disabled={people.length === 0}
							format="L"
							css={validityFieldStyles}
							clearIcon={<ClearButton />}
							disabledDate={(current) => current?.isBefore(moment().startOf('day')) || validUntil?.isBefore(current)}
						/>

						<Popconfirm
							title={translate.byKey('apply_valid_from_message')}
							onConfirm={onValidFromApply}
							okText={translate.byKey('yes')}
							cancelText={translate.byKey('cancel')}
							disabled={people.length === 0 || selectedRowKeys.length === 0 || !Boolean(validFrom)}
						>
							<Button disabled={people.length === 0 || selectedRowKeys.length === 0 || !Boolean(validFrom)}>
								{translate.byKey('apply')}
							</Button>
						</Popconfirm>
					</Space>
				</Form.Item>

				<Form.Item label={translate.byKey('valid_until')}>
					<Space>
						<DatePicker
							value={validUntil}
							onChange={setValidUntil}
							disabled={people.length === 0}
							format="L"
							css={validityFieldStyles}
							clearIcon={<ClearButton />}
							disabledDate={(current) => current?.isBefore(moment().startOf('day')) || validFrom?.isAfter(current)}
						/>

						<Popconfirm
							title={translate.byKey('apply_valid_until_message')}
							onConfirm={onValidUntilApply}
							okText={translate.byKey('yes')}
							cancelText={translate.byKey('cancel')}
							disabled={people.length === 0 || selectedRowKeys.length === 0}
						>
							<Button disabled={people.length === 0 || selectedRowKeys.length === 0}>{translate.byKey('apply')}</Button>
						</Popconfirm>
					</Space>
				</Form.Item>
			</Space>
		</>
	);
};

const headerStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '24px',
});

const searchInputStyles = css({ width: '250px' });

const validityStyles = css({
	marginTop: '24px',
});

const validityFieldStyles = css({
	width: '180px',
	'.ant-picker-clear': {
		opacity: 1,
	},
});

const tableStyles = css({
	'.ant-table-body': {
		'.ant-table-placeholder': {
			height: `${TABLE_HEIGHT}px`,
		},
		'.ant-form-item': {
			marginBottom: 0,
			'.ant-form-item-control': {
				'> div:nth-of-type(2)': {
					display: 'none !important',
				},
			},
		},
	},
});

const errorsStyles = css({
	marginTop: '12px',
	maxHeight: '100px',
	overflow: 'auto',
});
