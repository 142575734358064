import { Col, Form, Row } from 'antd';
import React from 'react';
import { useTheme } from 'emotion-theming';

import { Spin } from '~/components';
import getHelpHtmlText from '~/help/getHelpHtmlText';
import HelpViewNames, { pathNameToHelpViewNameMapper, pathNameToTitleKeyMapper } from '~/help/HelpViewNames';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useLocation } from 'react-router-dom';
import { useModals } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { MODAL_TYPES } from '~/components/shared/layouts/MainLayout/ModalsContainer/constants';

const HelpForm = ({ onCancel, loading }) => {
	const [form] = Form.useForm();
	const { isModalOpened } = useModals();

	const visibleHelpForm = isModalOpened(MODAL_TYPES.HELP_FORM);
	const visibleHelpControllerForm = isModalOpened(MODAL_TYPES.HELP_CONTROLLER_FORM);
	const visibleHelpCardForm = isModalOpened(MODAL_TYPES.HELP_CARD_FORM);
	const visibleHelpReaderForm = isModalOpened(MODAL_TYPES.HELP_READER_FORM);

	const theme = useTheme();
	const { translate } = useLocale();
	const location = useLocation();

	const routeSettings = {
		name: pathNameToTitleKeyMapper[location.pathname],
		helpName: pathNameToHelpViewNameMapper[location.pathname],
	};

	/**
	 * gets html text to inject
	 */
	const getHelpHTML = () => {
		if (visibleHelpControllerForm) {
			return getHelpHtmlText(HelpViewNames.CONTROLLER, true);
		} else if (visibleHelpReaderForm) {
			return getHelpHtmlText(HelpViewNames.READERS, true);
		} else if (visibleHelpCardForm) {
			return getHelpHtmlText(HelpViewNames.CARDS, true);
		} else {
			const isForm = visibleHelpForm !== undefined ? visibleHelpForm : false;
			return getHelpHtmlText(routeSettings.helpName, isForm);
		}
	};

	/**
	 * set the title to the view we are on + " Help"
	 */
	const getHelpTitle = () => {
		if (visibleHelpControllerForm) {
			return translate.byKeyFormatted('help_title_formatted', translate.byKey('controller'));
		} else if (visibleHelpReaderForm) {
			return translate.byKeyFormatted('help_title_formatted', [translate.byKey('reader')]);
		} else if (visibleHelpCardForm) {
			return translate.byKeyFormatted('help_title_formatted', [translate.byKey('card')]);
		} else {
			return translate.byKeyFormatted('help_title_formatted', [translate.byKey(routeSettings.name)]);
		}
	};

	const showVisible = () => {
		if (visibleHelpReaderForm) {
			return visibleHelpReaderForm;
		} else if (visibleHelpCardForm) {
			return visibleHelpCardForm;
		} else if (visibleHelpControllerForm) {
			return visibleHelpControllerForm;
		} else if (visibleHelpForm) {
			return visibleHelpForm;
		}
		return false;
	};

	return (
		<DraggableModalProvider>
			<DraggableModal
				destroyOnClose={true}
				open={showVisible()}
				closable={false}
				onOk={onCancel}
				onClose={onCancel}
				title={getHelpTitle()}
				zIndex={1001}
				okButtonProps={{ style: { backgroundColor: theme.colors.brand[500], borderColor: theme.colors.brand[500] } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Spin active={loading}>
					<Form layout="vertical" size={'medium'} name="help-form" form={form} scrollToFirstError>
						<Row>
							<Col md={24}>
								{routeSettings?.helpName !== undefined ? (
									<div dangerouslySetInnerHTML={{ __html: getHelpHTML() }} />
								) : (
									<div dangerouslySetInnerHTML={{ __html: '<div>No help to show for this view</div>' }} />
								)}
							</Col>
						</Row>
					</Form>
				</Spin>
			</DraggableModal>
		</DraggableModalProvider>
	);
};

export default HelpForm;
