import styled from '@emotion/styled';
import { Dropdown as ADropdown } from 'antd';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const Dropdown = styled(ADropdown)`
	${(props) => (props.isAddon ? 'display: flex;' : '')}

	& > .ant-btn:last-of-type:not(:first-of-type), & > span:last-of-type:not(:first-of-type) > .ant-btn {
		border-top-right-radius: ${(props) => (props.isAddon ? 0 : '4px')};
		border-bottom-right-radius: ${(props) => (props.isAddon ? 0 : '4px')};
		${(props) => (props.isAddon ? 'display: flex;' : '')}
		${(props) => (props.isAddon ? 'border-right: none;' : '')}
	}

	& > .ant-btn:first-of-type:not(:last-of-type),
	& > span:first-of-type:not(:last-of-type) > .ant-btn {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		${(props) => (props.isAddon ? 'display: flex;' : '')}
		${(props) => (props.isAddon ? 'flex: 1;' : '')}
	}

	button {
		color: ${(props) => props.theme.colors.gray[400]};
		border-color: ${(props) => props.theme.colors.gray[200]};
	}

	color: #a0aec0;
	border-color: #e2e8f0;
	${layout}
	${space}
    ${flexbox}
    ${size}
    ${background}
    ${grid}
    ${color}
    ${typography}
    ${border}
    ${position}
    ${shadow}
`;

const { Button, defaultProps } = ADropdown;
Dropdown.Button = Button;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
