/* eslint-disable no-sparse-arrays */
import styled from '@emotion/styled';
import { Modal as AModal, Tooltip } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

import { Button } from '~/components';
import { QuestionCircleFilled } from '@ant-design/icons';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';
import { showInfoToast } from '~/screens/_shared/toast';
import { useLocation } from 'react-router-dom';
import { pathNameToHelpViewNameMapper, pathNameToTitleKeyMapper } from '~/help/HelpViewNames';
import { useModals } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { MODAL_TYPES } from '~/components/shared/layouts/MainLayout/ModalsContainer/constants';

const StyledModal = styled(AModal)`
	.ant-modal-content {
		border-radius: 10px;
	}
	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-footer {
		padding: 16px;
	}

	.ant-spin-nested-loading > div > .ant-spin {
		background: ${(props) => props.theme.colors.white};
	}
	${layout}
	${space}
    ${flexbox}
    ${size}
    ${background}
    ${grid}
    ${color}
    ${typography}
    ${border}
    ${position}
    ${shadow}
`;

/**
 * @type {AModal}
 */
const Modal = (props) => {
	const { toggleModal } = useModals();
	const location = useLocation();
	const routeSettings = {
		name: pathNameToTitleKeyMapper[location.pathname],
		helpName: pathNameToHelpViewNameMapper[location.pathname],
	};
	const { translate } = useLocale();
	const { hideOkay } = props;
	const [buttons, setButtons] = useState(
		Array.isArray(props.footer) && props.footer.length > 0
			? props.footer
			: [
					<Button key="cancel" {...props.cancelButtonProps} onClick={props.onCancel}>
						{props.cancelText || translate.byKey('cancel')}
					</Button>,
			  ]
	);

	useEffect(() => {
		if (!hideOkay) {
			setButtons(
				props.footer
					? props.footer
					: [
							props.showDelete
								? wrapTooltip(
										props.deleteTooltipText,
										0,
										<Button
											type="primary"
											danger
											className="button-spacing"
											disabled={props.loading || props.disableDelete}
											key="delete"
											{...props.deleteButtonProps}
											onClick={props.onDelete}
										>
											{props.deleteText || translate.byKey('delete')}
										</Button>
								  )
								: null,
							!props.hideCancel
								? wrapTooltip(
										props.cancelTooltipText,
										1,
										<Button
											className="button-spacing"
											disabled={props.loading || props.disableCancel}
											key="cancel"
											{...props.cancelButtonProps}
											onClick={props.onCancel}
										>
											{props.cancelText || translate.byKey('cancel')}
										</Button>
								  )
								: null,
							!props.showRefresh
								? null
								: wrapTooltip(
										props.refreshTooltipText,
										2,
										<Button
											className="button-spacing"
											{...props.refreshButtonProps}
											key="refresh"
											disabled={props.loading || props.disableRefresh}
											loading={props.saving}
											onClick={props.onRefresh}
										>
											{props.refreshText || translate.byKey('refresh')}
										</Button>
								  ),
							props.hideOk === true
								? null
								: wrapTooltip(
										props.okTooltipText,
										4,
										<Button
											className="button-spacing"
											{...props.okButtonProps}
											key="submit"
											type="primary"
											disabled={props.loading || props.disableOk}
											loading={props.saving}
											onClick={props.onOk}
											data-testid={'btn-submit'}
										>
											{props.okText || translate.byKey('submit')}
										</Button>
								  ),
					  ]
			);
		}
	}, [props]);

	/**
	 * Wrap buttons inside a tooltip if the tooltip text is provided.
	 * Otherwise just return the button.
	 *
	 * @param {*} tooltipText
	 * @param {*} index
	 * @param {*} button
	 */
	const wrapTooltip = (tooltipText, index, button) => {
		// Button with tooltip
		if (tooltipText) {
			return (
				<Tooltip key={index} placement="topLeft" title={tooltipText} mouseEnterDelay="1">
					<span>{button}</span>
				</Tooltip>
			);
		}
		// Button
		else {
			return button;
		}
	};

	const onHelpClick = useCallback(() => {
		if (props.isControllerFeatureForm === true) {
			toggleModal(MODAL_TYPES.HELP_CONTROLLER_FORM);
		} else if (props.isReaderForm === true) {
			toggleModal(MODAL_TYPES.HELP_READER_FORM);
		} else if (props.isCardForm === true) {
			toggleModal(MODAL_TYPES.HELP_CARD_FORM);
		} else if (routeSettings.helpName !== undefined && routeSettings.helpName !== '') {
			toggleModal(MODAL_TYPES.HELP_FORM);
		} else {
			showInfoToast(translate.byKey('no_help_for_this_view_yet'));
		}
	}, []);

	const ContentHelpButton = styled(Button)`
		float: left;
		position: absolute;
		height: 30px;
		padding: 0 !important;
		float: left;
		margin-top: -66px;
	`;

	return (
		<div>
			{props.showHelpButton && (
				<StyledModal
					{...props}
					footer={buttons}
					css={css`
						.ant-modal-title {
							margin-left: 40px;
						}
					`}
				>
					{/* Contextual Help Button - there is some css magic to put this in the header, antd Modal header cannot be customised */}
					<ContentHelpButton
						css={
							props.loading
								? css`
										display: none;
								  `
								: ''
						}
						type="primary"
						className="actionButton"
						onClick={onHelpClick}
						icon={<QuestionCircleFilled />}
					/>
					{props.children}
				</StyledModal>
			)}
			{!props.showHelpButton && (
				<StyledModal {...props} footer={buttons}>
					{props.children}
				</StyledModal>
			)}
		</div>
	);
};

Modal.success = AModal.success;
Modal.info = AModal.info;
Modal.confirm = AModal.confirm;
Modal.contextType = AModal.contextType;
Modal.error = AModal.error;
Modal.warn = AModal.warn;
Modal.warning = AModal.warning;
Modal.useModal = AModal.useModal;

export default Modal;
