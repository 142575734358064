import { method } from './apiConstants';
import { useState } from 'react';
import { ApiRequester } from './ApiRequester';
import handleDefaultApiError from './ApiErrorHandler';

export const useApi = (apiConstants) => {
	const [isBusy, setIsBusy] = useState(false);

	const requester = new ApiRequester(apiConstants, handleDefaultApiError);

	return [
		(requestData, cacheOptions) => {
			setIsBusy(true);
			return requester.fetchData(requestData, cacheOptions).finally(() => setIsBusy(false));
		},
		isBusy,
	];
};

export function useGetApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.get,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function useListApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.list,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function useAuthApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.post,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function usePostApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.post,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function usePutApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.put,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function useDeleteApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.delete,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}

export function usePatchApi(entity, onError = null, additionalSettings = null) {
	return useApi(
		Object.assign(
			{
				entity: entity,
				method: method.patch,
				returnsPromise: true,
			},
			additionalSettings || {}
		)
	);
}
