import { identifierFormat } from '~/components/features/people/constants/identifierFormat';

/**
 * plugin manager will not cater for all these card types, but at least most.
 * FACILITY32_CARD32 and SIMPLE_NUMBER56 are highly unlikely numbers to be use for Incedo Lite.
 * by highly unlikely i mean never...
 * @param {*} enrollData
 */
export default function cardIdentifierFormatToUse(enrollData) {
	const cardType = enrollData.cardType;
	const seosType = enrollData.detectedType;
	const rawBits = enrollData.rawBits;
	if (rawBits === '16' || rawBits === 16) {
		return ['SIMPLE_NUMBER16', identifierFormat.SIMPLE_NUMBER16];
	} else if (rawBits === '26' || rawBits === 26) {
		if (cardType.toLowerCase().includes('seos') && seosType === 'H10301_26BIT') {
			return ['WIEGAND26', identifierFormat.WIEGAND26];
		} else {
			return ['NONE', identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
		}
	} else if (rawBits === '32' || rawBits === 32) {
		return ['SIMPLE_NUMBER32', identifierFormat.SIMPLE_NUMBER32];
		// } else if (rawBits === "35" || rawBits === 35) {
		//   if (cardType.toLowerCase().includes("seos") && seosType === "C1K_35BIT") {
		//     return ["NONE", identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
		//   } else {
		//     return ["NONE", identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
		//   }
	} else if (rawBits === '37' || rawBits === 37) {
		if (cardType.toLowerCase().includes('seos') && seosType === 'H10302_H10304_37BIT') {
			// this is the more likely response, it's not really possible to determine the exact card type here because there are 2 37 bit types
			return ['WIEGAND37_FACILITY', identifierFormat.WIEGAND37_FACILITY];
		} else if (cardType.toLowerCase().includes('seos') && seosType === 'H10302_37BIT') {
			return ['WIEGAND37', identifierFormat.WIEGAND37];
		} else {
			return ['NONE', identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
		}
	} else if (rawBits === '48' || rawBits === 48) {
		if (cardType.toLowerCase().includes('seos') && seosType === 'C1K_48BIT') {
			return ['FACILITY16_CARD32', identifierFormat.FACILITY16_CARD32];
		} else {
			return ['NONE', identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
		}
	}
	//// ignore these types for now, we dont support anyways
	// else if (rawBits === "56" || rawBits === 56) {
	//   return ["SIMPLE_NUMBER56", identifierFormat.SIMPLE_NUMBER56];
	// } else if (rawBits === "64" || rawBits === 64) {
	//   return ["FACILITY32_CARD32", identifierFormat.FACILITY32_CARD32];
	// }
	return ['NONE', identifierFormat.NONE]; // DO NOT HAVE A TYPE IN THE API FOR THIS
}
