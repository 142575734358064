import moment from 'moment';

export const isMomentDateAfterToday = (date) => (date.isSame(new Date(), 'd') ? false : date.isAfter(new Date()));

export const isMomentDateBefore2010 = (date) => date.isBefore(moment('2010-01-01', 'YYYY-MM-DD'));

export const isMomentTimeBeforeOtherMomentTime = (dateOne, dateTwo) =>
	dateOne.minutes() + dateOne.hours() * 60 <= dateTwo.minutes() + dateTwo.hours() * 60;

const daysInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const daysInWeekIndexes = daysInWeek.reduce((object, day, index) => ({ ...object, [day]: index }), {}); // monday 0, tuesday 1, ...

const sortDays = (days) => [...days].sort((dayOne, dayTwo) => daysInWeekIndexes[dayOne] - daysInWeekIndexes[dayTwo]);

export const getDaysAndTimesOverlappingIndexes = (daysAndTimes) => {
	const validDaysAndTimes = (daysAndTimes || []).filter(
		({ days, times } = { days: [], times: [] }) => days?.length && times?.length === 2
	);

	if (validDaysAndTimes.length <= 1) {
		return [];
	}

	const daysAndTimesWithOverflow = validDaysAndTimes.flatMap(({ days, times: [startTime, endTime] }, key) => {
		const alteredEndTime = endTime === '00:00' ? '24:00' : endTime;
		const isStartTimeAfterEndTime = moment(startTime, 'HH:mm').isAfter(moment(alteredEndTime, 'HH:mm'));

		if (isStartTimeAfterEndTime) {
			const sortedDays = sortDays(days);
			const overflowDays = sortedDays.map((day) => {
				const overlowDayIndex = daysInWeek.findIndex((dayInWeek) => dayInWeek === day) + 1;

				return daysInWeek[overlowDayIndex > daysInWeek.length - 1 ? 0 : overlowDayIndex];
			});
			const sortedOverflowDays = sortDays(overflowDays);

			return [
				{
					days: sortedDays,
					times: [startTime, '24:00'],
					key,
				},
				{
					days: sortedOverflowDays,
					times: ['00:00', alteredEndTime],
					key,
				},
			];
		} else {
			return [
				{
					days,
					times: [startTime, alteredEndTime],
					key,
				},
			];
		}
	});

	for (let i = 0; i < daysAndTimesWithOverflow.length; i++) {
		for (let j = 0; j < i; j++) {
			const {
				days: currentDays,
				times: [currentStartTime, currentEndTime],
				key: currentDaysAndTimesKey,
			} = daysAndTimesWithOverflow[i];
			const {
				days: nextDays,
				times: [nextStartTime, nextEndTime],
				key: nextDaysAndTimesKey,
			} = daysAndTimesWithOverflow[j];
			const overlappingDays = currentDays.filter((day) => nextDays.includes(day));

			if (overlappingDays.length) {
				const currentStartTimeAsMoment = moment(currentStartTime, 'HH:mm');
				const currentEndTimeAsMoment = moment(currentEndTime, 'HH:mm');
				const nextStartTimeAsMoment = moment(nextStartTime, 'HH:mm');
				const nextEndTimeAsMoment = moment(nextEndTime, 'HH:mm');

				if (
					currentStartTimeAsMoment.isBefore(nextEndTimeAsMoment) &&
					currentEndTimeAsMoment.isAfter(nextStartTimeAsMoment)
				) {
					return [currentDaysAndTimesKey, nextDaysAndTimesKey].sort();
				}
			}
		}
	}

	return [];
};
