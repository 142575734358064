import { Input, Item, Modal, Spin } from '~/components';
import { Form, Steps, Button, Col, Row, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { InfoOutlined, SlidersOutlined } from '@ant-design/icons';
import { translator, useLocale } from '~/screens/_shared/AppLocale';
import { BasicInfoInputs } from '~/components/features/people/modals/PeopleModal/tabs/Tabs';
import { AccessGroupsInputs } from '~/components/features/people/modals/PeopleModal/tabs/AccessGroupsTab/AccessGroupsInputs';
import { CardInputs } from '~/components/features/people/modals/PeopleModal/tabs/CredentialsTab/CardInputs';
import { useGetApi, useListApi } from '~/screens/_shared/useApi';
import { useGetPin } from '~/components/features/people/People.hooks';
import { useTheme } from 'emotion-theming';
import { useSavePerson } from '~/components/features/people/hooks/useSavePerson';
import isEmpty from 'lodash/isEmpty';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { setupState } from '~/components/features/people/People.utils';
import useGetAccessGroups from '~/screens/AccessGroups/hooks/useGetAccessGroups';
import { blockListState } from '~/components/features/people/constants/blockListState';
import mappers from '~/screens/_shared/mappers';
import {
	currentAdminIsSystemOwner,
	getHighestPrivilegedRole,
	getCurrentAdministrator,
	currentAdminHasRequiredRole,
	screens,
} from '~/screens/_shared/userRoleConstants';
import moment from 'moment';
import { ValidityInputs } from '~/components/features/people/modals/PeopleModal/tabs/ValidityTab/ValidityInputs';
import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { FeatureTypes } from '~/constants/FeatureTypes';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { PrivilegesInputs } from '~/components/features/people/modals/PeopleModal/tabs/PrivilegesTab/PrivilegesInputs';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const PeopleFormFieldName = {
	AcessProfiles: 'accessProfiles', // Hidden input
	AdministratorId: 'administratorId', // Hidden input
	CredentialHolderId: 'credentialHolderId', // Hidden input
	CredentialHolderVersion: 'credentialHolderVersion', // Hidden input
	Credentials: 'credentials',
	CredentialsToDelete: 'credentialsToDelete', // Hidden input
	Email: 'email',
	ExtraOfflineAccessProfile: 'extraOfflineAccessProfile',
	ExtraPortalName: 'extraPortalName',
	FirstName: 'firstName',
	IsAdministrator: 'isAdministrator',
	LastName: 'lastName',
	OfflineAccessProfileId: 'offlineAccessProfileId',
	OnlineAccessProfileId: 'onlineAccessProfileId',
	Phone: 'phone',
	Pin: 'pin',
	PortalIdForExtraAccess: 'portalIdForExtraAccess',
	SelectedRoleId: 'selectedRoleId',
	ShouldDelete: 'shouldDelete', // Hidden input
	SiteAccessGroups: 'siteAccessGroups', // Hidden input
	UserId: 'userId', // Hidden input
	UserVersion: 'userVersion', // Hidden input
	ValidityDateTime: 'validityDateTime',
};

export const PeopleFormStepTabName = {
	BasicInfoTab: 'BasicInfoTab',
	ValidityTab: 'ValidityTab',
	PrivilegesTab: 'PrivilegesTab',
	AccessGroupsTab: 'AccessGroupsTab',
	CredentialsTab: 'CredentialsTab',
};

const steps = [
	{
		key: PeopleFormStepTabName.BasicInfoTab,
		title: translator.byKey('basic_info'),
		status: null,
		description: null,
		displayStatus: true,
		icon: <InfoOutlined />,
	},
	{
		key: PeopleFormStepTabName.ValidityTab,
		title: translator.byKey('validity_tab_v2'),
		displayStatus: true,
		icon: <SlidersOutlined />,
	},
	{
		key: PeopleFormStepTabName.PrivilegesTab,
		title: translator.byKey('privileges_tab_v2'),
		displayStatus: true,
		icon: <SlidersOutlined />,
	},
	{
		key: PeopleFormStepTabName.AccessGroupsTab,
		title: translator.byKey('access_groups_menu_title'),
		displayStatus: true,
		icon: <SlidersOutlined />,
	},
	{
		key: PeopleFormStepTabName.CredentialsTab,
		title: translator.byKey('credentials_v2'),
		displayStatus: true,
		icon: <SlidersOutlined />,
	},
];

const StepFieldsMap = {
	[PeopleFormStepTabName.BasicInfoTab]: [
		PeopleFormFieldName.FirstName,
		PeopleFormFieldName.LastName,
		PeopleFormFieldName.Email,
		PeopleFormFieldName.Phone,
		PeopleFormFieldName.Pin,
		PeopleFormFieldName.IsAdministrator,
	],
	[PeopleFormStepTabName.ValidityTab]: [PeopleFormFieldName.ValidityDateTime],
	[PeopleFormStepTabName.PrivilegesTab]: [PeopleFormFieldName.SelectedRoleId],
	[PeopleFormStepTabName.AccessGroupsTab]: [
		PeopleFormFieldName.OnlineAccessProfileId,
		PeopleFormFieldName.OfflineAccessProfileId,
		PeopleFormFieldName.ExtraOfflineAccessProfile,
		PeopleFormFieldName.ExtraPortalName,
		PeopleFormFieldName.PortalIdForExtraAccess,
	],
	[PeopleFormStepTabName.CredentialsTab]: [PeopleFormFieldName.Credentials],
};

const { TabPane } = Tabs;

export const PeopleModal = (props) => {
	const { visible, selected, onClose, onUpdated } = props;
	const { data: userAuth } = useUserAuthData();
	const [form] = Form.useForm();
	const theme = useTheme();
	const { translate } = useLocale();

	const [currentTab, setCurrentTab] = useState(PeopleFormStepTabName.BasicInfoTab);

	const [isUpdate, setIsUpdate] = useState(false);
	const [personName, setPersonName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showPrivilegesTab, setShowPrivilegesTab] = useState(false);

	const visibleSteps = useMemo(
		() => (showPrivilegesTab ? steps : steps?.filter((step) => step.key !== PeopleFormStepTabName.PrivilegesTab)),
		[showPrivilegesTab]
	);

	const visibleStepsNamesMap = useMemo(
		() => new Map(visibleSteps.map((step, index) => [index, step.key])),
		[visibleSteps]
	);

	const currentStepIndex = visibleSteps?.findIndex((item) => item.key === currentTab);
	const isFirstStep = currentStepIndex === 0;
	const isLastStep = currentStepIndex === visibleSteps.length - 1;

	const [currentPerson, setCurrentPerson] = useState({});
	const [sameAdminAsLogin, setSameAdminAsLogin] = useState(false);
	const [adminSelected, setAdminSelected] = useState(undefined);

	const [getCredentials] = useListApi(mappers.credential);
	const [getCredentialHolder] = useGetApi(mappers.credentialHolder);
	const [getUser] = useGetApi(mappers.user);
	const [getAdministrator] = useGetApi('/administration/administrator/:administratorId');

	const [getAccessGroups] = useGetAccessGroups();
	const [getPin] = useGetPin();

	const [savePerson, isSaving, currentStep] = useSavePerson(setIsLoading, onUpdated, onClose);
	const {
		data: { system },
	} = useCurrentSystemSite();

	const isSystemOwner = currentAdminHasRequiredRole(getCurrentAdministrator(system), 'SYSTEM_OWNER');
	const isUpdatingAdminAndHasPermission = selected?.administrator ? isSystemOwner : true;
	const hasWritePermission = hasActionPermissions(userAuth, screens.PEOPLE, 'WRITE');
	const isEditDisabled = !hasWritePermission || !isUpdatingAdminAndHasPermission;

	useEffect(() => {
		if (visible) {
			const name = selected ? selected.name : translate.byKey('add_person');
			setCurrentTab(
				selected && selected.defaultSelectedTab ? selected.defaultSelectedTab : PeopleFormStepTabName.BasicInfoTab
			);

			setPersonName(name);
			setIsLoading(true);

			form.resetFields();

			const pendingCredentialHolder =
				selected && selected.credentialHolderId
					? getCredentialHolder({ credentialHolderId: selected.credentialHolderId })
					: Promise.resolve(null);

			pendingCredentialHolder
				.then(async (credentialHolder) => {
					if (credentialHolder) {
						const pendingUser = getUser(
							{ userId: credentialHolder.user.userId },
							{
								expiry: moment().add(5, 'minutes'),
								shared: true,
							}
						);

						let [{ credentials: unblockedCredentials }, { credentials: blockedCredentials }] = await Promise.all([
							getCredentials({
								params: {
									'detail-level': 'FULL',
									credential_holder_id: selected.credentialHolderId,
									'credential-states': blockListState.ENABLED,
									credential_states: blockListState.ENABLED,
								},
							}),
							getCredentials({
								params: {
									'detail-level': 'FULL',
									credential_holder_id: selected.credentialHolderId,
									'credential-states': blockListState.BLOCKED,
									credential_states: blockListState.BLOCKED,
								},
							}),
						]);

						unblockedCredentials = unblockedCredentials.map((credential) => {
							credential.isBlocked = false;
							return credential;
						});

						blockedCredentials = blockedCredentials.map((credential) => {
							credential.isBlocked = true;
							return credential;
						});

						const credentials = blockedCredentials.concat(unblockedCredentials);
						const user = await pendingUser;
						const formValues = await setupState(user, credentialHolder, credentials, getAccessGroups, getPin);

						form.setFieldsValue({
							...formValues,
							validityDateTime: [
								formValues.startDateTime,
								// When end date is undefined, the BE sets the date to 3000-01-01
								formValues.endDateTime.year() < 2999 ? formValues.endDateTime : undefined,
							],
						});
						setCurrentPerson({
							user,
							credentialHolder,
						});
					}

					let selectedAdmin;
					if (selected?.isMissingUser && selected?.administrator) {
						selectedAdmin = selected.administrator;
					} else if (form.getFieldValue('email')) {
						selectedAdmin = system.administrators.find(
							(admin) =>
								admin.username === form.getFieldValue('email') &&
								admin.firstName === form.getFieldValue('firstName') &&
								admin.lastName === form.getFieldValue('lastName')
						);
					}
					setAdminSelected(selectedAdmin);

					if (selectedAdmin) {
						if (Array.isArray(selectedAdmin.roles)) {
							const selectedRole = getHighestPrivilegedRole(system, selectedAdmin);

							form.setFieldsValue({
								isAdministrator: !!selectedRole,
								selectedRoleId: selectedRole?.roleId,
								administratorId: selectedAdmin.administratorId,
							});

							if (selectedRole && currentAdminIsSystemOwner()) {
								setShowPrivilegesTab(true);
							}
						}

						if (selected?.isMissingUser && selected?.administrator) {
							form.setFieldsValue({
								firstName: selected.administrator.firstName,
								lastName: selected.administrator.lastName,
								phone: selected.administrator.phone,
								email: selected.administrator.username || selected.administrator.email,
							});
							if (!selected.administrator.phone) {
								const admin = await getAdministrator(
									{ administratorId: selected.administrator.administratorId },
									{
										expiry: moment().add(3, 'minutes'),
									}
								);
								form.setFieldsValue({
									phone: admin.phone,
								});
							}
						}
						const admins = new Map();

						let currentAdmin = getCurrentAdministrator(system) || {};
						if (!admins.has(currentAdmin.username)) {
							admins.set(currentAdmin.username, currentAdmin);
						}
						const admin = admins.get(currentAdmin.username);
						setSameAdminAsLogin(() => {
							return selected.administrator?.email === admin?.email;
						});
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		const isUpdate = !!(selected && selected.credentialHolderId);
		setIsUpdate(isUpdate);
		setShowPrivilegesTab(false);
	}, [visible, selected]);

	const nextStep = () => {
		const currentStepName = visibleStepsNamesMap.get(currentStepIndex);
		const currentStepFieldNames = StepFieldsMap[currentStepName];

		form
			.validateFields(currentStepFieldNames)
			.then(() => {
				if (currentStepIndex + 1 < visibleSteps.length) {
					setCurrentTab(visibleSteps[currentStepIndex + 1].key);
				}
			})
			.catch((errorFields) => {
				onFinishFailed(errorFields);
			});
	};

	const previousStep = () => {
		if (currentStepIndex > 0) {
			onStepperChange(currentStepIndex - 1);
		}
	};

	const onTabChange = (newTab) => {
		setCurrentTab(newTab);
	};

	const onStepperChange = (newTabIndex) => {
		let fieldsToValidate = [];
		const isLowerStep = newTabIndex < currentStepIndex;
		const isHigerStep = newTabIndex > currentStepIndex;

		if (isLowerStep) {
			// If user is returning to one of the previous steps, trigger validation only for touched fields
			const touchedFields = Object.keys(form.getFieldsValue(null, ({ touched }) => touched));
			fieldsToValidate = touchedFields;
		}

		if (isHigerStep) {
			// If user clicked on one of the next steps trigger validaton for all previus steps
			const previousStepsNames = Object.keys(StepFieldsMap)
				.filter((_, index) => index < newTabIndex)
				.map((key) => key);
			fieldsToValidate = previousStepsNames.map((name) => StepFieldsMap[name]).flat();
		}

		form
			.validateFields(fieldsToValidate)
			.then(() => setCurrentTab(visibleSteps[newTabIndex].key))
			.catch((errorFields) => onFinishFailed(errorFields));
	};

	const onFinish = (formData) => {
		form
			.validateFields()
			.then(() => savePerson(formData))
			.catch((errorFields) => onFinishFailed(errorFields));
	};

	const style = {
		marginTop: isUpdate ? '-40px' : '0',
		paddingLeft: '10px',
		paddingRight: '10px',
	};

	const renderTabBar = (props, DefaultTabBar) => {
		return isUpdate ? <DefaultTabBar {...props} /> : <span />;
	};

	const onFinishFailed = ({ errorFields }) => {
		for (const field of errorFields) {
			if (field?.name[0] && ['firstName', 'lastName', 'email', 'phone'].includes(field.name[0])) {
				const basicInfo = visibleSteps.find((step) => step.key === PeopleFormStepTabName.BasicInfoTab);
				setCurrentTab(basicInfo.key);
				break;
			} else if (field?.name[0] && field.name[0] === 'selectedRoleId') {
				const privilegesTab = visibleSteps.find((step) => step.key === PeopleFormStepTabName.PrivilegesTab);
				setCurrentTab(privilegesTab.key);
				break;
			}
		}
	};

	const onPersonDelete = () => {
		if (sameAdminAsLogin && showPrivilegesTab) {
			Modal.warn({
				okText: translate.byKey('ok'),
				title: translate.byKey('cant_delete_v2'),
				content: translate.byKey('cant_delete_logged_in_user_message_v2'),
			});
		} else {
			Modal.confirm({
				title: translate.byKey('confirm_delete'),
				content: translate.byKey('are_you_sure_you_want_to_delete_this_person'),
				okText: translate.byKey('delete'),
				okButtonProps: {
					style: {
						backgroundColor: theme.colors.brand[500],
						borderColor: theme.colors.brand[500],
					},
				},
				cancelText: translate.byKey('cancel'),
				onOk: () => {
					form.setFieldsValue({
						shouldDelete: true,
					});
					const formData = form.getFieldsValue();
					handleDeletePerson(formData);
				},
			});
		}
	};

	const handleDeletePerson = (formData) => {
		savePerson(formData);
	};

	const renderDeleteButton = () => {
		const hasActionPermission = hasActionPermissions(
			userAuth,
			userConstants.screens.PEOPLE,
			userConstants.actions.DELETE
		);
		const deleteButton = (
			<Button
				danger
				type="primary"
				disabled={isLoading || isSaving}
				className="button-spacing"
				key="delete"
				onClick={onPersonDelete}
			>
				{translate.byKey('delete')}
			</Button>
		);

		if (isUpdate && !isEmpty(currentPerson) && hasActionPermission) {
			const currentAdministrator = userConstants.getCurrentAdministrator(system);
			const isSelectedCredentialHolderAnAdmin = userConstants.isGivenUserAnAdministrator(
				currentPerson,
				system.administrators
			);
			if (!isSelectedCredentialHolderAnAdmin) {
				const currentUserHasSystemOwnerRole = userConstants.currentAdminHasRequiredRole(currentAdministrator, [
					'SYSTEM_OWNER',
					'SYSTEM_OPERATOR',
					'SYSTEM_INSTALLER',
				]);
				if (currentUserHasSystemOwnerRole) {
					return deleteButton;
				}
				return null;
			} else {
				const currentUserHasSystemOwnerRole = userConstants.currentAdminHasRequiredRole(currentAdministrator, [
					'SYSTEM_OWNER',
				]);
				if (currentUserHasSystemOwnerRole) {
					return deleteButton;
				}
				return null;
			}
		}
	};

	const footer = [
		<Row key="people-page-footer">
			<Col span={10}>{getLicensedCount(system, FeatureTypes.CREDENTIAL_HOLDER)}</Col>
			<Col span={14}>
				<div key="door-form-footer" className="steps-action">
					{renderDeleteButton()}
					{!isFirstStep && !isUpdate && (
						<Button style={{ margin: '0 8px' }} disabled={isLoading || isSaving} onClick={() => previousStep()}>
							{translate.byKey('previous')}
						</Button>
					)}

					{!isLastStep && !isUpdate && (
						<Button disabled={isLoading || isSaving} type="primary" onClick={() => nextStep()}>
							{translate.byKey('next')}
						</Button>
					)}

					{isUpdate && (
						<>
							<Button disabled={isLoading || isSaving} onClick={onClose}>
								{translate.byKey('cancel')}
							</Button>
							{isUpdate &&
								!isEmpty(currentPerson) &&
								hasActionPermissions(userAuth, userConstants.screens.PEOPLE, userConstants.actions.WRITE) &&
								!isEditDisabled && (
									<Button type="primary" loading={isLoading || isSaving} onClick={form.submit}>
										{translate.byKey('update')}
									</Button>
								)}
						</>
					)}

					{isLastStep && !isUpdate && (
						<Button loading={isLoading || isSaving} type="primary" onClick={form.submit}>
							{translate.byKey('submit')}
						</Button>
					)}
				</div>
			</Col>
		</Row>,
	];

	const tabContents = {
		[PeopleFormStepTabName.BasicInfoTab]: (step) => (
			<TabPane
				forceRender={true}
				tab={step.status === 'error' ? translate.byKey('basic_info') + ' *' : translate.byKey('basic_info')}
				key={PeopleFormStepTabName.BasicInfoTab}
			>
				<BasicInfoInputs
					isEditDisabled={isEditDisabled}
					form={form}
					visible={currentTab === PeopleFormStepTabName.BasicInfoTab}
					isUpdate={isUpdate}
					sameAdminAsLogin={sameAdminAsLogin}
				/>
			</TabPane>
		),
		[PeopleFormStepTabName.ValidityTab]: (step) => (
			<TabPane
				forceRender={true}
				tab={step.status === 'error' ? translate.byKey('validity_tab_v2') + ' *' : translate.byKey('validity_tab_v2')}
				key={PeopleFormStepTabName.ValidityTab}
			>
				<ValidityInputs isEditDisabled={isEditDisabled} />
			</TabPane>
		),
		[PeopleFormStepTabName.PrivilegesTab]: (step) =>
			form.getFieldValue('isAdministrator') === true &&
			showPrivilegesTab && (
				<TabPane
					forceRender={true}
					tab={
						step.status === 'error' ? translate.byKey('privileges_tab_v2') + ' *' : translate.byKey('privileges_tab_v2')
					}
					key={PeopleFormStepTabName.PrivilegesTab}
				>
					<PrivilegesInputs
						isEditDisabled={isEditDisabled}
						adminSelected={adminSelected}
						form={form}
						visible={currentTab === PeopleFormStepTabName.PrivilegesTab}
						isUpdate={isUpdate}
						sameAdminAsLogin={sameAdminAsLogin}
					/>
				</TabPane>
			),
		[PeopleFormStepTabName.AccessGroupsTab]: (step) => (
			<TabPane
				forceRender={true}
				tab={
					step.status === 'error'
						? translate.byKey('access_groups_menu_title') + ' *'
						: translate.byKey('access_groups_menu_title')
				}
				key={PeopleFormStepTabName.AccessGroupsTab}
			>
				<AccessGroupsInputs isEditDisabled={isEditDisabled} form={form} currentPerson={currentPerson} />
			</TabPane>
		),
		[PeopleFormStepTabName.CredentialsTab]: (step) => (
			<TabPane
				forceRender={true}
				tab={step.status === 'error' ? translate.byKey('credentials_v2') + ' *' : translate.byKey('credentials_v2')}
				key={PeopleFormStepTabName.CredentialsTab}
			>
				<div>
					<CardInputs isEditDisabled={isEditDisabled} selected={selected} form={form} />
				</div>
			</TabPane>
		),
	};

	return (
		<Modal
			getContainer={false}
			showHelpButton={true}
			width={'44rem'}
			open={visible}
			maskClosable={false}
			destroyOnClose
			title={personName}
			onClose={onClose}
			onCancel={onClose}
			footer={footer}
		>
			<Spin active={isSaving || isLoading} text={currentStep}>
				<Steps
					size="small"
					current={currentStepIndex}
					onChange={onStepperChange}
					hidden={isUpdate}
					items={visibleSteps.map(({ status, description, title, key }) => ({
						status,
						description,
						title,
						key: `step_${key}`,
					}))}
				/>

				<div style={{ marginTop: '20px' }} className="steps-content">
					<Form
						autoComplete="off"
						layout="vertical"
						size="medium"
						name="v2-people-form"
						form={form}
						preserve="false"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						scrollToFirstError
						onFieldsChange={(changedFields) => {
							const isAdministratorChecked = changedFields.find((field) => field.name[0] === 'isAdministrator');
							if (changedFields.length > 0 && isAdministratorChecked?.value === true) {
								setShowPrivilegesTab(true);
								const existingAdmin = system.administrators.find(
									(admin) => admin.administratorId === form.getFieldValue('administratorId')
								);
								if (existingAdmin) {
									form.setFieldsValue({
										email: existingAdmin.username || existingAdmin.email,
									});
								}
							} else if (changedFields.length > 0 && isAdministratorChecked?.value === false) {
								setShowPrivilegesTab(false);
							}
						}}
					>
						<Tabs
							size="large"
							style={style}
							defaultActiveKey={PeopleFormStepTabName.BasicInfoTab}
							activeKey={currentTab}
							onTabClick={onTabChange}
							renderTabBar={renderTabBar}
						>
							{visibleSteps.reduce((tabs, step) => {
								if (step.key in tabContents) {
									tabs.push(tabContents[step.key](step));
								}
								return tabs;
							}, [])}
						</Tabs>

						<Item name="userId" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="credentialHolderId" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="credentialHolderId" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="userVersion" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="credentialHolderVersion" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="accessProfiles" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="credentialsToDelete" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="shouldDelete" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
					</Form>
				</div>
			</Spin>
		</Modal>
	);
};
