import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import React from 'react';
import { background, border, color, layout, position, shadow, size, space, typography } from 'styled-system';

const StyledIcon = styled.span`
	display: flex;
	${layout}
	${space}
  ${size}
  ${background}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
`;

const Icon = ({ name, size, color, ...props }) => {
	const { icons, colors } = useTheme();
	const currentIcon = name && icons[name] ? icons[name] : icons['question'];

	let newColor = '';
	const themeColor = color && color.split('.');
	if (themeColor && themeColor.length > 1) {
		newColor = colors[themeColor[0]][themeColor[1]];
	} else {
		newColor = color;
	}

	return (
		<StyledIcon {...props} role="img" aria-label={`${name}-icon`}>
			<svg width={size || 24} color={newColor || 'currentColor'} viewBox={currentIcon.viewBox}>
				{currentIcon.path}
			</svg>
		</StyledIcon>
	);
};

export default Icon;
