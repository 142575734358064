import React, { useState } from 'react';
import { Tag, TimePicker, Tooltip } from 'antd';
import moment from 'moment';

import { useLocale } from '~/screens/_shared/AppLocale';
import {
	tagsStyles,
	tagStyles,
	weekPeriodPickerStyles,
} from '~/components/shared/calendars/WeekPeriodPicker/WeekPeriodPickerStyles';
import { ClearButton } from '~/components/shared/ClearButton/ClearButton';

const daysInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const WeekPeriodPicker = ({ value, onChange, prefix }) => {
	const { translate } = useLocale();
	const [days, setDays] = useState(value?.days || []);
	const [times, setTimes] = useState(value?.times || ['', '']);

	const onDayClick = (checked, day) => {
		const updatedDays = new Set(days);

		if (checked) {
			updatedDays.add(day);
		} else {
			updatedDays.delete(day);
		}

		setDays(Array.from(updatedDays));
		onChange({
			days: Array.from(updatedDays),
			times,
		});
	};

	const onTimesChange = (_, [startTime, endTime]) => {
		const newTimes = [startTime, endTime];

		setTimes(newTimes);
		onChange({
			days,
			times: newTimes,
		});
	};

	const onTimesReset = () => {
		const newTimes = ['', ''];

		setTimes(newTimes);
		onChange({
			days,
			times: newTimes,
		});
	};

	return (
		<div css={weekPeriodPickerStyles}>
			{prefix}

			<div css={tagsStyles}>
				{daysInWeek.map((day) => (
					<Tooltip key={day} title={translate.byKey(day)}>
						<span>
							<Tag.CheckableTag
								css={tagStyles}
								checked={days.includes(day)}
								onChange={(checked) => onDayClick(checked, day)}
							>
								{translate.byKey(day)[0]}
							</Tag.CheckableTag>
						</span>
					</Tooltip>
				))}
			</div>

			<div>
				<TimePicker.RangePicker
					value={times.map((time) => (time ? moment(time, 'HH:mm') : null))}
					placeholder={[translate.byKey('start_time'), translate.byKey('end_time')]}
					format="HH:mm"
					size="small"
					minuteStep={15}
					allowClear={false}
					showNow={false}
					order={false}
					suffixIcon={null}
					onChange={onTimesChange}
				/>
				<ClearButton onClick={onTimesReset} />
			</div>
		</div>
	);
};
