import { useLocale } from '~/screens/_shared/AppLocale';
import { Button, Col, DatePicker, Form, Row, Space } from 'antd';
import React from 'react';
import { Item } from '~/components';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const ValidityInputs = ({ isEditDisabled }) => {
	const form = Form.useFormInstance();
	const { translate } = useLocale();

	return (
		<>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<Item name="validityDateTime" label={translate.byKey('valid_from') + ' - ' + translate.byKey('valid_until')}>
						<RangePicker
							allowEmpty={[true, true]}
							disabled={isEditDisabled}
							disabledDate={(current) => {
								return (
									(current && current < moment().add(-1, 'days').endOf('day')) ||
									(current && current > moment().add(10, 'years').endOf('day'))
								);
							}}
							showToday={true}
							placeholder={[translate.byKey('valid_from'), translate.byKey('valid_until')]}
							style={{ width: '100%' }}
							showTime={{
								hideDisabledOptions: true,
								defaultValue: [moment('00:00', 'HH:mm'), moment('11:59', 'HH:mm')],
							}}
							format="L HH:mm"
							minuteStep={15}
							renderExtraFooter={() => (
								<Space style={{ padding: '8px' }}>
									<Button
										size="small"
										type="ghost"
										onClick={() => form.setFieldValue('validityDateTime', [moment(), moment().add(1, 'day')])}
									>
										1 {translate.byKey('day')}
									</Button>
									<Button
										size="small"
										type="ghost"
										onClick={() => form.setFieldValue('validityDateTime', [moment(), moment().add(1, 'week')])}
									>
										1 {translate.byKey('week')}
									</Button>
									<Button
										size="small"
										type="ghost"
										onClick={() => form.setFieldValue('validityDateTime', [moment(), moment().add(1, 'month')])}
									>
										1 {translate.byKey('month')}
									</Button>
									<Button
										size="small"
										type="ghost"
										onClick={() => form.setFieldValue('validityDateTime', [moment(), moment().add(1, 'year')])}
									>
										1 {translate.byKey('year')}
									</Button>
								</Space>
							)}
						/>
					</Item>
				</Col>
			</Row>
			<div style={{ height: '10rem' }} />
		</>
	);
};
