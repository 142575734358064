import useSWR from 'swr';

const fakeData = [
	{
		id: 'person id 1',
		firstName: 'John',
		lastName: 'Doe',
		emailAddress: 'john.doe@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 2',
		firstName: 'Sara',
		lastName: 'Doe',
		emailAddress: 'sara.doe@abc.com',
		phoneNumber: '123 456',
		validFrom: null,
		validUntil: null,
	},
	{
		id: 'person id 3',
		firstName: 'John 2',
		lastName: 'Doe 2',
		emailAddress: 'john.doe2@abc.com',
		phoneNumber: '123 456',
		validFrom: null,
		validUntil: null,
	},
	{
		id: 'person id 4',
		firstName: 'John 3',
		lastName: 'Doe 3',
		emailAddress: 'john.doe3@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 5',
		firstName: 'John 4',
		lastName: 'Doe 4',
		emailAddress: 'john.doe4@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 6',
		firstName: 'John 5',
		lastName: 'Doe 5',
		emailAddress: 'john.doe5@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 7',
		firstName: 'John 6',
		lastName: 'Doe 6',
		emailAddress: 'john.doe6@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 8',
		firstName: 'John 7',
		lastName: 'Doe 7',
		emailAddress: 'john.doe7@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 9',
		firstName: 'John 8',
		lastName: 'Doe 8',
		emailAddress: 'john.doe8@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
	{
		id: 'person id 10',
		firstName: 'John 9',
		lastName: 'Doe 9',
		emailAddress: 'john.do92@abc.com',
		phoneNumber: '123 456',
		validFrom: '2023-11-14',
		validUntil: '2024-11-14',
	},
];

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// TODO MM change when API is ready
export const useCredentialHolders = (key, config) => {
	const fetcher = async (key) => {
		const [customerId, systemId, siteId, pageSize, pageNumber, name, ids] = key.split(separator);
		const idsAsArray = ids.split(',');

		return name
			? fakeData.filter(
					(person) =>
						`${person.firstName} ${person.lastName}`.toLowerCase().includes(name) || idsAsArray.includes(person.id)
			  )
			: fakeData;
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getCredentialHoldersKey = ({ customerId, systemId, siteId, pageSize, pageNumber, name, ids }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${name}${separator}\
${ids?.join(',')}${separator}\
credentialHolders`
		: null;
