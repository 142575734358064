import { AccessTransactionEventNames, AccessTransactionEventTypes } from '~/constants/AccessTransactionEvents';
import { translator } from '~/screens/_shared/AppLocale';

export const defaultPageNumber = 1;
export const defaultEventName = '';
export const defaultEventType = '';
export const defaultSiteName = '';
export const defaultEventSource = '';
export const defaultPerson = '';
export const defaultControllerId = '';

export const personColumnRenderer = (value) => {
	// Requested that we show "Unknown" instead of NA for clarity.
	if (value === 'NA' || value === 'N/A') {
		return translator.byValue('Unknown');
	}

	return value || '';
};

export const formatCredentialHolderName = (name) => {
	if (name === translator.byValue('Unknown')) {
		return 'NA';
	} else {
		return name;
	}
};

export const getAccessTransactionEventNameFilter = () =>
	Object.values(AccessTransactionEventNames)
		.sort((a, b) => (a > b ? 1 : -1))
		.map((eventName) => ({ label: translator.byKey(eventName), value: eventName }));

export const getAccessTransactionEventTypeFilter = () =>
	Object.values(AccessTransactionEventTypes)
		.sort((a, b) => (a > b ? 1 : -1))
		.map((eventType) => ({ label: translator.byKey(eventType), value: eventType }));
