const HelpViewNames = {
	LIVE: 'LIVE',
	PEOPLE: 'PEOPLE',
	CARDS: 'CARDS',
	CONTROLLER: 'CONTROLLER',
	CONTROLLERS: 'CONTROLLERS',
	DOORS: 'DOORS',
	READERS: 'READERS',
	ACCESSREPORT: 'ACCESSREPORT',
	STATUSREPORT: 'STATUSREPORT',
	AUDITREPORT: 'AUDITREPORT',
	HOLIDAYS: 'HOLIDAYS',
	ACCESSGROUPS: 'ACCESSGROUPS',
	ACCESS_SCHEDULES: 'ACCESS_SCHEDULES',
	ACCESS_PROFILES: 'ACCESS_PROFILES',
	USBDEVICES: 'USBDEVICES',
};

export default HelpViewNames;

export const pathNameToHelpViewNameMapper = {
	'/live': HelpViewNames.LIVE,
	'/people': HelpViewNames.PEOPLE,
	'/controllers': HelpViewNames.CONTROLLERS,
	'/doors': HelpViewNames.DOORS,
	'/access-log': HelpViewNames.ACCESSREPORT,
	'/device-log': HelpViewNames.STATUSREPORT,
	'/audit-logs': HelpViewNames.AUDITREPORT,
	'/holidays': HelpViewNames.HOLIDAYS,
	'/access-groups': HelpViewNames.ACCESSGROUPS,
	'/usb-devices': HelpViewNames.USBDEVICES,
	'/access-schedules': HelpViewNames.ACCESS_SCHEDULES,
	'/access-profiles': HelpViewNames.ACCESS_PROFILES,
};

export const pathNameToTitleKeyMapper = {
	'/live': 'live_menu_title',
	'/people': 'people_menu_title',
	'/controllers': 'connected_devices',
	'/doors': 'doors_menu_title',
	'/access-log': 'access_transactions_menu_title',
	'/device-log': 'status_transactions_menu_title',
	'/audit-logs': 'audit_translations_menu_title',
	'/holidays': 'holidays',
	'/access-groups': 'access_groups_menu_title',
	'/usb-devices': 'usb_devices',
	'/access-schedules': 'access_schedules',
	'/access-profiles': 'access_profiles',
};
