import React, { useState } from 'react';
import { Modal, message } from 'antd';

import { useLocale } from '~/screens/_shared/AppLocale';
import { UsbDeviceForm } from '~/components/features/usb-devices/UsbDeviceForm/UsbDeviceForm';
import { getUpdateControllerKey, useUpdateController } from '~/hooks/data/controllers/useUpdateController';
import { useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getUsbDevicesKey } from '~/components/features/usb-devices/UsbDevicesTable/UsbDevicesTable.utils';
import { getDeleteControllerKey, useDeleteController } from '~/hooks/data/controllers/useDeleteController';
import { EditUsbDeviceModalFooter } from '~/components/features/usb-devices/modals/edit-usb-device/EditUsbDeviceModalFooter/EditUsbDeviceModalFooter';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { FeatureTypes } from '~/constants/FeatureTypes';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const UsbTypesToFeatureTypes = {
	[ControllerTypes.DESKTOP_UPDATER]: FeatureTypes.USB_DEVICE,
	// [ControllerTypes.CLOUD_UPDATER]: FeatureTypes.USB_DEVICE, // TODO add usb device feature type when it is ready on Zuora
};

export const EditUsbDeviceModal = ({ usbDevice, onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { trigger: updateUsbDevice, isMutating: isUpdatingUsbDevice } = useUpdateController(
		getUpdateControllerKey({
			customerId,
			systemId,
			siteId,
			controllerId: usbDevice.controllerId,
		})
	);
	const { trigger: deleteUsbDevice, isMutating: isDeletingUsbDevice } = useDeleteController(
		getDeleteControllerKey({
			customerId,
			systemId,
			siteId,
			controllerId: usbDevice.controllerId,
		})
	);
	const { mutate: reloadUsbDevices } = useSiteControllers(
		getUsbDevicesKey({
			customerId,
			systemId,
			siteId,
		}),
		{
			revalidateOnMount: false,
		}
	);
	const [isFormDirtyAndValid, setIsFormDirtyAndValid] = useState(false);

	const formDefaultValues = {
		name: usbDevice.name,
		description: usbDevice.description,
	};

	const onFormChange = async (values, form) => {
		let isAnyFieldDirty = false;
		let isAnyFieldInvalid = false;

		Object.keys(values).forEach((key) => {
			if (values[key] !== formDefaultValues[key]) {
				isAnyFieldDirty = true;
			}
		});

		await form.validateFields().catch(({ errorFields }) => {
			if (errorFields.length) {
				isAnyFieldInvalid = true;
			}
		});

		setIsFormDirtyAndValid(isAnyFieldDirty && !isAnyFieldInvalid);
	};

	const onFormSubmit = ({ name, description }) => {
		updateUsbDevice(
			{
				name: name !== usbDevice.name ? name : undefined,
				description: description !== usbDevice.description ? description : undefined,
				controllerId: usbDevice.controllerId,
				ignoreGlobalHandlers: true, // to ignore default error handling
			},
			{
				onError: () => {
					message.error(translate.byKey('usb_device_generic_error_message'));
				},
				onSuccess: () => {
					onClose();
					message.success(translate.byKey('usb_device_updated_message'));
					reloadUsbDevices();
				},
			}
		);
	};

	const onDeleteUsbDevice = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('are_you_sure_you_want_to_delete_this_usb_device'),
			okText: translate.byKey('delete'),
			cancelText: translate.byKey('cancel'),
			onOk: async () => {
				deleteUsbDevice(
					{
						controllerId: usbDevice.controllerId,
						ignoreGlobalHandlers: true, // to ignore default error handling
					},
					{
						onError: () => {
							message.error(translate.byKey('usb_device_generic_error_message'));
						},
						onSuccess: () => {
							onClose();
							message.success(translate.byKey('usb_device_deleted_message'));
							reloadUsbDevices();
						},
					}
				);
			},
		});
	};

	return (
		<Modal
			open
			title={usbDevice.name}
			onCancel={onClose}
			maskClosable={false}
			width={600}
			footer={
				<EditUsbDeviceModalFooter
					usbDeviceFeatureType={UsbTypesToFeatureTypes[usbDevice.type]}
					isSubmitDisabled={isUpdatingUsbDevice || isDeletingUsbDevice || !isFormDirtyAndValid}
					isSubmitting={isUpdatingUsbDevice}
					isDeleting={isDeletingUsbDevice}
					onDeleteUsbDevice={onDeleteUsbDevice}
					onClose={onClose}
				/>
			}
		>
			<UsbDeviceForm
				disabled={isUpdatingUsbDevice || isDeletingUsbDevice}
				defaultValues={formDefaultValues}
				onFormChange={onFormChange}
				onFormSubmit={onFormSubmit}
			/>
		</Modal>
	);
};
