import { blockListState } from '~/components/features/people/constants/blockListState';
import moment from 'moment';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useListApi, usePutApi } from '~/screens/_shared/useApi';
import { method } from '~/screens/_shared/useApi/apiConstants';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import { systemLimits } from '~/screens/_shared/systemLimits';
import { useState } from 'react';
import { isOfflineCredential } from '~/components/features/people/People.utils';
import mappers from '~/screens/_shared/mappers';
import { waitTimeout } from '~/shared';

export const useBlockPerson = () => {
	const { translate } = useLocale();
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [getCredentials] = useListApi(mappers.credential);
	const [addToBlockList] = usePutApi(mappers.credentialState);
	const [updateCredential] = usePutApi(mappers.credential);
	const blockListCacheKey = getCacheKey(method.list, mappers.credential, {
		params: {
			'page-size': systemLimits.BlockList,
			page: 1,
			'detail-level': 'FULL',
			'credential-states': blockListState.BLOCKED,
			credential_states: blockListState.BLOCKED,
			'in-offline-blocklist': true,
		},
	});

	const onlineblockListCacheKey = getCacheKey(method.list, mappers.credential, {
		params: {
			'page-size': systemLimits.BlockList,
			page: 1,
			'detail-level': 'FULL',
			'credential-states': blockListState.BLOCKED,
			credential_states: blockListState.BLOCKED,
		},
	});

	const updateOnlineIdentifier = (credential, isBlocked) => {
		const onlineIdentifier = credential.credentialIdentifiers.find(
			(indent) => !isOfflineCredential(indent.identifierFormat)
		);
		if (onlineIdentifier) {
			onlineIdentifier.exemptedFromAuthentication = isBlocked;
			credential.credentialIdentifiers.map((identifier) => {
				if (!isOfflineCredential(identifier.identifierFormat)) {
					return onlineIdentifier;
				}
				return identifier;
			});
		}

		return credential;
	};

	const blockCredential = async (credential) => {
		setCurrentStep(translate.byKey('blocking_credential_v2'));
		const { credentialHolderId } = credential.credentialHolder;
		const currentDate = moment().startOf('day').toISOString();
		const reason = {
			reason: blockListState.BLOCKED,
			description: translate.byKey('blocked_credential_number_v2'),
			credentialId: credential.credentialId,
		};

		credential = updateOnlineIdentifier(credential, true);

		const credentialCount = await getCredentials({
			params: {
				'page-size': 1,
				page: 1,
				'credential-states': blockListState.BLOCKED,
				credential_states: blockListState.BLOCKED,
				'in-offline-blocklist': true,
			},
		});

		const includeInOfflineBlocklist = credentialCount.totalRecords < systemLimits.BlockList;

		const pendingRequests = [];
		pendingRequests.push(
			addToBlockList(
				{
					...reason,
					includeInOfflineBlocklist,
				},
				{ removeExistingKey: blockListCacheKey }
			)
		);
		pendingRequests.push(
			waitTimeout(() => {
				return updateCredential(
					{
						...credential,
						credentialHolderId,
						description: currentDate,
					},
					{ removeExistingKey: onlineblockListCacheKey }
				);
			}, 1000)
		);
		await Promise.all(pendingRequests);
	};

	const unblockCredential = async (credentialsDetails) => {
		setCurrentStep(translate.byKey('unblocking_credential_v2'));
		const { credentialHolderId } = credentialsDetails.credentialHolder;
		const reason = {
			reason: blockListState.ENABLED,
			description: credentialsDetails.unblockReason || translate.byKey('unblocking_credential_v2'),
			credentialId: credentialsDetails.credentialId,
		};

		credentialsDetails = updateOnlineIdentifier(credentialsDetails, false);

		const pendingRequests = [];
		pendingRequests.push(addToBlockList({ ...reason }, { removeExistingKey: blockListCacheKey }));
		pendingRequests.push(
			waitTimeout(() => {
				return updateCredential(
					{
						...credentialsDetails,
						credentialHolderId,
						description: '',
					},
					{ removeExistingKey: onlineblockListCacheKey }
				);
			}, 1000)
		);
		await Promise.all(pendingRequests);
	};

	const blockPerson = async (credential, isUpdate) => {
		setIsLoading(true);
		if (!isUpdate) {
			await blockCredential(credential);
		} else {
			await unblockCredential(credential);
		}
		setIsLoading(false);
		setCurrentStep('');
	};

	return [blockPerson, isLoading, currentStep];
};
