import React, { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { Item, Spin } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { DoorGroupsAssetTreeSelector } from '~/components/features/door-groups/DoorGroupsAssetTreeSelector/DoorGroupsAssetTreeSelector';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { AssetTypes } from '~/constants/AssetTypes';
import { css } from '@emotion/core';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const DOOR_GROUP_FORM_NAME = 'door-group-form';

export const DoorGroupForm = ({ defaultValues, onFormSubmit, onFormChange, message, isProcessingDoors }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();

	const {
		data,
		isLoading: isLoadingAssets,
		isValidating: isValidatingAssets,
	} = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			pageSize: 200,
			assetType: AssetTypes.BASIC_ASSET_GROUP,
		})
	);
	const [form] = Form.useForm();
	const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false);

	const isLoading = isLoadingAssets || isValidatingAssets || isProcessingDoors;

	const isDisabled = !hasActionPermissions(user, userConstants.screens.DOOR_GROUPS, userConstants.actions.WRITE);

	const validateDoors = (errors) => {
		form.setFields([
			{
				name: 'doors',
				errors,
			},
		]);
	};

	return isLoading ? (
		<div
			style={{
				height: '80px',
			}}
		>
			<Spin text={message} active={true} />
		</div>
	) : (
		<Form
			name={DOOR_GROUP_FORM_NAME}
			form={form}
			layout="vertical"
			size="medium"
			initialValues={defaultValues}
			scrollToFirstError
			onFinish={onFormSubmit}
			onValuesChange={(_, fieldValues) => onFormChange(fieldValues, form)}
		>
			<Row gutter={[24, 24]} css={rowStyles}>
				<Col sm={24}>
					<Item
						name="name"
						label={translate.byKey('door_group_name')}
						rules={[
							{
								required: true,
								whitespace: true,
								message: translate.byKey('name_is_required'),
							},
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('name'),
									maxLength: 50,
								}),
							},
							{
								validator(_, value) {
									const newDoorGroupName = value?.toLowerCase().trim();
									const doorGroupWithSameNameExists = data?.assets?.find(
										(asset) => asset.name.trim().toLowerCase() === newDoorGroupName
									);

									if (doorGroupWithSameNameExists) {
										const isEditingOwnName = defaultValues?.assetId === doorGroupWithSameNameExists?.assetId;

										return isEditingOwnName
											? Promise.resolve()
											: Promise.reject(translate.byKey('duplicated_door_group_message'));
									}

									return Promise.resolve();
								},
							},
						]}
					>
						<Input
							name="name"
							disabled={isDisabled || isLoading}
							autoFocus
							autoComplete="off"
							maxLength={50}
							showCount
						/>
					</Item>
				</Col>
			</Row>
			<Row gutter={[24, 24]} css={rowStyles}>
				<Col sm={24}>
					<Item
						name="description"
						label={translate.byKey('door_group_description')}
						rules={[
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('description'),
									maxLength: 50,
								}),
							},
						]}
					>
						<Input name="description" disabled={isDisabled || isLoading} autoComplete="off" maxLength={50} showCount />
					</Item>
				</Col>
			</Row>
			<Row gutter={[24, 24]} css={rowStyles}>
				<Col sm={24}>
					<Item name="doors" label={translate.byKey('doors')}>
						<DoorGroupsAssetTreeSelector
							doorGroupAssetId={defaultValues?.assetId}
							doors={defaultValues?.doors}
							validateDoors={validateDoors}
							setIsWarningMessageVisible={setIsWarningMessageVisible}
							disabled={isDisabled || isLoading}
						/>
					</Item>
				</Col>
			</Row>
			{defaultValues?.assetId && isWarningMessageVisible && (
				<Row gutter={[24, 24]} css={rowStyles}>
					<Col sm={24}>
						<Col
							style={{
								display: 'flex',
								backgroundColor: '#fffbe7',
								borderRadius: '5px',
								width: '100%',
								padding: '10px',
							}}
							md={24}
							lg={24}
						>
							<WarningOutlined style={{ fontSize: 22, color: '#faad14', display: 'inline-table' }} />
							<div style={{ marginLeft: 8, color: '#262623' }}>
								{translate.byKey('edit_door_groups_doors_warning_message')}
							</div>
						</Col>
					</Col>
				</Row>
			)}
		</Form>
	);
};

const rowStyles = css({
	marginBottom: '24px',
});
