import React from 'react';

export const viewBox = '0 0 512 512';

const path = (
	<g fill="currentColor">
		<path d="M392.567 0H119.435c-30.42 0-55.168 24.747-55.168 55.166v401.667c0 30.419 24.749 55.167 55.168 55.167h273.133c30.418 0 55.166-24.748 55.166-55.167V55.166C447.733 24.747 422.986 0 392.567 0zm0 482H119.435c-13.877 0-25.168-11.29-25.168-25.167V55.166c0-13.877 11.29-25.166 25.168-25.166h273.133c13.876 0 25.166 11.289 25.166 25.166V192.8h-65.332c-8.284 0-15 6.716-15 15v144.6c0 8.284 6.716 15 15 15h65.332v89.434C417.733 470.71 406.444 482 392.567 482zm25.166-144.601h-50.332V222.8h50.332z" />
		<path d="M256 192.8c-3.95 0-7.81 1.6-10.61 4.39-2.79 2.79-4.39 6.66-4.39 10.61s1.6 7.81 4.39 10.61c2.8 2.79 6.66 4.39 10.61 4.39s7.82-1.6 10.61-4.39c2.79-2.801 4.39-6.66 4.39-10.61s-1.6-7.82-4.39-10.61-6.66-4.39-10.61-4.39zM320.267 64.267H191.733c-8.284 0-15 6.716-15 15v64.267c0 8.284 6.716 15 15 15h128.533c8.284 0 15-6.716 15-15V79.267c.001-8.285-6.715-15-14.999-15zm-15 64.266h-98.533V94.267h98.533z" />
	</g>
);

const doors = {
	path,
	viewBox,
};

export default doors;
