import { translator as translate } from '~/screens/_shared/AppLocale';

export const formHelp = () => `
<p><strong> ${translate.byKey('marked_required_fields')} </strong></p>
<p style="padding-left: 30px;">${translate.byKey('first_name_last_Name')} </p>
<p style="padding-left: 30px;">${translate.byKey('card_holder_name')} </p>
<p style="padding-left: 30px;">${translate.byKey('email_address')} </p>
<p style="padding-left: 30px;">${translate.byKey('card_holder_email_address')} </p>
<p style="padding-left: 30px;">${translate.byKey('phone_number')} </p>
<p style="padding-left: 30px;">${translate.byKey('card_holder_phone_number')} </p>
<p style="padding-left: 30px;">${translate.byKey('pin_code')} </p>
<p style="padding-left: 30px;">${translate.byKey('four_digit_personnel_identification_number_with_card_pin')} </p>


<p><strong>${translate.byKey('access_group')}</strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'drop_down_and_select_the_access_group_the_person_should_have_this_determines_when_and_where_the_person_will_be_allowed_access'
)} </p>
<p><strong>${translate.byKey('valid_from_and_valid_to')} <br /></strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'click_on_each_date_time_field_to_set_a_valid_date_time_range_for_the_holiday_period_valid_from_must_be_before_valid_to'
)} </p>
<p><strong>${translate.byKey('card_number')}</strong></p>
<p style="padding-left: 30px;">${translate.byKey('type_credential_number_or_present_card_to_desktop_reader')} </p>
<p><strong>${translate.byKey('cancel')} </strong></p>
<p style="padding-left: 30px;">${translate.byKey('the_close_button_closes_the_dialog_window')} </p>
<p><strong> ${translate.byKey('submit')}</strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'submit_buttons_closes_the_window_saving_any_changes_made_to_property_fields'
)} </p>
`;
