import { InfoOutlined, SlidersOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Input, Item, Modal, Spin, Text } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import Button from '~/components/forms/Button';
import Tabs from 'antd/lib/tabs';
import { usePostApi, useListApi } from '~/screens/_shared/useApi';
import { method } from '~/screens/_shared/useApi/apiConstants';
import mappers from '~/screens/_shared/mappers';
import useSaveDoor, { formatTimeSlotData } from '~/screens/Doors/hooks/useSaveDoor';
import { doorTypeCreateTitles, doorTypeNumberOfReaders, isOffline, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import { useTheme } from 'emotion-theming';
import { mapSchedulesAndCalendars, normalizeTimeSlots } from '~/screens/Doors/hooks/helpers';
import useGetDoor from '~/screens/Doors/hooks/useGetDoor';
import moment from 'moment';
import {
	getAvailableReaders,
	getReaderFormDefaults,
	BasicInfoInputs,
	formatSelectedReaders,
} from '~/screens/Doors/shared';
import Checkbox from 'antd/lib/checkbox';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import TimesSelector from './TimesSelector';
import { ReaderInputs } from '~/screens/Doors/ReaderInputs';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import { actions, screens, getHighestPrivilegedRole, userRoles } from '~/screens/_shared/userRoleConstants';
import { AperioConfigurationInputs, PulseConfigurationInputs } from '~/screens/Doors/ConfigurationInputs';
import { useUpdateDoorSchedule } from './hooks/useUpdateDoorSchedule';
import { scheduleStates } from './hooks';
import { securityLevels } from './entityConstants';
import { showSuccessToast, showErrorToast } from '../_shared/toast';
import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency/SiteControllersEmergencyRecordsProvider';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export function getAllReaders(controllers, readerType, allReaders, currentDoor, doorType) {
	controllers = controllers || [];
	return controllers.map((controller) => ({
		controller,
		readers: getAvailableReaders(controller, allReaders, currentDoor, readerType, doorType),
	}));
}

const OfflineDoorForm = ({
	visible,
	onUpdated,
	selected,
	onClose,
	doors,
	doorType,
	defaultSelectedTab,
	hasBadReaders,
	controllers,
	updateDoorsCollection,
}) => {
	const {
		data: { system },
	} = useCurrentSystemSite();
	const { data: userAuth } = useUserAuthData();
	const [form] = Form.useForm();
	const { translate } = useLocale();
	const { TabPane } = Tabs;
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [currentTab, setCurrentTab] = useState('basic_info_tab');
	const [timeSlotData, setTimeSlotData] = useState({ timeSlots: [] });
	const [initialSlotData, setInitialTimeSlotData] = useState([]);
	const [allReaders, setAllReaders] = useState([]);
	const [currentDoor, setCurrentDoor] = useState({});
	const [steps, setSteps] = useState(getInitialSteps());
	const [isCopy, setIsCopy] = useState(false);
	const [doorNameCount, setDoorNameCount] = useState(0);
	const [zones, setZones] = useState([]);
	const [optionsSelected, setOptionsSelected] = useState([]);
	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);
	const [getPortalData, isLoadingData, currentLoadingStep] = useGetDoor();
	const [createZone] = usePostApi(mappers.asset);
	const [getAssets] = useListApi(mappers.asset);
	const theme = useTheme();
	const selectedControllers = controllers || {};
	const { updateDoorSchedule, isUpdating: isDoorScheduleUpdating } = useUpdateDoorSchedule();
	const [lastGeneratedDoorName, setLastGeneratedDoorName] = useState(null);
	const { isInEmergencyMode } = useSiteControllersEmergencyRecords();

	const doorCollectionUpdateDelay = 3500;

	function closeForm() {
		if (isLoading) return;

		if (isCopy) {
			setDoorNameCount(0);
			setIsCopy(false);
			setLastGeneratedDoorName(null);
			onUpdated.call(null, ...arguments);
		}

		resetFields();
		onClose();
	}

	function resetFields() {
		setOptionsSelected([]);
		setIsUpdate(false);
		setCurrentTab('basic_info_tab');
		setAllReaders([]);
		setTimeSlotData({ timeSlots: [] });
		setInitialTimeSlotData([]);
		setSteps(getInitialSteps());
		form.resetFields();
	}

	const onUpdatedNew = function () {
		if (!isCopy) {
			resetFields();
			onUpdated.call(null, ...arguments);
		} else {
			setCurrentTab('basic_info_tab');
			form.setFieldsValue({
				name: generateNewDoorName(form.getFieldValue('name')),
			});
			form.resetFields(['readers[0].fixedAddress', 'readers[1].fixedAddress']);
			loadReaders();
		}
	};

	const generateNewDoorName = (oldDoorName) => {
		const copyTranslation = translate.byKey('copy');
		let newDoorName = '';

		if (!lastGeneratedDoorName) {
			newDoorName = `${oldDoorName} ${copyTranslation}`;
			setLastGeneratedDoorName(newDoorName);
			return newDoorName;
		}

		if (lastGeneratedDoorName === oldDoorName) {
			const regex = new RegExp(`(Copy|Copy \\(${doorNameCount}\\))$`);

			newDoorName = oldDoorName.replace(regex, `${copyTranslation} (${doorNameCount + 1})`);
			setLastGeneratedDoorName(newDoorName);
			setDoorNameCount((count) => count + 1);
			return newDoorName;
		}

		newDoorName = `${oldDoorName} ${copyTranslation}`;
		setDoorNameCount(0);
		setLastGeneratedDoorName(newDoorName);
		return newDoorName;
	};

	const [onFinish, isSaving, currentStep] = useSaveDoor(
		doorType,
		currentDoor,
		timeSlotData,
		onUpdatedNew,
		setCurrentDoor
	);

	const onUpdateDoorClick = async () => {
		const { name: userRole } = getHighestPrivilegedRole(system);

		if (userRole === userRoles.RL_OCA_SYSOPERATOR) {
			const formData = form.getFieldsValue();
			const calendar = JSON.parse(formData.calendar);
			const schedule = JSON.parse(formData.schedule);

			const { timeSlots } = formatTimeSlotData(timeSlotData);
			const { dayTypes, standardWeek, timeIntervals } = normalizeTimeSlots(timeSlots);

			const data = {
				calendar: {
					calendarId: calendar.calendarId,
					version: calendar.version,
					name: calendar.name,
					description: calendar.description,
					dayTypes,
					standardWeek,
				},
				schedule: {
					calendarId: calendar.calendarId,
					scheduleId: schedule.scheduleId,
					version: schedule.version,
					name: schedule.name,
					description: schedule.description,
					scheduleStates,
					defaultState: securityLevels.CARD,
					timeIntervals,
				},
			};

			await updateDoorSchedule(
				data,
				() => showSuccessToast(translate.byKeyFormatted('updated_successfully_formatted', [formData.name])),
				() => showErrorToast(translate.byKey('failed_to_update_door_error'))
			);
			onUpdatedNew(true);
		} else {
			form.submit();
		}
	};

	const style = {
		marginTop: isUpdate ? '-40px' : '0',
		paddingLeft: '10px',
		paddingRight: '10px',
	};

	function getInitialSteps() {
		return [
			{
				key: 'basic_info_tab',
				title: translate.byKey('basic_info'),
				status: null,
				description: null,
				displayStatus: true,
				icon: <InfoOutlined />,
			},
			{
				key: 'schedule_tab',
				title: translate.byKey('schedule'),
				displayStatus: !isOffline(doorType),
				icon: <SlidersOutlined />,
			},
		].filter((step) => step.displayStatus);
	}

	const keys = {
		'readers[0].': 'entry_reader_tab',
		'readers[1].': 'exit_reader_tab',
	};
	const titles = ['entry_reader_tab_title', 'exit_reader_tab_title'];

	function getReaderSteps(readers) {
		if (readers[0]?.peripheralDeviceId && readers[0].fieldPrefix.includes('readers[1].')) {
			return readers
				.slice(0, 2)
				.map((reader) => {
					const title = doorType === 'hid_online' ? titles[reader.isEntry ? 0 : 1] : 'configuration_menu_title';
					return {
						key: keys[reader.fieldPrefix] || 'configuration_tab',
						fieldPrefix: reader.fieldPrefix,
						title: translate.byKey(title),
						displayStatus: isOnline(doorType) || readers.length === 1,
						icon: <SlidersOutlined />,
					};
				})
				.filter((step) => step.displayStatus)
				.reverse();
		}

		return readers
			.slice(0, 2)
			.map((reader, index) => {
				const title = doorType === 'hid_online' ? titles[index] : 'configuration_menu_title';
				return {
					key: keys[reader.fieldPrefix] || 'configuration_tab',
					fieldPrefix: reader.fieldPrefix,
					title: translate.byKey(title),
					displayStatus: isOnline(doorType) || readers.length === 1,
					icon: <SlidersOutlined />,
				};
			})
			.filter((step) => step.displayStatus);
	}

	function onFinishFailed({ errorFields }) {
		for (const field of errorFields) {
			const [name] = field.name;
			if (name === 'name') {
				const basicInfo = steps.find((step) => step.key === 'basic_info_tab');

				if (basicInfo) {
					basicInfo.status = 'error';
					basicInfo.description = field.errors[0];
					setSteps(steps.concat([]));
				}
			}
		}
	}

	function onFieldChanged(changedValues) {
		if ('name' in changedValues) {
			let errors = changedValues.name.length >= 3 ? [] : form.getFieldError('name');
			const basicInfo = steps.find((step) => step.key === 'basic_info_tab');

			if (basicInfo && basicInfo.status) {
				basicInfo.status = errors.length === 0 ? null : 'error';
				basicInfo.description = errors.length === 0 ? null : errors[0];
				setSteps(steps.concat([]));
			}
		}
	}

	const [loadingAssets, setLoadingAssets] = useState(false);

	async function getAllZones() {
		setLoadingAssets(true);
		let allZones = await getAssets({ params: { type: 'ACCESS_AREA_GROUP' } }, { expiry: moment().add(10, 'minutes') });

		if (!allZones.assets || !allZones.assets.find((zone) => zone.name === 'Zone 1')) {
			const newZone = {
				name: 'Zone 1',
				description: 'default zone',
				type: 'ACCESS_AREA_GROUP',
				domainKey: { domainId: system.systemId },
			};
			const cacheKey = getCacheKey(method.list, mappers.asset, { params: { type: 'ACCESS_AREA_GROUP' } });
			await createZone(newZone, { removeExistingKey: cacheKey });
			allZones = await getAssets({ params: { type: 'ACCESS_AREA_GROUP' } }, { expiry: moment().add(10, 'minutes') });
		}

		const zones = allZones.assets.filter((zone) => zone.name === 'Zone 1').slice(0, 1);

		allZones.assets = allZones.assets.filter((zone) => zone.name !== 'Zone 1').concat(zones);

		setLoadingAssets(false);
		return allZones.assets || [];
	}

	useEffect(() => {
		if (visible) {
			setIsLoading(true);
			resetFields();
			getAllZones().then((zones) => setZones(zones));

			if (selected) {
				const isUpdateDoor = selected.portalId !== undefined;
				setIsUpdate(isUpdateDoor);
				loadReaders(isUpdateDoor);
			} else {
				setIsUpdate(false);
				loadReaders(false);
			}
		}
	}, [visible, selected]);

	function loadReaders(isUpdateDoor) {
		const result =
			isUpdateDoor || isUpdate
				? getPortalData(selected.portalId, selected)
				: Promise.resolve({ portal: selected || {} });

		result
			.then(async (result) => {
				const { portal, authenticationProfile, calendar, schedule } = result;

				const formData = Object.assign(
					{
						schedule: JSON.stringify(schedule),
						calendar: JSON.stringify(calendar),
						authenticationProfile: JSON.stringify(authenticationProfile),
					},
					selected
				);

				if (
					(selected && selected?.configuration?.additionalSettings?.zoneId) ||
					selected?.configuration?.additionalSettings?.zoneAssetId
				) {
					formData.zoneId =
						selected?.configuration?.additionalSettings?.zoneId ||
						selected?.configuration?.additionalSettings?.zoneAssetId;
					formData.zoneName =
						selected?.configuration?.additionalSettings?.zoneName ||
						selected?.configuration?.additionalSettings?.zoneAssetName;
				}

				let allReaders = await getAssignedReaders(
					{
						params: {
							assignedToPortal: 'true',
							'detail-level': 'FULL',
						},
					},
					{ expiry: moment().add(5, 'minutes'), removeExistingKey: true }
				);

				setAllReaders(allReaders);
				setIsLoading(false);
				let selectedReaders = formatSelectedReaders(allReaders, portal);

				selectedReaders = selectedReaders.slice(0, doorTypeNumberOfReaders[doorType] || 2);

				formData.originalReaders = JSON.stringify(selectedReaders);

				if (selectedReaders.length < doorTypeNumberOfReaders[doorType]) {
					const numberOfReaders = doorTypeNumberOfReaders[doorType] || 1;
					const hasEntry = selectedReaders.some((reader) => reader.isEntry);
					for (let i = selectedReaders.length; i < numberOfReaders; ++i) {
						selectedReaders.push(
							getReaderFormDefaults(hasEntry ? 'EXIT' : 'ENTRY', selectedControllers, null, doorType)
						);
					}
				}

				if (
					selectedReaders.length > 1 &&
					(selectedReaders.every((reader) => reader.isEntry === true) ||
						selectedReaders.every((reader) => reader.isEntry === false))
				) {
					selectedReaders[0].isEntry = true;
					selectedReaders[1].isEntry = false;
				}

				selectedReaders = selectedReaders.map((reader) => ({
					...reader,
					fieldPrefix: reader.isEntry ? 'readers[0].' : 'readers[1].',
				}));

				const newSteps = getInitialSteps().concat(getReaderSteps(selectedReaders));
				setSteps(newSteps);
				defaultSelectedTab = selected ? defaultSelectedTab || selected.defaultSelectedTab : defaultSelectedTab;
				defaultSelectedTab = defaultSelectedTab || 'basic_info_tab';

				if (newSteps.some((step) => step.key === defaultSelectedTab)) {
					setCurrentTab(defaultSelectedTab);
				}

				const formReaders = selectedReaders.reduce((formReaders, reader) => {
					const fieldPrefix = reader.fieldPrefix;
					delete reader.fieldPrefix;
					for (const [prop, value] of Object.entries(reader)) {
						formReaders[`${fieldPrefix}${prop}`] = value;
					}

					return formReaders;
				}, {});
				Object.assign(formData, formReaders);

				if (selected?.configuration?.additionalSettings?.additionalParents) {
					formData.doorGroups = JSON.parse(selected.configuration?.additionalSettings?.additionalParents).map(
						(doorGroup) => doorGroup.key.assetId
					);
				}

				formData.doorAssetId = selected?.configuration?.additionalSettings?.doorAssetId;

				form.setFieldsValue(formData);

				if (!isCopy) {
					form.setFieldsValue({
						openTime: formData.configuration
							? moment.duration(formData.configuration.openTime).asSeconds().toString()
							: '',
					});
				}

				setCurrentDoor(portal);

				if (!isCopy) {
					setInitialTimeSlotData(mapSchedulesAndCalendars(calendar, schedule));
				}

				setIsLoading(false);
			})
			.catch((ex) => {
				console.error(ex);
				setIsLoading(false);
			});
	}

	function tabIndex(tab) {
		return steps.findIndex((item) => item.key === tab);
	}

	function isLastStep(tab) {
		return tabIndex(tab) === steps.length - 1;
	}

	function isFirstStep(tab) {
		return tabIndex(tab) === 0;
	}

	function nextStep() {
		form
			.validateFields()
			.then(() => {
				const currentIndex = tabIndex(currentTab);
				if (currentIndex + 1 < steps.length) {
					setCurrentTab(steps[currentIndex + 1].key);
				}
			})
			.catch((errorFields) => {
				onFinishFailed(errorFields);
			});
	}

	function previousStep() {
		const currentIndex = tabIndex(currentTab);

		if (currentIndex - 1 >= 0) {
			setCurrentTab(steps[currentIndex - 1].key);
		}
	}

	const onTabClick = (selectedTabKey, _) => {
		setCurrentTab(selectedTabKey);
	};

	const onStepperChange = (tabIndex) => {
		form
			.validateFields()
			.then(() => {
				setCurrentTab(steps[tabIndex].key);
			})
			.catch((errorFields) => {
				onFinishFailed(errorFields);
			});
	};

	function renderTabBar(props, DefaultTabBar) {
		return isUpdate ? <DefaultTabBar {...props} /> : <span />;
	}

	function onDoorDelete() {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('are_you_sure_you_want_to_delete_this_door'),
			okText: translate.byKey('delete'),
			okButtonProps: { style: { backgroundColor: theme.colors.brand[500], borderColor: theme.colors.brand[500] } },
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				form.setFieldsValue({
					shouldDelete: true,
				});
				const formData = form.getFieldsValue();
				handleDeleteDoors(formData);
			},
		});
	}

	const handleDeleteDoors = (formData) => {
		onFinish(formData);

		if (!isCopy) {
			setDoorNameCount(0);
		}

		if (isCopy && updateDoorsCollection) {
			const handle = setTimeout(() => {
				clearTimeout(handle);
				updateDoorsCollection();
			}, doorCollectionUpdateDelay);
		}
	};

	function getDoorName() {
		let doorTitleKey = 'add_door';
		if (doorType in doorTypeCreateTitles) {
			doorTitleKey = doorTypeCreateTitles[doorType];
		}

		return doorTitleKey;
	}

	function onCopyDoorCheckChanged(e) {
		setIsCopy(e.target.checked);
	}

	const hasDoorCreatePermissions = hasActionPermissions(userAuth, screens.DOORS, actions.CREATE);

	const hasDoorEditPermissions =
		hasActionPermissions(userAuth, screens.DOORS, actions.CREATE) ||
		hasActionPermissions(userAuth, screens.DOOR_MODES, actions.WRITE);

	const hasDoorDeletePermissions = hasActionPermissions(userAuth, screens.DOORS, actions.DELETE);

	const footer = [
		<Row key="offline-door-form-footer">
			<Col span={10} style={{ textAlign: 'left' }}>
				{getLicensedCount(system, doorType)}
			</Col>
			<Col span={14}>
				<div key="door-form-footer" className="steps-action">
					{!isUpdate && (
						<Checkbox
							checked={isCopy}
							onChange={onCopyDoorCheckChanged}
							disabled={isSaving || isLoading || isLoadingData}
						>
							{translate.byKey('copy_door_v2')}
						</Checkbox>
					)}

					{isUpdate && hasDoorDeletePermissions && !isInEmergencyMode && (
						<Button
							className="button-spacing"
							key="delete"
							type="primary"
							danger
							loading={isSaving}
							disabled={isLoading || isLoadingData}
							onClick={onDoorDelete}
						>
							{translate.byKey('delete')}
						</Button>
					)}

					{!isFirstStep(currentTab) && !isUpdate && (
						<Button
							style={{ margin: '0 8px' }}
							disabled={isSaving || isLoading || isLoadingData}
							onClick={() => previousStep()}
						>
							{translate.byKey('previous')}
						</Button>
					)}

					{!isLastStep(currentTab) && !isUpdate && (
						<Button type="primary" disabled={isSaving || isLoading || isLoadingData} onClick={() => nextStep()}>
							{translate.byKey('next')}
						</Button>
					)}

					{doorType && isUpdate && (
						<>
							<Button onClick={closeForm} disabled={isSaving || isLoading || isLoadingData}>
								{translate.byKey('cancel')}
							</Button>
						</>
					)}

					{doorType && isUpdate && hasDoorEditPermissions && !isInEmergencyMode && (
						<>
							<Button
								type="primary"
								disabled={isLoading || isLoadingData || isDoorScheduleUpdating}
								loading={isSaving}
								onClick={onUpdateDoorClick}
							>
								{translate.byKey('update')}
							</Button>
						</>
					)}

					{isLastStep(currentTab) && !isUpdate && hasDoorCreatePermissions && (
						<Button type="primary" disabled={isLoading || isLoadingData} loading={isSaving} onClick={form.submit}>
							{translate.byKey('submit')}
						</Button>
					)}
				</div>
			</Col>
		</Row>,
	];

	const readerTypeDefaults = {
		entry_reader_tab: 'ENTRY',
		exit_reader_tab: 'EXIT',
	};

	const editFieldsDisabled = () => {
		return !hasActionPermissions(userAuth, screens.DOORS, actions.WRITE);
	};

	const readerForms = {
		aperio_offline: (step) => (
			<AperioConfigurationInputs
				fieldPrefix={step.fieldPrefix}
				form={form}
				currentTab={currentTab}
				disabled={editFieldsDisabled()}
			/>
		),
		pulse_offline: (step) => (
			<PulseConfigurationInputs
				currentDoor={currentDoor}
				fieldPrefix={step.fieldPrefix}
				disabled={editFieldsDisabled()}
			/>
		),
		hid_online: (step) => (
			<ReaderInputs
				optionsSelected={optionsSelected}
				setOptionsSelected={setOptionsSelected}
				readers={getAllReaders(
					selectedControllers,
					readerTypeDefaults[step.key] || 'ENTRY',
					allReaders,
					currentDoor,
					doorType
				)}
				form={form}
				fieldPrefix={step.fieldPrefix}
				doorType={doorType}
				disabled={editFieldsDisabled()}
			/>
		),
		aperio_online: (step) => (
			<ReaderInputs
				optionsSelected={optionsSelected}
				setOptionsSelected={setOptionsSelected}
				readers={getAllReaders(
					selectedControllers,
					readerTypeDefaults[step.key] || 'ENTRY',
					allReaders,
					currentDoor,
					doorType
				)}
				form={form}
				fieldPrefix={step.fieldPrefix}
				doorType={doorType}
				disabled={editFieldsDisabled()}
			/>
		),
	};

	const onDoorDetailsFormSubmit = (values) => {
		onFinish(values);

		if (!isCopy) {
			setDoorNameCount(0);
		}

		if (isCopy && updateDoorsCollection) {
			const handle = setTimeout(() => {
				clearTimeout(handle);
				updateDoorsCollection();
			}, doorCollectionUpdateDelay);
		}
	};

	return (
		<Modal
			width={800}
			showHelpButton={true}
			open={visible}
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			title={isUpdate ? selected && selected.name : translate.byKey(getDoorName())}
			onClose={closeForm}
			onCancel={closeForm}
			footer={footer}
		>
			<Spin
				active={isSaving || isLoading || isLoadingData || isDoorScheduleUpdating}
				text={currentStep || currentLoadingStep}
			>
				<Steps
					current={tabIndex(currentTab)}
					onChange={onStepperChange}
					hidden={isUpdate}
					items={steps.map(({ status, description, title, key }) => ({
						status,
						description,
						title,
						key: `step_${key}`,
					}))}
				/>

				<div style={{ marginTop: '20px' }} className="steps-content">
					<Form
						layout="vertical"
						size="medium"
						name="v2-door-form"
						form={form}
						preserve="false"
						onFinish={onDoorDetailsFormSubmit}
						onFinishFailed={onFinishFailed}
						onValuesChange={onFieldChanged}
						scrollToFirstError
						aria-readonly={true}
					>
						<Tabs
							size="large"
							style={style}
							renderTabBar={renderTabBar}
							defaultActiveKey="basic_info_tab"
							activeKey={`${currentTab}`}
							onTabClick={onTabClick}
						>
							{steps.reduce((tabs, step) => {
								if (step.key === 'basic_info_tab') {
									tabs.push(
										<TabPane
											tab={
												step.status === 'error' ? translate.byKey('basic_info') + ' *' : translate.byKey('basic_info')
											}
											key="basic_info_tab"
										>
											<BasicInfoInputs
												form={form}
												selected={selected}
												doors={doors}
												zones={zones}
												loadingAssets={loadingAssets}
												isOfflineDoor={isOffline(doorType)}
												disabled={editFieldsDisabled()}
											/>
										</TabPane>
									);
								}

								if (step.key === 'schedule_tab') {
									tabs.push(
										<TabPane forceRender={true} tab={translate.byKey('schedule')} key="schedule_tab">
											<Row gutter={[12, 12]}>
												<Col lg={24}>
													<Text color="gray.300" fontSize="12px" marginLeft="40px" marginBottom="20px">
														{translate.byKey('default_mode_is_card_only')}
													</Text>
												</Col>
												<Col lg={24}>
													<Item name="schedule" shouldUpdate noStyle>
														<Input type="hidden" />
													</Item>
													<Item name="calendar" shouldUpdate noStyle>
														<Input type="hidden" />
													</Item>

													<TimesSelector
														visible={visible}
														onChange={setTimeSlotData}
														initialSlotData={initialSlotData}
														isActiveTab={currentTab === 'schedule_tab'}
													/>
												</Col>
											</Row>
										</TabPane>
									);
								}

								if (step.key === 'configuration_tab') {
									tabs.push(
										<TabPane
											forceRender={isUpdate}
											tab={translate.byKey('configuration_menu_title')}
											key="configuration_tab"
										>
											{readerForms[doorType](step)}
										</TabPane>
									);
								}

								if (step.key === 'entry_reader_tab') {
									tabs.push(
										<TabPane
											forceRender={true}
											tab={hasBadReaders ? translate.byKey('entry_reader_tab_title_corrupt_data_v2') : step.title}
											key="entry_reader_tab"
											disabled={hasBadReaders}
										>
											{readerForms[doorType](step)}
										</TabPane>
									);
								}

								if (step.key === 'exit_reader_tab') {
									tabs.push(
										<TabPane
											disabled={hasBadReaders}
											forceRender={true}
											tab={hasBadReaders ? translate.byKey('exit_reader_tab_title_corrupt_data_v2') : step.title}
											key="exit_reader_tab"
										>
											{readerForms[doorType](step)}
										</TabPane>
									);
								}
								return tabs;
							}, [])}
						</Tabs>
					</Form>
				</div>
			</Spin>
		</Modal>
	);
};

export default OfflineDoorForm;
