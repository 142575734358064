import React from 'react';

export const viewBox = '0 0 480 480';

const path = (
	<g fill="currentColor">
		<path d="M240 0C107.453 0 0 107.453 0 240s107.453 240 240 240c7.23 0 14.434-.324 21.602-.969 6.664-.597 13.27-1.511 19.824-2.656l2.52-.445c121.863-22.743 206.359-134.551 194.96-257.996C467.508 94.488 363.97.039 240 0zm-19.281 463.152h-.567a221.883 221.883 0 01-18.52-2.449c-.35-.062-.702-.101-1.046-.168a223.092 223.092 0 01-17.77-3.95l-1.418-.362a223.244 223.244 0 01-16.949-5.352c-.578-.207-1.16-.39-1.738-.605-5.465-2.008-10.832-4.258-16.117-6.692-.656-.293-1.313-.574-1.969-.887-5.184-2.398-10.266-5.101-15.25-7.945-.703-.398-1.414-.797-2.117-1.191a226.827 226.827 0 01-14.403-9.176c-.71-.496-1.43-.977-2.136-1.473a224.986 224.986 0 01-13.512-10.398L96 411.449V344c.059-48.578 39.422-87.941 88-88h112c48.578.059 87.941 39.422 88 88v67.457l-1.063.887a217.439 217.439 0 01-13.777 10.601c-.625.438-1.258.856-1.879 1.285a223.69 223.69 0 01-14.625 9.336c-.625.364-1.265.707-1.886 1.067-5.06 2.879-10.204 5.597-15.45 8.047-.601.28-1.207.543-1.816.8a220.521 220.521 0 01-16.246 6.743c-.547.203-1.098.379-1.602.57-5.601 2.008-11.281 3.824-17.031 5.383l-1.379.344a225.353 225.353 0 01-17.789 3.96c-.344.063-.687.106-1.031.16a222.58 222.58 0 01-18.54 2.458h-.566c-6.398.55-12.8.847-19.28.847-6.481 0-12.935-.242-19.321-.793zM400 396.625V344c-.066-57.41-46.59-103.934-104-104H184c-57.41.066-103.934 46.59-104 104v52.617C-6.164 308.676-5.203 167.68 82.148 80.918 169.5-5.84 310.5-5.84 397.852 80.918c87.351 86.762 88.312 227.758 2.148 315.7zm0 0" />
		<path d="M240 64c-44.184 0-80 35.816-80 80s35.816 80 80 80 80-35.816 80-80c-.047-44.164-35.836-79.953-80-80zm0 144c-35.348 0-64-28.652-64-64s28.652-64 64-64 64 28.652 64 64c-.04 35.328-28.672 63.96-64 64zm0 0" />
	</g>
);

const people = {
	path,
	viewBox,
};

export default people;
