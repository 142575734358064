import capitalize from 'lodash/capitalize';

const accessPoint = {
	base: {
		name: ({ type, portalName }) => `${portalName}(${capitalize(type)})`,
		description: ({ type, portalName }) => `${portalName}(${capitalize(type)})`,
		type: true,
		fromAsset: (data, { system, site }) => {
			if (!data) {
				return null;
			}

			let fromAssetId = null;
			if (data.fromAssetId) {
				fromAssetId = data.fromAssetId;
			}

			if (data.fromAsset && data.fromAsset.assetId) {
				fromAssetId = data.fromAsset.assetId;
			}

			return {
				key: {
					systemId: system,
					siteId: site,
					assetId: fromAssetId,
				},
			};
		},
		toAsset: (data, { system, site }) => {
			if (!data) {
				return null;
			}

			let toAssetId = null;
			if (data.toAssetId) {
				toAssetId = data.toAssetId;
			}

			if (data.toAsset && data.toAsset.assetId) {
				toAssetId = data.toAsset.assetId;
			}

			return {
				key: {
					systemId: system,
					siteId: site,
					assetId: toAssetId,
				},
			};
		},
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: [
		'customer',
		':customerId:',
		'system',
		':systemId:',
		'site',
		':siteId:',
		'portal',
		':portalId:',
		'access-point',
	],
};

export default accessPoint;
