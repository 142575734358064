import { useState } from 'react';

import { usePostApi, useGetApi, usePutApi, usePatchApi, useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { formatIdentifiers, numberToFormatString } from '~/components/features/people/People.utils';
import mappers from '~/screens/_shared/mappers';
import { waitTimeout } from '~/shared';
import { showErrorToast, showWarningToast } from '~/screens/_shared/toast';
import {
	getCurrentAdministrator,
	isEndCustomerContact,
	isSystemGuard,
	isSystemInstaller,
	isSystemOperator,
	isSystemOwner,
} from '~/screens/_shared/userRoleConstants';
import moment from 'moment';
import { checkCredentialHolderLimit } from '~/screens/People/PeoplePage';
import useGetEntitlements from '~/screens/_shared/entitlement/useGetEntitlements';
import { useTreeSelectorData } from '../../site-selection/hooks/useTreeSelectorData';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const canCurrentAdminEditAdministrators = async (getAdministratorRoles) => {
	try {
		const currentAdmin = getCurrentAdministrator();

		if (currentAdmin) {
			if (Array.isArray(currentAdmin.roles) && currentAdmin.roles.some(isSystemOwner)) {
				return true;
			}

			const freshRoles = await getAdministratorRoles(
				{ administratorId: currentAdmin.administratorId },
				{
					expiry: moment().add(10, 'minutes'),
					shared: true,
				}
			);

			return freshRoles.some(isSystemOwner) || freshRoles.some(isEndCustomerContact);
		}

		return false;
	} catch {
		return false;
	}
};

export const createCredentials = async (formData, createCredential, translate) => {
	const { firstName, lastName, startDateTime, endDateTime, credentials = [] } = formData;

	const errorResponses = (
		await Promise.allSettled(
			credentials
				.filter((credential) => !!credential.hexValue)
				.map((credential) => {
					const identifiers = formatIdentifiers(credential, formData.pin);
					if (identifiers.length > 0) {
						return createCredential({
							...credential,
							firstName,
							lastName,
							startDateTime,
							endDateTime,
							identifiers,
						});
					}
					return null;
				})
		)
	)
		.filter((response) => response.status === 'rejected')
		.map((response) => response.reason);

	const conflictResponses = errorResponses.filter((response) => response.status === 409);

	if (errorResponses.length === 1) {
		if (conflictResponses.length === errorResponses.length) {
			showErrorToast(
				translate.byKey(
					'an_existing_credential_exists_somewhere_else_in_the_system_the_credential_specified_is_being_ignored'
				)
			);
		} else {
			showErrorToast(
				translate.byKey(
					'an_error_occurred_when_saving_the_credential_please_check_your_network_connection_or_if_there_is_another_credential_in_the_system_with_the_same_value'
				)
			);
		}
	} else if (conflictResponses.length > 0) {
		showErrorToast(translate.byKey('several_credentials_that_were_saved_already_exist'));
	} else if (errorResponses.length > 0) {
		showErrorToast(translate.byKey('several_credentials_could_not_be_created'));
	}
};

// see https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
const uuidv4 = () => {
	try {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	} catch {
		// on the off chance that we are running in a browser without crypto.
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
};

export const createRoleIfNeeded = async ({ selectedRole, formData, system, createOrUpdateEmeaRole }) => {
	if (
		!selectedRole &&
		(isSystemGuard({ name: formData.selectedRoleId }) || isSystemOperator({ name: formData.selectedRoleId }))
	) {
		const newId = uuidv4();
		await createOrUpdateEmeaRole({
			systemId: system.systemId,
			roleId: newId,
			type: 'CUSTOM',
			name: formData.selectedRoleId,
			__contentType: 'application/vnd.assaabloy.msfss.administration-12.0+json',
		});
		selectedRole = {
			roleId: newId,
			id: newId,
			type: 'CUSTOM',
			name: formData.selectedRoleId,
		};
		formData.selectedRoleId = newId;

		system.roles = (system.roles || []).concat([selectedRole]);
	}
	return { selectedRole, formData, system };
};

export const useCreateAdministrator = () => {
	const { translate } = useLocale();
	const { data: treeSelectorData } = useTreeSelectorData();
	let {
		data: { system, site },
		mutate: mutateSystemSite,
	} = useCurrentSystemSite();
	const [getOwners] = useListApi('/administration/system-owner');
	const [getInstallers] = useListApi('/administration/installer');
	const [getAdministratorRoles] = useListApi('/administration/administrator/:administratorId/role');
	const [createAdministrator] = usePostApi('/administration/administrator', () => {}, {
		removeDomainKey: true,
	});

	const [updateSystem] = usePatchApi(
		'/administration/business-entity/:businessEntityId/customer/:endCustomerId/system/:systemId/',
		() => {},
		{
			removeDomainKey: true,
		}
	);

	const [updateAdminRole] = usePutApi('/administration/role/:roleId/administrator/:administratorId/', () => {}, {
		removeDomainKey: true,
	});

	// enable this only when system-services connector is fully functional
	// const [addAdminAsSecondarySystemOwner] = usePutApi("/system-services/administrator/:administratorId/role/:roleId/", () => {
	// }, {
	//   removeDomainKey: true
	// });

	const [getEmeaSystemByEveryId] = useGetApi(
		'/administration/business-entity/:businessEntityId/customer/:endCustomerId/system/:systemId'
	);

	const [createOrUpdateEmeaRole] = usePutApi('/administration/system/:systemId/role/:roleId', {
		removeDomainKey: true,
	});

	return [
		async (formData) => {
			if (!system.businessEntityId) {
				return;
			}

			const canCreate = await canCurrentAdminEditAdministrators(getAdministratorRoles);

			if (!canCreate) {
				return;
			}

			//TODO: need to add templateId when becomes available
			try {
				if (system.administrators.filter((admin) => admin.username === formData.email).length === 0) {
					let adminData = null;

					let selectedRole = system.roles.find((role) => role.roleId === formData.selectedRoleId);

					const results = await createRoleIfNeeded({ selectedRole, formData, system, createOrUpdateEmeaRole });

					selectedRole = results.selectedRole;
					formData = results.formData;
					system = results.system;

					try {
						adminData = await createAdministrator({
							firstName: formData.firstName,
							lastName: formData.lastName,
							email: formData.email,
							phone: formData.phone,
							organization: system.systemName,
							sendNotificationCopy: false,
							__contentType: 'application/vnd.assaabloy.msfss.administration-12.0+json',
						});
					} catch {
						// attempt updating the system if you are adding a System Owner
						if (selectedRole && isSystemOwner(selectedRole)) {
							try {
								await updateSystem({
									__arrayBody: [
										{
											op: 'add',
											path: '/systemOwner',
											value: {
												email: formData.email,
											},
										},
									],
									businessEntityId: system.businessEntityId,
									endCustomerId: system.endCustomerId,
									systemId: system.systemId,
									__contentType: 'application/json-patch+json',
								});

								const updatedSystem = await getEmeaSystemByEveryId({
									businessEntityId: system.businessEntityId,
									endCustomerId: system.endCustomerId,
									systemId: system.systemId,
								});

								adminData = updatedSystem.systemOwner.email === formData.email ? updatedSystem.systemOwner : {};

								if (adminData) {
									adminData.administratorId = adminData.administratorId || adminData.id;
								}
							} catch {}
						}
						// attempt upddating the system if you are adding all other roles i.e. System Installer, System Operator & System Guard
						else if (selectedRole && !isSystemOwner(selectedRole)) {
							try {
								await updateSystem({
									__arrayBody: [
										{
											op: 'add',
											path: '/systemInstallers',
											value: [
												{
													email: formData.email,
												},
											],
										},
									],
									businessEntityId: system.businessEntityId,
									endCustomerId: system.endCustomerId,
									systemId: system.systemId,
									__contentType: 'application/json-patch+json',
								});

								const updatedSystem = await getEmeaSystemByEveryId({
									businessEntityId: system.businessEntityId,
									endCustomerId: system.endCustomerId,
									systemId: system.systemId,
								});

								adminData = (updatedSystem.installers || []).find((installer) => installer.email === formData.email);

								if (adminData) {
									adminData.administratorId = adminData.administratorId || adminData.id;
									system.installers = system.installers.concat([adminData]);

									// This is a workaround to remove any non-systemInstaller roles added to the systemInstaller object
									if (!isSystemInstaller(selectedRole)) {
										await updateSystem({
											__arrayBody: [
												{
													op: 'remove',
													path: '/systemInstallers',
													value: {
														id: adminData.administratorId,
													},
												},
											],
											businessEntityId: system.businessEntityId,
											endCustomerId: system.endCustomerId,
											systemId: system.systemId,
											__contentType: 'application/json-patch+json',
										});
									}
								}

								await getEmeaSystemByEveryId({
									businessEntityId: system.businessEntityId,
									endCustomerId: system.endCustomerId,
									systemId: system.systemId,
								});
							} catch {}
						} else if (selectedRole) {
							const administrators =
								treeSelectorData.flatMap((customer) =>
									customer.systemsAndSites.flatMap((system) => system.administrators)
								) || [];

							adminData = administrators.find((admin) => admin.email === formData.email);

							if (!adminData) {
								const allAdmins = (await Promise.allSettled([getOwners(), getInstallers()])).reduce(
									(admins, response) => {
										if (response.status === 'fulfilled') {
											return admins.concat(response.value || []);
										}

										return admins;
									},
									[]
								);

								adminData = allAdmins
									.filter((owner) => owner.email === formData.email)
									.map((owner) => ({ ...owner, administratorId: owner.id }))[0];
							}

							if (!adminData) {
								showWarningToast({
									message: translate.byKeyFormatted('could_not_create_user_admin_id_not_found', [formData.email]),
								});
							}
						}
					}

					adminData = adminData || {};
					const { administratorId } = adminData;
					if (administratorId && selectedRole) {
						// enable this only when system-services connector is fully functional
						// if(isSystemOwner(selectedRole)) {
						//   await addAdminAsSecondarySystemOwner({
						//     roleId: formData.selectedRoleId,
						//     administratorId,
						//     sendNotificationCopy: false,
						//     __contentType: "application/vnd.assaabloy.msfss.system-services-10.0+json"
						//   });
						// }
						// else {
						await updateAdminRole({
							roleId: formData.selectedRoleId,
							administratorId,
							sendNotificationCopy: false,
							__contentType: 'application/vnd.assaabloy.msfss.administration-12.0+json',
						});
						// }

						system.administrators = (system.administrators || []).concat([
							{
								administratorId,
								id: administratorId,
								email: formData.email,
								username: formData.email,
								firstName: formData.firstName,
								lastName: formData.lastName,
								roles: isSystemOwner(selectedRole)
									? [selectedRole, system.roles.find((role) => isSystemInstaller(role))].filter((role) => !!role)
									: [selectedRole],
							},
						]);

						if (isSystemInstaller(selectedRole)) {
							system.installers = (system.installers || []).concat([
								{
									administratorId,
									id: administratorId,
									email: formData.email,
									username: formData.email,
									firstName: formData.firstName,
									lastName: formData.lastName,
								},
							]);
						}

						mutateSystemSite({ system: { ...system }, site });
					}
				} else {
					showErrorToast(translate.byKeyFormatted('could_not_create_admin_email_in_use', [formData.email]));
				}
			} catch (error) {
				console.error(error);
				showErrorToast(translate.byKey('failed_to_assign_administrator_privileges'));
			} finally {
			}
		},
	];
};

export const useCreatePerson = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const [createUser] = usePostApi(mappers.user);
	const [createCredentialHolder] = usePostApi(mappers.credentialHolder);
	const [createCredential] = usePostApi(mappers.credential);
	const [createAdministrator] = useCreateAdministrator();
	const [updateCredentialHolder] = usePutApi(mappers.credentialHolder, () => {}, {
		requiresUnformattedRequest: true,
	});

	const [getEntitlements] = useGetEntitlements();
	const [getCredentialHolder] = useGetApi(mappers.credentialHolder, () => {}, { requiresUnformattedResponse: true });
	const { translate } = useLocale();

	const createPerson = async (formData) => {
		try {
			setIsLoading(true);
			const { firstName, lastName, phone, email = '', pin } = formData;
			setCurrentStep(translate.byKey('creating_person_details'));

			const pinCode = pin ? numberToFormatString(pin, 'hex') : '';

			const newUser = {
				firstName,
				lastName,
				phone: phone?.trim()?.length > 0 ? phone : undefined,
				email: email.length ? email : undefined,
			};

			const offlineAccessProfileIds =
				formData?.offlineAccessProfileId?.length > 1
					? [...formData.offlineAccessProfileId]
					: [formData.offlineAccessProfileId];

			const groups = [formData.onlineAccessProfileId, ...offlineAccessProfileIds]
				.flat()
				.filter((id) => !!id)
				.map((id) => ({ accessProfileId: id }));

			const userData = await createUser(newUser);
			const { userId } = userData;
			const { startDateTime, endDateTime } = formData;

			const descriptionData = { pinCode, email };

			if (JSON.stringify(descriptionData).length > 50) {
				delete descriptionData.email;
			}

			const { credentialHolderId } = await createCredentialHolder({
				firstName,
				lastName,
				startDateTime,
				endDateTime,
				groups,
				userId,
				description: JSON.stringify(descriptionData),
			});

			formData.credentials = formData.credentials || [];
			formData.credentials = formData.credentials.map((credential) =>
				Object.assign(credential, { credentialHolderId })
			);

			try {
				await createCredentials(formData, createCredential, translate);
			} catch {
				// if there is a conflict, we still need to carry on
			}

			const [, latestCredentialHolder] = await Promise.all([
				Promise.resolve(null).then(() => {
					if (formData.isAdministrator) {
						setCurrentStep(translate.byKey('creating_admin'));
						return createAdministrator(formData);
					}

					return null;
				}),
				Promise.resolve(null).then(async () => {
					await waitTimeout(() => {}, 1000);
					const latestCredentialHolder = await getCredentialHolder({ credentialHolderId });
					await updateCredentialHolder(latestCredentialHolder.attributes);
					return latestCredentialHolder;
				}),
			]);

			return Object.assign(latestCredentialHolder.attributes, latestCredentialHolder.key, { firstName, lastName });
		} catch (error) {
			if (error.status === 403) {
				const updatedSystem = await getEntitlements();
				if (checkCredentialHolderLimit(updatedSystem)) return;
			}
			throw error;
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	};

	return [createPerson, isLoading, currentStep];
};
