const ttlCache = {
	registeredKeys: { key: { ttl: 0, handle: {} } },
	registerKeyExpiry: (key, ttl) => {
		if (!this.registeredKeys[key]) {
			const handle = setTimeout(() => {
				const registeredKey = this.registeredKeys[key];
				clearTimeout(registeredKey.handle);
				removeItem(key);
			}, ttl);
			this.registeredKeys[key] = { ttl, handle };
		}
	},
};

/**
 * Adds an item to Localstorage.
 * @param {string} key: Key identifier for the item
 * @param {string} value: Value, strings or serialized JSON object
 */
const addItem = (key, value) => {
	if (key.trim() === '') {
		console.error('Localstorage key cannot be an empty string');
		return;
	}
	localStorage.setItem(key, value);
};

/**
 * Adds an item to Localstorage with an expiry time in milliseconds.
 * @param {string} key: Key identifier for the item
 * @param {string} value: Value, strings or serialized JSON object
 * @param {number} ttl: Time-To-Live value for the item in localstorage, in milliseconds
 */
const addItemWithExpiry = (key, value, ttl) => {
	addItem(key, value);
	ttlCache.registerKeyExpiry(key, ttl);
};

/**
 * Retrieves an item from Localstorage.
 * @param {string} key: Key identifier for the item
 */
const getItem = (key) => {
	return localStorage.getItem(key);
};

/**
 * Removes an item from Localstorage.
 * @param {string} key: Key identifier for the item
 */
const removeItem = (key) => {
	localStorage.removeItem(key);
};

const LocalStorageService = {
	addItem,
	addItemWithExpiry,
	getItem,
	removeItem,
};

export default LocalStorageService;
