import React, { useState } from 'react';

import { Page } from '~/components';
import { AccessSchedulesTable } from '~/components/features/access-schedules/AccessSchedulesTable/AccessSchedulesTable';
import { AccessSchedulesPageHeader } from '~/components/features/access-schedules/AccessSchedulesPageHeader/AccessSchedulesPageHeader';
import { AccessScheduleCreateModal } from '~/components/features/access-schedules/AccessScheduleCreateModal/AccessScheduleCreateModal';
import { AccessScheduleUpdateModal } from '~/components/features/access-schedules/AccessScheduleUpdateModal/AccessScheduleUpdateModal';

export const AccessSchedulesPage = () => {
	const [isCreatingAccessSchedule, setIsCreatingAccessSchedule] = useState(false);
	const [selectedAccessScheduleId, setSelectedAccessScheduleId] = useState(null);

	// TODO MM handle roles per user types

	return (
		<Page>
			{isCreatingAccessSchedule ? (
				<AccessScheduleCreateModal onClose={() => setIsCreatingAccessSchedule(false)} />
			) : null}
			{selectedAccessScheduleId ? (
				<AccessScheduleUpdateModal
					selectedAccessScheduleId={selectedAccessScheduleId}
					onClose={() => setSelectedAccessScheduleId(null)}
				/>
			) : null}
			<AccessSchedulesPageHeader onAddNewButtonClick={() => setIsCreatingAccessSchedule(true)} />
			<AccessSchedulesTable onRowClick={setSelectedAccessScheduleId} />
		</Page>
	);
};
