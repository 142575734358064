import { css } from '@emotion/core';

export const checkboxGroupStyles = css`
	margin-top: 12px;
	.ant-checkbox-wrapper {
		display: flex;
		margin-left: 0;
		margin-top: 12px;
	}
`;
