import useSWR from 'swr';
import systemConfig from '~/screens/_shared/systemConfig';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useCustomers } from './useCustomers';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { extractIdsFromHref } from '~/screens/_shared/useApi/utils/formatResponse';
import moment from 'moment';
import { useGetApi } from '~/screens/_shared/useApi';

const fallbackData = [];

export const useTreeSelectorData = () => {
	const {
		data: { administratorId, tokens },
	} = useUserAuthData();
	const { data: customers } = useCustomers();
	const [getSystemEmea] = useGetApi('/administration/system/:systemId:');
	const [getSystemOca] = useGetApi('/customer/:customerId/system/:systemId');
	const [getAdministratorEmea] = useGetApi('/administration/administrator/:administratorId');

	const buildSystemSiteData = async (customer, systems) => {
		if (!systems?.length) {
			return fallbackData;
		}

		const sortedSystems = systems.sort(createSorter('name'));

		const processSystem = async (currentSystem) => {
			try {
				const system = await getSystemEmea({ systemId: currentSystem.id, ignoreGlobalHandlers: true });
				let extraIds = {};

				const link = system.links.find((link) => link.rel === 'listSites');
				if (link?.href) {
					extraIds = extractIdsFromHref(link);
				}

				system.businessEntityId = system.businessEntityId || extraIds.businessEntityId;
				system.systemId = system.systemId || system.id;
				system.systemName = system.systemName || system.name;
				system.customerId = customer.id;
				system.entitlements = system.entitlements || [];

				system.sites = (system.sites || []).map((site) => {
					site.siteId = site.siteId || site.id;
					site.siteName = site.siteName || site.name;
					site.customerId = customer.id;
					site.systemId = system.systemId;
					site.systemName = system.name;

					return site;
				});

				if (system.roles?.length && system.administrators?.length) {
					system.roles = system.roles.map((role) => {
						role.roleId = role.roleId || role.id;

						return role;
					});

					system.administrators = system.administrators.map((admin) => {
						admin.administratorId = admin.administratorId || admin.id;
						admin.username = admin.username || admin.email;

						admin.roles = (admin.roles || []).map((role) => {
							const roleInfo = system.roles.find((other) => other.roleId === role.roleId);

							if (roleInfo) {
								role = Object.assign(role, roleInfo);
							}

							return role;
						});

						return admin;
					});
				} else {
					const ocaSystems = await getSystemOca({
						customerId: customer.id,
						systemId: currentSystem.id,
						ignoreGlobalHandlers: true,
						params: { 'detail-level': 'FULL' },
					});

					ocaSystems.administrators.forEach((admin) => {
						admin.roles.forEach((role) => {
							if (!system.roles.some((currentRole) => currentRole.id === role.id)) {
								system.roles = [...system.roles, role];
							}
						});
					});

					system.administrators = ocaSystems.administrators.map((admin) => {
						admin.administratorId = admin.administratorId || admin.id;
						admin.username = admin.username || admin.email;

						return admin;
					});

					const cache = { expiry: moment().add(30, 'seconds'), shared: true };
					const currentUserData = await getAdministratorEmea({ administratorId }, cache);

					const admin = system.administrators.find((admin) => admin.username === currentUserData.email);

					if (admin) {
						admin.firstName = currentUserData.firstName;
						admin.lastName = currentUserData.lastName;
						admin.phone = currentUserData.phone;
					}
				}

				return system;
			} catch (error) {
				console.error('Error processing system:', error);
				return {};
			}
		};

		const systemSites = await Promise.all(sortedSystems.map(processSystem));

		const enabledSystemSites = systemSites.filter((currentSystem) => currentSystem.enabled === true);

		const children = enabledSystemSites.map((currentSystem) => ({
			title: currentSystem.name,
			value: currentSystem.systemId,
			children: currentSystem.sites.sort(createSorter('name')).map((site) => ({
				title: site.name,
				value: site.siteId,
			})),
		}));

		return [children, systemSites];
	};

	const fetcher = async () => {
		const customerTreeData = await Promise.all(
			customers.map(async (customer) => {
				const customerTreeData = {
					selectable: false,
					title: customer.name,
					value: customer.name,
				};
				const response = await fetch(`/customer/${customer.id}/system`, {
					method: 'GET',
					headers: {
						'Application-ID': systemConfig.applicationId,
						'Application-Version': systemConfig.jiraVersion,
						Authorization: `Bearer ${tokens[tokens.length - 1].accessToken}`,
					},
				});

				if (!response.ok) {
					throw response.statusText;
				}

				const systemsData = await response.json();
				const systems = systemsData.systems;

				const [children, systemsAndSites] = await buildSystemSiteData(customer, systems);

				customerTreeData.children = children;
				customerTreeData.systemsAndSites = systemsAndSites;

				return customerTreeData;
			})
		);

		return customerTreeData.filter((customer) => customer.children?.length);
	};

	return useSWR(customers.length ? `/customer/systems?${administratorId}` : null, () => fetcher(tokens, customers), {
		revalidateOnFocus: false,
		revalidateIfStale: false,
		revalidateOnReconnect: false,
		refreshInterval: 1000 * 60 * 10, // 10 minutes
		fallbackData,
	});
};
