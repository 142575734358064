import english from './en.json';

function getFormatKeys(value) {
	value = value || '';

	return value
		.split('{')
		.filter((key) => key.includes('}'))
		.map((key) => `{${key.split('}')[0]}}`);
}

function format(value, formatValues) {
	formatValues = formatValues || {};

	const formatKeys = getFormatKeys(value);
	if (value) {
		if (Array.isArray(formatValues)) {
			for (let index = 0; index < formatValues.length; ++index) {
				const formatKey = formatKeys[index] || `{${index}}`;
				while (value.includes(formatKey)) {
					value = value.replace(formatKey, formatValues[index]);
				}
			}
		} else {
			for (let key of Object.keys(formatValues)) {
				const formatKey = `{${key}}`;
				while (value.includes(formatKey)) {
					value = value.replace(formatKey, formatValues[key]);
				}
			}
		}
	}

	return value;
}

export class Translator {
	languages = new Map();
	translationOverrides = new Map();
	translationImports = new Map();

	localStorage = undefined;
	channel = undefined;
	getFile = undefined;
	getDefaultLangaugeCode = undefined;

	constructor(getFile, getDefaultLanguageCode, localStorage) {
		this.getFile = getFile;
		this.getDefaultLangaugeCode = getDefaultLanguageCode;
		this.languages.set('en', english);
		this.localStorage = localStorage;

		this.initializeBroadcastChannel();
		const translationData = this.getTranslationDataFromUrl(window.location.search || '');
		this.initializeTranslationsImport(translationData);
	}

	initializeBroadcastChannel() {
		this.channel = window.BroadcastChannel
			? new window.BroadcastChannel('translations_channel')
			: {
					postMessage() {},
			  };
		this.channel.onmessage = (event) => {
			if (event?.data?.type === 'override' && event.data.languageCode && event.data.key && event.data.value) {
				this.setOverrideValue(event.data.languageCode, event.data.key, event.data.value, false);
			}

			if (event?.data?.type === 'reset' && event.data.languageCode) {
				this.resetOverrides(event.data.languageCode, false);
			}
		};
	}

	getTranslationDataFromUrl(translationOverrideUrl) {
		const translationData = translationOverrideUrl.split('&').map((keyValue) => {
			const splitValue = keyValue.split('=');

			if (splitValue[0] && splitValue[0].startsWith('?')) {
				splitValue[0] = splitValue[0].replace('?', '');
			}

			return { key: decodeURIComponent(splitValue[0] || ''), value: decodeURIComponent(splitValue[1] || '') };
		});

		return translationData;
	}

	initializeTranslationsImport(translationData) {
		const langParams = translationData.filter((param) => param.key.startsWith('translationOverride.'));

		for (const langParam of langParams) {
			const code = langParam.key.split('.').pop() || '';
			if (code && code.length >= 2) {
				try {
					const values = JSON.parse(langParam.value);
					if (values) {
						const imports = {};

						for (const [key, value] of Object.entries(values)) {
							imports[key] = {
								prefer: 'import',
								value,
							};
						}
						this.translationImports.set(code, imports);
					}
				} catch (e) {
					console.error(`[Translator.ts]: ${e}`);
				}
			}
		}
	}

	supportedLanguages() {
		return [...new Set(['en', this.getDefaultLangaugeCode()])];
	}

	async loadLanguage(languageCode = null) {
		languageCode = languageCode || this.getDefaultLangaugeCode();

		if (!this.languages.has(languageCode)) {
			const languageData = await this.getFile(languageCode);

			if (languageData) {
				this.languages.set(languageCode, languageData);
			}
		}

		try {
			const storedData = JSON.parse(this.localStorage.getItem('translationOverride.' + languageCode));
			if (storedData) {
				this.translationOverrides.set(languageCode, storedData);
			}
		} catch {
			// Ignore
		}
	}

	byKey(key, languageCode = null) {
		languageCode = languageCode || this.getDefaultLangaugeCode();
		const lang = this.languages.get(languageCode);

		const imports = this.translationImports.get(languageCode);
		const overrides = this.translationOverrides.get(languageCode);
		let result = null;

		if (overrides) {
			result = overrides[key] || null;
		}

		if (lang && !result) {
			result = lang[key] || null;
		}

		if (imports && imports[key]?.prefer === 'import') {
			result = imports[key]?.value || '';
		}

		if (!result && languageCode !== 'en') {
			return this.byKey(key, 'en');
		}

		return result || key;
	}

	byValue(value, languageCode = null) {
		value = value || '';
		const english = this.languages.get('en');
		const keys = Object.keys(english).filter((key) => english[key] === value.trim() || english[key] === value);
		if (keys.length === 0) {
			return value;
		}

		const result = keys.map((key) => [key, this.byKey(key, languageCode)]).filter(([key, value]) => key !== value)[0];

		return result ? result.pop() : value;
	}

	byKeyFormatted(key, formatValues = {}, languageCode = null) {
		return format(this.byKey(key, languageCode), formatValues);
	}

	byValueFormatted(value, formatValues = {}, languageCode = null) {
		return format(this.byValue(value, languageCode), formatValues);
	}

	async getLanguageData(languageCode) {
		await this.loadLanguage(languageCode);

		const overrides = this.translationOverrides.get(languageCode) || {};

		const imports = this.translationImports.get(languageCode) || {};

		const selectedLanguageData = this.languages.get(languageCode) || {};

		const languageData = Object.assign({}, this.languages.get('en'), selectedLanguageData);

		return Object.entries(languageData).map(([key, value]) => ({
			key,
			isTranslated: key in selectedLanguageData,
			hasOverride: key in overrides,
			hasImport: key in imports && imports[key]?.value !== overrides[key],
			originalValue: value,
			value: key in imports && !(key in overrides) ? imports[key]?.value || value || '' : overrides[key] || value || '',
			importValue: key in imports ? imports[key]?.value || '' : '',
			prefer: key in imports ? imports[key]?.prefer || 'import' : 'override',
		}));
	}

	setOverrideValue(languageCode, key, value, shouldPostMessage = true) {
		if (!this.translationOverrides.has(languageCode)) {
			this.translationOverrides.set(languageCode, {});
		}

		const languageData = this.translationOverrides.get(languageCode) || {};
		languageData[key] = value;

		if (shouldPostMessage) {
			this.localStorage.setItem('translationOverride.' + languageCode, JSON.stringify(languageData));
			this.channel.postMessage({ type: 'override', languageCode, key, value });
		}

		if (this.forceUpdate) {
			this.forceUpdate();
		}
	}

	preferOverride(languageCode, key) {
		const imports = this.translationImports.get(languageCode);

		if (imports && imports[key]) {
			imports[key].prefer = 'override';

			if (this.forceUpdate) {
				this.forceUpdate();
			}
		}
	}

	preferImport(languageCode, key) {
		const imports = this.translationImports.get(languageCode);

		if (imports && imports[key]) {
			imports[key].prefer = 'import';

			if (this.forceUpdate) {
				this.forceUpdate();
			}
		}
	}

	resetOverrides(languageCode, shouldPostMessage = true) {
		this.translationOverrides.delete(languageCode);

		if (shouldPostMessage) {
			this.localStorage.removeItem('translationOverride.' + languageCode);
			this.channel.postMessage({ type: 'reset', languageCode });
		}

		if (this.forceUpdate) {
			this.forceUpdate();
		}
	}

	getTranslationUrl(languageCode) {
		const href = window.location.href;

		const joiner = href.includes('?') ? '&' : '?';

		const key = encodeURIComponent('translationOverride.' + languageCode);

		const overrides = Object.assign({}, this.translationOverrides.get(languageCode) || {});

		const imports = this.translationImports.get(languageCode) || {};

		for (const [importKey, importValue] of Object.entries(imports)) {
			if (importValue?.prefer === 'import') {
				overrides[importKey] = importValue.value;
			}
		}

		const value = encodeURIComponent(JSON.stringify(overrides));

		return `${href.replace('/translations', '/')}${joiner}${key}=${value}`;
	}
}
