import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

// AKA Peripheral Device
export const useReaders = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/site/:siteId:/peripheral-device',
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , , pageSize, pageNumber, sortBy, sortDirection, assignedToPortal] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': parseInt(pageSize),
					page: parseInt(pageNumber),
					'sort-by': sortBy,
					'sort-order': sortDirection,
					'assigned-to-portal': assignedToPortal,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getReadersKey = ({
	customerId,
	systemId,
	siteId,
	pageSize,
	pageNumber,
	sortBy,
	sortDirection,
	assignedToPortal,
}) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${sortBy}${separator}\
${sortDirection}${separator}\
${assignedToPortal}${separator}\
readers`
		: null;
