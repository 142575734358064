import { translator as translate } from '~/screens/_shared/AppLocale';

const formHelp = () => `
<p><strong> ${translate.byKey('general')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'access_group_name_start_time_hh:mm_end_time_hh:mm_and_doors_allowed_are_all_required_fields'
)} </p>
<p><strong> ${translate.byKey('start_and_end_times')} <br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'click_on_each_time_field_to_set_a_valid_time_range_when_access_is_allowed_for_all_the_days_selected_start_time_must_be_before_end_time'
)}</p>
<p><strong> ${translate.byKey('days_allowed')}<br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('click_on_each_day_label_to_select_as_a_valid_access_day')} </p>
<p><strong> ${translate.byKey('doors_allowed')} <br /></strong></p>
<p style="padding-left: 30px;">${translate.byKey('click_on_each_door_label_to_select_as_a_valid_access_door')} </p>
<p><strong> ${translate.byKey('cancel')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('cancel_button_closes_the_dialog_window_no_changes_are_saved')}</p>
<p><strong> ${translate.byKey('submit')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'submit_buttons_close_the_dialog_window_saving_any_changes_made_to_property_fields'
)}</p>
`;

export default formHelp;
