import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import startCase from 'lodash/startCase';

import '~/exporters/Arial-normal';
import * as formatters from '~/exporters/formatters';
import { translator as translate } from '~/screens/_shared/AppLocale';
import { assaAbloyLogoImg, incedoCloudLogoImg } from '~/exporters/imagesBase64';

const DOCUMENT_FONT_FAMILY = 'Arial';
const DOCUMENT_ORIENTATION = 'portrait';
const DOCUMENT_UNIT = 'mm';
const DOCUMENT_FORMAT = 'a4';

const LEFT_MARGIN_LENGTH = 14;
const RIGHT_MARGIN_LENGTH = 14;

const BRAND_ASSA_ABLOY_LOGO_FORMAT = 'PNG';
const BRAND_ASSA_ABLOY_LOGO_WIDTH = 50;
const BRAND_ASSA_ABLOY_LOGO_HEIGHT = 0;
const BRAND_ASSA_ABLOY_LOGO_X_POSITION = LEFT_MARGIN_LENGTH + BRAND_ASSA_ABLOY_LOGO_WIDTH;
const BRAND_ASSA_ABLOY_LOGO_Y_POSITION = 0;

const BRAND_INCEDO_CLOUD_LOGO_FORMAT = 'PNG';
const BRAND_INCEDO_CLOUD_LOGO_WIDTH = 40;
const BRAND_INCEDO_CLOUD_LOGO_HEIGHT = 0;
const BRAND_INCEDO_CLOUD_LOGO_X_POSITION = LEFT_MARGIN_LENGTH - 1; // -1 to align with the table
const BRAND_INCEDO_CLOUD_LOGO_Y_POSITION = 15;

const TITLE_X_POSITION = LEFT_MARGIN_LENGTH;
const TITLE_Y_POSITION = 35;

const SITE_TITLE_X_POSITION = LEFT_MARGIN_LENGTH;
const SITE_TITLE_Y_POSITION = 40;

const INFORMATION_MESSAGE_X_POSITION = LEFT_MARGIN_LENGTH;
const INFORMATION_MESSAGE_Y_POSITION = 45;

const TABLE_THEME = 'grid';
const TABLE_FONT_STYLE = 'normal';
const TABLE_FONT_FAMILY = DOCUMENT_FONT_FAMILY;
const TABLE_FONT_SIZE = 9;
const TABLE_MIN_CELL_WIDTH = 20;
const TABLE_Y_POSITION = 40;

const PAGE_NUMBER_Y_POSITION = 290;

const OPERATOR_DATE_Y_POSITION = 290;

const RECORDS_LIMIT = 2000;

export async function downloadDataAsFile(exportData) {
	if (exportData && exportData.list && exportData.columns) {
		const { list, columns, onCellParsed, theme, siteName, currentUser, totalNumberOfRecords } = exportData;
		let tableYPosition = TABLE_Y_POSITION;

		const doc = new jsPDF({
			putOnlyUsedFonts: true,
			orientation: DOCUMENT_ORIENTATION,
			unit: DOCUMENT_UNIT,
			format: DOCUMENT_FORMAT,
			font: DOCUMENT_FONT_FAMILY,
		});

		const pageWidth = doc.internal.pageSize.getWidth();
		const title = startCase(exportData.title);

		doc.addImage(
			assaAbloyLogoImg,
			BRAND_ASSA_ABLOY_LOGO_FORMAT,
			pageWidth - BRAND_ASSA_ABLOY_LOGO_X_POSITION,
			BRAND_ASSA_ABLOY_LOGO_Y_POSITION,
			BRAND_ASSA_ABLOY_LOGO_WIDTH,
			BRAND_ASSA_ABLOY_LOGO_HEIGHT
		);
		doc.addImage(
			incedoCloudLogoImg,
			BRAND_INCEDO_CLOUD_LOGO_FORMAT,
			BRAND_INCEDO_CLOUD_LOGO_X_POSITION,
			BRAND_INCEDO_CLOUD_LOGO_Y_POSITION,
			BRAND_INCEDO_CLOUD_LOGO_WIDTH,
			BRAND_INCEDO_CLOUD_LOGO_HEIGHT
		);
		doc.text(title, TITLE_X_POSITION, TITLE_Y_POSITION);

		if (siteName) {
			doc.setFontSize(10);
			doc.text(`${translate.byKey('site')} - ${siteName}`, SITE_TITLE_X_POSITION, SITE_TITLE_Y_POSITION);
			tableYPosition += 5;
		}

		if (totalNumberOfRecords > RECORDS_LIMIT) {
			doc.setFontSize(10);
			doc.text(
				translate.byKeyFormatted('reports_export_information_message_limit', { limit: RECORDS_LIMIT }),
				INFORMATION_MESSAGE_X_POSITION,
				siteName ? INFORMATION_MESSAGE_Y_POSITION : INFORMATION_MESSAGE_Y_POSITION - 5
			);

			tableYPosition += 5;
		}

		const newList = list.map((row) => {
			return columns.reduce((newRow, column) => {
				const value = row[column.dataKey];
				const formatter = formatters.getFormatter(column, formatters);

				if (value) {
					newRow[column.dataKey] = formatter(value, row);
				} else {
					newRow[column.dataKey] = '';
				}

				return newRow;
			}, {});
		});

		doc.autoTable({
			theme: TABLE_THEME,
			headStyles: {
				fillColor: theme.colors.brand[500],
			},
			styles: {
				font: TABLE_FONT_FAMILY,
				fontStyle: TABLE_FONT_STYLE,
				fontSize: TABLE_FONT_SIZE,
				minCellWidth: TABLE_MIN_CELL_WIDTH,
			},
			startY: tableYPosition,
			columns,
			body: newList,
			didParseCell: ({ cell }) => {
				onCellParsed && onCellParsed(cell);
			},
		});

		// For some reason font size needs to be set again because in some cases font size is different
		doc.setFontSize(9);

		const operatorNameDateText = `${currentUser.firstName} ${currentUser.lastName}, ${moment().format('L LT')}`;
		const textWidth = doc.getTextWidth(operatorNameDateText);

		const pageCount = doc.internal.getNumberOfPages();
		for (let i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			doc.setFontSize(9);
			doc.text(`${i}/${pageCount}`, pageWidth / 2, PAGE_NUMBER_Y_POSITION);
			doc.text(operatorNameDateText, pageWidth - textWidth - RIGHT_MARGIN_LENGTH, OPERATOR_DATE_Y_POSITION);
		}

		return doc.save(`${title} ${moment().format('L LT')}.pdf`);
	}
}
