import moment from 'moment';
import { getCurrentSystemSiteCacheData } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const hardCodedEntitlements = [
	{
		name: 'Sample Entitlement',
		description: 'This is a sample entitlement',
		state: 'ACTIVE',
		validity: {
			startDateTime: moment().startOf('year').toISOString(),
			endDateTime: moment().endOf('year').toISOString(),
			gracePeriod: 'P90D',
		},
		features: {
			OFFLINE_LOCK_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			ONLINE_ACCESS_SUPPORT: {
				enabled: true,
			},
			ONLINE_PORTAL_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			APERIO_ONLINE_PORTAL_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			WALL_UPDATER_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			ACCESS_CONTROL_UNIT_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			CREDENTIAL_HOLDER_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			ACCESS_PROFILE_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
			DESKTOP_UPDATER_QUANTITY: {
				softLimit: 10000,
				hardLimit: 10000,
				usedCount: 5,
			},
		},
		customerId: '1004147',
		lastUpdatedDate: '2021-06-24T04:50:41.093+00:00',
		createdDate: '2021-06-24T04:50:41.093+00:00',
	},
];
const uiEntitlementsEnabled = localStorage.getItem('uiEntitlementsEnabled') === 'true';

export function getActiveEntitlements(system = null) {
	const { system: systemCacheData } = getCurrentSystemSiteCacheData();
	system = system || systemCacheData;

	if (localStorage.getItem('uiEntitlementsEnabled') === 'true') {
		if (!Array.isArray(system.entitlements)) {
			return [];
		}

		return system.entitlements.filter((entitlement) => {
			let isActive = entitlement.state === 'ACTIVE';

			if (isActive && entitlement.validity?.startDateTime && entitlement.validity?.endDateTime) {
				let endDate = moment(entitlement.validity.endDateTime);

				if (entitlement.validity.gracePeriod) {
					endDate = endDate.add(entitlement.validity.gracePeriod);
				}

				isActive = moment().isBetween(entitlement.validity.startDateTime, endDate);
			}

			return isActive;
		});
	} else {
		return Array.from(hardCodedEntitlements);
	}
}

export function hasOnlineFunctionality(system = null) {
	const entitlements = getActiveEntitlements(system);

	if (entitlements.length === 0) {
		return false;
	}

	return entitlements.reduce((result, entitlement) => {
		if (!result) {
			result = entitlement.features?.ONLINE_ACCESS_SUPPORT?.enabled === true;
		}

		return result;
	}, false);
}

export function hasOfflineFunctionality(system = null) {
	const entitlements = getActiveEntitlements(system);

	// This will allow all offline screens to be visible if entitlement is empty
	if (entitlements.length === 0) {
		return true;
	}

	return entitlements.reduce((result, entitlement) => {
		if (!result) {
			result = entitlement.features?.OFFLINE_LOCK_QUANTITY?.hardLimit > 0;
		}

		return result;
	}, false);
}

export function getLimits(system = null, limitType) {
	const limitFeatureName = {
		hid_online: 'ONLINE_PORTAL_QUANTITY',
		aperio_online: 'ONLINE_PORTAL_QUANTITY',
		aperio_offline: 'OFFLINE_LOCK_QUANTITY',
		pulse_offline: 'OFFLINE_LOCK_QUANTITY',
		usb_device: 'DESKTOP_UPDATER_QUANTITY',
		improx: 'ACCESS_CONTROL_UNIT_QUANTITY',
		passthrough: 'WALL_UPDATER_QUANTITY',
		credential_holder: 'CREDENTIAL_HOLDER_QUANTITY',
		access_group: 'ACCESS_PROFILE_QUANTITY',
	};

	const entitlements = getActiveEntitlements(system);

	if (entitlements.length === 0) {
		// Setting the hardLimit so no enforcement is actioned if entitlement is empty
		return { softLimit: 0, hardLimit: 10000, usedCount: 0, isActive: false };
	}

	const { features } = entitlements.find((entitlement) => entitlement?.features?.[limitFeatureName[limitType]]) || {};

	if (features) {
		return {
			softLimit: Number(features?.[limitFeatureName[limitType]].softLimit),
			hardLimit: Number(features?.[limitFeatureName[limitType]].hardLimit),
			usedCount: Number(features?.[limitFeatureName[limitType]].usedCount) || 0,
			isActive: uiEntitlementsEnabled,
		};
	}

	return { softLimit: 0, hardLimit: 0, usedCount: 0, isActive: false };
}
