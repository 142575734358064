export const adjustAPISchedulesToWeekPeriods = (schedules = []) =>
	schedules.map(({ standardWeek, timeIntervals }) => ({
		days: Object.entries(standardWeek)
			.filter(([day, enabled]) => enabled)
			.map(([day]) => day.toLowerCase()),
		times: [timeIntervals[0].startTime, timeIntervals[0].endTime],
	}));

const standardWeekAPI = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const adjustWeekPeriodsToAPISchedules = (weekPeriods) =>
	weekPeriods.map(({ days, times: [startTime, endTime] }) => ({
		standardWeek: standardWeekAPI.reduce(
			(previousDays, day) => ({ ...previousDays, [day]: days.includes(day.toLowerCase()) }),
			{}
		),
		timeIntervals: [{ startTime, endTime }],
	}));
