import styled from '@emotion/styled';
import { Card as ACard } from 'antd';

const Card = styled(ACard)`
	color: ${(props) => props.theme.colors.gray[300]};
	font-size: 14px;
	display: flex;
	align-items: center;

	.ant-card-head {
		height: 28px;
		display: flex;
		margin-left: 10px;
		align-items: center;
		padding-left: 4px;
		color: ${(props) => props.theme.colors.gray[500]};
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		background: transparent;
		border-bottom: 1px solid #f0f0f0;
		border-radius: 2px 2px 0 0;
	}

	.ant-card-body {
		padding: 0;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
`;

export default Card;
