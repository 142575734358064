import { translator as translate } from '~/screens/_shared/AppLocale';

export const generalHelp = () => `
<p><strong><u> ${translate.byKey('common_functions_header')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('site_selection')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'you_can_switch_between_sites_by_changing_the_drop_down_on_the_top_left_of_the_page_if_all_sites_is_selected_then_on_some_pages_it_may_show_data_from_every_site_on_the_system'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('logout')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'logout_will_exit_the_system__the_system_will_also_automatically_log_out_based_on_the_session_time_limit_'
)}</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'typing_in_the_Search_field_will_filter_through_the_current_table_for_similar_values_from_what_was_typed'
)} </p>
<p><strong><u> ${translate.byKey('page_specific_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('add_new')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('add_new_allows_you_to_enter_a_new_person_into_the_system')}&nbsp;</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('edit')}<br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_edit_a_person_click_on_the_person_name_in_the_table_list_to_edit_a_person_cards_click_on_view_cards_on_the_table_row_with_the_person_name'
)}</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('batch_importing')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('to_import_a_list_of_people_onto_the_system_message')} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('get_batch_upload_template')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'download_an_example_Batch_upload_file_and_complete_with_user_data'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('edit_delete')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('to_edit_delete_a_person')} </p>
<p style="padding-left: 30px;"> ${translate.byKey('to_edit_delete_a_card')} </p>
`;
