export async function getFileWithFetch(languageCode) {
	const code = languageCode.split('-')[0];

	const response = await fetch(`/translations/${code}.json`, {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	});

	if (!response.ok) {
		return null;
	}

	return await response.json();
}
