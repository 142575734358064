import * as csvExport from './CsvExporter';
import * as pdfExporter from './PdfExporter';

export function downloadDataAsFile(fileData) {
	const downloader = getDownloader(fileData);
	fileData.list = fileData.list || [];

	return downloader(fileData);
}

export function getDownloader(fileData) {
	if (fileData && fileData.type === 'csv') {
		return csvExport.downloadDataAsFile;
	} else if (fileData && fileData.type === 'pdf') {
		return pdfExporter.downloadDataAsFile;
	} else {
		throw new Error('Type of download is not supported');
	}
}
