import { useState } from 'react';

import mappers from '~/screens/_shared/mappers';
import { usePutApi } from '~/screens/_shared/useApi';
import { cacheKeys } from './doorHelper';

/* eslint-disable no-unused-expressions */
export const useUpdateDoorSchedule = () => {
	const [isUpdating, setIsUpdating] = useState(false);

	const [updateSchedule] = usePutApi(mappers.schedule);
	const [updateCalendar] = usePutApi(mappers.calendar);

	const updateDoorSchedule = async (data, onSuccess, onError) => {
		const { calendar, schedule } = data;

		try {
			let calendarUpdate, scheduleUpdate;
			setIsUpdating(true);

			if (calendar) {
				calendarUpdate = updateCalendar(calendar, {
					removeExistingKey: cacheKeys.calendarItemKey(calendar.calendarId),
				});
			}

			if (schedule) {
				scheduleUpdate = updateSchedule(schedule, {
					removeExistingKey: cacheKeys.scheduleItemKey(calendar.calendarId, schedule.scheduleId),
				});
			}

			await Promise.all([calendarUpdate, scheduleUpdate]);
			onSuccess?.();
		} catch (error) {
			onError?.();
		} finally {
			setIsUpdating(false);
		}
	};

	return { updateDoorSchedule, isUpdating };
};
