import React from 'react';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';
import { EnabledFilters } from './EnabledFilters';
import { Button } from 'antd';

export const EnabledFiltersSection = ({ enabledFilters, onReset, onRemove }) => {
	const { translate } = useLocale();

	if (!Boolean(enabledFilters.length)) {
		return null;
	}

	return (
		<div
			css={css({
				display: 'flex',
				alignItems: 'center',
			})}
		>
			<div>{translate.byKey('selected_filters')}:</div>
			<div
				css={css({
					flexGrow: 1,
					margin: '0 10px',
					overflowX: 'auto',
					whiteSpace: 'nowrap',
				})}
			>
				<EnabledFilters enabledFilters={enabledFilters} onRemove={onRemove} />
			</div>
			<Button onClick={onReset} type="link" css={css({ paddingInline: 0 })}>
				{translate.byKey('clear_filters')}
			</Button>
		</div>
	);
};
