import React from 'react';
import { Input } from 'antd';

import { ClearButton } from '~/components/shared/ClearButton/ClearButton';
import { useLocale } from '~/screens/_shared/AppLocale';

export const SearchFilterPanel = ({ value, placeholder, maxLength, onSearch, onClear, onPressEnter }) => {
	const { translate } = useLocale();

	return (
		<div style={{ padding: '8px' }}>
			<Input
				placeholder={placeholder || translate.byKey('search')}
				value={value}
				maxLength={maxLength}
				onChange={onSearch}
				suffix={<ClearButton onClick={onClear} />}
				onPressEnter={onPressEnter}
			/>
		</div>
	);
};
