import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const config = {
	revalidateOnFocus: false,
};

export const useCredentialStateUpdate = (key) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: 'customer/:customerId/system/:systemId/credential/:credentialId/state',
				method: method.put,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => ref.current.fetchData({ ...arg });

	return useSWRMutation(key, fetcher, config);
};

export const getCredentialStateKey = ({ customerId, systemId, credentialId }) =>
	customerId && systemId ? `${customerId}-${systemId}-${credentialId}-credential-state` : null;
