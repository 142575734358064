import { useRef } from 'react';
import useSWR from 'swr';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const config = {
	revalidateOnFocus: false,
};

export const useEmeaSystem = (key) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/administration/system/:systemId:',
				method: method.get,
			},
			handleDefaultApiError
		)
	);
	const fetcher = () => ref.current.fetchData();

	return useSWR(key, fetcher, config);
};

export const getEmeaSystemKey = ({ customerId, systemId }) => `${customerId}-${systemId}-emea`;
