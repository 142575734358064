import React from 'react';
import { Collapse, Descriptions, Form, Table } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { css } from '@emotion/core';
import moment from 'moment';

import {
	doorGroupsField,
	doorsField,
	locationField,
	nameField,
	peopleField,
} from '~/components/features/access-profiles/modals/form/fields';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getDoorGroupsKey, useDoorGroups } from '~/hooks/data/door-groups/useDoorGroups';
import { getAccessSchedulesKey, useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { getDoorsKey, useDoors } from '~/hooks/data/doors/useDoors';
import { getCredentialHoldersKey, useCredentialHolders } from '~/hooks/data/credential-holders/useCredentialHolders';
import { SummaryDownload } from '~/components/features/access-profiles/modals/form/Summary/SummaryDownload';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const { Panel } = Collapse;

const ROW_HEIGHT = 40;
const TABLE_SCROLL_HEIGHT_THRESHOLD = 500;

export const Summary = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId, sites },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { data: allDoorGroups } = useDoorGroups(getDoorGroupsKey({ customerId, systemId }));
	const { data: allDoors } = useDoors(getDoorsKey({ customerId, systemId }));
	const { data: allPeople } = useCredentialHolders(getCredentialHoldersKey({ customerId, systemId, siteId }));
	const { data: accessSchedules } = useAccessSchedules(getAccessSchedulesKey({ customerId, systemId }));
	const form = Form.useFormInstance();
	const {
		[nameField]: name,
		[locationField]: location,
		[doorGroupsField]: doorGroups,
		[doorsField]: doors,
		[peopleField]: people,
	} = form.getFieldsValue();

	return (
		<>
			<Collapse css={collapseStyles} ghost defaultActiveKey={['0', '1', '2', '3']}>
				<Panel header={translate.byKey('basic_info')} key="0">
					<Descriptions size="small" column={1} bordered>
						<DescriptionsItem label={translate.byKey('name')}>{name}</DescriptionsItem>
						<DescriptionsItem label={translate.byKey('location')}>
							{sites.find(({ id }) => id === location)?.name}
						</DescriptionsItem>
					</Descriptions>
				</Panel>

				<Panel header={translate.byKey('door_groups')} key="1">
					<Table
						dataSource={doorGroups}
						rowKey={({ doorGroup, accessSchedule } = {}, index) => `${doorGroup}-${accessSchedule}-${index}`}
						size="small"
						bordered
						sticky
						{...(doorGroups?.length * ROW_HEIGHT > TABLE_SCROLL_HEIGHT_THRESHOLD
							? { scroll: { y: TABLE_SCROLL_HEIGHT_THRESHOLD } }
							: {})}
						locale={{ emptyText: translate.byKey('no_door_groups_selected') }}
						columns={[
							{
								title: translate.byKey('door_group'),
								dataIndex: 'doorGroup',
								render: (doorGroupId) => allDoorGroups?.find(({ id }) => id === doorGroupId)?.name,
							},
							{
								title: translate.byKey('access_schedule'),
								dataIndex: 'accessSchedule',
								render: (accessScheduleId) =>
									accessSchedules?.accessSchedules?.find(({ id }) => id === accessScheduleId)?.name || '24/7',
							},
						]}
						pagination={false}
					/>
				</Panel>

				<Panel header={translate.byKey('doors')} key="2">
					<Table
						dataSource={doors}
						rowKey={({ door, accessSchedule } = {}, index) => `${door}-${accessSchedule}-${index}`}
						size="small"
						bordered
						sticky
						{...(doors?.length * ROW_HEIGHT > TABLE_SCROLL_HEIGHT_THRESHOLD
							? { scroll: { y: TABLE_SCROLL_HEIGHT_THRESHOLD } }
							: {})}
						locale={{ emptyText: translate.byKey('no_doors_selected') }}
						columns={[
							{
								title: translate.byKey('door'),
								dataIndex: 'door',
								render: (doorId) => allDoors?.find(({ id }) => id === doorId)?.name,
							},
							{
								title: translate.byKey('access_schedule'),
								dataIndex: 'accessSchedule',
								render: (accessScheduleId) =>
									accessSchedules?.accessSchedules?.find(({ id }) => id === accessScheduleId)?.name || '24/7',
							},
						]}
						pagination={false}
					/>
				</Panel>

				<Panel header={translate.byKey('people')} key="3">
					<Table
						dataSource={people}
						rowKey="id"
						size="small"
						bordered
						sticky
						{...(people?.length * ROW_HEIGHT > TABLE_SCROLL_HEIGHT_THRESHOLD
							? { scroll: { y: TABLE_SCROLL_HEIGHT_THRESHOLD } }
							: {})}
						locale={{ emptyText: translate.byKey('no_people_selected') }}
						columns={[
							{
								title: translate.byKey('name'),
								render: (person) => {
									const { firstName, lastName } = people?.find(({ id }) => id === person.id) || {};

									return `${firstName} ${lastName}`;
								},
							},
							{
								title: translate.byKey('email_address'),
								render: (person) => {
									const { emailAddress } = people?.find(({ id }) => id === person.id) || {};

									return emailAddress;
								},
							},
							{
								title: `${translate.byKey('valid_from')} - ${translate.byKey('valid_until')}`,
								render: (person) => {
									const { validFrom, validUntil } = people?.find(({ id }) => id === person.id) || {};

									return `${moment(validFrom).format('L')} - ${
										validUntil ? moment(validUntil).format('L') : translate.byKey('unset')
									}`;
								},
							},
						]}
						pagination={false}
					/>
				</Panel>
			</Collapse>

			<SummaryDownload css={printButtonStyles} />
		</>
	);
};

const collapseStyles = css({
	'.ant-table-body': {
		height: 'auto',
	},
});

const printButtonStyles = css({ marginTop: '24px' });
