import { useMemo } from 'react';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

import { getSiteControllersKey, useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import {
	getControllerLimits,
	getLowestControllerInfo,
} from '~/hooks/features/useSiteInformation/useSiteInformation.utils';

export const useSiteInformation = () => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		isLoading: isLoadingSiteControllers,
		isValidating: isValidatingSiteControllers,
		data,
	} = useSiteControllers(
		getSiteControllersKey({
			customerId,
			systemId,
			siteId,
			detailLevel: 'FULL',
		})
	);

	const controllersInfo = useMemo(() => getLowestControllerInfo(data), [data]);

	const controllerLimits = useMemo(() => getControllerLimits(controllersInfo), [controllersInfo]);

	const isDataReady = !isLoadingSiteControllers && !isValidatingSiteControllers && data;

	return isDataReady
		? {
				lowestControllerType: controllersInfo?.lowestControllerType,
				lowestControllerFirmwareVersion: controllersInfo?.lowestControllerFirmwareVersion,
				...controllerLimits,
		  }
		: {};
};
