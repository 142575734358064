import styled from '@emotion/styled';
import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';

const { CheckableTag } = Tag;

let StyledCheckableTag = styled(CheckableTag)`
	width: 58.5px;
	text-align: center;
	margin: 0.1px;
	padding: 0;
	font-size: 9.5px;
	border-width: 0.5px;
	border-style: solid;
	border-color: lightgrey;
	cursor: pointer;
	// .ant-tag-checkable-checked {
	//   background-color: ${(props) => props.theme.colors.brand[500]} !important;
	// }
	${layout}
	${space}
${flexbox}
${size}
${background}
${grid}
${color}
${typography}
${border}
${position}
${shadow}
`;

const DayPicker = (props) => {
	const { value, onChange } = props;
	const days = value || [];
	const theme = useTheme();
	const { translate } = useLocale();

	const [, setFields] = useState([
		{
			day: translate.byKey('sunday'),
			index: 0,
			enabled: false,
		},
		{
			day: translate.byKey('monday'),
			index: 1,
			enabled: false,
		},
		{
			day: translate.byKey('tuesday'),
			index: 2,
			enabled: false,
		},
		{
			day: translate.byKey('wednesday'),
			index: 3,
			enabled: false,
		},
		{
			day: translate.byKey('thursday'),
			index: 4,
			enabled: false,
		},
		{
			day: translate.byKey('friday'),
			index: 5,
			enabled: false,
		},
		{
			day: translate.byKey('saturday'),
			index: 6,
			enabled: false,
		},
		{
			day: translate.byKey('holidays'),
			index: 7,
			enabled: false,
		},
	]);

	useEffect(() => {
		if (days && days.length > 0) {
			setFields(days);
		}
	}, [days]);

	let onDayChange = (item, checked) => {
		const fields = [
			{
				day: translate.byKey('sunday'),
				index: 0,
				enabled: item.index === 0 ? checked : days[0]?.enabled,
			},
			{
				day: translate.byKey('monday'),
				index: 1,
				enabled: item.index === 1 ? checked : days[1]?.enabled,
			},
			{
				day: translate.byKey('tuesday'),
				index: 2,
				enabled: item.index === 2 ? checked : days[2]?.enabled,
			},
			{
				day: translate.byKey('wednesday'),
				index: 3,
				enabled: item.index === 3 ? checked : days[3]?.enabled,
			},
			{
				day: translate.byKey('thursday'),
				index: 4,
				enabled: item.index === 4 ? checked : days[4]?.enabled,
			},
			{
				day: translate.byKey('friday'),
				index: 5,
				enabled: item.index === 5 ? checked : days[5]?.enabled,
			},
			{
				day: translate.byKey('saturday'),
				index: 6,
				enabled: item.index === 6 ? checked : days[6]?.enabled,
			},
			{
				day: translate.byKey('holidays'),
				index: 7,
				enabled: item.index === 7 ? checked : days[7]?.enabled,
			},
		];

		if (onChange) {
			onChange(fields);
		}
	};

	return (
		<div
			css={css`
				.ant-tag-checkable-checked {
					background-color: ${theme.colors.brand[500]} !important;
				}
				.ant-tag-checkable {
					background-color: transparent;
				}
			`}
		>
			{days &&
				days.length > 0 &&
				days.map((item, index) => {
					return (
						<StyledCheckableTag
							{...props}
							key={index}
							checked={item.enabled}
							onChange={(checked) => onDayChange(item, checked)}
						>
							{item.day}
						</StyledCheckableTag>
					);
				})}
		</div>
	);
};

export default DayPicker;
