import { translator as translate } from '~/screens/_shared/AppLocale';
import HelpViewNames from '~/help/HelpViewNames';

function getReportDescription(reportType) {
	return reportType === HelpViewNames.STATUSREPORT
		? 'status_transaction_report_contains_system_hardware_status_messages'
		: reportType === HelpViewNames.ACCESSREPORT
		  ? 'access_transaction_report_contains_system_hardware_status_messages'
		  : 'audit_transaction_report_contains_system_hardware_status_messages';
}

function getReportHeading(reportType) {
	return reportType === HelpViewNames.STATUSREPORT
		? 'status_transaction_report'
		: reportType === HelpViewNames.ACCESSREPORT
		  ? 'access_transaction_report'
		  : 'audit_transaction_report';
}

const generalHelp = (reportType) =>
	`<p><strong><u> ${translate.byKey('common_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('site_selection')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'you_can_switch_between_sites_by_changing_the_drop_down_on_the_top_left_of_the_page_if_all_sites_is_selected_then_on_some_pages_it_may_show_data_from_every_site_on_the_system'
	)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('logout')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'logout_will_exit_the_system_the_system_will_also_automatically_log_out_based_on_the_session_time_limit'
	)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'typing_in_the_search_field_will_filter_through_the_current_table_for_similar_values_from_what_was_typed'
	)} </p>
<p><strong><u> ${translate.byKey('page_specific_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey(getReportHeading(reportType))} </strong></p>

<p style="padding-left: 30px;"> ${translate.byKey(getReportDescription(reportType))} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'input_value_will_be_cross_checked_with_the_data_in_each_column'
	)} </p>

<p style="padding-left: 30px;"><strong> ${translate.byKey('pdf')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('create_a_pdf_report')} </p>

<p style="padding-left: 30px;"><strong> ${translate.byKey('csv')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('create_a_csv_text_file')} </p>

<p style="padding-left: 30px;"><strong> ${translate.byKey('refresh')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'clicking_reload_will_refresh_the_report_using_the_current_filter_set'
	)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('filters')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
		'by_clicking_the_filters_button_you_will_be_able_to_customise_the_data_that_is_returned_based_on_criteria_selected'
	)} </p>
`;

export default generalHelp;
