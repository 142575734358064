import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Divider } from 'antd';
import isEqual from 'lodash/isEqual';

import { filterTextByPartialText } from '~/utils/filter';
import { ResetApplyFilterPanel } from '~/components/shared/filters/panels/ResetApplyFilterPanel/ResetApplyFilterPanel';
import { SearchFilterPanel } from '~/components/shared/filters/panels/SearchFilterPanel/SearchFilterPanel';
import { NoResultsFilterPanel } from '~/components/shared/filters/panels/NoResultsFilterPanel/NoResultsFilterPanel';
import { BodyFilterPanel } from '~/components/shared/filters/panels/BodyFilterPanel/BodyFilterPanel';
import { FilterItem } from '~/components/shared/filters/FilterItem/FilterItem';
import { defaultSorter } from '~/utils/sort';

const areSelectedOptionsEqual = (optionsOne, optionsTwo) => {
	const optionsOneSortedCopy = [...optionsOne].sort();
	const optionsTwoSortedCopy = [...optionsTwo].sort();

	return isEqual(optionsOneSortedCopy, optionsTwoSortedCopy);
};

export const MultipleChoiceFilter = ({
	antdFilterProps,
	options,
	placeholder,
	shouldSort = true,
	showSearch = true,
}) => {
	const {
		setSelectedKeys: setAntdSelectedKeys,
		selectedKeys: antdSelectedKeys,
		confirm,
		visible,
		clearFilters,
	} = antdFilterProps;
	const [searchValue, setSearchValue] = useState('');
	const [checkedValues, setCheckedValues] = useState(antdSelectedKeys || []);
	const resetDisabled = !Boolean(antdSelectedKeys.length);
	const applyDisabled =
		areSelectedOptionsEqual(checkedValues, antdSelectedKeys) ||
		(!Boolean(checkedValues.length) && !Boolean(antdSelectedKeys.length));

	const panelRef = useRef(null);

	useEffect(() => {
		if (visible && antdSelectedKeys.length) {
			setCheckedValues(antdSelectedKeys);
		} else if (!visible) {
			setCheckedValues([]);
		}
	}, [visible, antdSelectedKeys, setCheckedValues]);

	const onSearch = (event) => {
		setSearchValue(event.target.value);
	};

	const onSearchClear = () => {
		setSearchValue('');
	};

	const onItemCheck = (newValues) => {
		setCheckedValues(newValues);
	};

	const onReset = () => {
		clearFilters({ confirm: true, closeDropdown: true });
		// Timeout added to prevent flicker, smooth scroll doesn't work without it
		setTimeout(() => {
			panelRef.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
		}, 1);
	};

	const onApply = () => {
		setAntdSelectedKeys(checkedValues);
		confirm();
	};

	const onEnterPress = (event) => {
		if (event.key === 'Enter' && !applyDisabled) {
			onApply();
		}
	};

	let filteredOptions = options.filter(({ label }) => label && filterTextByPartialText(label, searchValue));

	if (shouldSort) {
		filteredOptions.sort(({ label: labelOne }, { label: labelTwo }) => defaultSorter(labelOne, labelTwo));
	}

	return (
		<div style={{ minWidth: '200px' }} onKeyDown={onEnterPress}>
			{showSearch ? (
				<>
					<SearchFilterPanel
						value={searchValue}
						placeholder={placeholder}
						onSearch={onSearch}
						onClear={onSearchClear}
					/>

					<Divider style={{ margin: '0px' }} />
				</>
			) : null}

			<BodyFilterPanel ref={panelRef}>
				{filteredOptions.length ? (
					<Checkbox.Group onChange={onItemCheck} value={checkedValues} style={{ width: '100%' }}>
						{filteredOptions.map(({ label, value, count }) => (
							<FilterItem key={value}>
								<Checkbox value={value}>{count !== undefined ? `${label} (${count})` : label}</Checkbox>
							</FilterItem>
						))}
					</Checkbox.Group>
				) : (
					<NoResultsFilterPanel />
				)}
			</BodyFilterPanel>

			<Divider style={{ margin: '0px' }} />

			<ResetApplyFilterPanel
				resetDisabled={resetDisabled}
				applyDisabled={applyDisabled}
				onReset={onReset}
				onApply={onApply}
			/>
		</div>
	);
};
