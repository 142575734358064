import React from 'react';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import moment from 'moment';

import { Spin } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getReadableName } from '~/screens/_shared/getReadableName';
import { isPulseKey } from '~/components/features/people/People.utils';
import { convertCredentialHexToDecimal } from '~/utils/credential/credential';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { DEFAULT_SORT_DIRECTION } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { antdToApiSortModes, apiToAntdSortModes } from '~/utils/sort';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';

export const BlocklistTable = ({ data, isLoading, onRowClick }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const {
		queryParams: { pageSize, pageNumber, person, sortBy, sortDirection },
		setQueryParams,
	} = useQueryParams();

	const onTableChange = (pagination, filters, sorter) => {
		const isPageSizeChanged = pageSize ? pagination.pageSize !== pageSize : pagination.pageSize !== DEFAULT_PAGE_SIZE;

		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: isPageSizeChanged ? 1 : pagination.current,
			sortBy: sorter.columnKey,
			sortDirection: antdToApiSortModes[sorter.order],
			person: filters?.name?.[0] || '',
		});
	};

	return (
		<TableWithFiltersSection
			rowKey="credentialId"
			dataSource={data?.credentials}
			onChange={onTableChange}
			onRow={(blockedCredential) => ({
				onClick: () => {
					if (hasActionPermissions(user, userConstants.screens.BLOCKLIST, userConstants.actions.DELETE)) {
						onRowClick(blockedCredential);
					}
				},
			})}
			loading={{
				spinning: isLoading,
				indicator: <Spin active={true} />,
			}}
			columns={[
				{
					dataIndex: 'credentialHolder',
					title: translate.byKey('person'),
					key: 'name',
					// TODO #2 START - ATM backend sorts by name, not credentialHolder.name property so this part
					// is not working for this column. When the support exists, revisit this code below.
					// sorter: createSorter('name'),
					// defaultSortOrder: apiToAntdSortModes[DEFAULT_SORT_DIRECTION]x`,
					// sortDirections: ['ascend', 'descend', 'ascend'],
					// TODO #2 END
					render: (credentialHolder) => getReadableName(credentialHolder.name),
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: person ? [person] : null,
					filterIcon: <SearchFilterIcon />,
				},
				{
					dataIndex: 'name',
					title: translate.byKey('credential_type'),
					render: (name) => translate.byKey(isPulseKey({ name }) ? 'pulse_v2' : 'credential'),
				},
				{
					title: translate.byKey('credential_number_v2'),
					render: convertCredentialHexToDecimal,
				},
				{
					dataIndex: 'description',
					title: translate.byKey('date_blocked_v2'),
					key: 'description',
					sorter: createSorter('description'),
					defaultSortOrder:
						sortDirection && sortBy === 'description'
							? apiToAntdSortModes[sortDirection]
							: apiToAntdSortModes[DEFAULT_SORT_DIRECTION],
					sortDirections: ['ascend', 'descend', 'ascend'],
					render: (value) => (value ? moment(value).format('L') : ''),
				},
			]}
			pagination={{
				showSizeChanger: true,
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
				position: ['bottomCenter'],
				pageSize: pageSize || DEFAULT_PAGE_SIZE,
				total: data?.totalRecords,
				current: parseInt(pageNumber) || 1,
				disabled: isLoading,
			}}
			scroll={getScrollHeightOptions()}
		/>
	);
};
