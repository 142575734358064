import React from 'react';
import { Space } from 'antd';

import { Button } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { DOOR_GROUP_FORM_NAME } from '~/components/features/door-groups/DoorGroupForm/DoorGroupForm';

export const AddDoorGroupModalFooter = ({ isSubmitDisabled, isSubmitting, onClose }) => {
	const { translate } = useLocale();

	return (
		<Space
			align="center"
			style={{
				width: '100%',
				justifyContent: 'flex-end',
			}}
		>
			<div>
				<Button disabled={isSubmitting} onClick={onClose}>
					{translate.byKey('cancel')}
				</Button>
				<Button
					htmlType="submit"
					type="primary"
					form={DOOR_GROUP_FORM_NAME}
					disabled={isSubmitDisabled}
					loading={isSubmitting}
				>
					{translate.byKey('create')}
				</Button>
			</div>
		</Space>
	);
};
