import { Col, Row, Select, Input } from 'antd';
import { Item } from '~/components';
import React, { useEffect, useState } from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useListApi } from '~/screens/_shared/useApi';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { filterSupportedRoles, getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';
import { canCurrentAdminEditAdministrators } from '~/components/features/people/People.hooks';
import { InLineResetPasswordForm } from '~/components/features/people/modals/PeopleModal/tabs/PrivilegesTab/InLineResetPasswordForm';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const PrivilegesInputs = ({ form, visible, isUpdate, sameAdminAsLogin, adminSelected, isEditDisabled }) => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();

	const [getAdministratorRoles] = useListApi('/administration/administrator/:administratorId/role');
	const [canEditAdministrators, setCanEditAdministrators] = useState(false);

	const [roles, setRoles] = useState([]);
	const [notificationTemplates] = useState([]);
	let isPrimarySystemOwner = false;

	useEffect(() => {
		if (system && system.businessEntityId && visible) {
			const currentAdmin = getCurrentAdministrator();

			if (currentAdmin) {
				if (adminSelected && adminSelected.administratorId) {
					if (
						adminSelected.administratorId === system.primarySystemOwner.administratorId ||
						(adminSelected.roles &&
							Object.keys(adminSelected.roles).length &&
							adminSelected.roles.find((role) => role.type === 'SYSTEM_OWNER'))
					) {
						isPrimarySystemOwner = true;
					}
				}
				// TODO add code to reload the system to hydrate the roles again at this point.
				// Why? If two admins are using the same system, and both create a Guard, for example, at the same time,
				// then there is a chance that two roles will be created because the role of the first user won't
				// appear in this list during the creation process.
				setRoles(filterSupportedRoles(system.roles, isPrimarySystemOwner).sort(createSorter('name')));
			}
			if (!canEditAdministrators) {
				canCurrentAdminEditAdministrators(getAdministratorRoles)
					.then((canEdit) => {
						setCanEditAdministrators(canEdit || currentAdmin.administratorId === form.getFieldValue('administratorId'));
					})
					.catch(() => {
						setCanEditAdministrators(false);
					});
			}
		}
	}, [visible]);

	return (
		<>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<Item name="endCustomerContactRoleId" shouldUpdate noStyle>
						<Input type="hidden" />
					</Item>

					<Item
						name="selectedRoleId"
						label={translate.byKey('role_for_administrator')}
						required={true}
						shouldUpdate
						rules={
							isEditDisabled
								? []
								: [
										({ getFieldValue }) => ({
											validator() {
												const isAdministrator = getFieldValue('isAdministrator');
												const selectedId = getFieldValue('selectedRoleId');

												if (isAdministrator) {
													if (!selectedId) {
														return Promise.reject(translate.byKey('select_a_role_for_administrator'));
													} else {
														return Promise.resolve();
													}
												}
											},
										}),
								  ]
						}
					>
						<Select
							disabled={!canEditAdministrators || sameAdminAsLogin || isEditDisabled}
							placeholder={translate.byKey('select_a_role_for_administrator')}
							allowClear
						>
							{roles.map((item, index) => {
								return (
									<Select.Option key={item.roleId + index} value={item.roleId}>
										{translate.byKey(item.name) || translate.byValue(item.name) || item.name}
									</Select.Option>
								);
							})}
						</Select>
					</Item>
				</Col>
				<Col span={24}>
					{isUpdate && !isEditDisabled && form.getFieldValue('administratorId') && (
						<InLineResetPasswordForm emailAddress={form.getFieldValue('email')} />
					)}
				</Col>

				{notificationTemplates.length > 0 && (
					<Col span={24}>
						<Item
							name="selectedNotificationTemplateId"
							label={translate.byKey('notification_template_for_administrator')}
							shouldUpdate
						>
							<Select
								disabled={!canEditAdministrators || sameAdminAsLogin || isEditDisabled}
								placeholder={translate.byKey('select_notification_template_for_administrator')}
								allowClear
							>
								{notificationTemplates.map((item, index) => {
									return (
										<Select.Option key={item.templateId + index} value={item.templateId}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
				)}
			</Row>
		</>
	);
};
