import React, { useEffect } from 'react';
import moment from 'moment';

import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getScrollHeightOptions } from '~/constants/Table';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { defaultPageNumber } from '~/screens/Reports/StatusTransactions/StatusTransactionsPage.utils';
import { MultipleChoiceFilter } from '~/components/shared/filters/MultipleChoiceFilter/MultipleChoiceFilter';
import { antdToApiSortModes, defaultSorter } from '~/utils/sort';
import { useLiveEventsFilters } from '~/components/features/live/LiveEventsTable/LiveEventsTable.hooks';
import { formatEventSource } from '~/components/features/live/LiveEventsTable/LiveEventsTable.utils';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { useLiveEvents } from '~/components/features/live/LiveEvents.hooks';
import {
	getTimeRangeFilterValueLabel,
	TimeRangeFilter,
} from '~/components/shared/filters/TimeRangeFilter/TimeRangeFilter';
import { LoadingIcon } from '~/components/shared/LoadingIcon/LoadingIcon';
import { filterTextByPartialText } from '~/utils/filter';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';
import { StatusTransactionEventNames } from '~/constants/transactions';

export const LiveEventsTable = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { queryParams, setQueryParams, clearAllQueryParams } = useQueryParams();
	const { data: liveEvents, isLoading, isValidating } = useLiveEvents();
	const { filters } = useLiveEventsFilters();
	const isSiteSelected = Boolean(siteId);

	useEffect(() => {
		const clearAllQueryParamsOnSiteOrSystemChange = () => {
			clearAllQueryParams();
		};

		return clearAllQueryParamsOnSiteOrSystemChange;
	}, [siteId, systemId]);

	useEffect(() => {
		if (!window.location.search && !isLoading) {
			const filteredEventNames = filters?.eventName
				?.filter((event) => event.value.toLowerCase() !== StatusTransactionEventNames.IOT_HEARTBEAT_STATE)
				?.map((event) => event.value);
			setQueryParams({
				eventNames: filteredEventNames?.join(',') || '',
			});
		}
	}, [filters, isLoading]);

	const liveEventsFiltered = liveEvents.filter((event) => {
		const { eventName, reasonCode, siteName, controllerName, credentialHolderName, cardNumber, dateTime } = event;

		if (queryParams?.eventNames) {
			const eventNames = queryParams.eventNames.split(',');

			if (eventNames.length && !eventNames.includes(eventName)) {
				return false;
			}
		}

		if (queryParams?.eventTypes) {
			const eventTypes = queryParams.eventTypes.split(',');

			if (eventTypes.length && !eventTypes.includes(reasonCode)) {
				return false;
			}
		}

		if (queryParams?.sites) {
			const sites = queryParams.sites.split(',');

			if (sites.length && !sites.includes(siteName)) {
				return false;
			}
		}

		if (queryParams?.controllers) {
			const controllers = queryParams.controllers.split(',');

			if (controllers.length && !controllers.includes(controllerName)) {
				return false;
			}
		}

		if (queryParams?.eventSources) {
			const eventSources = queryParams.eventSources.split(',');

			if (eventSources.length && !eventSources.includes(formatEventSource(event))) {
				return false;
			}
		}

		if (
			queryParams.credentialHolderName &&
			!filterTextByPartialText(credentialHolderName, queryParams?.credentialHolderName)
		) {
			return false;
		}

		const cardNumberAsDecimalString = isNaN(parseInt(cardNumber?.[0], 16))
			? ''
			: parseInt(cardNumber?.[0], 16).toString();

		if (queryParams.credential && !cardNumberAsDecimalString?.includes?.(queryParams.credential)) {
			return false;
		}

		const dateTimeAsTimeStringOnly = moment(dateTime).format('HH:mm:ss');

		if (
			queryParams.startTime &&
			moment(dateTimeAsTimeStringOnly, 'HH:mm:ss').isBefore(moment(queryParams.startTime, 'HH:mm:ss'))
		) {
			return false;
		}

		if (
			queryParams.endTime &&
			moment(dateTimeAsTimeStringOnly, 'HH:mm:ss').isAfter(moment(queryParams.endTime, 'HH:mm:ss'))
		) {
			return false;
		}

		return true;
	});

	const onTableChange = (pagination, filters, sorter) => {
		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: pagination.current,
			eventNames: filters.eventName?.join(',') || '',
			eventTypes: filters.reasonCode?.join(',') || '',
			sites: filters.siteName?.join(',') || '',
			controllers: filters.controllerName?.join(',') || '',
			eventSources: filters.eventSource?.join(',') || '',
			credentialHolderName: filters.credentialHolderName || '',
			credential: filters.cardNumber || '',
			startTime: filters.dateTime?.[0]?.startTime?.format?.('HHmm'),
			endTime: filters.dateTime?.[0]?.endTime?.format?.('HHmm'),
			sortBy: sorter.field,
			sortDirection: antdToApiSortModes[sorter.order],
		});
	};

	return (
		<TableWithFiltersSection
			dataSource={liveEventsFiltered}
			rowKey="eventLogId"
			onChange={onTableChange}
			scroll={getScrollHeightOptions(420)}
			pagination={{
				showSizeChanger: true,
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
				position: ['bottomCenter'],
				pageSize: queryParams?.pageSize || DEFAULT_PAGE_SIZE,
				total: liveEventsFiltered.length,
				current: parseInt(queryParams?.pageNumber) || defaultPageNumber,
			}}
			loading={{
				spinning: isLoading || isValidating,
				indicator: <LoadingIcon />,
			}}
			columns={[
				{
					dataIndex: 'eventName',
					title: translate.byKey('event_name'),
					render: (value) => translate.byKey(value.toLocaleLowerCase()),
					sorter: (one, two) =>
						defaultSorter(
							translate.byKey(one?.eventName?.toLocaleLowerCase()),
							translate.byKey(two?.eventName?.toLocaleLowerCase())
						),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <MultipleChoiceFilter antdFilterProps={props} options={filters.eventName} />,
					filteredValue: queryParams.eventNames ? queryParams.eventNames.split(',') : null,
					enabledFilterProps: (value) => ({
						getValueLabel: () => {
							const valueLabel = filters.eventName.find((eventName) => eventName.value === value)?.label;

							return valueLabel ? translate.byKey(valueLabel) : value;
						},
					}),
				},
				{
					dataIndex: 'reasonCode',
					title: translate.byKey('event_type'),
					render: (value) => (value ? translate.byKey(value.toLocaleLowerCase()) : ''),
					sorter: (one, two) =>
						defaultSorter(
							translate.byKey(one?.reasonCode?.toLocaleLowerCase()),
							translate.byKey(two?.reasonCode?.toLocaleLowerCase())
						),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <MultipleChoiceFilter antdFilterProps={props} options={filters.eventType} />,
					filteredValue: queryParams.eventTypes ? queryParams.eventTypes.split(',') : null,
					enabledFilterProps: (value) => ({
						getValueLabel: () => {
							const valueLabel = filters.eventType.find((eventType) => eventType.value === value)?.label;

							return valueLabel ? translate.byKey(valueLabel) : value;
						},
					}),
				},
				{
					dataIndex: 'dateTime',
					title: translate.byKey('time'),
					render: (value) => (value ? moment(value).format('L LTS') : ''),
					sorter: (one, two) => moment(one.dateTime) - moment(two.dateTime),
					defaultSortOrder: 'descend',
					sortDirections: ['descend', 'ascend', 'descend'],
					filteredValue:
						queryParams.startTime || queryParams.endTime
							? [
									{
										startTime: queryParams.startTime ? moment(queryParams.startTime, 'HHmm') : '',
										endTime: queryParams.endTime ? moment(queryParams.endTime, 'HHmm') : '',
									},
							  ]
							: null,
					filterDropdown: (props) => <TimeRangeFilter antdFilterProps={props} />,
					enabledFilterProps: (value) => ({
						getValueLabel: () => getTimeRangeFilterValueLabel(value),
					}),
				},
				...(isSiteSelected
					? []
					: [
							{
								dataIndex: 'siteName',
								title: translate.byKey('site'),
								sorter: (one, two) => defaultSorter(one?.siteName, two?.siteName),
								sortDirections: ['descend', 'ascend', 'descend'],
								filterDropdown: (props) => <MultipleChoiceFilter antdFilterProps={props} options={filters.site} />,
								filteredValue: queryParams.sites ? queryParams.sites.split(',') : null,
								enabledFilterProps: (value) => ({
									getValueLabel: () => {
										const valueLabel = filters.site.find((site) => site.value === value)?.label;

										return valueLabel || '';
									},
								}),
							},
					  ]),
				{
					dataIndex: 'controllerName',
					title: translate.byKey('connected_device'),
					sorter: (one, two) => defaultSorter(one?.controllerName, two?.controllerName),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <MultipleChoiceFilter antdFilterProps={props} options={filters.controllers} />,
					filteredValue: queryParams.controllers ? queryParams.controllers.split(',') : null,
					enabledFilterProps: (value) => ({
						getValueLabel: () => {
							const valueLabel = filters.controllers.find((controller) => controller.value === value)?.label;

							return valueLabel || '';
						},
					}),
				},
				{
					key: 'eventSource',
					title: translate.byKey('event_source'),
					render: (value) => formatEventSource(value),
					sorter: (one, two) => defaultSorter(formatEventSource(one), formatEventSource(two)),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <MultipleChoiceFilter antdFilterProps={props} options={filters.eventSources} />,
					filteredValue: queryParams.eventSources ? queryParams.eventSources.split(',') : null,
					enabledFilterProps: (value) => ({
						getValueLabel: () => {
							const valueLabel = filters.eventSources.find((eventSource) => eventSource.value === value)?.label;

							return valueLabel || '';
						},
					}),
				},
				{
					dataIndex: 'credentialHolderName',
					title: translate.byKey('person'),
					render: (value) => (value === 'NA' || value === 'N/A' ? translate.byKey('unknown') : value || ''),
					sorter: (one, two) => defaultSorter(one?.credentialHolderName, two?.credentialHolderName),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: queryParams.credentialHolderName ? [queryParams.credentialHolderName] : null,
					filterIcon: <SearchFilterIcon />,
				},
				{
					dataIndex: 'cardNumber',
					title: translate.byKey('credential'),
					render: (value) => (isNaN(parseInt(value?.[0], 16)) ? '' : parseInt(value?.[0], 16)),
					sorter: (one, two) => defaultSorter(one?.cardNumber?.[0], two?.cardNumber?.[0]),
					sortDirections: ['descend', 'ascend', 'descend'],
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} numbersOnly />,
					filteredValue: queryParams.credential ? [queryParams.credential] : null,
					filterIcon: <SearchFilterIcon />,
				},
			]}
		/>
	);
};
