import { useTheme } from 'emotion-theming';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Rnd } from 'react-rnd';

import { SchedulePickerContext } from './';
import { Select, Modal } from '~/components';
import { css } from '@emotion/core';
import { invisibleTimes } from './labels';
import { securityLevels } from '../entityConstants';
import { isInOccupiedSpace, mapSlotsToValueArray } from './helpers';
import * as actions from './actions';

import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Tooltip } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const labels = new Map([
	[securityLevels.CARD_AND_PIN, 'card_and_pin_label'],
	[securityLevels.PAC, 'pac_label'],
	[securityLevels.LOCKED, 'locked_label'],
	[securityLevels.UNLOCKED, 'unlocked_label'],
	[securityLevels.OPEN_ON_FIRST_CARD, 'open_on_first_card_label'],
	[securityLevels.OFFICE_MODE, 'office_mode'],
]);

function getLabel(type, translate, times = '') {
	const label = translate.byKey(labels.get(type));

	return times ? `${label} ${times}` : label;
}

const SelectionBox = ({
	rowHeight,
	width,
	index,
	interval,
	slotId,
	enableOfficeModeWarning,
	setEnableOfficeModeWarning,
	isActiveTab,
}) => {
	const theme = useTheme();
	const { dispatch, state } = useContext(SchedulePickerContext);
	const [occupiedBlocks, setOccupiedBlocks] = useState(new Array(invisibleTimes.length).fill(0));
	const [border, setBorder] = useState(`1px solid ${theme.colors.gray[300]}`);
	const [isStable, setIsStable] = useState(false);
	const { data: user } = useUserAuthData();
	const { translate } = useLocale();

	useEffect(() => {
		// https://github.com/bokuweb/react-rnd/issues/846
		// isStable is used to prevent doubling the y offset
		// to work properly, Rnd must render after the tab has been loaded
		if (isActiveTab) {
			setTimeout(() => {
				setIsStable(true);
			}, 1);
		}
	}, [isActiveTab]);

	useEffect(() => {
		const currentSlot = state.timeSlots.find((o) => o && o.id === slotId);
		setOccupiedBlocks(mapSlotsToValueArray(currentSlot.modeTimeIntervals));
	}, [state]);

	const onIntervalDragged = useCallback(
		(e, d) => {
			setBorder(`1px solid ${theme.colors.gray[300]}`);
			const { y: oldY, deltaY } = d;

			const verticalRowSpan = Math.floor(d.node.clientHeight / rowHeight);

			const newStartIndex = Math.floor((oldY - deltaY) / rowHeight);
			const newEndIndex = newStartIndex + verticalRowSpan + 1;

			const startTime = invisibleTimes[newStartIndex];
			const endTime = invisibleTimes[newEndIndex];

			if (
				startTime &&
				endTime &&
				Number.isInteger(index) &&
				!isInOccupiedSpace(newStartIndex, newEndIndex, occupiedBlocks, index + 1) &&
				hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.WRITE) === true
			) {
				dispatch({
					type: actions.UPDATE_SINGLE_SLOT_INTERVAL,
					payload: {
						slotId,
						intervalData: {
							index,
							startTime,
							endTime,
							state: interval.state,
						},
					},
				});
			}
		},
		[interval, index, occupiedBlocks]
	);

	const onIntervalResized = useCallback(
		(e, direction, element, d, newPosition) => {
			setBorder(`1px solid ${theme.colors.gray[300]}`);
			const { y } = newPosition;

			let newStartIndex = y / rowHeight;
			let flooredIndex = Math.floor(newStartIndex);
			if (flooredIndex === invisibleTimes.length - 1) {
				newStartIndex = flooredIndex;
			} else if (newStartIndex !== flooredIndex && newStartIndex > flooredIndex + 0.5) {
				newStartIndex = flooredIndex + 1;
			} else if (newStartIndex !== flooredIndex && newStartIndex < flooredIndex + 0.5) {
				newStartIndex = flooredIndex;
			}

			const newEndIndex =
				direction === 'top'
					? invisibleTimes.indexOf(interval.endTime)
					: invisibleTimes.indexOf(interval.endTime) + d.height / rowHeight;

			const startTime = invisibleTimes[newStartIndex];
			const endTime = invisibleTimes[newEndIndex];

			if (
				startTime &&
				endTime &&
				Number.isInteger(index) &&
				hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.WRITE) === true
			) {
				dispatch({
					type: actions.UPDATE_SINGLE_SLOT_INTERVAL,
					payload: {
						slotId,
						intervalData: {
							index,
							startTime,
							endTime,
							state: interval.state,
						},
					},
				});
			}
		},
		[interval, index, occupiedBlocks]
	);

	const onStateSelectionChanged = useCallback(
		(value) => {
			if (value === securityLevels.OFFICE_MODE && enableOfficeModeWarning) {
				Modal.confirm({
					title: translate.byKey('warning'),
					content: translate.byKey('office_mode_warning_message'),
					okText: translate.byKey('do_not_show_this_message_again'),
					onOk: () => {
						setEnableOfficeModeWarning(false);
						localStorage.setItem('disableOfficeModeWarning', 'true');
					},
					cancelText: translate.byKey('close'),
				});
			}

			if (hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.WRITE) === true) {
				dispatch({
					type: actions.UPDATE_SINGLE_SLOT_INTERVAL,
					payload: {
						slotId,
						intervalData: {
							index,
							startTime: interval.startTime,
							endTime: interval.endTime,
							state: value,
						},
					},
				});
			}
		},
		[interval, index, occupiedBlocks]
	);

	const onDrag = useCallback(
		(e, d) => {
			const { y: oldY, deltaY } = d;

			const verticalRowSpan = Math.floor(d.node.clientHeight / rowHeight);

			const newStartIndex = Math.floor((oldY - deltaY) / rowHeight);
			const newEndIndex = newStartIndex + verticalRowSpan + 1;

			if (isInOccupiedSpace(newStartIndex, newEndIndex, occupiedBlocks, index + 1)) {
				setBorder(`2px solid ${theme.colors.red[300]}`);
			} else {
				setBorder(`1px solid ${theme.colors.gray[300]}`);
			}
		},
		[interval, index, occupiedBlocks, border, setBorder]
	);

	let tempColour = theme.colors.blue[300];
	switch (interval.state) {
		case securityLevels.LOCKED:
			tempColour = theme.colors.red[500];
			break;
		case securityLevels.UNLOCKED:
			tempColour = theme.colors.green[300];
			break;
		case securityLevels.PAC:
			tempColour = theme.colors.teal[300];
			break;
		case securityLevels.OPEN_ON_FIRST_CARD:
			tempColour = theme.colors.purple[300];
			break;
		case securityLevels.OFFICE_MODE:
			tempColour = theme.colors.orange[300];
			break;
		default:
			break;
	}

	const times = `(${interval.startTime} - ${interval.endTime})`;

	if (!isStable) {
		return null;
	}

	return (
		<Rnd
			data-test="time-selector-door-mode-selection-box"
			style={{
				background: tempColour,
				border,
				opacity: 0.7,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 10,
			}}
			enableResizing={{
				top: true,
				right: false,
				bottom: true,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			bounds={`parent`}
			resizeGrid={[width, rowHeight]}
			dragGrid={[width, rowHeight]}
			minHeight={rowHeight}
			minWidth={width}
			size={{
				width,
				height: (invisibleTimes.indexOf(interval.endTime) - invisibleTimes.indexOf(interval.startTime)) * rowHeight,
			}}
			position={{
				x: 0,
				y: invisibleTimes.indexOf(interval.startTime) * rowHeight,
			}}
			onDrag={onDrag}
			onDragStop={onIntervalDragged}
			onResizeStop={onIntervalResized}
		>
			{hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.DELETE) === true && (
				<CloseOutlined
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						dispatch({
							type: actions.DELETE_SINGLE_SLOT_INTERVAL,
							payload: {
								index,
								slotId,
							},
						});
					}}
					css={css`
						position: absolute;
						color: ${theme.colors.white};
						top: 10px;
						right: 10px;
						z-index: 10;
						&:hover {
							width: 25px;
							height: 25px;
							top: 5px;
							right: 5px;
							border-radius: 25px;
							background-color: ${theme.colors.red[400]};
							box-shadow: ${theme.shadows.shadow_3};
							display: flex;
							align-items: center;
							justify-content: center;
						}
					`}
				/>
			)}

			<Tooltip key={index} placement="topLeft" title={getLabel(interval.state, translate, times)} mouseEnterDelay="1">
				<Select
					data-test="time-selector-door-mode-dropdown"
					selectionBox
					value={interval.state}
					onChange={onStateSelectionChanged}
					bordered={false}
					size={'small'}
				>
					<Select.Option value={securityLevels.LOCKED}>
						{interval.state === securityLevels.LOCKED
							? getLabel(securityLevels.LOCKED, translate, times)
							: getLabel(securityLevels.LOCKED, translate)}
					</Select.Option>
					<Select.Option value={securityLevels.UNLOCKED}>
						{interval.state === securityLevels.UNLOCKED
							? getLabel(securityLevels.UNLOCKED, translate, times)
							: getLabel(securityLevels.UNLOCKED, translate)}
					</Select.Option>
					<Select.Option value={securityLevels.OPEN_ON_FIRST_CARD}>
						{interval.state === securityLevels.OPEN_ON_FIRST_CARD
							? getLabel(securityLevels.OPEN_ON_FIRST_CARD, translate, times)
							: getLabel(securityLevels.OPEN_ON_FIRST_CARD, translate)}
					</Select.Option>
					<Select.Option value={securityLevels.OFFICE_MODE}>
						{interval.state === securityLevels.OFFICE_MODE
							? getLabel(securityLevels.OFFICE_MODE, translate, times)
							: getLabel(securityLevels.OFFICE_MODE, translate)}
					</Select.Option>
					<Select.Option value={securityLevels.CARD_AND_PIN}>
						{interval.state === securityLevels.CARD_AND_PIN
							? getLabel(securityLevels.CARD_AND_PIN, translate, times)
							: getLabel(securityLevels.CARD_AND_PIN, translate)}
					</Select.Option>
					<Select.Option value={securityLevels.PAC}>
						{interval.state === securityLevels.PAC
							? getLabel(securityLevels.PAC, translate, times)
							: getLabel(securityLevels.PAC, translate)}
					</Select.Option>
				</Select>
			</Tooltip>
		</Rnd>
	);
};

export default SelectionBox;
