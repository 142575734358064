import { ownerPermissions } from '~/screens/_shared/userRoleConstants/owner';
import { operatorPermissions } from '~/screens/_shared/userRoleConstants/operator';
import { guardPermissions } from '~/screens/_shared/userRoleConstants/guard';
import { installerPermissions } from '~/screens/_shared/userRoleConstants/installer';
import { actions } from '~/screens/_shared/userRoleConstants/constants';
import moment from 'moment';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { getUserAuthCacheData } from '~/components/features/auth/hooks/useUserAuthData';
import { getCurrentSystemSiteCacheData } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export * from '~/screens/_shared/userRoleConstants/constants';

export const userRoles = {
	SYSTEM_OWNER: 'RL_OCA_SYSOWNER',
	SYSTEM_INSTALLER: 'RL_OCA_SYSINSTALLER',
	RL_OCA_SYSOPERATOR: 'RL_OCA_SYSOPERATOR',
	RL_OCA_GUARD: 'RL_OCA_GUARD',
};

const nonSystemOwnerUserRoles = {
	SYSTEM_INSTALLER: 'RL_OCA_SYSINSTALLER',
	RL_OCA_SYSOPERATOR: 'RL_OCA_SYSOPERATOR',
	RL_OCA_GUARD: 'RL_OCA_GUARD',
};

const possibleNameSplitters = ['.', '-', '_'];

export function getAdministratorNames(administrator) {
	let results = [];

	if (administrator?.firstName || administrator?.lastName) {
		results.push({
			firstName: administrator.firstName || '',
			lastName: administrator.lastName || '',
		});
	}

	let email = administrator?.email || administrator?.username;

	if (email && email.includes('@')) {
		const domainUsername = email.split('@').shift() || '';

		let emailUserNameAdded = false;
		for (const splitter of possibleNameSplitters) {
			if (domainUsername.includes(splitter)) {
				const possibleNames = domainUsername.split(splitter);
				if (possibleNames.length === 2) {
					results.push({
						firstName: possibleNames[0],
						lastName: possibleNames[1],
					});
					emailUserNameAdded = true;
					break;
				}
			}
		}

		if (!emailUserNameAdded) {
			results.push({
				firstName: domainUsername,
				lastName: '',
			});
		}
	}

	return results;
}

export function getNormalisedAdministratorNames(administrator) {
	return getAdministratorNames(administrator).map((person) => ({
		...person,
		fullName: `${person.firstName} ${person.lastName}`.trim(),
	}));
}

export async function bindAdministratorsToCredentialHolders(credentialHolders, administrators, getUser) {
	administrators = administrators.map((admin) => ({ ...admin, fullNames: getNormalisedAdministratorNames(admin) }));

	let pendingUsers = [];
	for (const holder of credentialHolders.filter((holder) => !!holder.user)) {
		if (holder.user?.userId) {
			holder.user.firstName = holder.user.firstName || '';
			holder.user.lastName = holder.user.lastName || '';
			holder.description = holder.description || '';

			let possibleEmails = [holder.description, holder.description.split(' - ').pop()].filter((text) => !!text);

			try {
				const data = JSON.parse(holder.description);
				if (data?.email) {
					possibleEmails.push(data.email);
				}
			} catch {
				// Does nothing
			}

			possibleEmails = possibleEmails.concat(possibleEmails.map((text) => text.toLocaleLowerCase()));

			const exactAdmin = administrators.find((admin) => possibleEmails.includes(admin.username || admin.email));

			if (exactAdmin) {
				holder.administrator = exactAdmin;
				continue;
			}

			let possibleAdmins = administrators.filter((admin) =>
				admin.fullNames.some((name) => {
					if (holder.name.toLocaleLowerCase() === name.fullName.toLocaleLowerCase()) {
						return true;
					}

					if (
						holder.user.firstName.toLocaleLowerCase() === name.firstName.toLocaleLowerCase() &&
						holder.user.lastName.toLocaleLowerCase() === name.lastName.toLocaleLowerCase()
					) {
						return true;
					}

					return (
						name.firstName.toLocaleLowerCase().startsWith(holder.user.firstName) &&
						name.lastName.toLocaleLowerCase().startsWith(holder.user.lastName)
					);
				})
			);

			if (possibleAdmins.length > 0) {
				pendingUsers.push(
					getUser(
						{ userId: holder.user.userId },
						{
							expiry: moment().add(5, 'minutes'),
							shared: true,
						}
					)
				);
			}
		}
	}

	const users = await Promise.allSettled(pendingUsers);

	for (const { status, value } of users) {
		if (status === 'fulfilled') {
			const finalAdmin = administrators.find(
				(admin) => admin.username === value?.email || admin.email === value?.email
			);

			if (finalAdmin) {
				const credentialHolder = credentialHolders
					.filter((holder) => !!holder.user)
					.find((holder) => holder.user.userId === value.userId);
				if (credentialHolder) {
					credentialHolder.administrator = finalAdmin;
				}
			}
		}
	}

	return credentialHolders;
}

export function filterAndMergeAdmins(credentialHolders, administrators) {
	credentialHolders = credentialHolders.filter((holder) => !!holder.administrator);

	const newHolders = administrators
		.filter(
			(admin) => !credentialHolders.some((holder) => holder.administrator.administratorId === admin.administratorId)
		)
		.map((admin) => {
			const name = getNormalisedAdministratorNames(admin).shift() || {};
			name.name = name.fullName || admin.username || admin.email;
			delete name.fullName;

			return {
				...admin,
				...name,
				administrator: admin,
				isMissingCredentialHolder: true,
				isMissingUser: true,
			};
		});
	credentialHolders = credentialHolders.concat(newHolders);
	return credentialHolders.sort(createSorter('name'));
}

export function getCurrentAdministrator(system = null) {
	const user = getUserAuthCacheData();
	const { system: currentSystemCache } = getCurrentSystemSiteCacheData();
	system = system || currentSystemCache;

	if (!Array.isArray(system.roles) && !Array.isArray(system.administrators)) {
		return null;
	}

	return (
		system.administrators.find((admin) => admin.username === user.username) ||
		system.administrators.find(
			(admin) => (admin.username || '').toLocaleLowerCase() === (user.username || '').toLocaleLowerCase()
		)
	);
}

export function currentAdminIsSystemOwner() {
	const currentAdmin = getCurrentAdministrator();

	if (currentAdmin && Array.isArray(currentAdmin.roles)) {
		return currentAdmin.roles.some(isSystemOwner);
	}

	return false;
}

export function filterSupportedRoles(roles, isPrimarySystemOwner) {
	roles = roles || [];

	roles = roles.filter(
		(role) =>
			role.type in userRoles ||
			Object.values(userRoles).some((userRole) => userRole === role.type || userRole === role.name)
	);

	if (!roles.some(isSystemGuard)) {
		roles.push({
			name: userRoles.RL_OCA_GUARD,
			type: 'CUSTOM',
			roleId: userRoles.RL_OCA_GUARD,
		});
	}

	if (!roles.some(isSystemOperator)) {
		roles.push({
			name: userRoles.RL_OCA_SYSOPERATOR,
			type: 'CUSTOM',
			roleId: userRoles.RL_OCA_SYSOPERATOR,
		});
	}

	// If this admin is not the System Owner then remove the System Owner role from roles
	if (!isPrimarySystemOwner) {
		roles = roles.filter(
			(role) =>
				role.type in nonSystemOwnerUserRoles ||
				Object.values(nonSystemOwnerUserRoles).some((userRole) => userRole === role.type || userRole === role.name)
		);
	}

	return roles;
}

export function isSystemOwner(role) {
	return (
		role?.type === userRoles.SYSTEM_OWNER ||
		role?.type === 'SYSTEM_OWNER' ||
		role?.name === userRoles.SYSTEM_OWNER ||
		role?.name === 'SYSTEM_OWNER'
	);
}

export function isSystemInstaller(role) {
	return (
		role?.type === userRoles.SYSTEM_INSTALLER ||
		role?.type === 'SYSTEM_INSTALLER' ||
		role?.name === userRoles.SYSTEM_INSTALLER ||
		role?.name === 'SYSTEM_INSTALLER'
	);
}

export function isSystemGuard(role) {
	return role?.name === userRoles.RL_OCA_GUARD;
}

export function isSystemOperator(role) {
	return role?.name === userRoles.RL_OCA_SYSOPERATOR;
}

export function isSupportedRole(role) {
	return isSystemOwner(role) || isSystemInstaller(role) || isSystemGuard(role) || isSystemOperator(role);
}

export function isEndCustomerContact(role) {
	return role?.type === 'CUSTOMER_CONTACT' || role?.name === 'CUSTOMER_CONTACT';
}

export function getHighestPrivilegedRole(system = null, admin = null) {
	admin = admin || getCurrentAdministrator(system);
	if (admin && Array.isArray(admin.roles) && admin.roles.length > 0) {
		const roleTypes = admin.roles.map((role) => {
			if (role.type in userRoles) {
				return userRoles[role.type];
			}

			return [Object.values(userRoles).find((value) => value === role.name || value === role.type), role];
		});

		const rolesInOrder = [
			userRoles.SYSTEM_OWNER,
			userRoles.SYSTEM_INSTALLER,
			userRoles.RL_OCA_SYSOPERATOR,
			userRoles.RL_OCA_GUARD,
		];

		for (const systemRole of rolesInOrder) {
			let finalRole = roleTypes.find(([type]) => type === systemRole);

			if (finalRole) {
				return finalRole.pop();
			}
		}

		if (admin.roles.some(isSystemOwner)) {
			return admin.roles.find(isSystemOwner);
		} else if (admin.roles.some(isSystemInstaller)) {
			return admin.roles.find(isSystemInstaller);
		}

		return admin.roles[0];
	}

	return null;
}

export function getPermissionsForRole(role) {
	let permissionsForRole = [];

	if (role === userRoles.SYSTEM_OWNER) {
		permissionsForRole = ownerPermissions;
	} else if (role === userRoles.RL_OCA_SYSOPERATOR) {
		permissionsForRole = operatorPermissions;
	} else if (role === userRoles.RL_OCA_GUARD) {
		permissionsForRole = guardPermissions;
	} else if (role === userRoles.SYSTEM_INSTALLER) {
		permissionsForRole = installerPermissions;
	}

	return permissionsForRole;
}

function checkPermissions(user, screen, action) {
	const admin = getCurrentAdministrator();
	if (admin && Array.isArray(admin.roles) && admin.roles.length > 0) {
		const roles = admin.roles.map((role) => {
			if (role.type in userRoles) {
				return userRoles[role.type];
			}

			return Object.values(userRoles).find((value) => value === role.name || value === role.type);
		});

		return roles.some((role) => checkRolePermissions(role, screen, action));
	}

	return false;
}

function checkRolePermissions(role, screen, action) {
	let userHasPermission = false;

	if (!role) {
		return false;
	}

	let permissionsForRole = getPermissionsForRole(role);

	if (permissionsForRole.length > 0) {
		let filteredList = permissionsForRole.filter((item) => {
			let isSameScreen = !!item.screen && item.screen === screen;
			let isSameAction = !!item.actionsSupported && item.actionsSupported.includes(action);
			return isSameScreen === true && isSameAction === true;
		});

		if (!!filteredList && filteredList.length > 0) {
			userHasPermission = true;
		}
	}

	return userHasPermission;
}

export function hasActionPermissions(user, screen, action) {
	if (!user || !screen) {
		return true; // If a screen does not check permissions.
	}

	return checkPermissions(user, screen, action);
}

export function hasScreenPermissions(user, screen) {
	if (!user || !screen) {
		return true; // If a screen does not check permissions.
	}

	return checkPermissions(user, screen, actions.READ);
}

export function currentAdminHasRequiredRole(currentAdmin, requiredRoles = []) {
	if (Array.isArray(requiredRoles) && requiredRoles.length === 0) {
		return false;
	}

	const roleMap = [
		{ role: 'SYSTEM_OWNER', roleCheck: (role) => isSystemOwner(role) },
		{ role: 'SYSTEM_OPERATOR', roleCheck: (role) => isSystemOperator(role) },
		{ role: 'SYSTEM_INSTALLER', roleCheck: (role) => isSystemInstaller(role) },
		{ role: 'SYSTEM_GUARD', roleCheck: (role) => isSystemGuard(role) },
	];

	if (currentAdmin && Array.isArray(currentAdmin.roles)) {
		let requiredRoleChecks = [];

		currentAdmin.roles.forEach((role) => {
			requiredRoleChecks = [...requiredRoleChecks, ...roleMap.filter((r) => r.roleCheck(role)).map((r) => r.role)];
		});

		const roleIntersection = requiredRoleChecks.filter((role) => requiredRoles.includes(role));

		return roleIntersection.length > 0;
	}

	return false;
}

export function isGivenUserAnAdministrator(currentUser, systemAdministrators) {
	if (!currentUser.user) {
		return false;
	}

	if (!currentUser.user.email && currentUser.user.email === '') {
		return false; //Administrators are required to have the email address field.
	}

	const currentUserSystemAdmin = systemAdministrators.find(
		(adminUser) => adminUser.username === currentUser.user.email
	);
	return currentUserSystemAdmin !== undefined;
}
