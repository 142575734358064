import { useState } from 'react';
import mappers from '~/screens/_shared/mappers';
import { useDeleteApi, useGetApi, useListApi, usePostApi, usePutApi } from '~/screens/_shared/useApi';
import { useCreateReader } from './useCreateReader';
import { useLocale } from '~/screens/_shared/AppLocale';
import { cacheKeys } from '~/screens/Doors/hooks/doorHelper';
import { doorTypeLogicalDeviceDefaults, doorTypeReaderDefaults, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import { createOrGetPort } from '~/screens/Controllers/hooks';

/**
 * Hook for updating a reader.
 *
 * @param {*} onPeripheralUpdated
 * @param {*} onError
 */
export function useUpdateReader(onPeripheralUpdated = () => {}, onError = () => {}) {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [createPeripheral] = useCreateReader();
	const [updatePeripheralDevice] = usePutApi(mappers.peripheralDevice);
	const [deletePeripheralDevice] = useDeleteApi(mappers.peripheralDevice);
	const [getPeripheralDevice] = useGetApi(mappers.peripheralDevice);
	const [createPort] = usePostApi(mappers.port);
	const [getPort] = useGetApi(mappers.port);
	const [getAllPorts] = useListApi(mappers.port);

	const { translate } = useLocale();

	/**
	 * Update a reader.
	 */
	async function updateReader(formData, doorType) {
		setIsLoading(true);
		try {
			if (!formData.formattedValues.peripheralDeviceId) {
				await createPeripheral(formData, doorType);
			} else {
				const { formattedValues } = formData;
				const { fixedAddress, peripheralDeviceId } = formattedValues;
				// First load the peripheralDevice. Check if we're replacing.
				setCurrentStep(translate.byKey('loading_reader'));
				const reader = await getPeripheralDevice({ peripheralDeviceId });

				const { configuration } = reader;
				const { additionalSettings } = configuration;

				// Reader was replaced
				if (
					isOnline(doorType) &&
					!!additionalSettings &&
					!!additionalSettings.fixedAddress &&
					additionalSettings.fixedAddress !== fixedAddress
				) {
					setCurrentStep(translate.byKey('deleting_old_reader'));
					await deletePeripheralDevice(
						{ peripheralDeviceId },
						{
							removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
						}
					);
					if (fixedAddress) {
						const { portal } = formData;
						const { isEntry } = formattedValues;
						setCurrentStep(translate.byKey('creating_new_reader'));
						await createPeripheral({ portal, isEntry, formattedValues }, doorType);
					}
				}
				// Update reader settings
				else {
					setCurrentStep(translate.byKey('updating_reader'));

					if (
						isOnline(doorType) &&
						formattedValues.fixedAddress &&
						formattedValues.controllerId &&
						!formattedValues.portId
					) {
						const currentController = {
							name: formData.formattedValues.controllerName,
							controllerId: formData.formattedValues.controllerId,
						};
						const port = await createOrGetPort({ currentController, createPort, getAllPorts, getPort });

						formattedValues.controllerId = port.controllerId;
						formattedValues.portId = port.portId;
					}

					formattedValues.deviceInfo = formattedValues.deviceInfo || {};
					formattedValues.deviceInfo.serialNumber = formattedValues.deviceInfo.serialNumber || '';
					const expected = Object.assign(
						{ serialNumber: formattedValues.deviceInfo.serialNumber },
						doorTypeReaderDefaults[doorType]
					);

					if (JSON.stringify(formattedValues.deviceInfo || 'null') !== JSON.stringify(expected)) {
						formattedValues.deviceInfo = expected;
						formattedValues.logicalDeviceFunction = doorTypeLogicalDeviceDefaults[doorType];
					}

					formattedValues.logicalDevices = formattedValues.logicalDevices || [];
					if (formattedValues.logicalDevices.length > 0) {
						formattedValues.logicalDevices[0].portalSide = formData.isEntry ? 'OUTSIDE' : 'INSIDE';
					}

					formattedValues.configuration = reader?.configuration || {};

					formattedValues.version = reader?.version ?? formattedValues.version;

					await updatePeripheralDevice(formattedValues, {
						removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
					});
				}
			}
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	}

	return [updateReader, isLoading, currentStep];
}
