import React from 'react';
import { Button, Divider, Form, Modal, Select, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';

import {
	accessScheduleField,
	doorGroupField,
	doorGroupsField,
} from '~/components/features/access-profiles/modals/form/fields';
import { useLocale } from '~/screens/_shared/AppLocale';
import { ButtonContentShow } from '~/components/shared/ButtonContentShow/ButtonContentShow';
import { getDuplicatedItemsIndexesInArray } from '~/utils/array';
import { WeeklyCalendar } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar';
import { getDoorGroupsKey, useDoorGroups } from '~/hooks/data/door-groups/useDoorGroups';
import { getAccessSchedulesKey, useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const DOOR_GROUPS_LIMIT = 5; // TODO MM soft limit

export const AddDoorGroups = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { data: doorGroups } = useDoorGroups(getDoorGroupsKey({ customerId, systemId }));
	const { data: accessSchedules } = useAccessSchedules(getAccessSchedulesKey({ customerId, systemId }));
	const form = Form.useFormInstance();

	const doorGroupsDuplicationValidator = ({ field }) => {
		const index = parseInt(field.split('.')[1]);
		const fieldName = field.split('.')[2];
		const defaultAccessSchedule = accessSchedules?.accessSchedules?.find(({ name }) => name === '24/7'); // TODO MM maybe it will be a fixed id, so lets wait on BE
		const doorGroups = form
			.getFieldValue(doorGroupsField)
			.filter(
				({ [doorGroupField]: doorGroup, [accessScheduleField]: accessSchedule } = {}) => doorGroup || accessSchedule
			)
			.map((doorGroup) => ({
				...doorGroup,
				accessSchedule: doorGroup.accessSchedule || defaultAccessSchedule?.accessScheduleId,
			}));

		return getDuplicatedItemsIndexesInArray(doorGroups).includes(index) && fieldName === doorGroupField
			? Promise.reject(translate.byKey('duplicated_door_group'))
			: Promise.resolve();
	};

	const onDoorsButtonClick = (doorGroupId) => {
		const { doors } = doorGroups.find(({ id }) => id === doorGroupId);

		Modal.info({
			icon: null,
			title: translate.byKey('doors'),
			content: (
				<>
					{doors.map(({ name, type }) => (
						<div key={name}>
							{name} ({translate.byKey(type)})
						</div>
					))}
				</>
			),
		});
	};

	const onAccessScheduleButtonClick = (accessScheduleId) => {
		Modal.info({
			icon: null,
			title: translate.byKey('periods'),
			content: <WeeklyCalendar periods={[]} />,
		});
	};

	return (
		<Form.List name={doorGroupsField}>
			{(fields, { add, remove }) => (
				<>
					{fields.map(({ key, name, ...rest }) => {
						return (
							<div css={fieldStyles} key={rest?.fieldKey}>
								<Space align="center" size={24}>
									<Form.Item
										{...rest}
										label={translate.byKey('door_group')}
										name={[name, doorGroupField]}
										dependencies={fields.flatMap((field) => [
											[doorGroupsField, field.name, doorGroupField],
											[doorGroupsField, field.name, accessScheduleField],
										])}
										rules={[
											{
												required: true,
												message: translate.byKeyFormatted('field_is_required_message', {
													fieldName: translate.byKey('door_group'),
												}),
											},
											{
												validator: doorGroupsDuplicationValidator,
											},
										]}
									>
										<Select
											css={doorGroupSelectStyles}
											placeholder={translate.byKey('select_door_group')}
											options={doorGroups?.map(({ id, name }) => ({ value: id, label: name }))}
										/>
									</Form.Item>

									<Form.Item dependencies={[[doorGroupsField, name, doorGroupField]]} noStyle>
										{({ getFieldValue }) => {
											const selectedDoorGroupId = getFieldValue([doorGroupsField, name, doorGroupField]);

											return Boolean(selectedDoorGroupId) ? (
												<Tooltip title={translate.byKey('show_doors')}>
													<InfoCircleOutlined
														css={iconStyles}
														onClick={() => {
															onDoorsButtonClick(selectedDoorGroupId);
														}}
													/>
												</Tooltip>
											) : null;
										}}
									</Form.Item>

									<Form.Item dependencies={[[doorGroupsField, name, doorGroupField]]} noStyle>
										{({ getFieldValue }) => {
											const isDoorGroupSelected = Boolean(getFieldValue([doorGroupsField, name, doorGroupField]));
											const isAccessScheduleSelected = Boolean(
												getFieldValue([doorGroupsField, name, accessScheduleField])
											);

											return isDoorGroupSelected ? (
												<ButtonContentShow
													css={addMoreStyles}
													showInitial={isAccessScheduleSelected}
													renderContentOnClick={() => (
														<Form.Item
															{...rest}
															label={translate.byKey('access_schedule')}
															name={[name, accessScheduleField]}
															dependencies={fields.flatMap((field) => [
																[doorGroupsField, field.name, doorGroupField],
																[doorGroupsField, field.name, accessScheduleField],
															])}
															rules={[
																{
																	required: true,
																	message: translate.byKeyFormatted('field_is_required_message', {
																		fieldName: translate.byKey('access_schedule'),
																	}),
																},
																{
																	validator: doorGroupsDuplicationValidator,
																},
															]}
														>
															<Select
																css={accessScheduleSelectStyles}
																placeholder={translate.byKey('select_access_schedule')}
																options={accessSchedules?.accessSchedules?.map(({ accessScheduleId, name }) => ({
																	value: accessScheduleId,
																	label: name,
																}))}
															/>
														</Form.Item>
													)}
												>
													{translate.byKey('add_access_schedule')}
												</ButtonContentShow>
											) : null;
										}}
									</Form.Item>

									<Form.Item
										dependencies={[
											[doorGroupsField, name, doorGroupField],
											[doorGroupsField, name, accessScheduleField],
										]}
										noStyle
									>
										{({ getFieldValue }) => {
											const selectedDoorGroupId = getFieldValue([doorGroupsField, name, doorGroupField]);
											const selectedAccessScheduleId = getFieldValue([doorGroupsField, name, accessScheduleField]);

											if (Boolean(selectedDoorGroupId)) {
												return Boolean(selectedAccessScheduleId) ? (
													<Tooltip title={translate.byKey('show_periods')}>
														<InfoCircleOutlined
															css={iconStyles}
															onClick={() => {
																onAccessScheduleButtonClick(selectedAccessScheduleId);
															}}
														/>
													</Tooltip>
												) : (
													<Tooltip title={translate.byKey('default_access_schedule_message')}>
														<InfoCircleOutlined css={iconStyles} />
													</Tooltip>
												);
											}

											return null;
										}}
									</Form.Item>

									<Tooltip title={translate.byKey('remove')}>
										<MinusCircleOutlined
											css={iconStyles}
											onClick={() => {
												remove(name);
												setTimeout(() => {
													form.validateFields();
												}, 100);
											}}
										/>
									</Tooltip>
								</Space>
							</div>
						);
					})}

					{fields.length > 0 && fields.length < DOOR_GROUPS_LIMIT ? <Divider css={dividerStyles} /> : null}

					{fields.length < DOOR_GROUPS_LIMIT ? (
						<div>
							<Button size="small" type="text" onClick={() => add()} icon={<PlusOutlined />}>
								{translate.byKey('add_access_to_door_group')}
							</Button>
						</div>
					) : null}
				</>
			)}
		</Form.List>
	);
};

const fieldStyles = css({ width: '100%' });

const doorGroupSelectStyles = css({ width: '200px !important' });

const iconStyles = css({
	fontSize: '20px',
	display: 'block',
	position: 'relative',
	top: '3px',
});

const addMoreStyles = css({ top: '3px', width: '200px' });

const accessScheduleSelectStyles = css({ width: '200px !important' });

const dividerStyles = css({ margin: '0 0 16px 0' });
