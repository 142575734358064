import React, { useState } from 'react';
import { Button, Form, Input, message, Popconfirm, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { CopyOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

import { useLocale } from '~/screens/_shared/AppLocale';
import {
	// collapsePanelStyles,
	// collapseStyles,
	generateOtpButtonRowStyles,
	validityStyles,
} from '~/components/features/system-settings/Licenses/Licenses.styles';
import { getGenerateOtpKey, useGenerateOtp } from '~/hooks/data/onboarding/useGenerateOtp';
import { useCountdown } from '~/hooks/utils/useCountdown';
import { getOnboardedClientsKey, useOnboardedClients } from '~/hooks/data/onboarding/useOnboardedClients';
import { getClientDeleteKey, useClientDelete } from '~/hooks/data/onboarding/useClientDelete';
import { filterTextByPartialText } from '~/utils/filter';
import { currentAdminHasRequiredRole, getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';
import Title from 'antd/lib/typography/Title';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const fifteenMinutesInMs = 15 * 60 * 1000;
const oneMinuteInMs = 1000 * 60;

export const Licenses = () => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();
	const isSystemOwner = currentAdminHasRequiredRole(getCurrentAdministrator(system), 'SYSTEM_OWNER');
	const { customerId, systemId } = system;
	const {
		data: { otp } = { otp: '' },
		isMutating: isGeneratingOtp,
		trigger: generateOtp,
	} = useGenerateOtp(isSystemOwner ? getGenerateOtpKey({ customerId, systemId }) : null);
	const {
		data: onboardedClients,
		isLoading: isLoadingClients,
		isValidating: isValidatingClients,
		mutate: reloadClients,
	} = useOnboardedClients(getOnboardedClientsKey({ customerId, systemId }), { refreshInterval: oneMinuteInMs });
	const [selectedClientId, setSelectedClientId] = useState(undefined);
	const { trigger: deleteClient, isMutating: isDeletingClient } = useClientDelete(
		isSystemOwner ? getClientDeleteKey({ customerId, systemId, clientId: selectedClientId }) : null
	);
	const countdownTimeInMs = useCountdown(fifteenMinutesInMs, otp);
	const momentTime = moment(countdownTimeInMs || 0);
	const otpExpired = momentTime.minutes() <= 0 && momentTime.seconds() <= 0;

	const onGenerateNewOtpClick = async () => {
		generateOtp({
			customerId,
			systemId,
		});
	};

	const onCopyClick = () => {
		navigator.clipboard.writeText(otp);
		message.success(translate.byKey('copied_successfully'));
	};

	const onSelectChange = (clientId) => {
		setSelectedClientId(clientId);
	};

	const onClientDelete = async () => {
		try {
			await deleteClient({
				customerId,
				systemId,
				clientId: selectedClientId,
			});

			await reloadClients();

			setSelectedClientId(undefined);

			message.success(translate.byKey('client_deleted_successfully'));
		} catch (error) {
			console.log(error);
			message.error(error?.problem);
		}
	};

	return (
		// <Collapse bordered={false} css={collapseStyles} expandIconPosition="right" defaultActiveKey="apiIntegration">
		// 	<Collapse.Panel css={collapsePanelStyles} key="apiIntegration" header={translate.byKey('api_integration')}>
		<>
			{/* this Title will be removed once we start using Collapse component */}
			<Title style={{ marginBottom: '16px', fontSize: '16px' }} level={4} strong>
				{translate.byKey('api_integration')}
			</Title>
			<Form layout="vertical" component="div" className="licenses">
				{isSystemOwner ? (
					<>
						<Form.Item>
							<div css={generateOtpButtonRowStyles}>
								<Button
									type="primary"
									disabled={isGeneratingOtp}
									loading={isGeneratingOtp}
									onClick={onGenerateNewOtpClick}
								>
									<PlusCircleOutlined /> {translate.byKey('generate_new')}
								</Button>

								{otp ? (
									<div css={validityStyles}>
										<div>{translate.byKey('validity')}</div>
										<div>{otpExpired ? translate.byKey('expired') : momentTime.format('mm:ss')}</div>
									</div>
								) : null}
							</div>
						</Form.Item>

						<Form.Item label={translate.byKey('new_otp_api_key')}>
							<Input.Group compact style={{ flex: 1 }}>
								<Input value={otpExpired ? '' : otp} readOnly style={{ width: 'calc(100% - 32px)' }} />
								<Tooltip title={otp ? translate.byKey('copy') : ''}>
									<Button disabled={!otp || otpExpired} onClick={onCopyClick} icon={<CopyOutlined />} />
								</Tooltip>
							</Input.Group>
						</Form.Item>
					</>
				) : null}

				<Text strong>{translate.byKey('api_keys')}</Text>

				{isLoadingClients ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Spin indicator={<LoadingOutlined style={{ fontSize: '36px' }} spin />} />
					</div>
				) : (
					<Form.Item label={translate.byKey('api_name')}>
						<Select
							value={selectedClientId}
							disabled={!onboardedClients?.clients?.length}
							loading={isValidatingClients}
							onChange={onSelectChange}
							placeholder={translate.byKey(onboardedClients?.clients?.length ? 'select_api_name' : 'no_data')}
							showSearch
							filterOption={(input, option) => filterTextByPartialText(option.label, input)}
							options={onboardedClients?.clients?.map(({ id, description }) => ({
								value: id,
								label: description,
							}))}
						/>
					</Form.Item>
				)}

				{selectedClientId ? (
					<>
						<Form.Item label={translate.byKey('client_id')}>
							<Text>{selectedClientId}</Text>
						</Form.Item>

						{isSystemOwner ? (
							<Popconfirm onConfirm={onClientDelete} title={translate.byKey('delete_api_integration_message')}>
								<Button type="primary" danger disabled={isDeletingClient} loading={isDeletingClient}>
									{translate.byKey('delete_api_integration')}
								</Button>
							</Popconfirm>
						) : null}
					</>
				) : null}
			</Form>
		</>
		// </Collapse.Panel>
		// </Collapse>
	);
};
