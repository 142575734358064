import React from 'react';

import { Page } from '~/components';
import { LivePageHeader } from '~/components/features/live/LivePageHeader/LivePageHeader';
import { LiveEventsTable } from '~/components/features/live/LiveEventsTable/LiveEventsTable';

export const LivePage = () => {
	return (
		<Page>
			<LivePageHeader />
			<LiveEventsTable />
		</Page>
	);
};
