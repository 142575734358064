const accessProfile = {
	base: {
		name: true,
		description: false,
		// TODO we are aware of start and end date time that were passed here as validity,
		// but due to the lack of understanding if those values are used somewhere else we are not removing them from data
		// e.g. src/screens/AccessGroups/hooks/useCreate.js line `const data = await createAccessProfile(` and date values
		accessPolicies: (data, { system, site }) => {
			const policies = data.policies;
			if (data.policies) {
				return policies.map((policy) => {
					const { calendarId, scheduleId } = policy;
					let assets = policy.assetItems || policy.assets || [];
					assets = assets.map((asset) => ({
						key: {
							assetId: asset.assetId,
							siteId: site,
							systemId: system,
						},
					}));

					if (calendarId && scheduleId) {
						return {
							schedule: {
								key: {
									systemId: system,
									calendarId,
									scheduleId,
								},
							},
							assets,
						};
					} else {
						return {
							assets,
						};
					}
				});
			} else {
				return data.accessPolicies || [];
			}
		},
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'access-profile'],
};

export default accessProfile;
