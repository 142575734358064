import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';

const componentStyle = StyleSheet.create({
	position: 'absolute',
	fontSize: 10,
	bottom: 16,
	left: 0,
	right: 0,
	textAlign: 'center',
	color: 'grey',
});

export const Pagination = ({ style, ...rest }) => {
	return (
		<Text
			fixed
			style={{
				...componentStyle,
				...style,
			}}
			render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
			{...rest}
		/>
	);
};
