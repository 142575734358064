import { Steps as ASteps } from 'antd';
import React from 'react';

const { Step: AStep } = ASteps;

const Steps = ({ status, percent, showInfo, ...props }) => {
	return <ASteps {...props} />;
};

const Step = ({ status, percent, showInfo, ...props }) => {
	return <AStep {...props} />;
};

export { Steps, Step };
