import { translator as translate } from '~/screens/_shared/AppLocale';

const generalHelp = () => `
<p><strong><u> ${translate.byKey('common_functions_header')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('site_selection')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'you_can_switch_between_sites_by_changing_the_drop_down_on_the_top_left_of_the_page_if_all_sites_is_selected_then_on_some_pages_it_may_show_data_from_every_site_on_the_system'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('logout')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'logout_will_exit_the_system__the_system_will_also_automatically_log_out_based_on_the_session_time_limit_'
)}&nbsp;</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'typing_in_the_search_field_will_filter_through_the_current_table_for_similar_values_from_what_was_typed'
)} </p>
<p><strong><u> ${translate.byKey('page_specific_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('general')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'up_to_32_readers_can_be_added_to_a_site_controller_which_can_be_32_doors_with_1_reader_per_door_exact_door_amounts_will_vary_based_on_reader_slots_already_used_on_the_site_controller'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('add_new')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('add_new_allows_you_to_enter_a_new_door_into_the_system_')} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('editing_a_door')} <br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('to_edit_a_door_click_on_the_doors_name_in_the_table_list')} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('editing_a_reader')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_edit_a_reader_click_on_either_the_entry_or_exit_button_on_the_table_row_relating_to_door_you_wish_to_edit'
)} </p>
`;

export default generalHelp;
