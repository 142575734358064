import { ThemeProvider } from 'emotion-theming';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';

import themes from './themes';
import GlobalStyle from '~/styles/Global';
import supportedThemes from './themes/supportedThemes';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

export const ThemeContext = React.createContext();

export function useAppTheme() {
	return useContext(ThemeContext);
}

const AppTheme = (props) => {
	const { core } = themes;
	const [localTheme, setLocalTheme] = useState({
		...core,
		colors: {
			...core.colors,
			...themes['incedo'].colors,
		},
		brand: themes['incedo'].brand,
		header: themes['incedo'].header,
		auth: themes['incedo'].auth,
		brandName: 'incedo',
	});

	const changeTheme = (name) => {
		let brandName = name;

		// check if this is a supported brand
		let isThemeSupported = supportedThemes.some((t) => t === brandName);

		if (!isThemeSupported) {
			brandName = supportedThemes[0];
		}

		// carry on with the theming
		const newTheme = {
			...core,
			colors: {
				...core.colors,
				...themes[brandName].colors,
			},
			brand: themes[brandName].brand,
			header: themes[brandName].header,
			auth: themes[brandName].auth,
			brandName,
		};

		if (newTheme && newTheme.brand && newTheme.brand.siteName) {
			window.document.title = newTheme.brand.siteName;
		}

		setLocalTheme(newTheme);
	};

	return (
		<React.Fragment>
			{isEmpty(localTheme) ? null : (
				<ThemeContext.Provider value={{ theme: localTheme, changeTheme }}>
					<ThemeProvider theme={localTheme}>
						<GlobalStyle />
						{props.children}
					</ThemeProvider>
				</ThemeContext.Provider>
			)}
		</React.Fragment>
	);
};

export default AppTheme;
