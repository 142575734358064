import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useCreateAsset = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: 'customer/:customerId/system/:systemId/site/:siteId/asset',
				method: method.post,
			},
			handleDefaultApiError
		)
	);

	const fetcher = (_, { arg: { ignoreGlobalHandlers, ...payload } }) =>
		ref.current.fetchData({
			ignoreGlobalHandlers,
			...omitBy({ ...payload }, isExcludedValue),
			removeProperties: ['ignoreGlobalHandlers'],
		});

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getCreateAssetKey = ({ customerId, systemId, siteId }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
create-asset`
		: null;
