import moment from 'moment';

export class PromiseCache {
	constructor() {
		this.cache = new Map();
		this.keyExpiry = new Map();
	}

	getOrAdd(key, createPromise, cacheOptions = null) {
		if (!cacheOptions) {
			return createPromise();
		}

		if (cacheOptions.removeExistingKey) {
			cacheOptions.removeExistingKey = Array.isArray(cacheOptions.removeExistingKey)
				? cacheOptions.removeExistingKey
				: [cacheOptions.removeExistingKey];

			for (const cacheKey of cacheOptions.removeExistingKey) {
				const keyToRemove = cacheKey === true ? key : cacheKey;

				this.cache.delete(keyToRemove);
				this.keyExpiry.delete(keyToRemove);
			}
		}

		if (cacheOptions && !cacheOptions.expiry) {
			return createPromise();
		}

		if (this.keyExpiry.has(key)) {
			const expiry = this.keyExpiry.get(key);

			if (moment().isAfter(expiry)) {
				this.keyExpiry.delete(key);
				this.cache.delete(key);
			}
		}

		if (!this.cache.has(key)) {
			const result = createPromise();
			if (cacheOptions.shared) {
				this.cache.set(key, result);
				if (cacheOptions && cacheOptions.expiry) {
					this.keyExpiry.set(key, cacheOptions.expiry);
				}
			} else {
				result.then(() => {
					this.cache.set(key, result);
					if (cacheOptions && cacheOptions.expiry) {
						this.keyExpiry.set(key, cacheOptions.expiry);
					}

					return result;
				});
			}
			return result;
		}

		return this.cache.get(key);
	}
}
