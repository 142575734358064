import React, { useEffect, useMemo } from 'react';
import { Document, StyleSheet, Text, usePDF, View } from '@react-pdf/renderer';
import { Button, Form } from 'antd';
import moment from 'moment';

import { useLocale } from '~/screens/_shared/AppLocale';
import { getDoorGroupsKey, useDoorGroups } from '~/hooks/data/door-groups/useDoorGroups';
import { getDoorsKey, useDoors } from '~/hooks/data/doors/useDoors';
import { getCredentialHoldersKey, useCredentialHolders } from '~/hooks/data/credential-holders/useCredentialHolders';
import { getAccessSchedulesKey, useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import {
	doorGroupsField,
	doorsField,
	locationField,
	nameField,
	peopleField,
} from '~/components/features/access-profiles/modals/form/fields';
import { Table } from '~/components/shared/pdf/table/Table/Table';
import { TableRowHeader } from '~/components/shared/pdf/table/TableRowHeader/TableRowHeader';
import { TableItemHeader } from '~/components/shared/pdf/table/TableItemHeader/TableItemHeader';
import { TableRow } from '~/components/shared/pdf/table/TableRow/TableRow';
import { TableItem } from '~/components/shared/pdf/table/TableItem/TableItem';
import { Page } from '~/components/shared/pdf/Page/Page';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const SummaryDownload = ({ className }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId, sites },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { data: allDoorGroups } = useDoorGroups(getDoorGroupsKey({ customerId, systemId }));
	const { data: allDoors } = useDoors(getDoorsKey({ customerId, systemId }));
	const { data: allPeople } = useCredentialHolders(getCredentialHoldersKey({ customerId, systemId, siteId }));
	const { data: accessSchedules } = useAccessSchedules(getAccessSchedulesKey({ customerId, systemId }));
	const [instance, updateInstance] = usePDF();
	const form = Form.useFormInstance();
	const {
		[nameField]: name,
		[locationField]: location,
		[doorGroupsField]: doorGroups,
		[doorsField]: doors,
		[peopleField]: people,
	} = form.getFieldsValue();

	const siteName = sites.find(({ id }) => id === location)?.name;
	const fileName = `${moment().format('L')}-${name}-${siteName}.pdf`;

	const document = useMemo(
		() => (
			<Document>
				<Page>
					<View>
						<Text style={documentStyles.text}>
							{translate.byKey('access_profile')}: {name}
						</Text>
						<Text style={documentStyles.text}>
							{translate.byKey('location')}: {siteName}
						</Text>
						<Text style={documentStyles.text}>
							{translate.byKey('date')}: {moment().format('L LT')}
						</Text>
					</View>

					<View>
						<Text style={documentStyles.sectionText}>{translate.byKey('door_groups')}</Text>

						<Table>
							<TableRowHeader>
								<TableItemHeader>{translate.byKey('door_group')}</TableItemHeader>
								<TableItemHeader>{translate.byKey('access_schedule')}</TableItemHeader>
							</TableRowHeader>

							{doorGroups?.map(({ doorGroup, accessSchedule } = {}) => (
								<TableRow key={`${doorGroup}-${accessSchedule}`}>
									<TableItem>{allDoorGroups?.find(({ id }) => id === doorGroup)?.name}</TableItem>
									<TableItem>
										{accessSchedules?.accessSchedules?.find(({ id }) => id === accessSchedule)?.name || '24/7'}
									</TableItem>
								</TableRow>
							))}
						</Table>
					</View>

					<View>
						<Text style={documentStyles.sectionText}>{translate.byKey('doors')}</Text>

						<Table>
							<TableRowHeader>
								<TableItemHeader>{translate.byKey('door')}</TableItemHeader>
								<TableItemHeader>{translate.byKey('access_schedule')}</TableItemHeader>
							</TableRowHeader>

							{doors?.map(({ door, accessSchedule } = {}) => (
								<TableRow key={`${door}-${accessSchedule}`}>
									<TableItem>{allDoors?.find(({ id }) => id === door)?.name}</TableItem>
									<TableItem>
										{accessSchedules?.accessSchedules?.find(({ id }) => id === accessSchedule)?.name || '24/7'}
									</TableItem>
								</TableRow>
							))}
						</Table>
					</View>

					<View>
						<Text style={documentStyles.sectionText}>{translate.byKey('people')}</Text>

						<Table>
							<TableRowHeader>
								<TableItemHeader>{translate.byKey('name')}</TableItemHeader>
								<TableItemHeader>{translate.byKey('email_address')}</TableItemHeader>
								<TableItemHeader>
									{translate.byKey('valid_from')} - {translate.byKey('valid_until')}
								</TableItemHeader>
							</TableRowHeader>

							{people?.map((person) => {
								const { firstName, lastName, emailAddress, validFrom, validUntil } =
									people?.find(({ id }) => id === person.id) || {};

								return (
									<TableRow key={person.id}>
										<TableItem>
											{firstName} {lastName}
										</TableItem>
										<TableItem>{emailAddress}</TableItem>
										<TableItem>
											{moment(validFrom).format('L')} -{' '}
											{validUntil ? moment(validUntil).format('L') : translate.byKey('unset')}
										</TableItem>
									</TableRow>
								);
							})}
						</Table>
					</View>
				</Page>
			</Document>
		),
		[name, location, doorGroups, doors, people, allDoorGroups, allDoors, allPeople, accessSchedules]
	);

	useEffect(() => updateInstance(document), [document]);

	return (
		<a href={instance.url} download={fileName}>
			<Button type="primary" className={className}>
				{translate.byKey('download_summary')}
			</Button>
		</a>
	);
};

const documentStyles = StyleSheet.create({
	sectionText: {
		fontSize: '11px',
		margin: '24px 0 16px 0',
	},
	text: {
		fontSize: '11px',
		marginBottom: '8px',
	},
});
