import { css } from '@emotion/core';
import React from 'react';

const Header = () => {
	return (
		<header css={headerStyles}>
			<img src="/assets/images/incedo-business-logo-black.jpg" alt="Incedo Business" width="220px" height="28px" />
			<img src="/assets/images/AssaAbloyLogo.png" alt="Assa Abloy" width="165px" height="22px" />
		</header>
	);
};

export default Header;

const headerStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingInline: '16px',
});
