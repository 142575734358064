import React from 'react';

const viewBox = '0 0 512 512';

const path = (
	<g fill="currentColor">
		<path d="M0 490.667v10.667C0 507.224 4.776 512 10.667 512h490.667c5.891 0 10.667-4.776 10.667-10.667v-10.667H0z" />
		<path d="M202.667 192H32c-5.891 0-10.667 4.776-10.667 10.667v298.667C21.333 507.224 26.109 512 32 512h170.667c5.891 0 10.667-4.776 10.667-10.667V202.667c-.001-5.891-4.776-10.667-10.667-10.667zM192 490.667H42.667V213.333H192v277.334zM480 64H309.333c-5.891 0-10.667 4.776-10.667 10.667v426.667c0 5.891 4.776 10.667 10.667 10.667H480c5.891 0 10.667-4.776 10.667-10.667V74.667C490.667 68.776 485.891 64 480 64zm-10.667 426.667H320V85.333h149.333v405.334z" />
		<path d="M405.333 0h-288c-5.891 0-10.667 4.776-10.667 10.667v160H128V21.333h266.667v21.333H416v-32C416 4.776 411.224 0 405.333 0zM64 234.667h21.333v192H64zM106.667 234.667H128v192h-21.333zM149.333 234.667h21.333v192h-21.333zM309.333 117.333h117.333v21.333H309.333zM309.333 170.667h117.333V192H309.333zM309.333 224h117.333v21.333H309.333zM309.333 277.333h117.333v21.333H309.333zM309.333 330.667h117.333V352H309.333zM309.333 384h117.333v21.333H309.333z" />
		<path d="M160 53.333h21.333v32H160zM160 117.333h21.333v32H160zM202.667 117.333H224v32h-21.333zM202.667 53.333H224v32h-21.333zM245.333 53.333h21.333v32h-21.333zM245.333 117.333h21.333v32h-21.333zM245.333 181.333h21.333v32h-21.333zM245.333 245.333h21.333v32h-21.333zM245.333 309.333h21.333v32h-21.333zM245.333 373.333h21.333v32h-21.333zM405.333 437.333H352c-5.891 0-10.667 4.776-10.667 10.667v53.333h21.333v-42.667h32v42.667H416V448c0-5.891-4.776-10.667-10.667-10.667zM160 448H74.667C68.776 448 64 452.776 64 458.667v42.667h21.333v-32h64v32h21.333v-42.667C170.667 452.776 165.891 448 160 448z" />
	</g>
);

const site = {
	path,
	viewBox,
};

export default site;
