import React from 'react';
import Text from 'antd/lib/typography/Text';

export const FilterItemCategory = ({ children }) => {
	return (
		<div
			style={{
				width: '100%',
				marginBottom: '8px',
				background: '#fafafa',
				padding: '4px 8px',
			}}
		>
			<Text strong>{children}</Text>
		</div>
	);
};
