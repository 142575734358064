import React from 'react';
import { Icon } from '~/components/general';
import { css } from '@emotion/core';

export const MenuItem = ({ label, icon }) => (
	<div css={menuItemStyle}>
		{icon ? (
			<div css={iconStyle(Boolean(label?.length))}>
				<Icon name={icon} />
			</div>
		) : null}
		<div>{label}</div>
	</div>
);

const menuItemStyle = css({
	display: 'flex',
	alignItems: 'center',
});

const iconStyle = (hasLabel) =>
	css({
		paddingRight: hasLabel ? 8 : 0,
	});
