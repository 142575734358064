export const credential = {
	paginationType: 'links',
	base: {
		name: ({ firstName, lastName, name, credentialTypeId }) => {
			if (firstName && lastName && credentialTypeId) {
				if (credentialTypeId === 'offline_pulse') {
					name = `${firstName} ${lastName} offline_pulse (Pulse)`;
				} else if (credentialTypeId === 'online_generic') {
					name = `${firstName} ${lastName} online_generic`;
				}

				return name;
			} else if (name) {
				return name;
			}

			return 'UNKNOWN CREDENTIAL';
		},
		validity: ({ startDateTime, endDateTime, validity }) => {
			if (validity) {
				return validity;
			}
			return { startDateTime, endDateTime };
		},
		credentialHolder: ({ credentialHolderId }, { system }) => {
			return {
				key: {
					systemId: system,
					credentialHolderId,
				},
			};
		},
		credentialIdentifiers: (data) => {
			if (data && !(Array.isArray(data.identifiers) || Array.isArray(data.credentialIdentifiers))) {
				let { recognitionType, identifierFormat, hexValue, exemptedFromAuthentication } = data;

				identifierFormat = identifierFormat || 'SIMPLE_NUMBER32';
				return [
					{
						recognitionType,
						identifierFormat,
						hexValue,
						exemptedFromAuthentication: exemptedFromAuthentication,
					},
				];
			} else if (data && (Array.isArray(data.credentialIdentifiers) || Array.isArray(data.identifiers))) {
				return data.credentialIdentifiers || data.identifiers;
			} else {
				return [];
			}
		},
		description: false,
	},
	update: {
		version: true,
	},
	params: {
		credentialHolderId: {
			list: false,
		},
	},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'credential'],
};
