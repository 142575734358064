const securityLevels = {
	base: {
		name: true,
		description: false,
		priority: true,

		possibleGroups: ({ groups }) => {
			let possibleGroups = [];

			groups &&
				groups.forEach((group) => {
					const { items } = group;
					let requiredMethods = [];
					items.forEach((item) => {
						const { type, order } = item;
						requiredMethods.push({
							type,
							order,
						});
					});

					possibleGroups.push({
						requiredMethods,
					});
				});

			return possibleGroups;
		},
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'security-level'],
};

export default securityLevels;
