/**
 * Controller Update Types
 *
 * These are used in RequestType in controllers configure.additionalSettings.
 *
 * The Full Sync is handled separately in configure.additionalSettings.FULL_SYNC.
 *
 * @readonly
 * @enum {*}
 * @typedef ControllerUpdateTypes
 *
 * @property {*} none
 * @property {*} simpleSync
 * @property {*} autoId
 * @property {*} clearAndAutoId
 */
export const controllerUpdateTypes = {
	none: {
		value: 'NONE',
		displayValue: 'None',
	},
	/**
	 * Simple sync: fw version check; requests transactions;
	 * heartbeat; updates time; push through all pending updates.
	 *
	 * Does some basic checks on the controller, etc.
	 */
	simpleSync: {
		value: 'SIMPLE_SYNCH',
		displayValue: 'Quick Sync',
	},
	/**
	 * AutoId: discover hardware connected to the controller. I.e. readers  /locks.
	 * Give them logical addresses in order to talk to them, differentiate them.
	 */
	autoId: {
		value: 'AUTO_ID',
		displayValue: 'Auto ID',
	},
	/**
	 * Clear existing data and perform an AutoId.
	 */
	clearAndAutoId: {
		value: 'CLEAR_AND_AUTO_ID',
		displayValue: 'Clear and Auto ID',
	},
	/**
	 * Clear controller and synchronize all data
	 * to controller again. Will stop functioning (e.g. doors)
	 * until it's done.
	 */
	fullSync: {
		value: 'FULL_SYNC',
		displayValue: 'Full Sync',
	},
};

/**
 * Controller Configure types.
 *
 * These will show the dialog with progress and
 * ticks and block user input. With retry, etc.
 */
export const controllerConfigureTypes = {
	onboarding: {
		value: 'ONBOARD',
		displayValue: 'Onboarding',
	},
	pingAndAutoId: {
		value: 'RETRY_PING_AUTOID',
		displayValue: 'Ping and AutoId',
	},
};

export const controllerRequestTypes = {
	// None
	none: {
		value: 'NONE',
		displayValue: 'None',
	},
	// Request was processed and succeeded.
	processedSuccess: {
		value: 'PROCESSED_SUCCESS',
		displayValue: 'Success',
	},
	// No request
	processedNa: {
		value: 'PROCESSED_NA',
		displayValue: 'Processed',
	},
	// Failed
};

/**
 * Controller State Types
 * @readonly
 * @enum {*}
 * @typedef ControllerStateTypes
 *
 * @property {*} pending
 * @property {*} failed
 * @property {*} updated
 * @property {*} connected
 * @property {*} reachable
 */
export const controllerStates = {
	PENDING_UPDATE: {
		value: 'PENDING_UPDATE',
		displayValue: 'Syncing',
	},
	PENDING_DELETE: {
		value: 'PENDING_DELETE',
		displayValue: 'Deleting',
	},
	UPDATE_FAILED: {
		value: 'UPDATE_FAILED',
		displayValue: 'Sync Failed',
	},
	UPDATED: {
		value: 'UPDATED',
		displayValue: 'Updated',
	},
	REACHABLE: {
		value: 'REACHABLE',
		displayValue: 'Reachable',
	},
	CONNECTED: {
		value: 'CONNECTED',
		displayValue: 'Connected',
	},
	UNKNOWN: {
		value: 'UNKNOWN',
		displayValue: 'Unknown',
	},
	DISCONNECTED: {
		value: 'DISCONNECTED',
		displayValue: 'Disconnected',
	},
};

/**
 * Controller Update Types
 * @readonly
 * @enum {*}
 * @typedef ControllerUpdateTypes
 *
 * @property {*} none
 * @property {*} simpleSync
 * @property {*} autoId
 * @property {*} clearAndAutoId
 */
export const syncTimeouts = {
	NONE: 1500,
	SIMPLE_SYNCH: 30000,
	AUTO_ID: 90000,
	CLEAR_AND_AUTO_ID: 120000,
};
