import React from 'react';
import { Image, StyleSheet, View } from '@react-pdf/renderer';

import incedoCloudImage from '~/assets/logo/incedo-cloud.png';
import assaAbloyImage from '~/assets/logo/assa-abloy-black.png';

const componentStyles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '32px',
	},
	incedoCloudImage: {
		width: '122px',
		height: '12px',
	},
	assaAbloyImage: {
		width: '124px',
		height: '17px',
	},
});

export const Header = ({ styles }) => {
	return (
		<View
			style={{
				...componentStyles.header,
				...styles?.header,
			}}
		>
			<Image
				style={{
					...componentStyles.incedoCloudImage,
					...styles?.incedoCloudImage,
				}}
				src={incedoCloudImage}
			/>
			<Image
				style={{
					...componentStyles.assaAbloyImage,
					...styles?.assaAbloyImage,
				}}
				src={assaAbloyImage}
			/>
		</View>
	);
};
