import { useState } from 'react';
import { usePutApi } from '~/screens/_shared/useApi';
import { getReadersForDoorsDefaultKey } from '~/components/features/doors/Doors.utils';
import { useReaders } from '~/hooks/data/readers/useReaders';
import { ControllerLockState } from '~/constants/ControllerEmergencyStatus';
import moment from 'moment';
import { getStrikeDurationForDevice, getDoorScheduleStatus } from '~/utils/doors/Doors.utils';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency';
import { EnrichedPortalStatus } from '~/constants/EnrichedPortalStatus';
import { showErrorToast } from '~/screens/_shared/toast';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const useDoorEmergency = () => {
	const { translate } = useLocale();

	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();

	const { getControllerEmergencyState, getControllersIdsWithEventTypes } = useSiteControllersEmergencyRecords();

	const assignedReadersKey =
		customerId && systemId && siteId ? getReadersForDoorsDefaultKey({ customerId, systemId, siteId }) : null;
	const { data: assignedReaders } = useReaders(assignedReadersKey);

	const [unlockedDoors, setUnlockedDoors] = useState([]);

	const [updatePortalStatus] = usePutApi('/customer/:customerId/system/:systemId/site/:siteId/portal/:portalId/status');

	/**
	 * portal can only be unlocked by sending the update request with mode OPEN to API
	 * the close request is not necessary because they are close automatically
	 */
	const unlock = async (portalId) => {
		const portalReader = assignedReaders.find(
			(reader) => reader.mapping?.port?.controllerId && reader.mapping?.portal?.portalId === portalId
		);

		if (!Boolean(portalReader)) {
			return;
		}

		const eventStartTime = moment().startOf('second');
		const unlockDuration = moment.duration(getStrikeDurationForDevice(portalReader), 'seconds');

		try {
			await updatePortalStatus(
				{
					ignoreGlobalHandlers: true,
					portalId,
					mode: 'OPEN',
					duration: unlockDuration.toISOString(),
				},
				{ expiry: moment().add(unlockDuration), shared: true }
			);

			setUnlockedDoors((doors) => [...doors, portalId]);

			setTimeout(
				() => setUnlockedDoors((doors) => [...doors.filter((doorId) => doorId !== portalId)]),
				[unlockDuration.valueOf()]
			);
		} catch {
			const controllersIdsWithEvents = await getControllersIdsWithEventTypes(eventStartTime, ['RTE']);
			if (controllersIdsWithEvents.includes(portalReader?.mapping?.port?.controllerId)) {
				setUnlockedDoors((doors) => [...doors, portalId]);

				setTimeout(
					() => setUnlockedDoors((doors) => [...doors.filter((doorId) => doorId !== portalId)]),
					[unlockDuration.valueOf()]
				);
			} else {
				showErrorToast(translate.byKey('could_not_lock_door'));
			}
		}
	};

	const getDoorStatus = (portal) => {
		const assignedReadersForDoors = assignedReaders
			?.map((r) => {
				if (r && r.configuration && r.configuration.additionalSettings) {
					r.fixedAddress = r.configuration.additionalSettings.fixedAddress || '';
				}
				return r;
			})
			?.filter((r) => r.mapping && r.mapping.portal?.portalId === portal.portalId)
			?.filter((r) => r.mapping?.port?.controllerId);

		if (!assignedReadersForDoors?.length) {
			return EnrichedPortalStatus.LOCKED;
		}

		for (const reader of assignedReadersForDoors) {
			const controllerState = getControllerEmergencyState(reader.mapping?.port?.controllerId);
			if (controllerState === ControllerLockState.EmergencyLocked) {
				return EnrichedPortalStatus.LOCKED;
			} else if (controllerState === ControllerLockState.EmergencyUnlocked) {
				return EnrichedPortalStatus.UNLOCKED;
			}
		}

		if (unlockedDoors.some((doorId) => portal.portalId === doorId)) {
			return EnrichedPortalStatus.UNLOCKED;
		}

		return getDoorScheduleStatus(portal);
	};

	return {
		unlock,
		getDoorStatus,
	};
};
