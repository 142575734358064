export const MODAL_TYPES = {
	ABOUT: 'about',
	SITE_SETTINGS: 'site-settings',
	SYSTEM_SETTINGS: 'system-settings',
	CARD_FORMAT: 'card-format',
	USER_PROFILE: 'user-profile',
	HELP_CONTROLLER_FORM: 'help-controller-form',
	HELP_FORM: 'help-form',
	HELP_CARD_FORM: 'help-card-form',
	HELP_READER_FORM: 'help-reader-form',
};
