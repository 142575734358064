import { Button, Dropdown } from 'antd';
import { ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PageHeader } from '~/components/shared';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { getAuditTransactionsKey, useAuditTransactions } from '~/hooks/data/transactions/useAuditTransactions';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useAllAuditTransactions } from '~/hooks/data/transactions/useAllAuditTransactions';
import { downloadDataAsFile } from '~/exporters';
import { useTheme } from 'emotion-theming';
import { useCurrentUser } from '~/hooks/features/useCurrentUser/useCurrentUser';
import {
	DEFAULT_DATE_RANGE,
	DEFAULT_PAGE_NUMBER,
	getActionType,
	getCategory,
	getDescription,
} from '../AuditTransactionsEvents.utils';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AuditTransactionsPageHeader = () => {
	const { translate } = useLocale();
	const theme = useTheme();
	const { currentUser } = useCurrentUser();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { queryParams } = useQueryParams();

	const auditTransactionsKey =
		customerId && systemId
			? getAuditTransactionsKey({
					customerId,
					systemId,
					pageSize: parseInt(queryParams?.pageSize) || DEFAULT_PAGE_SIZE,
					pageNumber: parseInt(queryParams?.pageNumber) || DEFAULT_PAGE_NUMBER,
					action: queryParams?.action,
					startTime: queryParams?.startTime || DEFAULT_DATE_RANGE.start.toISOString(),
					endTime: queryParams?.endTime || DEFAULT_DATE_RANGE.end.toISOString(),
			  })
			: null;
	const {
		data: transactions,
		isLoading: isLoadingTransactions,
		isValidating: isValidatingTransactions,
		mutate: getNewTransactions,
		error: loadTransactionsError,
	} = useAuditTransactions(auditTransactionsKey);

	const { isLoading: isLoadingAllTransactions, fetchAllAuditTransactions } = useAllAuditTransactions();

	const isLoadingOrValidatingTransactions = isLoadingTransactions || isValidatingTransactions;

	const onDataReload = () => {
		getNewTransactions();
	};

	const onExport = async (type) => {
		const { action, startTime, endTime } = queryParams;

		const allAuditTransactions = await fetchAllAuditTransactions({
			action,
			startTime: startTime ? moment(startTime) : DEFAULT_DATE_RANGE.start,
			endTime: endTime ? moment(endTime) : DEFAULT_DATE_RANGE.end,
		});

		downloadDataAsFile({
			type,
			theme,
			title: translate.byKey('audit_translations_menu_title'),
			list: allAuditTransactions.map((transaction) => {
				return { ...transaction, eventSource: 'blank' };
			}),
			columns: [
				{
					header: translate.byKey('action'),
					dataKey: 'httpMethod',
					formatter: (value) => translate.byValue(getActionType(value)),
				},
				{
					header: translate.byKey('category'),
					dataKey: 'requestUrl',
					formatter: (value) => translate.byValue(getCategory(value)),
				},
				{
					header: translate.byKey('date_time'),
					dataKey: 'sourceTimeStamp',
					formatter: (value) => moment(value).format('L LT'),
				},
				{ header: translate.byKey('is_administrator_label'), dataKey: 'userId' },
				{ header: translate.byKey('description'), dataKey: 'requestBody', formatter: getDescription },
			],
			currentUser,
			totalNumberOfRecords: transactions?.totalCount,
		});
	};

	return (
		<PageHeader title={translate.byKey('audit_translations_menu_title')} icon="reports">
			<Button
				disabled={isLoadingOrValidatingTransactions}
				type="primary"
				icon={<ReloadOutlined />}
				onClick={onDataReload}
			>
				{translate.byKey('refresh')}
			</Button>
			<Dropdown
				disabled={isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError}
				menu={{
					items: [
						{
							key: 'exportButtonPdf',
							disabled: isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError,
							onClick: () => onExport('pdf'),
							label: translate.byKey('pdf'),
						},
						{
							key: 'exportButtonCsv',
							disabled: isLoadingOrValidatingTransactions || isLoadingAllTransactions || loadTransactionsError,
							onClick: () => onExport('csv'),
							label: translate.byKey('csv'),
						},
					],
				}}
			>
				<Button loading={isLoadingAllTransactions} icon={<ExportOutlined />} type="primary">
					{translate.byKey('export')}
				</Button>
			</Dropdown>
		</PageHeader>
	);
};
