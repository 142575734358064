import styled from '@emotion/styled';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const Box = styled.div`
	${layout}
	${space}
  ${flexbox}
  ${size}
  ${background}
  ${grid}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
`;

export default Box;
