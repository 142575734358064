import './styles/App.css';

import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { appRouter } from '~/screens/_shared/AppRouter';

import ConfigProvider from './ConfigProvider';
import AppLocale from '~/screens/_shared/AppLocale';
import AppTheme from '~/screens/_shared/AppTheme';

const App = () => (
	<AppLocale>
		<AppTheme>
			<ConfigProvider>
				<RouterProvider router={appRouter} />
			</ConfigProvider>
		</AppTheme>
	</AppLocale>
);

export default App;
