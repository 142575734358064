// https://github.com/ant-design/ant-design/blob/6f1e67d9b8a9e22dd07e28852a083daa5668b4a0/components/date-picker/util.ts
export function transPlacement2DropdownAlign(direction, placement) {
	const overflow = {
		adjustX: 1,
		adjustY: 1,
	};
	switch (placement) {
		case 'bottomLeft': {
			return {
				points: ['tl', 'bl'],
				offset: [0, 4],
				overflow,
			};
		}
		case 'bottomRight': {
			return {
				points: ['tr', 'br'],
				offset: [0, 4],
				overflow,
			};
		}
		case 'topLeft': {
			return {
				points: ['bl', 'tl'],
				offset: [0, -4],
				overflow,
			};
		}
		case 'topRight': {
			return {
				points: ['br', 'tr'],
				offset: [0, -4],
				overflow,
			};
		}
		default: {
			return {
				points: direction === 'rtl' ? ['tr', 'br'] : ['tl', 'bl'],
				offset: [0, 4],
				overflow,
			};
		}
	}
}
