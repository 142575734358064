import moment from 'moment';
import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import { defAccessGroupName } from '~/screens/Doors/hooks/createOrUpdateDefaultAccessGroup';
import { useState } from 'react';
import {
	useGetEnrichedPortal,
	addOnlinePortalAssetsToSet,
	addOfflinePortalAssetsToSet,
} from '~/screens/AccessGroups/hooks/useGetEnrichedPortal';

export default function useGetAccessGroups() {
	const [getAccessProfiles] = useListApi(mappers.accessProfile);
	const [getPortals] = useGetEnrichedPortal(mappers.portal);
	const [isBusy, setIsBusy] = useState(false);

	return [
		async function (deviceType, site = null) {
			setIsBusy(true);
			try {
				let [{ onlinePortals, offlinePortals }, accessProfiles] = await Promise.all([
					getPortals(),
					getAccessProfiles(
						{ params: { 'detail-level': 'FULL' } },
						{ expiry: moment().add(1, 'seconds'), shared: true }
					),
				]);

				accessProfiles = accessProfiles.map((accessProfile) => ({
					...accessProfile,
					key: accessProfile.accessProfileId,
				}));

				if (site?.siteId) {
					accessProfiles = accessProfiles.filter((accessGroup) => {
						if (accessGroup.name === defAccessGroupName) {
							return false;
						}
						accessGroup.accessPolicies = accessGroup.accessPolicies || [];

						return (
							accessGroup.accessPolicies.length === 0 ||
							accessGroup.accessPolicies.some((policy) => {
								return policy.assets && policy.assets.some((asset) => asset.siteId === site.siteId);
							})
						);
					});
				}

				let results = {
					accessProfiles: [],
					portals: [],
				};

				if (deviceType === 'ONLINE' || deviceType === 'ALL') {
					const assets = [...onlinePortals.reduce(addOnlinePortalAssetsToSet, new Set())];

					results.accessProfiles = accessProfiles
						.filter((accessProfile) =>
							assets.some((assetId) => {
								return (
									Array.isArray(accessProfile.accessPolicies) &&
									accessProfile.accessPolicies.some((policy) =>
										policy.assets.some((otherAsset) => otherAsset.assetId === assetId)
									)
								);
							})
						)
						.map((accessProfile) => ({ ...accessProfile, type: 'online' }));
					results.portals = onlinePortals;
				}

				if (deviceType === 'OFFLINE' || deviceType === 'ALL') {
					const assets = [...offlinePortals.reduce(addOfflinePortalAssetsToSet, new Set())];

					results.accessProfiles = results.accessProfiles.concat(
						accessProfiles
							.filter(
								(accessProfile) =>
									!results.accessProfiles.some((other) => other.accessProfileId === accessProfile.accessProfileId)
							)
							.filter((accessProfile) =>
								assets.some((assetId) => {
									return (
										Array.isArray(accessProfile.accessPolicies) &&
										accessProfile.accessPolicies.some((policy) =>
											policy.assets.some((otherAsset) => otherAsset.assetId === assetId)
										)
									);
								})
							)
							.map((accessProfile) => ({ ...accessProfile, type: 'offline' }))
					);

					results.portals = results.portals.concat(offlinePortals);
				}

				if (deviceType === 'ALL') {
					const excess = accessProfiles.filter(
						(profile) => !results.accessProfiles.some((other) => other.accessProfileId === profile.accessProfileId)
					);

					return results.accessProfiles.concat(excess);
				}

				return [results.accessProfiles, results.portals];
			} finally {
				setIsBusy(false);
			}
		},
		isBusy,
	];
}
