import isEqual from 'lodash/isEqual';

export const range = (start, end) => Array.from({ length: end - start }, (value, key) => key + start);

export const getDuplicatedItemsIndexesInArray = (array) => {
	if (!array || array.length < 2) {
		return [];
	}

	for (let i = 0; i < array.length; i++) {
		for (let j = 0; j < i; j++) {
			const currentItem = array[j];
			const nextItem = array[i];

			if (isEqual(currentItem, nextItem)) {
				return [j, i];
			}
		}
	}

	return [];
};
