import useSWR from 'swr';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';

const fallbackData = [];

const fetcher = async (getCustomers) => {
	return await getCustomers({ ignoreGlobalHandlers: true });
};

export const useCustomers = () => {
	const {
		data: { administratorId },
	} = useUserAuthData();
	const [getCustomers] = useListApi(mappers.customer);

	return useSWR(administratorId ? `/customer?${administratorId}` : null, () => fetcher(getCustomers), {
		revalidateOnFocus: false,
		revalidateIfStale: false,
		revalidateOnReconnect: false,
		refreshInterval: 1000 * 60 * 10, // 10 minutes
		fallbackData,
	});
};
