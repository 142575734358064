import React from 'react';

export const viewBox = '0 0 20 20';

const path = (
	<g fill="currentColor">
		<path
			d="M7.5 2C9.433 2 11 3.567 11 5.5C11 7.433 9.433 9 7.5 9C5.567 9 4 7.433 4 5.5C4 3.567 5.567 2 7.5 2Z"
			fill="currentColor"
		/>
		<path
			d="M14.5 9C15.8807 9 17 7.88071 17 6.5C17 5.11929 15.8807 4 14.5 4C13.1193 4 12 5.11929 12 6.5C12 7.88071 13.1193 9 14.5 9Z"
			fill="currentColor"
		/>
		<path
			d="M11.3045 10.023C11.2052 10.0079 11.1035 10 11 10H4C2.89543 10 2 10.8954 2 12V13.5C2 15.5544 4.08805 17 7.5 17C8.23233 17 8.90367 16.9334 9.50646 16.8085C9.1814 16.1065 9 15.3244 9 14.5C9 12.6541 9.90933 11.0206 11.3045 10.023Z"
			fill="currentColor"
		/>
		<path
			d="M10 14.5C10 16.9853 12.0147 19 14.5 19C16.9853 19 19 16.9853 19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5ZM12.4039 17.3032L17.3032 12.4039C17.7408 12.9882 18 13.7138 18 14.5C18 16.433 16.433 18 14.5 18C13.7138 18 12.9882 17.7408 12.4039 17.3032ZM11.6968 16.596C11.2592 16.0118 11 15.2861 11 14.5C11 12.567 12.567 11 14.5 11C15.2861 11 16.0118 11.2592 16.596 11.6968L11.6968 16.596Z"
			fill="currentColor"
		/>
	</g>
);

const personProhibited = {
	path,
	viewBox,
};

export default personProhibited;
