import { WarningOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Space, Switch, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { useTheme } from 'emotion-theming';

import { DayPicker, Item, Modal, Spin } from '~/components';
import mappers from '~/screens/_shared/mappers';
import { usePostApi, useGetApi, usePutApi, useDeleteApi, useListApi } from '~/screens/_shared/useApi';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCreate, useDelete, useUpdate } from '~/screens/AccessGroups/hooks';
import systemConfig from '~/screens/_shared/systemConfig';
import { isAperioDoor, isPulseDoor } from '~/screens/Doors/hooks/doorDefaults';
import { showErrorToast, showSuccessToast } from '~/screens/_shared/toast';
import { AssetTreeSelector, defaultAssetTypes } from '~/screens/AccessGroups/AssetTreeSelector';
import { useGetEnrichedPortal, getAllPortalAssetIds } from '~/screens/AccessGroups/hooks/useGetEnrichedPortal';
import { validateMinNumberOfCharacters } from '~/utils/validation';
import { AccessGroupTypes } from '~/constants/transactions';
import { createEmptyDefaultDaySelection, formatDaySelectionValues } from './utils/daySelection';
import { useSiteInformation } from '~/hooks/features/useSiteInformation/useSiteInformation';
import { OnlineAccessGroupsPerSite } from '~/hooks/features/useSiteInformation/useSiteInformation.utils';
import { useWatch } from 'antd/lib/form/Form';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const ONLINE_DOOR_LIMIT = 32;
const OFFLINE_DOOR_LIMIT = 70;

export const AccessGroupForm = ({
	visible,
	onUpdated,
	onCancel,
	selected,
	onClose,
	accessGroups,
	onlineAccessGroupCount,
}) => {
	const theme = useTheme();
	const { translate } = useLocale();
	const { onlineAccessGroupsPerSite } = useSiteInformation();

	const typeOptions = [
		{
			label: translate.byKey('access_groups_online_v2'),
			value: AccessGroupTypes.ONLINE,
		},
		{
			label: translate.byKey('access_groups_offline_v2'),
			value: AccessGroupTypes.OFFLINE,
		},
	];

	const [form] = Form.useForm();
	const useDefaultSchedule = useWatch('useDefaultSchedule', form);
	const {
		data: { system },
	} = useCurrentSystemSite();
	const { data: authenticatedUser } = useUserAuthData();

	// api call to populate all accessProfile data
	const [getAccessProfile] = useGetApi(mappers.accessProfile);
	const [getAccessProfiles] = useListApi(mappers.accessProfile);
	const [getPortals] = useGetEnrichedPortal(mappers.portal);

	const [getCalendar] = useGetApi(mappers.calendar);
	const [createCalendar] = usePostApi(mappers.calendar);
	const [updateCalendar] = usePutApi(mappers.calendar);
	const [deleteCalendar] = useDeleteApi(mappers.calendar);

	const [getSchedule] = useGetApi(mappers.schedule);
	const [createSchedule] = usePostApi(mappers.schedule);
	const [updateSchedule] = usePutApi(mappers.schedule);
	const [deleteSchedule] = useDeleteApi(mappers.schedule);

	const [createAccessGroup, createDataLoading] = useCreate();
	const [updateAccessGroup, updateDataLoading] = useUpdate();
	const [deleteAccessGroup, deleteDataLoading] = useDelete();

	const [warningModal, setWarningModal] = useState({
		visible: false,
		shownTimes: 0,
	});
	const [accessGroupName, setAccessGroupName] = useState(translate.byKey('add_access_group'));
	const [modalIsLoading, setModalIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [onlineAssetsToInclude, setOnlineAssetsToInclude] = useState([]);
	const [offlineAssetsToInclude, setOfflineAssetsToInclude] = useState([]);
	// used to force UI re-renders. Source of truth is still the form for everything else.
	const [accessGroupType, setAccessGroupType] = useState(null);
	const [formEditDisabledForUser] = useState(
		!hasActionPermissions(authenticatedUser, userConstants.screens.ACCESS_GROUPS, userConstants.actions.WRITE)
	);
	const previouslyUntypedAccessGroup = selected.type ? false : true;

	const [hasAperioOfflinePortals, setHasAperioOfflinePortals] = useState(false);
	const [cannotAddMoreDoors, setCannotAddMoreDoors] = useState(false);
	const isOnlineAccessGroupsLimitReached =
		(onlineAccessGroupCount >= onlineAccessGroupsPerSite &&
			accessGroupType === AccessGroupTypes.ONLINE &&
			!selected.accessProfileId) ||
		(onlineAccessGroupCount >= onlineAccessGroupsPerSite &&
			accessGroupType === AccessGroupTypes.ONLINE &&
			selected.accessProfileId &&
			previouslyUntypedAccessGroup);

	// Copied from useDelete hook
	const deleteSchedulesAndCalendarsWhenUseDefaultSchedule = async () => {
		setModalIsLoading(true);
		try {
			const formData = form.getFieldsValue();
			const accessGroups = await getAccessProfiles({ params: { 'detail-level': 'FULL' } });

			const schedules = accessGroups.reduce((results, group) => {
				if (group.accessProfileId !== formData.accessProfileId) {
					const policies = group.accessPolicies || [];
					if (policies.some((p) => !!p.schedule)) {
						return results.concat(policies.filter((p) => !!p.schedule).map((p) => p.schedule));
					}
				}
				return results;
			}, []);

			formData.accessPolicies = JSON.parse(formData.accessPolicies || '[]');

			for (const accessPolicy of formData.accessPolicies) {
				if (accessPolicy.schedule) {
					const calendarId = accessPolicy.schedule.calendarId;
					const scheduleId = accessPolicy.schedule.scheduleId;

					if (!schedules.some((i) => i.calendarId === calendarId || i.scheduleId === scheduleId)) {
						setCurrentStep(translate.byKey('deleting_schedule'));
						await deleteSchedule({ scheduleId, calendarId });

						setCurrentStep(translate.byKey('deleting_calendar'));
						await deleteCalendar({ calendarId });
					}
				}
			}
		} finally {
			setModalIsLoading(false);
			setCurrentStep('');
		}
	};

	const onFormValuesChange = async (changedValues, allValues) => {
		if (visible && changedValues.accessGroupType) {
			const { accessGroupType } = changedValues;
			setAccessGroupType(accessGroupType);

			const selectedDays = createEmptyDefaultDaySelection(accessGroupType);
			form.setFieldsValue({
				selectedDays,
			});
		}

		if (visible && changedValues.selectedDays) {
			const { accessGroupType } = allValues;
			const { selectedDays } = changedValues;

			if (accessGroupType === AccessGroupTypes.OFFLINE) {
				const filteredSelectedDays = selectedDays.filter((value) => value.day !== 'Holidays');
				form.setFieldsValue({
					selectedDays: filteredSelectedDays,
				});
			} else {
				form.setFieldsValue({
					selectedDays,
				});
			}
		}

		if (visible && changedValues.selectedOfflineAssets) {
			const selectedOfflineAssets = changedValues.selectedOfflineAssets || [];
			const { accessGroupType } = allValues;

			if (accessGroupType === AccessGroupTypes.OFFLINE && selectedOfflineAssets.length) {
				const hasAperioOfflineDevices = offlineAssetsToInclude.some(
					(portal) =>
						isAperioDoor(portal.doorType) &&
						getAllPortalAssetIds(portal).some((assetId) => selectedOfflineAssets.includes(assetId))
				);

				form.setFieldsValue({
					hasAperioOfflineDevices,
				});

				setHasAperioOfflinePortals(hasAperioOfflineDevices);

				const hasPulseOfflineDevices = offlineAssetsToInclude.some(
					(portal) =>
						isPulseDoor(portal.doorType) &&
						getAllPortalAssetIds(portal).some((assetId) => selectedOfflineAssets.includes(assetId))
				);

				//show message only if it contains both Aperio Offline & Pulse Offline doors - show message only once
				if (hasAperioOfflineDevices && hasPulseOfflineDevices && warningModal.shownTimes === 0) {
					showWarningModal(translate.byKey('warning_message_for_aperio_and_pulse_offline_doors_v2'));
				} else if ((!hasAperioOfflineDevices || !hasPulseOfflineDevices) && warningModal.shownTimes === 1) {
					setWarningModal({
						...warningModal,
						shownTimes: 0,
					});
				}
			} else if (accessGroupType === AccessGroupTypes.OFFLINE) {
				form.setFieldsValue({
					hasAperioOfflineDevices: false,
				});

				setHasAperioOfflinePortals(false);
			}
		}
	};

	useEffect(() => {
		let mounted = true;
		let scheduleTimesData = null;
		if (selected && visible) {
			setModalIsLoading(true);
			_cleanUpStates();
			form.resetFields();
			let accessGroupType = selected.accessProfileId ? selected.type : selected.type || AccessGroupTypes.ONLINE;
			if (systemConfig.devVersion === 1 && !selected.type) {
				accessGroupType = AccessGroupTypes.ONLINE;
			}
			setAccessGroupType(accessGroupType);

			const selectedDays = createEmptyDefaultDaySelection(accessGroupType);
			form.setFieldsValue({
				assetsToInclude: [],
				accessGroupType,
				selectedDays,
				startTime: moment('00:00', 'HH:mm'),
				endTime: moment('23:59', 'HH:mm'),
				useDefaultSchedule: true,
			});

			let pendingAccessProfile = selected.accessProfileId
				? getAccessProfile({ accessProfileId: selected.accessProfileId })
				: Promise.resolve({
						validity: {
							startDateTime: moment().startOf('day'),
							endDateTime: moment().endOf('day'),
						},
				  });

			setAccessGroupName(selected.name);

			// get main item
			pendingAccessProfile
				.then(async (accessProfile) => {
					if (mounted) {
						const { onlinePortals, offlinePortals } = await getPortals();
						const mapper = (portal) => ({ ...portal, name: portal.name, assetId: portal.doorAssetId });
						setOnlineAssetsToInclude(onlinePortals.map(mapper));
						setOfflineAssetsToInclude(offlinePortals.map(mapper));

						const { name, accessPolicies, accessProfileId, version: accessProfileVersion } = accessProfile;

						const formData = {};
						if (accessPolicies && accessPolicies.length > 0) {
							let { assets, schedule } = accessPolicies[0];

							if (schedule) {
								const { calendarId, scheduleId } = schedule;
								const [calendarData, scheduleData] = await Promise.all([
									getCalendar({ calendarId }),
									getSchedule({
										scheduleId,
										calendarId,
									}),
								]);
								const { dayTypes, standardWeek } = calendarData;

								if (
									dayTypes &&
									'PublicHoliday' in dayTypes &&
									dayTypes.PublicHoliday &&
									dayTypes.PublicHoliday.description === 'not applying'
								) {
									delete dayTypes.PublicHoliday;
								}

								formData.selectedDays = formatDaySelectionValues(dayTypes, standardWeek, accessGroupType);
								formData.currentSchedule = JSON.stringify(scheduleData);
								formData.currentCalendar = JSON.stringify(calendarData);
								scheduleTimesData = scheduleData.timeIntervals;
							}

							if (accessGroupType === AccessGroupTypes.ONLINE) {
								formData.selectedOnlineAssets = assets.map((asset) => asset.assetId);
							} else if (accessGroupType === AccessGroupTypes.OFFLINE) {
								formData.selectedOfflineAssets = assets.map((asset) => asset.assetId);
							}
							formData.accessPolicies = JSON.stringify(accessPolicies);
						}
						form.setFieldsValue({
							...formData,
							name,
							/*
                            startTime & endTime has been set to use the values from the Schedule API call
                            */
							startTime: scheduleTimesData
								? moment(scheduleTimesData.Applicable[0].startTime, 'HH:mm')
								: moment('00:00', 'HH:mm'),
							endTime: scheduleTimesData
								? moment(scheduleTimesData.Applicable[0].endTime, 'HH:mm')
								: moment('23:59', 'HH:mm'),
							useDefaultSchedule: Boolean(!scheduleTimesData),
							accessProfileVersion,
							accessProfileId,
						});

						if (selected.type === AccessGroupTypes.OFFLINE) {
							const offlineAperioDevices = offlinePortals.filter((portal) => isAperioDoor(portal.doorType));
							const offlinePulseDevices = offlinePortals.filter((portal) => isPulseDoor(portal.doorType));
							const selectedAssetIds = selected.accessPolicies[0].assets.map((asset) => asset.assetId);

							const pulseAssetCollection = offlinePulseDevices.map((portal) => getAllPortalAssetIds(portal)).flat();
							const aperioAssetCollection = offlineAperioDevices.map((portal) => getAllPortalAssetIds(portal)).flat();

							let hasAperioOfflineDevices = false;

							const selectedAssetsContainsPulseDoor = pulseAssetCollection.some((assetId) =>
								selectedAssetIds.includes(assetId)
							);
							const selectedAssetsContainsAperioDoor = aperioAssetCollection.some((assetId) =>
								selectedAssetIds.includes(assetId)
							);

							if (selectedAssetsContainsPulseDoor) {
								hasAperioOfflineDevices = false;
							}

							if (selectedAssetsContainsAperioDoor) {
								hasAperioOfflineDevices = true;
							}

							setHasAperioOfflinePortals(hasAperioOfflineDevices);
							form.setFieldsValue({
								hasAperioOfflineDevices,
							});
						}
					}
				})
				.catch((error) => {
					console.error('Access groups has an error', error);
				})
				.finally(() => {
					if (mounted) {
						setModalIsLoading(false);
					}
				});
		}

		return () => {
			mounted = false;
		};
	}, [selected, visible]);

	const _cleanUpStates = () => {
		setCurrentStep('');
		setAccessGroupName('Add Access Group');
		setWarningModal({
			visible: false,
			shownTimes: 0,
		});
		setCannotAddMoreDoors(false);
		setHasAperioOfflinePortals(false);
	};

	const shouldHideSubmitButton = () => {
		const hasWritePermission = hasActionPermissions(
			authenticatedUser,
			userConstants.screens.ACCESS_GROUPS,
			userConstants.actions.WRITE
		);
		const hasCreatePermission = hasActionPermissions(
			authenticatedUser,
			userConstants.screens.ACCESS_GROUPS,
			userConstants.actions.CREATE
		);

		if ((selected && !hasWritePermission) || (!selected && !hasCreatePermission)) {
			return true; // Toggle Submit button if authenticatedUser has permissions to change/create AccessGroup values.
		}

		if (!accessGroupType) {
			return onlineAccessGroupCount >= onlineAccessGroupsPerSite;
		}

		return cannotAddMoreDoors;
	};

	function timesAreTheSame(getFieldValue) {
		let startTime = getFieldValue('startTime');
		let endTime = getFieldValue('endTime');

		startTime = startTime ? moment(startTime) : null;
		endTime = endTime ? moment(endTime) : null;

		if (startTime && endTime && startTime.format('HH:mm') === endTime.format('HH:mm')) {
			return Promise.reject(translate.byKey('start_time_and_end_time_cannot_be_the_same'));
		}

		return null;
	}

	function showWarningModal(warningMessage) {
		if (warningModal.visible) {
			return;
		}

		Modal.warning({
			title: translate.byKey('warning'),
			content: warningMessage,
			onOk: () => {
				setWarningModal({
					visible: false,
					shownTimes: 1,
				});
			},
		});
	}

	function onAccessGroupDelete() {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('are_you_sure_you_want_to_delete_this_access_group'),
			okText: translate.byKey('delete'),
			okButtonProps: { style: { backgroundColor: theme.colors.brand[500], borderColor: theme.colors.brand[500] } },
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				if (!modalIsLoading) {
					const formData = form.getFieldsValue();
					handleDeleteAccessGroup(formData);
				}
			},
		});
	}

	const handleDeleteAccessGroup = (formData) => {
		setModalIsLoading(true);
		deleteAccessGroup(formData).then(() => {
			showSuccessToast(translate.byKeyFormatted('successfully_deleted_formatted', [formData.name]));
			onUpdated();
		});
	};

	function onFormFinish(formData) {
		setModalIsLoading(true);
		const createData = {
			...formData,
			description: formData.name,
			startDateTime: formData.startTime,
			endDateTime: formData.endTime,
			version: formData.accessProfileVersion,
			domainKey: {
				domainId: system.systemId,
			},
		};

		// not ready to save AccessProfile yet, set 'saveMainParent = false' so setData knows not to save AccessProfile yet
		createData.saveMainParent = false;

		formData.selectedDays = formData.selectedDays || [];

		let calendarData = {
			name: createData.name + ':Cal',
			domainKey: {
				domainId: system.systemId,
			},
			description: createData.name + ':Cal',
			dayTypes: {
				NA: { description: 'No Access', sortOrder: 0 },
				Applicable: { description: 'Access', sortOrder: 1 },
				PublicHoliday:
					formData.accessGroupType === AccessGroupTypes.ONLINE
						? {
								description: formData.selectedDays[7].enabled ? '' : 'not applying', // if Special Days is selected replace default dayType to reflect this
								sortOrder: 2,
						  }
						: {},
			},
			standardWeek: {
				SUNDAY: formData.selectedDays[0].enabled ? 'Applicable' : 'NA',
				MONDAY: formData.selectedDays[1].enabled ? 'Applicable' : 'NA',
				TUESDAY: formData.selectedDays[2].enabled ? 'Applicable' : 'NA',
				WEDNESDAY: formData.selectedDays[3].enabled ? 'Applicable' : 'NA',
				THURSDAY: formData.selectedDays[4].enabled ? 'Applicable' : 'NA',
				FRIDAY: formData.selectedDays[5].enabled ? 'Applicable' : 'NA',
				SATURDAY: formData.selectedDays[6].enabled ? 'Applicable' : 'NA',
			},
		};
		const currentCalendar = JSON.parse(formData.currentCalendar || null);

		calendarData.version = currentCalendar ? currentCalendar.version : null;
		calendarData.calendarId = currentCalendar ? currentCalendar.calendarId : null;

		let saveCalendar = Promise.resolve({ isOffline: true });

		if (
			formData.accessGroupType === AccessGroupTypes.ONLINE ||
			(formData.hasAperioOfflineDevices && !useDefaultSchedule)
		) {
			setCurrentStep(
				currentCalendar && currentCalendar.calendarId
					? translate.byKey('updating_calendar')
					: translate.byKey('creating_calendar')
			);
			saveCalendar =
				currentCalendar && currentCalendar.calendarId ? updateCalendar(calendarData) : createCalendar(calendarData);
		} else if (formData.hasAperioOfflineDevices && useDefaultSchedule && selected) {
			deleteSchedulesAndCalendarsWhenUseDefaultSchedule();
		}

		saveCalendar
			.then(async (calendar) => {
				let scheduleStatesObj = {
					'No Access': 'No Access',
					Access: 'Allow Access',
				}; // default dayType without special days

				const policy = {};

				const validityStartDate = moment(createData.startDateTime).format();
				const validityEndDate = moment(createData.endDateTime).add(80, 'years').format();

				if (!calendar.isOffline) {
					/*
                    startTime & endTime has been defaulted to LocalTime
                    */
					const startTime = createData.startTime.format('HH:mm');
					const endTime = createData.endTime.format('HH:mm');

					let scheduleApplicableObj = [{ state: 'Access', startTime: startTime, endTime: endTime }];
					let scheduleNaObj = [{ state: 'No Access', startTime: '00:00', endTime: '23:59' }];
					let PublicHolidayObj = [{ state: 'Access', startTime: startTime, endTime: endTime }];

					let currentSchedule = JSON.parse(formData.currentSchedule || null);

					const scheduleData = {
						name: createData.name + ':Sch',
						calendarId: calendar.calendarId,
						domainKey: {
							domainId: system.systemId,
						},
						description: createData.name + ':Sch',
						startDateTime: createData.startDateTime,
						endDateTime: createData.endDateTime,
						defaultState: 'No Access',
						scheduleStates: scheduleStatesObj,
						timeIntervals: formData.selectedDays[7]?.enabled
							? { NA: scheduleNaObj, Applicable: scheduleApplicableObj, PublicHoliday: PublicHolidayObj }
							: { NA: scheduleNaObj, Applicable: scheduleApplicableObj },
					};

					if (!currentSchedule) {
						setCurrentStep(translate.byKey('creating_schedule'));
						currentSchedule = await createSchedule(scheduleData);
					} else {
						setCurrentStep(translate.byKey('updating_schedule'));
						scheduleData.version = currentSchedule.version;
						scheduleData.scheduleId = currentSchedule.scheduleId;
						scheduleData.calendarId = scheduleData.calendarId || currentSchedule.calendarId;
						await updateSchedule(scheduleData);
					}

					policy.calendarId = calendar.calendarId || currentSchedule.calendarId;
					policy.scheduleId = currentSchedule.scheduleId;
				}

				const selectedAssets =
					formData.accessGroupType === AccessGroupTypes.ONLINE
						? formData.selectedOnlineAssets || []
						: formData.selectedOfflineAssets || [];

				const values = {
					...createData,
					startDateTime: validityStartDate,
					endDateTime: validityEndDate,
					description: '',
					policies: [
						Object.assign(policy, {
							systemId: system.systemId,
							assetItems: selectedAssets.map((assetId) => ({ assetId })),
						}),
					],
					saveMainParent: true,
				};

				if (!values.accessProfileVersion) {
					setCurrentStep(translate.byKey('creating_access_group'));
					await createAccessGroup(values);
					showSuccessToast(translate.byKeyFormatted('created_successfully_formatted', [values.name]));
					onUpdated();
				} else {
					setCurrentStep(translate.byKey('updating_access_group'));
					await updateAccessGroup(values);
					showSuccessToast(translate.byKeyFormatted('successfully_updated_formatted', [values.name]));
					onUpdated();
				}
			})
			.catch((ex) => {
				if (ex && ex.message) {
					showErrorToast(translate.byValue(ex.message));
				}
				setModalIsLoading(false);
				setCurrentStep('');
				console.error(ex);
			});
	}

	function renderOnlineAccessGroupLimitReachedMessage() {
		if (!accessGroupType || accessGroupType !== AccessGroupTypes.ONLINE) {
			return null;
		}

		return onlineAccessGroupCount >= onlineAccessGroupsPerSite ? (
			<Col
				style={{
					display: 'flex',
					backgroundColor: '#00a0d0',
					borderRadius: '5px',
					marginTop: 10,
					width: '100%',
					padding: '10px',
				}}
				md={24}
				lg={24}
			>
				<WarningOutlined style={{ fontSize: 22, color: '#fff', display: 'inline-table' }} />
				<div
					style={{
						marginLeft: 8,
						color: '#fff',
					}}
				>
					{onlineAccessGroupsPerSite === OnlineAccessGroupsPerSite.level1
						? translate.byKeyFormatted('online_access_group_hardware_restriction_info', {
								limit: onlineAccessGroupsPerSite,
						  })
						: translate.byKeyFormatted('online_access_group_limit_reached', { limit: onlineAccessGroupsPerSite })}
				</div>
			</Col>
		) : null;
	}

	const shouldDisableScheduleControls =
		accessGroupType === AccessGroupTypes.ONLINE
			? isOnlineAccessGroupsLimitReached
			: !hasAperioOfflinePortals
			  ? true
			  : useDefaultSchedule;

	const onCancelOrCloseModal = () => {
		onClose();
		_cleanUpStates();
		onCancel();
	};

	return (
		<Modal
			getContainer={false}
			showHelpButton={true}
			open={visible}
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			loading={modalIsLoading}
			saving={createDataLoading || updateDataLoading || deleteDataLoading}
			title={accessGroupName}
			hideOk={shouldHideSubmitButton()}
			showDelete={
				selected?.accessProfileId &&
				hasActionPermissions(authenticatedUser, userConstants.screens.ACCESS_GROUPS, userConstants.actions.DELETE)
			}
			disableDelete={false}
			disableOk={isOnlineAccessGroupsLimitReached}
			onClose={onCancelOrCloseModal}
			onDelete={onAccessGroupDelete}
			onCancel={onCancelOrCloseModal}
			onOk={() => {
				if (accessGroupType === AccessGroupTypes.OFFLINE && useDefaultSchedule && selected?.accessProfileId) {
					Modal.confirm({
						title: translate.byKey('overriding_current_access'),
						content: translate.byKey('overriding_current_access_message'),
						okText: translate.byKey('apply'),
						cancelText: translate.byKey('back'),
						onOk: () => {
							form.submit();
						},
					});
				} else {
					form.submit();
				}
			}}
		>
			{visible && (
				<Spin text={currentStep || ''} active={modalIsLoading}>
					<Form
						form={form}
						layout="vertical"
						size="medium"
						preserve="false"
						onValuesChange={onFormValuesChange}
						onFinish={onFormFinish}
						name="access-group-form"
						scrollToFirstError
					>
						<Item name="accessProfileVersion" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="accessProfileId" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="hasAperioOfflineDevices" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="accessPolicies" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="currentCalendar" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="currentSchedule" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Item name="shouldDelete" shouldUpdate noStyle>
							<Input type="hidden" />
						</Item>
						<Row gutter={[24, 24]}>
							<Col md={24} lg={24}>
								<Item
									name={['name']}
									label={translate.byKey('access_group_name')}
									shouldUpdate
									rules={[
										{
											required: true,
											whitespace: true,
											message: translate.byKey('name_is_required'),
										},
										{
											pattern: validateMinNumberOfCharacters(3),
											message: translate.byKeyFormatted('field_value_cannot_be_shorter_than_message', {
												fieldName: translate.byKey('access_group_name'),
												minLength: 3,
											}),
										},
										{
											max: 96,
											message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
												fieldName: translate.byKey('access_group_name'),
												maxLength: 96,
											}),
										},
										{
											validator(rule, value) {
												const temp = (value || '').toLowerCase().trim();
												const otherAccessGroup = accessGroups.find(
													(accessGroup) => (accessGroup.name || '').trim().toLowerCase().localeCompare(temp) === 0
												);

												if (
													otherAccessGroup &&
													selected &&
													otherAccessGroup.accessProfileId === selected.accessProfileId
												) {
													return Promise.resolve();
												}

												if (otherAccessGroup) {
													return Promise.reject(translate.byKey('access_group_duplication_message'));
												}

												return Promise.resolve();
											},
										},
									]}
								>
									<Input
										disabled={formEditDisabledForUser || isOnlineAccessGroupsLimitReached}
										placeholder={translate.byKey('access_group_name')}
										maxLength={96}
										showCount
									/>
								</Item>
							</Col>
							<Col style={{ display: systemConfig.devVersion >= 2 ? 'block' : 'none' }} md={24} lg={24}>
								<Space size={80}>
									<Item
										style={{ display: systemConfig.devVersion >= 2 ? 'block' : 'none' }}
										name="accessGroupType"
										dependencies={['shouldDelete']}
										label={translate.byKey('access_group_type_v2')}
										shouldUpdate
										rules={[
											{
												// on create, the type will not be required but already selected (and doors will be required which prevents the save)
												// on update, the type will be required if it present, which when it has doors selected.
												// Otherwise, when it isn't there, it isn't required to enable deleting the access group - because
												// setFieldsValue doesn't trigger a re-render.
												required: !form.getFieldValue('shouldDelete') && !!selected?.accessProfileId && accessGroupType,
												message: translate.byKey('access_group_type_is_required_v2'),
											},
										]}
									>
										<Radio.Group
											disabled={formEditDisabledForUser || (selected?.accessProfileId && selected.type)}
											options={typeOptions}
										/>
									</Item>

									{accessGroupType === AccessGroupTypes.OFFLINE && hasAperioOfflinePortals ? (
										<Item
											name="useDefaultSchedule"
											valuePropName="checked"
											label={translate.byKey('use_default_access_message')}
										>
											<Switch disabled={formEditDisabledForUser} />
										</Item>
									) : null}
								</Space>
							</Col>
							<Col md={24} lg={12}>
								<Item
									name="startTime"
									label={translate.byKey('start_time')}
									shouldUpdate
									dependencies={['endTime', 'accessGroupType', 'hasAperioOfflineDevices']}
									rules={[
										{
											required: true,
											message: translate.byKey('you_must_select_a_start_time'),
										},
										({ getFieldValue }) => ({
											validator() {
												const validationResult = timesAreTheSame(getFieldValue);

												if (validationResult) {
													return validationResult;
												}

												if (moment(getFieldValue('startTime')).isBefore(moment(getFieldValue('endTime')))) {
													return Promise.resolve();
												}

												return Promise.reject(translate.byKey('the_start_time_must_be_before_end_time'));
											},
										}),
									]}
								>
									<TimePicker
										style={{ width: '100%' }}
										placeholder={translate.byKey('start_time')}
										name="startTime"
										format="HH:mm"
										disabled={formEditDisabledForUser || shouldDisableScheduleControls}
										popupClassName="access-group-time-popup"
									/>
								</Item>
							</Col>

							<Col md={24} lg={12}>
								<Item
									name="endTime"
									label={translate.byKey('end_time')}
									shouldUpdate
									dependencies={['startTime', 'accessGroupType']}
									rules={[
										{
											required: true,
											message: translate.byKey('you_must_select_end_time'),
										},
										({ getFieldValue }) => ({
											validator() {
												if (moment(getFieldValue('endTime')).isAfter(moment(getFieldValue('startTime')))) {
													return Promise.resolve();
												}

												return Promise.reject(translate.byKey('the_end_time_must_be_after_start_time'));
											},
										}),
									]}
								>
									<TimePicker
										style={{ width: '100%' }}
										placeholder={translate.byKey('end_time')}
										format="HH:mm"
										name="endTime"
										disabled={formEditDisabledForUser || shouldDisableScheduleControls}
										popupClassName="access-group-time-popup"
									/>
								</Item>
							</Col>

							<Col md={24} lg={24}>
								<Item
									name="selectedDays"
									dependencies={['accessGroupType', 'hasAperioOfflineDevices']}
									label={translate.byKey('days_allowed')}
									rules={[
										{
											required: !form.getFieldValue('shouldDelete'),
											message: translate.byKey('you_are_required_to_select_at_least_1_day'),
										},
										() => ({
											validator(rule, days) {
												if (form.getFieldValue('shouldDelete')) {
													return Promise.resolve();
												}

												if (systemConfig.devVersion === 1 && days.length > 0 && !days.some((day) => day.enabled)) {
													return Promise.reject(translate.byKey('no_days_selected'));
												}

												if (
													systemConfig.devVersion === 2 &&
													!days.some((day) => day.enabled) &&
													form.getFieldValue('hasAperioOfflineDevices') &&
													form.getFieldValue('accessGroupType') === AccessGroupTypes.OFFLINE &&
													!form.getFieldValue('useDefaultSchedule')
												) {
													return Promise.reject(translate.byKey('no_days_selected'));
												}

												if (
													systemConfig.devVersion === 2 &&
													days.length > 0 &&
													!days.some((day) => day.enabled) &&
													form.getFieldValue('accessGroupType') === AccessGroupTypes.ONLINE
												) {
													return Promise.reject(translate.byKey('no_days_selected'));
												}

												return Promise.resolve();
											},
										}),
									]}
									shouldUpdate
								>
									<DayPicker
										style={
											formEditDisabledForUser || shouldDisableScheduleControls
												? {
														pointerEvents: 'none',
														opacity: '0.4',
												  }
												: {}
										}
										label={translate.byKey('days_allowed')}
									/>
								</Item>
							</Col>
							<Col md={24} lg={24}>
								<Item
									style={{ display: accessGroupType === AccessGroupTypes.ONLINE ? 'inherit' : 'none' }}
									name="selectedOnlineAssets"
									dependencies={['accessGroupType', 'shouldDelete']}
									label={translate.byKey('doors')}
									shouldUpdate
									rules={[
										{
											required: !form.getFieldValue('shouldDelete') && accessGroupType === AccessGroupTypes.ONLINE,
											message: translate.byKey('you_are_required_to_select_at_least_1_door'),
										},
										{
											validator(rule, value) {
												if (value?.length > ONLINE_DOOR_LIMIT) {
													return Promise.reject(
														translate.byKeyFormatted('online_door_limit_reached', [ONLINE_DOOR_LIMIT])
													);
												}

												return Promise.resolve();
											},
										},
									]}
								>
									<AssetTreeSelector
										placeholder={translate.byKey('select_a_door')}
										assetsToInclude={onlineAssetsToInclude}
										types={['BASIC_ASSET', 'ACCESS_AREA']}
										selectionMode="preferChild"
										disabled={isOnlineAccessGroupsLimitReached}
									/>
								</Item>
								<Item
									style={{ display: accessGroupType === AccessGroupTypes.OFFLINE ? 'inherit' : 'none' }}
									name="selectedOfflineAssets"
									dependencies={['accessGroupType', 'shouldDelete']}
									label={translate.byKey('doors')}
									shouldUpdate
									rules={[
										{
											required: !form.getFieldValue('shouldDelete') && accessGroupType === AccessGroupTypes.OFFLINE,
											message: translate.byKey('you_are_required_to_select_at_least_1_door'),
										},
										{
											validator(rule, value) {
												if (value?.length > OFFLINE_DOOR_LIMIT) {
													return Promise.reject(
														translate.byKeyFormatted('offline_door_limit_reached', [OFFLINE_DOOR_LIMIT])
													);
												}

												return Promise.resolve();
											},
										},
									]}
								>
									<AssetTreeSelector
										placeholder={translate.byKey('select_a_door_or_door_group')}
										assetsToInclude={offlineAssetsToInclude}
										types={defaultAssetTypes}
										selectionMode="preferParent"
									/>
								</Item>
								{renderOnlineAccessGroupLimitReachedMessage()}
							</Col>
						</Row>
					</Form>
				</Spin>
			)}
		</Modal>
	);
};
