import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';
import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const separator = '__';

export const useDeleteHoliday = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.specialDay,
				method: method.delete,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => ref.current.fetchData({ ...arg });

	return useSWRMutation(key, fetcher, { ...config });
};

export const getDeleteHolidayKey = ({ customerId, systemId, calendarId }) =>
	customerId && systemId && calendarId
		? `${customerId}${separator}\
${systemId}${separator}\
${calendarId}${separator}\
deleteHoliday`
		: null;
