import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { getUserAuthCacheData } from '~/components/features/auth/hooks/useUserAuthData';
import { AuthLayout } from '~/components/shared/layouts/AuthLayout/AuthLayout';
import { MainLayout } from '~/components/shared/layouts/MainLayout/MainLayout';
import { LivePage } from '~/screens/Live/LivePage';
import { AuthPage } from '../Auth/AuthPage';
import Controllers from '../Controllers';
import { PeoplePage } from '../People/PeoplePage';
import Doors from '../Doors';
import { AccessSchedulesPage } from '../AccessSchedules/AccessSchedulesPage';
import { AccessProfilesPage } from '../AccessProfiles/AccessProfilesPage';
import AccessGroups from '../AccessGroups';
import { AccessTransactionsPage } from '../Reports/AccessTransactions/AccessTransactionsPage';
import { StatusTransactionsPage } from '../Reports/StatusTransactions/StatusTransactionsPage';
import { AuditTransactionsPage } from '../Reports/AuditTransactions/AuditTransactionsPage';
import { UsbDevicesPage } from '../UsbDevices/UsbDevicesPage';
import { BlocklistPage } from '../Blocklist/BlocklistPage';
import { DoorGroupsPage } from '../DoorGroups/DoorGroupsPage';
import { HolidaysPage } from '../Holidays/HolidaysPage';

const indexLoader = () => {
	const { tokens } = getUserAuthCacheData();

	return tokens?.length ? redirect('/live') : redirect('/auth');
};

const redirectToApp = () => {
	const { tokens } = getUserAuthCacheData();

	return tokens?.length ? redirect('/live') : null;
};

const redirectToAuth = () => {
	const { tokens } = getUserAuthCacheData();

	return !tokens?.length ? redirect('/auth') : null;
};

export const appRouter = createBrowserRouter([
	{
		path: '/',
		loader: indexLoader,
	},
	{
		element: <AuthLayout />,
		loader: redirectToApp,
		children: [
			{
				path: '/auth',
				element: <AuthPage />,
				loader: redirectToApp,
			},
		],
	},
	{
		element: <MainLayout />,
		loader: redirectToAuth,
		children: [
			{
				path: '/live',
				element: <LivePage />,
			},
			{
				path: '/controllers',
				element: <Controllers />,
			},
			{
				path: '/people',
				element: <PeoplePage />,
			},
			{
				path: '/doors',
				element: <Doors />,
			},
			{
				path: '/holidays',
				element: <HolidaysPage />,
			},
			{
				path: '/access-schedules',
				element: <AccessSchedulesPage />,
			},
			{
				path: '/access-profiles',
				element: <AccessProfilesPage />,
			},
			{
				path: '/access-groups',
				element: <AccessGroups />,
			},
			{
				path: '/access-log',
				element: <AccessTransactionsPage />,
			},
			{
				path: '/device-log',
				element: <StatusTransactionsPage />,
			},
			{
				path: '/audit-logs',
				element: <AuditTransactionsPage />,
			},
			{
				path: '/usb-devices',
				element: <UsbDevicesPage />,
			},
			{
				path: '/blocklist',
				element: <BlocklistPage />,
			},
			{
				path: '/door-groups',
				element: <DoorGroupsPage />,
			},
		],
	},
]);
