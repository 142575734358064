import React from 'react';
import { Table } from 'antd';

import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Spin } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getUsbDevicesKey } from '~/components/features/usb-devices/UsbDevicesTable/UsbDevicesTable.utils';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const UsbTypesToTranslationKeys = {
	[ControllerTypes.DESKTOP_UPDATER]: 'desktop_updater',
	[ControllerTypes.CLOUD_UPDATER]: 'idc_updater',
};

export const UsbDevicesTable = ({ onSelectedUsbDevice }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();
	const { data, isLoading, isValidating } = useSiteControllers(
		getUsbDevicesKey({
			customerId,
			systemId,
			siteId,
		}),
		{
			refreshInterval: 60 * 1000, // 60 seconds
		}
	);

	return (
		<Table
			rowKey="controllerId"
			dataSource={data}
			onRow={(usbDevice) => ({
				onClick: () => {
					if (hasActionPermissions(user, userConstants.screens.USB_DEVICES, userConstants.actions.WRITE)) {
						onSelectedUsbDevice(usbDevice);
					}
				},
			})}
			loading={{
				spinning: isLoading || isValidating,
				indicator: <Spin active={true} />,
			}}
			columns={[
				{
					dataIndex: 'name',
					title: translate.byKey('name'),
					key: 'name',
					sorter: {
						compare: (usbA, usbB) => {
							return usbA.name.toLocaleLowerCase().localeCompare(usbB.name.toLocaleLowerCase());
						},
						multiple: 1,
					},
					defaultSortOrder: 'ascend',
					sortDirections: ['ascend', 'ascend', 'ascend'],
				},
				{
					dataIndex: 'type',
					title: translate.byKey('type'),
					key: 'type',
					render: (value) => translate.byKey(UsbTypesToTranslationKeys[value]),
				},
				{
					dataIndex: 'description',
					title: translate.byKey('description'),
					key: 'description',
				},
				{
					dataIndex: ['configuration', 'additionalSettings', 'configuration_status'],
					title: translate.byKey('status'),
					key: 'status',
					sorter: {
						compare: (usbA, usbB) => {
							const usbAConfigurationStatusLabel = translate.byKey(
								usbA.configuration?.additionalSettings?.configuration_status?.toLowerCase() === 'configured'
									? 'configured'
									: 'pending'
							);
							const usbBConfigurationStatusLabel = translate.byKey(
								usbB.configuration?.additionalSettings?.configuration_status?.toLowerCase() === 'configured'
									? 'configured'
									: 'pending'
							);

							return usbAConfigurationStatusLabel
								.toLocaleLowerCase()
								.localeCompare(usbBConfigurationStatusLabel.toLocaleLowerCase());
						},
						multiple: 2,
					},
					defaultSortOrder: 'ascend',
					sortDirections: ['ascend', 'descend', 'ascend'],
					render: (value) => translate.byKey(value?.toLowerCase() === 'configured' ? 'configured' : 'pending'),
				},
			]}
			pagination={false}
			scroll={getScrollHeightOptions()}
		/>
	);
};
