import accessPoint from '../../Doors/mappers/accessPoint';
import accessProfile from '../../AccessGroups/mappers/accessProfile';
import asset from './asset';
import authenticationProfile from './authenticationProfile';
import calendar from '../../Doors/mappers/calendar';
import controller from '../../Controllers/mappers/controller';
import { credential } from '~/components/features/people/mappers/credential';
import { credentialHolder } from '~/components/features/people/mappers/credentialHolder';
import peripheralDevice from '../../Doors/mappers/peripheralDevice';
import port from '../../Controllers/mappers/port';
import portal from '../../Doors/mappers/portal';
import schedule from '../../Doors/mappers/schedule';
import securityLevel from '../../../components/features/auth/mappers/securityLevel';
import specialDay from '../../Doors/mappers/specialDay';
import syncState from '../../Controllers/mappers/syncState';
import { user } from '../../../components/features/people/mappers/user';
import login from '../../../components/features/auth/mappers/login';

const mappers = {
	accessLog: 'customer/:customerId/system/:systemId/reports/access-log',
	accessPoint,
	accessPointsBySite: 'customer/:customerId/system/:systemId/site/:siteId/access-point',
	accessProfile,
	authenticationProfile,
	asset,
	auditLog: 'customer/:customerId/system/:systemId/audit-logs',
	bulkUserEnrollment: 'customer/:customerId/system/:systemId/bulk-user-enrollment/template',
	calendar,
	customer: 'customer',
	controller,
	controllersBySystem: 'customer/:customerId/system/:systemId/controller',
	credential,
	credentialHolder,
	deviceStatus: 'customer/:customerId/system/:systemId/site/:siteId/device-status/:deviceId',
	deviceStatusSystem: 'customer/:customerId/system/:systemId/device-status',
	deviceStatusSite: 'customer/:customerId/system/:systemId/site/:siteId/device-status',
	deviceLog: 'customer/:customerId/system/:systemId/reports/device-log',
	controllerOnboard:
		'customer/:customerId:/system/:systemId:/site/:siteId:/controller/:controllerId:/controller-onboard/:serialNumber:',
	controllerDeOnboard:
		'customer/:customerId:/system/:systemId:/site/:siteId:/controller/:controllerId:/controller-onboard',
	log: 'customer/:customerId:/system/:systemId:/site/:siteId:/log',
	login,
	peripheralDevice,
	port,
	portal,
	schedule,
	securityLevel,
	site: 'customer/:customerId:/system/:systemId:/site',
	specialDay,
	syncState,
	system: 'customer/:customerId:/system',
	user,
	credentialState: 'customer/:customerId/system/:systemId/credential/:credentialId/state',
	businessEntity: '/administration/business-entity/:businessEntityId/business-entity',
	administrator: 'customer/:customerId/administrator/:administratorId',
	reSync: 'customer/:customerId:/system/:systemId:/site/:siteId:/controller/:controllerId:/action',
};

export default mappers;
