import styled from '@emotion/styled';
import { Divider as ADivider } from 'antd';

const Divider = styled(ADivider)`
	.ant-divider-inner-text {
		color: ${(props) => props.theme.colors.gray[500]};
		font-size: 14px;
		min-width: unset;
	}
`;

export default Divider;
