import { useLocale } from '~/screens/_shared/AppLocale';
import React, { useEffect, useState } from 'react';
import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import * as moment from 'moment';
import { Col, Row, Select, Slider, Switch } from 'antd';
import { Input, Item } from '~/components';
import { isAperioDoor } from '~/screens/Doors/hooks/doorDefaults';
import ledTypes from '~/screens/Doors/ledTypes.json';
import volumeMarks from '~/screens/Doors/volumeMarks.json';
import { css } from '@emotion/core';
import dosTypes from '~/screens/Doors/dosTypes.json';
import inputModes from '~/screens/Doors/inputModes.json';
import { buildOptions, isAperioDevice } from '~/screens/Doors/shared';
import { getLimits } from '~/screens/_shared/entitlement';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const DOOR_CONTACT_TYPE = {
	NONE: '10',
	DOS: '00',
};

export function ReaderInputs({ readers, fieldPrefix, form, doorType, disabled, step }) {
	const {
		data: { system, site },
	} = useCurrentSystemSite();
	const { translate } = useLocale();

	fieldPrefix = fieldPrefix || '';
	const otherFieldPrefix = fieldPrefix.includes('[0]')
		? fieldPrefix.replace('[0]', '[1]')
		: fieldPrefix.replace('[1]', '[0]');

	const fixedAddressName = fieldPrefix + 'fixedAddress';
	const doorOpenDurationName = fieldPrefix + 'doorOpenDuration';
	const doorContactModeName = fieldPrefix + 'input1DOSMode';
	const doorContactTypeName = fieldPrefix + 'input1DOSType';
	const offlineSyncName = fieldPrefix + 'offlineSync';

	const [selectedAddress, setSelectedAddress] = useState(form.getFieldValue(fixedAddressName));
	const [fixedAddressLabel, setFixedAddressLabel] = useState('serial_number_fixed_address_label');
	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);
	const [isUpdaterReader, setIsUpdaterReader] = useState(false);
	const [hidFixedAddresses, setHidFixedAddresses] = useState([]);
	const [aperioFixedAddresses, setAperioFixedAddresses] = useState([]);

	const [doorContactType, setDoorContactType] = useState(DOOR_CONTACT_TYPE.NONE);

	function getExcludedAddresses() {
		return [form.getFieldValue(`${otherFieldPrefix}fixedAddress`)].filter((address) => !!address);
	}

	function getSelectedAddresses() {
		return [
			form.getFieldValue(`${fieldPrefix}fixedAddress`),
			form.getFieldValue(`${otherFieldPrefix}fixedAddress`),
		].filter((address) => !!address);
	}

	const isDoorContactTypeDOS = doorContactType === DOOR_CONTACT_TYPE.DOS;
	const isAddressSelected = Boolean(selectedAddress);
	const isDoorOpenDurationSliderEnabled = isDoorContactTypeDOS && isAddressSelected;

	useEffect(() => {
		if (system.systemId && site.siteId) {
			readers = readers || [];

			getAssignedReaders(
				{
					siteId: site.siteId,
					params: { assignedToPortal: 'true', 'detail-level': 'FULL' },
				},
				{ expiry: moment().add(1, 'minutes'), shared: true }
			).then((assignedReaders) => {
				const addressesToExclude = getExcludedAddresses();
				const selectedAddresses = getSelectedAddresses();
				const filteredOptions = Array.isArray(readers)
					? readers.map((option) => {
							const readersForController = assignedReaders
								.filter(
									(r) =>
										r.mapping?.port?.controllerId === option?.controller?.controllerId &&
										r.configuration?.additionalSettings?.fixedAddress
								)
								.map((reader) => {
									return { fixedAddress: reader.configuration.additionalSettings.fixedAddress };
								});

							const hasSelectedOptions = option.readers
								.concat(readersForController)
								.some((val) => selectedAddresses.includes(val.fixedAddress));
							const newReaders = option.readers.filter((val) => !addressesToExclude.includes(val.fixedAddress));
							return { controller: option.controller, readers: newReaders, hasSelectedOptions };
					  })
					: readers;

				const hidFixedAddresses = filteredOptions?.map((controller) => {
					const newReaders = controller.readers.filter((indent) => !indent.fixedAddress.startsWith('17'));
					return {
						controller: controller.controller,
						readers: newReaders,
						hasSelectedOptions: controller.hasSelectedOptions,
					};
				});

				const aperioFixedAddresses = filteredOptions?.map((controller) => {
					const newReaders = controller.readers.filter((indent) => indent.fixedAddress.startsWith('17'));
					return {
						controller: controller.controller,
						readers: newReaders,
						hasSelectedOptions: controller.hasSelectedOptions,
					};
				});

				setIsUpdaterReader(
					hidFixedAddresses
						.concat(aperioFixedAddresses)
						.find((data) => data.readers.find((reader) => reader.fixedAddress === value))?.controller?.protocol ===
						'passthrough'
				);

				setHidFixedAddresses(hidFixedAddresses);
				setAperioFixedAddresses(aperioFixedAddresses);
			});
			if (readers?.every((data) => data.controller?.protocol === 'passthrough')) {
				setFixedAddressLabel('serial_number_label');
			} else if (readers?.every((data) => data.controller?.protocol !== 'passthrough')) {
				setFixedAddressLabel('fixed_address');
			}

			const value = form.getFieldValue(fixedAddressName);

			if (!form.getFieldValue(offlineSyncName)) {
				form.setFieldsValue({ [offlineSyncName]: false });
			}

			if (selectedAddress !== value) {
				setSelectedAddress(value);
			}

			const doorContactTypeValue = form.getFieldValue(doorContactTypeName);
			setDoorContactType(doorContactTypeValue);
		}
	}, [readers, form, doorType, doorContactTypeName, system.systemId, site.siteId]);

	function onFixedAddressChanged(fixedAddress) {
		if (fixedAddress) {
			setSelectedAddress(fixedAddress);

			const currentController = hidFixedAddresses
				.concat(aperioFixedAddresses)
				.find((data) => data.readers.find((reader) => reader.fixedAddress === fixedAddress));

			form.setFieldsValue({
				[fieldPrefix + 'controllerName']: currentController.controller?.name,
				[fieldPrefix + 'controllerId']: currentController.controller?.controllerId,
			});

			setIsUpdaterReader(
				hidFixedAddresses
					.concat(aperioFixedAddresses)
					.find((data) => data.readers.find((reader) => reader.fixedAddress === fixedAddress))?.controller?.protocol ===
					'passthrough'
			);
		} else {
			setIsUpdaterReader(false);
		}
	}

	function readerOptions(hidFixedAddresses, aperioFixedAddresses) {
		const addressesToExclude = getExcludedAddresses();
		if (doorType === 'hid_online') {
			return buildOptions(hidFixedAddresses, addressesToExclude);
		}

		if (doorType === 'aperio_online') {
			return buildOptions(aperioFixedAddresses, addressesToExclude);
		}

		return buildOptions(hidFixedAddresses.concat(aperioFixedAddresses), addressesToExclude);
	}

	return (
		<div data-test="reader-form-root">
			{isAperioDoor(doorType) && (
				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={24}>
						<Item name={fieldPrefix + 'isEntry'} label={translate.byKey('reader_type_label')}>
							<Select placeholder={translate.byKey('reader_type_placeholder')} disabled={disabled}>
								<Select.Option key="entry" value={true}>
									{translate.byKey('entry_reader_tab_title')}
								</Select.Option>
								<Select.Option key="exit" value={false}>
									{translate.byKey('exit_reader_tab_title')}
								</Select.Option>
							</Select>
						</Item>
					</Col>
				</Row>
			)}

			<Row gutter={[12, 24]} css={rowStyles}>
				<Col span={24}>
					<Item name={fixedAddressName} label={translate.byKey(fixedAddressLabel)}>
						<Select
							disabled={
								disabled || localStorage.getItem('uiEntitlementsEnabled') === 'true'
									? !getLimits(system, doorType)?.isActive
									: false
							}
							allowClear={true}
							data-test="reader-form-device-dropdown"
							placeholder={translate.byKey('select_a_device')}
							onChange={onFixedAddressChanged}
							options={readerOptions(hidFixedAddresses, aperioFixedAddresses)}
							showSearch
							filterOption={(input, option) => {
								const { label } = option;
								if (label) {
									return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}
							}}
						/>
					</Item>
				</Col>
			</Row>

			{isUpdaterReader && (
				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={24}>
						<Item
							name={offlineSyncName}
							label={translate.byKey('manage_offline_data_v2')}
							valuePropName="checked"
							initialValue={false}
						>
							<Switch checkedChildren="YES" unCheckedChildren="NO" disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
				</Row>
			)}

			<div style={{ display: isAperioDevice(selectedAddress, doorType) ? 'none' : 'block' }}>
				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={12}>
						<Item name={fieldPrefix + 'allowedLedType'} label={translate.byKey('allowed_led_type')}>
							<Select placeholder={translate.byKey('select_an_led_type')} disabled={disabled || !selectedAddress}>
								{ledTypes.map((type) => {
									return (
										<Select.Option key={type.value} value={type.value}>
											{translate.byValue(type.name)}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
					<Col span={12}>
						<Item name={fieldPrefix + 'deniedLedType'} label={translate.byKey('denied_led_type')}>
							<Select placeholder={translate.byKey('select_an_led_type')} disabled={disabled || !selectedAddress}>
								{ledTypes.map((type) => {
									return (
										<Select.Option key={type.value} value={type.value}>
											{translate.byValue(type.name)}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
				</Row>

				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={12}>
						<Item name={fieldPrefix + 'allowedLedDuration'} label={translate.byKey('allowed_led_duration_seconds')}>
							<Slider min={0} max={10} step={0.1} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
					<Col span={12}>
						<Item name={fieldPrefix + 'deniedLedDuration'} label={translate.byKey('denied_led_duration_seconds')}>
							<Slider min={0} max={10} step={0.1} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
				</Row>

				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={12}>
						<Item name={fieldPrefix + 'allowedBuzVol'} label={translate.byKey('allowed_buzzer_volume')}>
							<Slider min={0} max={4} marks={volumeMarks} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
					<Col span={12}>
						<Item name={fieldPrefix + 'deniedBuzVol'} label={translate.byKey('denied_buzzer_volume')}>
							<Slider min={0} max={4} marks={volumeMarks} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
				</Row>

				<Row gutter={[12, 24]} css={rowStyles}>
					<Col
						span={12}
						css={css`
							padding-left: 10px;
							padding-right: 10px;
						`}
					>
						<Item name={fieldPrefix + 'allowedBuzDuration'} label={translate.byKey('allowed_buzzer_duration_seconds')}>
							<Slider min={0} max={10} step={0.1} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
					<Col
						span={12}
						css={css`
							padding-left: 10px;
							padding-right: 10px;
						`}
					>
						<Item name={fieldPrefix + 'deniedBuzDuration'} label={translate.byKey('denied_buzzer_duration_seconds')}>
							<Slider min={0} max={10} step={0.1} disabled={disabled || !selectedAddress} />
						</Item>
					</Col>
				</Row>
			</div>

			<Row gutter={[12, 24]} css={rowStyles}>
				<Col
					span={12}
					css={css`
						padding-left: 10px;
						padding-right: 10px;
					`}
				>
					<Item name={fieldPrefix + 'strikeDuration'} label={translate.byKey('strike_duration_seconds')}>
						<Slider min={0} max={10} step={0.1} disabled={disabled || !selectedAddress} />
					</Item>
				</Col>
				<Col
					span={12}
					css={css`
						padding-left: 10px;
						padding-right: 10px;
					`}
				>
					<Item
						name={fieldPrefix + 'doorOpenDuration'}
						label={translate.byKey('door_open_duration_seconds')}
						rules={[
							{
								warningOnly: true,
								validator: async (_, value) => {
									if (value === 0 && doorContactType === DOOR_CONTACT_TYPE.DOS) {
										throw new Error(translate.byKey('door_open_detection_disabled_warning'));
									}
								},
							},
						]}
					>
						<Slider min={0} max={10} step={0.1} disabled={disabled || !isDoorOpenDurationSliderEnabled} />
					</Item>
				</Col>
			</Row>
			<div style={{ display: isAperioDevice(selectedAddress, doorType) ? 'none' : 'block' }}>
				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={12}>
						<Item name={fieldPrefix + 'input1DOSType'} label={translate.byKey('door_contact_type')}>
							<Select
								onChange={(value) => {
									if (value === DOOR_CONTACT_TYPE.NONE) {
										form.setFieldsValue({ [doorOpenDurationName]: 0 });
										form.setFieldsValue({ [doorContactModeName]: '1' });
									} else {
										form.setFieldsValue({ [doorOpenDurationName]: 5 });
									}

									setDoorContactType(value);
								}}
								placeholder={translate.byKey('select_a_type')}
								disabled={disabled || !selectedAddress}
							>
								{dosTypes.map((type) => {
									return (
										<Select.Option key={type.value} value={type.value}>
											{translate.byValue(type.name)}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
					<Col span={12}>
						<Item name={fieldPrefix + 'input1DOSMode'} label={translate.byKey('door_contact_mode')}>
							<Select
								placeholder={translate.byKey('select_a_mode')}
								disabled={disabled || !isDoorOpenDurationSliderEnabled}
							>
								{inputModes.map((type) => {
									return (
										<Select.Option key={type.value} value={type.value}>
											{translate.byValue(type.name)}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
				</Row>

				<Row gutter={[12, 24]} css={rowStyles}>
					<Col span={24}>
						<Item name={fieldPrefix + 'input2RTEMode'} label={translate.byKey('rte_mode')}>
							<Select placeholder={translate.byKey('select_a_mode')} disabled={disabled || !selectedAddress}>
								{inputModes.map((type) => {
									return (
										<Select.Option key={type.value} value={type.value}>
											{translate.byValue(type.name)}
										</Select.Option>
									);
								})}
							</Select>
						</Item>
					</Col>
				</Row>
			</div>

			<Item name={fieldPrefix + 'version'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'accessPointId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'isEntry'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'portalName'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'portId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'controllerId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'controllerName'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'portalId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'peripheralDeviceId'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'logicalDevices'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'deviceInfo'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
			<Item name={fieldPrefix + 'configuration'} shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</div>
	);
}

const rowStyles = css({
	marginBottom: '24px',
});
