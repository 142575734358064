import { Modal } from '~/components';
import { translator as translate } from '~/screens/_shared/AppLocale';

/**
 * System Limit constants
 */
export const systemLimits = {
	ACCESS_GROUPS_PER_SITE: 32, // Limit is 8 per site. Api ability to filter is req.
	ACCESS_GROUPS_PER_PERSON: 1,
	CARDS_PER_PERSON: 3,
	DOORS_PER_CONTROLLER: 32,
	PEOPLE_PER_SYSTEM: 2,
	SPECIAL_DAYS_PER_SYSTEM: 18,
	BlockList: 100,
	validationPeriod: 30,
};

export const checkIfSystemLimitsReached = (limit, totalCount) => limit && totalCount >= limit;

export function isExpiredLicense() {
	Modal.info({
		title: translate.byKey('holidays_limit_reached'),
		content: translate.byKeyFormatted('a_limit_of_on_system_has_been_reached_formatted'),
		okText: translate.byKey('ok'),
	});
}
