import { getReadersKey } from '~/hooks/data/readers/useReaders';

export const getReadersForDoorsDefaultKey = ({ customerId, systemId, siteId }) =>
	getReadersKey({
		customerId,
		systemId,
		siteId,
		pageSize: 1000,
		pageNumber: 1,
		assignedToPortal: true,
	});
