import { ConfigProvider as Provider, Empty } from 'antd';
import React from 'react';

import startCase from 'lodash/startCase';
import { getDefaultLanguageCode } from './services/translator/defaultLanguageCode';
import { getLocale } from './services/translator/utils';
import { useLocale } from '~/screens/_shared/AppLocale';

const validateMessages = {
	required: (name) => {
		return `${startCase(name)} is required.`;
	},
	whiteSpace: (name) => {
		return `${startCase(name)} cannot be empty.`;
	},
};

export const validationRegex = {
	email:
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
};

const getLocaleWithOverrides = (t) => {
	const locale = getLocale(getDefaultLanguageCode());

	return {
		...locale,
		DatePicker: {
			...locale.DatePicker,
			lang: {
				...locale.DatePicker.lang,
				ok: t.byKey('apply'),
			},
		},
		Table: {
			...locale.Table,
			filterConfirm: t.byKey('search'),
			filterReset: t.byKey('reset'),
			triggerDesc: t.byKey('sort_in_descending_order'),
			triggerAsc: t.byKey('sort_in_ascending_order'),
		},
	};
};

const emptyRenderer = (t) => () => (
	<Empty description={t.byKey('no_results')} image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ fontSize: 18 }} />
);

const ConfigProvider = ({ children }) => {
	const { translate } = useLocale();
	const localeWithOverrides = getLocaleWithOverrides(translate);

	return (
		<Provider form={{ validateMessages }} locale={localeWithOverrides} renderEmpty={emptyRenderer(translate)}>
			{children}
		</Provider>
	);
};

export default ConfigProvider;
