import useSWR from 'swr';
import { useRef } from 'react';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import mappers from '~/screens/_shared/mappers';
import { method } from '~/screens/_shared/useApi/apiConstants';
import { omitBy } from 'lodash/object';
import { DEFAULT_PAGE_NUMBER } from '../assets/useAssets';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';

const defaultConfig = {
	revalidateOnFocus: false,
};

const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

const separator = '**';

export const useDoors = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.portal,
				method: method.list,
			},
			handleDefaultApiError
		)
	);

	const fetcher = async (key) => {
		const [, , , pageSize, pageNumber] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': pageSize,
					page: pageNumber,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getDoorsKey = ({
	customerId,
	systemId,
	siteId,
	pageSize = DEFAULT_PAGE_SIZE,
	pageNumber = DEFAULT_PAGE_NUMBER,
	name = '',
}) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${name}${separator}\
doors`
		: null;
