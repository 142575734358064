import { actions, screens } from '~/screens/_shared/userRoleConstants/constants';

export const ownerPermissions = [
	{
		screen: screens.SETTINGS,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.ACCESS_GROUPS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.HOLIDAYS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.ACCESS_SCHEDULES,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.ACCESS_PROFILES,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.CARDS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.PEOPLE,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.SITES,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.CONTROLLERS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.CONTROLLER_ONBOARD,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.REPORTS,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.REPORT_ACCESS,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.REPORT_STATUS,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.REPORT_AUDIT,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.LIVE,
		actionsSupported: [actions.READ],
	},
	{
		screen: screens.READER,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.DOORS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.DOOR_MODES,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.DOOR_GROUPS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.CARD_FORMAT,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.BLOCKLIST,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.ADD_SITE,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.SYSTEM_SETTINGS,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
	{
		screen: screens.USB_DEVICES,
		actionsSupported: [actions.CREATE, actions.READ, actions.WRITE, actions.DELETE],
	},
];
