import { problemCodes, method } from './apiConstants';
import { Modal } from '~/components';
import { translator } from '~/screens/_shared/AppLocale';
import { showErrorToast, showWarningToast } from '~/screens/_shared/toast';
import mappers from '~/screens/_shared/mappers';
import { getKey } from './utils/formatResponse';
import { getUserAuthCacheData, logout } from '~/components/features/auth/hooks/useUserAuthData';

const handleKnownErrors = (response, apiEntity, apiMethod) => {
	if (response.status) {
		switch (response.status) {
			case 401:
				const user = getUserAuthCacheData();
				if (user.tokens.length > 0) {
					showWarningToast({
						message: translator.byKey('your_session_has_expired_redirecting_to_Login'),
					});
					logout();
					return true;
				}
				return true;
			case 403:
				if (getKey(apiEntity) === getKey(mappers.controller) && apiMethod === method.list) {
					showWarningToast({
						key: translator.byKey('general_permission_issue'),
						message: translator.byKey('controller_permission_issue'),
					});
					return true;
				} else if (apiMethod === method.list || apiMethod === method.get) {
					showWarningToast({
						message: translator.byKey('general_permission_issue'),
					});
					return true;
				}
				return false;
			case 404:
				// Using auth as the method here to avoid confusion with creating a user
				if (getKey(apiEntity) === getKey(mappers.login) && apiMethod === method.post) {
					let message = translator.byKey('incorrect_client_id_or_client_secret');
					if (response.message === 'Network Error') {
						message = translator.byKey('authentication_blocked_by_cors_please_try_refreshing_the_page');
					}
					showWarningToast({
						message,
					});
					return true;
				} else if (getKey(apiEntity) === getKey(mappers.controllerDeOnboard)) {
					Modal.warn({
						title: translator.byKey('de_boarding_error'),
						content: translator.byKey(
							'the_controller_failed_to_deboard_it_is_possible_that_it_has_already_been_allocated_to_a_different_site'
						),
					});
					return true;
				}
				return false;
			case 409:
				return (
					getKey(apiEntity) === getKey(mappers.controllerOnboard) || getKey(apiEntity) === getKey(mappers.credential)
				);
			default:
				return false;
		}
	}
	return false;
};

const handleServerError = (response) => {
	if (response.status === 500 && response.data && response.data.message === 'No message available') {
		console.log('No reason 500 Error, api will handle retrying');
		return true;
	} else {
		showErrorToast({
			message: translator.byKey('an_error_occurred_on_the_server_when_attempting_to_make_a_request'),
		});
	}
};

const handleTimeoutError = () => {
	showErrorToast({
		message: translator.byKey('the_request_timed_out_while_waiting_for_a_response_from_the_server'),
	});
};

const handleConnectionError = () => {
	showErrorToast({
		message: translator.byKey('the_server_is_unavailable_please_try_again'),
	});
};

const handleNetworkError = () => {
	showErrorToast({
		message: translator.byKey('a_network_error_occurred_please_check_your_internet_connection'),
	});
};

const handleClientError = (response, method) => {
	const urlForCreatingAdminOnPeopleForm = 'administration/administrator';
	const urlForCreatingUsbOnUsbForm = '/controller';

	if (
		method === 'post' &&
		(response.config.url === urlForCreatingAdminOnPeopleForm ||
			response.config.url.indexOf(urlForCreatingUsbOnUsbForm) > -1)
	) {
		return;
	} else {
		showErrorToast({
			message: response.message || translator.byKey('an_request_attempt_was_made_to_a_resource_that_does_not_exist'),
		});
	}
};

export default function handleDefaultApiError(apiError) {
	const { response, entity: apiEntity, method: apiMethod } = apiError;
	const isHandled = handleKnownErrors(response, apiEntity, apiMethod);

	if (!isHandled) {
		switch (response.problem) {
			case problemCodes.CLIENT_ERROR:
				handleClientError(response, apiMethod);
				break;
			case problemCodes.SERVER_ERROR:
				handleServerError(response);
				break;
			case problemCodes.TIMEOUT_ERROR:
				handleTimeoutError();
				break;
			case problemCodes.CONNECTION_ERROR:
				handleConnectionError();
				break;
			case problemCodes.NETWORK_ERROR:
				handleNetworkError();
				break;
			default:
				break;
		}
	}
}
