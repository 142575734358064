import { getReadableName } from '~/screens/_shared/getReadableName';
import { StatusTransactionEventNames, StatusTransactionEventTypes } from '~/constants/transactions';
import { translator } from '~/screens/_shared/AppLocale';

export const defaultControllerId = '';
export const defaultPageNumber = 1;
export const defaultEventName = '';
export const defaultReasonCode = '';
export const defaultSiteName = '';

export const eventSourceRender = (value, row) => {
	if (value && value !== 'blank') {
		// RTE / DOS, etc. have an eventSource property. Use it.
		return value.sourceName;
	} else if (row.accessPointName) {
		// Access events don't have an eventSource property. They show accessPointName.
		return row.accessPointName;
	} else if (row.controllerName) {
		// Event didn't come from a reader. E.g. Heartbeat. Show controller name.
		return getReadableName(row.controllerName);
	} else {
		return 'N/A';
	}
};

export const getStatusTransactionEventNameFilter = () =>
	Object.values(StatusTransactionEventNames)
		.sort((a, b) => (a > b ? 1 : -1))
		.map((eventName) => ({ label: translator.byKey(eventName), value: eventName }));

export const getStatusTransactionReasonCodeFilter = () =>
	Object.values(StatusTransactionEventTypes)
		.sort((a, b) => (a > b ? 1 : -1))
		.map((reasonCode) => ({ label: translator.byKey(reasonCode), value: reasonCode }));
