import { useRef } from 'react';
import useSWR from 'swr';

import mappers from '~/screens/_shared/mappers';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const usePortals = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.portal,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async () => {
		return ref.current.fetchData({
			params: {
				'detail-level': 'FULL',
				'page-size': 1000,
			},
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getPortalsKey = ({ customerId, systemId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
portals`
		: null;
