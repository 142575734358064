import React, { useEffect, useState } from 'react';
import { Checkbox, Divider } from 'antd';
import { css } from '@emotion/core';

import { ControllerTypes } from '~/constants/ControllerTypes';
import { getReadableName } from '~/screens/_shared/getReadableName';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getSystemControllersKey, useSystemControllers } from '~/hooks/data/controllers/useSystemControllers';
import { SearchFilterPanel } from '~/components/shared/filters/panels/SearchFilterPanel/SearchFilterPanel';
import { ResetApplyFilterPanel } from '~/components/shared/filters/panels/ResetApplyFilterPanel/ResetApplyFilterPanel';
import { BodyFilterPanel } from '~/components/shared/filters/panels/BodyFilterPanel/BodyFilterPanel';
import { FilterItem } from '~/components/shared/filters/FilterItem/FilterItem';
import { LoadingFilterPanel } from '~/components/shared/filters/panels/LoadingFilterPanel/LoadingFilterPanel';
import { NoResultsFilterPanel } from '~/components/shared/filters/panels/NoResultsFilterPanel/NoResultsFilterPanel';
import { filterTextByPartialText } from '~/utils/filter';
import { FilterItemCategory } from '~/components/shared/filters/FilterItemCategory/FilterItemCategory';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const SystemControllerFilterDropdown = ({ antdFilterProps }) => {
	const {
		setSelectedKeys: setAntdSelectedKeys,
		selectedKeys: antdSelectedKeys,
		confirm,
		visible,
		clearFilters,
	} = antdFilterProps;
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const systemControllersKey =
		customerId && systemId
			? getSystemControllersKey({
					customerId,
					systemId,
			  })
			: null;
	const { data: controllers, isLoading: isLoadingControllers } = useSystemControllers(systemControllersKey);
	const [searchValue, setSearchValue] = useState('');
	const [checkedValue, setCheckedValue] = useState(antdSelectedKeys[0] || null);
	const resetDisabled = !Boolean(antdSelectedKeys[0]);
	const applyDisabled =
		checkedValue === antdSelectedKeys[0] || (!Boolean(checkedValue) && !Boolean(antdSelectedKeys[0]));

	useEffect(() => {
		if (visible && antdSelectedKeys.length) {
			setCheckedValue(antdSelectedKeys[0]);
		} else if (!visible) {
			setCheckedValue(null);
		}
	}, [visible, antdSelectedKeys, setCheckedValue]);

	const onSearch = (event) => {
		setSearchValue(event.target.value);
	};

	const onSearchClear = () => {
		setSearchValue('');
	};

	const onItemCheck = (newValues) => {
		setCheckedValue(newValues[newValues.length - 1]);
	};

	const onReset = () => {
		clearFilters({ confirm: true, closeDropdown: true });
	};

	const onApply = () => {
		setAntdSelectedKeys(checkedValue ? [checkedValue] : []);
		confirm();
	};

	const onEnterPress = (event) => {
		if (event.key === 'Enter' && !applyDisabled) {
			onApply();
		}
	};

	const filteredControllers = (
		controllers?.filter(
			({ type }) => type === ControllerTypes.ACCESS_CONTROL_UNIT || type === ControllerTypes.CONTROLLER_UPDATER
		) || []
	).filter(({ name }) => filterTextByPartialText(getReadableName(name), searchValue));
	const filteredUsbDevices = (
		controllers?.filter(
			({ type }) => type === ControllerTypes.DESKTOP_UPDATER || type === ControllerTypes.CLOUD_UPDATER
		) || []
	).filter(({ name }) => filterTextByPartialText(name, searchValue));

	const isControllersDataExisting = Boolean(filteredControllers.length);
	const isUsbDevicesDataExisting = Boolean(filteredUsbDevices.length);

	return (
		<div style={{ minWidth: '300px' }} onKeyDown={onEnterPress}>
			<SearchFilterPanel value={searchValue} onSearch={onSearch} onClear={onSearchClear} />

			<Divider style={{ margin: '0px' }} />

			<BodyFilterPanel>
				{isLoadingControllers ? <LoadingFilterPanel /> : null}

				{!isLoadingControllers && !isControllersDataExisting && !isUsbDevicesDataExisting ? (
					<NoResultsFilterPanel />
				) : null}

				{!isLoadingControllers && (isControllersDataExisting || isUsbDevicesDataExisting) ? (
					<Checkbox.Group onChange={onItemCheck} value={[checkedValue]} style={{ width: '100%' }}>
						{isControllersDataExisting ? (
							<>
								<FilterItemCategory>{translate.byKey('connected_devices')}</FilterItemCategory>

								{filteredControllers.map(({ controllerId, name }) => (
									<FilterItem key={controllerId}>
										<Checkbox
											css={css`
												.ant-checkbox-inner {
													border-radius: 50%;
												}
											`}
											value={controllerId}
										>
											{getReadableName(name)}
										</Checkbox>
									</FilterItem>
								))}
							</>
						) : null}

						{isUsbDevicesDataExisting ? (
							<>
								<FilterItemCategory>{translate.byKey('usb_devices')}</FilterItemCategory>

								{filteredUsbDevices.map(({ controllerId, name }) => (
									<FilterItem key={controllerId}>
										<Checkbox
											css={css`
												.ant-checkbox-inner {
													border-radius: 50%;
												}
											`}
											value={controllerId}
										>
											{name}
										</Checkbox>
									</FilterItem>
								))}
							</>
						) : null}
					</Checkbox.Group>
				) : null}
			</BodyFilterPanel>

			<Divider style={{ margin: '0px' }} />

			<ResetApplyFilterPanel
				resetDisabled={resetDisabled}
				applyDisabled={applyDisabled}
				onReset={onReset}
				onApply={onApply}
			/>
		</div>
	);
};
