import { css } from '@emotion/core';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const AuthLayout = () => {
	const {
		data: { tokens },
	} = useUserAuthData();

	return (
		<main css={authStyles}>
			{tokens?.length ? <Navigate to="/live" replace /> : null}
			<Outlet />
		</main>
	);
};

const authStyles = css({
	display: 'flex',
	height: '100svh',
	width: '100vw',
});
