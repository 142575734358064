import React, { useEffect } from 'react';
import { Button, Popover } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { WeeklyCalendar } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar';
import { adjustAPISchedulesToWeekPeriods } from '~/utils/access-schedule/AccessSchedule.utils';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { antdToApiSortModes } from '~/utils/sort';
import { getDefaultAccessSchedulesKey } from '~/components/features/access-schedules/AccessSchedules.utils';
import { DEFAULT_ACCESS_SCHEDULE_ID } from '~/constants/AccessSchedules';
import { LoadingIcon } from '~/components/shared/LoadingIcon/LoadingIcon';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AccessSchedulesTable = ({ onRowClick }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const { queryParams, setQueryParams, clearAllQueryParams } = useQueryParams();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		data: accessSchedules,
		isLoading,
		isValidating,
	} = useAccessSchedules(
		getDefaultAccessSchedulesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
			sortBy: queryParams?.sortBy,
			sortDirection: queryParams?.sortDirection,
		})
	);

	useEffect(() => {
		clearAllQueryParams();

		return () => {
			clearAllQueryParams();
		};
	}, [siteId, systemId]);

	// To handle deletion on the last page with 1 item
	useEffect(() => {
		const isDataEmptyForPageButOverallDataExists =
			!Boolean(accessSchedules?.accessSchedules) && accessSchedules?.totalRecords > 0;

		if (isDataEmptyForPageButOverallDataExists) {
			setQueryParams({
				pageNumber: parseInt(queryParams.pageNumber) - 1,
			});
		}
	}, [accessSchedules?.accessSchedules, accessSchedules?.totalRecords, queryParams.pageNumber]);

	const onTableChange = (pagination, filters, sorter) => {
		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: pagination.current,
			name: filters?.name?.[0] || '',
			sortBy: sorter.columnKey,
			sortDirection: antdToApiSortModes[sorter.order],
		});
	};

	return (
		<TableWithFiltersSection
			rowKey="accessScheduleId"
			dataSource={accessSchedules?.accessSchedules}
			loading={{
				spinning: isLoading || isValidating,
				indicator: <LoadingIcon />,
			}}
			onChange={onTableChange}
			onRow={(accessSchedule) => ({
				onClick: () => {
					if (
						hasActionPermissions(user, userConstants.screens.ACCESS_SCHEDULES, userConstants.actions.WRITE) &&
						accessSchedule.accessScheduleId !== DEFAULT_ACCESS_SCHEDULE_ID
					) {
						onRowClick(accessSchedule.accessScheduleId);
					}
				},
			})}
			columns={[
				{
					dataIndex: 'name',
					render: (_, accessSchedule) =>
						accessSchedule.accessScheduleId === DEFAULT_ACCESS_SCHEDULE_ID
							? translate.byKey('default_access_schedule_name')
							: accessSchedule.name,
					title: translate.byKey('name'),
					sorter: true,
					defaultSortOrder: 'ascend',
					sortDirections: ['ascend', 'descend', 'ascend'],
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: queryParams?.name ? [queryParams.name] : null,
					filterIcon: <SearchFilterIcon />,
				},
				{
					dataIndex: 'allowAccessOnHolidays',
					title: translate.byKey('allow_access_on_holidays'),
					render: (value) => (value ? <CheckCircleOutlined /> : null),
				},
				{
					title: translate.byKey('overview'),
					render: ({ schedules }) => (
						<Popover
							trigger="click"
							onClick={(event) => event.stopPropagation()}
							content={() => (
								<div style={{ width: '250px' }}>
									<WeeklyCalendar
										periods={adjustAPISchedulesToWeekPeriods(schedules)}
										hourStep={8}
										rowHeight={20}
										showPeriodNumber={false}
									/>
								</div>
							)}
						>
							<Button type="ghost">{translate.byKey('periods')}</Button>
						</Popover>
					),
				},
			]}
			pagination={{
				current: parseInt(queryParams?.pageNumber) || 1,
				pageSize: queryParams?.pageSize || DEFAULT_PAGE_SIZE,
				total: accessSchedules?.totalRecords,
				showSizeChanger: true,
				position: ['bottomCenter'],
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
			}}
			scroll={getScrollHeightOptions()}
		/>
	);
};
