import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { EnabledFilter } from './EnabledFilter';

export const EnabledFilters = ({ enabledFilters = [], onRemove }) =>
	enabledFilters.map(({ name, value, clearable, tooltip, getId, getValueLabel, getNameLabel }) => (
		<EnabledFilter
			// if ID is object => toString will return object Object, and we will get duplicate key error
			key={typeof getId(value) === 'object' ? JSON.stringify(getId(value)) : `${getId(value)}`}
			closable={clearable}
			closeIcon={
				<Tooltip title={tooltip}>
					<CloseOutlined />
				</Tooltip>
			}
			onClose={(e) => {
				e.preventDefault();
				onRemove(getId(value));
			}}
		>
			{getNameLabel(name)}: {getValueLabel(value)}
		</EnabledFilter>
	));
