import { Tag, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { formatAssetName } from '~/utils/asset/asset';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;

function mapParents(asset) {
	const mainParent = asset.mainParent;
	const additionalParents = asset.additionalParents;

	asset.parentAssetIds = [];

	if (mainParent) {
		asset.parentAssetIds.push(mainParent.assetId);
	}

	if (Array.isArray(additionalParents)) {
		asset.parentAssetIds = asset.parentAssetIds.concat(additionalParents.map((parent) => parent.assetId));
	}

	return asset;
}

function shouldFormatName(asset) {
	return asset.type === 'BASIC_ASSET' || asset.type === 'ACCESS_AREA';
}

function mapTreeEntries(asset, allChildren, selectionMode = 'preferParent') {
	const treeItem = {
		title: shouldFormatName(asset) ? formatAssetName(asset.name) : asset.name,
		value: asset.assetId,
		children: allChildren
			.map((children) =>
				children
					.filter((child) => child.parentAssetIds.includes(asset.assetId))
					.map((child) => ({
						title: shouldFormatName(child) ? formatAssetName(child.name) : child.name,
						value: `${asset.assetId}__${child.assetId}`,
					}))
					.sort((a, b) => a.title.localeCompare(b.title))
			)
			.flat(),
	};

	if (selectionMode === 'preferParent' && treeItem.children.length > 0) {
		treeItem.children = treeItem.children.concat([
			{
				title: '',
				style: { display: 'none' },
				value: `${asset.assetId}__${asset.assetId}`,
			},
		]);
	}
	return treeItem;
}

function mapChildAssets(asset, allChildren) {
	return {
		...asset,
		childAssetIds: allChildren
			.map((children) =>
				children.filter((child) => child.parentAssetIds.includes(asset.assetId)).map((child) => child.assetId)
			)
			.flat(),
	};
}

function selectChildInstances(childId, selectedSet, allNonGroups) {
	const relevantNonGroup = allNonGroups.find((asset) => asset.assetId === childId);
	if (Array.isArray(relevantNonGroup?.parentAssetIds)) {
		if (relevantNonGroup.parentAssetIds.length === 0) {
			selectedSet.add(childId);
		} else {
			for (const parentId of relevantNonGroup.parentAssetIds) {
				selectedSet.add(`${parentId}__${childId}`);
			}
		}
	}
}

export function generateExternalValues(oldValues, newValues, oldExternalValues, allGroups, selectionMode) {
	const rootIds = new Set();
	const childIds = new Set();
	const possibleParentIds = new Set();

	const oldComposites = oldValues.filter((value) => value.includes('__'));

	for (const composite of oldComposites) {
		const [parentId, childId] = composite.split('__');
		if (parentId === childId) {
			continue;
		}

		const otherOldChildren = oldComposites.filter((other) => other.endsWith(childId));
		const otherNewChildren = newValues.filter((other) => other.includes('__') && other.endsWith(childId));

		if (otherOldChildren.length > otherNewChildren.length) {
			newValues = newValues.filter((value) => !value.endsWith(childId));
		}
	}

	for (const value of newValues) {
		if (value.includes('__')) {
			const [parentId, childId] = value.split('__');
			possibleParentIds.add(parentId);
			childIds.add(childId);
		} else {
			rootIds.add(value);
		}
	}

	if (selectionMode === 'preferParent') {
		for (const parentId of possibleParentIds) {
			const relevantGroup = allGroups.find((group) => group.assetId === parentId);
			if (
				Array.isArray(relevantGroup?.childAssetIds) &&
				relevantGroup.childAssetIds.every((childId) => childIds.has(childId))
			) {
				rootIds.add(parentId);
				for (const childId of relevantGroup.childAssetIds) {
					childIds.delete(childId);
				}
			}
		}
	}

	return [...rootIds, ...childIds];
}

function VisibleTag(props) {
	return <Tag {...props}>{props.label}</Tag>;
}

function InvisibleTag() {
	return <span />;
}

export function generateInternalValues(newValues, allGroups, allNonGroups, selectionMode) {
	const selectedSet = new Set();
	for (const item of newValues) {
		const relevantGroup = allGroups.find((group) => group.assetId === item);

		if (Array.isArray(relevantGroup?.childAssetIds)) {
			for (const childId of relevantGroup?.childAssetIds) {
				selectedSet.add(`${item}__${childId}`);
				selectChildInstances(childId, selectedSet, allNonGroups);
			}

			if (selectionMode === 'preferParent') {
				selectedSet.add(`${relevantGroup.assetId}__${relevantGroup.assetId}`);
			}
			continue;
		} else if (relevantGroup && selectionMode === 'preferParent') {
			selectedSet.add(`${relevantGroup.assetId}__${relevantGroup.assetId}`);
		}

		selectChildInstances(item, selectedSet, allNonGroups);
	}

	return [...selectedSet];
}

export const defaultAssetTypes = ['BASIC_ASSET', 'BASIC_ASSET_GROUP', 'ACCESS_AREA'];

// TODO
// Add support for selectionMode. preferParent will emit group IDs when all children are selected.
// preferChild will only emit child IDs, even if all children of a group are selected.

export const AssetTreeSelector = ({
	value,
	onChange,
	placeholder,
	selectionMode = 'preferParent',
	hideAssetsOn = 'emptyIncludes',
	assetsToInclude = [],
	types = [],
	handleChange = undefined,
	disabled = false,
}) => {
	const { translate } = useLocale();
	const [getAssets] = useListApi(mappers.asset);
	const { data: userAuth } = useUserAuthData();
	const [isBusy, setIsBusy] = useState(false);
	const [treeData, setTreeData] = useState([]);
	const [selectedValues, setSelectedValues] = useState([]);

	const [loadedData, setLoadedData] = useState({});
	const [localAssets, setLocalAssets] = useState([]);
	const [uniqueLabels, setUniqueLabels] = useState({});
	const [userCanEditDoors] = useState(
		!hasActionPermissions(userAuth, userConstants.screens.ACCESS_GROUPS, userConstants.actions.CREATE)
	);

	useEffect(() => {
		setUniqueLabels(
			selectedValues.reduce((results, key) => {
				const keys = Object.keys(results).map((otherKey) => otherKey.split('__').pop());

				if (keys.includes(key.split('__').pop() || '')) {
					results[key] = InvisibleTag;
				} else {
					results[key] = VisibleTag;
				}

				return results;
			}, {})
		);
	}, [selectedValues]);

	useEffect(() => {
		if (treeData.length === 0 || isBusy) {
			return;
		}
		value = value || [];

		const allGroups = (loadedData.accessAreaGroups?.assets || []).concat(loadedData.basicAssetGroups?.assets || []);
		const allNonGroups = (loadedData.accessAreas?.assets || []).concat(loadedData.basicAssets?.assets || []);

		const selectedSet = generateInternalValues(value, allGroups, allNonGroups, selectionMode);

		setSelectedValues([...selectedSet]);
	}, [value, treeData, isBusy, selectionMode]);

	function onTreeValueSelected(values) {
		if (treeData.length === 0 || isBusy) {
			return;
		}

		const allGroups = (loadedData.accessAreaGroups?.assets || []).concat(loadedData.basicAssetGroups?.assets || []);

		value = value || [];

		const finalValues = generateExternalValues(selectedValues, values, value, allGroups, selectionMode);
		if (value.length !== finalValues.length || !finalValues.every((id) => value.includes(id))) {
			onChange(finalValues);
			if (handleChange) {
				handleChange(finalValues.length);
			}
		}
	}

	async function getAllAssets() {
		let pendingAssets = [];

		const cache = {
			shared: true,
			expiry: moment().add(1, 'seconds'),
		};

		types = types || defaultAssetTypes;

		if (types.includes('BASIC_ASSET')) {
			pendingAssets.push(
				getAssets(
					{
						params: {
							'detail-level': 'FULL',
							'page-size': 1000,
							type: 'BASIC_ASSET',
							'sort-order': 'DESCENDING',
							'sort-by': 'offline_reference_id',
						},
					},
					cache
				)
			);
		} else {
			pendingAssets.push(Promise.resolve({ assets: [] }));
		}

		if (types.includes('BASIC_ASSET_GROUP')) {
			pendingAssets.push(
				getAssets(
					{
						params: {
							'detail-level': 'FULL',
							'page-size': 100,
							type: 'BASIC_ASSET_GROUP',
							'sort-order': 'DESCENDING',
							'sort-by': 'offline_reference_id',
						},
					},
					cache
				)
			);
		} else {
			pendingAssets.push(Promise.resolve({ assets: [] }));
		}

		if (types.includes('ACCESS_AREA')) {
			pendingAssets.push(
				getAssets(
					{
						params: {
							'detail-level': 'FULL',
							'page-size': 50,
							type: 'ACCESS_AREA',
							'sort-order': 'DESCENDING',
							'sort-by': 'offline_reference_id',
						},
					},
					cache
				)
			);
		} else {
			pendingAssets.push(Promise.resolve({ assets: [] }));
		}

		// I will leave this here encase we need Zones back
		if (types.includes('ACCESS_AREA_GROUP')) {
			pendingAssets.push(
				getAssets(
					{
						params: {
							'detail-level': 'FULL',
							'page-size': 10,
							type: 'ACCESS_AREA_GROUP',
							'sort-order': 'DESCENDING',
							'sort-by': 'offline_reference_id',
						},
					},
					cache
				)
			);
		} else {
			pendingAssets.push(Promise.resolve({ assets: [] }));
		}

		let [basicAssets, basicAssetGroups, accessAreas, accessAreaGroups] = await Promise.all(pendingAssets);

		basicAssets = Object.assign({}, basicAssets);
		basicAssetGroups = Object.assign({}, basicAssetGroups);
		accessAreas = Object.assign({}, accessAreas);
		accessAreaGroups = Object.assign({}, accessAreaGroups);

		basicAssets.assets = basicAssets.assets || [];
		accessAreas.assets = accessAreas.assets || [];

		const removeUnsupportedParents = (asset) => {
			asset = Object.assign({}, asset);
			if (!types.includes(asset.mainParent?.type)) {
				delete asset.mainParent;
			}

			asset.additionalParents = asset.additionalParents || [];
			asset.additionalParents = asset.additionalParents.filter((parent) => types.includes(parent.type));

			return asset;
		};

		basicAssets.assets = basicAssets.assets.map(removeUnsupportedParents);
		accessAreas.assets = accessAreas.assets.map(removeUnsupportedParents);

		if (types.includes('BASIC_ASSET')) {
			const assetIds = basicAssets.assets.concat(accessAreas.assets).map(({ assetId }) => assetId);

			basicAssets.assets = basicAssets.assets.concat(
				assetsToInclude
					.filter((other) => !assetIds.includes(other.assetId))
					.map((other) => ({
						...other,
						childAssetIds: [],
					}))
			);
		}

		basicAssets.assets = basicAssets.assets.filter(includeOrExcludeAsset).map(mapParents);
		accessAreas.assets = accessAreas.assets.filter(includeOrExcludeAsset).map(mapParents);

		basicAssetGroups.assets = basicAssetGroups.assets
			.map((parent) => mapChildAssets(parent, [basicAssets.assets, accessAreas.assets]))
			.filter((parent) => parent.childAssetIds.length > 0);

		accessAreaGroups.assets = accessAreaGroups.assets
			.map((parent) => mapChildAssets(parent, [basicAssets.assets, accessAreas.assets]))
			.filter((parent) => parent.childAssetIds.length > 0);

		const loadedData = { basicAssets, basicAssetGroups, accessAreas, accessAreaGroups };
		setLoadedData(loadedData);
		return loadedData;
	}

	function includeOrExcludeAsset(other) {
		if (assetsToInclude.length > 0) {
			return assetsToInclude.some((asset) => asset.assetId === other.assetId);
		}

		return hideAssetsOn !== 'emptyIncludes';
	}

	useEffect(() => {
		if (
			treeData.length === 0 ||
			localAssets.length === 0 ||
			localAssets.length !== assetsToInclude.length ||
			!localAssets.every((asset) => assetsToInclude.some((other) => asset.assetId === other.assetId))
		) {
			setIsBusy(true);
			setLocalAssets(assetsToInclude);
			getAllAssets()
				.then(({ basicAssets, basicAssetGroups, accessAreas, accessAreaGroups }) => {
					const assetsWithoutParents = basicAssets.assets.filter((asset) => asset.parentAssetIds.length === 0);
					const areasWithoutParents = accessAreas.assets.filter((asset) => asset.parentAssetIds.length === 0);
					const assetsWithParents = basicAssets.assets.filter((asset) => asset.parentAssetIds.length > 0);
					const areasWithParents = accessAreas.assets.filter((asset) => asset.parentAssetIds.length > 0);
					const byTitle = (a, b) => a.title.localeCompare(b.title);

					const ungroupedDoors = {
						title: translate.byKey('ungrouped_doors_title'),
						value: 'UNGROUPED_DOORS',
						checkable: false,
						children: assetsWithoutParents
							.map((asset) => mapTreeEntries(asset, []))
							.concat(areasWithoutParents.map((asset) => mapTreeEntries(asset, [])))
							.sort(byTitle),
					};

					const treeData =
						selectionMode === 'preferChild'
							? [
									...assetsWithoutParents
										.map((asset) => mapTreeEntries(asset, []))
										.concat(areasWithoutParents.map((asset) => mapTreeEntries(asset, [])))
										.sort(byTitle),
									...basicAssetGroups.assets
										.map((asset) => mapTreeEntries(asset, [assetsWithParents, areasWithParents]))
										.sort(byTitle),
									...accessAreaGroups.assets
										.map((asset) => mapTreeEntries(asset, [assetsWithParents, areasWithParents]))
										.sort(byTitle),
							  ]
							: [
									ungroupedDoors,
									...basicAssetGroups.assets
										.map((asset) => mapTreeEntries(asset, [assetsWithParents, areasWithParents]), 'preferParent')
										.sort(byTitle),
									...accessAreaGroups.assets
										.map((asset) => mapTreeEntries(asset, [assetsWithParents, areasWithParents]), 'preferParent')
										.sort(byTitle),
							  ];

					setTreeData(treeData);
					setIsBusy(false);
				})
				.catch(() => {
					setIsBusy(false);
				});
		}
	}, [types, assetsToInclude]);

	return (
		<TreeSelect
			disabled={userCanEditDoors || disabled}
			filterTreeNode={true}
			treeNodeFilterProp={'title'}
			treeData={treeData}
			placeholder={placeholder || translate.byKey('select_a_door_or_door_group')}
			value={selectedValues}
			showCheckedStrategy={selectionMode === 'preferParent' ? SHOW_PARENT : SHOW_CHILD}
			onChange={onTreeValueSelected}
			tagRender={(props) => (uniqueLabels[props.value] ? uniqueLabels[props.value](props) : <VisibleTag {...props} />)}
			treeCheckable={true}
			virtual={false}
		/>
	);
};
