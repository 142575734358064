const login = {
	base: {
		client_id: true,
		client_secret: true,
		username: true,
		password: true,
		grant_type: true, // ["client_credentials", "password", "refresh_token"]
		scope: false,
		refreshToken: false,
		customerId: false,
	},
	update: {
		version: false,
	},
	params: {},
	baseUrlPath: (requestBody) => ('refreshToken' in requestBody ? ['customer', ':customerId:', 'login'] : ['login']),
};

export default login;
