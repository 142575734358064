import React, { useState } from 'react';

import { Page } from '~/components';
import { UsbDevicesHeader } from '~/components/features/usb-devices/UsbDevicesHeader/UsbDevicesHeader';
import { UsbDevicesTable } from '~/components/features/usb-devices/UsbDevicesTable/UsbDevicesTable';
import { EditUsbDeviceModal } from '~/components/features/usb-devices/modals/edit-usb-device/EditUsbDeviceModal';
import { AddDesktopUpdaterModal } from '~/components/features/usb-devices/modals/add-usb-device/AddDesktopUpdaterModal';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { AddCloudUpdaterModal } from '~/components/features/usb-devices/modals/add-usb-device/AddCloudUpdaterModal';

export const UsbDevicesPage = () => {
	const [selectedUsbDevice, setSelectedUsbDevice] = useState(null);
	const [usbDeviceTypeForCreation, setUsbDeviceTypeForCreation] = useState('');

	const onAddNewButtonClick = (usbDeviceType) => {
		setUsbDeviceTypeForCreation(usbDeviceType);
	};

	const onSelectedUsbDevice = (usbDevice) => {
		setSelectedUsbDevice(usbDevice);
	};

	return (
		<>
			{selectedUsbDevice ? (
				<EditUsbDeviceModal usbDevice={selectedUsbDevice} onClose={() => setSelectedUsbDevice(null)} />
			) : null}
			{usbDeviceTypeForCreation === ControllerTypes.DESKTOP_UPDATER ? (
				<AddDesktopUpdaterModal onClose={() => setUsbDeviceTypeForCreation('')} />
			) : null}
			{usbDeviceTypeForCreation === ControllerTypes.CLOUD_UPDATER ? (
				<AddCloudUpdaterModal onClose={() => setUsbDeviceTypeForCreation('')} />
			) : null}
			<Page>
				<UsbDevicesHeader onAddNewButtonClick={onAddNewButtonClick} />
				<UsbDevicesTable onSelectedUsbDevice={onSelectedUsbDevice} />
			</Page>
		</>
	);
};
