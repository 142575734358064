import React, { useState } from 'react';
import { useMenuItems } from '~/components/shared/layouts/MainLayout/Navigation/AppMenu/useMenuItems';
import { NavigationMenu } from '~/components/shared/layouts/MainLayout/Navigation/shared/NavigationMenu';

export const AppMenu = () => {
	const [selectedKey, setSelectedKey] = useState('');
	const items = useMenuItems(setSelectedKey);

	return <NavigationMenu selectedKeys={[selectedKey]} items={items} />;
};
