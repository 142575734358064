import React, { useMemo } from 'react';
import { Button, message, Modal } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';

import { useLocale } from '~/screens/_shared/AppLocale';
import { WeeklyCalendar } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar';
import {
	AccessScheduleForm,
	allowAccessOnHolidaysField,
	nameField,
	weekPeriodsField,
} from '~/components/features/access-schedules/AccessScheduleForm/AccessScheduleForm';
import {
	getAccessScheduleCreateKey,
	useAccessScheduleCreate,
} from '~/hooks/data/access-schedules/useAccessScheduleCreate';
import { adjustWeekPeriodsToAPISchedules } from '~/utils/access-schedule/AccessSchedule.utils';
import { useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getDefaultAccessSchedulesKey } from '~/components/features/access-schedules/AccessSchedules.utils';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const isValidWeekPeriod = (weekPeriod) => Boolean(weekPeriod?.days.length) && weekPeriod?.times?.length === 2;

export const AccessScheduleCreateModal = ({ onClose }) => {
	const { translate } = useLocale();
	const { queryParams } = useQueryParams();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { trigger: createAccessSchedule, isMutating: isCreatingAccessSchedule } = useAccessScheduleCreate(
		getAccessScheduleCreateKey({ customerId, systemId })
	);
	const { mutate: reloadAccessSchedules } = useAccessSchedules(
		getDefaultAccessSchedulesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
			sortBy: queryParams?.sortBy,
			sortDirection: queryParams?.sortDirection,
		}),
		{ revalidateOnMount: false }
	);
	const [form] = useForm();
	const { [nameField]: name, [weekPeriodsField]: weekPeriods } = useWatch([], form) || {};
	const initialValues = useMemo(() => {
		return {
			[weekPeriodsField]: [
				{
					days: [],
					times: [],
				},
			],
		};
	}, []);

	const isCurrentDataMinimallyFilled = Boolean(name) && isValidWeekPeriod(weekPeriods?.[0]);

	const onSubmit = async () => {
		form
			.validateFields()
			.then(async (values) => {
				try {
					await createAccessSchedule({
						name: values[nameField],
						allowAccessOnHolidays: values[allowAccessOnHolidaysField],
						schedules: adjustWeekPeriodsToAPISchedules(values[weekPeriodsField]),
					});

					onClose();
					message.success(translate.byKey('access_schedule_created_successfully'));
					reloadAccessSchedules();
				} catch (error) {
					message.error(`${translate.byKey('an_error_occurred')} ${JSON.stringify(error?.data?.message)}`);
				}
			})
			.catch(() => {});
	};

	const onModalDismiss = () => {
		if (isCurrentDataMinimallyFilled) {
			Modal.confirm({
				title: translate.byKey('unsaved_changes'),
				content: translate.byKey('discard_changes_message'),
				okText: translate.byKey('yes'),
				cancelText: translate.byKey('no'),
				onOk: () => {
					onClose();
				},
			});
		} else {
			onClose();
		}
	};

	return (
		<Modal
			open
			width={590}
			title={translate.byKey('new_access_schedule')}
			maskClosable={false}
			onCancel={onModalDismiss}
			footer={[
				<Button key="cancel" onClick={onModalDismiss} disabled={isCreatingAccessSchedule}>
					{translate.byKey('cancel')}
				</Button>,
				<Button
					key="create"
					type="primary"
					onClick={onSubmit}
					disabled={isCreatingAccessSchedule || !isCurrentDataMinimallyFilled}
					loading={isCreatingAccessSchedule}
				>
					{translate.byKey('create')}
				</Button>,
			]}
		>
			<AccessScheduleForm initialValues={initialValues} form={form} />

			<div style={{ marginTop: '32px' }}>
				<WeeklyCalendar periods={weekPeriods} />
			</div>
		</Modal>
	);
};
