import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Table, Tag } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';

import { ClearButton } from '~/components/shared/ClearButton/ClearButton';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { peopleField } from '~/components/features/access-profiles/modals/form/fields';
import { getCredentialHoldersKey, useCredentialHolders } from '~/hooks/data/credential-holders/useCredentialHolders';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const SelectPeople = ({ onDisableSelectingMode }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const [searchValue, setSearchValue] = useState('');
	const form = Form.useFormInstance();
	const { data: credentialHolders } = useCredentialHolders(
		getCredentialHoldersKey({ customerId, systemId, siteId, name: searchValue })
	);
	const people = Form.useWatch(peopleField, form) || [];
	const [selectedPeople, setSelectedPeople] = useState([]);

	useEffect(() => {
		if (people.length) {
			setSelectedPeople(people);
		}
	}, [people]);

	const onAdd = (person) => {
		setSelectedPeople((previousPeople) => [...previousPeople, person]);
	};

	const onRemove = (person) => {
		setSelectedPeople((previousPeople) => previousPeople.filter((previousPerson) => previousPerson.id !== person.id));
	};

	const onApply = () => {
		form.setFieldValue(peopleField, selectedPeople);

		onDisableSelectingMode();
	};

	return (
		<>
			<div css={headerStyles}>
				<Input
					value={searchValue}
					placeholder={translate.byKey('search')}
					onChange={(event) => setSearchValue(event.target.value)}
					css={searchInputStyles}
					suffix={<ClearButton onClick={() => setSearchValue('')} />}
				/>
			</div>

			<Form.Item name={peopleField}>
				<Table
					size="small"
					scroll={{ y: 240 }}
					rowKey="id"
					dataSource={credentialHolders}
					columns={[
						{
							title: translate.byKey('first_name'),
							dataIndex: 'firstName',
						},
						{
							title: translate.byKey('last_name'),
							dataIndex: 'lastName',
						},
						{
							title: translate.byKey('email_address'),
							dataIndex: 'emailAddress',
						},
						{
							title: translate.byKey('phone_number'),
							dataIndex: 'phoneNumber',
						},
						{
							width: 100,
							align: 'center',
							title: translate.byKey('select'),
							render: (person) =>
								selectedPeople.find((selectedPerson) => selectedPerson.id === person.id) ? (
									<Button
										icon={<MinusOutlined />}
										type="primary"
										danger
										shape="circle"
										onClick={() => onRemove(person)}
									/>
								) : (
									<Button icon={<PlusOutlined />} type="primary" shape="circle" onClick={() => onAdd(person)} />
								),
						},
					]}
					pagination={{
						showSizeChanger: true,
						showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
						position: ['bottomCenter'],
						// TODO MM handle when API is ready
						current: 1,
						pageSize: DEFAULT_PAGE_SIZE,
					}}
				/>
			</Form.Item>

			<div css={tagsStyles}>
				{selectedPeople.map((person) => (
					<div key={person.id}>
						<Tag id={person.id} closable onClose={() => onRemove(person)}>
							{person.firstName} {person.lastName}
						</Tag>
					</div>
				))}
			</div>

			<div css={footerStyles}>
				<Button onClick={onDisableSelectingMode}>{translate.byKey('discard')}</Button>
				<Button onClick={onApply} type="primary">
					{translate.byKey('apply')}
				</Button>
			</div>
		</>
	);
};

const headerStyles = css({
	marginBottom: '24px',
});

const searchInputStyles = css({ width: '250px' });

const footerStyles = css({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	gap: '8px',
	marginTop: '24px',
});

const tagsStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	columnGap: '8px',
	rowGap: '16px',
	maxHeight: '140px',
	overflow: 'auto',
});
