import React from 'react';
import { css } from '@emotion/core';

import { Flex } from '~/components';

import { AppMenu } from '~/components/shared/layouts/MainLayout/Navigation/AppMenu/AppMenu';
import { UserMenu } from '~/components/shared/layouts/MainLayout/Navigation/UserMenu/UserMenu';

import { SiteSelection } from '~/components/features/site-selection/SiteSelection';

const Navigation = () => {
	return (
		<nav css={navigationStyles}>
			<Flex grow>
				<SiteSelection />
				<AppMenu />
			</Flex>
			<Flex alignItems="center">
				<UserMenu />
			</Flex>
		</nav>
	);
};

export default Navigation;

const navigationStyles = (theme) =>
	css({
		display: 'flex',
		marginTop: '16px',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.brand[500],
	});
