import { Alert } from 'antd';
import React from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency/SiteControllersEmergencyRecordsProvider';
import { ControllerLockState } from '~/constants/ControllerEmergencyStatus';

export const EmergencyAlert = () => {
	const { translate } = useLocale();
	const { areControllersInEmergencyState } = useSiteControllersEmergencyRecords();

	if (areControllersInEmergencyState(ControllerLockState.EmergencyUnlocked)) {
		return <Alert banner={true} message={translate.byKey('emergency_unlock_all_doors_are_unlocked')} />;
	}

	if (areControllersInEmergencyState(ControllerLockState.EmergencyLocked)) {
		return <Alert banner={true} message={translate.byKey('locked_all_doors_are_locked')} />;
	}

	return null;
};
