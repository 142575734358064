import React, { useContext, useState } from 'react';
import { AboutForm } from '~/screens/About/AboutForm';
import { SiteSettingsModal } from '~/components/features/site-settings/SiteSettingsModal/SiteSettingsModal';
import { SystemSettings } from '~/screens/Systems/system-settings/SystemSettings';
import { CardFormatModal } from '~/components/features/card-format/CardFormatModal/CardFormatModal';
import { UserProfile } from '~/screens/_shared/UserProfile/UserProfile';
import { MODAL_TYPES } from './constants';

export const ModalsContext = React.createContext();

export const useModals = () => useContext(ModalsContext);

export const ModalsContainer = (props) => {
	const [openedModal, setOpenedModal] = useState();

	const toggleModal = (modalType) => {
		setOpenedModal((value) => (value === modalType ? undefined : modalType));
	};

	const closeModal = () => toggleModal();

	const isModalOpened = (type) => type === openedModal;

	return (
		<ModalsContext.Provider value={{ toggleModal, isModalOpened }}>
			{props.children}
			{isModalOpened(MODAL_TYPES.ABOUT) ? <AboutForm onCancel={closeModal} /> : null}
			{isModalOpened(MODAL_TYPES.SITE_SETTINGS) ? <SiteSettingsModal onClose={closeModal} /> : null}
			{isModalOpened(MODAL_TYPES.SYSTEM_SETTINGS) ? <SystemSettings onCancel={closeModal} /> : null}
			{isModalOpened(MODAL_TYPES.CARD_FORMAT) ? <CardFormatModal onCancel={closeModal} /> : null}
			{isModalOpened(MODAL_TYPES.USER_PROFILE) ? <UserProfile onClose={closeModal} /> : null}
		</ModalsContext.Provider>
	);
};
