import { useState } from 'react';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import { usePostApi, useGetApi, useListApi, usePutApi, useDeleteApi } from '~/screens/_shared/useApi';
import { controllerUpdateTypes, controllerRequestTypes } from '../config';
import { useLocale } from '~/screens/_shared/AppLocale';
import { ControllerProtocols } from '~/constants/ControllerProtocols';
import { ControllerTypes } from '~/constants/ControllerTypes';

import systemConfig from '~/screens/_shared/systemConfig';
import moment from 'moment';
import mappers from '~/screens/_shared/mappers';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export class ConnectionError extends Error {
	constructor(error, controller) {
		super(error);
		this.controller = controller;
	}
}

export class ControllerOnboardedAlreadyError extends Error {}

export async function controllerIsReachable(controller, getControllerStatus) {
	if (controller && controller.configuration && controller.configuration.additionalSettings) {
		const { reachable, requestType } = controller.configuration.additionalSettings;
		return (
			reachable === 'TRUE' &&
			(requestType === controllerRequestTypes.processedSuccess.value ||
				requestType === controllerRequestTypes.processedNa.value)
		);
	}

	const onlineStatus = await getControllerStatus({ deviceId: controller.controllerId });

	return onlineStatus.connectionStatus === 'CONNECTED';
}

export function controllerIsBusy(tempController) {
	// Only need to check status if we actually have a controller.
	if (tempController && tempController.configuration && tempController.configuration.additionalSettings) {
		const { requestType, FULL_SYNC } = tempController.configuration.additionalSettings;

		// Processing a Full Sync
		if (FULL_SYNC && FULL_SYNC === true) {
			return true;
		}
		// Processing a Simple Sync or AutoId
		else if (
			requestType &&
			requestType !== controllerRequestTypes.processedSuccess.value &&
			requestType !== controllerRequestTypes.processedNa.value &&
			requestType !== controllerRequestTypes.none.value
		) {
			return true;
		}
	}

	return false;
}

export function controllerHasReaders(controller) {
	if (controller && controller.configuration && controller.configuration.additionalSettings) {
		const { additionalSettings } = controller.configuration;
		return Object.keys(additionalSettings).some((key) => key.startsWith('reader.'));
	}

	return false;
}

export function controllerRequestProcessed(controller) {
	if (controller && controller.configuration && controller.configuration.additionalSettings) {
		const { requestType } = controller.configuration.additionalSettings;
		return (
			requestType &&
			(requestType === controllerRequestTypes.processedSuccess.value ||
				requestType === controllerRequestTypes.processedNa.value)
		);
	}

	return false;
}

export async function createOrGetPort({ currentController, serialNumber, createPort, getAllPorts, getPort }) {
	const { controllerId } = currentController;
	const ports = await getAllPorts({ controllerId }, { expiry: moment().add(5, 'minutes') });

	let port = ports[0];

	if (!port) {
		const { portId } = await createPort({
			controllerId,
			name: `Port for Controller: ${currentController.name || serialNumber}`,
			description: JSON.stringify({
				controllerId,
			}),
			configuration: {
				portType: 'SERIAL',
				serialConfiguration: {
					baudRate: 38400,
					characterLength: 0,
				},
			},
		});

		port = await getPort({ portId, controllerId });
	}

	return port;
}

export function useCreateController() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStepObject] = useState(null);
	const [getControllers] = useListApi(mappers.controller);
	const [getController] = useGetApi(mappers.controller);
	const [updateController] = usePutApi(mappers.controller);
	const [createController] = usePostApi(mappers.controller);
	const [createPort] = usePostApi(mappers.port);
	const [getPort] = useGetApi(mappers.port);
	const [getAllPorts] = useListApi(mappers.port);
	const [onboardController] = usePostApi(mappers.controllerOnboard);
	const [getControllerStatus] = useGetApi(mappers.deviceStatus);
	const { translate } = useLocale();
	const [reSync] = usePostApi(mappers.reSync, () => {}, { removeDomainKey: true });
	const {
		data: { system, site },
	} = useCurrentSystemSite();
	const totalNumSteps = 5;

	function setCurrentStep(stepNumber, description = '', isError = false) {
		if (stepNumber > totalNumSteps - 1) {
			return;
		}

		let stepData = currentStep;

		stepData = stepData || { stepNumber: 0, status: null };
		stepData.steps =
			stepData.steps ||
			[...new Array(totalNumSteps)].map(() => {
				return { description: '', isPending: false };
			});

		if (stepNumber > stepData.stepNumber) {
			stepData.steps[stepData.stepNumber].isPending = false;
		}

		stepData.stepNumber = stepNumber;
		stepData.steps[stepNumber].description = description;
		stepData.steps[stepNumber].isPending = true;
		stepData.status = isError ? 'error' : null;

		setCurrentStepObject(stepData);
	}

	function waitTimeout(callback, timeout) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					resolve(callback());
				} catch (ex) {
					reject(ex);
				}
			}, timeout);
		});
	}

	/**
	 * Simple Sync
	 * - the first type of sync we perform after onboarding.
	 * - We can keep repeating it until we get a isReachable and isProcessed response
	 * for the controller. Meaning - controller is online.
	 */
	async function triggerControllerCommand(
		siteId,
		controllerId,
		macAddress,
		serialNumber,
		nonce,
		controllerResponse,
		name,
		requestType,
		protocol,
		type
	) {
		return updateController({
			siteId,
			controllerId,
			macAddress,
			serialNumber,
			protocol,
			nonce,
			additionalSettings: {
				...controllerResponse.configuration.additionalSettings,
				reachable: 'NA', // Force it back to NA so we can trust it when it comes back with TRUE
				requestType: requestType,
				FULL_SYNC: 'false',
			},
			name: name,
			type,
		});
	}

	function findControllerByMacAddress(macAddress) {
		return (controller) => {
			if (
				controller &&
				controller.configuration &&
				controller.configuration.additionalSettings &&
				controller.configuration.additionalSettings.macAddress
			) {
				return controller.configuration.additionalSettings.macAddress.toLowerCase() === macAddress;
			}
			return false;
		};
	}

	async function discoverController({
		siteId,
		controllerId,
		macAddress,
		serialNumber,
		protocol,
		nonce,
		currentController,
		name,
		stepDescription,
		type,
	}) {
		setCurrentStep(3, stepDescription || '');
		const numTries = 6;
		let delayBetweenCalls = 15;

		if (!currentController.isOnboarded) {
			try {
				await onboardController({ controllerId, serialNumber });
				currentController.isOnboarded = true;
			} catch (error) {
				if (error && error.status !== 409) {
					throw error;
				}
			}
		}

		let controller;
		for (let i = 0; i < numTries; i++) {
			const currentStep = translate.byKeyFormatted('connecting_to_controller_attempt_x_of_many', {
				x: i + 1,
				numberOfTries: numTries,
			});

			setCurrentStep(3, currentStep);

			const requestType = controllerUpdateTypes.simpleSync.value;
			await triggerControllerCommand(
				siteId,
				controllerId,
				macAddress,
				serialNumber,
				nonce,
				currentController,
				name,
				requestType,
				protocol,
				type
			);

			// Wait a little between calls.
			await waitTimeout(() => {}, delayBetweenCalls * 1000);

			controller = await getController({ controllerId, siteId });

			if (await controllerIsReachable(controller, getControllerStatus)) {
				if (controller?.protocol === 'passthrough') {
					await reSync({
						siteId,
						__contentType: 'application/vnd.oca.administration.api-1.0+json',
						controllerId: controllerId,
						type: 'RESYNC',
					});
				}
				return controller;
			}

			delayBetweenCalls += 5;

			await waitTimeout(() => {}, 15 * 1000);
		}

		setCurrentStep(3, translate.byKey('failed_to_connect_cleaning_up_data'), true);

		throw new ConnectionError(
			translate.byKey('could_not_discover_controller_please_check_the_connection_and_try_again'),
			controller
		);
	}

	async function discoverReaders({
		siteId,
		controllerId,
		macAddress,
		serialNumber,
		protocol,
		nonce,
		currentController,
		name,
		type,
	}) {
		setCurrentStep(4, translate.byKey('collecting_reader_details'));
		let isStandaloneCall = false;
		let shouldClearAndAutoId = true;

		try {
			if (!currentController) {
				isStandaloneCall = true;
				shouldClearAndAutoId = false;
				setIsLoading(true);
				currentController = await getController({ controllerId, siteId });
			}

			// This is present for the case where the discover button has been pressed, but the controller
			// was not onboarded correctly (either the power was disconnected or the browser was refreshed).
			if (
				!controllerHasReaders(currentController) &&
				!(await controllerIsReachable(currentController, getControllerStatus))
			) {
				shouldClearAndAutoId = true;
				currentController = await discoverController({
					siteId,
					controllerId,
					macAddress,
					serialNumber,
					protocol,
					nonce,
					currentController,
					name,
					stepDescription: translate.byKey('the_controller_is_not_reachable_so_trying_to_connect_to_it_again'),
					type,
				});
			}

			const delayBtwReaderChecks = 15;
			const numTriesAutoId = 10;
			const scanningForReaders = translate.byKey(
				'scanning_for_readers_name_please_do_not_interrupt_this_process_this_can_take_up_to_2_minutes'
			);

			const requestType = shouldClearAndAutoId
				? controllerUpdateTypes.clearAndAutoId.value
				: controllerUpdateTypes.autoId.value;
			setCurrentStep(4, scanningForReaders);
			await triggerControllerCommand(
				siteId,
				controllerId,
				macAddress,
				serialNumber,
				nonce,
				currentController,
				name,
				requestType,
				protocol,
				type
			);

			// Wait a little between calls.
			await waitTimeout(() => {}, 15 * 1000);

			// Have to wait this long time so autoId has time to trigger and complete.
			for (let i = 0; i < numTriesAutoId; i++) {
				const controller = await getController({ controllerId, siteId });
				if (shouldClearAndAutoId && (controllerRequestProcessed(controller) || controllerHasReaders(controller))) {
					return controller;
				} else if (controllerRequestProcessed(controller)) {
					return controller;
				}

				await waitTimeout(() => {}, delayBtwReaderChecks * 1000);
			}
		} finally {
			if (isStandaloneCall) {
				setIsLoading(false);
				setCurrentStepObject(null);
			}
		}
	}

	function getRandomLetters(name = '', length = 2) {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

		for (let i = name.length; i < length; i++) {
			name = name.concat(characters.charAt(Math.floor(Math.random() * characters.length)));
		}

		return name;
	}

	function generateMacAddressFromName(name) {
		if (name.length >= 5) {
			name = getRandomLetters(name.substring(0, 4), 5);
		}
		return name
			.split('')
			.reduce(
				(results, character, index) => {
					if (index < results.length) {
						results[index] = character.charCodeAt(0);
					}

					return results;
				},
				[0, 0, 0, 0, 0, 0]
			)
			.map((number) => {
				if (number.toString(16) === '0') {
					return getRandomLetters();
				}
				return number.toString(16);
			})
			.join(':');
	}

	function generateSerialNumberFromName(name) {
		return name
			.split('')
			.reverse()
			.reduce(
				(results, character, index) => {
					if (index < results.length) {
						results[index] = character.charCodeAt(0);
					}

					return results;
				},
				[0, 0, 0, 0, 0]
			)
			.map((number) => number.toString(16))
			.join('');
	}

	async function createUpdater(formData) {
		formData.protocol = formData.protocol || 'pulse';

		formData.description = formData.description || ' ';
		formData.latitude = formData.latitude || 0;
		formData.longitude = formData.longitude || 0;
		formData.upstreamUri = `${systemConfig.realServerUrl}/updates-connector/system/${system.systemId}/site/1/device/1/upstream/`;
		formData.downstreamUri = `${systemConfig.realServerUrl}/updates-connector/system/${system.systemId}/site/1/device/1/downstream/`;

		formData.macAddress = generateMacAddressFromName(formData.name);
		formData.serialNumber = generateSerialNumberFromName(formData.name);

		await createController(formData);

		const controllers = (
			await getControllers(
				{ params: { 'detail-level': 'FULL', type: 'DESKTOP_UPDATER' } },
				{ expiry: moment().add(1, 'minutes'), removeExistingKey: true }
			)
		).filter((controller) => controller.type === 'DESKTOP_UPDATER');
		let currentController = controllers.find(findControllerByMacAddress(formData.macAddress.toLocaleLowerCase()));

		if (currentController) {
			const config = (currentController.configuration = currentController.configuration || {});
			const controllerId = currentController.controllerId;
			config.upstreamUri = `${systemConfig.realServerUrl}/updates-connector/system/${system.systemId}/site/1/device/${controllerId}/upstream/`;
			config.downstreamUri = `${systemConfig.realServerUrl}/updates-connector/system/${system.systemId}/site/1/device/${controllerId}/downstream/`;

			delete currentController.configuration;

			currentController = Object.assign(currentController, config);
			currentController.macAddress = formData.macAddress;
			currentController.serialNumber = formData.serialNumber;

			await updateController(currentController);
		}

		return controllers;
	}

	const [deleteController] = useDeleteApi(mappers.controller);

	const createdIds = new Map();

	async function createAndOnboardController(formData) {
		try {
			setIsLoading(true);

			if (formData.type === ControllerTypes.DESKTOP_UPDATER) {
				const controller = await createUpdater(formData);
				setIsLoading(false);
				return controller;
			}

			setCurrentStep(0);
			const { serialNumber, nonce, name } = formData;
			const type =
				formData.protocol === ControllerProtocols.IMPROX
					? ControllerTypes.ACCESS_CONTROL_UNIT
					: ControllerTypes.CONTROLLER_UPDATER;
			const macAddress = (formData.macAddress || '').toLowerCase();

			if (!(serialNumber && macAddress && nonce && name)) {
				throw new Error(translate.byKey('values_incorrect'));
			}

			let currentController;

			if (createdIds.has(macAddress)) {
				const controllerId = createdIds.get(macAddress);
				try {
					currentController = await getController({ controllerId, siteId: site.siteId });
				} catch {
					createdIds.delete(macAddress);
				}
			}

			if (!currentController) {
				const cacheKey = getCacheKey(method.list, mappers.controller, {
					params: { 'detail-level': 'FULL' },
				});

				const systemCacheKey = getCacheKey(method.list, mappers.controllersBySystem, {
					params: { 'detail-level': 'FULL' },
				});
				const result = await createController(
					{ serialNumber, macAddress, nonce, name, protocol: formData.protocol, type },
					{ removeExistingKey: [cacheKey, systemCacheKey] }
				);

				currentController = await getController({ controllerId: result.controllerId, siteId: site.siteId });

				if (!currentController) {
					throw new Error(translate.byKey('controller_could_not_be_created_correctly'));
				}

				const controllerId = currentController.controllerId;

				createdIds.set(macAddress, controllerId);

				try {
					setCurrentStep(1);
					await onboardController({ controllerId, serialNumber });
					currentController.isOnboarded = true;
				} catch (error) {
					if (error && error.status === 409) {
						throw new ControllerOnboardedAlreadyError();
					}
					if (error && error.status === 404) {
						const cacheKey = getCacheKey(method.list, mappers.controller, {
							params: { 'detail-level': 'FULL' },
						});
						await deleteController({ controllerId }, { removeExistingKey: cacheKey });
					}
					throw new Error(translate.byKey('controller_certificate_is_not_registered'));
				}
			}

			setCurrentStep(2);
			const controllerId = currentController.controllerId;
			await createOrGetPort({ controllerId, currentController, serialNumber, createPort, getAllPorts, getPort });

			currentController = await discoverController({
				protocol: formData.protocol,
				controllerId,
				macAddress,
				serialNumber,
				nonce,
				currentController,
				name,
				type,
			});

			currentController = await discoverReaders({
				protocol: formData.protocol,
				controllerId,
				macAddress,
				serialNumber,
				nonce,
				currentController,
				name,
				type,
			});

			return currentController;
		} finally {
			setIsLoading(false);
			setCurrentStepObject(null);
		}
	}

	return [createAndOnboardController, discoverReaders, isLoading, currentStep];
}
