import en from 'antd/lib/locale/en_GB';
import cs from 'antd/lib/locale/cs_CZ';
import fr from 'antd/lib/locale/fr_FR';
import it from 'antd/lib/locale/it_IT';
import nl from 'antd/lib/locale/nl_NL';
import pl from 'antd/lib/locale/pl_PL';

const localesPerLanguageCode = {
	cs,
	fr,
	it,
	nl,
	pl,
};

/**
 * Returns Antd locale for any language code.
 *
 * @param languageCode
 * @returns {*|Locale}
 */
export const getLocale = (languageCode) => localesPerLanguageCode[languageCode] || en;
