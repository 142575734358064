import styled from '@emotion/styled';

import Box from '../Box';

const Flex = styled(Box)`
	display: flex;
	${(props) => props.center && 'align-items: center; justify-content: center;'}
	${(props) => (props.grow ? 'flex: 1;' : '')}
`;

export default Flex;
