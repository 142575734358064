import {
	TypedAddButton,
	AddButton,
	Button,
	ButtonIcon,
	Flex,
	Modal,
	Page,
	PageHeader,
	Spin,
	ReloadButton,
} from '~/components';
import { Popconfirm, Tooltip } from 'antd';
import { css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { useListApi, useGetApi } from '~/screens/_shared/useApi';
import ReaderForm from './ReaderForm';
import OfflineDoorForm from './OfflineDoorForm';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useTheme } from 'emotion-theming';
import systemConfig from '~/screens/_shared/systemConfig';
import {
	doorTypeDisplayNames,
	getDoorTypeDisplayName,
	getDoorTypeFromConfig,
	isOffline,
	isOnline,
	hasReadersAssigned,
} from '~/screens/Doors/hooks/doorDefaults';
import moment from 'moment';
import { showWarningToast } from '~/screens/_shared/toast';
import mappers from '~/screens/_shared/mappers';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { LockFilled, UnlockFilled } from '@ant-design/icons';
import { titleCase } from '../AccessGroups';
import { getLimits } from '~/screens/_shared/entitlement';
import { ReaderConfigurationProtocolTypes } from '~/constants/ReaderConfigurationProtocolTypes';
import { ControllerProtocols } from '~/constants/ControllerProtocols';
import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { MultipleChoiceFilter } from '~/components/shared/filters/MultipleChoiceFilter/MultipleChoiceFilter';
import { CategorizedMultipleChoiceFilter } from '~/components/shared/filters/CategorizedMultipleChoiceFilter/CategorizedMultipleChoiceFilter';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';
import { getStrikeDurationForDevice } from '~/utils/doors/Doors.utils';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { useDoorEmergency } from '~/hooks/features/emergency';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency';
import { EnrichedPortalStatus } from '~/constants/EnrichedPortalStatus';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const Doors = () => {
	const {
		data: { system, site },
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();

	const theme = useTheme();
	const { translate } = useLocale();
	const [doorTypeVisible, setDoorTypeVisible] = useState(null);
	const [readerFormVisible, setReaderFormVisible] = useState(false);
	const [selectedReader, setSelectedReader] = useState(false);
	const [selected, setSelected] = useState({});
	const [pageFilters, setPageFilters] = useState({ doorName: null, doorTypes: [] });
	const [filteredData, setFilteredData] = useState([]);
	const [doors, setDoors] = useState([]);
	const [hasBadReaders, setHasBadReaders] = useState(false);
	const [loading, setLoadingDoors] = useState(false);
	const [controllers, setControllers] = useState([]);
	const [doorStatusList, setDoorStatusList] = useState([]);
	const [doorZoneList, setDoorZoneList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const [getControllers] = useListApi(mappers.controller);
	const [getController] = useGetApi(mappers.controller);
	const [getDoors, loadingDoors] = useListApi(mappers.portal);
	const [getAssignedReaders, loadingAssignedReaders] = useListApi(mappers.peripheralDevice);
	const [warningModalShown, setWarningModalShown] = useState(false);
	const siteId = selected ? selected.siteId : site.siteId;
	const [popConfirmTitle, setPopConfirmTitle] = useState(null);
	const [listOfDoorsMappedToUpdater, setListOfDoorsMappedToUpdater] = useState([]);
	const [enableDeviceStateDispatch, setEnableDeviceStateDispatch] = useState(true);

	const { isInEmergencyMode } = useSiteControllersEmergencyRecords();
	const { getDoorStatus, unlock } = useDoorEmergency();

	function tableChanged(pagination, filter, sorter, extra, listOfDoors = null) {
		filter = filter || {};
		listOfDoors = listOfDoors ? Array.from(listOfDoors) : Array.from(doors).sort(createSorter());

		const doorTypes = Boolean(filter.doorType?.length) ? filter.doorType : null;
		const doorName = Boolean(filter.name?.length) ? filter.name[0] : null;
		const doorStatus = Boolean(filter.status?.length) ? filter.status : null;
		const doorZones = Boolean(filter.zone?.length) ? filter.zone : null;

		setPageFilters({
			doorTypes,
			doorName,
			doorStatus,
			doorZones,
		});

		if (doorTypes) {
			listOfDoors = listOfDoors.filter((d) => doorTypes.includes(d.doorType));
		}

		if (doorName) {
			listOfDoors = listOfDoors.filter((d) => d.name.toLocaleLowerCase().includes(doorName.toLocaleLowerCase()));
		}

		if (doorStatus) {
			const formattedDoorStatus = doorStatus.map((currentDoorStatus) =>
				(currentDoorStatus !== 'none' ? currentDoorStatus : '').toLocaleLowerCase()
			);
			listOfDoors = listOfDoors.filter((d) => formattedDoorStatus.includes(d.status.toLocaleLowerCase()));
		}

		if (doorZones) {
			const formattedDoorZones = doorZones.map((doorZone) =>
				(!['null', null].includes(doorZone) ? doorZone : 'null').toLocaleLowerCase()
			);

			listOfDoors = listOfDoors.filter((d) =>
				Array.isArray(d?.zone)
					? d.zone.map((zone) => String(zone).toLocaleLowerCase()).find((zone) => formattedDoorZones.includes(zone))
					: formattedDoorZones.includes(String(d.zone).toLocaleLowerCase())
			);
		}

		setFilteredData(listOfDoors);
		if (pagination) {
			setPageSize(pagination.pageSize);
			setCurrentPage(pageSize !== pagination.pageSize ? 1 : pagination.current);
		}
	}

	function deviceChecker(type) {
		return function (reader) {
			const doorType = getDoorTypeFromConfig(reader.deviceInfo);
			if (isOnline(doorType) && reader.mapping.port && reader.fixedAddress) {
				if (Array.isArray(reader.logicalDevices) && reader.logicalDevices.length > 0) {
					if (type === 'entry') {
						return reader.logicalDevices.some((device) => (device.portalSide || '').toLocaleLowerCase() === 'outside');
					} else if (type === 'exit') {
						return reader.logicalDevices.some((device) => (device.portalSide || '').toLocaleLowerCase() === 'inside');
					}
				}
			}
			return false;
		};
	}

	const entryChecker = deviceChecker('entry');
	const exitChecker = deviceChecker('exit');

	function hasEntryReader(door) {
		return door.assignedReaders.some(entryChecker);
	}

	function hasExitReader(door) {
		return door.assignedReaders.some(exitChecker);
	}

	async function processDoors(doors, removeExistingKey = false, preserveFilters = false) {
		setDoors([]);
		setLoadingDoors(true);
		try {
			let assignedReaders;

			try {
				assignedReaders = await getAssignedReaders(
					{
						params: {
							assignedToPortal: 'true',
							'detail-level': 'FULL',
						},
					},
					{ expiry: moment().add(1, 'minutes'), shared: true, removeExistingKey }
				);

				setHasBadReaders(false);
			} catch (error) {
				setHasBadReaders(true);
				assignedReaders = await getAssignedReaders({ params: { assignedToPortal: 'true' } });
				doorErrorMessage();
			}

			for (const door of doors) {
				const assignedReadersForDoor = assignedReaders
					.map((r) => {
						if (r && r.configuration && r.configuration.additionalSettings) {
							r.fixedAddress = r.configuration.additionalSettings.fixedAddress || '';
						}
						return r;
					})
					.filter((r) => r.mapping && r.mapping.portal?.portalId === door.portalId);

				const assignedAperioReadersForDoor = assignedReadersForDoor.filter((assignedReader) => {
					if (assignedReader && assignedReader.fixedAddress) {
						return (assignedReader.fixedAddress || '').startsWith('17');
					}

					return false;
				});

				let doorType =
					door.configuration?.additionalSettings?.doorType || door.additionalSettings?.doorType || door?.doorType;
				if ((!doorType && assignedReadersForDoor.length > 0) || assignedAperioReadersForDoor.length > 0) {
					const deviceInfoDoorType = getDoorTypeFromConfig(assignedReadersForDoor[0].deviceInfo);
					doorType =
						isOnline(deviceInfoDoorType) && (assignedReadersForDoor[0].fixedAddress || '').startsWith('17')
							? 'aperio_online'
							: getDoorTypeFromConfig(assignedReadersForDoor[0].deviceInfo || assignedReadersForDoor[0].deviceInfo);
				}

				door.doorType = doorType || 'hid_online';
				door.assignedReaders = assignedReadersForDoor;
				door.assignedAperioReaders = assignedAperioReadersForDoor;
				door.showEntryReader = isOnline(doorType);
				door.showExitReader = isOnline(doorType);

				let zone = null;
				if (door.assignedReaders.length > 0 && door.assignedReaders[0].configuration.additionalSettings) {
					zone = door.assignedReaders.map((reader) => {
						return reader.configuration.additionalSettings?.zoneName ||
							reader.configuration.additionalSettings?.zoneAssetName
							? reader.configuration.additionalSettings?.zoneName ||
									reader.configuration.additionalSettings?.zoneAssetName
							: null;
					});
				}

				if (
					(door.configuration.additionalSettings && door.configuration.additionalSettings.zoneName) ||
					door.configuration.additionalSettings?.zoneAssetName
				) {
					zone = door.configuration.additionalSettings.zoneName || door.configuration.additionalSettings?.zoneAssetName;
				}

				door.zone = zone;

				door.status = '';
				if (isOffline(doorType) && door.assignedReaders.length > 0) {
					let doorStatus = door.assignedReaders.find((reader) => !!reader.configuration?.configurationStatus)
						?.configuration?.configurationStatus;
					door.status = doorStatus === 'CONFIGURED' ? translate.byKey('configured') : translate.byKey('pending');
				}

				// we specifically check if there is one reader, because if there are more, they still need to be shown so that they can be deleted.
				// especially if there was already bad data before this change.
				if (doorType === 'aperio_online') {
					if (door.assignedReaders.length > 0) {
						door.showEntryReader = door.assignedReaders.some((reader) =>
							reader.logicalDevices.some((device) => (device.portalSide || '').toLocaleLowerCase() === 'outside')
						);
						door.showExitReader = door.assignedReaders.some((reader) =>
							reader.logicalDevices.some((device) => (device.portalSide || '').toLocaleLowerCase() === 'inside')
						);
					} else {
						door.showEntryReader = false;
						door.showExitReader = false;
					}
				}
			}

			setDoors(doors);

			const statusList = [];
			let zoneList = [];

			doors.forEach(async (door) => {
				if (!statusList.includes(door.status)) {
					statusList.push(door.status);
				}
				if (Array.isArray(door.zone)) {
					zoneList = zoneList.concat(door.zone.map((zone) => String(zone)));
				} else {
					zoneList.push(String(door.zone));
				}
				const isDoorMappedToUpdater = await checkIfDoorIsMappedToUpdater(door);
				if (isDoorMappedToUpdater) {
					setListOfDoorsMappedToUpdater((updaterDoorList) => [...updaterDoorList, door.portalId]);
				}
			});

			setDoorStatusList(statusList);
			setDoorZoneList([...new Set(zoneList)]);

			let filters = {};
			if (preserveFilters) {
				filters = {
					doorType: pageFilters.doorTypes,
					name: [pageFilters.doorName],
					status: pageFilters.doorStatus,
					zone: pageFilters.doorZones,
				};
			}
			tableChanged(null, filters, null, null, doors);
		} finally {
			setLoadingDoors(false);
		}
	}

	/**
	 * Check if the selected door has a reader mapped to an Updater Controller (M90)
	 */
	const checkIfDoorIsMappedToUpdater = async (door) => {
		const [entryReader, exitReader] = door.assignedReaders;
		const isEntryReaderTypeHID =
			entryReader?.deviceInfo?.configurationProtocol === ReaderConfigurationProtocolTypes.HID_READER;
		const isExitReaderTypeHID =
			exitReader?.deviceInfo?.configurationProtocol === ReaderConfigurationProtocolTypes.HID_READER;

		if (isEntryReaderTypeHID || isExitReaderTypeHID) {
			const controllerId = entryReader.mapping.port.controllerId;
			const currentController = await getController({ controllerId, siteId });

			if (currentController.protocol === ControllerProtocols.PASSTHROUGH) {
				return true;
			}
		}

		return false;
	};

	const checkForRTESupport = (door) => {
		const isRTEDisabled = listOfDoorsMappedToUpdater.includes(door.portalId);

		if (isRTEDisabled) {
			setPopConfirmTitle(translate.byKey('rte_not_supported'));
			setEnableDeviceStateDispatch(false);
		} else {
			const strikeDurationDefault = door.assignedAperioReaders[0]
				? getStrikeDurationForDevice(door.assignedAperioReaders[0])
				: 0;
			const strikeDuration = door.assignedReaders[0]
				? getStrikeDurationForDevice(door.assignedReaders[0])
				: strikeDurationDefault;

			setPopConfirmTitle(translate.byKeyFormatted('unlock_door_for_strike_duration', [strikeDuration]));
			setEnableDeviceStateDispatch(true);
		}
	};

	/**
	 * Update total count for use in checking system limits.
	 */
	useEffect(() => {
		if (system.systemId && site.siteId && doors) {
			// No records. Clear the table.
			if (!doors || doors.length === 0) {
				setFilteredData([]);
			}
		}
	}, [system, site, doors]);

	// Create or update request from form
	function onDoorUpdated(withSuccess) {
		if (withSuccess) {
			getDoors(
				{ params: { 'detail-level': 'FULL', 'page-size': 1000 } },
				{
					expiry: moment().add(5, 'minutes'),
					removeExistingKey: true,
				}
			)
				.then((doors) => processDoors(doors.portals, true, true))
				.catch(console.error);
		}
		setDoorTypeVisible(null);
		setSelected(null);
	}

	function onReaderUpdated(withSuccess) {
		onDoorUpdated(withSuccess);
		setReaderFormVisible(false);
	}

	const onDoorCancel = () => {
		setSelected(null);
		setDoorTypeVisible(null);
	};

	const onReaderFormCancel = () => {
		setReaderFormVisible(false);
	};

	const onAddNewDoorClicked = (doorType) => {
		if (hasBadReaders) {
			doorErrorMessage();
			return;
		}

		doorType = doorType || 'hid_online';

		if (isOffline(doorType) && hasExceededOfflineDoorFeatureLimits()) {
			showWarningModal();
		} else {
			setDoorTypeVisible(doorType);
		}
	};

	const onRowClick = (row) => {
		setSelected(row);
		setDoorTypeVisible(row.doorType);
	};

	function doorErrorMessage() {
		showWarningToast(translate.byKey('corrupt_readers_error'));
	}

	const onReaderClick = (e, type, row) => {
		e.stopPropagation();
		e.preventDefault();
		if (systemConfig.devVersion >= 2) {
			if (hasBadReaders) {
				doorErrorMessage();
				return;
			}

			if (type === 'ENTRY') {
				row.defaultSelectedTab = 'entry_reader_tab';
			} else if (type === 'EXIT') {
				row.defaultSelectedTab = 'exit_reader_tab';
			}

			onRowClick(row);
		} else {
			const { portalId, name, assignedReaders, doorType } = row;
			setSelectedReader({ portalId, type: type, name, assignedReaders, doorType });
			setReaderFormVisible(true);
		}
	};

	/**
	 * Make sure we have a system AND site loaded before we attempt to list
	 * Also ensure that we reload the list whenever the system OR site changes
	 */
	useEffect(() => {
		if (system.systemId && site.siteId) {
			getControllers(
				{
					ignoreGlobalHandlers: true,
					params: { 'detail-level': 'FULL' },
				},
				{ expiry: moment().add(15, 'minutes'), shared: true }
			)
				.then((results) => {
					setControllers(
						results.filter((controller) => controller.protocol === 'improx' || controller.protocol === 'passthrough')
					);
				})
				.catch(console.error);

			getDoors(
				{ params: { 'detail-level': 'FULL', 'page-size': 1000 } },
				{ expiry: moment().add(30, 'seconds'), removeExistingKey: true }
			)
				.then((doors) => processDoors(doors.portals, false, false))
				.catch(console.error);
		}
	}, [system, site]);

	async function onReloadDoors() {
		try {
			const doorsCollection = await getDoors(
				{ params: { 'detail-level': 'FULL', 'page-size': 1000 } },
				{ removeExistingKey: true }
			);
			processDoors(doorsCollection.portals, true, true);
		} catch (e) {
			console.error(`Error reloading Doors: ${e}`);
		}
	}

	function getDoorSvg(doorType, batteryStatus = 'ok') {
		if (doorType === 'aperio_online') {
			if (batteryStatus === 'low') {
				return '/assets/images/AperioOnlineLow.svg';
			} else if (batteryStatus === 'flat') {
				return '/assets/images/AperioOnlineFlat.svg';
			}
			return '/assets/images/AperioOnline.svg';
		} else if (doorType === 'aperio_offline') {
			return '/assets/images/AperioOffline.svg';
		} else if (doorType === 'pulse_offline') {
			return '/assets/images/PulseOffline.svg';
		}
		return '/assets/images/WiredOnline.svg';
	}

	function disableDefaultRowClick(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	function getColumns(version) {
		const results = [
			{
				title: translate.byKey('name'),
				dataIndex: 'name',
				sortDirections: ['descend', 'ascend', 'descend'],
				defaultSortOrder: 'ascend',
				sorter: createSorter('name'),
				filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
				filteredValue: pageFilters?.doorName ? [pageFilters?.doorName] : null,
				filterIcon: <SearchFilterIcon />,
			},
			{
				title: '',
				dataIndex: 'readers',
				render: (value, row) => (
					<Flex
						grow
						css={css`
							${version >= 2
								? `
            justify-content: end;
            @media (max-width: 1550px) {
              flex-direction: column;
            }`
								: `justify-content: end;`}
						`}
					>
						{hasActionPermissions(user, userConstants.screens.READER, userConstants.actions.READ) && (
							<>
								<Button
									style={{
										visibility: row.showEntryReader ? 'visible' : 'hidden',
										margin: version >= 2 ? 2 : '0px 5px',
									}}
									icon={<ButtonIcon name="entry-reader" />}
									css={
										hasEntryReader(row)
											? css`
													background-color: ${theme.colors.brand[500]};
													color: #fff;
												`
											: css``
									}
									type={hasEntryReader(row) ? 'default' : 'dashed'}
									size="small"
									onClick={(e) => {
										onReaderClick(e, 'ENTRY', row);
									}}
								>
									{translate.byKey('entry_reader')}
								</Button>
								<Button
									style={{
										visibility: row.showExitReader ? 'visible' : 'hidden',
										margin: version >= 2 ? 2 : '0px 5px',
									}}
									icon={<ButtonIcon name="exit-reader" />}
									css={
										hasExitReader(row)
											? css`
													background-color: ${theme.colors.brand[500]};
													color: #fff;
												`
											: css``
									}
									type={hasExitReader(row) ? 'default' : 'dashed'}
									size="small"
									onClick={(e) => {
										onReaderClick(e, 'EXIT', row);
									}}
								>
									{translate.byKey('exit_reader')}
								</Button>
							</>
						)}
					</Flex>
				),
			},
		];

		if (version >= 2) {
			const doorTypeFilterOptions = Object.entries(doorTypeDisplayNames)
				.map(([key, value]) => ({
					label: translate.byKey(value),
					value: key,
					count: doors.filter((door) => door.doorType === key).length,
				}))
				.reduce(
					(previousValue, currentValue) => {
						if (currentValue.value.includes('online')) {
							const items = [...previousValue[0].items, currentValue];
							return [{ ...previousValue[0], items }, previousValue[1]];
						} else {
							const items = [...previousValue[1].items, currentValue];
							return [previousValue[0], { ...previousValue[1], items }];
						}
					},
					[
						{ category: translate.byKey('online'), items: [] },
						{ category: translate.byKey('offline'), items: [] },
					]
				);
			const zoneFilterOptions = doorZoneList.map((zone) => {
				const count = doors.filter(
					(door) =>
						door.zone === zone ||
						String(door.zone) === zone ||
						(Array.isArray(door.zone) && door.zone.some((item) => item === zone || String(item) === zone))
				).length;

				return zone && zone !== 'null'
					? {
							label: zone,
							value: zone,
							count,
						}
					: {
							label: translate.byKey('none'),
							value: 'null',
							count,
						};
			});
			const statusFilterOptions = doorStatusList.map((status) => {
				const count = doors.filter((door) => door.status === status).length;

				return Boolean(status)
					? { label: titleCase(status), value: status, count }
					: {
							label: translate.byKey('none'),
							value: 'none',
							count,
						};
			});

			results.splice(
				1,
				0,
				...[
					{
						title: '',
						dataIndex: 'doorType',
						width: '5em',
						height: '',
						render: (cell, record) => {
							if (isOnline(cell.toLocaleLowerCase()) && hasReadersAssigned(record)) {
								return (
									<Flex alignItems="center">
										{getDoorStatus(record) === EnrichedPortalStatus.UNLOCKED ? (
											<UnlockFilled
												style={{
													fontSize: '1.75rem',
													color: '#2dc937',
												}}
											/>
										) : (
											<Popconfirm
												title={popConfirmTitle}
												onCancel={(e) => {
													disableDefaultRowClick(e);
												}}
												onConfirm={(e) => {
													disableDefaultRowClick(e);
													if (enableDeviceStateDispatch) {
														unlock(record.portalId);
													}
												}}
											>
												<LockFilled
													onClick={(e) => {
														disableDefaultRowClick(e);
														checkForRTESupport(record);
													}}
													style={{
														fontSize: '1.75rem',
														color: getDoorStatus(record) === EnrichedPortalStatus.DEFAULT ? '#db7b2b' : '#cc3232',
													}}
												/>
											</Popconfirm>
										)}
									</Flex>
								);
							}
						},
					},
					{
						title: translate.byKey('door_type_header_v2'),
						dataIndex: 'doorType',
						render: (doorType, portal) => {
							return (
								<Tooltip title={getLicensedCount(system, doorType)}>
									<img
										width={doorType === 'aperio_offline' ? '40em' : '30em'}
										height={doorType === 'aperio_offline' ? '40em' : '30em'}
										style={doorType === 'aperio_offline' ? { marginRight: '0.25rem' } : { marginRight: '1rem' }}
										src={getDoorSvg(
											doorType,
											portal?.assignedReaders[0]?.configuration?.additionalSettings?.batteryStatus
										)}
										alt={translate.byKey(getDoorTypeDisplayName(doorType))}
									/>
									{translate.byKey(getDoorTypeDisplayName(doorType))}
								</Tooltip>
							);
						},
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: ({ doorType: doorTypeA }, { doorType: doorTypeB }) => {
							const doorTypeALabel = translate.byKey(getDoorTypeDisplayName(doorTypeA));
							const doorTypeBLabel = translate.byKey(getDoorTypeDisplayName(doorTypeB));

							return doorTypeALabel.toLocaleLowerCase().localeCompare(doorTypeBLabel.toLocaleLowerCase());
						},
						filterDropdown: (props) => (
							<CategorizedMultipleChoiceFilter
								antdFilterProps={props}
								options={doorTypeFilterOptions}
								showSearch={false}
							/>
						),
						filteredValue: pageFilters?.doorTypes ? pageFilters?.doorTypes : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () =>
								doorTypeFilterOptions.flatMap((category) => category.items)?.find((x) => x.value === value)?.label ??
								value,
						}),
					},
					{
						title: translate.byKey('zone_v2'),
						dataIndex: 'zone',
						filterDropdown: (props) => (
							<MultipleChoiceFilter antdFilterProps={props} options={zoneFilterOptions} showSearch={false} />
						),
						filteredValue: pageFilters.doorZones ? pageFilters.doorZones : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => zoneFilterOptions?.find((x) => x.value === value)?.label ?? value,
						}),
					},

					// { TODO add when supported
					//   Header: translate.byKey("door_version_header_v2"),
					//   accessor: "version",
					//   width: "50%"
					// },
					{
						title: translate.byKey('door_status_header_v2'),
						dataIndex: 'status',
						render: (value, row) => {
							const { auditCollectedDateTime } = row.assignedReaders?.[0]?.configuration?.additionalSettings || {};

							return auditCollectedDateTime ? (
								<Flex flexDirection="column">
									<span>{value}</span>
									<span css={auditCollectedDateTimeStyles}>{moment(auditCollectedDateTime).format('L LT')}</span>
								</Flex>
							) : (
								value
							);
						},
						filterDropdown: (props) => (
							<MultipleChoiceFilter antdFilterProps={props} options={statusFilterOptions} showSearch={false} />
						),
						filteredValue: pageFilters.doorStatus ? pageFilters.doorStatus : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => statusFilterOptions?.find((x) => x.value === value)?.label ?? value,
						}),
					},
				]
			);
		}

		return results;
	}

	function hasExceededOfflineDoorFeatureLimits() {
		const offlineDoorFeatureLimits = getLimits(system, 'aperio_offline');
		const { hardLimit, usedCount } = offlineDoorFeatureLimits;
		return usedCount >= hardLimit; //This condition is to guard against instances where usageCounts are already beyond the hardLimit
	}

	function showWarningModal() {
		if (warningModalShown) {
			return;
		}

		Modal.warning({
			title: translate.byKey('warning'),
			content: translate.byKeyFormatted('entitlement_limit_reached_message', { entitlement: 'Offline Doors' }),
			onOk: () => {
				setWarningModalShown(false);
			},
		});

		setWarningModalShown(true);
	}

	return (
		<Page>
			<PageHeader title={translate.byKey('doors')} icon="doors">
				{systemConfig.devVersion >= 2 ? (
					<React.Fragment>
						<ReloadButton onReload={onReloadDoors} />
						{!isInEmergencyMode && (
							<TypedAddButton
								hasControllerDependency={false}
								hasSiteDependency={true}
								screen={userConstants.screens.DOORS}
								onAddNewClick={onAddNewDoorClicked}
								items={[
									{
										key: 'hid_online',
										label: translate.byKey('hid_online_label'),
									},
									{
										key: 'aperio_online',
										label: translate.byKey('aperio_online_label'),
									},
									{
										key: 'aperio_offline',
										label: translate.byKey('aperio_offline_label'),
									},
									{
										key: 'pulse_offline',
										label: translate.byKey('pulse_offline_label'),
									},
								]}
							/>
						)}
					</React.Fragment>
				) : (
					<AddButton
						hasControllerDependency={false}
						hasSiteDependency={true}
						screen={userConstants.screens.DOORS}
						onAddNewClick={onAddNewDoorClicked}
					/>
				)}
			</PageHeader>

			<TableWithFiltersSection
				loading={{
					spinning: loadingDoors || loadingAssignedReaders || loading,
					indicator: <Spin active={true} />,
				}}
				columns={getColumns(systemConfig.devVersion)}
				dataSource={filteredData}
				rowKey={(record) => record.portalId + record.siteId + record.systemId}
				onChange={tableChanged}
				onRow={(record) => ({
					onClick: () => onRowClick(record),
				})}
				scroll={getScrollHeightOptions()}
				pagination={{
					showTotal: (total, range) => translate.byKeyFormatted('results_counter_label', [range[0], range[1], total]),
					position: ['bottomCenter'],
					pageSize: pageSize,
					current: currentPage,
					showSizeChanger: true,
				}}
			/>

			<DndProvider backend={Backend}>
				<OfflineDoorForm
					visible={doorTypeVisible && systemConfig.devVersion >= 2}
					doorType={doorTypeVisible}
					selected={selected}
					doors={doors}
					isBadReader={hasBadReaders}
					onClose={onDoorCancel}
					onUpdated={onDoorUpdated}
					controllers={controllers}
					updateDoorsCollection={() => {
						onReloadDoors();
					}}
				/>

				<ReaderForm
					visible={readerFormVisible}
					selected={{ ...selectedReader, doorType: '' }}
					onClose={onReaderFormCancel}
					onUpdated={onReaderUpdated}
					selectedController={[controllers[0]]}
				/>
			</DndProvider>
		</Page>
	);
};

export default Doors;

const auditCollectedDateTimeStyles = (theme) => ({
	color: theme.colors.gray[400],
	fontSize: '12px',
});
