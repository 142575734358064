export const nameField = 'name';
export const locationField = 'location';
export const doorGroupsField = 'doorGroups';
export const doorGroupField = 'doorGroup';
export const accessScheduleField = 'accessSchedule';
export const doorsField = 'doors';
export const doorField = 'door';
export const peopleField = 'people';
export const validFromField = 'validFrom';
export const validUntilField = 'validUntil';
