import React from 'react';
import ReactLoadingOverlay from 'react-loading-overlay';
import { useTheme } from 'emotion-theming';

/** @type {ReactLoadingOverlay} */
const Spin = ({ children, active, ...props }) => {
	const theme = useTheme();
	return (
		<ReactLoadingOverlay
			text=""
			{...props}
			active={!!active}
			styles={{
				overlay: (base) => ({
					...base,
					background: theme.colors.white,
				}),
				spinner: (base) => ({
					...base,
					'& svg circle': {
						stroke: theme.colors.brand[500],
					},
				}),
				content: (base) => ({
					...base,
					color: theme.colors.gray[400],
				}),
			}}
			spinner={true}
		>
			{children}
		</ReactLoadingOverlay>
	);
};

export default Spin;
