import { PromiseCache } from './PromiseCache';
import axiosRetry from 'axios-retry';
import { create } from 'apisauce';
import axios from 'axios';
import { getRequestConfig } from './configModule';
import { method } from './apiConstants';

import formatResponse, { getKey } from './utils/formatResponse';
import moment from 'moment';
import systemConfig from '~/screens/_shared/systemConfig';
import { getUserAuthCacheData, logout, mutateUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getCurrentSystemSiteCacheData } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { showWarningToast } from '../toast';
import { translator } from '../AppLocale';

const customAxiosInstances = new Map();
let currentAuthRefreshRequest = null;
const promiseCache = new PromiseCache();
let pendingTimeout = null;

export function getExpiresInOverride() {
	let expiresInOverride = Number(localStorage.getItem('expiresInOverride'));
	if (!expiresInOverride || isNaN(expiresInOverride)) {
		expiresInOverride = null;
	}

	return expiresInOverride;
}

function filterExpiredTokens(user) {
	const hasExpiredTokens = user.tokens.some((token) =>
		moment(token.tokenCreationTime).clone().add(token.expiresIn, 'milliseconds').isSameOrBefore(moment())
	);

	if (!user.tokens.length || (hasExpiredTokens && user.tokens.length === 1)) {
		showWarningToast({
			message: translator.byKey('your_session_has_expired_redirecting_to_Login'),
		});
		logout();
		return;
	}

	if (hasExpiredTokens && user.tokens.length > 2) {
		const validTokens = user.tokens.filter((token) =>
			moment(token.tokenCreationTime).clone().add(token.expiresIn, 'milliseconds').isAfter(moment())
		);

		if (!validTokens.length) {
			showWarningToast({
				message: translator.byKey('your_session_has_expired_redirecting_to_Login'),
			});
			logout();
			return;
		}

		mutateUserAuthData({ ...user, validTokens });
	}
}

function applyRetryLogic(axiosInstance) {
	axiosRetry(axiosInstance, {
		retries: 3,
		retryCondition: (error) => {
			const isTooManyRequests = error.response.status === 429;
			const isInternalServerError =
				error.response.status === 500 && error.response.data && error.response.data.message === 'No message available';

			if (isTooManyRequests || isInternalServerError) {
				console.group(`Common API Error - Attempting Retry`);
				const response = {
					Url: error.config.url,
					'Rest Method': error.config.method,
					Parameters: JSON.stringify(error.config.params),
					Data: error.config.data,
					'Authorization Header': error.config.headers['Authorization'],
				};
				console.table(response);
				console.groupEnd();
			}
			return isTooManyRequests || isInternalServerError;
		},
		retryDelay: () => 1000,
	});

	return axiosInstance;
}

function getAxiosInstance(accessToken, isSystemManagerUrl = false) {
	const axioConfig = {
		headers: {
			'Application-ID': systemConfig.applicationId,
			'Application-Version': systemConfig.jiraVersion,
		},
	};

	if (accessToken) {
		axioConfig.headers = {
			...axioConfig.headers,
			Authorization: `Bearer ${accessToken}`,
		};
	}

	if (isSystemManagerUrl) {
		return create({ axiosInstance: applyRetryLogic(axios.create(axioConfig)) });
	}

	if (accessToken && !customAxiosInstances.has(accessToken)) {
		const instance = create({ axiosInstance: applyRetryLogic(axios.create(axioConfig)) });

		customAxiosInstances.set(accessToken, instance);

		return instance;
	}

	return create({ axiosInstance: applyRetryLogic(axios.create(axioConfig)) });
}

async function doRequest(apiMethod, mapper, requestData, cacheOptions = null) {
	const isSystemManagerUrl = mapper.includes ? mapper.includes(systemConfig.emeaEndPoint) : false;

	if (isSystemManagerUrl && mapper.replace) {
		mapper = mapper.replace(systemConfig.emeaEndPoint, '');
	}

	const { tokens } = getUserAuthCacheData();
	const token = new axios.CancelToken.source();

	let config = getRequestConfig(apiMethod, mapper, requestData, token);

	const instance = getAxiosInstance(tokens.slice(-1).pop()?.accessToken, isSystemManagerUrl);
	const { method: RESTMethod, url, body, ...axiosConfig } = config;

	if (body && (body.removeDomainKey || !body.removeDomainKey)) {
		delete body.removeDomainKey;
	}

	if (body?.removeProperties?.length) {
		Object.keys(body).forEach((property) => {
			if (body.removeProperties.includes(property)) {
				delete body[property];
			}
		});

		delete body.removeProperties;
	}

	if (
		apiMethod === method.post ||
		apiMethod === method.put ||
		apiMethod === method.patch ||
		apiMethod === method.delete
	) {
		let extraData = requestData instanceof FormData ? {} : Object.assign({}, requestData);

		if (!Array.isArray(cacheOptions?.removeListCache)) {
			cacheOptions = cacheOptions || {};

			cacheOptions.removeListCache = [
				{ params: { 'detail-level': 'FULL' } },
				{ params: { 'detail-level': 'SIMPLE' } },
				{ params: { 'detail-level': 'EXTENDED' } },
				{},
				extraData,
			];
		}

		if (!Array.isArray(cacheOptions?.removeGetCache)) {
			cacheOptions = cacheOptions || {};
			cacheOptions.removeGetCache = [{}, extraData];
		}
	}

	const key = `${RESTMethod}:${url}:${JSON.stringify(body)}`;

	if (cacheOptions?.removeExistingKey && !Array.isArray(cacheOptions.removeExistingKey)) {
		cacheOptions.removeExistingKey = [cacheOptions.removeExistingKey];
	} else if (cacheOptions) {
		cacheOptions.removeExistingKey = cacheOptions.removeExistingKey || [];
	}

	if (Array.isArray(cacheOptions?.removeListCache)) {
		cacheOptions.removeExistingKey = cacheOptions.removeExistingKey.concat(
			cacheOptions.removeListCache.map((params) => getCacheKey(method.list, mapper, params))
		);
		delete cacheOptions?.removeListCache;
	}

	if (Array.isArray(cacheOptions?.removeGetCache)) {
		cacheOptions.removeExistingKey = cacheOptions.removeExistingKey.concat(
			cacheOptions.removeGetCache.map((params) => getCacheKey(method.get, mapper, params))
		);
		delete cacheOptions?.removeGetCache;
	}

	return promiseCache.getOrAdd(
		key,
		async () => {
			switch (apiMethod) {
				case method.get:
				case method.delete:
				case method.list:
					return await instance[RESTMethod](url, {}, axiosConfig);
				default:
					return await instance[RESTMethod](url, body, axiosConfig);
			}
		},
		cacheOptions
	);
}

export function getCacheKey(apiMethod, apiEntity, requestData) {
	const { customer } = getUserAuthCacheData();
	const token = new axios.CancelToken.source();
	const { system, site } = getCurrentSystemSiteCacheData();

	let siteId = requestData.siteId;

	if (!siteId && site?.siteId) {
		siteId = site.siteId; // check if we passed in a siteId, use that as preference
	}

	let config = getRequestConfig(apiMethod, apiEntity, requestData, token, {
		systemId: system.systemId,
		siteId,
		customer,
	});

	const { method: RESTMethod, url, body } = config;

	return `${RESTMethod}:${url}:${JSON.stringify(body)}`;
}

export class ApiRequester {
	constructor(apiConstants, errorHandler) {
		let {
			method: apiMethod,
			entity: apiEntity,
			requiresUnformattedResponse,
			requiresUnformattedRequest,
			removeDomainKey,
		} = apiConstants;

		this.apiMethod = apiMethod;
		this.apiEntity = apiEntity;
		this.errorHandler = errorHandler;
		this.requiresUnformattedResponse = requiresUnformattedResponse || false;
		this.requiresUnformattedRequest = requiresUnformattedRequest || false;
		this.removeDomainKey = removeDomainKey || false;
	}

	async refreshAuthToken(currentToken) {
		const tokenCreationTime = moment();
		const user = getUserAuthCacheData();
		if (currentToken?.refreshToken) {
			let refreshTime = (currentToken.expiresIn || 0) * 0.8;
			currentAuthRefreshRequest = doRequest(
				method.post,
				'/authentication/token-generation/',
				{
					params: { 'client-id': systemConfig.emeaClientId },
					__additionalHeaders: {
						'refresh-token': currentToken.refreshToken,
						'access-token': currentToken.accessToken,
					},
					__contentType: 'application/vnd.assaabloy.msfss.authentication-7.0+json',
				},
				{
					expiry: moment().add(refreshTime, 'milliseconds'),
					shared: true,
				}
			);

			let response = await currentAuthRefreshRequest;

			if (response.ok) {
				response = {
					accessToken: response.data.accessToken,
					expiresIn: response.data.accessTokenAliveDuration
						? response.data.accessTokenAliveDuration * 1000
						: 10 * 60 * 1000,
					refreshToken: response.data.refreshToken,
				};

				if (!response.refreshToken) {
					response.refreshToken = (user.tokens || [])
						.concat([])
						.reverse()
						.find((other) => !!other.refreshToken)?.refreshToken;
				}

				const expiresInOverride = getExpiresInOverride();

				if (expiresInOverride) {
					response.expiresIn = expiresInOverride;
				}

				mutateUserAuthData({
					...user,
					tokens: [
						{
							customerId: currentToken.customerId,
							...response,
							tokenCreationTime,
						},
					],
				});

				currentAuthRefreshRequest = null;
			} else {
				if (response.status === 400) {
					response.status = 401;
				}
			}
		} else if (currentAuthRefreshRequest) {
			await currentAuthRefreshRequest;
		}
	}

	async fetchData(requestData, cacheOptions, retryOnAuthFail = true) {
		requestData = requestData || {};

		const user = getUserAuthCacheData();

		if (user.tokens.length) {
			const currentToken = user.tokens[user.tokens.length - 1];

			let refreshTime = (currentToken.expiresIn || 0) * 0.8;
			let nextRefresh = moment(currentToken.tokenCreationTime).clone().add(refreshTime, 'milliseconds');

			clearTimeout(pendingTimeout);
			pendingTimeout = null;

			if (moment().isAfter(nextRefresh)) {
				await this.refreshAuthToken(currentToken);
				nextRefresh = moment().add(refreshTime, 'milliseconds');
				filterExpiredTokens(user);
			}

			let timeout = nextRefresh.diff(moment(), 'milliseconds');
			timeout = timeout > 0 ? timeout : 0;

			const handler = () => {
				const user = getUserAuthCacheData();
				this.refreshAuthToken((user.tokens || []).slice(-1).pop() || null)
					.then(() => {
						const user = getUserAuthCacheData();
						filterExpiredTokens(user);
						pendingTimeout = setTimeout(handler, refreshTime);
					})
					.catch(() => {
						clearTimeout(pendingTimeout);
						pendingTimeout = null;
					});
			};

			if (!pendingTimeout) {
				pendingTimeout = setTimeout(handler, timeout);
			}
		}

		requestData.requiresUnformattedRequest = this.requiresUnformattedRequest;
		requestData.removeDomainKey = this.removeDomainKey;
		let response = await doRequest(this.apiMethod, this.apiEntity, requestData, cacheOptions);
		if (response.ok) {
			if (this.requiresUnformattedResponse) {
				const rawResponse = Object.assign({}, response.data);

				if ('attributes' in rawResponse) {
					const newResponse = Object.assign({}, response);

					if (newResponse.data && typeof newResponse.data === 'object') {
						newResponse.data = JSON.parse(JSON.stringify(newResponse.data));
					}

					const temp = formatResponse(newResponse, this.apiEntity, this.apiMethod);
					const tempKey = getKey(this.apiEntity) || this.apiEntity;
					// Raw responses can't be reused for subsequent commands without the
					// extracted ID.
					rawResponse.attributes[`${tempKey}Id`] = temp[`${tempKey}Id`];
				}
				response = rawResponse;
			} else {
				response = formatResponse(response, this.apiEntity, this.apiMethod);
			}

			return response;
		} else {
			if (!requestData.ignoreGlobalHandlers) {
				this.errorHandler({
					response,
					entity: this.apiEntity,
					method: this.apiMethod,
				});
			}

			return Promise.reject(response);
		}
	}
}
