import { useState } from 'react';

import mappers from '~/screens/_shared/mappers';
import { useDeleteApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { cacheKeys } from '~/screens/Doors/hooks/doorHelper';

/**
 * Hook for removing a reader from a door.
 *
 * Needs to:
 * 1. Delete the peripheralDevice representing that reader
 * 2. Create a new peripheralDevice for the door (without FixedAddress, etc.)
 * 3. Get the door record
 * 4. Update door description to replace old peripheralDeviceId with new one.
 */
export function useDeleteReader() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const [deletePeripheralDevice] = useDeleteApi(mappers.peripheralDevice);
	const { translate } = useLocale();

	/**
	 * Delete a reader
	 * @param {*} formData
	 */
	async function deleteReader(formData) {
		setIsLoading(true);
		setCurrentStep(translate.byKey('deleting_reader'));
		try {
			await deletePeripheralDevice(
				{ peripheralDeviceId: formData.deviceId },
				{
					removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
				}
			);
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	}

	return [deleteReader, isLoading, currentStep];
}
