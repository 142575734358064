export const blockListState = {
	ENABLED: 'ENABLED',
	DISABLED: 'DISABLED',
	LOCKED_OUT: 'Locked Out',
	BLOCKED: 'BLOCKED',
	LOST: 'Lost',
	STOLEN: 'Stolen',
	DAMAGED: 'Damaged',
	DESTROYED: 'Destroyed',
	INACTIVITY: 'Inactivity',
	EXPIRED: 'Expired',
	RENEWAL_NEEDED: 'Renewal Needed',
	ANTIPASSBACK_VIOLATED: 'Antipassback Violated',
};
