import React from 'react';
import { Modal, Space } from 'antd';
import { Button } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useTheme } from 'emotion-theming';
import { usePostApi, usePutApi } from '~/screens/_shared/useApi';
import { mutateUserAuthData, useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { css } from '@emotion/core';

const Password = ({ emailAddress }) => {
	const theme = useTheme();
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const [resetPassword] = usePostApi('/authentication/reset-password-request/', () => {}, {
		removeDomainKey: true,
	});

	const [setMFA] = usePutApi(`/administration/administrator/${user.administratorId}/mfa-settings/`, () => {}, {
		removeDomainKey: true,
	});

	const onResetPassword = async () => {
		const languageCode = window.localStorage.getItem('selectedLanguage');
		const res = await resetPassword({
			userName: emailAddress,
			languageCode,
			__contentType: 'application/vnd.assaabloy.msfss.authentication-5.0+json',
		});

		if (res.success) {
			Modal.confirm({
				title: translate.byKey('reset_password'),
				content: translate.byKey('password_reset_success'),
				okText: translate.byKey('ok'),
				okButtonProps: {
					style: {
						backgroundColor: theme.colors.brand[500],
						borderColor: theme.colors.brand[500],
					},
				},
				destroyOnClose: true,
				cancelButtonProps: { style: { display: 'none' } },
			});
		}
	};

	const confirmEnableMFA = () => {
		Modal.confirm({
			title: translate.byKey('enable_multi_factor_authentication'),
			content: translate.byKey('enable_multi_factor_authentication_confirm'),
			okText: translate.byKey('ok'),
			okButtonProps: {
				style: {
					backgroundColor: theme.colors.brand[500],
					borderColor: theme.colors.brand[500],
				},
			},
			onOk: enableMFA,
			cancelText: translate.byKey('cancel'),
			destroyOnClose: true,
		});
	};

	const confirmDisableMFA = () => {
		Modal.confirm({
			title: translate.byKey('disable_multi_factor_authentication'),
			content: translate.byKey('disable_multi_factor_authentication_confirm'),
			okText: translate.byKey('ok'),
			okButtonProps: {
				style: {
					backgroundColor: theme.colors.brand[500],
					borderColor: theme.colors.brand[500],
				},
			},
			onOk: disableMFA,
			cancelText: translate.byKey('cancel'),
			destroyOnClose: true,
		});
	};

	const enableMFA = async () => {
		const res = await setMFA({
			mfaMethods: ['AUTHENTICATOR_APP'],
			__contentType: 'application/vnd.assaabloy.msfss.administration-14.3+json',
		});

		if (res.success) {
			mutateUserAuthData({ ...user, mfaEnabled: true });
		}
	};

	const disableMFA = async () => {
		const res = await setMFA({
			mfaMethods: ['NONE'],
			__contentType: 'application/vnd.assaabloy.msfss.administration-14.3+json',
		});

		if (res.success) {
			mutateUserAuthData({ ...user, mfaEnabled: false });
		}
	};

	return (
		<Space direction="vertical" css={spaceStyles}>
			<Button block type="primary" onClick={onResetPassword}>
				{translate.byKey('reset_password')}
			</Button>

			{user.mfaEnabled ? (
				<Button block type="primary" onClick={confirmDisableMFA}>
					{translate.byKey('disable_multi_factor_authentication')}
				</Button>
			) : (
				<Button block type="primary" onClick={confirmEnableMFA}>
					{translate.byKey('enable_multi_factor_authentication')}
				</Button>
			)}
		</Space>
	);
};

export default Password;

const spaceStyles = css({
	display: 'flex',
});
