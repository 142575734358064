import { getReadableName } from '~/screens/_shared/getReadableName';
import { translator } from '~/screens/_shared/AppLocale';

export const generateSiteControllerEnabledFilterProps = (
	siteControllers,
	deletedControllers,
	value,
	defaultControllerId
) => {
	const controllerReadableName = getReadableName(
		siteControllers?.find((x) => x.controllerId === value)?.name ??
			deletedControllers?.find((x) => x.controllerId === value)?.controllerName
	);
	const isDeletedValue = Boolean(deletedControllers?.find((x) => x.controllerId === value));
	const valueLabel = `${controllerReadableName}${isDeletedValue ? ` (${translator.byKey('deleted_upper_case')})` : ''}`;

	return {
		getValueLabel: () => valueLabel,
		clearable: value !== defaultControllerId,
		tooltip:
			Boolean(defaultControllerId) &&
			translator.byKeyFormatted('reset_to', {
				value: valueLabel,
			}),
	};
};

export const generateSystemControllerEnabledFilterProps = (controllers, value, defaultControllerId) => {
	const valueLabel = getReadableName(controllers?.find((x) => x.controllerId === value)?.name);

	return {
		getValueLabel: () => valueLabel,
		clearable: value !== defaultControllerId,
		tooltip:
			Boolean(defaultControllerId) &&
			translator.byKeyFormatted('reset_to', {
				value: valueLabel,
			}),
	};
};
