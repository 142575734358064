import React from 'react';
import Text from 'antd/lib/typography/Text';

import { useLocale } from '~/screens/_shared/AppLocale';

export const NoResultsFilterPanel = () => {
	const { translate } = useLocale();

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '80px',
			}}
		>
			<Text>{translate.byKey('no_results')}</Text>
		</div>
	);
};
