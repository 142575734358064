const authenticationProfiles = {
	base: {
		name: true,
		description: false,
		accessPoints: ({ points }, { system, site }) => {
			let accessPoints = [];

			points &&
				points.forEach((point) => {
					const { portalId, accessPointId } = point;
					accessPoints.push({
						key: {
							systemId: system,
							siteId: site,
							portalId,
							accessPointId,
						},
					});
				});

			return accessPoints;
		},
		authenticationPolicies: ({ policies }, { system }) => {
			let authenticationPolicies = [];

			policies &&
				policies.forEach((policy) => {
					const { calendarId, scheduleId, scheduleState, securityLevelId, authenticationMode } = policy;

					authenticationPolicies.push({
						schedule: {
							key: {
								systemId: system,
								calendarId,
								scheduleId,
							},
						},
						scheduleState,
						securityLevel: {
							key: {
								systemId: system,
								securityLevelId,
							},
						},
						authenticationMode,
					});
				});
			return authenticationPolicies;
		},
		defaultSecurityLevel: ({ defaultSecurityLevelId }, { system }) => {
			return {
				key: {
					systemId: system,
					securityLevelId: defaultSecurityLevelId,
				},
			};
		},
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'authentication-profile'],
};

export default authenticationProfiles;
