import React from 'react';
import { Col, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import moment from 'moment/moment';

import { useLocale } from '~/screens/_shared/AppLocale';
import { isPulseKey } from '~/components/features/people/People.utils';
import { convertCredentialHexToDecimal, updateOnlineIdentifier } from '~/utils/credential/credential';
import { Button, Item } from '~/components';
import { getCredentialStateKey, useCredentialStateUpdate } from '~/hooks/data/credentials/useCredentialStateUpdate';
import { CredentialState } from '~/constants/Credential';
import { showSuccessToast } from '~/screens/_shared/toast';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useCredentials } from '~/hooks/data/credentials/useCredentials';
import { getBlockedCredentialsKey } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { getCredentialKey, useCredentialUpdate } from '~/hooks/data/credentials/useCredentialUpdate';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const UNBLOCK_REASON_FIELD_NAME = 'unblock-reason';

const UNBLOCK_REASON_MAX_LENGTH = 50;

export const UnblockCredentialModal = ({ credential, onClose }) => {
	const [form] = Form.useForm();
	const unblockReason = Form.useWatch([UNBLOCK_REASON_FIELD_NAME], form);

	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, mode, sortBy, sortDirection, person },
	} = useQueryParams();
	const { mutate: reloadBlockedCredentials } = useCredentials(
		getBlockedCredentialsKey({
			customerId,
			systemId,
			pageSize,
			pageNumber,
			mode,
			sortBy,
			sortDirection,
			person,
		}),
		{ revalidateOnMount: false }
	);
	const { trigger: updateCredentialState, isMutating: isUpdatingCredentialState } = useCredentialStateUpdate(
		getCredentialStateKey({
			customerId,
			systemId,
			credentialId: credential.credentialId,
		})
	);
	const { trigger: updateCredential, isMutating: isUpdatingCredential } = useCredentialUpdate(
		getCredentialKey({
			customerId,
			systemId,
		})
	);

	const isUnblockingCredential = isUpdatingCredentialState || isUpdatingCredential;

	const onSubmit = async (values) => {
		const unblockReasonValue = values[UNBLOCK_REASON_FIELD_NAME];

		try {
			await updateCredentialState({
				credentialId: credential.credentialId,
				reason: CredentialState.ENABLED,
				description: unblockReasonValue,
			});
			await updateCredential({
				...credential,
				credentialHolderId: credential.credentialHolder.credentialHolderId,
				credentialIdentifiers: updateOnlineIdentifier(credential.credentialIdentifiers, false),
				description: moment(),
			});
			showSuccessToast(translate.byKey('unblocked_credential_successfully'));
			reloadBlockedCredentials();
			onClose();
		} catch (error) {
			// handled globally elsewhere atm
		}
	};

	const isUnblockButtonDisabled =
		isUnblockingCredential || !Boolean(unblockReason?.trim()) || unblockReason.length > UNBLOCK_REASON_MAX_LENGTH;

	return (
		<Modal
			open
			title={credential.credentialHolder.name}
			onCancel={onClose}
			maskClosable={false}
			footer={[
				<Button key="cancel" onClick={onClose} disabled={isUnblockingCredential}>
					{translate.byKey('cancel')}
				</Button>,
				<Button key="unblock" htmlType="submit" type="primary" form="unblock" disabled={isUnblockButtonDisabled}>
					{translate.byKey('unblock')}
				</Button>,
			]}
		>
			<Row>
				<Col span={8}>
					<Text strong>{translate.byKey('credential_type')}</Text>
				</Col>
				<Col span={8}>
					<Text strong>{translate.byKey(isPulseKey(credential) ? 'pulse_v2' : 'credential')}</Text>
				</Col>
			</Row>
			<Row>
				<Col span={8}>
					<Text strong>{translate.byKey('credential_number_v2')}</Text>
				</Col>
				<Col span={8}>
					<Text strong>{convertCredentialHexToDecimal(credential)}</Text>
				</Col>
			</Row>
			<Row>
				<Col span={8}>
					<Text strong>{translate.byKey('date_time')}</Text>
				</Col>
				<Col span={8}>
					<Text strong>
						{credential.description ? moment(credential.description).format('L') : translate.byKey('missing_date_v2')}
					</Text>
				</Col>
			</Row>

			<Form name="unblock" layout="vertical" size="medium" scrollToFirstError form={form} onFinish={onSubmit}>
				<Item
					name={UNBLOCK_REASON_FIELD_NAME}
					label={translate.byKey('unblock_reason')}
					style={{ marginTop: '8px' }}
					validateTrigger={'onChange'}
					rules={[
						{
							required: true,
							whitespace: true,
							message: translate.byKey('unblock_is_required'),
						},
						{
							max: UNBLOCK_REASON_MAX_LENGTH,
							message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
								fieldName: translate.byKey('unblock_reason'),
								maxLength: UNBLOCK_REASON_MAX_LENGTH,
							}),
						},
					]}
				>
					<TextArea
						maxLength={UNBLOCK_REASON_MAX_LENGTH}
						showCount
						disabled={isUnblockingCredential}
						autoSize={{ minRows: 4, maxRows: 4 }}
					/>
				</Item>
			</Form>
		</Modal>
	);
};
