import { LoadingOutlined } from '@ant-design/icons';
import { Col, Form, List, Row, Steps, Tooltip, Input as BaseInput } from 'antd';
import random from 'lodash/random';
import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Input, Item, Modal, Spin } from '~/components';

import {
	ConnectionError,
	ControllerOnboardedAlreadyError,
	useCreateController,
	useDeleteController,
	useCanDeleteController,
} from './hooks';
import { usePutApi, useGetApi, useListApi, usePostApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';

import { getReadableName } from '~/screens/_shared/getReadableName';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { controllerStates, controllerUpdateTypes, controllerConfigureTypes } from './config';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';
import { getCurrentControllerState, getReadersFromController } from './shared';
import { useLocale } from '~/screens/_shared/AppLocale';
import StatusControllerGrid from './StatusControllerGrid';
import { showErrorToast, showSuccessToast } from '~/screens/_shared/toast';
import Meta from 'antd/lib/card/Meta';
import { getRelevantSync } from '~/screens/Controllers/index';
import systemConfig from '~/screens/_shared/systemConfig';
import { getCurrentAdministrator, currentAdminHasRequiredRole } from '~/screens/_shared/userRoleConstants';
import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { getUpdateControllerKey, useUpdateController } from '~/hooks/data/controllers/useUpdateController';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { MAC_ADDRESS } from '~/utils/regex';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

function getDefaultController() {
	return {
		name: '',
		configuration: {
			additionalSettings: {},
		},
	};
}

const NAME_FIELD_NAME = 'name';
const SERIAL_NUMBER_FIELD_NAME = 'serialNumber';
const MAC_ADDRESS_FIELD_NAME = 'macAddress';

export const ControllerForm = ({ visible, hideModal, selected, onUpdated, controllerType }) => {
	const { translate } = useLocale();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [readers, setReaders] = useState([]);
	const [controller, setController] = useState(getDefaultController());
	const [systemSyncState, setSystemSyncState] = useState({});
	const [onlineState, setOnlineState] = useState(null);
	const [modalTitle, setModalTitle] = useState('');
	const {
		data: { system, site },
	} = useCurrentSystemSite();
	const { isInEmergencyMode } = useSiteControllersEmergencyRecords();

	const primaryButtonProps = {
		style: {
			backgroundColor: theme.colors.brand[500],
			borderColor: theme.colors.brand[500],
			color: theme.colors.white,
		},
	};

	const { data: user } = useUserAuthData();
	const [form] = Form.useForm();
	const {
		[NAME_FIELD_NAME]: name,
		[SERIAL_NUMBER_FIELD_NAME]: serialNumber,
		[MAC_ADDRESS_FIELD_NAME]: macAddress,
	} = Form.useWatch([], form) || {};

	const siteId = selected?.siteId || site?.siteId;

	const [canDeleteController] = useCanDeleteController();
	const [updateController] = usePutApi(mappers.controller);
	const { trigger: updateControllerDevice } = useUpdateController(
		getUpdateControllerKey({
			customerId: system.customerId,
			systemId: system.systemId,
			siteId,
			controllerId: selected?.controllerId || controller?.controllerId,
		})
	);
	const [getController] = useGetApi(mappers.controller);
	const [getControllerStatus] = useGetApi(mappers.deviceStatus);
	const [getSyncState] = useListApi(mappers.syncState); // TODO this needs more time to update.
	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);
	const [createController, discoverReaders, , currentCreateStep] = useCreateController();
	const [getControllers] = useListApi(mappers.controller);
	const [reSync] = usePostApi(
		'/customer/:customerId/system/:systemId/site/:siteId/controller/:controllerId/action',
		() => {},
		{ removeDomainKey: true }
	);

	const getRelevantSyncState = getRelevantSync.bind({ getSyncState, getAssignedReaders, getControllerStatus });

	const currentAdmin = getCurrentAdministrator();
	const isClusterController = controllerType === 'improx';

	function getControllerMacAddress() {
		if (controller.configuration) {
			return controller.configuration.additionalSettings.macAddress;
		}
	}

	function getControllerSerialNumber() {
		if (controller.configuration) {
			return controller.configuration.additionalSettings.serialNumber;
		}
	}

	function cannotSyncNow() {
		return (
			onlineState === 'UNKNOWN' &&
			getCurrentControllerState(systemSyncState) === 'UPDATE_FAILED' &&
			readers.length === 0
		);
	}

	function updateReaders(updatedController) {
		const someReaders = getReadersFromController(updatedController);
		setReaders(someReaders);
		return someReaders;
	}

	async function refreshStatus(updatedController) {
		const onlineStatus = await getControllerStatus({ deviceId: updatedController.controllerId, siteId });
		setOnlineState(onlineStatus.connectionStatus);

		let allSyncState = await getRelevantSyncState(updatedController, onlineStatus, siteId);

		if (Array.isArray(allSyncState)) {
			const controllerState = allSyncState.find((state) => state.controllerId === updatedController.controllerId);
			allSyncState = { controllerInfoSyncState: controllerState?.syncState || '' };
		}

		setSystemSyncState(allSyncState);
	}

	useEffect(() => {
		if (visible === true) {
			setModalTitle(
				translate.byKey(isClusterController ? 'configure_cluster_controller' : 'configure_updater_controller')
			);
			form.resetFields();
			setController(getDefaultController());
			setReaders([]);
			setSystemSyncState({});
			setOnlineState(null);
			form.setFieldsValue({
				macAddress: '',
				serialNumber: '',
				protocol: controllerType,
			});
			setIsLoading(true);

			let results = selected ? Promise.resolve([selected]) : getControllers({ params: { 'detail-level': 'FULL' } });

			results
				.then(async (controllers) => {
					const firstController = controllers[0];
					if (!firstController) {
						return;
					}

					if (Object.keys(firstController).length === 0) {
						return;
					}

					setController(firstController);
					const { macAddress, serialNumber } = firstController.configuration.additionalSettings;
					updateReaders(firstController);

					// Update the fields
					if (macAddress && serialNumber) {
						form.setFieldsValue({
							name: getReadableName(firstController.name),
							macAddress,
							serialNumber,
							protocol: firstController.protocol,
						});
					} else {
						form.setFieldsValue({
							macAddress: '',
							serialNumber: '',
							protocol: 'improx',
						});
					}

					await refreshStatus(firstController);
				})
				.catch((ex) => {
					console.error(ex);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			hideModal();
		}
	}, [visible, selected]);

	/**
	 * Perform an Update request. All update requests come through here.
	 */
	async function updateGlobalController(requestTypeVal, controllerId) {
		const latestController = await getController({ controllerId, siteId });
		const { configuration } = latestController;
		const { additionalSettings } = configuration;
		const { serialNumber, macAddress } = additionalSettings;
		const nonce = random(5000);

		await updateController({
			protocol: latestController.protocol,
			siteId,
			controllerId,
			macAddress,
			serialNumber,
			nonce,
			additionalSettings: {
				...additionalSettings,
				requestType: requestTypeVal,
				FULL_SYNC: requestTypeVal === controllerUpdateTypes.fullSync.value,
			},
			siteName: `${site.name}|${nonce}|`,
		});

		showSuccessToast(translate.byKey('controller_Sync_initiated'));
		hideModal();

		return latestController;
	}

	function refreshOnConnectionFailure(ex) {
		setIsLoading(true);
		refreshStatus(ex.controller).finally(() => {
			setIsLoading(false);
			setController(ex.controller);
			updateReaders(ex.controller);
		});
	}

	const forceSyncModal = {
		okText: translate.byKey('force_sync'),
		okButtonProps: primaryButtonProps,
		cancelText: translate.byKey('cancel'),
		onCancel: () => {},
	};

	async function onSubmit(values, numOfRetries = 0, forceSync = false) {
		setIsLoading(true);
		const controllerId = controller.controllerId || '';
		const { macAddress, serialNumber, name } = values;
		const nonce = random(5000);

		try {
			if (numOfRetries === 0 && controllerId && controllerId.length > 0) {
				const requestTypeVal = values.requestType || controllerUpdateTypes.fullSync.value;

				const allSyncState = await getRelevantSyncState({ controllerId: controllerId }, null, siteId);

				if (
					getCurrentControllerState(allSyncState, true) === 'UPDATED' &&
					getCurrentControllerState(systemSyncState, true) === 'PENDING_UPDATE'
				) {
					showSuccessToast(translate.byKey('previous_sync_complete'));
				}

				setSystemSyncState(allSyncState);

				const onForceSyncAccepted = () => {
					onSubmit(values, numOfRetries, true).catch((ex) => {
						Modal.warn({
							title: translate.byKey('error_with_sync'),
							content: translate.byValue(ex.message || ex),
						});
					});
				};

				if (readers.length === 0 && onlineState === 'DISCONNECTED') {
					const currentUiSyncState = await getRelevantSyncState(
						{ controllerId: controllerId },
						{
							connectionStatus: onlineState,
						},
						siteId
					);

					if (
						!forceSync &&
						readers.length === 0 &&
						onlineState === 'DISCONNECTED' &&
						getCurrentControllerState(currentUiSyncState, true) === 'UPDATE_FAILED'
					) {
						Modal.confirm(
							Object.assign({}, forceSyncModal, {
								title: translate.byKey('sync_not_advised'),
								content: translate.byKey(
									'it_does_not_appear_as_if_the_controller_is_connected_and_no_readers_are_detected_try_clicking_discover_readers_first_do_you_want_to_force_a_sync'
								),
								onOk: onForceSyncAccepted,
							})
						);
						return;
					}
				}

				if (!forceSync && getCurrentControllerState(allSyncState, true) === 'PENDING_UPDATE') {
					Modal.confirm(
						Object.assign({}, forceSyncModal, {
							title: translate.byKey('sync_in_progress_title'),
							content: translate.byKey('update_in_progress_message'),
							onOk: onForceSyncAccepted,
						})
					);
					return;
				}

				const updatedController = await updateGlobalController(requestTypeVal, controllerId);

				if (onUpdated) {
					onUpdated(updatedController);
				}

				// This updates our state to the appropriate value
				hideModal();
				showSuccessToast(translate.byKey('sync_in_progress_toast_message'));

				setController(updatedController);
				updateReaders(updatedController);
				return updatedController;
			} else {
				const controllerDetails = await createController({
					protocol: values.protocol,
					macAddress,
					serialNumber,
					nonce,
					name: name,
					action: controllerConfigureTypes.onboarding.value,
				});

				if (onUpdated) {
					form.setFieldsValue({ name });
					onUpdated(controllerDetails);
				}

				setController(controllerDetails);
				updateReaders(controllerDetails);

				showSuccessToast(translate.byKey('controller_saved_message'));
				await refreshStatus(controllerDetails);
				return controllerDetails;
			}
		} catch (ex) {
			if (ex instanceof ControllerOnboardedAlreadyError) {
				Modal.warn({
					title: translate.byKey('on_boarding_error_title'),
					content: translate.byKey('on_boarding_error_message'),
				});
				return;
			}

			if (ex instanceof ConnectionError) {
				if (numOfRetries === 2) {
					Modal.warn({
						title: translate.byKey('controller_connect_fail_title'),
						content: translate.byValue(ex.message),
					});
					refreshOnConnectionFailure(ex);
				} else {
					Modal.confirm({
						title: translate.byKey('controller_onboarding_title'),
						content: translate.byKey('controller_onboarding_message'),
						okText: translate.byKey('retry'),
						okButtonProps: primaryButtonProps,
						cancelText: translate.byKey('cancel'),
						onCancel: () => {
							refreshOnConnectionFailure(ex);
						},
						onOk: () => {
							onSubmit(values, numOfRetries + 1).catch((ex) => {
								Modal.warn({
									title: translate.byKey('controller_comms_failure_title'),
									content: translate.byValue(ex.message),
								});
							});
						},
					});
				}
				return;
			}

			if (ex.status === 409) {
				Modal.error({
					title: translate.byKey('controller_create_failure_title'),
					content: translate.byKey(
						'device_with_the_same_mac_address_already_registered_please_contact_support_team_for_more_details'
					),
				});

				return;
			}

			let message = ex.message || '';

			if (ex && ex.replace) {
				message = ex;
			}

			if (ex.data && ex.data.message) {
				message = ex.data.message;
			}

			message = message.replace('!!!', '!');
			message = message.replace(' ! ', '! ');

			Modal.error({
				title: translate.byKey('controller_create_failure_title'),
				content: translate.byValue(message),
			});
		} finally {
			setIsLoading(false);
		}
	}

	const resetState = () => {
		setController(getDefaultController());
		setReaders([]);
		form.setFieldsValue({
			macAddress: '',
			serialNumber: '',
		});
	};

	const [deleteController, , currentDeleteStep] = useDeleteController();

	/**
	 * Delete
	 */
	async function onDeleteRequest() {
		setIsLoading(true);
		try {
			const result = await canDeleteController(controller);
			if (result === false) {
				try {
					await deleteController(controller.controllerId, null, siteId);
					showSuccessToast(translate.byKey('controller_delete_success_title'));
					hideModal();
					resetState();
					if (onUpdated) {
						onUpdated(null);
					}
				} catch (error) {
					Modal.error({
						title: translate.byKey('controller_delete_failure_title'),
						content: translate.byValue(error.data?.message || error.message || error),
					});
				}
			} else {
				Modal.info({
					title: translate.byKey('controller_delete_doors_error_title'),
					content: translate.byKey('controller_delete_doors_error_message'),
				});
			}
		} catch (error) {
			Modal.error({
				title: translate.byKey('controller_delete_general_error_title'),
				content: translate.byValue(error.data?.message || error.message || error),
			});
		} finally {
			setIsLoading(false);
		}
	}

	/**
	 * conditionally render the reader items and filter out what should not show
	 * @param {*} item
	 * @param controllerType
	 */
	const renderReaderItems = (item, controllerType) => {
		if (item.fixedAddress) {
			return (
				<List.Item>
					<Card style={{ justifyContent: 'center', textAlign: 'center', height: 80 }}>
						<Meta
							title={translate.byKey(controllerType === 'improx' ? 'fixed_address_label' : 'serial_number_label')}
							description={item.fixedAddress}
						/>
					</Card>
				</List.Item>
			);
		}
		return <React.Fragment />; // dont return null or undefined as it throws errors and screen goes blank, so return an empty fragment instead
	};

	/**
	 * Whether or not the user is allowed to see the Submit button.
	 * Depends on whether they are updating / creating and whether they have permissions.
	 */
	const shouldHideSubmitButton = () => {
		if (controller && controller.name && cannotSyncNow()) {
			return true;
		}

		if (currentCreateStep) {
			return true;
		}

		// No site selected yet
		if (!site || site.siteId === undefined) {
			return true;
		}

		if (hasActionPermissions(user, userConstants.screens.CONTROLLER_ONBOARD, userConstants.actions.WRITE) === true) {
			return false;
		}

		if (hasActionPermissions(user, userConstants.screens.CONTROLLER_ONBOARD, userConstants.actions.CREATE) === true) {
			return false;
		}

		return hasActionPermissions(user, userConstants.screens.CONTROLLER_ONBOARD, userConstants.actions.READ);
	};

	const shouldHideSyncButton = () => {
		if (!currentAdminHasRequiredRole(currentAdmin, ['SYSTEM_OWNER', 'SYSTEM_INSTALLER'])) {
			return true;
		}

		const hideSubmitBtn = shouldHideSubmitButton();

		return hideSubmitBtn || isInEmergencyMode;
	};

	const getSyncStatusTranslation = () => {
		return translate.byKey(
			(getCurrentControllerState(systemSyncState, true) || '').toLocaleLowerCase() + '_upper_case'
		);
	};

	/**
	 * Certain functions (e.g. AutoId and Delete) need to be hidden
	 * if the controller does not exist yet or based on user role
	 * permissions.
	 *
	 * The Configure / Submit button's logic is handled separately.
	 * It's a little more involved as the same button has 2 different
	 * states.
	 *
	 * @param {*} action
	 */
	const showButton = (action) =>
		site &&
		!currentCreateStep &&
		getControllerSerialNumber() &&
		getControllerMacAddress() &&
		hasActionPermissions(user, userConstants.screens.CONTROLLER_ONBOARD, action) === true &&
		!isInEmergencyMode;

	const onCancelButtonClick = () => {
		if (isLoading) {
			Modal.confirm({
				title: translate.byKey('work_in_progress'),
				content: translate.byKey('Please_wait_for_current_operation_to_complete'),
				okText: translate.byKey('close_anyway'),
				okButtonProps: primaryButtonProps,
				cancelText: translate.byKey('cancel'),
				onOk: () => {
					hideModal();
				},
			});
		} else {
			hideModal();
		}
	};

	const onDeleteButtonClick = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('are_you_sure_you_want_to_delete_this_controller'),
			okText: translate.byKey('delete'),
			okButtonProps: primaryButtonProps,
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				onDeleteRequest().catch(console.error);
			},
		});
	};

	const onUpdateButtonClick = async () => {
		form
			.validateFields()
			.then(async (values) => {
				setIsLoading(true);
				setIsUpdating(true);

				await updateControllerDevice({
					name: values.name,
					controllerId: selected?.controllerId || controller?.controllerId,
					ignoreGlobalHandlers: true, // to ignore default error handling
				});

				setIsLoading(false);
				setIsUpdating(false);
				showSuccessToast(translate.byKeyFormatted('controller_name_has_been_updated_successfully'));
				hideModal();

				return systemConfig.devVersion >= 2 ? onUpdated(null) : onUpdated;
			})
			.catch(() => {
				setIsLoading(false);
				setIsUpdating(false);

				Modal.warning({
					title: translate.byKeyFormatted('an_error_occurred_updating_formatted', { item: controller.name }),
				});
			});
	};

	const onReadersButtonClick = () => {
		discoverReaders({
			siteId,
			controllerId: controller.controllerId,
			protocol: controller.protocol,
			macAddress: getControllerMacAddress(),
			serialNumber: getControllerSerialNumber(),
			nonce: Math.floor(Math.random() * 1000),
			name: controller.name,
			type: controller.type,
		})
			.then(async (updatedController) => {
				const currentReaders = Array.from(readers);

				setController(updatedController);

				const newReaders = updateReaders(updatedController);
				let message = translate.byKey('discovered_no_new_readers');

				if (JSON.stringify(currentReaders) !== JSON.stringify(newReaders)) {
					if (currentReaders.length === newReaders.length) {
						message = translate.byKey('some_readers_have_been_replaced');
					} else if (currentReaders.length > newReaders.length) {
						message = translate.byKey('some_readers_have_been_removed');
					} else if (currentReaders.length < newReaders.length) {
						message = translate.byKey('some_readers_have_been_added');
					}
				}

				showSuccessToast(message);

				let onlineStatus;

				if (onlineState !== 'CONNECTED') {
					onlineStatus = await getControllerStatus({ deviceId: updatedController.controllerId, siteId });
					setOnlineState(onlineStatus.connectionStatus);
				}

				if (getCurrentControllerState(systemSyncState) !== 'UPDATED') {
					const allSyncState = await getRelevantSyncState(updatedController, onlineStatus, siteId);
					setSystemSyncState(allSyncState);
				}
			})
			.catch((error) => {
				showErrorToast(translate.byValue(error.message || error));
			});
	};

	const onSyncButtonClick = () => {
		form
			.validateFields()
			.then(async (values) => {
				if (!controller.name) {
					setModalTitle(translate.byKeyFormatted('configuring_controller_formatted', [values.name]));
					return onSubmit(values);
				}

				setIsLoading(true);

				const status = await reSync({
					siteId,
					__contentType: 'application/vnd.oca.administration.api-1.0+json',
					controllerId: selected?.controllerId || controller.controllerId,
					type: 'RESYNC',
				});

				setIsLoading(false);

				if (status) {
					hideModal();
					showSuccessToast(translate.byKey('sync_in_progress_toast_message'));
					return systemConfig.devVersion >= 2 ? onUpdated(null) : onUpdated;
				}

				showErrorToast(translate.byKey('syncing_data_with_controller_failed_v2'));
			})
			.catch((error) => {
				let message;

				// This is present as an edge case. Validation should always disable the submit button.
				if (error?.errorFields?.length > 0) {
					// see https://ant.design/components/form/ for the errorInfo structure.

					// we can only show one error because embedded line breaks do not work.
					message = error.errorFields[0].errors[0];
				} else {
					message = error?.message;
				}

				Modal.warning({
					title: translate.byKey('validation_failed'),
					content: translate.byValue(message),
				});
			});
	};

	const isUpdateDisabled =
		controller?.name === name ||
		!name?.trim()?.length ||
		name?.length > 50 ||
		(serialNumber?.trim()?.replace('_', '')?.length ?? 0) < 10 ||
		serialNumber.length > 20 ||
		!RegExp(MAC_ADDRESS).test(macAddress);

	return (
		<Modal
			css={css`
				.readers-list {
					overflow: auto;
					overflowx: hidden;
					maxheight: 25em;
					padding: 0.5em;
				}
			`}
			title={getReadableName(controller.name) || modalTitle}
			showHelpButton
			open={visible}
			loading={isLoading}
			maskClosable={false}
			forceRender
			hideCancel
			onCancel={onCancelButtonClick}
			footer={[
				<>
					{showButton(userConstants.actions.DELETE) ? (
						<Tooltip title={translate.byKey('delete_controller')} placement="topLeft" mouseEnterDelay={1}>
							<Button key="delete" type="primary" danger disabled={isLoading} onClick={onDeleteButtonClick}>
								{translate.byKey('delete')}
							</Button>
						</Tooltip>
					) : null}
					{showButton(userConstants.actions.WRITE) ? (
						<Tooltip
							title={translate.byKey(readers.length ? 'rediscover_readers' : 'discover_readers')}
							placement="topLeft"
							mouseEnterDelay={1}
						>
							<Button
								key="refresh"
								{...(cannotSyncNow() ? primaryButtonProps : {})}
								disabled={isLoading}
								onClick={onReadersButtonClick}
							>
								{translate.byKey(readers.length ? 'rediscover_readers' : 'discover_readers')}
							</Button>
						</Tooltip>
					) : null}
					{!shouldHideSyncButton() ? (
						<Button
							key="submit"
							type="primary"
							disabled={isLoading || (controller.name && isInEmergencyMode) || (!controller.name && isUpdateDisabled)}
							onClick={onSyncButtonClick}
							data-testid="btn-submit"
						>
							{translate.byKey(controller.name ? 'sync_now' : 'configure')}
						</Button>
					) : null}
					{showButton(userConstants.actions.WRITE) ? (
						<Button
							key="update"
							type="primary"
							disabled={isUpdateDisabled || isLoading}
							loading={isUpdating}
							onClick={onUpdateButtonClick}
						>
							{translate.byKey('update')}
						</Button>
					) : null}
				</>,
			]}
		>
			{currentCreateStep && currentCreateStep.steps ? (
				<Steps
					direction="vertical"
					status={currentCreateStep.status}
					current={currentCreateStep.stepNumber}
					items={[
						{
							title: translate.byKey('creating_controller'),
							icon: currentCreateStep.steps[0].isPending ? <LoadingOutlined /> : null,
							description: currentCreateStep.steps[0].description,
						},
						{
							title: translate.byKey('onboarding_controller'),
							icon: currentCreateStep.steps[1].isPending ? <LoadingOutlined /> : null,
							description: currentCreateStep.steps[1].description,
						},
						{
							title: translate.byKey('configuring_port_for_controller'),
							icon: currentCreateStep.steps[2].isPending ? <LoadingOutlined /> : null,
							description: currentCreateStep.steps[2].description,
						},
						{
							title: translate.byKey('controller_setup'),
							icon: currentCreateStep.steps[3].isPending ? <LoadingOutlined /> : null,
							description: currentCreateStep.steps[3].description,
						},
						{
							title: translate.byKey('reader_detection'),
							icon: currentCreateStep.steps[4].isPending ? <LoadingOutlined /> : null,
							description: currentCreateStep.steps[4].description,
						},
					]}
				/>
			) : (
				<Spin active={isLoading} text={currentDeleteStep || ''}>
					<Form layout="vertical" size={'medium'} name="controller-form" form={form} scrollToFirstError>
						<Row gutter={[12, 12]}>
							{getControllerMacAddress() && getControllerSerialNumber() && (
								<StatusControllerGrid
									systemSyncState={systemSyncState}
									onlineState={onlineState}
									getCurrentControllerState={getCurrentControllerState}
									controllerStates={controllerStates}
									isLoading={isLoading}
									getSyncStatusTranslation={getSyncStatusTranslation}
								/>
							)}

							<Col md={24}>
								<Item
									name={NAME_FIELD_NAME}
									label={translate.byKey('controller_name_label')}
									shouldUpdate
									rules={[
										{
											required: true,
											whitespace: true,
											message: translate.byKey('name_is_required'),
										},
										{
											max: 50,
											message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
												fieldName: translate.byKey('name'),
												maxLength: 50,
											}),
										},
									]}
								>
									<BaseInput
										name={NAME_FIELD_NAME}
										spellCheck={false}
										disabled={
											(selected?.name || controller?.controllerId) &&
											!hasActionPermissions(user, userConstants.screens.CONTROLLER_ONBOARD, userConstants.actions.WRITE)
										}
										maxLength={50}
										showCount
										css={baseInputStyles(theme)}
									/>
								</Item>
							</Col>

							<Col md={24}>
								<Item
									name={SERIAL_NUMBER_FIELD_NAME}
									label={translate.byKey('serial_number_label')}
									shouldUpdate
									normalize={(value) => (isClusterController ? value.toUpperCase() : value)}
									validateTrigger="onBlur"
									validateFirst
									rules={[
										{
											required: true,
											whitespace: true,
											validator: (_, __) => Promise.resolve(),
										},
										{
											max: 20,
											message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
												fieldName: translate.byKey('serial_number_label'),
												maxLength: 20,
											}),
										},
										{
											transform: (value) => value && value.split('_').join(''),
											min: 10,
											message: isClusterController
												? translate.byKeyFormatted('field_value_exact_length_required_message', {
														fieldName: translate.byKey('serial_number_label'),
														length: 10,
												  })
												: translate.byKeyFormatted('field_value_cannot_be_shorter_than_message', {
														fieldName: translate.byKey('serial_number_label'),
														minLength: 10,
												  }),
											validator: (_, value) =>
												(value?.trim()?.length ?? 0) < 10 ? Promise.reject() : Promise.resolve(),
										},
										{
											validator(rule, value) {
												if (
													getControllerSerialNumber() &&
													readers.length > 0 &&
													value &&
													value !== getControllerSerialNumber()
												) {
													return Promise.reject(
														translate.byKey(
															'you_cannot_change_the_serial_number_of_a_controller_when_it_has_been_onboarded'
														)
													);
												}

												return Promise.resolve();
											},
										},
									]}
								>
									<Input
										mask={isClusterController ? '**********' : ''}
										spellCheck={false}
										disabled={selected?.serialNumber || controller?.controllerId}
									/>
								</Item>
							</Col>

							<Col md={24}>
								<Item
									name={MAC_ADDRESS_FIELD_NAME}
									label={translate.byKey('mac_address_label')}
									shouldUpdate
									validateTrigger="onBlur"
									rules={[
										{
											required: true,
											whitespace: true,
											validator: (_, __) => Promise.resolve(),
										},
										{
											transform: (value) => value && value.split('_').join(''),
											min: 17,
											message: translate.byKeyFormatted('field_value_exact_length_required_message', {
												fieldName: translate.byKey('mac_address_label'),
												length: 17,
											}),
											validator: (_, value) =>
												(value?.trim()?.length ?? 0) < 17 ? Promise.reject() : Promise.resolve(),
										},
										{
											transform: (value) => value && value.split('_').join(''),
											pattern: MAC_ADDRESS,
											message: translate.byKey('mac_address_is_invalid'),
										},
										{
											validator(rule, value) {
												if (
													getControllerMacAddress() &&
													readers.length > 0 &&
													value &&
													value.toLowerCase() !== getControllerMacAddress()
												) {
													return Promise.reject(
														translate.byKey(
															'you_cannot_change_the_mac_address_of_a_controller_when_it_has_been_onboarded'
														)
													);
												}

												return Promise.resolve();
											},
										},
									]}
								>
									<Input
										mask="**:**:**:**:**:**"
										spellCheck={false}
										placeholder="__:__:__:__:__:__"
										disabled={selected?.macAddress || controller?.controllerId}
									/>
								</Item>

								<Item name="protocol">
									<Input type="hidden" />
								</Item>
							</Col>
						</Row>
						{getControllerMacAddress() && getControllerSerialNumber() && (
							<React.Fragment>
								<Divider>{translate.byKey('firmware_version').toUpperCase()}</Divider>
								<Card style={{ width: '200px', margin: '0 auto' }} bodyStyle={{ padding: '4px', margin: '0 auto' }}>
									<Meta
										title={
											selected.configuration?.additionalSettings?.firmwareVersion || translate.byKey('not_available')
										}
									/>
								</Card>
								<Divider>{translate.byKey('readers_upper_case')}</Divider>
								<List
									grid={{ gutter: 12, column: 2 }}
									dataSource={readers}
									renderItem={(item) => renderReaderItems(item, selected?.protocol || controllerType || 'improx')}
								/>
								<Divider />
								{getLicensedCount(system, controllerType)}
							</React.Fragment>
						)}
					</Form>
				</Spin>
			)}
		</Modal>
	);
};

const baseInputStyles = (theme) =>
	css({
		borderRadius: '4px',
		color: theme.colors.gray[400],
		borderColor: theme.colors.gray[200],
		'.ant-input-prefix': {
			marginRight: '10px',
		},
		svg: {
			color: theme.colors.gray[600],
		},
	});
