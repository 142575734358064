import React from 'react';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';

import { Spin } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { antdToApiSortModes } from '~/utils/sort';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { getScrollHeightOptions } from '~/constants/Table';

export const DoorGroupsTable = ({ data, isLoading, onRowClick }) => {
	const { translate } = useLocale();
	const {
		queryParams: { pageNumber, pageSize, doorGroup, description },
		setQueryParams,
	} = useQueryParams();

	const onTableChange = (pagination, filters, sorter) => {
		const isPageSizeChanged = pageSize ? pagination.pageSize !== pageSize : pagination.pageSize !== DEFAULT_PAGE_SIZE;

		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: isPageSizeChanged ? 1 : pagination.current,
			sortBy: sorter.columnKey,
			sortDirection: antdToApiSortModes[sorter.order],
			doorGroup: filters?.name?.[0] || '',
			description: filters?.description?.[0] || '',
		});
	};

	return (
		<TableWithFiltersSection
			rowKey="assetId"
			dataSource={data?.assets}
			onChange={onTableChange}
			onRow={(doorGroup) => ({
				onClick: () => onRowClick(doorGroup),
			})}
			loading={{
				spinning: isLoading,
				indicator: <Spin active />,
			}}
			columns={[
				{
					dataIndex: 'name',
					title: translate.byKey('name'),
					key: 'name',
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: doorGroup ? [doorGroup] : null,
					filterIcon: <SearchFilterIcon />,
					sorter: createSorter('name'),
					defaultSortOrder: 'ascend',
					sortDirections: ['descend', 'ascend', 'descend'],
				},
				{
					dataIndex: 'description',
					title: translate.byKey('description'),
					key: 'description',
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: description ? [description] : null,
					filterIcon: <SearchFilterIcon />,
					sorter: createSorter('description'),
					sortDirections: ['ascend', 'descend', 'ascend'],
				},
			]}
			pagination={{
				showSizeChanger: true,
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
				position: ['bottomCenter'],
				pageSize: pageSize || DEFAULT_PAGE_SIZE,
				total: data?.totalRecords,
				current: parseInt(pageNumber) || 1,
				disabled: isLoading,
			}}
			scroll={getScrollHeightOptions()}
		/>
	);
};
