import React, { useState, useReducer, createContext, useEffect } from 'react';

import { Flex, Text } from '~/components';

import TimeSlot from './TimeSlot';
import { days, times, timesWithHalfHour } from './labels';
import {
	move,
	renameSlot,
	addNew,
	updateSingleSlotInterval,
	deleteSingleSlotInterval,
	addIntervalToSlot,
} from './helpers';
import NewTimeSlot from './NewTimeSlot';
import * as actions from './actions';
import { getInitialState } from './initialState';

export const SchedulePickerContext = createContext({});

const maxTimeSlots = 4;

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_INITIAL_DATA:
			return { timeSlots: action.payload };
		case actions.MOVE_DAY:
			const movedAndSortedSlots = move(action, state);
			return { timeSlots: movedAndSortedSlots };
		case actions.UPDATE_TIME_SLOT_NAME:
			const renamedEntrySlots = renameSlot(action, state);
			return { timeSlots: renamedEntrySlots };
		case actions.ADD_NEW_INTERVAL_TO_SLOT:
			const addedIntervalTimePeriodsSlots = addIntervalToSlot(action, state);
			return { timeSlots: addedIntervalTimePeriodsSlots };
		case actions.UPDATE_SINGLE_SLOT_INTERVAL:
			const updatedSingleIntervalTimeSlots = updateSingleSlotInterval(action, state);
			return { timeSlots: updatedSingleIntervalTimeSlots };
		case actions.DELETE_SINGLE_SLOT_INTERVAL:
			const deletedSingleIntervalTimeSlots = deleteSingleSlotInterval(action, state);
			return { timeSlots: deletedSingleIntervalTimeSlots };
		case actions.ADD_NEW_SLOT:
			const addedNewEntrySlots = addNew(action, state);
			return { timeSlots: addedNewEntrySlots };
		default:
			throw new Error('Unknown action received.');
	}
};

const TimesSelector = (props) => {
	const { visible, onChange, initialSlotData, isActiveTab } = props;

	const [state, dispatch] = useReducer(reducer, getInitialState());
	const [dimensions, setDimensions] = useState({
		colCount: 0,
		rowCount: 0,
		rowHeight: 0,
		colWidth: 0,
		width: 0,
		height: 0,
	});

	useEffect(() => {
		onChange(state);
	}, [state]);

	useEffect(() => {
		if (initialSlotData && initialSlotData.length > 0) {
			dispatch({
				type: actions.SET_INITIAL_DATA,
				payload: initialSlotData,
			});
		} else {
			dispatch({
				type: actions.SET_INITIAL_DATA,
				payload: getInitialState().timeSlots,
			});
		}
	}, [initialSlotData, dispatch]);

	const timeSlotHeaderHeight = 36;
	const dayHeaderHeight = 24;
	const rowHeight = 14;

	useEffect(() => {
		const rowCount = timesWithHalfHour.length;
		const colCount = days.length;
		const colWidth = 85;

		const timeSlotSpacing = 2;
		const width = colWidth * colCount + timeSlotSpacing * colCount;
		const height = rowHeight * rowCount + dayHeaderHeight + timeSlotHeaderHeight;

		setDimensions({
			rowCount,
			colCount,
			rowHeight,
			colWidth,
			dayHeaderHeight,
			timeSlotHeaderHeight,
			height,
			width,
			timeSlotSpacing,
		});
	}, [visible]);

	return (
		<SchedulePickerContext.Provider value={{ ...dimensions, dispatch, state }}>
			<Flex grow center data-test="time-selector-root">
				<Flex
					style={{ width: '3.25rem', paddingTop: `${timeSlotHeaderHeight + dayHeaderHeight}px` }}
					height={dimensions.height}
					justifyContent="flex-end"
					flexDirection="column"
				>
					{timesWithHalfHour.map((time) => (
						<Flex key={time} height={`${dimensions.rowHeight}px`}>
							<Text data-test="time-selector-time-label" color="gray.300" fontSize={10}>
								{times.includes(time) ? time : '  '}
							</Text>
						</Flex>
					))}
				</Flex>
				<Flex width={dimensions.width} height={dimensions.height}>
					{state.timeSlots &&
						state.timeSlots.map((timeSlot) => (
							<TimeSlot key={timeSlot.id} slot={timeSlot} dispatch={dispatch} isActiveTab={isActiveTab} />
						))}
					{state.timeSlots && state.timeSlots.length > 0 && state.timeSlots.length < maxTimeSlots && (
						<NewTimeSlot slots={state.timeSlots} dispatch={dispatch} />
					)}
				</Flex>
			</Flex>
		</SchedulePickerContext.Provider>
	);
};

export default TimesSelector;
