import startCase from 'lodash/startCase';
import isFunction from 'lodash/isFunction';
import moment from 'moment';

export function formatEvent(cell) {
	return startCase(cell.toLowerCase());
}

export function formatDate(cell) {
	const date = moment(cell);
	return date.isValid() ? date.format('ll h:mm:ss A') : 'N/A';
}

export function formatController(cell) {
	return cell.split('|').splice(0, 1).join('');
}

export function doNothing(cell) {
	return cell;
}

export function getFormatter(columnInfo, formatters) {
	if (columnInfo.formatter && isFunction(columnInfo.formatter)) {
		return columnInfo.formatter;
	}

	if (columnInfo.formatter in formatters) {
		return formatters[columnInfo.formatter];
	}

	return doNothing;
}
