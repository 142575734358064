import { useState } from 'react';

import { method } from '~/screens/_shared/useApi/apiConstants';
import mappers from '~/screens/_shared/mappers';
import { usePutApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';

export function useUpdate() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [updateAccessProfile] = usePutApi(mappers.accessProfile);
	const { translate } = useLocale();

	/** ***************** **/
	/** Initial function call **/
	/** ***************** **/
	const updateAccessGroup = async (formData) => {
		setIsLoading(true);
		setCurrentStep(translate.byKey('creating_accessGroup_details'));
		try {
			return await updateAccessProfile(formData, {
				removeExistingKey: getCacheKey(method.list, mappers.accessProfile, { params: { 'detail-level': 'FULL' } }),
			});
		} finally {
			setIsLoading(false);
		}
	};

	return [updateAccessGroup, isLoading, currentStep];
}
