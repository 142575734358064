import React, { useEffect } from 'react';

import { BlocklistTable } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useCredentials } from '~/hooks/data/credentials/useCredentials';
import { getBlockedCredentialsKey } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const BlocklistDataTable = ({ onSelectedCredential }) => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, mode, sortBy, sortDirection, person },
		setQueryParams,
	} = useQueryParams();
	const { data, isLoading, isValidating } = useCredentials(
		getBlockedCredentialsKey({
			customerId,
			systemId,
			pageSize,
			pageNumber,
			mode,
			sortBy,
			sortDirection,
			person,
		})
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		const lastPageNumber = Math.ceil(data.totalRecords / (parseInt(pageSize) || DEFAULT_PAGE_SIZE));
		const currentPageNumber = parseInt(pageNumber) || 1;
		const isCurrentPageEmptyAndLast =
			data.credentials.length === 0 && lastPageNumber && lastPageNumber < currentPageNumber;

		if (isCurrentPageEmptyAndLast) {
			setQueryParams({
				pageNumber: currentPageNumber - 1,
			});
		}
	}, [data, pageSize, pageNumber]);

	return <BlocklistTable data={data} isLoading={isLoading || isValidating} onRowClick={onSelectedCredential} />;
};
