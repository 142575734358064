import fileSaver from 'file-saver';
import moment from 'moment';

import * as formatters from './formatters';
import { translator as translate } from '~/screens/_shared/AppLocale';

const SEPARATOR = ';';
const NEW_LINE = '\n';

const RECORDS_LIMIT = 2000;

export function downloadDataAsFile(exportData) {
	if (exportData && exportData.list && exportData.columns) {
		const { list, columns, currentUser, totalNumberOfRecords } = exportData;

		const lines = [];

		const operatorAndDateTimeInformation = `# ${currentUser.firstName} ${currentUser.lastName} -  ${moment().format(
			'L LT'
		)}${NEW_LINE}`;

		lines.push(operatorAndDateTimeInformation);

		if (totalNumberOfRecords > RECORDS_LIMIT) {
			const warningMessage = `# ${translate.byKeyFormatted('reports_export_information_message_limit', {
				limit: RECORDS_LIMIT,
			})}${NEW_LINE}`;

			lines.push(warningMessage);
		}

		lines.push(columns.map((c) => c.header).join(SEPARATOR) + NEW_LINE);

		for (let raw of list) {
			const newRow = columns.reduce((row, column) => {
				const value = raw[column.dataKey];

				if (value) {
					const formatter = formatters.getFormatter(column, formatters);
					row.push(formatter(value, raw));
				} else {
					row.push('');
				}

				return row;
			}, []);
			lines.push(newRow.join(SEPARATOR) + NEW_LINE);
		}

		const blob = new Blob(['\uFEFF', ...lines], { type: 'text/csv;charset=utf-8' });

		return fileSaver.saveAs(blob, `${exportData.title} ${moment().format('L LT')}.csv`);
	}
}
