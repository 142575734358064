import React from 'react';
import { Dropdown, Radio } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';

import { BlocklistMode, useCredentials } from '~/hooks/data/credentials/useCredentials';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Button, PageHeader } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getBlockedCredentialsKey } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { downloadDataAsFile } from '~/exporters';
import { useTheme } from 'emotion-theming';
import { getReadableName } from '~/screens/_shared/getReadableName';
import { isPulseKey } from '~/components/features/people/People.utils';
import { convertCredentialHexToDecimal } from '~/utils/credential/credential';
import moment from 'moment';
import { useCurrentUser } from '~/hooks/features/useCurrentUser/useCurrentUser';
import { removeDiacritics } from '~/utils/string';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const BlocklistHeader = ({ onAddNewButtonClick }) => {
	const { translate } = useLocale();
	const theme = useTheme();
	const { currentUser } = useCurrentUser();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, mode, sortBy, sortDirection, person },
		setQueryParams,
	} = useQueryParams();
	const { data: user } = useUserAuthData();
	const { data, isLoading, isValidating } = useCredentials(
		getBlockedCredentialsKey({
			customerId,
			systemId,
			pageSize,
			pageNumber,
			mode,
			sortBy,
			sortDirection,
			person,
		})
	);

	const onBlocklistModeChange = (event) => {
		setQueryParams({
			mode: event.target.value,
			pageNumber: 1,
		});
	};

	const onExport = async (type) => {
		const filteredCredentials = person
			? data?.credentials?.filter((credential) =>
					removeDiacritics(credential.credentialHolder.name.toLowerCase()).includes(
						removeDiacritics(person.toLowerCase())
					)
			  ) || []
			: data?.credentials || [];

		const formattedData = filteredCredentials.map((item) => ({
			credentialHolder: getReadableName(item.credentialHolder.name),
			name: translate.byKey(isPulseKey({ name: item.name }) ? 'pulse_v2' : 'credential'),
			credentialNumber: convertCredentialHexToDecimal(item),
			description: item.description ? moment(item.description).format('L') : '',
		}));

		downloadDataAsFile({
			type,
			theme,
			title:
				mode === BlocklistMode.ONLINE
					? translate.byKey('online_blocklist_report')
					: translate.byKey('offline_blocklist_report'),
			list: formattedData?.map((transaction) => {
				return transaction.eventSource ? transaction : { ...transaction, eventSource: 'blank' };
			}),
			columns: [
				{
					dataKey: 'credentialHolder',
					header: translate.byKey('person'),
				},
				{
					dataKey: 'name',
					header: translate.byKey('credential_type'),
				},
				{
					dataKey: 'credentialNumber',
					header: translate.byKey('credential_number_v2'),
				},
				{
					dataKey: 'description',
					header: translate.byKey('date_blocked_v2'),
				},
			],
			currentUser,
			totalNumberOfRecords: filteredCredentials.length,
		});
	};

	return (
		<PageHeader title={translate.byKey('blocklist_menu_title_v2')} icon="person-prohibited" showHelp={false}>
			<Radio.Group
				value={mode || BlocklistMode.OFFLINE}
				disabled={isLoading || isValidating}
				options={[
					{ label: translate.byKey('offline'), value: BlocklistMode.OFFLINE },
					{ label: translate.byKey('online'), value: BlocklistMode.ONLINE },
				]}
				onChange={onBlocklistModeChange}
			/>

			<Dropdown
				disabled={isLoading || isValidating}
				menu={{
					items: [
						{
							key: 'exportButtonPdf',
							disabled: isLoading || isValidating,
							onClick: () => onExport('pdf'),
							label: translate.byKey('pdf'),
						},
						{
							key: 'exportButtonCsv',
							disabled: isLoading || isValidating,
							onClick: () => onExport('csv'),
							label: translate.byKey('csv'),
						},
					],
				}}
			>
				<Button loading={isLoading || isValidating} icon={<ExportOutlined />} type="primary" ml={6}>
					{translate.byKey('export')}
				</Button>
			</Dropdown>

			{hasActionPermissions(user, userConstants.screens.BLOCKLIST, userConstants.actions.CREATE) ? (
				<Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddNewButtonClick}>
					{translate.byKey('add_new')}
				</Button>
			) : null}
		</PageHeader>
	);
};
