export const user = {
	base: {
		firstName: true,
		lastName: true,
		description: ({ firstName, lastName }) => `User: ${firstName} ${lastName}`,
		phone: false,
		email: false,
	},
	update: {
		version: false,
	},
	params: {
		credentialHolderId: {
			list: false,
		},
	},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'user'],
};
