import React, { useEffect, useMemo } from 'react';

import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { removeDiacritics } from '~/utils/string';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { DoorGroupsTable } from '~/components/features/door-groups/table/DoorGroupsTable/DoorGroupsTable';
import { AssetTypes } from '~/constants/AssetTypes';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

// TODO #2 START - FILTERING BY NAME, DESCRIPTION OR BOTH ON THE CLIENT SINCE NO SUPPORT ON BACKEND ATM
// Remove this and other #2 todo's and refactor when and if backend supports filtering by name (door group name), description or both since this is a hack and client side filtering implementation

export const DoorGroupsClientDataTable = ({ onRowClick }) => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageNumber, pageSize, sortBy, sortDirection, doorGroup, description },
		setQueryParams,
	} = useQueryParams();
	const { data, isLoading, isValidating } = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			siteId,
			pageSize: doorGroup || description ? 200 : pageSize, // TODO #2 when client side pagination is removed, change this
			pageNumber: doorGroup || description ? 1 : pageNumber, // TODO #2 when client side pagination is removed, change this
			sortBy,
			sortDirection,
			assetType: AssetTypes.BASIC_ASSET_GROUP,
			doorGroup,
			description,
		})
	);

	const dataFiltered = useMemo(() => {
		let filteredAssets = [];
		const filterAssetsByDoorGroupName = (data) =>
			data?.filter((asset) =>
				removeDiacritics(asset.name.toLowerCase()).includes(removeDiacritics(doorGroup.toLowerCase()))
			);

		const filterAssetsByDescription = (data) =>
			data?.filter((asset) => {
				if (asset.description) {
					return removeDiacritics(asset.description.toLowerCase()).includes(
						removeDiacritics(description.toLowerCase())
					);
				} else {
					return false;
				}
			});

		if (doorGroup && description) {
			let filteredAssetsByDoorGroupNameAndDescription = [];

			if (data?.assets) {
				const filteredAssetsByDoorGroupName = filterAssetsByDoorGroupName(data.assets);
				const filteredAssetsByDescription = filterAssetsByDescription(filteredAssetsByDoorGroupName);

				filteredAssetsByDoorGroupNameAndDescription = filteredAssetsByDescription
					? [...filteredAssetsByDescription]
					: [];
			}

			filteredAssets = [...filteredAssetsByDoorGroupNameAndDescription];
		}

		if (doorGroup && !description) {
			const filteredAssetsByDoorGroupName = filterAssetsByDoorGroupName(data?.assets);
			filteredAssets = filteredAssetsByDoorGroupName ? [...filteredAssetsByDoorGroupName] : [];
		}

		if (!doorGroup && description) {
			const filteredAssetsByDescription = filterAssetsByDescription(data?.assets);
			filteredAssets = filteredAssetsByDescription ? [...filteredAssetsByDescription] : [];
		}

		return {
			assets: filteredAssets,
			totalRecords: filteredAssets.length,
		};
	}, [doorGroup, description, data?.assets]);

	useEffect(() => {
		if (!dataFiltered) {
			return;
		}

		const lastPageNumber = Math.ceil(dataFiltered.assets.length / (parseInt(pageSize) || DEFAULT_PAGE_SIZE));
		const currentPageNumber = parseInt(pageNumber) || 1;
		const isCurrentPageEmptyAndLast = lastPageNumber && lastPageNumber < currentPageNumber;

		if (isCurrentPageEmptyAndLast) {
			setQueryParams({
				pageNumber: currentPageNumber - 1,
			});
		}
	}, [dataFiltered, pageSize, pageNumber]);

	return <DoorGroupsTable data={dataFiltered} isLoading={isLoading || isValidating} onRowClick={onRowClick} />;
};
