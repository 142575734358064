import { isVersionEqual, isVersionGreaterThan, isVersionLessThan } from '~/utils/compareVersions';
import { ControllerTypes } from '~/constants/ControllerTypes';

export const getLowestControllerInfo = (controllers) => {
	let currentLowestControllerType = null;
	let currentLowestControllerFirmwareVersion = null;

	if (controllers && controllers.length) {
		controllers.forEach((controller) => {
			const firmwareVersion = controller?.configuration?.additionalSettings?.firmwareVersion;

			if (controller.type === ControllerTypes.ACCESS_CONTROL_UNIT) {
				if (currentLowestControllerFirmwareVersion === null && firmwareVersion) {
					currentLowestControllerFirmwareVersion = firmwareVersion;
				} else if (firmwareVersion && currentLowestControllerType !== ControllerTypes.CONTROLLER_UPDATER) {
					currentLowestControllerFirmwareVersion = isVersionLessThan(
						firmwareVersion,
						currentLowestControllerFirmwareVersion
					)
						? firmwareVersion
						: currentLowestControllerFirmwareVersion;
				} else {
					currentLowestControllerFirmwareVersion = firmwareVersion || null;
				}

				currentLowestControllerType = ControllerTypes.ACCESS_CONTROL_UNIT;
			} else if (controller.type === ControllerTypes.CONTROLLER_UPDATER) {
				if (currentLowestControllerFirmwareVersion === null && firmwareVersion) {
					currentLowestControllerFirmwareVersion = firmwareVersion;
				} else if (currentLowestControllerType !== ControllerTypes.ACCESS_CONTROL_UNIT && firmwareVersion) {
					currentLowestControllerFirmwareVersion = isVersionLessThan(
						firmwareVersion,
						currentLowestControllerFirmwareVersion
					)
						? firmwareVersion
						: currentLowestControllerFirmwareVersion;
				}

				currentLowestControllerType =
					currentLowestControllerType === ControllerTypes.ACCESS_CONTROL_UNIT
						? ControllerTypes.ACCESS_CONTROL_UNIT
						: ControllerTypes.CONTROLLER_UPDATER;
			}
		});
	}

	return {
		lowestControllerType: currentLowestControllerType,
		lowestControllerFirmwareVersion: currentLowestControllerFirmwareVersion,
	};
};

export const OnlineAccessGroupsPerSite = {
	level1: 32,
	level2: 50,
};

export const OnlineAccessGroupsPerUser = {
	level1: 1,
	level2: 3,
};

const ControllerLimits = {
	default: {
		'*': {
			onlineAccessGroupsPerSite: OnlineAccessGroupsPerSite.level1,
			onlineAccessGroupsPerUser: OnlineAccessGroupsPerUser.level1,
		},
	},
	[ControllerTypes.ACCESS_CONTROL_UNIT]: {
		// M50/M70
		'4.00': {
			onlineAccessGroupsPerSite: OnlineAccessGroupsPerSite.level1,
			onlineAccessGroupsPerUser: OnlineAccessGroupsPerUser.level1,
		},
		4.01: {
			onlineAccessGroupsPerSite: OnlineAccessGroupsPerSite.level2,
			onlineAccessGroupsPerUser: OnlineAccessGroupsPerUser.level2,
		},
	},
	[ControllerTypes.CONTROLLER_UPDATER]: {
		// M90
		'*': {
			onlineAccessGroupsPerSite: OnlineAccessGroupsPerSite.level2,
			onlineAccessGroupsPerUser: OnlineAccessGroupsPerUser.level2,
		},
	},
};

export const getControllerLimits = (controllersInfo) => {
	let controllerLimits = ControllerLimits.default['*'];

	if (!controllersInfo?.lowestControllerType) {
		return controllerLimits;
	}

	const controllerVersionLimits = ControllerLimits[controllersInfo.lowestControllerType];

	Object.entries(controllerVersionLimits).forEach(([version, value]) => {
		if (
			version === '*' ||
			isVersionEqual(controllersInfo.lowestControllerFirmwareVersion || '0', version) ||
			isVersionGreaterThan(controllersInfo.lowestControllerFirmwareVersion || '0', version)
		) {
			controllerLimits = value;
		}
	});

	return controllerLimits;
};
