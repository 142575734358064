import React from 'react';

import config from '../../systemConfig';
import core from '../core';
import Extras from './Extras';
import Logo from './Logo';

const incedo = {
	...core,
	colors: {
		...core.colors,
		header: '#00a0d0',
		footer: '#45637B',
		authBkg: '#FFFFFF',
		brand: {
			50: '#e6f6fa',
			100: '#b3e3f1',
			200: '#99d9ec',
			300: '#66c6e3',
			400: '#33b3d9',
			500: '#00a0d0',
			600: '#00a0d0',
			700: '#0080a6',
			800: '#007092',
			900: '#00607d',
			995: '',
		},
	},
	shadows: {
		...core.shadows,
		shadow_outline: `0 0 2px #00a0d0`,
		shadow_outline_2: `0 0 8px #00a0d0`,
	},
	brand: {
		logo: {
			component: (props) => <Logo {...props} />,
			type: 'image',
			fontSize: '36px',
		},
		header: {
			logoLight: {
				width: 147,
				height: 64,
			},
		},
		auth: {
			additionalLogo: {
				width: 250,
				height: 33,
				position: '',
				top: '',
				parent: {
					position: '',
				},
			},
			logo: {
				width: 250,
				height: 109,
			},
			backgroundImg: {
				width: '100%',
				height: 'auto',
				position: 'relative',
				left: '',
				top: '',
			},
		},
		extras: (props) => <Extras {...props} />,
		siteName: 'Incedo™ Business',
		siteNamePart1: 'Incedo™',
		siteNamePart2: 'Business',
		version: config.version,
		images: {
			logo: '/assets/images/incedo-business-logo.svg',
			logoLight: '/assets/images/incedo-business-logo-white.svg',
			extras: '/assets/images/cloud.png',
			headerImage: '/assets/images/auth-background.jpg',
			authBackground: '/assets/images/auth-background.jpg',
			additionalLogo: '/assets/images/AssaAbloyLogo.png',
			additionalLogoLight: '/assets/images/AssaAbloyLogo_white.png',
		},
	},
};

export default incedo;
