export const times = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
];

export const timesWithHalfHour = times.reduce((results, time) => {
	results.push(time);
	results.push(time.replace(':00', ':30'));

	return results;
}, []);

export const invisibleTimes = [...timesWithHalfHour, '24:00'];

export const days = [
	{ id: 0, name: 'Mon', slot: 0 },
	{ id: 1, name: 'Tues', slot: 0 },
	{ id: 2, name: 'Wed', slot: 0 },
	{ id: 3, name: 'Thurs', slot: 0 },
	{ id: 4, name: 'Fri', slot: 0 },
	{ id: 5, name: 'Sat', slot: 1 },
	{ id: 6, name: 'Sun', slot: 1 },
	{ id: 7, name: 'Hol', slot: 2 },
];
