import useSWR from 'swr';
import { useTreeSelectorData } from './useTreeSelectorData';
import { cache } from 'swr/_internal';
import { getUserAuthCacheData, useUserAuthData } from '../../auth/hooks/useUserAuthData';
import timezone from 'moment-timezone';

const fallbackData = { system: {}, site: {} };

const fetcher = async (treeSelectorData) => {
	const allSystems = treeSelectorData.reduce((results, treeData) => results.concat(treeData.systemsAndSites), []);

	const storedCurrentSystemId = sessionStorage.getItem('currentSystemId');
	const storedCurrentSiteId = sessionStorage.getItem('currentSiteId');

	const currentSystem =
		(storedCurrentSystemId && allSystems.find((system) => system.systemId === storedCurrentSystemId)) ||
		allSystems.find((system) => system.active) ||
		allSystems.find((system) => system.enabled) ||
		allSystems[0];

	const currentSite = storedCurrentSystemId
		? storedCurrentSiteId && currentSystem.sites.find((site) => site.siteId === storedCurrentSiteId)
		: currentSystem.sites.find((site) => site.timezone === timezone.tz.guess()) || currentSystem.sites[0];

	return {
		system: currentSystem,
		site: currentSite || {
			name: '',
			siteId: '',
		},
	};
};

export const useCurrentSystemSite = () => {
	const { data: treeSelectorData } = useTreeSelectorData();
	const {
		data: { administratorId },
	} = useUserAuthData();

	return useSWR(
		treeSelectorData.length ? `/current-system-site?${administratorId}` : null,
		() => fetcher(treeSelectorData),
		{
			revalidateOnFocus: false,
			fallbackData,
		}
	);
};

export const getCurrentSystemSiteCacheData = () => {
	const { administratorId } = getUserAuthCacheData();
	const cachedData = cache.get(`/current-system-site?${administratorId}`);

	return cachedData?.data || fallbackData;
};
