import React from 'react';
import { Button, Modal, Tabs } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { SystemDetails } from '~/components/features/system-settings/SystemDetails/SystemDetails';
import { Licenses } from '~/components/features/system-settings/Licenses/Licenses';
import { OfflineSettings } from '~/components/features/system-settings/OfflineSettings/OfflineSettings';

const { TabPane } = Tabs;

const tabs = {
	systemDetails: 'systemDetails',
	offlineSettings: 'offlineSettings',
	licenses: 'licenses',
};

export const SystemSettings = ({ onCancel }) => {
	const { translate } = useLocale();

	return (
		<Modal
			visible
			destroyOnClose
			maskClosable={false}
			width="480px"
			title={translate.byKey('system_settings_v2')}
			onCancel={onCancel}
			footer={[<Button onClick={onCancel}>{translate.byKey('close')}</Button>]}
		>
			<Tabs>
				<TabPane tab={translate.byKey('system_details')} key={tabs.systemDetails}>
					<SystemDetails />
				</TabPane>
				<TabPane tab={translate.byKey('offline_settings')} key={tabs.offlineSettings}>
					<OfflineSettings onClose={onCancel} />
				</TabPane>
				<TabPane tab={translate.byKey('licenses')} key={tabs.licenses}>
					<Licenses />
				</TabPane>
			</Tabs>
		</Modal>
	);
};
