import React, { useEffect, useMemo } from 'react';

import { BlocklistTable } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useCredentials } from '~/hooks/data/credentials/useCredentials';
import { getBlockedCredentialsKey } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { removeDiacritics } from '~/utils/string';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

// TODO #1 START - FILTERING BY CREDENTIAL HOLDER NAME ON THE CLIENT SINCE NO SUPPORT ON BACKEND ATM
// Remove this and other #1 todos and refactor when and if backend supports filtering by name, since this is a hack and client side filtering implementation

export const BlocklistClientDataTable = ({ onSelectedCredential }) => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageNumber, pageSize, mode, sortBy, sortDirection, person },
		setQueryParams,
	} = useQueryParams();
	const { data, isLoading, isValidating } = useCredentials(
		getBlockedCredentialsKey({
			customerId,
			systemId,
			pageSize,
			pageNumber,
			mode,
			sortBy,
			sortDirection,
			person,
		})
	);

	const dataFiltered = useMemo(() => {
		const filteredCredentials =
			data?.credentials?.filter((credential) =>
				removeDiacritics(credential.credentialHolder.name.toLowerCase()).includes(
					removeDiacritics(person.toLowerCase())
				)
			) || [];

		return {
			credentials: filteredCredentials,
			totalRecords: filteredCredentials.length,
		};
	}, [person, data?.credentials]);

	useEffect(() => {
		if (!dataFiltered) {
			return;
		}

		const lastPageNumber = Math.ceil(dataFiltered.credentials.length / (parseInt(pageSize) || DEFAULT_PAGE_SIZE));
		const currentPageNumber = parseInt(pageNumber) || 1;
		const isCurrentPageEmptyAndLast = lastPageNumber && lastPageNumber < currentPageNumber;

		if (isCurrentPageEmptyAndLast) {
			setQueryParams({
				pageNumber: currentPageNumber - 1,
			});
		}
	}, [dataFiltered, pageSize, pageNumber]);

	return <BlocklistTable data={dataFiltered} isLoading={isLoading || isValidating} onRowClick={onSelectedCredential} />;
};
