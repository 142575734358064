import environment from '~/env.json';
import { protocolEnabledHosts } from './themes/supportedThemes';

export const generateSystemConfig = (environment, localStorage, location) => {
	const devVersion = 2; //This defaults all UI regions to version 2

	let useNewPulseFormat = localStorage.getItem('useNewPulseFormat');
	let treatPulseAsDecimal = true;

	if (localStorage.getItem('treatPulseAsDecimal') === 'false') {
		treatPulseAsDecimal = false;
	}

	if (useNewPulseFormat === 'true') {
		useNewPulseFormat = true;
	} else {
		useNewPulseFormat = useNewPulseFormat !== 'false';
	}

	let serverUrl = environment.OCA_ENDPOINT;
	let realServerUrl = environment.REAL_OCA_ENDPOINT || environment.OCA_ENDPOINT;
	const incedoCloudApiUrl = environment.INCEDO_CLOUD_API;

	return {
		serverUrl,
		realServerUrl,
		incedoCloudApiUrl,
		devVersion: devVersion,
		emeaEndPoint: `${location.protocol}//${location.host}`,
		emeaClientId: environment.EMEA_CLIENT_ID,
		showControllerProtocol: protocolEnabledHosts.includes(location.hostname),
		useNewPulseFormat,
		treatPulseAsDecimal,
		jiraVersion: environment.JIRA_VERSION || 'Incedo UI 24.1.5',
		jiraDashboardLink: environment.JIRA_DASHBOARD_URL || 'https://jira.assaabloy.net/projects/DASICP2/versions/35099',
		applicationId: environment.APPLICATION_ID || 'incedo-cloud',
	};
};

const system = generateSystemConfig(environment, window.localStorage, window.location);

window.__getSystemConfig = function () {
	return { ...system };
};

Object.defineProperty(system, 'version', {
	enumerable: true,
	get() {
		const version = environment.VERSION || '1.0';
		if (environment.BUILD_ID) {
			return `${version}.${environment.BUILD_ID}`;
		}
		return version;
	},
});

export default system;
