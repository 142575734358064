import React from 'react';
import { Col, Form, Input, Row } from 'antd';

import { Item } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';

export const USB_DEVICE_FORM_NAME = 'usb-device-form';

export const UsbDeviceForm = ({ disabled, defaultValues, onFormSubmit, onFormChange }) => {
	const { translate } = useLocale();
	const [form] = Form.useForm();

	return (
		<Form
			name={USB_DEVICE_FORM_NAME}
			form={form}
			layout="vertical"
			size="medium"
			initialValues={defaultValues}
			scrollToFirstError
			onFinish={onFormSubmit}
			onValuesChange={(_, fieldValues) => onFormChange(fieldValues, form)}
		>
			<Row gutter={[24, 24]}>
				<Col sm={12}>
					<Item
						name="name"
						label={translate.byKey('usb_device_name')}
						rules={[
							{
								required: true,
								whitespace: true,
								message: translate.byKey('name_is_required'),
							},
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('name'),
									maxLength: 50,
								}),
							},
						]}
					>
						<Input name="name" disabled={disabled} autoFocus maxLength={50} showCount />
					</Item>
				</Col>

				<Col sm={12}>
					<Item
						name="description"
						label={translate.byKey('usb_device_description')}
						rules={[
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('description'),
									maxLength: 50,
								}),
							},
						]}
					>
						<Input name="description" disabled={disabled} maxLength={50} showCount />
					</Item>
				</Col>
			</Row>
		</Form>
	);
};
