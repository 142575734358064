import { useTheme } from 'emotion-theming';
import React from 'react';

import { Flex, Image } from '~/components';

const Extras = (props) => {
	const theme = useTheme();
	return (
		<Flex grow flexDirection="column" paddingLeft="18px">
			<Image width={70} height={39} src={theme.brand.images.extras} {...props} alt="cloud" />
		</Flex>
	);
};

export default Extras;
