import moment from 'moment';
import React, { useEffect } from 'react';
import { Page } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';

import { EVENTS_VISIBLE_MONTHS_THRESHOLD } from '~/constants/transactions';
import { Modal } from 'antd';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { AuditTransactionsPageHeader } from '~/components/features/audit-transactions/AuditTransactionsPageHeader/AuditTransactionsPageHeader';
import { AuditTransactionsEventsTable } from '~/components/features/audit-transactions/AuditTransactionsEventsTable/AuditTransactionEventsTable';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const AuditTransactionsPage = () => {
	const {
		data: {
			system: { systemId },
		},
	} = useCurrentSystemSite();
	const { queryParams, setQueryParams, clearAllQueryParams } = useQueryParams();
	const { translate } = useLocale();

	useEffect(() => {
		const clearSpecificQueryParamsOnSiteOrSystemChange = () => {
			clearAllQueryParams();
		};

		return clearSpecificQueryParamsOnSiteOrSystemChange;
	}, [systemId]);

	useEffect(() => {
		const eventsOlderThanThreshold = moment().subtract(EVENTS_VISIBLE_MONTHS_THRESHOLD, 'months');

		if (moment(queryParams.startTime).isBefore(eventsOlderThanThreshold)) {
			const newStartTime = eventsOlderThanThreshold.add(1, 'day');
			const newEndTime = moment(queryParams.endTime).isBefore(eventsOlderThanThreshold)
				? moment()
				: moment(queryParams.endTime);

			Modal.warning({
				title: translate.byKey('operation_impossible'),
				content: translate.byKeyFormatted('no_events_visible_before_months', [
					EVENTS_VISIBLE_MONTHS_THRESHOLD,
					`${newStartTime.format('L LT')} - ${newEndTime.format('L LT')}`,
				]),
			});

			setQueryParams({
				startTime: newStartTime,
				endTime: newEndTime,
			});
		}
	}, [queryParams.startTime, queryParams.endTime]);

	return (
		<Page>
			<AuditTransactionsPageHeader />
			<AuditTransactionsEventsTable />
		</Page>
	);
};
