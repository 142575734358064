import { Progress as AProgress } from 'antd';
import { useTheme } from 'emotion-theming';
import React from 'react';

const Progress = ({ status, percent, showInfo, ...props }) => {
	const theme = useTheme();
	return (
		<AProgress
			{...props}
			strokeColor={{
				from: theme.colors.brand[500],
				to: theme.colors.brand[300],
			}}
			showInfo={showInfo}
			percent={percent || 100}
			status={status || 'active'}
		/>
	);
};

export default Progress;
