import React, { useEffect } from 'react';

import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { DoorGroupsTable } from '~/components/features/door-groups/table/DoorGroupsTable/DoorGroupsTable';
import { AssetTypes } from '~/constants/AssetTypes';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const DoorGroupsDataTable = ({ onRowClick }) => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, sortBy, sortDirection },
		setQueryParams,
	} = useQueryParams();
	const { data, isLoading, isValidating } = useAssets(
		siteId
			? getAssetsKey({
					customerId,
					systemId,
					siteId,
					pageSize: pageSize,
					pageNumber: pageNumber,
					sortBy,
					sortDirection,
					assetType: AssetTypes.BASIC_ASSET_GROUP,
			  })
			: null
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		const lastPageNumber = Math.ceil(data.totalRecords / (parseInt(pageSize) || DEFAULT_PAGE_SIZE));
		const currentPageNumber = parseInt(pageNumber) || 1;
		const isCurrentPageEmptyAndLast = data.assets.length === 0 && lastPageNumber && lastPageNumber < currentPageNumber;

		if (isCurrentPageEmptyAndLast) {
			setQueryParams({
				pageNumber: currentPageNumber - 1,
			});
		}
	}, [data, pageSize, pageNumber]);

	return <DoorGroupsTable data={data} isLoading={isLoading || isValidating} onRowClick={onRowClick} />;
};
