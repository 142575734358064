import { useRef } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useAccessTransactions = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/reports/access-log',
				method: method.post,
				removeDomainKey: true,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [
			,
			,
			pageSize,
			pageNumber,
			siteId,
			eventName,
			reasonCode,
			siteName,
			controllerId,
			accessPointName,
			credentialHolderName,
			startTimeAsIsoString,
			endTimeAsIsoString,
		] = key.split(separator);

		return ref.current.fetchData(
			omitBy(
				{
					pageSize: parseInt(pageSize),
					pageNumber: parseInt(pageNumber),
					siteId,
					eventName,
					reasonCode,
					siteName,
					controllerId,
					accessPointName,
					credentialHolderName,
					startTime: moment(startTimeAsIsoString),
					endTime: moment(endTimeAsIsoString),
					__contentType: 'application/vnd.hidglobal.ca.event-report-2.0+json',
				},
				isExcludedValue
			)
		);
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessTransactionsKey = ({
	customerId,
	systemId,
	pageSize,
	pageNumber,
	siteId,
	eventName,
	reasonCode,
	siteName,
	controllerId,
	accessPointName,
	credentialHolderName,
	startTime,
	endTime,
}) =>
	`${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${siteId}${separator}\
${eventName}${separator}\
${reasonCode}${separator}\
${siteName}${separator}\
${controllerId}${separator}\
${accessPointName}${separator}\
${credentialHolderName}${separator}\
${startTime}${separator}\
${endTime}${separator}\
accessTransactions`;
