import { supportedLanguages } from '~/screens/_shared/AppLocale';

export function getDefaultLanguageCode() {
	const storedLanguage = window.localStorage.getItem('selectedLanguage');
	const localCode = storedLanguage || window.navigator.userLanguage || window.navigator.language;

	if (localCode && supportedLanguages.findIndex((lang) => lang.value === localCode) !== -1) {
		return localCode;
	} else if (localCode && supportedLanguages.findIndex((lang) => lang.value === localCode.split('-')[0]) !== -1) {
		return localCode.split('-')[0];
	}

	return 'en-GB';
}
