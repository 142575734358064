import { useMemo } from 'react';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

import { getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';

export const useCurrentUser = () => {
	const {
		data: { system },
	} = useCurrentSystemSite();

	const currentUser = useMemo(() => getCurrentAdministrator(system), [system]);

	return { currentUser };
};
