import { Tooltip, Radio } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { UserOutlined } from '@ant-design/icons';

import systemConfig from '~/screens/_shared/systemConfig';
import { CardList } from '../../components/features/people/modals/PeopleModal/tabs/CredentialsTab/CardList';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { PeopleFormStepTabName, PeopleModal } from '~/components/features/people/modals/PeopleModal/PeopleModal';
import { blockListState } from '~/components/features/people/constants/blockListState';
import mappers from '~/screens/_shared/mappers';
import { Button, Flex, Page, PageHeader, AddButton, Spin, Modal } from '~/components';
import { useGetApi, useListApi } from '~/screens/_shared/useApi';
import { systemLimits } from '~/screens/_shared/systemLimits';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { translator, useLocale } from '~/screens/_shared/AppLocale';
import { showSuccessToast } from '~/screens/_shared/toast';
import { getLimits } from '~/screens/_shared/entitlement';
import { bindAdministratorsToCredentialHolders, filterAndMergeAdmins } from '~/screens/_shared/userRoleConstants';
import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { FeatureTypes } from '~/constants/FeatureTypes';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import moment from 'moment';

const loadedPages = new Map();

const PERSON_TYPE = {
	EVERYBODY: 'everybody',
	ADMINISTRATORS: 'administrators',
};

// const CSV_TEMPLATE_PATH = '/import_template.csv';
// const CSV_DOWNLOAD_FILENAME = 'People Batch Upload Template.csv';

const DEFAULT_TABLE_PAGINATION = {
	currentPage: 1,
	totalRecords: 0,
	rowsPerPage: DEFAULT_PAGE_SIZE,
	currentRecords: [],
};

const DEFAULT_TABLE_SORTING = {
	field: 'name',
	order: 'ascend',
};

const DEFAULT_TABLE_FILTERS = {
	personName: null,
	personType: PERSON_TYPE.EVERYBODY,
};

// https://jira.assaabloy.net/browse/DASICP2-2708 - suggested max length by QA
// maximum length of combination of Name is 90 First + Space + 90 Last (181 together)
const NAME_SEARCH_MAX_LENGTH = 181;

const calculateTotalNumberOfRecords = (records) => {
	return records.totalRecords ?? Number(records.pagination.first.pageSize) * Number(records.pagination.last.pageNumber);
};

const addNewRecord = (record) => {
	if (!loadedPages.has(0)) {
		loadedPages.set(0, []);
	}
	const pageZero = loadedPages.get(0);

	if (!pageZero.some((person) => person.credentialHolderId === record.credentialHolderId)) {
		pageZero.push(record);
	}
};

const updateRecord = (record, shouldRemove) => {
	if (!loadedPages.has(0)) {
		loadedPages.set(0, []);
	}
	for (const [, page] of loadedPages) {
		const person = page.find((person) => person.credentialHolderId === record.credentialHolderId);

		if (person) {
			if (shouldRemove) {
				page.splice(page.indexOf(person), 1);
			} else {
				Object.assign(person, record);
			}
		}
	}
};

export const checkCredentialHolderLimit = (system) => {
	const limits = system ? getLimits(system, 'credential_holder') : { usedCount: 0, hardLimit: 0 };
	if (limits.usedCount >= limits.hardLimit) {
		Modal.warn({
			title: translator.byKey('limit_reached_title_v2'),
			content: translator.byKeyFormatted('door_limit_message_v2', [translator.byKey('credential_holders_v2')]),
			okText: translator.byKey('ok'),
		});
		return true;
	}
	return false;
};

export const PeoplePage = () => {
	const { translate } = useLocale();

	const version = systemConfig.devVersion;
	const {
		data: { system, site },
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();

	const [tablePagination, setTablePagination] = useState(DEFAULT_TABLE_PAGINATION);
	const [tableSorting, setTableSorting] = useState(DEFAULT_TABLE_SORTING);
	const [tableFilters, setTableFilters] = useState(DEFAULT_TABLE_FILTERS);
	const [tableData, _setTableData] = useState([]);

	const setTableData = (data) => {
		_setTableData(data.map((data) => ({ ...data, key: data.credentialHolderId })));
	};

	const [isTablePaginationDisabled, setIsTablePaginationDisabled] = useState(false);

	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState({});
	const [selectedCredentials, setSelectedCredentials] = useState({});
	const [credentialsVisible, setCredentialsVisible] = useState(false);
	const [forceUnmount, setForceUnmount] = useState(1);
	const [isLoadingPeople, setIsLoadingPeople] = useState(false);

	const [getPeopleRaw] = useListApi(mappers.credentialHolder);
	const [getUser] = useGetApi(mappers.user);
	const [getCredentials, isLoadingCredentials] = useListApi(mappers.credential);

	const getPeople = async (queryParams) => {
		try {
			setIsLoadingPeople(true);

			const personType = queryParams.personType;
			const results = await getPeopleRaw(queryParams);

			if (system.businessEntityId) {
				results.credentialHolders = await bindAdministratorsToCredentialHolders(
					results.credentialHolders,
					system.administrators,
					getUser
				);
			}

			if (personType === PERSON_TYPE.ADMINISTRATORS) {
				results.credentialHolders = filterAndMergeAdmins(results.credentialHolders, system.administrators);
				results.totalRecords = results.credentialHolders.length;
			}

			return results;
		} catch (error) {
			// TODO SOMETHING MEANINGFUL
			console.error('Error when fetching people');
		} finally {
			setIsLoadingPeople(false);
		}
	};

	const getBlockedCredentials = async () => {
		return await getCredentials({
			params: {
				'page-size': systemLimits.BlockList,
				page: 1,
				'detail-level': 'FULL',
				'credential-states': blockListState.BLOCKED,
				'in-offline-blocklist': true,
			},
		});
	};

	const getPeopleWithBlockedStatusApplied = (credentialHolders, blockedCredentials) => {
		return credentialHolders.map((person) => {
			const blockedCredentialExists = blockedCredentials.credentials.find(
				(credential) => credential.credentialHolder.credentialHolderId === person.credentialHolderId
			);

			person.status = blockedCredentialExists ? 'blocked' : '';

			return person;
		});
	};

	const loadPeople = async (personType = PERSON_TYPE.EVERYBODY, pagination, sorting, filters) => {
		const isAdministrator = personType === PERSON_TYPE.ADMINISTRATORS;
		pagination = pagination ?? DEFAULT_TABLE_PAGINATION;
		sorting = sorting ?? DEFAULT_TABLE_SORTING;
		filters = filters ?? DEFAULT_TABLE_FILTERS;

		const queryParams = {
			params: {
				page: pagination.currentPage,
				'page-size': pagination.rowsPerPage === 0 ? DEFAULT_PAGE_SIZE : pagination.rowsPerPage,
				'sort-by': sorting.field,
				'sort-order': sorting.order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
				'detail-level': 'FULL',
			},
		};

		if (filters.personName) {
			queryParams.params.name = filters.personName;
		}

		if (isAdministrator) {
			queryParams.personType = PERSON_TYPE.ADMINISTRATORS;
		}

		try {
			const people = await getPeople(queryParams);

			const blockedCredentials = await getBlockedCredentials();
			people.credentialHolders = people.credentialHolders || [];
			blockedCredentials.credentials = blockedCredentials.credentials || [];
			people.credentialHolders = getPeopleWithBlockedStatusApplied(people.credentialHolders, blockedCredentials);

			if (isAdministrator) {
				setTablePagination({
					rowsPerPage: pagination.rowsPerPage,
					currentPage: pagination.currentPage,
					totalRecords: calculateTotalNumberOfRecords(people),
					currentRecords: [...people.credentialHolders],
				});
			} else {
				const pageSize = Number(people.pagination.first.pageSize);

				setTablePagination({
					rowsPerPage: pageSize,
					currentPage: pagination.currentPage,
					totalRecords: calculateTotalNumberOfRecords(people),
					currentRecords: [...people.credentialHolders],
				});
			}

			setTableData(people.credentialHolders);
		} catch (error) {
			// TODO SOMETHING MEANINGFUL
			console.error('Error when fetching people');
		}
	};

	const onTableChange = (pagination, filter, sorter) => {
		const newPagination = {
			currentPage: pagination.pageSize !== tablePagination.rowsPerPage ? 1 : pagination.current,
			rowsPerPage: pagination.pageSize,
		};
		const newSorting = {
			field: sorter.field,
			order: sorter.order,
		};
		let searchedName = '';

		if (Array.isArray(filter.name)) {
			searchedName = filter.name[0];
		} else {
			searchedName = filter.name;
		}

		setTableSorting(newSorting);
		setTableFilters({ ...tableFilters, personName: searchedName });

		// currently this is client side pagination and filtering, backend doesn't support administrators pagination
		if (tableFilters.personType === PERSON_TYPE.ADMINISTRATORS) {
			if (
				pagination.current !== tablePagination.currentPage ||
				pagination.rowsPerPage !== tablePagination.rowsPerPage
			) {
				setTablePagination({ ...tablePagination, ...newPagination });
			}

			if (searchedName !== null) {
				if (searchedName === '') {
					loadPeople(
						PERSON_TYPE.ADMINISTRATORS,
						{ ...tablePagination, currentPage: 1, rowsPerPage: DEFAULT_PAGE_SIZE },
						tableSorting,
						{
							personName: null,
						}
					);

					setIsTablePaginationDisabled(false);
					return;
				} else {
					const newData = tableData.filter((person) =>
						person.administrator.firstName.toLowerCase().includes(searchedName.toLowerCase())
					);

					setTablePagination({
						rowsPerPage: newData.length,
						currentPage: 1,
						totalRecords: newData.length,
						currentRecords: [...newData],
					});
					setIsTablePaginationDisabled(true);

					setTableData(newData);
				}
			}

			return;
		}

		loadPeople(PERSON_TYPE.EVERYBODY, newPagination, newSorting, { personName: searchedName });
	};

	const onUpdated = (results) => {
		switch (results.action) {
			case 'create':
				addNewRecord(results);
				showSuccessToast(
					translate.byKeyFormatted('credential_holder_created_successfully_formatted', [
						results.firstName,
						results.lastName,
					])
				);
				loadPeople(tableFilters.personType, tablePagination, tableSorting, { personName: tableFilters.personName });
				setVisible(false);
				break;

			case 'update':
				updateRecord(results);
				showSuccessToast(
					translate.byKeyFormatted('person_successfully_updated_formatted', [results.firstName, results.lastName])
				);
				loadPeople(tableFilters.personType, tablePagination, tableSorting, { personName: tableFilters.personName });
				setVisible(false);
				break;

			case 'delete':
				showSuccessToast(
					translate.byKeyFormatted('successfully_deleted_formatted', [results.firstName, results.lastName])
				);
				updateRecord(results, true);
				loadPeople(tableFilters.personType, tablePagination, tableSorting, { personName: tableFilters.personName });
				setVisible(false);
				break;

			default:
				throw Error('Wrong action applied');
		}
	};

	const onCredentialsCancel = useCallback(() => {
		setCredentialsVisible(false);
	}, [setCredentialsVisible]);

	const onAddNewPersonClicked = () => {
		if (checkCredentialHolderLimit(system)) return;

		setForceUnmount((prev) => prev + 1);
		setSelected(null);
		setVisible(true);
	};

	const onModalClose = () => {
		setSelected(null);
		setVisible(false);
	};

	const onCredentialsModalClose = () => {
		setSelectedCredentials(null);
	};

	const onRowClick = (credentialHolder, selectedTab = null) => {
		credentialHolder = credentialHolder || {};
		credentialHolder.defaultSelectedTab = selectedTab;
		setVisible(true);
		setSelected(credentialHolder);
	};

	const onCardsClick = (credentialHolder) => {
		setCredentialsVisible(true);
		setSelectedCredentials(credentialHolder);
	};

	const onUserTypeChange = (e) => {
		setIsTablePaginationDisabled(false);

		const personType = e.target.value;

		setTableFilters({
			...tableFilters,
			personName: null,
			personType,
		});

		setTableSorting({
			...tableSorting,
			order: 'ascend',
		});

		loadPeople(personType, { ...tablePagination, currentPage: 1, rowsPerPage: DEFAULT_PAGE_SIZE }, tableSorting, {
			personName: null,
		});
	};

	/**
	 * On this page specifically we only have reference to system level items.
	 * Keeping that in mind, we only need to check for a valid systemId
	 */
	useEffect(() => {
		if (system.systemId && site.siteId) {
			loadPeople();
			loadedPages.clear();
		}
	}, [system, site]);

	const tableColumns = [
		{
			title: translate.byKey('name'),
			key: 'name',
			dataIndex: 'name',
			sorter: createSorter('name'),
			defaultSortOrder: 'ascend',
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: tableSorting.order,
			render: (value, row) => {
				if (row.administrator) {
					return (
						<Tooltip title={getLicensedCount(system, FeatureTypes.CREDENTIAL_HOLDER)}>
							<span>
								{value} <UserOutlined />
							</span>
						</Tooltip>
					);
				}

				return <Tooltip title={getLicensedCount(system, FeatureTypes.CREDENTIAL_HOLDER)}>{value}</Tooltip>;
			},
			filterDropdown: (props) => <SearchFilter antdFilterProps={props} maxLength={NAME_SEARCH_MAX_LENGTH} />,
			filteredValue: tableFilters?.personName ? [tableFilters?.personName] : null,
			filterIcon: <SearchFilterIcon />,
		},
		{
			title: translate.byKey('door_status_header_v2'),
			dataIndex: 'status',
			render: (text, row) => {
				if (text === 'blocked') {
					return <span style={{ color: 'red' }}>{translate.byKey('blocked_v2')}</span>;
				}
				if (row?.validity?.endDateTime && moment().isAfter(moment(row.validity.endDateTime))) {
					return <span style={{ color: 'orange' }}>{translate.byKey('expired')}</span>;
				}
				return text;
			},
		},
		{
			title: '',
			dataIndex: 'cards',
			render: (_, row) => (
				<Flex grow justifyContent="flex-end" alignItems="flexStart" paddingRight="20px">
					{hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.READ) && (
						<Button
							type="primary"
							size="small"
							data-testid={'btn-view-credentials'}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								if (version >= 2) {
									onRowClick(row, PeopleFormStepTabName.CredentialsTab);
								} else {
									onCardsClick(row);
								}
							}}
						>
							{translate.byKey(version >= 2 ? 'view_credentials_v2' : 'view_cards')}
						</Button>
					)}
				</Flex>
			),
		},
	];

	return (
		<Page>
			<PageHeader title={translate.byKey('people_menu_title')} icon="people">
				<Radio.Group
					disabled={isLoadingPeople || isLoadingCredentials}
					options={[
						{ label: translate.byKey('everybody_person_filter_label'), value: 'everybody' },
						{ label: translate.byKey('admins_only_person_filter_label'), value: 'administrators' },
					]}
					onChange={onUserTypeChange}
					value={tableFilters.personType}
					style={{ alignSelf: 'center' }}
				/>

				{hasActionPermissions(user, userConstants.screens.PEOPLE, userConstants.actions.CREATE) && (
					<>
						{/* BATCH UPLOAD TO BE RE-ADDED AFTER REFINING ON THE API SIDE */}
						{/* <BatchUpload
							setIsLoadingPeople={setIsLoadingPeople}
							loadPeople={loadPeople}
							dropdownButtonStyle={{ marginLeft: '1rem', marginRight: '1rem', width: 'auto' }}
						/> */}
						<AddButton
							hasControllerDependency={false}
							screen={userConstants.screens.PEOPLE}
							onAddNewClick={onAddNewPersonClicked}
						/>
					</>
				)}
			</PageHeader>

			<TableWithFiltersSection
				loading={{
					spinning: isLoadingPeople || isLoadingCredentials,
					indicator: <Spin active={true} />,
				}}
				columns={tableColumns}
				onChange={onTableChange}
				dataSource={tableData}
				scroll={getScrollHeightOptions()}
				onRow={(record) => ({
					onClick: () => onRowClick(record),
				})}
				rowKey="credentialHolderId"
				pagination={{
					showTotal: (total, range) => translate.byKeyFormatted('results_counter_label', [range[0], range[1], total]),
					position: ['bottomCenter'],
					showSizeChanger: !isTablePaginationDisabled,
					pageSize: tablePagination.rowsPerPage,
					total: tablePagination.totalRecords,
					current: tablePagination.currentPage,
					disabled: isTablePaginationDisabled,
				}}
			/>

			<PeopleModal
				key={forceUnmount}
				visible={visible}
				selected={selected}
				onClose={onModalClose}
				onUpdated={onUpdated}
			/>

			<CardList
				visible={credentialsVisible}
				selected={selectedCredentials}
				onClose={onCredentialsModalClose}
				onCancel={onCredentialsCancel}
			/>
		</Page>
	);
};
