import { useState } from 'react';

import { method } from '~/screens/_shared/useApi/apiConstants';
import { useDeleteApi, useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import mappers from '~/screens/_shared/mappers';

export function useDeleteController() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const { translate } = useLocale();

	/** ***************** **/
	/** Declare useApi functions for API Calls **/
	/** ***************** **/

	const [deleteController] = useDeleteApi(mappers.controller);

	const [deboardController] = useDeleteApi(mappers.controllerDeOnboard);

	const [getAllPorts] = useListApi(mappers.port);

	const [deletePort] = useDeleteApi(mappers.port);

	/**
	 * Delete an Existing Controller
	 */
	async function deleteControllerInit(controllerId, type = 'controller', siteId) {
		setIsLoading(true);
		const cacheKey = getCacheKey(method.list, mappers.controller, { params: { 'detail-level': 'FULL' } });
		const systemCacheKey = getCacheKey(method.list, mappers.controllersBySystem, {
			params: { 'detail-level': 'FULL' },
		});
		try {
			setCurrentStep(translate.byKey('deleting_controller_port_configurations'));
			const ports = await getAllPorts({ controllerId, siteId }, { removeExistingKey: true });

			for (let port of ports) {
				await deletePort({ controllerId, portId: port.portId, siteId });
			}

			if (type === 'controller' && ports.length !== 0) {
				setCurrentStep(translate.byKey('de_boarding_controller'));
				await deboardController({ controllerId, siteId });
			}

			setCurrentStep(translate.byKey('deleting_controller'));
			await deleteController({ controllerId, siteId }, { removeExistingKey: [cacheKey, systemCacheKey] });
		} catch (error) {
			if (error.status && error.status === 404) {
				setCurrentStep(translate.byKey('deleting_controller'));
				// Skip to delete controller step.
				await deleteController({ controllerId, siteId }, { removeExistingKey: [cacheKey, systemCacheKey] });
			}
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	}

	return [deleteControllerInit, isLoading, currentStep];
}
