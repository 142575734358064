import React, { useEffect, useState } from 'react';

import { Modal, Page, PageHeader, ReloadButton, Spin, TypedAddButton } from '~/components';
import mappers from '~/screens/_shared/mappers';
import { useGetApi, useListApi } from '~/screens/_shared/useApi';

import { translator, useLocale } from '~/screens/_shared/AppLocale';
import { ControllerForm } from './ControllerForm';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import moment from 'moment';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import { getReadableName } from '~/screens/_shared/getReadableName';
import { getLimits } from '~/screens/_shared/entitlement';
import { ControllerProtocols } from '~/constants/ControllerProtocols';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { MultipleChoiceFilter } from '~/components/shared/filters/MultipleChoiceFilter/MultipleChoiceFilter';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { css } from '@emotion/core';
import { SingleChoiceFilter } from '~/components/shared/filters/SingleChoiceFilter/SingleChoiceFilter';
import { CategorizedMultipleChoiceFilter } from '~/components/shared/filters/CategorizedMultipleChoiceFilter/CategorizedMultipleChoiceFilter';
import { getScrollHeightOptions } from '~/constants/Table';
import { useSiteControllersEmergencyRecords, getSiteControllersEmergencyKey } from '~/screens/_shared/emergency';
import { mutate } from 'swr';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const devicesTypeTranslationKeys = {
	[ControllerTypes.CONTROLLER_UPDATER]: 'updater_controller_v2',
	[ControllerTypes.ACCESS_CONTROL_UNIT]: 'cluster_controller_v2',
};
const defaultDeviceTypeTranslationKey = 'controller';
const getDeviceTypeTranslationKey = (deviceType) =>
	devicesTypeTranslationKeys[deviceType] || defaultDeviceTypeTranslationKey;

export async function getRelevantSync(updatedController, onlineStatus = null, siteId) {
	const allSyncState = await this.getSyncState(
		{
			controllerId: updatedController.controllerId,
			siteId,
			params: {
				'detail-level': 'simple',
			},
		},
		{
			expiry: moment().add(1, 'second'),
			shared: true,
		}
	);

	if (allSyncState.peripheralDeviceStates) {
		const possibleErrorReaders = allSyncState.peripheralDeviceStates.filter(
			(d) => d.syncState === 'DELETE_FAILED' || d.syncState === 'UPDATE_FAILED'
		);
		if (possibleErrorReaders.length > 0) {
			const assignedReaders = await this.getAssignedReaders(
				{
					params: { assignedToPortal: 'true' },
					siteId,
				},
				{
					expiry: moment().add(1, 'second'),
					shared: true,
				}
			);
			allSyncState.peripheralDeviceStates = allSyncState.peripheralDeviceStates.filter((d) =>
				assignedReaders.some((r) => r.deviceId === d.deviceId)
			);
		}
	}

	return allSyncState;
}

export function checkControllerLimits(system, controllerType) {
	const limits = getLimits(system, controllerType);
	let controllerTypeName = translator.byKey('cluster_controller_v2');

	if (controllerType === ControllerProtocols.PASSTHROUGH) {
		controllerTypeName = translator.byKey('updater_controller_v2');
	}

	if (limits.usedCount >= limits.hardLimit || !system) {
		Modal.warn({
			title: translator.byKey('limit_reached_title_v2'),
			content: translator.byKeyFormatted('entitlement_limit_reached_message', { entitlement: controllerTypeName }),
			okText: translator.byKey('ok'),
		});
		return true;
	}

	return false;
}

export const isControllerOnboarded = (controller) =>
	controller.protocol === ControllerProtocols.IMPROX || controller.protocol === ControllerProtocols.PASSTHROUGH;

const Controllers = () => {
	const {
		data: { system, site },
		mutate: mutateSystemSite,
	} = useCurrentSystemSite();
	const { translate } = useLocale();
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState({});
	const [filteredData, setFilteredData] = useState([]); // The filtered list. Only has controllers with state=3
	const [isLoading, setIsLoading] = useState(false);
	const [controllers, setControllers] = useState([]);
	const [controllerType, setControllerType] = useState('improx');
	const [pageFilters, setPageFilters] = useState({
		syncState: null,
		deviceStatus: null,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

	const [getControllersSiteStatus] = useGetApi(mappers.deviceStatusSite);
	const [getControllersSystemStatus] = useGetApi(mappers.deviceStatusSystem);
	const [getControllers] = useListApi(mappers.controller);
	const [getControllersBySystem] = useListApi(mappers.controllersBySystem);
	const [getSyncState] = useListApi(mappers.syncState);
	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);
	const { isInEmergencyMode } = useSiteControllersEmergencyRecords();
	const controllerStatuses = ['UNKNOWN', 'DISCONNECTED', 'CONNECTED'];
	const controllerSyncStates = [
		'DELETE_GLITCH',
		'DELETE_FAILED',
		'PENDING_DELETE',
		'DELETED',
		'UPDATE_GLITCH',
		'UPDATE_FAILED',
		'PENDING_UPDATE',
		'UPDATED',
	];

	const getRelevantSyncState = getRelevantSync.bind({ getSyncState, getAssignedReaders });
	const deviceTypeFilters = [
		{
			label: translator.byKey('updater_controller_v2'),
			value: ControllerTypes.CONTROLLER_UPDATER,
			count: controllers.filter((controller) => controller.type === ControllerTypes.CONTROLLER_UPDATER).length,
		},
		{
			label: translator.byKey('cluster_controller_v2'),
			value: ControllerTypes.ACCESS_CONTROL_UNIT,
			count: controllers.filter((controller) => controller.type === ControllerTypes.ACCESS_CONTROL_UNIT).length,
		},
	];

	const onAddControllerClick = (value) => {
		if (checkControllerLimits(system, value)) return;
		setSelected({});
		setControllerType(value);
		setVisible(true);
	};

	/**
	 * Row Clicked
	 */
	const onRowClick = (row) => {
		setSelected(row);
		setControllerType(row.protocol);
		setVisible(true);
	};

	const applyFiltersToControllers = (controllers, filters) => {
		if (filters.name) {
			controllers = controllers.filter((item) =>
				item.name.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase())
			);
		}

		if (filters.syncState) {
			controllers = controllers.filter((item) =>
				filters.syncState.find(
					(syncStateItem) => syncStateItem.toLocaleLowerCase() === item.syncState?.toLocaleLowerCase()
				)
			);
		}

		if (filters.deviceStatus) {
			controllers = controllers.filter((item) =>
				filters.deviceStatus.find(
					(deviceStatusItem) => deviceStatusItem.toLocaleLowerCase() === item.deviceStatus?.toLocaleLowerCase()
				)
			);
		}

		if (filters.deviceTypes?.length) {
			controllers = controllers.filter((item) => filters.deviceTypes.includes(item?.type));
		}

		if (filters.serialNumber) {
			controllers = controllers.filter((item) =>
				item.serialNumber.toLocaleLowerCase().includes(filters.serialNumber.toLocaleLowerCase())
			);
		}

		if (filters.macAddress) {
			controllers = controllers.filter((item) =>
				item.macAddress.toLocaleLowerCase().includes(filters.macAddress.toLocaleLowerCase())
			);
		}

		if (filters.siteName) {
			controllers = controllers.filter((item) =>
				item.siteName.toLocaleLowerCase().includes(filters.siteName.toLocaleLowerCase())
			);
		}

		return controllers;
	};

	const onControllerUpdated = (clearCache = false) => {
		if (system.systemId) {
			setIsLoading(true);
			setFilteredData([]);
			let results;
			if (site?.siteId) {
				results = getControllers({ params: { 'detail-level': 'FULL' } });

				if (clearCache) {
					// forcing a site update to make DeviceLockState pay attention
					mutateSystemSite({ system, site: { ...site } });
					mutate(
						getSiteControllersEmergencyKey({
							customerId: system.customerId,
							systemId: system.systemId,
							siteId: site.siteId,
						})
					);
				}
			} else {
				results = getControllersBySystem(
					{ params: { 'detail-level': 'FULL' } },
					{ expiry: moment().add(15, 'minutes'), removeExistingKey: clearCache }
				);
			}

			results
				.then(setControllersStatus)
				.then((controllers) => {
					controllers = (controllers || []).map((controller) => {
						controller.macAddress = controller.configuration.additionalSettings.macAddress;
						controller.serialNumber = controller.configuration.additionalSettings.serialNumber;
						return controller;
					});

					let filteredControllers = controllers;

					if (!site?.siteId) {
						filteredControllers = applyFiltersToControllers(controllers, pageFilters);
					}

					setFilteredData(filteredControllers);
					setControllers(controllers);
					setIsLoading(false);
				})
				.catch(() => {
					setFilteredData([]);
					setIsLoading(false);
					// TODO something meaningful here
				});
		} else {
			setFilteredData([]);
		}
	};

	const setControllersStatus = async (controllers) => {
		try {
			controllers = controllers
				.filter((controller) => controller.protocol === 'improx' || controller.protocol === 'passthrough')
				.map((controller) => {
					let deviceType;
					if (controller.protocol === 'improx') {
						deviceType = translate.byKey('controller');
					} else if (controller.protocol === 'passthrough') {
						deviceType = translate.byKey('updater_controller_v2');
					} else {
						deviceType = controller.protocol;
					}
					controller.deviceType = deviceType;

					if (site) {
						const site = system.sites.find((site) => site.siteId === controller.siteId);
						controller.siteName = site?.name ?? '';
					}

					return controller;
				});

			controllers = await Promise.all(
				controllers.map(async (controller) => {
					const statuses = await getRelevantSyncState(controller, {}, site?.siteId ? site.siteId : controller.siteId);
					if (Array.isArray(statuses)) {
						controller.syncState =
							statuses.find((status) => status.controllerId === controller.controllerId)?.syncState || '';
					} else {
						controller.syncState = statuses.controllerInfoSyncState || '';
					}

					return controller;
				})
			);

			let { deviceStatuses } =
				site && site.siteId
					? await getControllersSiteStatus({
							systemId: system.systemId,
							siteId: site.siteId,
							params: { 'page-size': 1000 },
						})
					: await getControllersSystemStatus({ systemId: system.systemId, params: { 'page-size': 1000 } });

			controllers = controllers.map((controller) => {
				let device = deviceStatuses?.find((device) => device.deviceId === controller.controllerId);
				if (device) {
					controller.deviceStatus = device.connectionStatus;
				}
				return controller;
			});

			return controllers;
		} catch (e) {
			console.error(e);
		}
	};

	/**
	 * Load the list only if we have both site and system. Update the list
	 * when the site or system change.
	 */
	useEffect(() => {
		onControllerUpdated();
	}, [system, site]);

	const tableChanged = (pagination, filter, sorter, extra) => {
		filter = filter || {};

		let syncState = null;
		let deviceStatus = null;
		let name = null;
		let deviceTypes = [];
		let serialNumber = null;
		let macAddress = null;
		let siteName = null;

		if (Boolean(filter.syncState?.length)) {
			syncState = filter.syncState;
		}

		if (Boolean(filter.deviceStatus?.length)) {
			deviceStatus = filter.deviceStatus;
		}

		if (Boolean(filter.name?.length)) {
			name = filter.name[0];
		}

		if (Boolean(filter.type?.length)) {
			deviceTypes = [...filter.type];
		}

		if (Boolean(filter.serialNumber?.length)) {
			serialNumber = filter.serialNumber[0];
		}

		if (Boolean(filter.macAddress?.length)) {
			macAddress = filter.macAddress[0];
		}

		if (Boolean(filter.siteName?.length)) {
			siteName = filter.siteName[0];
		}

		const formattedPageFilters = {
			name,
			serialNumber,
			macAddress,
			deviceTypes,
			deviceStatus,
			syncState,
			siteName,
		};

		setPageFilters(formattedPageFilters);

		let listToFilter = Array.from(controllers);

		listToFilter = applyFiltersToControllers(listToFilter, formattedPageFilters);

		if (siteName) {
			listToFilter = listToFilter.filter((item) =>
				item.siteName.toLocaleLowerCase().includes(siteName.toLocaleLowerCase())
			);
		}

		setFilteredData(listToFilter);
		setPageSize(pagination.pageSize);
		setCurrentPage(pageSize !== pagination.pageSize ? 1 : pagination.current);
	};

	const doorStatusFilterOptions = controllerStatuses.map((item) => ({
		label: translate.byKey(item.toLowerCase() + '_upper_case'),
		value: item,
		count: controllers.filter((controller) => controller.deviceStatus === item).length,
	}));

	const syncStatusFilterOptions = controllerSyncStates
		.map((item) => ({
			label: translate.byKey(item.toLowerCase() + '_upper_case'),
			value: item,
			count: controllers.filter((controller) => controller.syncState === item).length,
		}))
		.reduce(
			(previousValue, currentValue) => {
				if (currentValue.value.toLowerCase().includes('delete')) {
					const items = [...previousValue[0].items, currentValue];
					return [{ ...previousValue[0], items }, previousValue[1]];
				} else {
					const items = [...previousValue[1].items, currentValue];
					return [previousValue[0], { ...previousValue[1], items }];
				}
			},
			[
				{ category: translate.byKey('delete'), items: [] },
				{ category: translate.byKey('update'), items: [] },
			]
		);

	const columns = [
		{
			title: translate.byKey('name'),
			dataIndex: 'name',
			render: (value) => getReadableName(value),
			...(!site?.siteId
				? {
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: createSorter('name'),
						defaultSortOrder: 'ascend',
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: pageFilters.name ? [pageFilters.name] : null,
						filterIcon: <SearchFilterIcon />,
					}
				: []),
		},
		{
			title: translate.byKey('serial_number_label'),
			dataIndex: ['serialNumber'],
			...(!site?.siteId
				? {
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: pageFilters.serialNumber ? [pageFilters.serialNumber] : null,
						filterIcon: <SearchFilterIcon />,
					}
				: []),
		},
		{
			title: translate.byKey('mac_address_label'),
			dataIndex: ['macAddress'],
			...(!site?.siteId
				? {
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: pageFilters.macAddress ? [pageFilters.macAddress] : null,
						filterIcon: <SearchFilterIcon />,
					}
				: []),
		},
		{
			title: translate.byKey('type_label_v2'),
			dataIndex: 'type',
			render: (controllerType) => translate.byKey(getDeviceTypeTranslationKey(controllerType)),
			...(!site?.siteId
				? {
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: ({ type: controllerTypeA }, { type: controllerTypeB }) => {
							const controllerTypeALabel = translate.byKey(getDeviceTypeTranslationKey(controllerTypeA));
							const controllerTypeBLabel = translate.byKey(getDeviceTypeTranslationKey(controllerTypeB));

							return controllerTypeALabel.toLocaleLowerCase().localeCompare(controllerTypeBLabel.toLocaleLowerCase());
						},
						filterDropdown: (props) => (
							<SingleChoiceFilter antdFilterProps={props} options={deviceTypeFilters} showSearch={false} />
						),
						filteredValue: pageFilters.deviceTypes?.length ? pageFilters.deviceTypes : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => deviceTypeFilters?.find((x) => x.value === value)?.label ?? value,
						}),
					}
				: []),
		},
		{
			title: translate.byKey('door_status_header_v2'),
			dataIndex: ['deviceStatus'],
			render: (text) => (text ? translate.byKey(`${text.toLocaleLowerCase()}_upper_case`) : ''),
			...(!site?.siteId
				? {
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: createSorter('deviceStatus'),
						filterDropdown: (props) => (
							<MultipleChoiceFilter
								antdFilterProps={props}
								options={doorStatusFilterOptions}
								shouldSort={false}
								showSearch={false}
							/>
						),
						filteredValue: pageFilters.deviceStatus ? pageFilters.deviceStatus : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => doorStatusFilterOptions?.find((x) => x.value === value)?.label ?? value,
						}),
					}
				: []),
		},
		{
			title: translate.byKey('sync_status_v2'),
			dataIndex: ['syncState'],
			render: (text) => (text ? translate.byKey(`${text.toLocaleLowerCase()}_upper_case`) : ''),
			...(!site?.siteId
				? {
						sortDirections: ['descend', 'ascend', 'descend'],
						filterDropdown: (props) => (
							<CategorizedMultipleChoiceFilter
								antdFilterProps={props}
								options={syncStatusFilterOptions}
								showSearch={false}
							/>
						),
						filteredValue: pageFilters.syncState ? pageFilters.syncState : null,
						enabledFilterProps: (value) => ({
							getValueLabel: () => syncStatusFilterOptions?.find((x) => x.value === value)?.label ?? value,
						}),
					}
				: []),
		},
		...(!site?.siteId
			? [
					{
						title: translate.byKey('site'),
						dataIndex: ['siteName'],
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: createSorter('siteName'),
						filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
						filteredValue: pageFilters.siteName ? [pageFilters.siteName] : null,
						filterIcon: <SearchFilterIcon />,
					},
				]
			: []),
		{
			title: translate.byKey('firmware_version'),
			dataIndex: ['configuration', 'additionalSettings', 'firmwareVersion'],
		},
	];

	return (
		<Page>
			<PageHeader title={translate.byKey('connected_devices')} icon="controller">
				<ReloadButton onReload={() => onControllerUpdated(true)} />

				{!isLoading && site?.siteId && !isInEmergencyMode ? (
					<TypedAddButton
						hasControllerDependency={false}
						hasSiteDependency={true}
						screen={userConstants.screens.CONTROLLER_ONBOARD}
						onAddNewClick={onAddControllerClick}
						items={[
							{ key: 'improx', label: translate.byKey('cluster_controller_v2') },
							{
								key: 'passthrough',
								label: translate.byKey('updater_controller_v2'),
							},
						]}
					/>
				) : (
					''
				)}
			</PageHeader>
			<TableWithFiltersSection
				loading={{
					spinning: isLoading,
					indicator: <Spin active={true} />,
				}}
				onChange={tableChanged}
				scroll={getScrollHeightOptions()}
				columns={columns}
				dataSource={filteredData}
				rowKey="controllerId"
				onRow={(record) => ({
					onClick: () => onRowClick(record),
				})}
				pagination={
					!site?.siteId
						? {
								showTotal: (total, range) =>
									translate.byKeyFormatted('results_counter_label', [range[0], range[1], total]),
								position: ['bottomCenter'],
								showSizeChanger: true,
								pageSize: pageSize,
								current: currentPage,
							}
						: false
				}
				size="large"
				css={headerCellStyles(site?.siteId)}
			/>

			<ControllerForm
				visible={visible}
				hideModal={() => setVisible(false)}
				selected={selected}
				onUpdated={onControllerUpdated}
				controllerType={controllerType}
			/>
		</Page>
	);
};

export default Controllers;

const headerCellStyles = (isSiteLevel) =>
	css({
		'& thead.ant-table-thead th.ant-table-cell': {
			paddingBlock: isSiteLevel ? '16px' : 0,
		},
	});
