import React, { useMemo } from 'react';
import { Tooltip } from 'antd';

import { translator, useLocale } from '~/screens/_shared/AppLocale';
import {
	bodyColumnStyles,
	bodyRowStyles,
	dayStyles,
	hourStyles,
	userDataStyles,
	weeklyCalendarStyles,
} from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar.styles';

const daysInWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const roundToFullHour = (time) => {
	const [hourAsString, minuteAsString] = time.split(':');
	const hourAsNumber = Number.parseInt(hourAsString);
	const minuteAsNumber = Number.parseInt(minuteAsString);
	const newHourAsString = String(minuteAsNumber > 30 ? hourAsNumber + 1 : hourAsNumber).padStart(2, '0');

	return `${newHourAsString}:00`;
};

const isTimeRangeOverflow = (startTime, endTime) => {
	const [startTimeHourAsString] = startTime.split(':');
	const [endTimeHourAsString] = endTime.split(':');
	const startTimeHourAsNumber = Number.parseInt(startTimeHourAsString);
	const endTimeHourAsNumber = Number.parseInt(endTimeHourAsString);

	return endTimeHourAsNumber < startTimeHourAsNumber || startTimeHourAsNumber === endTimeHourAsNumber;
};

export const WeeklyCalendar = ({ periods, rowHeight = 24, hourStep = 4, showPeriodNumber = true }) => {
	const { translate } = useLocale();
	const bodyColumns = useMemo(
		() => daysInWeek.map((day) => <div key={day} data-type="bodyColumn" css={() => bodyColumnStyles({ day })} />),
		[]
	);
	const bodyRows = useMemo(() => {
		const bodyRowElements = [];

		for (let i = 0; i < 24 / hourStep; i++) {
			bodyRowElements.push(
				<div key={i} data-type="bodyRow" css={() => bodyRowStyles({ hourStep, currentRowIndex: i })} />
			);
		}

		return bodyRowElements;
	}, [hourStep]);
	const days = useMemo(
		() =>
			daysInWeek.map((day) => (
				<Tooltip key={day} title={translator.byKey(day)}>
					<div data-type="day" css={() => dayStyles({ rowHeight, day })} key={day}>
						{translator.byKey(day).charAt(0)}
					</div>
				</Tooltip>
			)),
		[rowHeight]
	);
	const hours = useMemo(() => {
		const hourElements = [];

		for (let i = 0; i <= 24; i = i + hourStep) {
			hourElements.push(
				<div key={i} data-type="hour" css={() => hourStyles({ hourStep, currentHour: i })}>
					<span>{String(i).padStart(2, '0')}:00</span>
				</div>
			);
		}

		return hourElements;
	}, [hourStep]);

	const userData = useMemo(
		() =>
			periods
				?.filter(({ times: [startTime, endTime] = [] } = {}) => Boolean(startTime) && Boolean(endTime))
				.map(({ days, times: [startTime, endTime] }, index) => {
					const daysTranslated = days.map((day) => translate.byKey(day).slice(0, 3).toUpperCase()).join(', ');

					return days.map((day) => {
						const roundedStartTime = roundToFullHour(startTime);
						const adjustedEndTime = endTime === '00:00' ? '24:00' : endTime;
						const roundedEndTime = roundToFullHour(adjustedEndTime);
						const gridRowStart = `hour-${roundedStartTime.replace(':', '')}`;
						const gridRowEnd = `hour-${roundedEndTime.replace(':', '')}`;
						const isNewDayOverflow = isTimeRangeOverflow(roundedStartTime, roundedEndTime);
						const dayOverflowGridRowStart = daysInWeek[daysInWeek.indexOf(day) + 1]
							? daysInWeek[daysInWeek.indexOf(day) + 1]
							: daysInWeek[0];

						return (
							<React.Fragment key={`${day}-${startTime}-${endTime}`}>
								<Tooltip title={`${daysTranslated} - ${startTime}-${endTime}`}>
									<div
										data-type="userData"
										css={() => userDataStyles({ index })}
										style={{
											gridColumn: day,
											gridRow: `${gridRowStart} / ${isNewDayOverflow ? 'hour-2400' : gridRowEnd}`,
										}}
									>
										{showPeriodNumber ? <div>{index + 1}</div> : null}
									</div>
								</Tooltip>
								{isNewDayOverflow ? (
									<Tooltip title={`${daysTranslated} - ${startTime}-${endTime}`}>
										<div
											data-type="userData"
											css={() => userDataStyles({ index })}
											style={{
												gridColumn: dayOverflowGridRowStart,
												gridRow: `hour-0000 / ${gridRowEnd}`,
											}}
										>
											{showPeriodNumber ? <div>{index + 1}</div> : null}
										</div>
									</Tooltip>
								) : null}
							</React.Fragment>
						);
					});
				}),
		[periods]
	);

	return (
		<div css={() => weeklyCalendarStyles({ rowHeight })}>
			{bodyColumns}
			{bodyRows}
			{days}
			{hours}
			{userData}
		</div>
	);
};
