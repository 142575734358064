import { Col, Form, Modal, Row } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Button, Input, Item, PasswordInput } from '~/components';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';
import { showErrorToast } from '~/screens/_shared/toast';
import { ResetPasswordModal } from '~/components/features/auth/ResetPasswordModal/ResetPasswordModal';
import {
	authenticate,
	confirmAuthentication,
	mutateUserAuthData,
	verifyAuthenticator,
} from '~/components/features/auth/hooks/useUserAuthData';
import { AuthenticatorAppModal } from '~/components/features/auth/AuthenticatorAppModal/AuthenticatorAppModal';
import { filterNumbersFromString } from '~/utils/filter';

export const LoginForm = () => {
	const [form] = Form.useForm();
	const theme = useTheme();
	const { translate } = useLocale();
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const [qrCode, setQrCode] = useState(null);
	const [sessionId, setSessionId] = useState(null);

	const closeForgotPasswordModal = () => {
		setShowForgotPasswordModal(false);
	};

	const closeAuthenticatorAppModal = () => {
		setQrCode(null);
	};

	const onFinish = async ({ email, password, code }) => {
		setIsAuthenticating(true);

		try {
			if (sessionId && code) {
				const userData = await confirmAuthentication({
					username: form.getFieldValue('email'),
					mfaCode: code,
					sessionId,
				});

				mutateUserAuthData({ ...userData, mfaEnabled: true });
			} else {
				const authData = {
					userName: email,
					credentials: {
						credential: password,
						credentialType: 'PASSWORD',
					},
				};

				const userData = await authenticate(authData);

				if (!userData.tokenResponseDto && userData.mfaSettings) {
					// initial QR setup
					setQrCode(userData.mfaSettings.qrCodeData);
				} else if (userData.tokenResponseDto?.sessionId) {
					// confirm MFA on login
					setSessionId(userData.tokenResponseDto.sessionId);
				} else {
					// normal flow
					mutateUserAuthData({ ...userData, mfaEnabled: false });
				}
			}

			setIsAuthenticating(false);
		} catch (e) {
			showErrorToast(translate.byKey('cannot_log_into_system_message'));
			setIsAuthenticating(false);
			console.error(e);
		}
	};

	const submitVerifyAuthenticator = async ({ code }) => {
		setIsAuthenticating(true);

		try {
			await verifyAuthenticator({
				mfaCode: code,
				qrData: qrCode,
			});

			setQrCode(null);

			form.submit();
		} catch (e) {
			Modal.error({
				title: translate.byKey('failed_to_register_authenticator_app'),
				content: translate.byKey('please_try_again_later'),
				destroyOnClose: true,
			});
		}

		setIsAuthenticating(false);
	};

	return (
		<>
			<Form name="normal_login" layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
				<Row gutter={[16, 16]} style={{ marginBottom: '24px', rowGap: '20px' }}>
					<Col span={24} style={sessionId ? { display: 'none' } : {}}>
						<Item
							required
							labelAlign="right"
							name="email"
							validateTrigger="onBlur"
							rules={[
								{ required: true, message: translate.byKey('email_is_required'), whitespace: true },
								{
									type: 'email',
									message: translate.byKey('email_format_not_valid'),
								},
							]}
						>
							<Input
								disabled={sessionId}
								prefix={<MailOutlined />}
								placeholder={translate.byKey('email_address')}
								type="email"
							/>
						</Item>
					</Col>
					<Col span={24} style={sessionId ? { display: 'none' } : {}}>
						<Item name="password" rules={[{ required: true, message: translate.byKey('password_is_required') }]}>
							<PasswordInput
								disabled={sessionId}
								prefix={<LockOutlined />}
								type="password"
								placeholder={translate.byKey('password')}
							/>
						</Item>
					</Col>
					{sessionId ? (
						<Col span={24}>
							<Item
								name="code"
								validateTrigger="onBlur"
								rules={[
									{
										required: true,
										message: translate.byKeyFormatted('field_is_required_message', {
											fieldName: translate.byKey('authenticator_app_code'),
										}),
									},
									{
										len: 6,
										message: translate.byKeyFormatted('field_value_must_be_long_message', {
											fieldName: translate.byKey('authenticator_app_code'),
											length: 6,
										}),
									},
								]}
								normalize={filterNumbersFromString}
							>
								<Input
									maxLength={6}
									autoComplete="one-time-code"
									placeholder={translate.byKey('authenticator_app_code')}
								/>
							</Item>
						</Col>
					) : null}
				</Row>

				<Form.Item>
					<Button
						loading={isAuthenticating}
						block
						type="primary"
						htmlType="submit"
						css={css`
							background-color: ${theme.colors.brand[600]} !important;
							border-color: ${theme.colors.brand[600]} !important;
						`}
					>
						{translate.byKey('log_in')}
					</Button>
					<a
						css={css`
							margin: 10px;
							float: right;
							color: ${theme.colors.brand[600]} !important;
						`}
						style={sessionId ? { display: 'none' } : {}}
						onClick={() => {
							setShowForgotPasswordModal(true);
						}}
						href="#reset_password"
					>
						{translate.byKey('forgot_password')}
					</a>
				</Form.Item>
			</Form>
			{showForgotPasswordModal ? <ResetPasswordModal onCancel={closeForgotPasswordModal} /> : null}
			{qrCode ? (
				<AuthenticatorAppModal
					qrCode={qrCode}
					submitVerifyAuthenticator={submitVerifyAuthenticator}
					onCancel={closeAuthenticatorAppModal}
					isAuthenticating={isAuthenticating}
				/>
			) : null}
		</>
	);
};
