import HelpViewNames from './HelpViewNames';
import * as Cards from '~/components/features/people/help/cards';
import * as People from '~/components/features/people/help';
import * as Controller from '~/screens/Controllers/help/edit';
import * as Controllers from '~/screens/Controllers/help';
import * as Readers from '~/screens/Doors/help/readers';
import * as Doors from '~/screens/Doors/help';
import * as AccessLogsReport from '~/screens/Reports/help';
import * as AuditLogsReport from '~/components/features/audit-transactions/help/auditTransactionsHelp';
import * as DeviceLogsReport from '~/screens/Reports/StatusTransactions/help';
import * as AccessGroups from '~/screens/AccessGroups/help';

export default function getHelpHtmlText(view, isForm) {
	if (view !== undefined) {
		switch (view) {
			case HelpViewNames.CARDS:
				return Cards.formHelp();
			case HelpViewNames.PEOPLE:
				if (isForm === true) {
					return People.formHelp();
				} else {
					return People.generalHelp();
				}
			case HelpViewNames.CONTROLLER:
				return Controller.formHelp();
			case HelpViewNames.CONTROLLERS:
				if (isForm === true) {
					return Controllers.formHelp();
				} else {
					return Controllers.generalHelp();
				}
			case HelpViewNames.READERS:
				return Readers.formHelp();
			case HelpViewNames.DOORS:
				if (isForm === true) {
					return Doors.formHelp();
				} else {
					return Doors.generalHelp();
				}
			case HelpViewNames.ACCESSREPORT:
				if (isForm === true) {
					return AccessLogsReport.formHelp(HelpViewNames.ACCESSREPORT);
				} else {
					return AccessLogsReport.generalHelp(HelpViewNames.ACCESSREPORT);
				}
			case HelpViewNames.AUDITREPORT:
				if (isForm === true) {
					return AuditLogsReport.formHelp(HelpViewNames.AUDITREPORT);
				} else {
					return AuditLogsReport.generalHelp(HelpViewNames.AUDITREPORT);
				}
			case HelpViewNames.STATUSREPORT:
				if (isForm === true) {
					return DeviceLogsReport.formHelp(HelpViewNames.STATUSREPORT);
				} else {
					return DeviceLogsReport.generalHelp(HelpViewNames.STATUSREPORT);
				}
			case HelpViewNames.ACCESSGROUPS:
				if (isForm === true) {
					return AccessGroups.formHelp();
				} else {
					return AccessGroups.generalHelp();
				}
			default:
				return '<div>No help to show for this view</div>';
		}
	}
	return '<div>No help to show for this view</div>';
}
