import React from 'react';
import { Space } from 'antd';

import { getLicensedCount } from '~/screens/_shared/featureLimits';
import { Button } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { USB_DEVICE_FORM_NAME } from '~/components/features/usb-devices/UsbDeviceForm/UsbDeviceForm';

export const EditUsbDeviceModalFooter = ({
	usbDeviceFeatureType,
	isSubmitDisabled,
	isSubmitting,
	isDeleting,
	onDeleteUsbDevice,
	onClose,
}) => {
	const { translate } = useLocale();

	return (
		<Space
			align="center"
			style={{
				width: '100%',
				justifyContent: 'space-between',
			}}
		>
			<div style={{ textAlign: 'left' }}>
				{usbDeviceFeatureType ? getLicensedCount(null, usbDeviceFeatureType) : null}
			</div>
			<div>
				<Button
					disabled={isSubmitting || isDeleting}
					onClick={onDeleteUsbDevice}
					loading={isDeleting}
					type="primary"
					danger
				>
					{translate.byKey('delete')}
				</Button>
				<Button disabled={isSubmitting || isDeleting} onClick={onClose}>
					{translate.byKey('cancel')}
				</Button>
				<Button
					htmlType="submit"
					type="primary"
					form={USB_DEVICE_FORM_NAME}
					disabled={isSubmitDisabled || isSubmitting || isDeleting}
					loading={isSubmitting}
				>
					{translate.byKey('update')}
				</Button>
			</div>
		</Space>
	);
};
