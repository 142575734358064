import { defaultValues } from '~/components/features/people/constants/defaultValues';
import { identifierFormat as formatConstants } from '~/components/features/people/constants/identifierFormat';
import cardIdentifierFormatToUseByBits from '~/services/WSEnrolmentService/cardIdentifierFormatToUseByBits';
import systemConfig from '~/screens/_shared/systemConfig';
import moment from 'moment';
import { getCurrentSystemSiteCacheData } from '../site-selection/hooks/useCurrentSystemSite';

const CredentialTypeId = {
	ONLINE_GENERIC: 'online_generic',
	OFFLINE_PULSE: 'offline_pulse',
};

const IdentifierFormat = {
	SODA_CARD_ID: 'SODA_CARD_ID',
	SODA_LITE_CARD_ID: 'SODA_LITE_CARD_ID',
	INCEDO_CARD_ID: 'INCEDO_CARD_ID',
};

export const isPulseKey = (credential) => credential?.name?.endsWith('(Pulse)');

export const isOfflineCredential = (identifierFormat) =>
	identifierFormat === IdentifierFormat.SODA_CARD_ID ||
	identifierFormat === IdentifierFormat.SODA_LITE_CARD_ID ||
	identifierFormat === IdentifierFormat.INCEDO_CARD_ID;

export const formatHexValues = (values, accessLog, credential) => {
	values = values || [];
	let value = values[0];

	if (value) {
		value = Object.values(value).join('');
	}

	if (credential && isPulseKey(credential) && !systemConfig.treatPulseAsDecimal) {
		return value || '';
	}

	if (isNaN(parseInt(value, 16))) {
		return value || ''; // probably string 'NA'
	} else {
		return value ? parseInt(value, 16) : '';
	}
};

export const formatHexValuesAsString = (values, accessLog, credential) =>
	String(formatHexValues(values, accessLog, credential) || '');

export const formatIdentifiers = (credential, pin, currentMainIdentifierFormat) => {
	let { hexValue, identifierFormat, credentialTypeId, isBlocked } = credential;
	const identifiers = [];

	if (!identifierFormat && credentialTypeId) {
		const identifierFormatType = cardIdentifierFormatToUseByBits();
		identifierFormat = identifierFormatType[0];
	}

	if (
		credentialTypeId !== CredentialTypeId.OFFLINE_PULSE ||
		(credentialTypeId === CredentialTypeId.OFFLINE_PULSE && systemConfig.treatPulseAsDecimal)
	) {
		hexValue = numberToFormatString(hexValue, 'hex');

		if (hexValue === 'NAN') {
			hexValue = '';
		}
	}

	if (hexValue && hexValue.length > 0) {
		identifiers.push({
			recognitionType: 'CREDENTIAL_NUMBER',
			identifierFormat,
			hexValue: hexValue,
			exemptedFromAuthentication: isBlocked,
		});

		let secondaryIdentifierFormat = IdentifierFormat.INCEDO_CARD_ID;

		if (credentialTypeId === CredentialTypeId.ONLINE_GENERIC) {
			secondaryIdentifierFormat = IdentifierFormat.SODA_CARD_ID;
		}

		if (credentialTypeId === CredentialTypeId.OFFLINE_PULSE && !systemConfig.useNewPulseFormat) {
			secondaryIdentifierFormat = IdentifierFormat.SODA_LITE_CARD_ID;
		}

		identifiers.push({
			recognitionType: 'CREDENTIAL_NUMBER',
			identifierFormat: currentMainIdentifierFormat || secondaryIdentifierFormat,
			hexValue: hexValue,
			exemptedFromAuthentication: false,
		});

		if (pin && pin.length > 0) {
			identifiers.push({
				recognitionType: 'PIN',
				identifierFormat: 'SIMPLE_NUMBER16',
				hexValue: pin,
			});
		}
	}
	return identifiers;
};

export const setupState = async (user, credentialHolder, credentials, getAccessGroups, getPin) => {
	const { site } = getCurrentSystemSiteCacheData();

	const getPinCodeFormatted = (value) => {
		if (value === undefined || value === '') {
			return '';
		}

		return numberToFormatString(value, 'int');
	};

	let { firstName, lastName, phone, email, userId, version: userVersion } = user;

	phone = phone || '';

	if (email && email.length > 0 && email === defaultValues.email) {
		email = '';
	}

	let {
		validity: { startDateTime, endDateTime },
		credentialHolderId,
		version: credentialHolderVersion,
	} = credentialHolder;

	let localPinCode = '';

	// Existing person, load their cards
	if (credentialHolderId) {
		const existingIdentifier = await getPin(credentialHolder, credentials);

		if (existingIdentifier) {
			localPinCode = existingIdentifier.hexValue;
		}
	}

	let accessProfiles = credentialHolder.accessProfiles || [];
	const onlineAccessGroups = await getAccessGroups('ONLINE', site);
	const offlineAccessGroups = await getAccessGroups('OFFLINE', site);

	const onlineAccessProfileId = onlineAccessGroups
		.flat()
		.map((group) => {
			return group.accessProfileId;
		})
		.filter((groupId) => {
			return accessProfiles.some((profile) => profile.accessProfileId === groupId) ? groupId : undefined;
		});

	const offlineAccessProfileId = offlineAccessGroups
		.flat()
		.map((group) => {
			return group.accessProfileId;
		})
		.filter((groupId) => {
			return accessProfiles.some((profile) => profile.accessProfileId === groupId) ? groupId : undefined;
		});

	accessProfiles = accessProfiles
		.filter((profile) => onlineAccessProfileId.includes((ident) => profile.accessProfileId !== ident))
		.filter((profile) => offlineAccessProfileId.includes((ident) => profile.accessProfileId !== ident));

	credentials = credentials.map((credential) => {
		credential.credentialIdentifiers = credential.credentialIdentifiers || [];
		const identifier = credential.credentialIdentifiers.find((ident) => ident.recognitionType === 'CREDENTIAL_NUMBER');

		let credentialTypeId = CredentialTypeId.ONLINE_GENERIC;
		let conversionType = '';

		//TODO when the new pulse keys have arrived, we will have to update the logic again.
		if (identifier && isPulseKey(credential)) {
			credentialTypeId = CredentialTypeId.OFFLINE_PULSE;
			if (systemConfig.treatPulseAsDecimal) {
				conversionType = 'int';
			}
		} else if (identifier && identifier.identifierFormat in formatConstants) {
			conversionType = formatConstants[identifier.identifierFormat].numeric ? 'int' : 'hex';
		}

		return {
			credentialId: credential.credentialId,
			credentialHolderId: credential.credentialHolder.credentialHolderId,
			name: credential.name,
			label: credential.label,
			version: credential.version,
			description: credential.description,
			isNewCredentialBlocked: credential.isBlocked,
			hexValue: conversionType ? numberToFormatString(identifier.hexValue, conversionType) : identifier.hexValue,
			credentialTypeId,
			isBlocked: credential.isBlocked,
		};
	});

	return {
		firstName: firstName,
		lastName: lastName,
		phone: phone,
		email: email,
		startDateTime: moment(startDateTime),
		endDateTime: moment(endDateTime),
		credentialHolderVersion,
		userVersion,
		credentialHolderId,
		userId,
		pin: getPinCodeFormatted(localPinCode),
		onlineAccessProfileId,
		offlineAccessProfileId,
		accessProfiles: accessProfiles,
		credentials: credentials || [],
		credentialsToDelete: [],
	};
};

export const isCredentialBitLengthValid = (value, allowSavingIfNullOrEmpty) => {
	// No card number defined. User possibly backspaced.
	if (value === undefined || value.length === 0) {
		return allowSavingIfNullOrEmpty; // No card number yet.
	}

	// Ensure that the values that are entered for CredentialNumber are numerical values only. As per this card: https://jira.assaabloy.net/browse/DASICP2-2194
	const isCredentialNumericValue = /^\d+$/;
	return isCredentialNumericValue.test(value);
};

/**
 * take a number and convert to binary or hex
 * example for binary: 100737258355327 - 48 bits
 * = 010110111001111010111000011111111111101001111111
 * javascript functions tend to chop off that first zero
 * which is wrong in terms of bit count
 * NOTE: do NOT try pass in an actual INT or string INT value and try convert it to INT.
 * @param {*} number
 * @param {*} convertTo - "int", "binary" or "hex"
 */
export const numberToFormatString = (number, convertTo) => {
	if (number === undefined || number === null) {
		return undefined;
	}

	if (convertTo && (convertTo === 'binary' || convertTo === 'hex' || convertTo === 'int')) {
		// (2 to the power 53 - 1) = 9007199254740991, this is considered a safe number. if this is a string number we must convert first
		if (!Number.isSafeInteger(number)) {
			// could be hex, check if we converting to int
			// (isHexadecimal is not fool proof, a number can also seem to be hex, onus is on the coder to make sure conversion to int is always from hex value)
			if (convertTo === 'int') {
				// is this actually hex? You better be sure now...no backsies
				return parseInt(Number('0x' + number, 10)).toString();
			}

			if (!String(number).split('').map(Number).some(isNaN)) {
				number = parseInt(number);
			}
		}

		if (convertTo === 'binary') {
			// this may not be the best logic for this, but because simple conversions trim the leading zeros,
			// we try convert this based on the premise that each byte has 8 bits per byte,
			// so we keep chopping 8 zeros at a time.
			const negative = number < 0;
			const twosComplement = negative ? Number.MAX_SAFE_INTEGER + number + 1 : number;
			const signExtend = negative ? '1' : '0';
			let firstBinary = twosComplement.toString(2).padStart(37, '0').padStart(48, signExtend);
			// if there is 8 leading zeros, thats 1 byte we dont need
			while (firstBinary.substring(0, 8) === '00000000') {
				firstBinary = firstBinary.substring(8, firstBinary.length);
			}
			return firstBinary;
		} else if (convertTo === 'hex') {
			return number.toString(16).toUpperCase(); // "ff" (radix 16, i.e. hexadecimal)
		}
	}
	// not sure what you are trying to do so...here...here's your number thrown back at you as string...how you like 'em apples?
	return number.toString();
};
