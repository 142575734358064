import { translator as translate } from '~/screens/_shared/AppLocale';

const formHelp = () => `
<p><strong>${translate.byKey('general')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('displays_controller_name_serial_number_and_mac_address')} </p>
<p><strong> ${translate.byKey('close')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('the_close_button_closes_the_dialog_window')} </p>
<p><strong> ${translate.byKey('door_status_header_v2')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('controller_connection_status_v2')} </p>
<p><strong> ${translate.byKey('sync_status_v2')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('controller_data_status_v2')} </p>
<p><strong> ${translate.byKey('serial_number_label')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'controller_serial_number_is_required_for_on_boarding_the_controller_v2'
)} </p>
<p><strong> ${translate.byKey('mac_address_label')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'controller_mac_address_is_required_for_on_boarding_the_controller_v2'
)} </p>
<p><strong> ${translate.byKey('readers')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'fixed_addresses_connected_to_controller_are_displayed_after_on_boarding_the_controller_v2'
)} </p>
<p>&nbsp;</p>
`;

export default formHelp;
