import { useRef } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useAuditTransactions = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId/system/:systemId/audit-logs',
				method: method.post,
				removeDomainKey: true,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , pageSize, pageNumber, action, startTimeAsIsoString, endTimeAsIsoString] = key.split(separator);

		return ref.current.fetchData(
			omitBy(
				{
					pageSize: parseInt(pageSize),
					pageNumber: parseInt(pageNumber),
					httpMethod: action,
					startTime: moment(startTimeAsIsoString),
					endTime: moment(endTimeAsIsoString),
					__contentType: 'application/vnd.hidglobal.ca.audit-report-1.0+json',
				},
				isExcludedValue
			)
		);
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAuditTransactionsKey = ({ customerId, systemId, pageSize, pageNumber, action, startTime, endTime }) =>
	`${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${action}${separator}\
${startTime}${separator}\
${endTime}${separator}\
auditTransactions`;
