import { translator as translate } from '~/screens/_shared/AppLocale';

export const formHelp = () => `
<p><strong> ${translate.byKey('general')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('only_3_cards_per_person_is_allowed')} </p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_edit_a_card_click_on_the_edit_icon_pencil_this_will_bring_to_view_the_editable_fields'
)} </p>
<p style="padding-left: 30px;"> ${translate.byKey('card_number_is_a_required_field')} </p>
<p><strong> ${translate.byKey('edit_suspend_delete')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('to_edit_suspend_delete_a_card')} </p>
<p><strong> ${translate.byKey('card_number')}  </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('type_credential_number_or_present_card_to_desktop_reader')} </p>
<p><strong> ${translate.byKey('suspend')}  </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('card_is_suspended_will_be_blocked_from_use')} </p>
<p><strong> ${translate.byKey('add_card')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'click_add_card_if_it_is_available_to_add_a_new_card_to_the_system_first_step_is_to_select_credential_type_selecting_any_will_automatically_select_the_closest_credential_type_when_a_value_is_typed_in_the_card_number_field'
)}</p>
<p><strong> ${translate.byKey('enrol_card')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'click_enrol_card_if_an_omnikey_plugin_is_available_to_read_the_card_and_set_type_and_card_number'
)} </p>
<p><strong> ${translate.byKey('delete')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_delete_a_card_click_the_edit_icon_pencil_then_in_the_card_edit_form_click_the_delete_icon_minus_and_then_click_delete_button_to_confirm_this_will_delete_the_card_from_the_person_clicking_cancel_button_closes_the_delete_dialog_and_exits_the_delete_function'
)} </p>
<p><strong> ${translate.byKey('cancel')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'cancel_button_closes_the_card_window_and_refreshes_cards_no_changes_are_saved'
)}</p>
<p><strong> ${translate.byKey('submit')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'save_button_close_the_card_window_and_refreshes_cards_saving_any_changes_made_to_property_fields'
)} </p>
<p><strong> ${translate.byKey('close')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'close_button_will_close_the_entire_card_form_and_return_you_to_the_person_list_view'
)} </p>`;
