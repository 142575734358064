import React, { useContext } from 'react';

import { Flex, Box, Text } from '~/components';
import { useDrop } from 'react-dnd';

import ItemTypes from './ItemTypes';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTheme } from 'emotion-theming';
import { SchedulePickerContext } from '.';
import { css } from '@emotion/core';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const NewTimeSlot = ({ slots, dispatch }) => {
	const theme = useTheme();
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const { timeSlotHeaderHeight, height, timeSlotSpacing } = useContext(SchedulePickerContext);
	const colors = [theme.colors.green, theme.colors.blue, theme.colors.orange, theme.colors.purple];
	let slotIndex = slots.length - 1;

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ItemTypes.DayColumn,
		drop: ({ slotId, day, dayIndex }, monitor) => {
			if (hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.CREATE) === true) {
				dispatch({
					type: 'ADD_NEW_SLOT',
					payload: {
						slot: { slotId, day, dayIndex },
					},
				});
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop:
				monitor.canDrop() &&
				hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.CREATE) === true,
		}),
	});

	const isVisible = canDrop;
	const isActive =
		isVisible &&
		isOver &&
		hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.CREATE) === true;

	return (
		<Flex
			data-test="time-selector-new-time-slot"
			css={css`
				opacity: 1;
				${!isVisible ? 'width: 0; opacity: 0' : ''}
				position: relative;
				margin-left: ${timeSlotSpacing}px;
			`}
			flexDirection={'column'}
			grow
			height={height}
			borderColor={isVisible ? theme.colors.green[200] : theme.colors.gray[200]}
			background={isActive && colors[slotIndex] ? colors[slotIndex][50] : theme.colors.white}
			zIndex={18}
			ref={drop}
		>
			{isVisible && (
				<React.Fragment>
					<Flex
						center
						height={`${timeSlotHeaderHeight}px`}
						borderTop={`4px solid ${colors[slotIndex] ? colors[slotIndex][400] : theme.colors.gray[200]}`}
						background={colors[slotIndex] ? colors[slotIndex][50] : theme.colors.white}
					>
						<Text color="gray.400" fontSize={12}>
							{translate.byKey('add_new')}
						</Text>
					</Flex>
					<Flex grow center>
						<Box>
							<PlusCircleOutlined
								css={css`
									font-size: 36px;
									color: ${theme.colors.gray[400]};
								`}
							/>
						</Box>
					</Flex>
				</React.Fragment>
			)}

			{isActive && (
				<Box
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						opacity: 0.4,
						background: colors[slotIndex] ? colors[slotIndex][50] : theme.colors.white,
						zIndex: 50,
					}}
				/>
			)}
		</Flex>
	);
};

export default NewTimeSlot;
