import React, { useState } from 'react';
import { HolidaysTable } from '~/components/features/holidays/HolidaysTable/HolidaysTable';
import { EditHolidayModal } from '~/components/features/holidays/EditHolidayModal/EditHolidayModal';
import { useHolidays } from '~/hooks/features/holidays/useHolidays';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';

export const HolidaysPageBody = () => {
	const { data, isLoading, isValidating, mutate } = useHolidays();
	const { data: user } = useUserAuthData();

	const [selectedHoliday, setSelectedHoliday] = useState();
	const [isHolidayFormOpen, setIsHolidayFormOpen] = useState(false);

	const hasDeletePermission = hasActionPermissions(user, userConstants.screens.HOLIDAYS, userConstants.actions.DELETE);
	const hasEditPermission = hasActionPermissions(user, userConstants.screens.HOLIDAYS, userConstants.actions.WRITE);
	const hasAnyPermissions = hasDeletePermission || hasEditPermission;

	const onRowClick = hasAnyPermissions
		? (record) => {
				setIsHolidayFormOpen(true);
				setSelectedHoliday(record);
		  }
		: undefined;

	const onEditClose = () => {
		setSelectedHoliday();
		setIsHolidayFormOpen(false);
	};

	const onDelete = () => {
		onEditClose();
		mutate();
	};

	const onUpdate = () => {
		onEditClose();
		mutate();
	};

	return (
		<React.Fragment>
			<HolidaysTable data={data} isLoading={isLoading || isValidating} onRowClick={onRowClick} />
			<EditHolidayModal
				open={isHolidayFormOpen}
				selected={selectedHoliday}
				onClose={onEditClose}
				onDelete={onDelete}
				onUpdate={onUpdate}
			/>
		</React.Fragment>
	);
};
