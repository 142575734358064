import React from 'react';
import { Flex } from '~/components';
import { TreeSelect } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { Global, css } from '@emotion/core';
import { useTreeSelectorData } from './hooks/useTreeSelectorData';
import { useCurrentSystemSite } from './hooks/useCurrentSystemSite';

export const SiteSelection = () => {
	const { translate } = useLocale();
	const {
		data: { system: currentSystem, site: currentSite },
		mutate: mutateCurrentSystemSite,
	} = useCurrentSystemSite();
	const { data: customerTreeData } = useTreeSelectorData();

	const treeSelectorData = customerTreeData.length === 1 ? customerTreeData[0].children : customerTreeData;

	const getCurrentTreeValue = () => {
		if (currentSite?.siteId) {
			return currentSite.siteId;
		}

		if (currentSystem?.systemId) {
			return currentSystem.systemId;
		}

		return undefined;
	};

	const onSystemSiteSelect = (value) => {
		const selectedSubtreeObject = findSubtreeObjectById(customerTreeData, value);

		if (!selectedSubtreeObject) {
			return;
		}

		const isSameSystem = currentSystem.systemId === selectedSubtreeObject.systemId;
		const isSameSite = currentSite.siteId === selectedSubtreeObject.siteId;

		if (isSameSystem && isSameSite) {
			return;
		}

		sessionStorage.setItem('currentSystemId', selectedSubtreeObject.systemId);

		const selectedSystem = isSameSystem
			? currentSystem
			: findSubtreeObjectById(customerTreeData, selectedSubtreeObject.systemId);

		if (selectedSubtreeObject.siteId) {
			sessionStorage.setItem('currentSiteId', selectedSubtreeObject.siteId);
			mutateCurrentSystemSite({ system: selectedSystem, site: selectedSubtreeObject });
		} else {
			sessionStorage.removeItem('currentSiteId');
			mutateCurrentSystemSite({ system: selectedSystem, site: { name: '', siteId: '' } });
		}
	};

	return (
		<Flex width="300px" center px="16px">
			<TreeSelect
				data-test="site-selection-dropdown"
				style={{ width: '100%' }}
				value={getCurrentTreeValue()}
				treeData={treeSelectorData}
				treeDefaultExpandAll
				onChange={onSystemSiteSelect}
				virtual={false}
				popupClassName="site-selection-dropdown-popup"
				css={treeSelectSelectorBeforeStyles(translate, Boolean(currentSite?.siteId))}
			/>
			<Global styles={treeSelectSelectableGlobalStyles(treeSelectorData.some((item) => item.selectable === false))} />
		</Flex>
	);
};

const findSubtreeObjectById = (tree, id) => {
	for (const item of tree) {
		if (item.id === id) {
			return item;
		}
		if (item.systemsAndSites) {
			const found = findSubtreeObjectById(item.systemsAndSites, id);
			if (found) {
				return found;
			}
		}
		if (item.sites) {
			const found = findSubtreeObjectById(item.sites, id);
			if (found) {
				return found;
			}
		}
	}
	return null;
};

const treeSelectSelectorBeforeStyles = (translate, isSiteLevel) =>
	css({
		'.ant-select-selection-item::before': isSiteLevel ? {} : { content: `"${translate.byKey('all_sites_label')}: "` },
	});

// Passing React nodes as labels is currently throwing errors
// Logic: If you are owner first level groups should not be bold, all of the other groups are bold
const treeSelectSelectableGlobalStyles = (hasNonSelectableParentGroup) =>
	hasNonSelectableParentGroup
		? css({
				'.site-selection-dropdown-popup .ant-select-tree-treenode:has(.ant-select-tree-indent-unit):has(.ant-select-tree-switcher_open), .site-selection-dropdown-popup .ant-select-tree-treenode:has(.ant-select-tree-indent-unit):has(.ant-select-tree-switcher_close)':
					{
						fontWeight: 'bold',
					},
			})
		: css({
				'.site-selection-dropdown-popup .ant-select-tree-treenode:has(.ant-select-tree-switcher_open), .site-selection-dropdown-popup .ant-select-tree-treenode:has(.ant-select-tree-switcher_close)':
					{
						fontWeight: 'bold',
					},
			});
