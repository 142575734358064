import moment from 'moment';
import { useListApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import { getDoorTypeFromConfig, isOffline, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import { getAssetsForPortal } from '~/screens/Doors/hooks/createOrUpdateDefaultAccessGroup';

export const getAllPortalAssetIds = (portal) => {
	const results = [].concat(portal.allParentAssetIds || []);
	if (portal.doorAssetId) {
		results.push(portal.doorAssetId);
	}

	if (portal.portalId) {
		results.push(portal.portalId);
	}

	return results;
};

export const addOnlinePortalAssetsToSet = (allAssets, portal) => {
	if (portal.doorAssetId) {
		allAssets.add(portal.doorAssetId);
	}
	return allAssets;
};

export const addOfflinePortalAssetsToSet = (allAssets, portal) => {
	const assetIds = getAllPortalAssetIds(portal);
	for (const id of assetIds) {
		allAssets.add(id);
	}
	return allAssets;
};

export const useGetEnrichedPortal = () => {
	const [getAccessPoints] = useListApi(mappers.accessPoint);
	const [getPortals] = useListApi(mappers.portal);
	const [getAssets] = useListApi(mappers.asset);
	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);

	return [
		async () => {
			let { portals = [] } = await getPortals({ params: { 'detail-level': 'FULL', 'page-size': 1000 } });

			const cache = {
				shared: true,
			};

			if (portals.some((portal) => !!portal.configuration?.additionalSettings?.doorAssetId)) {
				portals = portals.map((portal) => {
					if (portal.configuration?.additionalSettings?.doorAssetId) {
						portal.doorAssetId = portal.configuration.additionalSettings.doorAssetId;
					}

					portal.allParentAssetIds = [];
					if (portal.configuration?.additionalSettings?.zoneId) {
						portal.allParentAssetIds.push(portal.configuration.additionalSettings.zoneId);
					}

					if (portal.configuration?.additionalSettings?.zoneAssetId) {
						portal.allParentAssetIds.push(portal.configuration.additionalSettings.zoneAssetId);
					}

					if (portal.configuration?.additionalSettings?.additionalParents) {
						try {
							const parents = JSON.parse(portal.configuration.additionalSettings.additionalParents) || [];

							if (Array.isArray(parents)) {
								portal.allParentAssetIds = portal.allParentAssetIds.concat(
									parents
										.map((parent) => parent.key || parent)
										.filter((parent) => !!parent.assetId)
										.map((parent) => parent.assetId)
								);
							}
						} catch {
							//ignore
						}
					}

					return portal;
				});
			}

			if (portals.some((portal) => !!portal.configuration?.additionalSettings?.doorType)) {
				portals = portals.map((portal) => {
					if (portal.configuration?.additionalSettings?.doorType) {
						portal.doorType = portal.configuration.additionalSettings.doorType;
					}
					return portal;
				});
			}

			if (portals.some((portal) => (portal.allParentAssetIds || []).length === 0 || !portal.doorAssetId)) {
				const pendingAssets = [];

				pendingAssets.push(
					getAssets(
						{
							params: { 'detail-level': 'FULL', 'page-size': 1000, type: 'BASIC_ASSET' },
						},
						cache
					)
				);
				pendingAssets.push(
					getAssets(
						{
							params: { 'detail-level': 'FULL', 'page-size': 50, type: 'ACCESS_AREA' },
						},
						cache
					)
				);

				const [basicAssets, accessAreas] = await Promise.all(pendingAssets);

				const allAssets = (basicAssets.assets || []).concat(accessAreas.assets || []);

				portals = await Promise.all(
					portals.map(async (portal) => {
						portal.allParentAssetIds = portal.allParentAssetIds || [];
						if (!portal.doorAssetId) {
							const { assets } = await getAssetsForPortal(getAccessPoints, portal.portalId, {
								shared: true,
								expiry: moment().add(30, 'seconds'),
							});

							const asset = assets.pop();

							if (asset) {
								portal.doorAssetId = asset.assetId;
							}
						}

						if (portal.allParentAssetIds.length === 0) {
							let asset = allAssets.find((asset) => asset.assetId === portal.doorAssetId);

							if (asset) {
								const mainParent = asset.mainParent;
								const additionalParents = asset.additionalParents;

								if (mainParent) {
									portal.allParentAssetIds.push(mainParent.assetId);
								}

								if (Array.isArray(additionalParents)) {
									portal.allParentAssetIds = portal.allParentAssetIds.concat(
										additionalParents.map((parent) => parent.assetId)
									);
								}
							}
						}
						return portal;
					})
				);
			}

			if (portals.some((portal) => !portal.doorType)) {
				portals = await Promise.all(
					portals.map(async (portal) => {
						if (!portal.doorType) {
							const readers = await getAssignedReaders(
								{
									params: { assignedToPortal: 'true', 'detail-level': 'FULL' },
								},
								{ expiry: moment().add(1, 'minutes'), shared: true }
							);

							portal.doorType = readers
								.filter((r) => r.mapping?.portal?.portalId === portal.portalId)
								.map((reader) => getDoorTypeFromConfig(reader.deviceInfo))[0];
						}

						return portal;
					})
				);
			}

			portals = portals.filter((portal) => !!portal.doorAssetId);

			return {
				onlinePortals: portals.filter((portal) => isOnline(portal.doorType)),
				offlinePortals: portals.filter((portal) => isOffline(portal.doorType)),
			};
		},
	];
};
