import styled from '@emotion/styled';
import { Tag, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

const { CheckableTag } = Tag;

let StyledItemCheckableTag = styled(CheckableTag)`
	width: 156px;
	text-align: center;
	margin: 0.1px;
	padding: 0;
	font-size: 9.5px;
	border-width: 0.5px;
	border-style: solid;
	border-color: lightgrey;
	cursor: pointer;
	${layout}
	${space}
${flexbox}
${size}
${background}
${grid}
${color}
${typography}
${border}
${position}
${shadow}
`;

/**
 * pass in an array of items to display and dynamically create checkable tags
 * based on the styling 3 per row will show, long text will be truncated if
 * longer than 26 chars, tooltips will show full text after 1 second hover
 * @param {*} props
 */
const MultiItemPicker = (props) => {
	const { items, onChange } = props;
	const theme = useTheme();

	const [fields, setFields] = useState(items);

	const onItemChange = (item, checked) => {
		if (onChange) {
			onChange(item, checked);
		} else {
			fields[item.index].enabled = !item.enabled;
		}
		setFields(
			fields.map((field, i) => {
				return {
					id: field.id,
					index: i,
					name: field.name,
					data: field,
					enabled: i === item.index ? checked : field.enabled,
					visible: field.visible,
					lockType: field.lockType,
				};
			})
		);
	};

	// door/asset names can get really long, truncate them, on hover will show all
	const truncateAndCheckNull = useCallback((str, truncate) => {
		if (str && str.length > 0) {
			if (truncate === true) {
				return str.length > 26 ? str.substring(0, 23) + '...' : str;
			} else {
				return str;
			}
		} else {
			return '';
		}
	});

	return (
		<div
			css={css`
				.ant-tag-checkable-checked {
					background-color: ${theme.colors.brand[500]} !important;
				}
				.ant-tag-checkable {
					background-color: transparent;
				}
			`}
		>
			{fields &&
				fields.length > 0 &&
				fields.map((item, index) => {
					return (
						<Tooltip key={index} placement="topLeft" title={truncateAndCheckNull(item.name, false)} mouseEnterDelay="1">
							{item.visible && (
								<StyledItemCheckableTag
									{...props}
									key={index}
									checked={item.enabled}
									onChange={(checked) => onItemChange(item, checked)}
								>
									{truncateAndCheckNull(item.name, true)}
								</StyledItemCheckableTag>
							)}
						</Tooltip>
					);
				})}
		</div>
	);
};

export default MultiItemPicker;
