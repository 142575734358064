import React from 'react';
import { Form, Input, Modal } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { filterNumbersFromString } from '~/utils/filter';

const { Item } = Form;

export const AuthenticatorAppModal = ({ qrCode, submitVerifyAuthenticator, isAuthenticating, onCancel }) => {
	const [form] = Form.useForm();
	const { translate } = useLocale();

	const onSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				submitVerifyAuthenticator(values);
			})
			.catch(() => {});
	};

	return (
		<Modal
			open
			width={380}
			title={translate.byKey('authenticator_app_registration')}
			onOk={onSubmit}
			okButtonProps={{ disabled: isAuthenticating, loading: isAuthenticating }}
			onCancel={onCancel}
			cancelButtonProps={{ disabled: isAuthenticating }}
		>
			<QRCodeSVG value={qrCode} width="100%" />

			<Form form={form} layout="vertical" onFinish={onSubmit} style={{ marginTop: '16px' }}>
				<Item
					label={translate.byKey('authenticator_app_code')}
					name="code"
					rules={[
						{
							required: true,
							message: translate.byKeyFormatted('field_is_required_message', {
								fieldName: translate.byKey('authenticator_app_code'),
							}),
						},
						{
							len: 6,
							message: translate.byKeyFormatted('field_value_must_be_long_message', {
								fieldName: translate.byKey('authenticator_app_code'),
								length: 6,
							}),
						},
					]}
					normalize={filterNumbersFromString}
				>
					<Input maxLength={6} autoComplete="one-time-code" />
				</Item>
			</Form>
		</Modal>
	);
};
