const schedule = {
	base: {
		description: false,
		scheduleStates: true,
		defaultState: true,
		timeIntervals: true,
		name: true,
		doa: 'nostru',
		cupidatatf: -54466837.45338916,
		et54: 'true',
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'calendar', ':calendarId:', 'schedule'],
};

export default schedule;
