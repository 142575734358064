import React, { useEffect, useState } from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useDoors, getDoorsKey } from '~/hooks/data/doors/useDoors';
import { useSiteControllers, getSiteControllersKey } from '~/hooks/data/controllers/useSiteControllers';
import { useReaders } from '~/hooks/data/readers/useReaders';
import { getReadersForDoorsDefaultKey } from '~/components/features/doors/Doors.utils';
import { UnlockOutlined, LockOutlined, UndoOutlined } from '@ant-design/icons';
import { hasAssignedReadersCheck } from '~/screens/Doors/shared';
import { isControllerOnboarded } from '~/screens/Controllers';
import { css } from '@emotion/core';
import { useSiteControllersEmergencyRecords } from '~/screens/_shared/emergency/SiteControllersEmergencyRecordsProvider';
import { useSiteEmergencyActions } from '~/hooks/features/emergency';
import { ControllerLockState } from '~/constants/ControllerEmergencyStatus';
import { Button, Space } from 'antd';
import { useCurrentSystemSite } from '../site-selection/hooks/useCurrentSystemSite';
import { getDeviceStatusKey, useDeviceStatus } from '~/hooks/data/device-status/useDeviceStatus';
import { ControllerProtocols } from '~/constants/ControllerProtocols';
import { ControllerTypes } from '~/constants/ControllerTypes';

const filterOutM90 = (controller) => {
	return controller.protocol !== ControllerProtocols.PASSTHROUGH;
};

export const EmergencyLockdownButtons = () => {
	const { translate } = useLocale();

	const {
		data: {
			system: { customerId, systemId },
			site,
		},
	} = useCurrentSystemSite();

	const [hasOnboardedControllers, setHasOnboardedControllers] = useState(false);
	const [hasAssingedReaders, setAssignedHasReaders] = useState(false);

	const doorsKey =
		customerId && systemId && site?.siteId
			? getDoorsKey({ customerId, systemId, siteId: site?.siteId, pageSize: 1000 })
			: null;
	const { data: allDoors } = useDoors(doorsKey);

	const assignedReadersKey =
		customerId && systemId && site?.siteId
			? getReadersForDoorsDefaultKey({ customerId, systemId, siteId: site?.siteId })
			: null;
	const { data: assignedReaders } = useReaders(assignedReadersKey);

	const deviceStatusesKey =
		customerId && systemId && site?.siteId ? getDeviceStatusKey({ customerId, systemId, siteId: site?.siteId }) : null;
	const { data: deviceStatuses } = useDeviceStatus(deviceStatusesKey);

	const siteControllersKey =
		customerId && systemId && site?.siteId
			? getSiteControllersKey({
					systemId,
					customerId,
					siteId: site?.siteId,
					detailLevel: 'FULL',
				})
			: null;
	const { data: controllers } = useSiteControllers(siteControllersKey);

	const { areControllersInEmergencyState, isInEmergencyMode } = useSiteControllersEmergencyRecords();
	const { lock, reset, unlock } = useSiteEmergencyActions();

	useEffect(() => {
		setHasOnboardedControllers(controllers?.filter(filterOutM90)?.some(isControllerOnboarded));
	}, [controllers]);

	useEffect(() => {
		setAssignedHasReaders(allDoors?.portals?.some((portal) => hasAssignedReadersCheck(assignedReaders, portal)));
	}, [allDoors?.portals, assignedReaders]);

	if (!hasOnboardedControllers) {
		return null;
	}

	if (isInEmergencyMode || areControllersInEmergencyState(ControllerLockState.ResetEmergencyPending)) {
		return (
			<Button
				loading={areControllersInEmergencyState(ControllerLockState.ResetEmergencyPending)}
				icon={<UndoOutlined rotate={90} />}
				css={revertToNormalButtonStyle}
				onClick={() => reset(site.siteId)}
			>
				{translate.byKey('revert_to_normal_state')}
			</Button>
		);
	}

	const onlineDevice = deviceStatuses?.deviceStatuses?.find?.(
		(device) =>
			(device?.connectionStatus === 'CONNECTED' &&
				device?.deviceDetails?.deviceType === ControllerTypes.ACCESS_CONTROL_UNIT) ||
			device?.deviceDetails?.deviceType === ControllerTypes.CONTROLLER_UPDATER
	);
	const onlineController = controllers?.find((controller) => controller.controllerId === onlineDevice?.deviceId);

	return (
		<Space size="small">
			<Button
				disabled={
					areControllersInEmergencyState(ControllerLockState.EmergencyLockPending) ||
					!hasAssingedReaders ||
					!Boolean(onlineController)
				}
				loading={areControllersInEmergencyState(ControllerLockState.EmergencyUnlockPending)}
				icon={<UnlockOutlined />}
				css={emergencyButtonStyles('#cc3232')}
				onClick={() => unlock(site.siteId)}
			>
				{translate.byKey('emergency')}
			</Button>
			<Button
				disabled={
					areControllersInEmergencyState(ControllerLockState.EmergencyUnlockPending) ||
					!hasAssingedReaders ||
					!Boolean(onlineController)
				}
				loading={areControllersInEmergencyState(ControllerLockState.EmergencyLockPending)}
				icon={<LockOutlined />}
				css={emergencyButtonStyles('#db7b2b')}
				onClick={() => lock(site.siteId)}
			>
				{translate.byKey('lockdown')}
			</Button>
		</Space>
	);
};

const revertToNormalButtonStyle = css({
	backgroundColor: 'gray',
	color: 'white',
});

const emergencyButtonStyles = (backgroundColor) =>
	css({
		width: '10em',
		color: 'white !important',
		backgroundColor: `${backgroundColor}`,
		'&:disabled': {
			backgroundColor: 'darkgrey',

			'&:hover': {
				backgroundColor: `darkgrey !important`,
			},
		},
		'&:hover, &:focus': {
			backgroundColor: `${backgroundColor} !important`,
			opacity: 0.8,
		},
	});
