export const doorTypeNumberOfReaders = {
	hid_online: 2,
	aperio_online: 1,
	aperio_offline: 1,
	pulse_offline: 1,
};

export const doorTypeReaderDefaults = {
	hid_online: {
		vendor: 'HID',
		context: 'ONLINE',
		configurationProtocol: 'HID_READER',
	},
	aperio_online: {
		vendor: 'ASSA',
		context: 'ONLINE',
		configurationProtocol: 'APERIO_LOCK',
	},
	aperio_offline: {
		vendor: 'ASSA',
		context: 'OFFLINE',
		configurationProtocol: 'APERIO_LOCK',
	},
	pulse_offline: {
		vendor: 'ASSA',
		context: 'OFFLINE',
		configurationProtocol: 'ABLOY_LOCK',
	},
};

export const doorTypeLogicalDeviceDefaults = {
	hid_online: 'RECOGNITION',
	aperio_online: 'RECOGNITION',
	aperio_offline: 'LOCK',
	pulse_offline: 'LOCK',
};

export const doorTypeDisplayNames = {
	aperio_online: 'aperio_online_display_name',
	hid_online: 'hid_online_display_name',
	pulse_offline: 'pulse_offline_display_name',
	aperio_offline: 'aperio_offline_display_name',
};

export const doorTypeCreateTitles = {
	hid_online: 'add_hid_online_title_v2',
	aperio_online: 'add_aperio_online_title_v2',
	aperio_offline: 'add_aperio_offline_title_v2',
	pulse_offline: 'add_pulse_offline_title_v2',
};

export function isOffline(doorType) {
	return doorType === 'aperio_offline' || doorType === 'pulse_offline';
}

export function isOnline(doorType) {
	return doorType === 'hid_online' || doorType === 'aperio_online';
}

export function isAperioDoor(doorType) {
	return doorType === 'aperio_online' || doorType === 'aperio_offline';
}

export function isPulseDoor(doorType) {
	return doorType === 'pulse_offline';
}

export function getDoorTypeFromConfig(config) {
	config = config || {};

	for (const [key, value] of Object.entries(doorTypeReaderDefaults)) {
		if (value.context === config.context && value.configurationProtocol === config.configurationProtocol) {
			return key;
		}
	}
	return 'hid_online';
}

export function getDoorTypeDisplayName(doorType) {
	return doorTypeDisplayNames[doorType] || doorTypeDisplayNames['hid_online'];
}

export const hasReadersAssigned = (door) => {
	return (
		(door.doorType.toLocaleLowerCase() === 'hid_online' && !!door.assignedReaders?.length) ||
		(door.doorType.toLocaleLowerCase() === 'aperio_online' && !!door.assignedAperioReaders?.length)
	);
};
