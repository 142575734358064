import React, { useState } from 'react';
import { Divider } from 'antd';

import { SearchFilterPanel } from '~/components/shared/filters/panels/SearchFilterPanel/SearchFilterPanel';
import { ResetApplyFilterPanel } from '~/components/shared/filters/panels/ResetApplyFilterPanel/ResetApplyFilterPanel';

export const SearchFilter = ({ antdFilterProps, placeholder, maxLength, numbersOnly }) => {
	const { setSelectedKeys, selectedKeys, confirm, clearFilters, visible } = antdFilterProps;
	const [searchValue, setSearchValue] = useState(selectedKeys[0] || '');
	const resetDisabled = !Boolean(selectedKeys.length);
	const applyDisabled = searchValue === selectedKeys[0] || (!Boolean(searchValue) && !Boolean(selectedKeys.length));

	const onSearch = (event) => {
		const value = numbersOnly ? event.target.value.replace(/\D/g, '') : event.target.value;

		setSearchValue(value);
	};

	const onSearchClear = () => {
		setSearchValue('');
	};

	const onReset = () => {
		clearFilters({ confirm: true, closeDropdown: true });
	};

	const onApply = () => {
		setSelectedKeys([searchValue]);
		confirm();
	};

	const onPressEnter = () => {
		if (!applyDisabled) {
			onApply();
		}
	};

	React.useEffect(() => {
		if (searchValue !== selectedKeys[0]) {
			setSearchValue(selectedKeys[0]);
		}
	}, [visible]);

	return (
		<div style={{ minWidth: '200px' }}>
			<SearchFilterPanel
				value={searchValue}
				placeholder={placeholder}
				maxLength={maxLength}
				onSearch={onSearch}
				onClear={onSearchClear}
				onPressEnter={onPressEnter}
			/>

			<Divider style={{ margin: '0px' }} />

			<ResetApplyFilterPanel
				resetDisabled={resetDisabled}
				applyDisabled={applyDisabled}
				onReset={onReset}
				onApply={onApply}
			/>
		</div>
	);
};
