import { translator as translate } from '~/screens/_shared/AppLocale';

const formHelp = () => `
<p><strong>${translate.byKey('general', 'en')} </strong></p>
<p style="padding-left: 30px;">${translate.byKey('serial_number_and_mac_address_are_required_fields', 'en')} </p>
<p><strong>${translate.byKey('status', 'en')}: </strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'status_gives_an_indication_of_the_controllers_last_known_connection_state',
	'en'
)} </p>
<p><strong>${translate.byKey('sync_status', 'en')} <br /></strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'sync_status_gives_an_indication_of_the_last_sync_update_state_to_the_controller',
	'en'
)} </p>
<p><strong>${translate.byKey('delete', 'en')} </strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'delete_button_will_delete_the_controller_from_the_system_completely_be_careful_doing_this_as_it_could_leave_your_system_non_functional',
	'en'
)}</p>
<p><strong>${translate.byKey('close', 'en')} </strong></p>
<p style="padding-left: 30px;">${translate.byKey('the_close_button_closes_the_dialog_window', 'en')} </p>
<p><strong>${translate.byKey('sync_now', 'en')} <br /></strong></p>
<p style="padding-left: 30px;">${translate.byKey(
	'sync_now_button_will_force_an_update_of_all_doors_readers_access_groups_holidays_people_and_cards_down_to_the_controller',
	'en'
)} </p>
<p><strong>${translate.byKey('configure', 'en')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'configure_button_will_install_the_controller_onto_your_site',
	'en'
)} </p>
`;

export default formHelp;
