import { formatHexValuesAsString, isOfflineCredential, isPulseKey } from '~/components/features/people/People.utils';
import systemConfig from '~/screens/_shared/systemConfig';

export const convertCredentialHexToDecimal = (credential) => {
	const eventType =
		isPulseKey(credential) && !systemConfig.treatPulseAsDecimal ? 'OFFLINE_ACCESS_LOG' : 'ONLINE_ACCESS_LOG';
	const identifier = credential.credentialIdentifiers.find((credentialIdentifier) =>
		isOfflineCredential(credentialIdentifier.identifierFormat)
	);

	return formatHexValuesAsString([identifier?.hexValue], { eventType });
};

export const updateOnlineIdentifier = (credentialIdentifiers, isBlocked) =>
	credentialIdentifiers.map((identifier) => ({
		...identifier,
		exemptedFromAuthentication: isOfflineCredential(identifier.identifierFormat)
			? identifier.exemptedFromAuthentication
			: isBlocked,
	}));
