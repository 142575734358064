import { useState } from 'react';
import { usePutApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';

export const useUpdateCardFormatSettings = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [updateController] = usePutApi(mappers.controller);

	const updateCardFormatSettings = async (updatedController) => {
		setIsLoading(true);
		try {
			await updateController(updatedController);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return [updateCardFormatSettings, isLoading];
};
