import { css } from '@emotion/core';

export const weekPeriodPickerStyles = css({
	display: 'flex',
	alignItems: 'center',
	'.ant-picker': {
		width: '190px',
	},
});

export const tagsStyles = css({
	display: 'flex',
	height: '100%',
});

export const tagStyles = css({
	border: '1px solid #d9d9d9',
	paddingTop: '1px',
	paddingBottom: '1px',
});
