import React from 'react';
import { Col, Tooltip } from 'antd';
import { Divider, Flex, Tag, Text } from '~/components';
import { translator } from '~/screens/_shared/AppLocale';
import { getDefaultLanguageCode } from '~/services/translator/defaultLanguageCode';

const StatusControllerGrid = (props) => {
	const currentLanguage = getDefaultLanguageCode();
	let syncTagStyle = {};
	let syncStatusText = props.getSyncStatusTranslation();
	let statusText = '';

	if (currentLanguage !== 'en' && currentLanguage !== 'en-GB' && syncStatusText.length > 12) {
		syncTagStyle = { height: '40px', overflow: 'auto', overflowX: 'hidden', paddingRight: '2rem' };
		statusText = syncStatusText.substring(0, 10) + '...';
	} else {
		statusText = syncStatusText;
	}

	return (
		<React.Fragment>
			<Col md={12} style={{ borderRight: '1px solid #f0f0f0' }}>
				<Flex center>
					<Text mr="8px" color="gray.300">
						{translator.byKey('status')}:
					</Text>

					{props.onlineState && (
						<Tag
							style={{ height: syncTagStyle.height }}
							color={
								props.onlineState === props.controllerStates.UPDATED.value ||
								props.onlineState === props.controllerStates.CONNECTED.value
									? 'success'
									: 'error'
							}
						>
							<Tooltip title={translator.byKey((props.onlineState || '').toLocaleLowerCase() + '_upper_case')}>
								{props.isLoading
									? translator.byKey('loading')
									: translator.byKey((props.onlineState || '').toLocaleLowerCase() + '_upper_case')}
							</Tooltip>
						</Tag>
					)}
				</Flex>
			</Col>

			<Col md={12}>
				<Flex center>
					<React.Fragment>
						<Text mr="7px" color="gray.300">
							{translator.byKey('sync_status')}
						</Text>

						<Tag
							style={syncTagStyle}
							color={
								props.getCurrentControllerState(props.systemSyncState, true) ===
								props.controllerStates.UPDATE_FAILED.value
									? 'error'
									: 'processing'
							}
						>
							<Tooltip title={props.getSyncStatusTranslation()}>
								{props.isLoading ? translator.byKey('loading') : statusText}
							</Tooltip>
						</Tag>
					</React.Fragment>
				</Flex>
			</Col>

			<Divider />
		</React.Fragment>
	);
};

export default StatusControllerGrid;
