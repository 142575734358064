import { useState } from 'react';

import { useDeleteApi, useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import mappers from '~/screens/_shared/mappers';
import { useUpdateAdministrator } from '~/components/features/people/hooks/useUpdatePerson';
import { getCurrentAdministrator } from '~/screens/_shared/userRoleConstants';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const useDeletePerson = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [deleteUser] = useDeleteApi(mappers.user);
	const [deleteCredentialHolder] = useDeleteApi(mappers.credentialHolder);
	const [getCredentials] = useListApi(mappers.credential);
	const [deleteCredential] = useDeleteApi(mappers.credential);
	const [deleteAdministrator] = useDeleteApi('/system-services/administrator/:administratorId');
	const [updateAdministrator] = useUpdateAdministrator();
	const { translate } = useLocale();

	const {
		data: { system },
	} = useCurrentSystemSite();

	const deletePerson = async (personData) => {
		try {
			const currentAdmin = getCurrentAdministrator(system);

			setCurrentStep(translate.byKey('deleting_person'));
			setIsLoading(true);

			setCurrentStep(translate.byKey('fetching_credentials'));
			const { credentials } = await getCredentials({ params: { credential_holder_id: personData.credentialHolderId } });

			for (const credential of credentials) {
				setCurrentStep(translate.byKey('deleting_credential'));
				await deleteCredential({ credentialId: credential.credentialId });
			}

			setCurrentStep(translate.byKey('deleting_credential_holder'));
			await deleteCredentialHolder({ credentialHolderId: personData.credentialHolderId });

			setCurrentStep(translate.byKey('deleting_user'));
			await deleteUser({ userId: personData.userId });

			if (currentAdmin.roles.find((role) => role.type === 'SYSTEM_OWNER')) {
				await updateAdministrator({ ...personData, isAdministrator: false });

				if (personData.administratorId) {
					await deleteAdministrator({
						administratorId: personData.administratorId,
						ignoreGlobalHandlers: true,
					});
				}
			}
		} catch (e) {
			console.error(`Error executing deletePerson: ${e}`);
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	};

	return [deletePerson, isLoading, currentStep];
};
