import React from 'react';
import { Tag } from 'antd';
import { css } from '@emotion/core';

export const EnabledFilter = ({ children, closable = true, ...rest }) => (
	<Tag
		closable={closable}
		css={css({
			padding: '5px 10px',
			borderRadius: '16px',
		})}
		{...rest}
	>
		{children}
	</Tag>
);
