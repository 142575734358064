import { standardWeek } from '../entityConstants';
import { translator as translate } from '~/screens/_shared/AppLocale';
import { WeekTypes } from '~/constants/WeekTypes';

export function getInitialState() {
	return {
		timeSlots: [
			{
				id: 0,
				name: WeekTypes.WEEKDAY,
				modeTimeIntervals: [],
				days: [
					{
						id: 0,
						type: standardWeek.MONDAY,
						label: translate.byKey('monday'),
					},
					{
						id: 1,
						type: standardWeek.TUESDAY,
						label: translate.byKey('tuesday'),
					},
					{
						id: 2,
						type: standardWeek.WEDNESDAY,
						label: translate.byKey('wednesday'),
					},
					{
						id: 3,
						type: standardWeek.THURSDAY,
						label: translate.byKey('thursday'),
					},
					{
						id: 4,
						type: standardWeek.FRIDAY,
						label: translate.byKey('friday'),
					},
				],
			},
			{
				id: 1,
				name: WeekTypes.WEEKEND,
				modeTimeIntervals: [],
				days: [
					{
						id: 5,
						type: standardWeek.SATURDAY,
						label: translate.byKey('saturday'),
					},
					{
						id: 6,
						type: standardWeek.SUNDAY,
						label: translate.byKey('sunday'),
					},
				],
			},
			{
				id: 2,
				name: WeekTypes.HOLIDAY,
				modeTimeIntervals: [],
				days: [
					{
						id: 7,
						type: WeekTypes.HOLIDAY.toUpperCase(),
						label: translate.byKey('holiday'),
					},
				],
			},
		],
	};
}
