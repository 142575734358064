import { css, Global } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import React from 'react';

const GlobalStyle = () => {
	const theme = useTheme();
	return (
		<Global
			styles={css`
        @import url("https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap");

        *,
        *::after,
        *::before {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          min-width: 0;
        }

        @font-face {
          font-family: "AssaVesta";
          src: local("AssaVesta"),
            url(../assets/fonts/AAVESREG.woff) format("woff");
        }

        @font-face {
          font-family: "Verdana";
          src: local("Verdana"), url(../assets/fonts/Verdana.ttf) format("ttf");
        }

        *::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
          background-color: #edf2f7;
        }

        *::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          background-color: #ffffff;
        }

        *::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: #cbd5e0;
        }

        *:focus {
          outline: none;
        }

        html {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        body {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        html,
        body,
        #root {
          margin: 0;
        }

        #root {
          display: flex;
          flex: 1;
        }

        .spaces-fullpage-layout {
          display: flex;
        }

        .ant-breadcrumb {
          font-size: 15px;
          float: left;
        }

        .ant-breadcrumb ol li {
          display: flex;
          align-items: center;
        }

        .ant-breadcrumb-link {
          color: ${theme.colors.gray[600]};
          float: left;
        }

        .ant-breadcrumb-separator {
          color: ${theme.colors.gray[600]};
          float: left;
        }

        .ant-breadcrumb .anticon {
          font-size: 15px;
          float: left;
      }

      .ant-breadcrumb a span {
        float: left;
      }

        .ant-breadcrumb > span:last-child a {
          color: ${theme.colors.gray[600]};
          float: left;
        }

        .ant-breadcrumb {
          color: ${theme.colors.gray[600]};
          float: left;

          a {
            color: ${theme.colors.gray[600]};
            float: left;

            display: flex;
            align-items: center;
          }
        }

        .ant-popover-inner {
          color: ${theme.colors.gray[400]};
          border-radius: 4px;
        }

        .ant-popover-message {
          display: flex;
        }

        .ant-popover-message > .anticon {
          position: absolute;
          top: 3px;
          font-size: 15px;
        }

        .ant-popover-message-title {
          font-family: Verdana;
          font-size: 12px;
          color: ${theme.colors.gray[400]};
        }

        .ant-popover-buttons > button {
          border-radius: 4px;
          font-family: Verdana;
          font-size: 12px;
          color: ${theme.colors.gray[300]};
          border-color: ${theme.colors.gray[300]};

        }
        .ant-popover-buttons > .ant-btn-primary {
          background-color: ${theme.colors.brand[500]};
          color: ${theme.colors.white};
          border-color: ${theme.colors.brand[500]};

          &:hover {
            background-color: ${theme.colors.brand[400]};
          }
        }

        .ant-modal-content {
          border-radius: 10px;
        }
        .ant-modal-header {
          border-radius: 10px 10px 0 0;
        }
        .ant-modal-footer {
          padding: 16px;
        }

        .ant-message-warning .anticon{
            color: ${theme.colors.orange[400]};
        }

        .ant-message-success .anticon {
            color: ${theme.colors.green[400]};
        }

        .ant-message-error .anticon {
            color: ${theme.colors.red[500]};
          }
        }

        .ant-message-info .anticon, .ant-message-loading .anticon {
            color: ${theme.colors.blue[500]};
        }

        .ant-message-notice-content {
          border-radius: 0 4px 4px 0;
          height: 35px;
          vertical-align: middle;
          padding: 0;

          .ant-message-warning {
            border-left: 4px solid ${theme.colors.orange[400]};
          }

          .ant-message-info {
            border-left: 4px solid ${theme.colors.blue[500]};
          }

          .ant-message-success {
            border-left: 4px solid ${theme.colors.green[400]};
          }

          .ant-message-error {
            border-left: 4px solid ${theme.colors.red[500]};
          }

          .ant-message-loading {
            border-left: 4px solid ${theme.colors.blue[500]};
          }

          .ant-message-custom-content {
            display: flex;
            height: 35px;
            align-items: center;
            padding: 0 16px;

            .ant-message .anticon {
              display: flex;
            }
            .ant-message {
            }
            .anticon {
              position: relative;
              top: 0;
              font-size: 18px;
            }
          }

          .ReactVirtualized__Collection {
          }

          .ReactVirtualized__Collection__innerScrollContainer {
          }

          /* Grid default theme */

          .ReactVirtualized__Grid {
          }

          .ReactVirtualized__Grid__innerScrollContainer {
          }

          /* Table default theme */

          .ReactVirtualized__Table {
          }

          .ReactVirtualized__Table__Grid {
          }

          .ReactVirtualized__Table__headerRow {
            font-weight: 700;
            text-transform: uppercase;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
          }

          .ReactVirtualized__Table__row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
          }

          .ReactVirtualized__Table__headerTruncatedText {
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .ReactVirtualized__Table__headerColumn,
          .ReactVirtualized__Table__rowColumn {
            margin-right: 10px;
            min-width: 0px;
          }

          .ReactVirtualized__Table__rowColumn {
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .ReactVirtualized__Table__headerColumn:first-of-type,
          .ReactVirtualized__Table__rowColumn:first-of-type {
            margin-left: 10px;
          }

          .ReactVirtualized__Table__sortableHeaderColumn {
            cursor: pointer;
          }

          .ReactVirtualized__Table__sortableHeaderIconContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
          }

          .ReactVirtualized__Table__sortableHeaderIcon {
            -webkit-box-flex: 0;
                -ms-flex: 0 0 24px;
                    flex: 0 0 24px;
            height: 1em;
            width: 1em;
            fill: currentColor;
          }

          /* List default theme */

          .ReactVirtualized__List {
          }
      `}
		/>
	);
};

export default GlobalStyle;
