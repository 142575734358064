import { Col, Form, Row } from 'antd';
import random from 'lodash/random';
import React, { useState, useEffect, useRef } from 'react';
import { Spin, Checkbox, Input, Item, Select, Modal } from '~/components';
import { cardFormatData } from '~/components/features/card-format/CardFormatModal/CardFormatModal.utils';
import { controllerUpdateTypes } from '~/screens/Controllers/config';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUpdateCardFormatSettings } from '~/components/features/card-format/CardFormatModal/CardFormatModal.hooks';
import Button from '../../../forms/Button';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { numberToFormatString } from '~/components/features/people/People.utils';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getSiteControllersKey, useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const numbersOnlyRegex = /\D/g;
const FACILITY_CODE_MAX_DIGITS = 8;
const NO_FACILITY_CODE = 'FFFFFFFF';
const DEFAULT_CARD_FORMAT_KEY = 'H10302_37';

export const CardFormatModal = ({ onCancel }) => {
	const [form] = Form.useForm();
	const facilityCodeVal = Form.useWatch('facilityCode', form);
	const selectedFormat = Form.useWatch('cardFormat', form);
	const [isSaving, setIsSaving] = useState(false);
	const [isFacilityCodeCheckboxEnabled, setIsFacilityCodeCheckboxEnabled] = useState(false);
	const defaultEnableFacilityCodeRef = useRef(false);
	const defaultFacilityCodeRef = useRef('');
	const defaultFormatRef = useRef(DEFAULT_CARD_FORMAT_KEY);
	const [updateCardFormatSettings, updatingCardFormatSettings] = useUpdateCardFormatSettings();
	const { translate } = useLocale();
	const { data: userAuth } = useUserAuthData();
	const hasPermission = !hasActionPermissions(
		userAuth,
		userConstants.screens.CARD_FORMAT,
		userConstants.actions.CREATE
	);
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const siteControllersKey =
		customerId && systemId && siteId
			? getSiteControllersKey({
					customerId,
					systemId,
					siteId,
					detailLevel: 'FULL',
			  })
			: null;
	const {
		data: controllers,
		isLoading: isLoadingControllers,
		mutate: fetchUpdatedControllers,
		error: loadControllerError,
	} = useSiteControllers(siteControllersKey);

	const selectedFormatData = cardFormatData[selectedFormat];

	const onControllersLoaded = (controllers) => {
		const controllerData =
			controllers.find((item) => item.configuration?.additionalSettings?.wiegandName) || controllers[0];

		const { wiegandName, facilityCodeHex } = controllerData.configuration?.additionalSettings || {};

		// Has facility code
		const hasFacilityCode = facilityCodeHex?.length > 0 && facilityCodeHex !== NO_FACILITY_CODE;
		setIsFacilityCodeCheckboxEnabled(hasFacilityCode);
		defaultEnableFacilityCodeRef.current = hasFacilityCode;

		// Facility Code
		const tempFacilityCode = hasFacilityCode ? numberToFormatString(facilityCodeHex, 'int') : '';
		defaultFacilityCodeRef.current = tempFacilityCode;
		form.setFieldsValue({ facilityCode: tempFacilityCode });

		// Format
		const [savedFormat] = Object.entries(cardFormatData).find(([key, value]) => value.name === wiegandName) || [
			DEFAULT_CARD_FORMAT_KEY,
		];
		defaultFormatRef.current = savedFormat;
		form.setFieldsValue({ cardFormat: savedFormat });
	};

	const onUpdateController = async (controller) => {
		const { controllerId, configuration } = controller;
		const { macAddress, serialNumber } = configuration.additionalSettings;
		const nonce = random(5000);

		const wiegandName = selectedFormatData.name;
		const wiegandMask = selectedFormatData.mask;
		const wiegandBitsDecimal = selectedFormatData.bitsDecimal;

		const facilityCodeHex =
			selectedFormatData.hasFacilityCode && facilityCodeVal?.length > 0 && isFacilityCodeCheckboxEnabled
				? numberToFormatString(facilityCodeVal, 'hex')
				: NO_FACILITY_CODE;

		try {
			await updateCardFormatSettings({
				...controller,
				controllerId,
				macAddress,
				serialNumber,
				nonce,
				additionalSettings: {
					...controller.configuration.additionalSettings,
					requestType: controllerUpdateTypes.none.value, // Force it back to NONE. Not doing a Sync/AutoId/etc.
					FULL_SYNC: false, // Not doing a Full Sync.
					wiegandName,
					wiegandMask,
					wiegandBitsDecimal,
					facilityCodeHex,
				},
			});
		} catch (error) {
			console.error(error);
			onCloseModal();
			Modal.error({
				title: translate.byKey('card_format'),
				content: translate.byKey('failed_to_update_the_controller'),
			});
		}
	};

	const onEnableFacilityCodeChange = (event) => {
		setIsFacilityCodeCheckboxEnabled(event.target.checked);
	};

	const onFormatChange = () => {
		setIsFacilityCodeCheckboxEnabled(false);
		form.setFieldsValue({ facilityCode: '' });
	};

	const onSave = async () => {
		try {
			await form.validateFields();
		} catch (error) {
			return;
		}

		if (selectedFormatData?.mask !== undefined) {
			setIsSaving(true);
			try {
				await Promise.allSettled(
					controllers
						.filter((controller) => controller?.controllerId)
						.map((controller) => onUpdateController(controller))
				);
			} catch (error) {
				console.error('Error updating controllers:', error);
			} finally {
				setIsSaving(false);
				fetchUpdatedControllers();
			}
		}

		onCloseModal();
	};

	const onCloseModal = () => {
		onCancel();
	};

	useEffect(() => {
		if (controllers?.length) {
			onControllersLoaded(controllers);
		} else if (loadControllerError || (!isLoadingControllers && !controllers?.length)) {
			onCloseModal();
			Modal.error({
				title: translate.byKey('controller_connect_fail_title'),
				content: translate.byKey('unable_to_load_controller_record'),
			});
		}
	}, [isLoadingControllers, loadControllerError, controllers]);

	const isSelectionSame = isFacilityCodeCheckboxEnabled
		? defaultFormatRef.current === selectedFormat &&
		  defaultFacilityCodeRef.current === facilityCodeVal &&
		  defaultEnableFacilityCodeRef.current === isFacilityCodeCheckboxEnabled
		: defaultFormatRef.current === selectedFormat &&
		  defaultEnableFacilityCodeRef.current === isFacilityCodeCheckboxEnabled;

	const isSaveDisabled =
		isLoadingControllers ||
		!selectedFormatData ||
		isSaving ||
		isSelectionSame ||
		(isFacilityCodeCheckboxEnabled && !facilityCodeVal?.length);

	const footer = [
		<>
			<Button disabled={isLoadingControllers || isSaving} onClick={onCloseModal}>
				{translate.byKey('cancel')}
			</Button>

			{hasActionPermissions(userAuth, userConstants.screens.DOORS, userConstants.actions.CREATE) && (
				<Button type="primary" disabled={isSaveDisabled} loading={isSaving} onClick={onSave}>
					{translate.byKey('save')}
				</Button>
			)}
		</>,
	];

	return (
		<Modal width={300} open title={translate.byKey('card_format')} footer={footer} onCancel={onCloseModal}>
			<Spin active={isLoadingControllers || isSaving || updatingCardFormatSettings}>
				<Form layout="vertical" size={'medium'} name="card-format-form" form={form} scrollToFirstError>
					<Row gutter={[12, 12]}>
						<Col md={24}>
							<Item name="cardFormat" label={translate.byKey('card_format_selection')}>
								<Select
									disabled={hasPermission}
									onChange={onFormatChange}
									options={Object.entries(cardFormatData).map(([prop, val]) => ({ value: prop, label: val.text }))}
								/>
							</Item>
						</Col>

						{selectedFormatData?.hasFacilityCode ? (
							<Col md={24}>
								<Checkbox
									disabled={hasPermission}
									name="enableFacilityCode"
									checked={isFacilityCodeCheckboxEnabled}
									onChange={onEnableFacilityCodeChange}
								>
									{translate.byKey('enable_facility_code')}
								</Checkbox>
							</Col>
						) : null}

						{isFacilityCodeCheckboxEnabled && selectedFormatData?.hasFacilityCode ? (
							<Col md={24}>
								<Item
									name="facilityCode"
									label={translate.byKey('facility_code')}
									rules={[
										{
											required: true,
											whitespace: true,
											message: translate.byKeyFormatted('field_digits_count_message', {
												fieldName: translate.byKey('facility_code'),
												count: FACILITY_CODE_MAX_DIGITS,
											}),
										},
										{
											max: FACILITY_CODE_MAX_DIGITS,
											message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
												fieldName: translate.byKey('facility_code'),
												maxLength: FACILITY_CODE_MAX_DIGITS,
											}),
										},
									]}
									normalize={(value) => value.replace(numbersOnlyRegex, '')}
								>
									<Input
										disabled={hasPermission}
										placeholder={translate.byKey('facility_code')}
										maxLength={FACILITY_CODE_MAX_DIGITS}
									/>
								</Item>
							</Col>
						) : null}
					</Row>
				</Form>
			</Spin>
		</Modal>
	);
};
