import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';

const componentStyle = StyleSheet.create({
	fontSize: '10px',
	padding: '4px 8px',
	display: 'table-cell',
	width: '100%',
	borderStyle: 'solid',
	borderTop: 1,
	borderLeft: 1,
	borderBottom: 0,
	borderRight: 0,
	borderColor: '#f0f0f0',
});

export const TableItem = ({ children, style, ...rest }) => {
	return (
		<Text
			style={{
				...componentStyle,
				...style,
			}}
			{...rest}
		>
			{children}
		</Text>
	);
};
