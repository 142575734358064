import styled from '@emotion/styled';
import { Button as AButton } from 'antd';
import React from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const StyledButton = styled(AButton)`
	appearance: none;
	color: ${(props) => props.theme.colors.white};
	border-radius: 4px;
	height: 34px;
	padding: 4px 15px;
	${(props) =>
		props.type === 'primary' &&
		`background-color: ${props.theme.colors.brand[500]}; border-color: ${props.theme.colors.brand[500]};`}

	${(props) => props.type !== 'primary' && `border-color: ${props.theme.colors.gray[200]};`}
  ${(props) => props.type !== 'primary' && `color: ${props.theme.colors.gray[400]};`}
  svg {
		vertical-align: inherit;
		font-size: 17px;
	}

	&:hover {
		${(props) =>
			props.type === 'primary'
				? `background-color: ${props.theme.colors.brand[400]}; border-color: ${props.theme.colors.brand[300]};`
				: `border-color: ${props.theme.colors.brand[500]};
        color: ${props.theme.colors.brand[500]};`}
	}

	&:active {
		${(props) =>
			props.type === 'primary'
				? `background-color: ${props.theme.colors.brand[400]}; border-color: ${props.theme.colors.brand[300]};`
				: `border-color: ${props.theme.colors.brand[500]};
        color: ${props.theme.colors.brand[500]};`}
	}

	&:focus {
		${(props) =>
			props.type === 'primary'
				? `background-color: ${props.theme.colors.brand[400]}; border-color: ${props.theme.colors.brand[300]};`
				: `border-color: ${props.theme.colors.brand[500]};
        color: ${props.theme.colors.brand[500]};`}
	}

	${layout}
	${space}
  ${flexbox}
  ${size}
  ${background}
  ${grid}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${shadow}
`;

// dropdown.tsx in Ant Design will clone the button,
// which then has a ref passed to it in rc-dropdown.
// Button can't take a ref because it is a standard functional component,
// so it needs to be wrapped with forward ref.
const Button = React.forwardRef((props, ref) => {
	const { isAddon, ...newProps } = props;
	return <StyledButton ref={ref} {...newProps} />;
});

export default Button;
