import React from 'react';
import { Form, Input, Select } from 'antd';
import { css } from '@emotion/core';

import { useLocale } from '~/screens/_shared/AppLocale';
import { locationField, nameField } from '~/components/features/access-profiles/modals/form/fields';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const BasicInfo = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { sites },
			site: selectedSite,
		},
	} = useCurrentSystemSite();

	return (
		<>
			<Form.Item
				name={nameField}
				label={translate.byKey('name')}
				rules={[
					{
						required: true,
						whitespace: true,
						message: translate.byKeyFormatted('field_is_required_message', {
							fieldName: translate.byKey('name'),
						}),
					},
				]}
				wrapperCol={{ span: 8 }}
			>
				<Input maxLength={50} placeholder={translate.byKey('name')} />
			</Form.Item>

			<Form.Item
				name={locationField}
				label={translate.byKey('location')}
				rules={[
					{
						required: true,
						message: translate.byKeyFormatted('field_is_required_message', {
							fieldName: translate.byKey('location'),
						}),
					},
				]}
				wrapperCol={{ span: 8 }}
			>
				<Select
					placeholder={translate.byKey('select_location')}
					css={locationSelectStyles}
					options={sites.map((site) => ({ value: site.id, label: site.name }))}
					disabled={Boolean(selectedSite?.id)}
				/>
			</Form.Item>
		</>
	);
};

const locationSelectStyles = css({ width: '100%' });
