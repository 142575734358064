import React from 'react';
import { Space } from 'antd';

import { Button } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { DOOR_GROUP_FORM_NAME } from '~/components/features/door-groups/DoorGroupForm/DoorGroupForm';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userRoleConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const EditDoorGroupModalFooter = ({
	isSubmitDisabled,
	isSubmitting,
	isDeleting,
	onDeleteDoorGroup,
	onClose,
}) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const userHasDeletePermission = hasActionPermissions(
		user,
		userRoleConstants.screens.ACCESS_GROUPS,
		userRoleConstants.actions.DELETE
	);
	const userHasUpdatePermission = hasActionPermissions(
		user,
		userRoleConstants.screens.ACCESS_GROUPS,
		userRoleConstants.actions.WRITE
	);

	return (
		<Space
			align="center"
			style={{
				width: '100%',
				justifyContent: 'flex-end',
			}}
		>
			<div>
				{userHasDeletePermission && (
					<Button
						disabled={isSubmitting || isDeleting}
						onClick={onDeleteDoorGroup}
						loading={isDeleting}
						type="primary"
						danger
					>
						{translate.byKey('delete')}
					</Button>
				)}
				<Button disabled={isSubmitting || isDeleting} onClick={onClose}>
					{translate.byKey('cancel')}
				</Button>
				{userHasUpdatePermission && (
					<Button
						htmlType="submit"
						type="primary"
						form={DOOR_GROUP_FORM_NAME}
						disabled={isSubmitDisabled || isSubmitting || isDeleting}
						loading={isSubmitting}
					>
						{translate.byKey('update')}
					</Button>
				)}
			</div>
		</Space>
	);
};
