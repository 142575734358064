const syncState = {
	base: {},
	update: {},
	params: {},
	baseUrlPath: [
		'customer',
		':customerId:',
		'system',
		':systemId:',
		'site',
		':siteId:',
		'controller',
		':controllerId:',
		'sync-state',
	],
};

export default syncState;
