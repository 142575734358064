import systemConfig from '~/screens/_shared/systemConfig';
import { getExpiresInOverride } from '~/screens/_shared/useApi/ApiRequester';
import moment from 'moment';
import useSWR from 'swr';
import { cache, mutate } from 'swr/_internal';

const fallbackData = {
	tokens: [],
};

const queryParams = systemConfig.emeaClientId
	? new URLSearchParams({
			'client-id': systemConfig.emeaClientId,
	  })
	: '';

const parseCustomerData = ({ username, administratorId, tokens }) => {
	const tokenCreationTime = moment();
	const expiresInOverride = getExpiresInOverride();

	return {
		username,
		administratorId,
		tokens: [
			{
				accessToken: tokens?.accessToken,
				expiresIn: expiresInOverride
					? expiresInOverride
					: tokens?.accessTokenAliveDuration
					  ? tokens.accessTokenAliveDuration * 1000
					  : 10 * 60 * 1000,
				refreshToken: tokens?.refreshToken,
				tokenCreationTime,
			},
		],
	};
};

export const authenticate = async (authData) => {
	const response = await fetch(`/authentication/authentication-request?${queryParams}`, {
		method: 'POST',
		body: JSON.stringify(authData),
		headers: {
			'Content-Type': 'application/vnd.assaabloy.msfss.authentication-7.0+json',
			'Application-ID': systemConfig.applicationId,
			'Application-Version': systemConfig.jiraVersion,
		},
	});

	if (!response.ok) {
		throw response.statusText;
	}

	const customerData = await response.json();

	if (!customerData.tokenResponseDto || customerData.tokenResponseDto.sessionId) {
		return customerData;
	}

	return parseCustomerData({
		username: authData.userName,
		administratorId: response.headers.get('Administratorid'),
		tokens: customerData.tokenResponseDto,
	});
};

export const confirmAuthentication = async (authData) => {
	const response = await fetch(`/authentication/authentication-request/confirm-authentication?${queryParams}`, {
		method: 'POST',
		body: JSON.stringify(authData),
		headers: {
			'Content-Type': 'application/vnd.assaabloy.msfss.authentication-7.0+json',
			'Application-ID': systemConfig.applicationId,
			'Application-Version': systemConfig.jiraVersion,
		},
	});

	if (!response.ok) {
		throw response.statusText;
	}

	const customerData = await response.json();

	return parseCustomerData({
		username: authData.username,
		administratorId: response.headers.get('Administratorid'),
		tokens: customerData.tokenResponseDto,
	});
};

export const verifyAuthenticator = async (verifyData) => {
	const response = await fetch(`/authentication/authenticator-app/verify?${queryParams}`, {
		method: 'POST',
		body: JSON.stringify(verifyData),
		headers: {
			'Content-Type': 'application/vnd.assaabloy.msfss.authentication-7.0+json',
			'Application-ID': systemConfig.applicationId,
			'Application-Version': systemConfig.jiraVersion,
		},
	});

	if (!response.ok) {
		throw response.statusText;
	}
};

export const mutateUserAuthData = (data) => {
	sessionStorage.setItem('persist:auth', JSON.stringify(data));
	mutate('/authentication/authentication-request', data);
};

export const logout = () => {
	sessionStorage.removeItem('persist:auth');
	sessionStorage.removeItem('currentSystemId');
	sessionStorage.removeItem('currentSiteId');
	mutate('/authentication/authentication-request', fallbackData);
};

export const getUserAuthCacheData = () => {
	const cachedData = cache.get('/authentication/authentication-request');
	const authStorage = sessionStorage.getItem('persist:auth');

	return cachedData?.data || (authStorage && JSON.parse(authStorage)) || fallbackData;
};

const fetcher = () => {
	const authStorage = sessionStorage.getItem('persist:auth');
	return authStorage ? JSON.parse(authStorage) : fallbackData;
};

export const useUserAuthData = () => {
	const authStorage = sessionStorage.getItem('persist:auth');

	return useSWR('/authentication/authentication-request', fetcher, {
		fallbackData: authStorage ? JSON.parse(authStorage) : fallbackData,
	});
};
