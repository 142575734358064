import React from 'react';

export const viewBox = '0 0 128 128';

const path = (
	<g fill="currentColor">
		<path d="M28.487 53.8H81.82a1.75 1.75 0 000-3.5h-3.5V24.05a1.75 1.75 0 00-1.75-1.75h-6.821v-6.051A1.75 1.75 0 0068 14.5h-8.566a1.75 1.75 0 00-1.75 1.75v21.084h-5.067V24.05a1.75 1.75 0 00-1.75-1.75H42.3a1.75 1.75 0 00-1.75 1.75v6.59h-6.815a1.751 1.751 0 00-1.75 1.75V50.3h-3.5a1.75 1.75 0 100 3.5zm46.328-28v24.5h-5.066V25.8zM61.184 18h5.065v32.3h-5.065zm-3.5 22.835V50.3h-5.067v-9.466zM44.052 25.8h5.065v24.5h-5.065zm-8.567 8.34h5.067V50.3h-5.067zM49.863 86.089H17.636a1.75 1.75 0 100 3.5h32.227a1.75 1.75 0 000-3.5zM49.86 95.563H17.633a1.75 1.75 0 100 3.5H49.86a1.75 1.75 0 000-3.5zM49.86 105.038H17.633a1.75 1.75 0 100 3.5H49.86a1.75 1.75 0 000-3.5zM49.863 67.141H17.636a1.75 1.75 0 100 3.5h32.227a1.75 1.75 0 000-3.5zM17.633 80.114H49.86a1.75 1.75 0 000-3.5H17.633a1.75 1.75 0 100 3.5z" />
		<path d="M121.741 114.458L109.9 102.612a1.751 1.751 0 00-2.475 0l-.922.922-.939-.94v-1.462a25.837 25.837 0 000-31.933V6.5a1.751 1.751 0 00-1.75-1.75H6.5A1.75 1.75 0 004.75 6.5v115a1.75 1.75 0 001.75 1.75h97.31a1.751 1.751 0 001.75-1.75v-8.657l8.9 8.9a5.15 5.15 0 107.283-7.283zm-36.576-6.874a22.419 22.419 0 1122.419-22.418 22.444 22.444 0 01-22.419 22.418zM8.25 119.75V8.25h93.81v57.284a25.919 25.919 0 100 39.263v14.953zm111.017-.484a1.653 1.653 0 01-2.334 0l-10.609-10.608 1.405-1.405.008-.007.007-.008.914-.914 10.609 10.608a1.65 1.65 0 010 2.333z" />
		<path d="M90.758 67.09a1.75 1.75 0 10-1.037 3.343 15.354 15.354 0 0110.863 14.733 1.75 1.75 0 003.5 0A18.838 18.838 0 0090.758 67.09z" />
	</g>
);

const noData = {
	path,
	viewBox,
};

export default noData;
