import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const useGenerateOtp = (key, config) => {
	const ref = useRef(
		new ApiRequester({
			entity: '/otp',
			method: method.post,
		})
	);
	const fetcher = async (_, { arg: { customerId, systemId } }) => {
		return ref.current.fetchData({
			params: {
				customerId,
				systemId,
			},
			ignoreGlobalHandlers: true,
			removeProperties: ['ignoreGlobalHandlers'],
		});
	};

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getGenerateOtpKey = ({ customerId, systemId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
otp`
		: null;
