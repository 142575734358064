import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const config = {
	revalidateOnFocus: false,
};

export const useCredentialUpdate = (key) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.credential,
				method: method.put,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => ref.current.fetchData({ ...arg });

	return useSWRMutation(key, fetcher, config);
};

export const getCredentialKey = ({ customerId, systemId, credentialId }) =>
	customerId && systemId ? `${customerId}-${systemId}-${credentialId}-credential` : null;
