import React from 'react';
import { Tooltip } from 'antd';

import { formatAssetName } from '~/utils/asset/asset';
import { translator as translate } from '~/screens/_shared/AppLocale';

export const SEPARATOR = '__';

const OfflineDoorsType = {
	APERIO_OFFLINE: 'aperio_offline',
	PULSE_OFFLINE: 'pulse_offline',
};

const isAssetDeleted = (asset) => {
	return asset.description === 'deleted';
};

const getDoorTypeForAsset = (assetId, portals) => {
	const result = portals?.find((portal) => portal.assetId === assetId);
	return result?.doorType;
};

const getDoorGroupsWithAssets = (assets, portals) => {
	const doorGroupsWithAssetsMap = new Map();
	const assetsIdsThatBelongToGroup = new Set();

	assets.forEach((asset) => {
		if (isAssetDeleted(asset)) return;

		if (asset.additionalParents) {
			assetsIdsThatBelongToGroup.add(asset.assetId);

			asset.additionalParents.forEach((group) => {
				if (!doorGroupsWithAssetsMap.has(group.assetId)) {
					doorGroupsWithAssetsMap.set(group.assetId, {
						name: group.name,
						type: group.type,
						assetId: group.assetId,
						doors: [
							{
								name: formatAssetName(asset.name),
								assetId: asset.assetId,
								doorType: getDoorTypeForAsset(asset.assetId, portals),
							},
						],
					});
				} else {
					doorGroupsWithAssetsMap.get(group.assetId).doors.push({
						name: formatAssetName(asset.name),
						assetId: asset.assetId,
						doorType: getDoorTypeForAsset(asset.assetId, portals),
					});
				}
			});
		}
	});

	return { doorGroupsWithAssetsMap, assetsIdsThatBelongToGroup };
};

const getUngroupedAssets = (portals, assetsIdsThatBelongToGroup) => {
	const ungroupedAssetsMap = new Map();

	if (portals) {
		portals.forEach((portal) => {
			const find = Array.from(assetsIdsThatBelongToGroup).find((assetId) => assetId === portal.assetId);

			if (!find) {
				ungroupedAssetsMap.set(portal.assetId, {
					name: formatAssetName(portal.name),
					doorType: portal.doorType,
					assetId: portal.assetId,
				});
			}
		});
	}

	return ungroupedAssetsMap;
};

export const getAssetsIdsForDoorGroup = (doorGroupId, assets, portals) => {
	const { doorGroupsWithAssetsMap } = getDoorGroupsWithAssets(assets, portals);

	const doorGroup = doorGroupsWithAssetsMap.get(doorGroupId);

	if (!doorGroup) return [];

	return doorGroup.doors.map((door) => door.assetId);
};

export const getAssetsForDoorGroup = (selectedDoorGroupId, assets, portals) => {
	const { doorGroupsWithAssetsMap } = getDoorGroupsWithAssets(assets, portals);
	const doorGroupAssets = doorGroupsWithAssetsMap.get(selectedDoorGroupId);
	if (!doorGroupAssets) return [];

	const transformedData = Array.from(doorGroupsWithAssetsMap).reduce((accumulator, [doorGroupId, asset]) => {
		doorGroupAssets.doors.forEach((door) => {
			const find = asset.doors.find(({ assetId }) => door.assetId === assetId);

			if (find) {
				accumulator.push(`${doorGroupId}${SEPARATOR}${door.assetId}`);
			}
		});

		return accumulator;
	}, []);

	return transformedData;
};

export const generateTreeSelectorData = (assets, portals) => {
	const { doorGroupsWithAssetsMap, assetsIdsThatBelongToGroup } = getDoorGroupsWithAssets(assets, portals);
	const ungroupedAssetsMap = getUngroupedAssets(portals, assetsIdsThatBelongToGroup);

	const groupedAssets = Array.from(doorGroupsWithAssetsMap).reduce((accumulator, [_, value]) => {
		accumulator.push({
			title: value.name,
			tag: value.name,
			value: value.assetId,
			key: value.assetId,
			type: value.type,
			children: value.doors
				.map((door) => ({
					title: (
						<Tooltip destroyTooltipOnHide title={translate.byKey(`${door.doorType}_label`)}>
							<span>{door.name}</span>
						</Tooltip>
					),
					tag: door.name,
					value: `${value.assetId}${SEPARATOR}${door.assetId}`,
					key: `${value.assetId}${SEPARATOR}${door.assetId}`,
				}))
				.sort((item1, item2) => item1.tag.localeCompare(item2.tag)),
		});
		return accumulator.sort((item1, item2) => item1.tag.localeCompare(item2.tag));
	}, []);

	const ungroupedAssets = Array.from(ungroupedAssetsMap).reduce((accumulator, [_, value]) => {
		accumulator.push({
			title: (
				<Tooltip destroyTooltipOnHide title={translate.byKey(`${value.doorType}_label`)}>
					<span>{value.name}</span>
				</Tooltip>
			),
			tag: value.name,
			value: value.assetId,
			key: value.assetId,
			doorType: value.doorType,
		});
		return accumulator;
	}, []);

	const data = [
		{
			title: translate.byKey('ungrouped_aperio_doors'),
			tag: 'Ungrouped Aperio Doors',
			value: 'UNGROUPED_APERIO',
			key: 'UNGROUPED_APERIO',
			checkable: false,
			selectable: false,
			children: ungroupedAssets
				.filter((door) => door.doorType === OfflineDoorsType.APERIO_OFFLINE)
				.sort((item1, item2) => item1.tag.localeCompare(item2.tag)),
		},
		{
			title: translate.byKey('ungrouped_pulse_doors'),
			tag: 'Ungrouped Pulse Doors',
			value: 'UNGROUPED_PULSE',
			key: 'UNGROUPED_PULSE',
			checkable: false,
			selectable: false,
			children: ungroupedAssets
				.filter((door) => door.doorType === OfflineDoorsType.PULSE_OFFLINE)
				.sort((item1, item2) => item1.tag.localeCompare(item2.tag)),
		},
		...groupedAssets,
	];

	return data;
};

// SELECTING/DESELECTING ASSETS
export const isAssetPartOfGroup = (asset) => {
	return asset.includes(SEPARATOR);
};

export const getAllSameGroupAssets = (selectedAssetId, ungroupedAssets) => {
	const [, selectedAssetAssetId] = selectedAssetId.split(SEPARATOR);

	const allChildren = ungroupedAssets.reduce((accumulator, currentValue) => {
		accumulator.push(...currentValue.children);
		return accumulator;
	}, []);

	const assetsToInclude = allChildren.reduce((accumulator, currentValue) => {
		const [, assetId] = currentValue.value.split(SEPARATOR);

		if (assetId === selectedAssetAssetId) {
			accumulator.push(currentValue.value);
		}
		return accumulator;
	}, []);

	return assetsToInclude;
};

// UNCHECKING ASSETS
export const isDoorGroupAssetUnchecked = (uncheckedAssetId) => {
	return uncheckedAssetId.includes(SEPARATOR);
};

export const isDoorGroupUnchecked = (uncheckedAssetId, selectedValues) => {
	const found = selectedValues.find((selectedValue) => {
		const doesItInclude = selectedValue.includes(uncheckedAssetId) && selectedValue.includes(SEPARATOR);
		return doesItInclude;
	});

	return found;
};

export const determineTypeOfUncheckedAsset = (uncheckedAssetId, selectedValues) => {
	if (isDoorGroupAssetUnchecked(uncheckedAssetId)) {
		return 'DOOR_GROUP_ASSET';
	}

	if (isDoorGroupUnchecked(uncheckedAssetId, selectedValues)) {
		return 'DOOR_GROUP';
	}

	return 'DOOR';
};

// REMOVING ASSETS
export const handleDoorGroupRemoval = (triggerValue, selectedAssets) => {
	const doorGroupAssets = selectedAssets.filter((asset) => {
		return asset.includes(triggerValue);
	});

	const doorGroupAssetsAssetIds = doorGroupAssets.map((asset) => {
		const [, assetId] = asset.split(SEPARATOR);
		return assetId;
	});

	const filteredAssets = selectedAssets.filter((asset) => {
		const [, assetId] = asset.split(SEPARATOR);

		if (assetId && doorGroupAssetsAssetIds.includes(assetId)) {
			return false;
		}

		return true;
	});

	return filteredAssets;
};

export const handleDoorGroupAssetRemoval = (triggerValue, selectedAssets) => {
	const [, assetId] = triggerValue.split(SEPARATOR);

	const filteredAssets = selectedAssets.filter((asset) => {
		return !asset.includes(assetId);
	});

	return filteredAssets;
};
