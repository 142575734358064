import { method } from './apiConstants';

import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import mappers from '~/screens/_shared/mappers';
import { getCurrentSystemSiteCacheData } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

function deleteSpecialParams(requestData) {
	delete requestData.__contentType;
	delete requestData.params;

	return requestData;
}

/**
 * Creates and returns an axios config object
 * @memberof apiService

 */
export function getRequestConfig(apiMethod, mapper, requestData, cancelToken) {
	const { page, page_size } = requestData;
	let additionalHeaders = {};

	let rawRequest = null;

	if ('__additionalHeaders' in requestData) {
		additionalHeaders = requestData.__additionalHeaders;
		delete requestData.__additionalHeaders;
	}

	if ('requiresUnformattedRequest' in requestData) {
		const requiresUnformattedRequest = requestData.requiresUnformattedRequest;
		delete requestData.requiresUnformattedRequest;
		if (requiresUnformattedRequest) {
			rawRequest = Object.assign({}, requestData);
		}
	}

	let returnData = {};

	try {
		returnData = getPayload(apiMethod, mapper, requestData);

		if (rawRequest) {
			returnData.body = rawRequest;
		}
	} catch (error) {
		throw error;
	}

	const { url, params, body } = returnData;

	/**
	 * The methods we use (such as create) are not actual REST methods.
	 * We update those appropriately (to get, post .etc)
	 */
	const RESTMethod = getRESTAlignedMethod(apiMethod);
	let parameters = false;
	if (page || page_size || params) {
		parameters = {
			...(page && { page }),
			...(page_size && { page_size }),
			...(params && { ...params }),
		};
	}

	const contentType = requestData.__contentType;
	deleteSpecialParams(requestData);

	return {
		method: RESTMethod,
		url: `${url}`,
		cancelToken: cancelToken.token,
		...(parameters && { params: parameters }),
		...(body && {
			body: requestData instanceof FormData ? requestData : getBody(url, body),
		}),
		headers: {
			'Content-Type': contentType || getContentType(url, apiMethod, requestData),
			Accept: '*/*',
			...additionalHeaders,
		},
	};
}

function getPayload(apiMethod, apiEntity, requestData) {
	const correctEntity = typeof apiEntity === 'string' && mappers[apiEntity] && !mappers[apiEntity].split;
	const correctMethod = typeof apiMethod === 'string' && has(method, apiMethod);
	const correctData = typeof requestData === 'object';
	let payloadId = null;

	if (correctMethod && correctEntity && correctData) {
		const mapper = mappers[apiEntity];
		payloadId = requestData[`${apiEntity}Id`];
		let { url, body, params } = _buildPayload(apiMethod, mapper, requestData, payloadId);
		return { url, body, params };
	} else if (correctMethod && correctData) {
		let mapper = apiEntity;
		let key = Object.entries(mappers).find(([, value]) => value === apiEntity);

		if (key) {
			payloadId = requestData[`${key[0]}Id`];
		}

		if (apiEntity.split) {
			const params = Object.assign({}, (requestData || {}).params || {});

			for (const key of Object.keys(params)) {
				params[key] = true;
			}

			const base = deleteSpecialParams(Object.assign({}, requestData || {}));

			for (const key of Object.keys(base)) {
				base[key] = true;
			}

			mapper = {
				base,
				update: {},
				params,
				baseUrlPath: apiEntity.split('/').filter((x) => x),
			};
		}

		let { url, body, params } = _buildPayload(apiMethod, mapper, requestData, payloadId);

		return { url, body, params };
	} else {
		throw new Error('Incorrect Data Passed to getPayload method');
	}
}

const apiMethods = {
	[method.post]: 'post',
	[method.put]: 'put',
	[method.list]: 'get',
	[method.post]: 'post',
	[method.post]: 'post',
};

function getRESTAlignedMethod(apiMethod) {
	if (apiMethod in apiMethods) {
		return apiMethods[apiMethod];
	}
	return apiMethod;
}

function getContentType(url, apiMethod, requestData) {
	if (requestData instanceof FormData) {
		return 'multipart/form-data';
	}

	let contentType = 'application/vnd.hidglobal.ca.connector-2.0+json';

	if (apiMethod === method.patch) {
		contentType = 'application/json-patch+json';
	}

	return contentType;
}

/**
 * Audit log report does not include the domainKey.
 * Return the appropriate body for the request type.
 * @param {*} url - temporary, should possibly have another apiMethod defined.
 * @param {*} body
 */

function getBody(url, body) {
	if (body && body.__arrayBody && Array.isArray(body.__arrayBody)) {
		return body.__arrayBody;
	}

	if (url) {
		if (url.includes('audit-logs') || body.removeDomainKey) {
			return { ...body };
		} else if (url.includes('login')) {
			// We have to format the data correctly for posting to the login endpoint
			return Object.entries(body)
				.map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
				.join('&');
		}
	}

	const { system } = getCurrentSystemSiteCacheData();
	return { ...body, domainKey: { domainId: system.systemId } };
}

function _buildPayload(apiMethod, mapper, requestData, payloadId) {
	requestData = requestData || {};

	const basePayloadProperties = _getPayloadProperties(mapper['base'], requestData, 'base', apiMethod);

	const updatePayloadProperties = _getPayloadProperties(mapper['update'], requestData, 'update', apiMethod);

	const paramsProperties = _getParamsProperties(mapper['params'], requestData, apiMethod);

	let rawBaseUrl = mapper['baseUrlPath'];

	if (rawBaseUrl && rawBaseUrl.apply) {
		rawBaseUrl = rawBaseUrl(basePayloadProperties);
	}

	if (!Array.isArray(rawBaseUrl) && rawBaseUrl?.split) {
		rawBaseUrl = rawBaseUrl.split('/').filter((x) => x);
	}

	const baseUrl = _buildBaseUrl(rawBaseUrl, requestData);

	return _buildPayloadFactoryObject(
		apiMethod,
		basePayloadProperties,
		updatePayloadProperties,
		payloadId,
		baseUrl,
		paramsProperties
	);
}

function _buildBaseUrl(baseUrlPathArray, requestData) {
	baseUrlPathArray = baseUrlPathArray || [];
	const { system, site } = getCurrentSystemSiteCacheData();
	const temp = { customerId: system.customerId, systemId: system.systemId, siteId: site.siteId };

	if (baseUrlPathArray.length === 0) {
		throw new Error(`Invalid URL Path Array for entity.`);
	}

	let baseUrl = baseUrlPathArray
		.map((value) => {
			if (value.startsWith(':')) {
				const key =
					value
						.split(':')
						.filter((value) => value)
						.shift() || '';

				if (key) {
					return `${requestData[key] || temp[key]}`;
				}
			}
			return value;
		})
		.join('/');

	if (requestData.params) {
		baseUrl += '?';

		baseUrl += Object.entries(requestData.params)
			.map(([key, param]) => `${encodeURIComponent(key)}=${encodeURIComponent(param)}`)
			.join('&');
	}
	return baseUrl;
}

function _buildPayloadFactoryObject(
	apiMethod,
	basePayloadProperties = null,
	updatePayloadProperties = null,
	id,
	baseUrl,
	additionalParams = null
) {
	let idString = '';
	let body = null;
	let params = null;

	switch (apiMethod) {
		case method.get:
		case method.delete:
			idString = id ? `/${id}` : '';
			break;
		case method.post:
			body = basePayloadProperties;
			break;
		case method.patch:
		case method.put:
			idString = id ? `/${id}` : '';
			body = {
				...basePayloadProperties,
				...updatePayloadProperties,
			};
			break;
		default:
			break;
	}

	if (additionalParams && !isEmpty(additionalParams)) {
		params = additionalParams;
	}

	let url = baseUrl + idString;

	return { url, body, params };
}

function _getPayloadProperties(payloadConfig, requestData, payloadType, apiMethod) {
	const { system, site } = getCurrentSystemSiteCacheData();
	let payloadProperties = {};

	for (const key in payloadConfig) {
		if (payloadConfig.hasOwnProperty(key)) {
			if (typeof payloadConfig[key] === 'boolean') {
				if (payloadConfig[key] === false && !(key in requestData)) {
					continue;
				}

				payloadProperties[key] = requestData[key];
			} else if (typeof payloadConfig[key] === 'function') {
				try {
					payloadProperties[key] = payloadConfig[key](requestData, {
						customer: requestData.customerId || system.customerId,
						system: requestData.systemId || system.systemId,
						site: requestData.siteId || site.siteId,
					});
				} catch (error) {
					throw new Error(`Couldn't execute function property: ${key} when getting ${payloadType} payload`);
				}
			} else {
				payloadProperties[key] = payloadConfig[key];
			}
		}
	}

	return payloadProperties;
}

function _getParamsProperties(paramsConfig, payloadFactoryInputData, apiEntity, apiMethod) {
	const { system, site } = getCurrentSystemSiteCacheData();
	let missingRequiredKeys = [];
	let currentParamItem;
	let paramProperties = {};

	for (const key in paramsConfig) {
		if (paramsConfig.hasOwnProperty(key)) {
			if (paramsConfig[key][apiMethod]) {
				currentParamItem = paramsConfig[key][apiMethod];
				if (currentParamItem === true && !payloadFactoryInputData[key]) {
					missingRequiredKeys.push(key);
				} else {
					if (typeof currentParamItem === 'boolean') {
						paramProperties[key] = payloadFactoryInputData[key];
					} else if (typeof currentParamItem === 'function') {
						try {
							paramProperties[key] = currentParamItem(
								payloadFactoryInputData,
								{
									customer: payloadFactoryInputData.customerId || system.customerId,
									system: payloadFactoryInputData.systemId || system.systemId,
									site: payloadFactoryInputData.siteId || site.siteId,
								},
								apiEntity
							);
						} catch (error) {
							throw new Error(`Couldn't execute function property: ${key} when getting payload for ${apiEntity}`);
						}
					} else {
						paramProperties[key] = currentParamItem;
					}
				}
			}
		}
	}

	if (missingRequiredKeys.length > 0) {
		throw new Error(
			`Missing ${JSON.stringify(
				missingRequiredKeys
			)} property when getting params properties from ${apiMethod} method for ${apiEntity}`
		);
	}

	return paramProperties;
}
