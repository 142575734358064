import React from 'react';
import { Modal } from 'antd';
import { Button, Item } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';
import { usePostApi } from '~/screens/_shared/useApi';

export const InLineResetPasswordForm = ({ emailAddress }) => {
	const theme = useTheme();
	const { translate } = useLocale();
	const [resetPassword] = usePostApi('/authentication/reset-password-request/', () => {}, {
		removeDomainKey: true,
	});

	const onFinish = async () => {
		const languageCode = window.localStorage.getItem('selectedLanguage');
		const res = await resetPassword({
			userName: emailAddress,
			languageCode,
			__contentType: 'application/vnd.assaabloy.msfss.authentication-5.0+json',
		});

		if (res.success) {
			Modal.confirm({
				title: translate.byKey('reset_password'),
				content: translate.byKey('password_reset_success'),
				okText: translate.byKey('ok'),
				okButtonProps: {
					style: {
						backgroundColor: theme.colors.brand[500],
						borderColor: theme.colors.brand[500],
					},
				},
				destroyOnClose: true,
				cancelButtonProps: { style: { display: 'none' } },
			});
		}
	};

	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				onFinish();
			}}
		>
			<Item>
				<Button
					block
					type="primary"
					htmlType="submit"
					className="login-form-button"
					css={css`
						background-color: ${theme.colors.brand[600]} !important;
						border-color: ${theme.colors.brand[600]} !important;
					`}
				>
					{translate.byKey('reset_password')}
				</Button>
			</Item>
		</div>
	);
};
