import useSWR from 'swr';

const fakeData = [
	{
		id: 'door group id 1',
		name: 'Door group 1',
		doors: [
			{ name: 'Door A', type: 'aperio_offline' },
			{ name: 'Door B', type: 'aperio_offline' },
		],
	},
	{ id: 'door group id 2', name: 'Door group 2', doors: [{ name: 'Door A', type: 'aperio_offline' }] },
];

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// TODO MM change when API is ready
export const useDoorGroups = (key, config) => {
	const fetcher = async (key) => {
		// const [customerId, systemId, pageSize, pageNumber, name] = key.split(separator);
		return fakeData;
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getDoorGroupsKey = ({ customerId, systemId, pageSize, pageNumber, name }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${name}${separator}\
doorGroups`
		: null;
