import { useState } from 'react';
import { useGetApi } from '~/screens/_shared/useApi';
import mappers from '~/screens/_shared/mappers';
import { useLocale } from '~/screens/_shared/AppLocale';
import moment from 'moment';

const useGetDoor = () => {
	const { translate } = useLocale();
	const [isLoadingData, setIsLoadingData] = useState(false);

	const [currentLoadingStep, setCurrentLoadingStep] = useState('');
	const [getPortal] = useGetApi(mappers.portal);
	const [getCalendar] = useGetApi(mappers.calendar);
	const [getSchedule] = useGetApi(mappers.schedule);
	const [getAuthenticationProfile] = useGetApi(mappers.authenticationProfile);

	async function getPortalData(portalId, existingPortal) {
		setIsLoadingData(true);
		setCurrentLoadingStep(translate.byKey('fetching_door_data'));
		let portalData;
		try {
			portalData = !existingPortal ? await getPortal({ portalId }) : existingPortal;

			let authenticationProfileId = null;
			try {
				const relations = portalData.description ? JSON.parse(portalData.description) : {};
				if (relations.authId === '0') {
					relations.authId = null;
				}
				authenticationProfileId =
					relations.authId ||
					(portalData.configuration && portalData.configuration?.additionalSettings?.authenticationProfileId) ||
					undefined;
			} catch (ex) {
				if (
					portalData.configuration &&
					portalData.configuration.additionalSettings &&
					portalData.configuration.additionalSettings.authenticationProfileId
				) {
					authenticationProfileId = portalData.configuration.additionalSettings.authenticationProfileId;
				} else {
					throw ex;
				}
			}

			if (authenticationProfileId) {
				setCurrentLoadingStep(translate.byKey('fetching_authentication_profile'));
				const authenticationProfile = await getAuthenticationProfile(
					{ authenticationProfileId },
					{ expiry: moment().add(30, 'minutes') }
				);

				authenticationProfile.authenticationPolicies = authenticationProfile.authenticationPolicies || [];

				if (authenticationProfile.authenticationPolicies.length > 0) {
					const { scheduleId, calendarId } = authenticationProfile.authenticationPolicies[0].schedule;
					setCurrentLoadingStep(translate.byKey('fetching_schedule'));
					const pendingSchedule = getSchedule({ calendarId, scheduleId }, { expiry: moment().add(5, 'minutes') });
					setCurrentLoadingStep(translate.byKey('fetching_calendar'));
					const pendingCalendar = getCalendar({ calendarId }, { expiry: moment().add(5, 'minutes') });

					return {
						portal: portalData,
						authenticationProfile,
						calendar: await pendingCalendar,
						schedule: await pendingSchedule,
					};
				}
			}
			return { portal: portalData };
		} catch (ex) {
			console.error(ex);
			throw ex;
		} finally {
			setCurrentLoadingStep('');
			setIsLoadingData(false);
		}
	}

	return [getPortalData, isLoadingData, currentLoadingStep];
};

export default useGetDoor;
