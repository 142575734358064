import styled from '@emotion/styled';

import Flex from '../Flex';

const Overlay = styled(Flex)`
	position: absolute;
	opacity: ${(props) => (props.visible ? 1 : 0)};
	${(props) => (props.hasTransition ? 'transition: all 0.6s;' : '')}
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: ${(props) => props.zIndex || 1000};
	background-color: ${(props) => props.theme.colors.white};
`;

export default Overlay;
