import React from 'react';
import { getScrollHeightOptions } from '~/constants/Table';
import { SyncOutlined } from '@ant-design/icons';
import { Spin } from '~/components';
import { Table } from 'antd';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { useLocale } from '~/screens/_shared/AppLocale';
import moment from 'moment';

const holidayDateSorter = (a, b) => {
	const date1 = moment(a.end).set('year', moment().year());
	const date2 = moment(b.end).set('year', moment().year());

	return date1.diff(date2);
};

export const HolidaysTable = ({ data, isLoading, onRowClick }) => {
	const { translate } = useLocale();

	return (
		<Table
			loading={{
				spinning: isLoading,
				indicator: <Spin active={true} />,
			}}
			scroll={getScrollHeightOptions()}
			dataSource={data}
			rowKey="specialDayId"
			onRow={(record) => ({
				onClick: () => onRowClick(record),
			})}
			pagination={false}
			columns={[
				{
					dataIndex: 'recurring',
					render: (value) => (value ? <SyncOutlined /> : null),
					width: 46,
				},
				{
					title: translate.byKey('name'),
					dataIndex: 'name',
					sortDirections: ['descend', 'ascend', 'descend'],
					sorter: createSorter('name'),
				},
				{
					title: translate.byKey('start_date'),
					dataIndex: 'start',
					render: (value, record) => (record.recurring ? moment(value).format('[LDM]') : moment(value).format('L')),
					sortDirections: ['descend', 'ascend', 'descend'],
					sorter: holidayDateSorter,
				},
				{
					title: translate.byKey('end_date'),
					dataIndex: 'end',
					render: (value, record) => (record.recurring ? moment(value).format('[LDM]') : moment(value).format('L')),
					sortDirections: ['descend', 'ascend', 'descend'],
					sorter: holidayDateSorter,
				},
			]}
		/>
	);
};
