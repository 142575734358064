import { translator as translate } from '~/screens/_shared/AppLocale';
import HelpViewNames from '~/help/HelpViewNames';

function showForAuditReport(reportType) {
	return reportType === HelpViewNames.AUDITREPORT ? 'block' : 'none';
}

function showForAccessStatusReport(reportType) {
	return reportType === HelpViewNames.STATUSREPORT
		? 'block'
		: reportType === HelpViewNames.ACCESSREPORT
		  ? 'block'
		  : 'none';
}

function showForAccessReport(reportType) {
	return reportType === HelpViewNames.ACCESSREPORT ? 'block' : 'none';
}

const formHelp = (reportType) => `
<p><strong> ${translate.byKey('date_range_filter')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'from_and_until_are_both_required_for_filtering_by_date_and_time'
)} </p>

<p style="display: ${showForAuditReport(reportType)}"><strong> ${translate.byKey('action')} </strong></p>
<p style="display: ${showForAuditReport(reportType)}; padding-left: 30px;"> ${translate.byKey(
	'filter_by_operator_action'
)} </p>

<p style="display:${showForAccessStatusReport(reportType)}"><strong> ${translate.byKey(
	'controller_filter'
)} </strong></p>
<p style="display:${showForAccessStatusReport(reportType)}; padding-left: 30px;"> ${translate.byKey(
	'if_the_controller_filter_is_available_it_will_allow_you_to_report_on_logs_from_a_specific_access_control_device'
)} </p>

<p style="display:${showForAccessReport(reportType)}"><strong> ${translate.byKey('door')} </strong></p>
<p style="padding-left: 30px;display:${showForAccessReport(reportType)}"> ${translate.byKey('filter_by_door')} </p>

<p style="display:${showForAccessStatusReport(reportType)}"><strong> ${translate.byKey('event')} </strong></p>
<p style="padding-left: 30px;display:${showForAccessStatusReport(reportType)}"> 
      ${translate.byKey(
				reportType === HelpViewNames.STATUSREPORT ? 'filter_by_status_event' : 'filter_by_access_event'
			)} </p>

<p style="display:${showForAccessReport(reportType)}"><strong> ${translate.byKey('person')} </strong></p>
<p style="padding-left: 30px;display:${showForAccessReport(reportType)}"> ${translate.byKey(
	'filter_by_person_or_credential'
)} </p>

<p><strong> ${translate.byKey('clear_filters')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('reset_filter_selection')} </p> 

<p><strong> ${translate.byKey('cancel')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('cancel_button_closes_the_dialog_window_no_changes_are_saved')} </p>

<p><strong> ${translate.byKey('submit')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'submit_button_closes_the_dialog_window_saving_any_changes_made_to_filters'
)} </p>`;

export default formHelp;
