import { CredentialState } from '~/constants/Credential';
import { BlocklistMode, getCredentialsKey } from '~/hooks/data/credentials/useCredentials';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { API_SORT_MODE } from '~/utils/sort';

const DEFAULT_MODE = BlocklistMode.OFFLINE;
const DEFAULT_SORT_BY = 'description'; // TODO #2 -> 'name'
export const DEFAULT_SORT_DIRECTION = API_SORT_MODE.DESCENDING; // TODO #2 -> 'ASCENDING'

export const getBlockedCredentialsKey = ({
	customerId,
	systemId,
	pageSize = DEFAULT_PAGE_SIZE,
	pageNumber = 1,
	mode = DEFAULT_MODE,
	sortBy = DEFAULT_SORT_BY,
	sortDirection = DEFAULT_SORT_DIRECTION,
	person,
}) =>
	getCredentialsKey({
		customerId,
		systemId,
		pageSize: person ? 200 : pageSize, // TODO #1 when client side pagination is removed, change this
		pageNumber: person ? 1 : pageNumber, // TODO #1 when client side pagination is removed, change this
		mode,
		sortBy,
		sortDirection,
		credentialStates: CredentialState.BLOCKED,
		person,
	});
