import React from 'react';
import { getReadableName } from '~/screens/_shared/getReadableName';
import moment from 'moment';
import fileSaver from 'file-saver';
import { Button } from 'antd';

/**
 * Extract value out of the selected row.
 *
 * If anything is undefined, we return an empty string instead.
 *
 * @param {*} jsonString
 * @param {*} elementName
 */
export default function getJsonValue(jsonString, elementName, extractBody, category) {
	let value = '';

	// Nothing selected
	if (jsonString === undefined) {
		return value;
	}

	let row = extractBody === true ? jsonString.requestBody : jsonString;

	// Not all actions have a body. E.g. Delete.
	if (!row) {
		return value;
	}

	let jsonObj;

	if (row.includes) {
		try {
			jsonObj = JSON.parse(row);
		} catch (error) {
			if (
				error instanceof SyntaxError &&
				(row.includes(';') || row.includes(',')) &&
				(row.includes('\n') || row.includes('\r'))
			) {
				return (
					<Button
						type="link"
						onClick={(event) => {
							event.preventDefault();
							const blob = new Blob([row], { type: 'text/csv;charset=utf-8' });

							fileSaver.saveAs(blob, `People for bulk user enrollment ${moment().format('YYYY-MM-DD HH-mm')}.csv`);
						}}
					>
						Uploaded {row.trim().split(/\r?\n/).length - 1} people. Click to download the file.
					</Button>
				);
			}
			return '';
		}
	} else {
		jsonObj = row;
	}

	// if (extractBody === true) {
	//   console.log(`JSON Obj::  `, JSON.parse(JSON.stringify(row)));
	// } // DEBUG

	if (elementName === 'name') {
		if (category !== undefined && category === 'Controller') {
			value = getReadableName(jsonObj.name);
		} else {
			value = jsonObj.name;
		}
	} else if (elementName === 'fixedAddress') {
		value = jsonObj.fixedAddress;

		if (jsonObj.configuration !== undefined && jsonObj.configuration.additionalSettings !== undefined) {
			value = jsonObj.configuration.additionalSettings.fixedAddress;
		}
	} else if (elementName === 'modelNumber') {
		value = jsonObj.modelNumber;

		if (value === undefined && jsonObj.deviceInfo !== undefined) {
			value = jsonObj.deviceInfo.modelNumber;
		}
	} else if (elementName === 'description') {
		value = jsonObj.description;
	} else if (elementName === 'portalSide') {
		value = jsonObj.portalSide;

		if (value === undefined && jsonObj.logicalDevices !== undefined) {
			value = jsonObj.logicalDevices.portalSide;
		}
	} else if (elementName === 'serialNumber') {
		value = jsonObj.serialNumber;
	} else if (elementName === 'macAddress') {
		value = jsonObj.macAddress;
	} else if (elementName === 'defaultState') {
		value = jsonObj.defaultState;
	} else if (elementName === 'type') {
		value = jsonObj.type;
	} else if (elementName === 'firstName') {
		value = jsonObj.firstName;
	} else if (elementName === 'lastName') {
		value = jsonObj.lastName;
	} else if (elementName === 'phone' && jsonObj.phone !== undefined) {
		value = jsonObj.phone.replace(/,/g, '-');
	} else if (elementName === 'email') {
		value = jsonObj.email;
	}

	if (value === undefined) {
		value = '';
	} else if (value.includes('{')) {
		value = '';
	} else if (value.includes('}')) {
		value = '';
	}

	return value;
}
