import { AccessGroupTypes } from '~/constants/transactions';
import { translator } from '~/screens/_shared/AppLocale';

const isApplicable = (day) => day === 'Applicable';

export const formatDaySelectionValues = (dayTypes, standardWeek, accessGroupType) => [
	{
		day: translator.byKey('sunday'),
		index: 0,
		enabled: isApplicable(standardWeek.SUNDAY),
	},
	{
		day: translator.byKey('monday'),
		index: 1,
		enabled: isApplicable(standardWeek.MONDAY),
	},
	{
		day: translator.byKey('tuesday'),
		index: 2,
		enabled: isApplicable(standardWeek.TUESDAY),
	},
	{
		day: translator.byKey('wednesday'),
		index: 3,
		enabled: isApplicable(standardWeek.WEDNESDAY),
	},
	{
		day: translator.byKey('thursday'),
		index: 4,
		enabled: isApplicable(standardWeek.THURSDAY),
	},
	{
		day: translator.byKey('friday'),
		index: 5,
		enabled: isApplicable(standardWeek.FRIDAY),
	},
	{
		day: translator.byKey('saturday'),
		index: 6,
		enabled: isApplicable(standardWeek.SATURDAY),
	},
	...(accessGroupType === AccessGroupTypes.ONLINE
		? [
				{
					day: translator.byKey('holidays'),
					index: 7,
					enabled: Boolean(dayTypes.PublicHoliday),
				},
		  ]
		: []),
];

export const createEmptyDefaultDaySelection = (accessGroupType) => {
	const dayTypes = {};
	const standardWeek = {
		MONDAY: 'NA',
		TUESDAY: 'NA',
		WEDNESDAY: 'NA',
		THURSDAY: 'NA',
		FRIDAY: 'NA',
		SATURDAY: 'NA',
		SUNDAY: 'NA',
	};
	return formatDaySelectionValues(dayTypes, standardWeek, accessGroupType);
};
