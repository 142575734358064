import { useState } from 'react';

import mappers from '~/screens/_shared/mappers';
import { normalizeTimeSlots } from './helpers';
import { useGetApi, useListApi, usePutApi } from '~/screens/_shared/useApi';
import { securityLevels as securityLevelsConstants } from '../entityConstants';
import { cacheKeys } from './doorHelper';
import {
	getAccessAreaParams,
	getAssetsForPortal,
	getBasicAssetParams,
	useDefaultAccessGroupUpdate,
} from '~/screens/Doors/hooks/createOrUpdateDefaultAccessGroup';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUpdateReader } from '~/screens/Doors/hooks/useUpdateReader';
import { waitTimeout } from '~/shared';
import { doorTypeNumberOfReaders, isOffline, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { useSecurityLevels } from './useSecurityLevels';

export const scheduleStates = {
	[securityLevelsConstants.LOCKED]: 'Access is not allowed',
	[securityLevelsConstants.CARD_AND_PIN]: 'Access is allowed',
	[securityLevelsConstants.CARD]: 'Access is allowed',
	[securityLevelsConstants.UNLOCKED]: 'Unlocked',
	[securityLevelsConstants.PAC]: 'PAC',
	[securityLevelsConstants.OPEN_ON_FIRST_CARD]: 'Open on first card',
	[securityLevelsConstants.OFFICE_MODE]: 'Office Mode',
};

export function useUpdateDoor() {
	const {
		data: { site },
	} = useCurrentSystemSite();
	const { data: securityLevelStates } = useSecurityLevels();
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const { translate } = useLocale();
	const [getAssets] = useListApi(mappers.asset);
	const [updatePortal] = usePutApi(mappers.portal);
	const [updateCalendar] = usePutApi(mappers.calendar);
	const [updateSchedule] = usePutApi(mappers.schedule);
	const [updateAsset] = usePutApi(mappers.asset);

	const [getAuthenticationProfile] = useGetApi(mappers.authenticationProfile, console.error, {
		requiresUnformattedResponse: true,
	});

	const [updateAuthenticationProfile] = usePutApi(mappers.authenticationProfile, console.error, {
		requiresUnformattedRequest: true,
	});

	const [getAccessPoints] = useListApi(mappers.accessPoint);
	const [updateReader] = useUpdateReader();
	const [updateAccessPoint] = usePutApi(mappers.accessPoint);
	const updateDefaultAccessGroup = useDefaultAccessGroupUpdate();

	async function updateDoor(formData, doorType) {
		setIsLoading(true);
		setCurrentStep(translate.byKey('updating_door_details'));
		try {
			const { portalId, version, timeSlots, name, authenticationProfileId, authenticationProfile, configuration } =
				formData;
			setCurrentStep(translate.byKey('fetching_assets'));
			const { assets, accessPoints } = await getAssetsForPortal(getAccessPoints, portalId, {}, getAssets);

			let pendingUpdates = [];
			let calendarData = null;
			let scheduleData = null;

			if (isOnline(doorType)) {
				pendingUpdates.push(updateDefaultAccessGroup(setCurrentStep, assets));
			}

			let authenticationDetails = authenticationProfile ? JSON.parse(authenticationProfile) : {};
			let authenticationId = authenticationDetails.authenticationProfileId;
			const newTimeSlots = normalizeTimeSlots(timeSlots);
			let isDoorUpdated = false;

			const additionalParents = (formData.doorGroups || []).map((assetId) => {
				return { key: { assetId, systemId: site.systemId, siteId: site.siteId } };
			});

			pendingUpdates.push(
				updatePortal({
					portalId,
					version,
					name,
					description: '',
					configuration: {
						...configuration,
						openTime: doorType === 'pulse_offline' ? 'PT20S' : configuration.openTime,
						additionalSettings: {
							authenticationProfileId: authenticationId || authenticationProfileId,
							timeSlots: isOnline(doorType) ? JSON.stringify(newTimeSlots) : undefined,
							additionalData: {
								zoneAssetId: formData.zoneId,
								zoneAssetName: formData.zoneName,
								doorType: doorType,
								doorAssetId: formData.doorAssetId || assets[0].assetId,
								additionalParents: JSON.stringify(additionalParents),
							},
						},
					},
					removeExistingKey: cacheKeys.portalIndexKey(),
				})
			);

			await Promise.all(pendingUpdates).then(() => {
				isDoorUpdated = true;
			});

			if (newTimeSlots) {
				pendingUpdates = [];
				const { dayTypes, standardWeek } = newTimeSlots;
				const { calendar } = formData;
				calendarData = calendar;

				if (calendar) {
					setCurrentStep(translate.byKey('updating_calendar'));
					pendingUpdates.push(
						updateCalendar(
							{
								dayTypes,
								standardWeek,
								calendarId: calendar.calendarId,
								version: calendar.version,
								name: calendar.name,
								description: calendar.description,
							},
							{
								removeExistingKey: cacheKeys.calendarItemKey(calendar.calendarId),
							}
						)
					);
				}
				const { schedule } = formData;
				scheduleData = schedule;

				if (schedule) {
					const { timeIntervals } = newTimeSlots;
					const defaultState = securityLevelsConstants.CARD;

					setCurrentStep(translate.byKey('updating_schedule'));
					pendingUpdates.push(
						updateSchedule(
							{
								scheduleId: schedule.scheduleId,
								version: schedule.version,
								calendarId: calendar.calendarId,
								scheduleStates,
								defaultState,
								timeIntervals,
								name: schedule.name,
								description: schedule.description,
							},
							{
								removeExistingKey: cacheKeys.scheduleItemKey(calendar.calendarId, schedule.scheduleId),
							}
						)
					);

					pendingUpdates.push(waitTimeout(() => {}, 500));
				}
				await Promise.all(pendingUpdates);
			}

			if (authenticationProfileId || authenticationId) {
				setCurrentStep(translate.byKey('loading_authentication_profile'));
				const authenticationProfile = await getAuthenticationProfile({
					authenticationProfileId: authenticationProfileId || authenticationId,
				});

				if (!authenticationProfile.attributes) {
					return;
				}

				const allAuthenticationPolicies = authenticationProfile.attributes.authenticationPolicies;
				const isOfficeModeInAuthenticationPolicy = allAuthenticationPolicies?.some((authenticationPolicy) =>
					authenticationPolicy.scheduleState.includes(securityLevelsConstants.OFFICE_MODE)
				);

				/*
                  If the system has Office Mode enabled but the door does not have the Office Mode authentication policy, 
                  then we update the authentication policy to include Office Mode.
                */
				if (securityLevelStates[securityLevelsConstants.OFFICE_MODE] && !isOfficeModeInAuthenticationPolicy) {
					const authenticationPolicyScheduleAttributes = authenticationProfile.attributes.authenticationPolicies[0];

					const officeModeAuthenticationPolicy = {
						schedule: {
							key: {
								systemId: `${site.systemId}`,
								calendarId: `${calendarData.calendarId}`,
								scheduleId: `${scheduleData.scheduleId}`,
							},
							attributes: {
								name: `${authenticationPolicyScheduleAttributes.schedule.attributes.name}`,
								version: `${authenticationPolicyScheduleAttributes.schedule.attributes.version}`,
							},
						},
						scheduleState: `${securityLevelStates[securityLevelsConstants.OFFICE_MODE].name}`,
						securityLevel: {
							key: {
								systemId: `${securityLevelStates[securityLevelsConstants.OFFICE_MODE].systemId}`,
								securityLevelId: `${securityLevelStates[securityLevelsConstants.OFFICE_MODE].securityLevelId}`,
							},
							attributes: {
								name: `${securityLevelStates[securityLevelsConstants.OFFICE_MODE].name}`,
								priority: parseInt(`${securityLevelStates[securityLevelsConstants.OFFICE_MODE].priority}`),
								version: `${securityLevelStates[securityLevelsConstants.OFFICE_MODE].version}`,
							},
						},
					};

					authenticationProfile.attributes.authenticationPolicies.push(officeModeAuthenticationPolicy);
				}

				setCurrentStep(translate.byKey('updating_authentication_profile'));
				await updateAuthenticationProfile(authenticationProfile.attributes);
				await waitTimeout(() => {}, 500);
			}

			const { zoneId } = formData || '';
			const asset = assets.find((asset) => asset.assetId === formData.doorAssetId);
			let hasAssetChange = false;
			if (asset) {
				let removeListCache = [getBasicAssetParams()];

				if (asset.type !== 'BASIC_ASSET') {
					hasAssetChange = true;
					asset.type = 'BASIC_ASSET';
					removeListCache.push(getAccessAreaParams());
				}

				const mainParent = asset.mainParent || {};
				const existingAdditionalParents = asset.additionalParents || [];

				hasAssetChange =
					hasAssetChange ||
					additionalParents.length !== existingAdditionalParents.length ||
					!additionalParents.every((parent) =>
						existingAdditionalParents.some((other) => other.assetId === parent.key.assetId)
					);
				hasAssetChange = hasAssetChange || mainParent.assetId !== zoneId;

				/* This will now update the asset name everytime the door name is updated.
                   It will need to be reverted when an Asset Management facility is available.
                   */
				if (hasAssetChange || isDoorUpdated) {
					await updateAsset(
						{
							assetId: asset.assetId,
							name: name,
							type: asset.type,
							version: asset.version,
							mainParent: zoneId
								? {
										key: {
											systemId: asset.systemId,
											siteId: asset.siteId,
											assetId: zoneId,
										},
								  }
								: undefined,
							additionalParents: additionalParents || undefined,
						},
						{
							removeListCache,
						}
					);
				}
			}

			formData.readers = formData.readers || [];

			if (formData.readers.length > 0 && !(isOffline(doorType) && hasAssetChange)) {
				await Promise.all(
					formData.readers.map((reader) => {
						if (reader.isEntry === null || reader.isEntry === undefined) {
							reader.isEntry = formData.readers.length === 1;
						}

						reader.portalName = name || reader.portalName;

						return updateReader(
							{
								portal: { portalId, name },
								isEntry: JSON.parse(reader.isEntry),
								formattedValues: reader,
							},
							doorType
						);
					})
				);
			}

			setCurrentStep(translate.byKey('updating_access_points'));
			await Promise.all(
				accessPoints.map((accessPoint) => {
					accessPoint.name = accessPoint.name || '';
					let shouldUpdate = !accessPoint.name.startsWith(name);

					const deviceType = formData.readers
						.map((reader) => (reader.isEntry ? 'ENTRY' : 'EXIT'))
						.concat([accessPoint.type])[0];

					if (doorTypeNumberOfReaders[doorType] === 1 && accessPoint.type !== deviceType) {
						shouldUpdate = true;
						accessPoint.type = deviceType;
					}

					if (shouldUpdate) {
						accessPoint.portalName = name;
						return updateAccessPoint(accessPoint);
					}

					return Promise.resolve();
				})
			);
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	}

	return [updateDoor, isLoading, currentStep];
}
