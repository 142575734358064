import styled from '@emotion/styled';
import { Tabs as ATabs } from 'antd';
import React from 'react';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	position,
	shadow,
	size,
	space,
	typography,
} from 'styled-system';

const StyledTabs = styled(ATabs)`
	overflow: inherit !important;

	.ant-tabs-nav {
		${(props) => props.evenWidth && 'width: 100% !important;'}
		${(props) => props.evenWidth && 'padding-right: 2px !important;'}
      ${(props) => props.evenWidth && 'display: flex !important;'}

      & > div {
			${(props) => props.evenWidth && 'display: flex !important;'}
			${(props) => props.evenWidth && 'flex: 1 !important;'}
		}
	}
	.ant-tabs-tab {
		color: ${(props) => props.theme.colors.gray[400]} !important;
		font-family: Verdana !important;
		font-size: 12px !important;
		border-radius: 4px 4px 0 0 !important;
		background: ${(props) => props.theme.colors.gray[50]} !important;
		${(props) => !props.evenWidth && '120px !important;'}
		${(props) => props.evenWidth && 'flex: 1 !important;'}
        text-align: center !important;
		line-height: 40px !important;
	}

	.ant-tabs-tab-active {
		color: ${(props) => props.theme.colors.brand[500]} !important;
		background: ${(props) => props.theme.colors.white} !important;
	}
	${layout}
	${space}
    ${flexbox}
    ${size}
    ${background}
    ${grid}
    ${color}
    ${typography}
    ${border}
    ${position}
    ${shadow}
`;

/**
 * @type {ATabs}
 * @param {import("antd/lib/tabs").TabsProps} props
 */
const Tabs = (props) => {
	return <StyledTabs {...props}>{props.children}</StyledTabs>;
};

Tabs.TabPane = ATabs.TabPane;

export default Tabs;
