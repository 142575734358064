import { useRef } from 'react';
import useSWR from 'swr';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const useOnboardedClients = (key, config) => {
	const ref = useRef(
		new ApiRequester({
			entity: '/clients',
			method: method.get,
		})
	);
	const fetcher = async (key) => {
		const [customerId, systemId] = key.split(separator);

		return ref.current.fetchData({
			params: {
				customerId,
				systemId,
			},
			ignoreGlobalHandlers: true,
			removeProperties: ['ignoreGlobalHandlers'],
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getOnboardedClientsKey = ({ customerId, systemId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
clients`
		: null;
