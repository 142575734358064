import { ACTION_TYPES } from './SiteControllersEmergencyRecords.reducer';

export const resetAction = () => ({
	type: ACTION_TYPES.RESET,
});

export const initializeRecordsAction = (controllers) => ({
	type: ACTION_TYPES.INITIALIZE,
	controllers,
});

export const updateControllersStateAction = ({ controllerIds, state }) => ({
	type: ACTION_TYPES.UPDATE_CONTROLLLERS,
	controllerIds,
	state,
});

export const updateControllerStateAction = ({ controllerId, state }) => ({
	type: ACTION_TYPES.UPDATE_CONTROLLER,
	controllerId,
	state,
});
