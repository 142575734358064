import { getCalendarsKey, useGetCalendars } from '~/hooks/data/calendars/useGetCalendars';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { DEFAULT_CALENDAR_NAME } from '~/constants/Calendars';

export const useCalendars = () => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();

	const {
		data: calendars,
		isLoading: isCalendarsLoading,
		isValidating: isCalendarsValidating,
		error: calendarsError,
		mutate: mutateCalendars,
	} = useGetCalendars(
		getCalendarsKey({
			customerId,
			systemId,
		})
	);

	const defaultCalendarId = calendars?.find((x) => x.name === DEFAULT_CALENDAR_NAME)?.calendarId;

	return {
		data: calendars,
		isLoading: isCalendarsLoading,
		isValidating: isCalendarsValidating,
		error: calendarsError,
		mutate: mutateCalendars,
		defaultCalendarId,
	};
};
