import { useLocale } from '~/screens/_shared/AppLocale';
import { Button, Col, Form, Row } from 'antd';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { CredentialForm } from '~/components/features/people/modals/PeopleModal/tabs/CredentialsTab/CredentialForm';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const CardInputs = ({ form, selected, sharedPinCredential, onUpdate, onCancelCard, isEditDisabled }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const isCredentialBlocked = (credential) => {
		if (form.getFieldValue('credentials')[credential.name]) {
			return form.getFieldValue('credentials')[credential.name].isBlocked;
		}
	};

	return (
		<Row gutter={[12, 12]}>
			<Col span={24}>
				<Form.List name="credentials">
					{(credentials, { add, remove }) => {
						return (
							<div>
								{credentials
									.filter((credential) => !credential.shouldDelete)
									.map((credential) => (
										<Row key={credential.key}>
											<Col span={24}>
												<CredentialForm
													field={credential}
													selected={{ ...selected, total: credentials.length }}
													form={form}
													credentialHolderId={selected ? selected.credentialHolderId : null}
													sharedPinCredential={sharedPinCredential}
													onUpdate={onUpdate}
													onCancelCard={onCancelCard}
													blocked={isCredentialBlocked(credential)}
													disabled={isEditDisabled}
													removeItem={(item) => {
														const credentials = form.getFieldValue('credentials');
														if (credentials[item.name] && credentials[item.name].credentialId) {
															const credentialsToDelete = form.getFieldValue('credentialsToDelete') || [];
															credentialsToDelete.push(credentials[item.name].credentialId);
															form.setFieldsValue({ credentialsToDelete });
															remove(item.name);
														} else {
															remove(item.name);
														}
													}}
												/>
											</Col>
										</Row>
									))}

								{!credentials.length && isEditDisabled ? <div>{translate.byKey('no_credentials')}</div> : null}

								{credentials.length < 3 &&
									hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.CREATE) &&
									!isEditDisabled && (
										<Row gutter={[12, 12]}>
											<Col
												span={24}
												style={{
													justifyContent: 'center',
													display: 'flex',
													paddingTop: '24px',
												}}
											>
												<Form.Item style={{ width: '50%' }}>
													<Button
														type="dashed"
														style={{ width: '100%' }}
														onClick={() => {
															add();
														}}
													>
														<PlusOutlined /> {translate.byKey('add_credential_v2')}
													</Button>
												</Form.Item>
											</Col>
										</Row>
									)}
							</div>
						);
					}}
				</Form.List>
			</Col>
		</Row>
	);
};
