import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const config = {
	revalidateOnFocus: false,
};

export const useSystemOfflineSettingsUpdate = (key) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/validate',
				method: method.put,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg: { revalidationTime, collectAccessEvents } }) =>
		ref.current.fetchData({ revalidationTime, collectAccessEvents });

	return useSWRMutation(key, fetcher, config);
};
