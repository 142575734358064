const theme = {
	breakpoints: ['30em', '48em', '62em', '80em'],
	zIndices: {
		hide: -1,
		auto: 'auto',
		base: 0,
		docked: 10,
		dropdown: 1000,
		sticky: 1100,
		banner: 1200,
		overlay: 1300,
		modal: 1400,
		popover: 1500,
		skipLink: 1600,
		toast: 1700,
		tooltip: 1800,
	},
	radii: {
		none: '0',
		sm: '0.125rem',
		md: '0.25rem',
		lg: '0.5rem',
		full: '9999px',
	},
	opacity: {
		0: '0',
		'20%': '0.2',
		'40%': '0.4',
		'60%': '0.6',
		'80%': '0.8',
		'100%': '1',
	},
	borders: {
		none: 0,
		'1px': '1px solid',
		'2px': '2px solid',
		'4px': '4px solid',
	},
	colors: {
		transparent: 'transparent',
		current: 'currentColor',
		black: '#000',
		white: '#fff',
		whiteAlpha: {
			50: 'rgba(255, 255, 255, 0.04)',
			100: 'rgba(255, 255, 255, 0.06)',
			200: 'rgba(255, 255, 255, 0.08)',
			300: 'rgba(255, 255, 255, 0.16)',
			400: 'rgba(255, 255, 255, 0.24)',
			500: 'rgba(255, 255, 255, 0.36)',
			600: 'rgba(255, 255, 255, 0.48)',
			700: 'rgba(255, 255, 255, 0.64)',
			800: 'rgba(255, 255, 255, 0.80)',
			900: 'rgba(255, 255, 255, 0.92)',
		},
		blackAlpha: {
			50: 'rgba(0, 0, 0, 0.04)',
			100: 'rgba(0, 0, 0, 0.06)',
			200: 'rgba(0, 0, 0, 0.08)',
			300: 'rgba(0, 0, 0, 0.16)',
			400: 'rgba(0, 0, 0, 0.24)',
			500: 'rgba(0, 0, 0, 0.36)',
			600: 'rgba(0, 0, 0, 0.48)',
			700: 'rgba(0, 0, 0, 0.64)',
			800: 'rgba(0, 0, 0, 0.80)',
			900: 'rgba(0, 0, 0, 0.92)',
		},
		gray: {
			50: '#F7FAFC',
			100: '#EDF2F7',
			200: '#E2E8F0',
			300: '#CBD5E0',
			400: '#A0AEC0',
			500: '#718096',
			600: '#4A5568',
			700: '#2D3748',
			800: '#1A202C',
			900: '#171923',
		},
		red: {
			50: '#fff5f5',
			100: '#fed7d7',
			200: '#feb2b2',
			300: '#fc8181',
			400: '#f56565',
			500: '#e53e3e',
			600: '#c53030',
			700: '#9b2c2c',
			800: '#822727',
			900: '#63171b',
		},
		orange: {
			50: '#FFFAF0',
			100: '#FEEBC8',
			200: '#FBD38D',
			300: '#F6AD55',
			400: '#ED8936',
			500: '#DD6B20',
			600: '#C05621',
			700: '#9C4221',
			800: '#7B341E',
			900: '#652B19',
		},
		yellow: {
			50: '#fffff0',
			100: '#fefcbf',
			200: '#faf089',
			300: '#f6e05e',
			400: '#ecc94b',
			500: '#d69e2e',
			600: '#b7791f',
			700: '#975a16',
			800: '#744210',
			900: '#5F370E',
		},
		green: {
			50: '#f0fff4',
			100: '#c6f6d5',
			200: '#9ae6b4',
			300: '#68d391',
			400: '#48bb78',
			500: '#38a169',
			600: '#2f855a',
			700: '#276749',
			800: '#22543d',
			900: '#1C4532',
		},
		teal: {
			50: '#E6FFFA',
			100: '#B2F5EA',
			200: '#81E6D9',
			300: '#4FD1C5',
			400: '#38B2AC',
			500: '#319795',
			600: '#2C7A7B',
			700: '#285E61',
			800: '#234E52',
			900: '#1D4044',
		},
		blue: {
			50: '#ebf8ff',
			100: '#ceedff',
			200: '#90cdf4',
			300: '#63b3ed',
			400: '#4299e1',
			500: '#3182ce',
			600: '#2a69ac',
			700: '#1e4e8c',
			800: '#153e75',
			900: '#1a365d',
		},
		cyan: {
			50: '#EDFDFD',
			100: '#C4F1F9',
			200: '#9DECF9',
			300: '#76E4F7',
			400: '#0BC5EA',
			500: '#00B5D8',
			600: '#00A3C4',
			700: '#0987A0',
			800: '#086F83',
			900: '#065666',
		},
		purple: {
			50: '#faf5ff',
			100: '#e9d8fd',
			200: '#d6bcfa',
			300: '#b794f4',
			400: '#9f7aea',
			500: '#805ad5',
			600: '#6b46c1',
			700: '#553c9a',
			800: '#44337a',
			900: '#322659',
		},
		pink: {
			50: '#fff5f7',
			100: '#fed7e2',
			200: '#fbb6ce',
			300: '#f687b3',
			400: '#ed64a6',
			500: '#d53f8c',
			600: '#b83280',
			700: '#97266d',
			800: '#702459',
			900: '#521B41',
		},
		linkedin: {
			50: '#E8F4F9',
			100: '#CFEDFB',
			200: '#9BDAF3',
			300: '#68C7EC',
			400: '#34B3E4',
			500: '#00A0DC',
			600: '#008CC9',
			700: '#0077B5',
			800: '#005E93',
			900: '#004471',
		},
		facebook: {
			50: '#E8F4F9',
			100: '#D9DEE9',
			200: '#B7C2DA',
			300: '#6482C0',
			400: '#4267B2',
			500: '#385898',
			600: '#314E89',
			700: '#29487D',
			800: '#223B67',
			900: '#1E355B',
		},
		messenger: {
			50: '#D0E6FF',
			100: '#B9DAFF',
			200: '#A2CDFF',
			300: '#7AB8FF',
			400: '#2E90FF',
			500: '#0078FF',
			600: '#0063D1',
			700: '#0052AC',
			800: '#003C7E',
			900: '#002C5C',
		},
		whatsapp: {
			50: '#e2f7f4',
			100: '#c3f0e9',
			200: '#a0e7dc',
			300: '#76dccd',
			400: '#43cfba',
			500: '#00BFA5',
			600: '#00ac92',
			700: '#009780',
			800: '#007d6a',
			900: '#005a4c',
		},
		twitter: {
			50: '#e5f4fd',
			100: '#c8e9fb',
			200: '#a8dcfa',
			300: '#83cdf7',
			400: '#57bbf5',
			500: '#1DA1F2',
			600: '#1a94da',
			700: '#1681bf',
			800: '#136b9e',
			900: '#0d4d71',
		},
		telegram: {
			50: '#e3f2f9',
			100: '#c5e4f3',
			200: '#a2d4ec',
			300: '#7ac1e4',
			400: '#47a9da',
			500: '#0088CC',
			600: '#007ab8',
			700: '#006ba1',
			800: '#005885',
			900: '#003f5e',
		},
	},
	letterSpacings: {
		tighter: '-0.05em',
		tight: '-0.025em',
		normal: '0',
		wide: '0.025em',
		wider: '0.05em',
		widest: '0.1em',
	},
	lineHeights: {
		normal: 'normal',
		none: '1',
		shorter: '1.25',
		short: '1.375',
		base: '1.5',
		tall: '1.625',
		taller: '2',
	},
	fontWeights: {
		hairline: 100,
		thin: 200,
		light: 300,
		normal: 400,
		medium: 500,
		semibold: 600,
		bold: 700,
		extrabold: 800,
		black: 900,
	},
	fonts: {
		heading:
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		mono: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
	},
	fontSizes: {
		xs: '0.75rem',
		sm: '0.875rem',
		md: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '4rem',
	},
	sizes: {
		0: '0',
		1: '0.25rem',
		2: '0.5rem',
		3: '0.75rem',
		4: '1rem',
		5: '1.25rem',
		6: '1.5rem',
		8: '2rem',
		10: '2.5rem',
		12: '3rem',
		16: '4rem',
		20: '5rem',
		24: '6rem',
		32: '8rem',
		40: '10rem',
		48: '12rem',
		56: '14rem',
		64: '16rem',
		px: '1px',
		full: '100%',
		'3xs': '14rem',
		'2xs': '16rem',
		xs: '20rem',
		sm: '24rem',
		md: '28rem',
		lg: '32rem',
		xl: '36rem',
		'2xl': '42rem',
		'3xl': '48rem',
		'4xl': '56rem',
		'5xl': '64rem',
		'6xl': '72rem',
		containers: { sm: '640px', md: '768px', lg: '1024px', xl: '1280px' },
	},
	shadows: {
		sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
		md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
		lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
		xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
		'2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
		outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
		inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
		none: 'none',
	},
	space: {
		0: '0',
		1: '0.25rem',
		2: '0.5rem',
		3: '0.75rem',
		4: '1rem',
		5: '1.25rem',
		6: '1.5rem',
		8: '2rem',
		10: '2.5rem',
		12: '3rem',
		16: '4rem',
		20: '5rem',
		24: '6rem',
		32: '8rem',
		40: '10rem',
		48: '12rem',
		56: '14rem',
		64: '16rem',
		px: '1px',
	},
	icons: {
		copy: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z',
				},
				_owner: null,
				_store: {},
			},
		},
		search: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M23.384,21.619,16.855,15.09a9.284,9.284,0,1,0-1.768,1.768l6.529,6.529a1.266,1.266,0,0,0,1.768,0A1.251,1.251,0,0,0,23.384,21.619ZM2.75,9.5a6.75,6.75,0,1,1,6.75,6.75A6.758,6.758,0,0,1,2.75,9.5Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'search-2': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M23.414,20.591l-4.645-4.645a10.256,10.256,0,1,0-2.828,2.829l4.645,4.644a2.025,2.025,0,0,0,2.828,0A2,2,0,0,0,23.414,20.591ZM10.25,3.005A7.25,7.25,0,1,1,3,10.255,7.258,7.258,0,0,1,10.25,3.005Z',
				},
				_owner: null,
				_store: {},
			},
		},
		moon: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M21.4,13.7C20.6,13.9,19.8,14,19,14c-5,0-9-4-9-9c0-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0-0.7-0.3-1 c-0.3-0.3-0.6-0.4-1-0.3C4.3,2.7,1,7.1,1,12c0,6.1,4.9,11,11,11c4.9,0,9.3-3.3,10.6-8.1c0.1-0.3,0-0.7-0.3-1 C22.1,13.7,21.7,13.6,21.4,13.7z',
				},
				_owner: null,
				_store: {},
			},
		},
		sun: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					strokeLinejoin: 'full',
					strokeLinecap: 'full',
					strokeWidth: '2',
					fill: 'none',
					stroke: 'currentColor',
					children: [
						{
							type: 'circle',
							key: null,
							ref: null,
							props: { cx: '12', cy: '12', r: '5' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M12 1v2' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M12 21v2' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M4.22 4.22l1.42 1.42' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M18.36 18.36l1.42 1.42' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M1 12h2' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M21 12h2' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M4.22 19.78l1.42-1.42' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M18.36 5.64l1.42-1.42' },
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		add: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M0,12a1.5,1.5,0,0,0,1.5,1.5h8.75a.25.25,0,0,1,.25.25V22.5a1.5,1.5,0,0,0,3,0V13.75a.25.25,0,0,1,.25-.25H22.5a1.5,1.5,0,0,0,0-3H13.75a.25.25,0,0,1-.25-.25V1.5a1.5,1.5,0,0,0-3,0v8.75a.25.25,0,0,1-.25.25H1.5A1.5,1.5,0,0,0,0,12Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'small-add': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M14 9h-3V6c0-.55-.45-1-1-1s-1 .45-1 1v3H6c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1z',
					fillRule: 'evenodd',
				},
				_owner: null,
				_store: {},
			},
			viewBox: '0 0 20 20',
		},
		settings: {
			viewBox: '0 0 14 14',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M14,7.77 L14,6.17 L12.06,5.53 L11.61,4.44 L12.49,2.6 L11.36,1.47 L9.55,2.38 L8.46,1.93 L7.77,0.01 L6.17,0.01 L5.54,1.95 L4.43,2.4 L2.59,1.52 L1.46,2.65 L2.37,4.46 L1.92,5.55 L0,6.23 L0,7.82 L1.94,8.46 L2.39,9.55 L1.51,11.39 L2.64,12.52 L4.45,11.61 L5.54,12.06 L6.23,13.98 L7.82,13.98 L8.45,12.04 L9.56,11.59 L11.4,12.47 L12.53,11.34 L11.61,9.53 L12.08,8.44 L14,7.75 L14,7.77 Z M7,10 C5.34,10 4,8.66 4,7 C4,5.34 5.34,4 7,4 C8.66,4 10,5.34 10,7 C10,8.66 8.66,10 7,10 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'check-circle': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z',
				},
				_owner: null,
				_store: {},
			},
		},
		lock: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M19.5,9.5h-.75V6.75a6.75,6.75,0,0,0-13.5,0V9.5H4.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2V11.5A2,2,0,0,0,19.5,9.5Zm-9.5,6a2,2,0,1,1,3,1.723V19.5a1,1,0,0,1-2,0V17.223A1.994,1.994,0,0,1,10,15.5ZM7.75,6.75a4.25,4.25,0,0,1,8.5,0V9a.5.5,0,0,1-.5.5H8.25a.5.5,0,0,1-.5-.5Z',
				},
				_owner: null,
				_store: {},
			},
		},
		unlock: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M19.5,9.5h-.75V6.75A6.751,6.751,0,0,0,5.533,4.811a1.25,1.25,0,1,0,2.395.717A4.251,4.251,0,0,1,16.25,6.75V9a.5.5,0,0,1-.5.5H4.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h15a2,2,0,0,0,2-2V11.5A2,2,0,0,0,19.5,9.5Zm-9.5,6a2,2,0,1,1,3,1.723V19.5a1,1,0,0,1-2,0V17.223A1.994,1.994,0,0,1,10,15.5Z',
				},
				_owner: null,
				_store: {},
			},
		},
		view: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M23.432,10.524C20.787,7.614,16.4,4.538,12,4.6,7.6,4.537,3.213,7.615.568,10.524a2.211,2.211,0,0,0,0,2.948C3.182,16.351,7.507,19.4,11.839,19.4h.308c4.347,0,8.671-3.049,11.288-5.929A2.21,2.21,0,0,0,23.432,10.524ZM7.4,12A4.6,4.6,0,1,1,12,16.6,4.6,4.6,0,0,1,7.4,12Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'circle',
							key: null,
							ref: null,
							props: { cx: '12', cy: '12', r: '2' },
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'view-off': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M23.2,10.549a20.954,20.954,0,0,0-4.3-3.6l4-3.995a1,1,0,1,0-1.414-1.414l-.018.018a.737.737,0,0,1-.173.291l-19.5,19.5c-.008.007-.018.009-.026.017a1,1,0,0,0,1.631,1.088l4.146-4.146a11.26,11.26,0,0,0,4.31.939h.3c4.256,0,8.489-2.984,11.051-5.8A2.171,2.171,0,0,0,23.2,10.549ZM16.313,13.27a4.581,4.581,0,0,1-3,3.028,4.3,4.3,0,0,1-3.1-.19.253.253,0,0,1-.068-.407l5.56-5.559a.252.252,0,0,1,.407.067A4.3,4.3,0,0,1,16.313,13.27Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M7.615,13.4a.244.244,0,0,0,.061-.24A4.315,4.315,0,0,1,7.5,12,4.5,4.5,0,0,1,12,7.5a4.276,4.276,0,0,1,1.16.173.244.244,0,0,0,.24-.062l1.941-1.942a.254.254,0,0,0-.1-.421A10.413,10.413,0,0,0,12,4.75C7.7,4.692,3.4,7.7.813,10.549a2.15,2.15,0,0,0-.007,2.9,21.209,21.209,0,0,0,3.438,3.03.256.256,0,0,0,.326-.029Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		download: {
			viewBox: '0 0 14 14',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M11.2857,6.05714 L10.08571,4.85714 L7.85714,7.14786 L7.85714,1 L6.14286,1 L6.14286,7.14786 L3.91429,4.85714 L2.71429,6.05714 L7,10.42857 L11.2857,6.05714 Z M1,11.2857 L1,13 L13,13 L13,11.2857 L1,11.2857 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		delete: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M19.452,7.5H4.547a.5.5,0,0,0-.5.545L5.334,22.181A2,2,0,0,0,7.326,24h9.347a2,2,0,0,0,1.992-1.819L19.95,8.045a.5.5,0,0,0-.129-.382A.5.5,0,0,0,19.452,7.5Zm-9.2,13a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Zm5,0a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M22,4H17.25A.25.25,0,0,1,17,3.75V2.5A2.5,2.5,0,0,0,14.5,0h-5A2.5,2.5,0,0,0,7,2.5V3.75A.25.25,0,0,1,6.75,4H2A1,1,0,0,0,2,6H22a1,1,0,0,0,0-2ZM9,3.75V2.5A.5.5,0,0,1,9.5,2h5a.5.5,0,0,1,.5.5V3.75a.25.25,0,0,1-.25.25H9.25A.25.25,0,0,1,9,3.75Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		repeat: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M10.319,4.936a7.239,7.239,0,0,1,7.1,2.252,1.25,1.25,0,1,0,1.872-1.657A9.737,9.737,0,0,0,9.743,2.5,10.269,10.269,0,0,0,2.378,9.61a.249.249,0,0,1-.271.178l-1.033-.13A.491.491,0,0,0,.6,9.877a.5.5,0,0,0-.019.526l2.476,4.342a.5.5,0,0,0,.373.248.43.43,0,0,0,.062,0,.5.5,0,0,0,.359-.152l3.477-3.593a.5.5,0,0,0-.3-.844L5.15,10.172a.25.25,0,0,1-.2-.333A7.7,7.7,0,0,1,10.319,4.936Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M23.406,14.1a.5.5,0,0,0,.015-.526l-2.5-4.329A.5.5,0,0,0,20.546,9a.489.489,0,0,0-.421.151l-3.456,3.614a.5.5,0,0,0,.3.842l1.848.221a.249.249,0,0,1,.183.117.253.253,0,0,1,.023.216,7.688,7.688,0,0,1-5.369,4.9,7.243,7.243,0,0,1-7.1-2.253,1.25,1.25,0,1,0-1.872,1.656,9.74,9.74,0,0,0,9.549,3.03,10.261,10.261,0,0,0,7.369-7.12.251.251,0,0,1,.27-.179l1.058.127a.422.422,0,0,0,.06,0A.5.5,0,0,0,23.406,14.1Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'repeat-clock': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M12.965,6a1,1,0,0,0-1,1v5.5a1,1,0,0,0,1,1h5a1,1,0,0,0,0-2h-3.75a.25.25,0,0,1-.25-.25V7A1,1,0,0,0,12.965,6Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M12.567,1.258A10.822,10.822,0,0,0,2.818,8.4a.25.25,0,0,1-.271.163L.858,8.309a.514.514,0,0,0-.485.213.5.5,0,0,0-.021.53l2.679,4.7a.5.5,0,0,0,.786.107l3.77-3.746a.5.5,0,0,0-.279-.85L5.593,9.007a.25.25,0,0,1-.192-.35,8.259,8.259,0,1,1,7.866,11.59,1.25,1.25,0,0,0,.045,2.5h.047a10.751,10.751,0,1,0-.792-21.487Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		edit: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'none',
					stroke: 'currentColor',
					strokeLinecap: 'full',
					strokeWidth: '2',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'chevron-right': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z',
				},
				_owner: null,
				_store: {},
			},
		},
		'chevron-left': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z',
				},
				_owner: null,
				_store: {},
			},
		},
		'chevron-down': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
				},
				_owner: null,
				_store: {},
			},
		},
		'chevron-up': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-forward': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-up': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-down': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-back': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z',
				},
				_owner: null,
				_store: {},
			},
		},
		'external-link': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'none',
					stroke: 'currentColor',
					strokeLinecap: 'full',
					strokeWidth: '2',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M15 3h6v6' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M10 14L21 3' },
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		link: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M10.458,18.374,7.721,21.11a2.853,2.853,0,0,1-3.942,0l-.892-.891a2.787,2.787,0,0,1,0-3.941l5.8-5.8a2.789,2.789,0,0,1,3.942,0l.893.892A1,1,0,0,0,14.94,9.952l-.893-.892a4.791,4.791,0,0,0-6.771,0l-5.8,5.8a4.787,4.787,0,0,0,0,6.77l.892.891a4.785,4.785,0,0,0,6.771,0l2.736-2.735a1,1,0,1,0-1.414-1.415Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M22.526,2.363l-.892-.892a4.8,4.8,0,0,0-6.77,0l-2.905,2.9a1,1,0,0,0,1.414,1.414l2.9-2.9a2.79,2.79,0,0,1,3.941,0l.893.893a2.786,2.786,0,0,1,0,3.942l-5.8,5.8a2.769,2.769,0,0,1-1.971.817h0a2.766,2.766,0,0,1-1.969-.816,1,1,0,1,0-1.415,1.412,4.751,4.751,0,0,0,3.384,1.4h0a4.752,4.752,0,0,0,3.385-1.4l5.8-5.8a4.786,4.786,0,0,0,0-6.771Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'plus-square': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'none',
					stroke: 'currentColor',
					strokeLinecap: 'full',
					strokeWidth: '2',
					children: [
						{
							type: 'rect',
							key: null,
							ref: null,
							props: {
								height: '18',
								width: '18',
								rx: '2',
								ry: '2',
								x: '3',
								y: '3',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M12 8v8' },
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: { d: 'M8 12h8' },
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		chat: {
			viewBox: '0 0 14 14',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M0.913134,0.920639 C1.49851,0.331726 2.29348,0 3.12342,0 L10.8766,0 C11.7065,0 12.5015,0.331725 13.0869,0.920639 C13.6721,1.50939 14,2.30689 14,3.13746 L14,8.12943 C13.9962,8.51443 13.9059,8.97125 13.7629,9.32852 C13.6128,9.683 13.3552,10.0709 13.0869,10.3462 C12.813,10.6163 12.4265,10.8761 12.0734,11.0274 C11.7172,11.1716 11.2607,11.263 10.8766,11.2669 L10.1234,11.2669 L10.1234,12.5676 L10.1209,12.5676 C10.1204,12.793 10.0633,13.0791 9.97807,13.262 C9.8627,13.466 9.61158,13.7198 9.40818,13.8382 L9.40824,13.8383 C9.4077,13.8386 9.40716,13.8388 9.40661,13.8391 C9.40621,13.8393 9.4058,13.8396 9.40539,13.8398 L9.40535,13.8397 C9.22958,13.9254 8.94505,13.9951 8.75059,14 L8.74789,14 C8.35724,13.9963 7.98473,13.8383 7.71035,13.5617 L5.39553,11.2669 L3.12342,11.2669 C2.29348,11.2669 1.49851,10.9352 0.913134,10.3462 C0.644826,10.0709 0.387187,9.683 0.23711,9.32852 C0.0941235,8.97125 0.00379528,8.51443 0,8.12943 L0,3.13746 C0,2.30689 0.327915,1.50939 0.913134,0.920639 Z M3.12342,1.59494 C2.71959,1.59494 2.33133,1.75628 2.04431,2.04503 C1.75713,2.33395 1.59494,2.72681 1.59494,3.13746 L1.59494,8.12943 C1.59114,8.35901 1.62114,8.51076 1.71193,8.72129 C1.79563,8.9346 1.88065,9.06264 2.04431,9.22185 C2.33133,9.5106 2.71959,9.67195 3.12342,9.67195 L5.72383,9.67195 C5.93413,9.67195 6.13592,9.75502 6.28527,9.90308 L8.52848,12.1269 L8.52848,10.4694 C8.52848,10.029 8.88552,9.67195 9.32595,9.67195 L10.8766,9.67195 C11.1034,9.67583 11.2517,9.64614 11.4599,9.55518 C11.6712,9.47132 11.7976,9.38635 11.9557,9.22185 C12.1193,9.06264 12.2044,8.9346 12.2881,8.72129 C12.3789,8.51076 12.4089,8.35901 12.4051,8.12943 L12.4051,3.13746 C12.4051,2.72681 12.2429,2.33394 11.9557,2.04503 C11.6687,1.75628 11.2804,1.59494 10.8766,1.59494 L3.12342,1.59494 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		calendar: {
			viewBox: '0 0 14 14',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M10.8889,5.5 L3.11111,5.5 L3.11111,7.05556 L10.8889,7.05556 L10.8889,5.5 Z M12.4444,1.05556 L11.6667,1.05556 L11.6667,0 L10.1111,0 L10.1111,1.05556 L3.88889,1.05556 L3.88889,0 L2.33333,0 L2.33333,1.05556 L1.55556,1.05556 C0.692222,1.05556 0.00777777,1.75556 0.00777777,2.61111 L0,12.5 C0,13.3556 0.692222,14 1.55556,14 L12.4444,14 C13.3,14 14,13.3556 14,12.5 L14,2.61111 C14,1.75556 13.3,1.05556 12.4444,1.05556 Z M12.4444,12.5 L1.55556,12.5 L1.55556,3.94444 L12.4444,3.94444 L12.4444,12.5 Z M8.55556,8.61111 L3.11111,8.61111 L3.11111,10.1667 L8.55556,10.1667 L8.55556,8.61111 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		time: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M17.134,15.81,12.5,11.561V6.5a1,1,0,0,0-2,0V12a1,1,0,0,0,.324.738l4.959,4.545a1.01,1.01,0,0,0,1.413-.061A1,1,0,0,0,17.134,15.81Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		attachment: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M21.843,3.455a6.961,6.961,0,0,0-9.846,0L1.619,13.832a5.128,5.128,0,0,0,7.252,7.252L17.3,12.653A3.293,3.293,0,1,0,12.646,8L7.457,13.184A1,1,0,1,0,8.871,14.6L14.06,9.409a1.294,1.294,0,0,1,1.829,1.83L7.457,19.67a3.128,3.128,0,0,1-4.424-4.424L13.411,4.869a4.962,4.962,0,1,1,7.018,7.018L12.646,19.67a1,1,0,1,0,1.414,1.414L21.843,13.3a6.96,6.96,0,0,0,0-9.846Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'up-down': {
			viewBox: '-1 -1 9 11',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M 3.5 0L 3.98809 -0.569442L 3.5 -0.987808L 3.01191 -0.569442L 3.5 0ZM 3.5 9L 3.01191 9.56944L 3.5 9.98781L 3.98809 9.56944L 3.5 9ZM 0.488094 3.56944L 3.98809 0.569442L 3.01191 -0.569442L -0.488094 2.43056L 0.488094 3.56944ZM 3.01191 0.569442L 6.51191 3.56944L 7.48809 2.43056L 3.98809 -0.569442L 3.01191 0.569442ZM -0.488094 6.56944L 3.01191 9.56944L 3.98809 8.43056L 0.488094 5.43056L -0.488094 6.56944ZM 3.98809 9.56944L 7.48809 6.56944L 6.51191 5.43056L 3.01191 8.43056L 3.98809 9.56944Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'at-sign': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12,.5A11.634,11.634,0,0,0,.262,12,11.634,11.634,0,0,0,12,23.5a11.836,11.836,0,0,0,6.624-2,1.25,1.25,0,1,0-1.393-2.076A9.34,9.34,0,0,1,12,21a9.132,9.132,0,0,1-9.238-9A9.132,9.132,0,0,1,12,3a9.132,9.132,0,0,1,9.238,9v.891a1.943,1.943,0,0,1-3.884,0V12A5.355,5.355,0,1,0,12,17.261a5.376,5.376,0,0,0,3.861-1.634,4.438,4.438,0,0,0,7.877-2.736V12A11.634,11.634,0,0,0,12,.5Zm0,14.261A2.763,2.763,0,1,1,14.854,12,2.812,2.812,0,0,1,12,14.761Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-left': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M10.416,12a2.643,2.643,0,0,1,.775-1.875L20.732.584a1.768,1.768,0,0,1,2.5,2.5l-8.739,8.739a.25.25,0,0,0,0,.354l8.739,8.739a1.768,1.768,0,0,1-2.5,2.5l-9.541-9.541A2.643,2.643,0,0,1,10.416,12Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M.25,12a2.643,2.643,0,0,1,.775-1.875L10.566.584a1.768,1.768,0,0,1,2.5,2.5L4.327,11.823a.25.25,0,0,0,0,.354l8.739,8.739a1.768,1.768,0,0,1-2.5,2.5L1.025,13.875A2.643,2.643,0,0,1,.25,12Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-right': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M13.584,12a2.643,2.643,0,0,1-.775,1.875L3.268,23.416a1.768,1.768,0,0,1-2.5-2.5l8.739-8.739a.25.25,0,0,0,0-.354L.768,3.084a1.768,1.768,0,0,1,2.5-2.5l9.541,9.541A2.643,2.643,0,0,1,13.584,12Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M23.75,12a2.643,2.643,0,0,1-.775,1.875l-9.541,9.541a1.768,1.768,0,0,1-2.5-2.5l8.739-8.739a.25.25,0,0,0,0-.354L10.934,3.084a1.768,1.768,0,0,1,2.5-2.5l9.541,9.541A2.643,2.643,0,0,1,23.75,12Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		star: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M23.555,8.729a1.505,1.505,0,0,0-1.406-.98H16.062a.5.5,0,0,1-.472-.334L13.405,1.222a1.5,1.5,0,0,0-2.81,0l-.005.016L8.41,7.415a.5.5,0,0,1-.471.334H1.85A1.5,1.5,0,0,0,.887,10.4l5.184,4.3a.5.5,0,0,1,.155.543L4.048,21.774a1.5,1.5,0,0,0,2.31,1.684l5.346-3.92a.5.5,0,0,1,.591,0l5.344,3.919a1.5,1.5,0,0,0,2.312-1.683l-2.178-6.535a.5.5,0,0,1,.155-.543l5.194-4.306A1.5,1.5,0,0,0,23.555,8.729Z',
				},
				_owner: null,
				_store: {},
			},
		},
		email: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M11.114,14.556a1.252,1.252,0,0,0,1.768,0L22.568,4.87a.5.5,0,0,0-.281-.849A1.966,1.966,0,0,0,22,4H2a1.966,1.966,0,0,0-.289.021.5.5,0,0,0-.281.849Z',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								d: 'M23.888,5.832a.182.182,0,0,0-.2.039l-6.2,6.2a.251.251,0,0,0,0,.354l5.043,5.043a.75.75,0,1,1-1.06,1.061l-5.043-5.043a.25.25,0,0,0-.354,0l-2.129,2.129a2.75,2.75,0,0,1-3.888,0L7.926,13.488a.251.251,0,0,0-.354,0L2.529,18.531a.75.75,0,0,1-1.06-1.061l5.043-5.043a.251.251,0,0,0,0-.354l-6.2-6.2a.18.18,0,0,0-.2-.039A.182.182,0,0,0,0,6V18a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V6A.181.181,0,0,0,23.888,5.832Z',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		phone: {
			viewBox: '0 0 14 14',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M2.20731,0.0127209 C2.1105,-0.0066419 1.99432,-0.00664663 1.91687,0.032079 C0.871279,0.438698 0.212942,1.92964 0.0580392,2.95587 C-0.426031,6.28627 2.20731,9.17133 4.62766,11.0689 C6.77694,12.7534 10.9012,15.5223 13.3409,12.8503 C13.6507,12.5211 14.0186,12.037 13.9993,11.553 C13.9412,10.7397 13.186,10.1588 12.6051,9.71349 C12.1598,9.38432 11.2304,8.47427 10.6495,8.49363 C10.1267,8.51299 9.79754,9.05515 9.46837,9.38432 L8.88748,9.96521 C8.79067,10.062 7.55145,9.24878 7.41591,9.15197 C6.91248,8.8228 6.4284,8.45491 6.00242,8.04829 C5.57644,7.64167 5.18919,7.19632 4.86002,6.73161 C4.7632,6.59607 3.96933,5.41495 4.04678,5.31813 C4.04678,5.31813 4.72448,4.58234 4.91811,4.2919 C5.32473,3.67229 5.63453,3.18822 5.16982,2.45243 C4.99556,2.18135 4.78257,1.96836 4.55021,1.73601 C4.14359,1.34875 3.73698,0.942131 3.27227,0.612963 C3.02055,0.419335 2.59457,0.0708094 2.20731,0.0127209 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		spinner: {
			path: {
				key: null,
				ref: null,
				props: {
					children: [
						{
							type: 'defs',
							key: null,
							ref: null,
							props: {
								children: {
									type: 'linearGradient',
									key: null,
									ref: null,
									props: {
										x1: '28.154%',
										y1: '63.74%',
										x2: '74.629%',
										y2: '17.783%',
										id: 'a',
										children: [
											{
												type: 'stop',
												key: null,
												ref: null,
												props: { stopColor: 'currentColor', offset: '0%' },
												_owner: null,
												_store: {},
											},
											{
												type: 'stop',
												key: null,
												ref: null,
												props: {
													stopColor: '#fff',
													stopOpacity: '0',
													offset: '100%',
												},
												_owner: null,
												_store: {},
											},
										],
									},
									_owner: null,
									_store: {},
								},
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'g',
							key: null,
							ref: null,
							props: {
								transform: 'translate(2)',
								fill: 'none',
								children: [
									{
										type: 'circle',
										key: null,
										ref: null,
										props: {
											stroke: 'url(#a)',
											strokeWidth: '4',
											cx: '10',
											cy: '12',
											r: '10',
										},
										_owner: null,
										_store: {},
									},
									{
										type: 'path',
										key: null,
										ref: null,
										props: {
											d: 'M10 2C4.477 2 0 6.477 0 12',
											stroke: 'currentColor',
											strokeWidth: '4',
										},
										_owner: null,
										_store: {},
									},
									{
										type: 'rect',
										key: null,
										ref: null,
										props: {
											fill: 'currentColor',
											x: '8',
											width: '4',
											height: '4',
											rx: '8',
										},
										_owner: null,
										_store: {},
									},
								],
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		'drag-handle': {
			viewBox: '0 0 10 10',
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z',
				},
				_owner: null,
				_store: {},
			},
		},
		close: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'small-close': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					d: 'M9.41 8l2.29-2.29c.19-.18.3-.43.3-.71a1.003 1.003 0 0 0-1.71-.71L8 6.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42L6.59 8 4.3 10.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71L8 9.41l2.29 2.29c.18.19.43.3.71.3a1.003 1.003 0 0 0 .71-1.71L9.41 8z',
					fillRule: 'evenodd',
					fill: 'currentColor',
				},
				_owner: null,
				_store: {},
			},
			viewBox: '0 0 16 16',
		},
		'not-allowed': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z',
				},
				_owner: null,
				_store: {},
			},
		},
		'triangle-down': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z',
				},
				_owner: null,
				_store: {},
			},
		},
		'triangle-up': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z',
				},
				_owner: null,
				_store: {},
			},
		},
		bell: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z',
				},
				_owner: null,
				_store: {},
			},
		},
		'info-outline': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					stroke: 'currentColor',
					strokeLinecap: 'square',
					strokeWidth: '2',
					children: [
						{
							type: 'circle',
							key: null,
							ref: null,
							props: {
								cx: '12',
								cy: '12',
								fill: 'none',
								r: '11',
								stroke: 'currentColor',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'line',
							key: null,
							ref: null,
							props: {
								fill: 'none',
								x1: '11.959',
								x2: '11.959',
								y1: '11',
								y2: '17',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'circle',
							key: null,
							ref: null,
							props: { cx: '11.959', cy: '7', r: '1', stroke: 'none' },
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		info: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm.25,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12.25,5ZM14.5,18.5h-4a1,1,0,0,1,0-2h.75a.25.25,0,0,0,.25-.25v-4.5a.25.25,0,0,0-.25-.25H10.5a1,1,0,0,1,0-2h1a2,2,0,0,1,2,2v4.75a.25.25,0,0,0,.25.25h.75a1,1,0,1,1,0,2Z',
				},
				_owner: null,
				_store: {},
			},
		},
		question: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,19a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,19Zm1.6-6.08a1,1,0,0,0-.6.917,1,1,0,1,1-2,0,3,3,0,0,1,1.8-2.75A2,2,0,1,0,10,9.255a1,1,0,1,1-2,0,4,4,0,1,1,5.6,3.666Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'question-outline': {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					stroke: 'currentColor',
					strokeWidth: '1.5',
					children: [
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								strokeLinecap: 'full',
								fill: 'none',
								d: 'M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'path',
							key: null,
							ref: null,
							props: {
								fill: 'none',
								strokeLinecap: 'full',
								d: 'M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0',
							},
							_owner: null,
							_store: {},
						},
						{
							type: 'circle',
							key: null,
							ref: null,
							props: {
								fill: 'none',
								strokeMiterlimit: '10',
								cx: '12',
								cy: '12',
								r: '11.25',
							},
							_owner: null,
							_store: {},
						},
					],
				},
				_owner: null,
				_store: {},
			},
		},
		warning: {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'warning-2': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M23.119,20,13.772,2.15h0a2,2,0,0,0-3.543,0L.881,20a2,2,0,0,0,1.772,2.928H21.347A2,2,0,0,0,23.119,20ZM11,8.423a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Zm1.05,11.51h-.028a1.528,1.528,0,0,1-1.522-1.47,1.476,1.476,0,0,1,1.448-1.53h.028A1.527,1.527,0,0,1,13.5,18.4,1.475,1.475,0,0,1,12.05,19.933Z',
				},
				_owner: null,
				_store: {},
			},
		},
		'arrow-up-down': {
			path: {
				type: 'path',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					d: 'M11.891 9.992a1 1 0 1 1 1.416 1.415l-4.3 4.3a1 1 0 0 1-1.414 0l-4.3-4.3A1 1 0 0 1 4.71 9.992l3.59 3.591 3.591-3.591zm0-3.984L8.3 2.417 4.709 6.008a1 1 0 0 1-1.416-1.415l4.3-4.3a1 1 0 0 1 1.414 0l4.3 4.3a1 1 0 1 1-1.416 1.415z',
				},
				_owner: null,
				_store: {},
			},
			viewBox: '0 0 16 16',
		},
		check: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: {
						type: 'polygon',
						key: null,
						ref: null,
						props: {
							points: '5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039',
						},
						_owner: null,
						_store: {},
					},
				},
				_owner: null,
				_store: {},
			},
			viewBox: '0 0 14 14',
		},
		minus: {
			path: {
				type: 'g',
				key: null,
				ref: null,
				props: {
					fill: 'currentColor',
					children: {
						type: 'rect',
						key: null,
						ref: null,
						props: { height: '4', width: '20', x: '2', y: '10' },
						_owner: null,
						_store: {},
					},
				},
				_owner: null,
				_store: {},
			},
		},
	},
};

export default theme;
