const configurationDefaultValues = {
	releaseTime: 'PT25S',
	extendedReleaseTime: 'PT15S',
	delayTimeBeforeRelock: 'PT10S',
	openTime: 'PT20S',
	extendedOpenTime: 'PT15S',
	preAlarmTime: 'PT10S',
};

const portal = {
	base: {
		name: true,
		description: true,
		type: 'DOOR',
		configuration: (data) => {
			if (data.configuration) {
				return {
					...configurationDefaultValues,
					openTime: data.configuration.openTime,
					additionalSettings: {
						timeSlots: data.configuration.additionalSettings.timeSlots,
						authenticationProfileId: data.configuration.additionalSettings.authenticationProfileId,
						...data.configuration.additionalSettings.additionalData,
					},
				};
			}
			return configurationDefaultValues;
		},
	},
	update: {
		version: false,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'site', ':siteId:', 'portal'],
};

export default portal;
