import React from 'react';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { Button, PageHeader } from '~/components';
import { getAccessProfilesKey, useAccessProfiles } from '~/hooks/data/access-profiles/useAccessProfiles';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AccessProfilesPageHeader = ({ onAddNewButtonClick }) => {
	const { translate } = useLocale();
	const { queryParams } = useQueryParams();
	const { data: user } = useUserAuthData();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		isLoading: isLoadingAccessProfiles,
		isValidating: isValidatingAccessProfiles,
		mutate: refetchAccessProfiles,
	} = useAccessProfiles(
		getAccessProfilesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
		})
	);

	const onDataRefetch = () => {
		refetchAccessProfiles();
	};

	return (
		<PageHeader title={translate.byKey('access_profiles')} icon="reports">
			<Button
				disabled={isLoadingAccessProfiles || isValidatingAccessProfiles}
				type="primary"
				icon={<ReloadOutlined />}
				onClick={onDataRefetch}
			>
				{translate.byKey('refresh')}
			</Button>

			{hasActionPermissions(user, userConstants.screens.ACCESS_PROFILES, userConstants.actions.CREATE) ? (
				<Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddNewButtonClick}>
					{translate.byKey('add_new')}
				</Button>
			) : null}
		</PageHeader>
	);
};
