import React from 'react';

const viewBox = '0 0 496 496';

const path = (
	<g fill="currentColor">
		<path d="M92.688 0L48 44.688V80H0v112h48v128H0v64h48v112h448V0H92.688zM16 176V96h96v80H16zm0 192v-32h96v32H16zm464 112H64v-96h64v-24h16v-16h-16v-24H64V192h64v-16h16v-16h-16v-48h16V96h-16V80H64V51.312L99.312 16H480v464z" />
		<path d="M304 448h112v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16V96h-16V80h16V64h-16V48H304v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16zm16-384h80v368h-80V64z" />
		<circle cx={360} cy={88} r={8} />
		<path d="M256 64h-64v48h64V64zm-16 32h-32V80h32v16zM256 144h-64v48h64v-48zm-16 32h-32v-16h32v16zM144 216v16h-16v-16h-16v16H96v48h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v-48h-16v-16h-16v16h-16v-16h-16zm48 32v16h-80v-16h80zM112 400c-17.648 0-32 14.352-32 32s14.352 32 32 32 32-14.352 32-32-14.352-32-32-32zm0 48c-8.824 0-16-7.176-16-16s7.176-16 16-16c8.816 0 16 7.176 16 16s-7.184 16-16 16zM192 400c-17.648 0-32 14.352-32 32s14.352 32 32 32 32-14.352 32-32-14.352-32-32-32zm0 48c-8.824 0-16-7.176-16-16s7.176-16 16-16c8.816 0 16 7.176 16 16s-7.184 16-16 16zM176 336h16v32h-16zM208 336h16v32h-16zM240 336h16v32h-16z" />
		<circle cx={96} cy={48} r={8} />
	</g>
);

const controller = {
	path,
	viewBox,
};

export default controller;
