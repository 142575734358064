/**
 * Enum for controller lock states.
 * @readonly
 * @enum {string}
 */
export const ControllerLockState = {
	NormalMode: 'normalMode',
	EmergencyUnlocked: 'emergencyUnlocked',
	EmergencyLocked: 'emergencyLocked',
	EmergencyUnlockPending: 'emergencyUnlockPending',
	EmergencyLockPending: 'emergencyLockPending',
	ResetEmergencyPending: 'resetEmergencyPending',
};
