import React, { useState } from 'react';
import { Modal, Space, Input, Form } from 'antd';
import { Text } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { usePostApi } from '~/screens/_shared/useApi';
import systemConfig from '~/screens/_shared/systemConfig';
import { showSuccessToast } from '~/screens/_shared/toast';
import { css } from '@emotion/core';
import { filterNumbersFromString } from '~/utils/filter';

const { Item } = Form;

export const ResetPasswordModal = ({ onCancel }) => {
	const [form] = Form.useForm();
	const [resetPassword] = usePostApi('/authentication/reset-password-request', () => {}, {
		removeDomainKey: true,
	});
	const [resetPasswordConfirm] = usePostApi('/authentication/reset-password-request/confirm', () => {}, {
		removeDomainKey: true,
	});
	const { translate } = useLocale();
	const [isLoading, setIsLoading] = useState(false);
	const [isConfirmationCodeSent, setIsConfirmationCodeSent] = useState(false);

	const onConfirmationCodeSend = () => {
		form.validateFields().then((values) => {
			handleResetPassword(values.email);
		});
	};

	const handleResetPassword = async (email) => {
		setIsLoading(true);
		try {
			await resetPassword({
				username: email,
				__contentType: 'application/vnd.assaabloy.msfss.authentication-8.0+json',
				ignoreGlobalHandlers: true,
				params: {
					'client-id': systemConfig.emeaClientId,
				},
			});

			setIsConfirmationCodeSent(true);
		} catch (error) {
			Modal.error({
				title: translate.byKey('password_reset_failed_title'),
				content: translate.byKey('please_try_again_later'),
				okText: translate.byKey('ok'),
				destroyOnClose: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onPasswordChangeSubmit = () => {
		form.validateFields().then((values) => {
			// Email field removed from DOM and thus it is not validated again, but it is present in form values
			createNewPassword(form.getFieldValue('email'), values.newPassword, values.confirmationCode);
		});
	};

	const createNewPassword = async (email, newPassword, confirmationCode) => {
		setIsLoading(true);
		try {
			await resetPasswordConfirm({
				username: email,
				newPassword,
				confirmationCode,
				__contentType: 'application/vnd.assaabloy.msfss.authentication-8.0+json',
				params: {
					'client-id': systemConfig.emeaClientId,
				},
			});
			showSuccessToast(translate.byKey('your_password_has_been_created_successfully'));
			onCancel();
		} catch (error) {
			Modal.error({
				title: translate.byKey('password_reset_failed_title'),
				content: translate.byKey('please_try_again_later'),
				okText: translate.byKey('ok'),
				destroyOnClose: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal
			title={translate.byKey('reset_password')}
			open
			onOk={isConfirmationCodeSent ? onPasswordChangeSubmit : onConfirmationCodeSend}
			onCancel={onCancel}
			okText={isConfirmationCodeSent ? translate.byKey('reset') : translate.byKey('next')}
			cancelText={translate.byKey('cancel')}
			confirmLoading={isLoading}
		>
			<Form
				form={form}
				name="forgot_password"
				layout="vertical"
				onFinish={isConfirmationCodeSent ? onPasswordChangeSubmit : onConfirmationCodeSend}
				style={{ margin: 10 }}
			>
				{!isConfirmationCodeSent ? (
					<Space direction="vertical" style={{ display: 'flex' }}>
						<Text>{translate.byKey('enter_username_to_reset_password')}</Text>
						<Item
							required
							labelAlign="right"
							name="email"
							rules={[
								{ required: true, message: translate.byKey('email_is_required') },
								{
									type: 'email',
									message: translate.byKey('email_format_not_valid'),
								},
							]}
							validateTrigger="onBlur"
						>
							<Input css={itemStyles} prefix={<MailOutlined />} placeholder={translate.byKey('email_address')} />
						</Item>
					</Space>
				) : (
					<>
						<Item
							label={translate.byKey('new_password')}
							labelAlign="right"
							name="newPassword"
							hasFeedback
							rules={[
								{
									required: true,
									message: translate.byKeyFormatted('field_is_required_message', {
										fieldName: translate.byKey('new_password'),
									}),
								},
								{
									pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/,
									message: translate.byKey(
										'your_password_must_be_at_least_12_characters_long_and_must_include_at_least_three_of_the_four_character_sets'
									),
								},
							]}
							validateTrigger="onBlur"
						>
							<Input.Password maxLength={100} type="password" css={itemStyles} prefix={<LockOutlined />} />
						</Item>
						<Item
							label={translate.byKey('confirm_password')}
							labelAlign="right"
							name="confirmPassword"
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: translate.byKeyFormatted('field_is_required_message', {
										fieldName: translate.byKey('confirm_password'),
									}),
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('newPassword') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(translate.byKey('password_that_you_entered_does_not_match')));
									},
								}),
							]}
						>
							<Input.Password type="password" css={itemStyles} prefix={<LockOutlined />} />
						</Item>
						<Item
							label={translate.byKey('confirmation_code')}
							name="confirmationCode"
							hasFeedback
							rules={[
								{
									required: true,
									message: translate.byKeyFormatted('field_is_required_message', {
										fieldName: translate.byKey('confirmation_code'),
									}),
								},
								{
									len: 6,
									message: translate.byKeyFormatted('field_value_must_be_long_message', {
										fieldName: translate.byKey('confirmation_code'),
										length: 6,
									}),
								},
							]}
							normalize={(value) => filterNumbersFromString(value).substring(0, 6)}
						>
							<Input css={itemStyles} />
						</Item>
					</>
				)}
			</Form>
		</Modal>
	);
};

const itemStyles = css({
	borderRadius: '4px',
});
