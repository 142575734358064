import React, { useState } from 'react';
import { Alert, Checkbox, Space } from 'antd';
import { useHolidays } from '~/hooks/features/holidays/useHolidays';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const getDoNotShowAgainKey = (user, systemId) => `do_not_show_holiday_notification_${user.username}-${systemId}`;

export const HolidayAlert = () => {
	const { translate } = useLocale();

	const { getTodaysHoliday, data } = useHolidays();
	const todaysHoliday = getTodaysHoliday();

	const {
		data: {
			system: { systemId },
		},
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();

	const [doNotShowTodayChecked, setDoNotShowTodayChecked] = useState(false);

	if (!Boolean(data)) {
		return null;
	}

	if (!Boolean(todaysHoliday)) {
		localStorage.removeItem(getDoNotShowAgainKey(user, systemId));
		return null;
	}

	if (Boolean(localStorage.getItem(getDoNotShowAgainKey(user, systemId)))) {
		return null;
	}

	const onAlertClose = () => {
		if (doNotShowTodayChecked) {
			localStorage.setItem(getDoNotShowAgainKey(user, systemId), true);
		}
	};

	const onCheckboxChange = (e) => {
		setDoNotShowTodayChecked(e.target.checked);
	};

	return (
		<Alert
			banner
			closable
			type="info"
			onClose={onAlertClose}
			message={
				<Space css={spaceStyle}>
					{translate.byKeyFormatted('today_is_holiday', {
						holiday: todaysHoliday.name,
					})}
					<Checkbox css={checkboxStyle} checked={doNotShowTodayChecked} onChange={onCheckboxChange}>
						{translate.byKey('do_not_show_again_today')}
					</Checkbox>
				</Space>
			}
		/>
	);
};

const checkboxStyle = css({
	marginRight: 50,
});

const spaceStyle = css({
	width: '100%',
	justifyContent: 'space-between',
});
