import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useSiteControllers = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.controller,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , , protocol, detailLevel, type] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					protocol,
					'detail-level': detailLevel,
					type,
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getSiteControllersKey = ({ customerId, systemId, siteId, protocol, detailLevel, type }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${protocol}${separator}\
${detailLevel}${separator}\
${type}${separator}\
siteControllers`
		: null;
