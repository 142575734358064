import { CredentialRecognitionType } from '~/constants/Credential';
import { formatHexValuesAsString, isPulseKey } from '~/components/features/people/People.utils';

export const getCorrectCredentialNumberAsDecimal = (credential) => {
	const credentialNumber = credential.credentialIdentifiers.find((identifier) => {
		return identifier.recognitionType === CredentialRecognitionType.CREDENTIAL_NUMBER;
	});

	return formatHexValuesAsString([credentialNumber.hexValue], credentialNumber, credential);
};

export const mapDataToCredentialHoldersAndCredentials = (data) => {
	const credentialHoldersWithCredentials = {};

	data.credentials.forEach((credential) => {
		const { credentialHolder, credentialId } = credential;
		const credentialHolderId = credentialHolder.credentialHolderId;
		const isExistingCredentialHolderEntry = Boolean(credentialHoldersWithCredentials[credentialHolderId]);

		if (isExistingCredentialHolderEntry) {
			credentialHoldersWithCredentials[credentialHolderId] = {
				...credentialHoldersWithCredentials[credentialHolderId],
				credentials: [
					...credentialHoldersWithCredentials[credentialHolderId].credentials,
					{
						credentialId,
						credentialNumber: getCorrectCredentialNumberAsDecimal(credential),
						isPulse: isPulseKey(credential),
					},
				],
			};
		} else {
			credentialHoldersWithCredentials[credentialHolderId] = {
				credentialHolderId,
				credentialHolderName: credentialHolder.name,
				credentials: [
					{
						credentialId,
						credentialNumber: getCorrectCredentialNumberAsDecimal(credential),
						isPulse: isPulseKey(credential),
					},
				],
			};
		}
	});

	return Object.values(credentialHoldersWithCredentials).sort(
		({ credentialHolderName: credentialHolderNameOne }, { credentialHolderName: credentialHolderNameTwo }) =>
			credentialHolderNameOne.toLocaleLowerCase().localeCompare(credentialHolderNameTwo.toLocaleLowerCase())
	);
};
