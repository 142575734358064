import { translator as translate } from '~/screens/_shared/AppLocale';

const formHelp = () => `
<p><strong> ${translate.byKey('door_name')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('door_name_is_a_required_field')} </p>
<p><strong> ${translate.byKey('door_modes')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('there_are_six_door_modes_available')}</p>
<p style="padding-left: 40px;"> <strong> ${translate.byKey(
	'card_only'
)}</strong></p><p style="padding-left: 40px;"> ${translate.byKey(
	'default_mode_is_card_only_it_is_automatically_assigned_time_patterns'
)} </p><p style="padding-left: 30px;"> ${translate.byKey('time_pattern_on_the_grid_dragover_days_and_times')} </p>

<p style="padding-left: 30px;">${translate.byKey('choose_from_the_following_door_modes')}</p>
<p style="padding-left:40px;"><strong> ${translate.byKey('card_and_pin')}</strong></p> 
<p style="padding-left: 40px;">${translate.byKey('credential_and_pin_number_is_required_for_access')} </p>

<p style="padding-left:40px;"> <strong>${translate.byKey('pac_label')}</strong></p>
<p style="padding-left:40px;">
${translate.byKey('personnel_access_code')} </p>

<p style="padding-left:40px;"> <strong>${translate.byKey('locked_label')}</strong></p>
<p style="padding-left:40px;">
${translate.byKey('sets_the_door_to_a_locked_state')} </p>

<p style="padding-left:40px;"><strong> ${translate.byKey('unlocked_label')}</strong></p>
<p style="padding-left:40px;">${translate.byKey('sets_a_period_of_time_door_is_unlocked')} </p>

<p style="padding-left:40px;"> <strong>${translate.byKey('open_on_first_card_label')}</strong></p>
<p style="padding-left: 40px;">
${translate.byKey('sets_a_period_time_after_user_with_valid_access_uses_their_credential')} </p>

<p style="padding-left:40px;"> <strong>${translate.byKey('office_mode')}</strong></p>
<p style="padding-left: 40px;">
${translate.byKey('office_mode_help_message')} </p>

<p><strong> ${translate.byKey('cancel')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey('cancel_button_closes_the_dialog_window_no_changes_are_saved')} </p>
<p><strong> ${translate.byKey('submit')}</strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'submit_buttons_closes_the_window_saving_any_changes_made_to_property_fields'
)}</p>
`;

export default formHelp;
