import { useLocale } from '~/screens/_shared/AppLocale';
import React from 'react';
import { isCredentialBitLengthValid } from '../../../../People.utils';
import { Button, Card, Checkbox, Col, Row, Select } from 'antd';
import { Input, Item } from '~/components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { ensureCredentialValueUpperLimit, removeUnwantedCharacters } from '../../../../constants/credentialConstraints';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const { Option } = Select;

const credentialTypes = [
	{ credentialTypeId: 'offline_pulse', name: 'Pulse Key' },
	{ credentialTypeId: 'online_generic', name: 'Credential' },
];

const removeSpaces = (value) =>
	String(value || '')
		.split(' ')
		.join('');

export const CredentialForm = ({ form, selected, field, removeItem, blocked, disabled }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const hasSavedCredential = () => {
		if (form.getFieldValue('credentials')[field.name]) {
			return form.getFieldValue('credentials')[field.name].credentialId;
		}
		return false;
	};

	const validateCredentialNumberInput = (value) => {
		return new Promise((resolve, reject) => {
			const formCredentials = form
				.getFieldValue('credentials')
				.map((cred) => cred?.hexValue)
				.filter((hex) => hex === value);

			if (formCredentials.length >= 2) {
				reject(translate.byKey('you_cant_have_a_duplicate_value'));
			}

			if (isCredentialBitLengthValid(removeSpaces(value), false) === true) {
				resolve();
			}
			reject(translate.byKey('value_is_invalid_empty_or_too_long'));
		});
	};

	const onCredentialChange = () => {
		const currentCredential = form.getFieldValue('credentials')[field.name];
		const sanitizedCredentialHexValue = ensureCredentialValueUpperLimit(
			removeUnwantedCharacters(removeSpaces(currentCredential.hexValue))
		);
		currentCredential.hexValue = sanitizedCredentialHexValue;

		if (currentCredential && currentCredential.credentialId) {
			const credentialsToDelete = form.getFieldValue('credentialsToDelete') || [];
			credentialsToDelete.push(currentCredential.credentialId);
			form.setFieldsValue({ credentialsToDelete });
			delete currentCredential.credentialId;
		}
	};

	return (
		<Card hoverable style={{ marginBottom: '1rem' }}>
			<Item noStyle required={false} key={field.name}>
				<Row>
					<Col style={{ marginLeft: 'auto' }}>
						{!blocked && !disabled && (
							<Button
								style={{ marginBottom: '-1.5rem' }}
								danger
								type="link"
								onClick={() => {
									removeItem(field);
								}}
							>
								<MinusCircleOutlined className="anticon-remove" />
							</Button>
						)}
					</Col>
				</Row>
				<Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
					<Col span={24}>
						<Item
							label={translate.byKey('credential_type')}
							placeholder={translate.byKey('select_credential_type')}
							name={[field.name, 'credentialTypeId']}
							rules={[
								{
									required: true,
									message: translate.byKey('credential_type_is_required_v2'),
								},
							]}
						>
							<Select
								disabled={blocked || disabled || hasSavedCredential()}
								placeholder={translate.byKey('select_credential_type')}
								allowClear
							>
								{credentialTypes.map((credentialType) => {
									return (
										<Option value={credentialType.credentialTypeId}>{translate.byValue(credentialType.name)}</Option>
									);
								})}
							</Select>
						</Item>
						<Item name={[field.name, 'credentialId']} fieldKey={[field.fieldKey, 'credentialId']} noStyle>
							<Input type="hidden" />
						</Item>
						<Item name={[field.name, 'credentialHolderId']} fieldKey={[field.fieldKey, 'credentialHolderId']} noStyle>
							<Input type="hidden" />
						</Item>
						<Item name={[field.name, 'version']} fieldKey={[field.fieldKey, 'version']} noStyle>
							<Input type="hidden" />
						</Item>
					</Col>
				</Row>

				<Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
					<Col span={24}>
						<Item
							className="card-form-input"
							name={[field.name, 'hexValue']}
							fieldKey={[field.fieldKey, 'hexValue']}
							validateTrigger={['onChange']}
							label={translate.byKeyFormatted('credential_number_formatted_v2', [''])}
							rules={[
								{
									required: true,
									whitespace: true,
									message: ' ',
								},
								() => ({
									validator(rule, value) {
										return validateCredentialNumberInput(value);
									},
								}),
							]}
						>
							<Input
								disabled={blocked || disabled}
								onChange={onCredentialChange}
								placeholder={translate.byKey('credential_number_v2')}
							/>
						</Item>
					</Col>
				</Row>

				{hasSavedCredential() &&
					selected.label &&
					hasActionPermissions(user, userConstants.screens.CARDS, userConstants.actions.CREATE) && (
						<Item name={[field.name, 'isNewCredentialBlocked']} valuePropName="checked">
							<Checkbox className="card-form-input" disabled={disabled}>
								{translate.byKey(!blocked ? 'block_v2' : 'blocked_v2')}
							</Checkbox>
						</Item>
					)}
			</Item>
			<Item name="isBlocked" shouldUpdate noStyle>
				<Input type="hidden" />
			</Item>
		</Card>
	);
};
