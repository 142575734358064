const specialDay = {
	base: {
		name: true,
		description: false,
		dayType: 'PublicHoliday',
		start: true,
		end: true,
		recurring: true,
	},
	update: {
		version: false,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'calendar', ':calendarId:', 'special-day'],
};

export default specialDay;
