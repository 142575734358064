import { Form } from 'antd';
import React, { useEffect, useState } from 'react';

import { Modal, Spin } from '~/components';
import mappers from '~/screens/_shared/mappers';
import { useGetApi, useListApi } from '~/screens/_shared/useApi';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { useTheme } from 'emotion-theming';
import { useCreateReader, useDeleteReader, useUpdateReader } from '~/screens/Doors/hooks';
import { useLocale } from '~/screens/_shared/AppLocale';
import {
	convertPeripheralDevice,
	getReaderFormDefaults,
	getSelectedFixedAddress,
	isAperioDevice,
	readerIsSaved,
} from '~/screens/Doors/shared';
import { showErrorToast, showSuccessToast } from '~/screens/_shared/toast';
import { getAllReaders } from '~/screens/Doors/OfflineDoorForm';
import { ReaderInputs } from '~/screens/Doors/ReaderInputs';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const ReaderForm = ({ visible, onUpdated, selected, onClose, selectedController = {} }) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	const [readerType, setReaderType] = useState('');
	const [title, setTitle] = useState('');
	const [allReaders, setAllReaders] = useState([]);
	const [selectedReader, setSelectedReader] = useState({});
	const [selectedFixedAddress, setSelectedFixedAddress] = useState(getSelectedFixedAddress(selectedReader));

	const [deleteReader, loadingDeleteReader, deleteReaderCurrentStep] = useDeleteReader();
	const [createReader, loadingCreateReader, createReaderCurrentStep] = useCreateReader();
	const [updateReader, loadingUpdateReader, updateReaderCurrentStep] = useUpdateReader();

	const [portal, setPortal] = useState({});

	const [getPortal, loadingPortal] = useGetApi(mappers.portal);
	const [getAssignedReaders, loadingAssignedReaders] = useListApi(mappers.peripheralDevice);

	function updateState(changedValues) {
		if ('fixedAddress' in changedValues) {
			setSelectedFixedAddress(changedValues.fixedAddress);
		}
	}

	/**
	 * Add a reader to this door
	 */
	async function onReaderFormUpdate(values) {
		const { portal, formattedValues } = values;
		const { isEntry } = formattedValues;

		if (formattedValues.logicalDevices) {
			formattedValues.logicalDevices = JSON.parse(formattedValues.logicalDevices);
		}

		if (formattedValues.deviceInfo) {
			formattedValues.deviceInfo = JSON.parse(formattedValues.deviceInfo);
		}

		// Create
		if (!values.formattedValues.version) {
			try {
				await createReader(
					{
						portal,
						isEntry,
						formattedValues,
					},
					isAperioDevice(getSelectedFixedAddress(selectedReader)) ? 'aperio_online' : 'hid_online'
				);
				onUpdated(true);
				showSuccessToast(translate.byKey('reader_added_successfully'));
			} catch {
				onUpdated();
				showErrorToast(translate.byKey('failed_to_delete_reader'));
			}
		}
		// Update
		else {
			try {
				await updateReader(
					{ portal, isEntry, formattedValues },
					isAperioDevice(getSelectedFixedAddress(selectedReader)) ? 'aperio_online' : 'hid_online'
				);
				onUpdated(true);

				showSuccessToast(translate.byKey('reader_updated_successfully'));
			} catch {
				onUpdated();
				showErrorToast(translate.byKey('failed_to_update_reader'));
			}
		}
	}

	/**
	 * Delete the reader
	 */
	async function onReaderFormDelete(peripheralDevice) {
		try {
			await deleteReader(peripheralDevice);
			onUpdated(true);
			showSuccessToast(translate.byKey('reader_deleted_successfully'));
		} catch {
			onUpdated();
			showErrorToast(translate.byKey('failed_to_delete_reader'));
		}
	}

	useEffect(() => {
		if (visible && selected && selected.portalId) {
			form.resetFields();
			setTitle('');
			setSelectedReader({});

			const { portalId, type } = selected;
			const readerType = type;
			setReaderType(type);
			getPortal({ portalId })
				.then(async (portal) => {
					setPortal(portal);
					const allReaders = await getAssignedReaders({
						params: {
							assignedToPortal: 'true',
							'detail-level': 'FULL',
						},
					});
					setAllReaders(allReaders);

					let expectedPortalSide = readerType === 'ENTRY' ? 'OUTSIDE' : 'INSIDE';
					const selectedReader = allReaders
						.filter((reader) => reader.mapping.portal.portalId === portal.portalId)
						.find((reader) => {
							if (reader && reader.logicalDevices && reader.logicalDevices.length > 0) {
								return reader.logicalDevices.some((ld) => ld.portalSide === expectedPortalSide);
							}
							return false;
						});

					const title =
						readerType === 'ENTRY'
							? translate.byKeyFormatted('reader_form_entry_title', { doorName: portal.name })
							: translate.byKeyFormatted('reader_form_exit_title', { doorName: portal.name });

					setTitle(title);

					if (selectedReader) {
						form.setFieldsValue(convertPeripheralDevice(selectedReader, portal, readerType));
						setSelectedReader(selectedReader);
						setSelectedFixedAddress(getSelectedFixedAddress(selectedReader));
					} else {
						form.setFieldsValue(getReaderFormDefaults(readerType, selectedController.controllerId, portalId));
					}
				})
				.catch(() => {
					setPortal({});
				});
		} else if (visible) {
			setSelectedReader({});
			form.resetFields();
		}
	}, [selected, visible]);

	/**
	 * Whether or not the user is allowed to see the Submit button.
	 * Depends on whether they are updating / creating and whether they have permissions.
	 */
	const shouldHideSubmitButton = () => {
		let hideSubmit = true;

		// Has reader and allowed to update
		if (
			getSelectedFixedAddress(selectedReader) &&
			hasActionPermissions(user, userConstants.screens.READER, userConstants.actions.WRITE) === true
		) {
			hideSubmit = false; // Alowed user to update the reader settings.
		}

		// No reader yet and allowed to create
		else if (
			!getSelectedFixedAddress(selectedReader) &&
			hasActionPermissions(user, userConstants.screens.READER, userConstants.actions.CREATE) === true
		) {
			hideSubmit = false; // Alowed user to add a reader.
		}

		return hideSubmit;
	};

	return (
		<Modal
			showHelpButton={true}
			isReaderForm={true}
			open={visible}
			width={620}
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			loading={loadingPortal || loadingAssignedReaders}
			disableOk={loadingPortal || !selectedFixedAddress}
			hideOk={shouldHideSubmitButton()}
			saving={loadingUpdateReader || loadingDeleteReader || loadingCreateReader}
			title={title}
			onClose={onClose}
			showDelete={
				readerIsSaved(selectedReader) &&
				hasActionPermissions(user, userConstants.screens.READER, userConstants.actions.DELETE)
			}
			onDelete={() => {
				Modal.confirm({
					title: translate.byKey('confirm_delete'),
					content: translate.byKey('are_you_sure_you_want_to_delete_this_reader'),
					okText: translate.byKey('delete'),
					okButtonProps: {
						style: {
							backgroundColor: theme.colors.brand[500],
							borderColor: theme.colors.brand[500],
						},
					},
					cancelText: translate.byKey('cancel'),
					onOk: () => {
						onReaderFormDelete(selectedReader)
							.then(() => {
								setSelectedReader({});
							})
							.catch(console.error);
					},
				});
			}}
			onCancel={() => {
				onClose();
				setSelectedReader({});
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						const {
							deniedBuzDuration,
							deniedLedDuration,
							allowedLedDuration,
							allowedBuzDuration,
							doorOpenDuration,
							strikeDuration,
						} = values;

						const formattedValues = {
							...values,
							deniedLedDuration: deniedLedDuration * 10,
							allowedLedDuration: allowedLedDuration * 10,
							allowedBuzDuration: allowedBuzDuration * 10,
							deniedBuzDuration: deniedBuzDuration * 10,
							doorOpenDuration: doorOpenDuration * 10,
							strikeDuration: strikeDuration * 10,
							isEntry: readerType === 'ENTRY',
						};

						return onReaderFormUpdate({ formattedValues, portal });
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Spin
				styles={{ display: 'none' }}
				active={
					loadingPortal || loadingAssignedReaders || loadingUpdateReader || loadingDeleteReader || loadingCreateReader
				}
				text={deleteReaderCurrentStep || createReaderCurrentStep || updateReaderCurrentStep}
				size="large"
			>
				<Form
					preserve="false"
					onValuesChange={updateState}
					layout="vertical"
					size={'medium'}
					name="door-form"
					form={form}
					scrollToFirstError
				>
					<ReaderInputs
						form={form}
						readers={getAllReaders(selectedController, readerType, allReaders, portal)}
						doorType={selected.doorType}
					/>
				</Form>
			</Spin>
		</Modal>
	);
};

export default ReaderForm;
