import React from 'react';
import { Page as PdfPage, StyleSheet } from '@react-pdf/renderer';

import { Pagination } from '~/components/shared/pdf/Pagination/Pagination';
import { Header } from '~/components/shared/pdf/Header/Header';

const componentStyles = StyleSheet.create({
	padding: '24px',
});

export const Page = ({ styles, children, ...rest }) => {
	return (
		<PdfPage
			style={{
				...componentStyles,
				...styles,
			}}
			{...rest}
		>
			<Header />
			{children}
			<Pagination />
		</PdfPage>
	);
};
