import React from 'react';

export const viewBox = '0 0 128 128';

const path = (
	<g fill="currentColor">
		<path d="M95.424 114.4H37.667a1.75 1.75 0 01-1.75-1.75v-97.3a1.75 1.75 0 011.75-1.75h57.757a1.75 1.75 0 011.75 1.75V54a1.75 1.75 0 11-3.5 0V17.1H39.417v93.8h54.257V74a1.75 1.75 0 013.5 0v38.65a1.75 1.75 0 01-1.75 1.75z" />
		<path d="M46.883 121.5L7.076 103.808V24.192L46.883 6.5z" />
		<path d="M13.076 97.083a1.75 1.75 0 01-1.75-1.75V66.667a1.75 1.75 0 013.5 0v28.666a1.75 1.75 0 01-1.75 1.75z" />
		<path d="M122.162 62.763l-12.51-12.511a1.75 1.75 0 00-2.475 2.475l9.523 9.523H63.424a1.75 1.75 0 000 3.5H116.7l-9.523 9.523a1.75 1.75 0 102.475 2.475l12.51-12.511a1.749 1.749 0 000-2.474z" />
	</g>
);

const exit = {
	path,
	viewBox,
};

export default exit;
