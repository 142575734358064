import React, { useEffect, useRef } from 'react';
import { Table } from 'antd';
import { EnabledFiltersSection } from '../EnabledFiltersSection';
import { css } from '@emotion/core';

/**
 * `TableWithFiltersSection` extends the Ant Design Table component to include a dynamic filter section.
 * It renders a table with the ability to filter data based on specified columns and displays the currently
 * active filters above the table. Users can remove individual filters or reset all filters.
 *
 * Props:
 * @param {Array<ColumnsType extends {enabledFilterProps: (value) => EnabledFiltersProps}>} columns - The columns for the table. Each column object includes all standard
 *                             properties of Ant Design Table columns. Additionally, a specific property
 *                             `enabledFilterProps` is a function that returns `EnabledFilterProps` for
 *                             each filter value. This function enhances the filtering capabilities by
 *                             providing additional properties for each filter.
 *
 * @param rest - rest of the Ant Table props: https://4x.ant.design/components/table/#API
 *
 */
export const TableWithFiltersSection = ({ columns, onChange, ...rest }) => {
	const currentFilters = useRef();
	const sorterValue = useRef({});

	useEffect(() => {
		currentFilters.current = columns.reduce((acc, c) => ({ ...acc, [c.dataIndex]: c.filteredValue ?? [] }), {});
	}, [columns]);

	const onChangeWrapper = (pagination, filters, sorter) => {
		sorterValue.current = sorter;
		onChange(pagination, filters, sorter);
	};

	const removeFilter = ({ dataIndex, value }) => {
		onChange(
			rest.pagination,
			{
				...(currentFilters.current ?? {}),
				[dataIndex]: [...currentFilters.current[dataIndex]?.filter((x) => x !== value)],
			},
			sorterValue.current
		);
	};

	const resetFilters = () => {
		onChange(rest.pagination, {}, sorterValue.current);
	};

	const renderFiltersSection = (columns) => (
		<EnabledFiltersSection
			enabledFilters={columns
				.filter((column) => Boolean(column.filteredValue?.length))
				.map(({ filteredValue, dataIndex, title, enabledFilterProps }) =>
					(filteredValue ?? []).map((filterValue) => ({
						name: title,
						value: filterValue,
						getId: (value) => ({ dataIndex, value }),
						getNameLabel: (name) => name,
						getValueLabel: (value) => value?.toString(),
						...(Boolean(enabledFilterProps) ? enabledFilterProps(filterValue) : {}),
					}))
				)
				.flat()}
			onRemove={removeFilter}
			onReset={resetFilters}
		/>
	);

	return (
		<Table
			onChange={onChangeWrapper}
			columns={columns.map(({ filterChipRenderer, ...rest }) => ({
				...rest,
			}))}
			title={() => renderFiltersSection(columns)}
			css={tableStyles}
			{...rest}
		/>
	);
};

const tableStyles = css({
	'.ant-table-title': {
		paddingInline: 0,
	},
});
