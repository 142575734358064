import React from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import moment from 'moment';
import { UserInfo } from '~/components/shared/layouts/MainLayout/Footer/UserInfo';
import { css } from '@emotion/core';
import { Typography } from 'antd';

const { Text } = Typography;

export const Footer = () => {
	const { translate } = useLocale();

	return (
		<footer css={footerStyles}>
			<Text css={textStyles}>
				{translate.byKeyFormatted('assa_abloy_copyright_formatted', { year: moment().year() })}
			</Text>
			<UserInfo />
		</footer>
	);
};

const footerStyles = css({
	height: '32px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: '#45637B',
	paddingLeft: '20px',
	paddingRight: '20px',

	'@media only screen and (max-width: 769px)': {
		fontSize: '10px',
	},
});

const textStyles = css({
	color: 'white',
});
