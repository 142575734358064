import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

export const DEFAULT_SORT_BY = 'name';
export const DEFAULT_SORT_DIRECTION = 'ASCENDING';
export const DEFAULT_PAGE_NUMBER = 1;

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useAssets = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.asset,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , , pageSize, pageNumber, sortBy, sortDirection, assetType] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': pageSize,
					page: pageNumber,
					'sort-by': sortBy,
					'sort-order': sortDirection,
					type: assetType,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAssetsKey = ({
	customerId,
	systemId,
	siteId,
	pageSize = DEFAULT_PAGE_SIZE,
	pageNumber = DEFAULT_PAGE_NUMBER,
	sortBy = DEFAULT_SORT_BY,
	sortDirection = DEFAULT_SORT_DIRECTION,
	assetType,
	doorGroup,
	description,
}) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${sortBy}${separator}\
${sortDirection}${separator}\
${assetType}${separator}\
${doorGroup}${separator}\
${description}${separator}\
assets`
		: null;
