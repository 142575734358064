const SEPARATOR = '.';

const fillMissingVersionValues = (shorterVersion, longerVersion, characterToFill) =>
	longerVersion
		.split('')
		.map((character, index) => {
			if (index < shorterVersion.length) {
				return shorterVersion.charAt(index);
			} else {
				return character === SEPARATOR ? SEPARATOR : characterToFill;
			}
		})
		.join('');

// Based on: https://stackoverflow.com/questions/6832596/how-can-i-compare-software-version-number-using-javascript-only-numbers
const compareVersions = (versionA, versionB) => {
	const newVersionA = versionA.length < versionB.length ? fillMissingVersionValues(versionA, versionB, '0') : versionA;
	const newVersionB = versionA.length > versionB.length ? fillMissingVersionValues(versionB, versionA, '0') : versionB;

	return newVersionA.localeCompare(newVersionB, undefined, { numeric: true, sensitivity: 'base' });
};

export const isVersionLessThan = (versionA, versionB) => compareVersions(versionA, versionB) === -1;

export const isVersionGreaterThan = (versionA, versionB) => compareVersions(versionA, versionB) === 1;

export const isVersionEqual = (versionA, versionB) => compareVersions(versionA, versionB) === 0;
