const CREDENTIAL_LENGTH_UPPER_LIMIT = 15;

export const ensureCredentialValueUpperLimit = (credentialValue) =>
	credentialValue.length > CREDENTIAL_LENGTH_UPPER_LIMIT
		? credentialValue.substr(0, CREDENTIAL_LENGTH_UPPER_LIMIT)
		: credentialValue;

export const removeUnwantedCharacters = (input) =>
	input
		.split('')
		.filter((item) => !isNaN(item))
		.join('');
