import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';
import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { DEFAULT_PAGE_NUMBER } from '../assets/useAssets';

const separator = '__';

const defaultConfig = {
	revalidateOnFocus: false,
};

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useGetHolidays = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.specialDay,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , calendarId, pageSize, pageNumber] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': pageSize,
					page: pageNumber,
					'detail-level': 'FULL',
				},
				isExcludedValue
			),
			calendarId,
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getHolidaysKey = ({
	customerId,
	systemId,
	calendarId,
	pageSize = DEFAULT_PAGE_SIZE,
	pageNumber = DEFAULT_PAGE_NUMBER,
}) =>
	customerId && systemId && calendarId
		? `${customerId}${separator}\
${systemId}${separator}\
${calendarId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
getHolidays`
		: null;
