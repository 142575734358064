import React, { useEffect } from 'react';
import { Table } from 'antd';
import moment from 'moment/moment';

import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getAccessProfilesKey, useAccessProfiles } from '~/hooks/data/access-profiles/useAccessProfiles';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getScrollHeightOptions } from '~/constants/Table';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AccessProfilesTable = ({ onRowClick }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const { queryParams, setQueryParams, clearAllQueryParams } = useQueryParams();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { data: accessProfiles } = useAccessProfiles(
		getAccessProfilesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
		})
	);

	useEffect(() => {
		clearAllQueryParams();

		return () => {
			clearAllQueryParams();
		};
	}, [siteId, systemId]);

	const onTableChange = (pagination, filters) => {
		setQueryParams({
			pageSize: pagination.pageSize,
			pageNumber: pagination.current,
			name: filters?.name?.[0] || '',
		});
	};

	// TODO MM rowKey is id when BE adds

	return (
		<Table
			rowKey="name"
			dataSource={accessProfiles}
			onChange={onTableChange}
			onRow={(accessProfile) => ({
				onClick: () => {
					if (hasActionPermissions(user, userConstants.screens.ACCESS_PROFILES, userConstants.actions.WRITE)) {
						onRowClick(accessProfile.id);
					}
				},
			})}
			columns={[
				{
					dataIndex: 'name',
					title: translate.byKey('name'),
					filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
					filteredValue: queryParams?.name ? [queryParams.name] : null,
					filterIcon: <SearchFilterIcon />,
				},
				...(!siteId
					? [
							{
								dataIndex: 'location',
								title: translate.byKey('location'),
							},
					  ]
					: []),
				{
					dataIndex: 'validFrom',
					title: translate.byKey('valid_from'),
					render: (value) => (value ? moment(value).format('L LT') : translate.byKey('unset')),
				},
				{
					dataIndex: 'validUntil',
					title: translate.byKey('valid_until'),
					render: (value) => (value ? moment(value).format('L LT') : translate.byKey('unset')),
				},
			]}
			pagination={{
				current: parseInt(queryParams?.pageNumber) || 1,
				pageSize: queryParams?.pageSize || DEFAULT_PAGE_SIZE,
				// TODO MM
				// total: data?.totalRecordCount,
				showSizeChanger: true,
				position: ['bottomCenter'],
				showTotal: (total, [start, end]) => translate.byKeyFormatted('results_counter_label', [start, end, total]),
			}}
			scroll={getScrollHeightOptions()}
		/>
	);
};
