import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

export const useAccessScheduleCreate = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/access-schedule',
				method: method.post,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => ref.current.fetchData({ ignoreGlobalHandlers: true, ...arg });

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessScheduleCreateKey = ({ customerId, systemId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
accessScheduleCreate`
		: null;
