import { getSiteControllersKey } from '~/hooks/data/controllers/useSiteControllers';
import { ControllerProtocols } from '~/constants/ControllerProtocols';

export const getUsbDevicesKey = ({ customerId, systemId, siteId, type }) =>
	getSiteControllersKey({
		customerId,
		systemId,
		siteId,
		protocol: ControllerProtocols.PULSE,
		detailLevel: 'FULL',
		type,
	});
