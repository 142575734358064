export const INITIAL_STATE = {
	availableControllers: null,
	controllersLockRecords: {},
};

export const ACTION_TYPES = {
	RESET: 'reset',
	INITIALIZE: 'initializeRecords',
	UPDATE_CONTROLLER: 'updateControllerState',
	UPDATE_CONTROLLLERS: 'updateControllersState',
};

export const siteControllersEmergencyRecordsReducer = (reducerState = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.RESET:
			return { ...INITIAL_STATE };
		case ACTION_TYPES.INITIALIZE:
			const { controllers } = action;
			return {
				availableControllers: [...controllers],
				controllersLockRecords: controllers.reduce((result, controller) => {
					result[controller.controllerId] = controller?.configuration?.additionalSettings?.controllerEmergencyStatus;
					return result;
				}, {}),
			};
		case ACTION_TYPES.UPDATE_CONTROLLLERS: {
			const { controllerIds, state } = action;
			return {
				...reducerState,
				controllersLockRecords: {
					...reducerState.controllersLockRecords,
					...controllerIds.reduce((result, controllerId) => {
						result[controllerId] = state;
						return result;
					}, {}),
				},
			};
		}
		case ACTION_TYPES.UPDATE_CONTROLLER: {
			const { controllerId, state } = action;
			return {
				...reducerState,
				controllersLockRecords: {
					...reducerState.controllersLockRecords,
					[controllerId]: state,
				},
			};
		}
		default:
			throw new Error('not implemented');
	}
};
