import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Button, PageHeader, ReloadButton } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

export const DoorGroupsHeader = ({ onAddNewButtonClick, onReload }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();

	return (
		<PageHeader title={translate.byKey('door_groups_title_v2')} icon="doors">
			<ReloadButton onReload={onReload} />
			{hasActionPermissions(user, userConstants.screens.ACCESS_GROUPS, userConstants.actions.CREATE) ? (
				<Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddNewButtonClick}>
					{translate.byKey('add_new')}
				</Button>
			) : null}
		</PageHeader>
	);
};
