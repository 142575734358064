import { useRef } from 'react';
import useSWR from 'swr';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

export const useAccessSchedule = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/access-schedule/:accessScheduleId:',
				method: method.get,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , accessScheduleId] = key.split(separator);

		return ref.current.fetchData({ accessScheduleId });
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessScheduleKey = ({ customerId, systemId, id }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${id}${separator}\
accessSchedule`
		: null;
