import { translator } from '~/screens/_shared/AppLocale';

const controller = {
	base: {
		name: (data) => (data.name ? data.name : translator.byKeyFormatted('controller_name_formatted', [data.siteName])),
		description: (data) => {
			if (data.description) return data.description;
			if (data.protocol !== 'pulse') return translator.byKeyFormatted('controller_name_formatted', [data.name]);
			return '';
		},
		serialNumber: true,
		macAddress: true,
		mountedStatus: (data) => data.mountedStatus || 'MOUNTED', //c could also be UNKNOWN or UNMOUNTED
		type: (data) => data.type || 'ACCESS_CONTROL_UNIT', // could also be 'UPDATER
		protocol: (data) => data.protocol || 'improx', // could also be 'pulse'
		region: 'us-east-1',
		upstreamUri: false,
		downstreamUri: false,
		latitude: false,
		longitude: false,
	},
	update: {
		additionalSettings: ({ additionalSettings }) => {
			return additionalSettings;
		},
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'site', ':siteId:', 'controller'],
};

export default controller;
