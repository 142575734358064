import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const separator = '**';

const __contentType = 'application/vnd.assaabloy.msfss.administration-11.0+json';

export const usePutEmeaSite = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity:
					'/administration/business-entity/:businessEntityId/customer/:endCustomerId/system/:systemId/site/:siteId',
				method: method.put,
			},
			handleDefaultApiError
		)
	);

	const fetcher = (_, { arg }) => {
		const [businessEntityId, endCustomerId] = key.split(separator);

		return ref.current.fetchData({
			...arg,
			__contentType,
			businessEntityId,
			endCustomerId,
		});
	};

	return useSWRMutation(key, fetcher, { ...config });
};

export const putEmeaSiteKey = ({ businessEntityId, endCustomerId, systemId, siteId }) =>
	businessEntityId && endCustomerId && systemId && siteId
		? `${businessEntityId}${separator}\
${endCustomerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
put-emea-site`
		: null;
