/**
 * constants for identifier formats. some are commented
 * but left here for reference in case needed later, these are
 * not really values our system uses or understands. and also
 * we only support up to 48 bit formats on the incedo lite hardware
 */
export const identifierFormat = {
	NONE: { text: 'Any', mask: undefined, bitlength: 0, numeric: true, hint: '' },
	WIEGAND26: { text: 'Wiegand 26', mask: new RegExp('^[0-9]*$'), bitlength: 26, numeric: true, hint: 'DEC' },
	WIEGAND37: { text: 'Wiegand 37', mask: new RegExp('^[0-9]*$'), bitlength: 37, numeric: true, hint: 'DEC' },
	INCEDO_CARD_ID: { text: 'Incedo Card', mask: new RegExp('^[0-9]*$'), bitlength: 37, numeric: true, hint: 'DEC' },
	WIEGAND37_FACILITY: {
		text: 'Facility Code Wiegand 37',
		mask: new RegExp('^[0-9]*$'),
		bitlength: 37,
		numeric: true,
		hint: 'DEC',
	},
	FACILITY16_CARD32: {
		text: 'Facility Code 16 Card 32',
		mask: new RegExp('^[0-9]*$'),
		bitlength: 48,
		numeric: true,
		hint: 'DEC',
	},
	SIMPLE_NUMBER16: {
		text: 'Number 16',
		mask: new RegExp('^[0-9]*$'),
		bitlength: 16,
		numeric: true,
		hint: 'DEC',
	},
	SIMPLE_NUMBER32: {
		text: 'Number 32',
		mask: new RegExp('^[0-9]*$'),
		bitlength: 32,
		numeric: true,
		hint: 'DEC',
	},
	SIMPLE_ALPHA_NUMERIC: {
		text: 'Alpha Numeric',
		mask: new RegExp('^[a-fA-F0-9]*$'),
		bitlength: 0,
		numeric: false,
		hint: 'HEX',
	},
	SIMPLE_NUMBER56: {
		text: 'Number 56',
		mask: new RegExp('^[0-9]*$'),
		bitlength: 56,
		numeric: true,
		hint: 'DEC',
	},
};
