import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useSystemControllers = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.controllersBySystem,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , type] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					type,
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getSystemControllersKey = ({ customerId, systemId, type }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${type}${separator}\
systemControllers`
		: null;
