import { useState } from 'react';

import { method } from '~/screens/_shared/useApi/apiConstants';
import { usePostApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import mappers from '~/screens/_shared/mappers';

export function useCreate() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [createAccessProfile] = usePostApi(mappers.accessProfile);
	const { translate } = useLocale();

	/**
	 * Create new
	 * @param {*} formData
	 */
	const createData = async (formData) => {
		try {
			// pull out formData we need
			const { name, description, policies, startDateTime, endDateTime, domainKey } = formData;
			setCurrentStep(translate.byKey('creating_new'));
			setIsLoading(true);

			// begin creating
			const data = await createAccessProfile(
				{
					name,
					description,
					policies,
					startDateTime,
					endDateTime,
					domainKey,
				},
				{
					removeExistingKey: getCacheKey(method.list, mappers.accessProfile, { params: { 'detail-level': 'FULL' } }),
				}
			);

			setIsLoading(false);

			return {
				accessProfileId: data.accessProfileId,
			};
		} finally {
			setCurrentStep('');
			setIsLoading(false);
		}
	};

	return [createData, isLoading, currentStep];
}
