import { Col, Form, Row, Modal } from 'antd';
import React, { Fragment } from 'react';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import { Logo, Spin, Text } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import Button from '../../components/forms/Button';
import systemConfig from '~/screens/_shared/systemConfig';
import moment from 'moment';

export const AboutForm = ({ onCancel, loading }) => {
	const [form] = Form.useForm();
	const { translate } = useLocale();
	const theme = useTheme();

	const footer = [
		<Fragment key="about-form-footer">
			<Button onClick={onCancel}>{translate.byKey('close')}</Button>
		</Fragment>,
	];

	return (
		<Modal
			footer={footer}
			showHelpButton={false}
			open
			maskClosable={false}
			forceRender={false}
			loading={loading}
			cancelText={translate.byKey('close')}
			onCancel={onCancel}
			title={translate.byKey('about')}
		>
			<Spin active={loading}>
				<Form layout="vertical" size={'medium'} name="about-form" form={form} scrollToFirstError>
					<Row gutter={[12, 12]}>
						<Col md={24}>
							<Logo
								width={250}
								height={109}
								pl={'30px'}
								css={css`
									background-color: ${theme.colors.brand[995]};
									padding-right: 30px !important;
									border-radius: 10px;
								`}
							/>
						</Col>
						<Col md={24}>
							{/* <a href={systemConfig.jiraDashboardLink} target="_blank" rel="noopener noreferrer"> */}
							<Text fontFamily="Verdana" fontSize="13px" color="gray.500" fontWeight="600" marginLeft="30px">
								{translate.byKeyFormatted('version_formatted', [systemConfig.jiraVersion])}
							</Text>
							{/* </a> */}
						</Col>
						<Col md={24}>
							<Text fontFamily="Verdana" fontSize="13px" color="gray.500" fontWeight="600" marginLeft="30px">
								{translate.byKeyFormatted('build_number_formatted', [systemConfig.version])}
							</Text>
						</Col>
						<Col md={24}>
							<Text fontFamily="Verdana" fontSize="11px" color="gray.500" fontWeight="500" marginLeft="30px">
								{translate.byKeyFormatted('assa_abloy_copyright_formatted', { year: moment().year() })}
							</Text>
						</Col>
					</Row>
				</Form>
			</Spin>
		</Modal>
	);
};
