import moment from 'moment';
import { message } from 'antd';

export const messageStore = new Map();

message.config({
	duration: 3,
	maxCount: 3,
});

const getConfig = (config) => {
	return typeof config === 'string'
		? {
				message: config,
		  }
		: { ...config };
};

export function showToast(config, type, localMessage = null) {
	localMessage = localMessage || message;
	config = getConfig(config);
	const key = config.key || config.message;
	if (!messageStore.has(key)) {
		messageStore.set(key, moment().add(4, 'second'));
		if (type in localMessage) {
			localMessage[type](config.message);
			config.type = type;
			return config;
		}
	}

	if (moment().isAfter(messageStore.get(key))) {
		messageStore.set(key, moment().add(4, 'second'));
		if (type in localMessage) {
			localMessage[type](config.message);
			config.type = type;
			return config;
		}
	}

	config.type = 'NOOP';

	return config;
}

export function showErrorToast(config) {
	return showToast(config, 'error');
}

export function showInfoToast(config) {
	return showToast(config, 'info');
}

export function showWarningToast(config) {
	return showToast(config, 'warning');
}

export function showSuccessToast(config) {
	return showToast(config, 'success');
}
