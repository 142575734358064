import useSWR from 'swr';

const fakeData = {
	name: 'Cleaner',
	location: '5296d33d-ed77-4af7-a50f-c003eabaa4ad',
	doorGroups: [
		{ doorGroup: 'door group id 1', accessSchedule: '1' },
		{ doorGroup: 'door group id 2' },
		{ doorGroup: 'door group id 1', accessSchedule: '2' },
	],
	doors: [
		{ door: 'door id 1', accessSchedule: '2' },
		{ door: 'door id 2', accessSchedule: '1' },
	],
	people: [
		{
			id: 'person id 2',
			firstName: 'Sara',
			lastName: 'Doe',
			emailAddress: 'sara.doe@abc.com',
			phoneNumber: '123 456',
			validFrom: '2023-11-14',
			validUntil: '2024-11-14',
		},
		{
			id: 'person id 3',
			firstName: 'John 2',
			lastName: 'Doe 2',
			emailAddress: 'john.doe2@abc.com',
			phoneNumber: '123 456',
			validFrom: '2023-11-14',
			validUntil: '2024-11-14',
		},
	],
};
const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// TODO MM change when API is ready
export const useAccessProfile = (key, config) => {
	const fetcher = async (key) => {
		// const [customerId, systemId, id] = key.split(separator);
		return fakeData;
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessProfileKey = ({ customerId, systemId, id }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${id}${separator}\
accessProfile`
		: null;
