import React from 'react';
import { useHolidays } from '~/hooks/features/holidays/useHolidays';
import { Col, Form, Row, DatePicker, Switch, Input } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { Item } from '~/components';
import { SyncOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';

import moment from 'moment';

const isDateDisabled = (recurring) => (date) => {
	if (recurring) {
		return date.isBefore(moment().startOf('year')) || date.isAfter(moment().endOf('year'));
	}

	return date.isBefore(moment().startOf('day')) || date.isAfter(moment().add(10, 'years').endOf('year'));
};

const { RangePicker } = DatePicker;

export const HOLIDAY_FORM_NAME = 'holiday-form';

const EMPTY_FORM_INITIAL_VALUES = {
	name: '',
	description: '',
	recurring: false,
};

export const HolidayForm = ({ selected, onFormChange, onFormSubmit }) => {
	const { translate } = useLocale();
	const [form] = Form.useForm();

	const recurring = Form.useWatch('recurring', form);

	const { data: holidays } = useHolidays();

	const initialValues = !Boolean(selected)
		? EMPTY_FORM_INITIAL_VALUES
		: {
				name: selected?.name,
				description: selected?.description,
				dates: [moment(selected?.start), moment(selected?.end)],
				recurring: selected?.recurring,
		  };

	return (
		<Form
			form={form}
			layout="vertical"
			size="medium"
			name={HOLIDAY_FORM_NAME}
			initialValues={initialValues}
			scrollToFirstError
			onValuesChange={() => onFormChange(form)}
			onFinish={() => onFormSubmit(form)}
		>
			<Row gutter={[24, 24]}>
				<Col md={24} lg={24}>
					<Item
						name={'name'}
						label={translate.byKey('holiday_name')}
						rules={[
							{
								required: true,
								whitespace: true,
								message: translate.byKey('name_is_required'),
							},
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('name'),
									maxLength: 50,
								}),
							},
							{
								validator(rule, value) {
									const temp = (value || '').toLowerCase().trim();
									const otherSpecialDay =
										Array.isArray(holidays) && holidays.length > 0
											? holidays.find(
													(specialDay) => (specialDay.name || '').trim().toLowerCase().localeCompare(temp) === 0
											  )
											: null;

									if (selected && otherSpecialDay?.specialDayId === selected.specialDayId) {
										return Promise.resolve();
									}

									if (otherSpecialDay) {
										return Promise.reject(translate.byKey('holiday_with_the_same_name_already_exist'));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input autoFocus={true} maxLength={50} showCount />
					</Item>
				</Col>
				<Col md={24} lg={24}>
					<Item
						name={'description'}
						label={translate.byKey('holiday_description')}
						rules={[
							{
								required: false,
							},
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('description'),
									maxLength: 50,
								}),
							},
						]}
					>
						<Input maxLength={50} showCount />
					</Item>
				</Col>
				<Col md={24} lg={24}>
					<Item
						name="dates"
						labelCol={{ style: { height: '20px' } }}
						label={
							<Row style={{ width: '100%' }}>
								<Col xs={12}>{translate.byKey('start_date')}</Col>
								<Col xs={12}>{translate.byKey('end_date')}</Col>
							</Row>
						}
						rules={[
							{
								required: true,
								message: translate.byKey('dates_are_required'),
							},
						]}
						css={pickerItemStyles}
					>
						<RangePicker
							disabledDate={isDateDisabled(recurring)}
							style={{ width: '100%' }}
							placeholder={['', '']}
							format={recurring ? '[LDM]' : 'L'}
						/>
					</Item>
				</Col>

				<Col md={24} lg={12}>
					<Item
						name="recurring"
						label={
							<>
								<SyncOutlined />
								&nbsp;{translate.byKey('recurring_annually')}
							</>
						}
					>
						<Switch
							checked={recurring}
							checkedChildren={translate.byKey('yes')}
							unCheckedChildren={translate.byKey('no')}
						/>
					</Item>
				</Col>
			</Row>
		</Form>
	);
};

const pickerItemStyles = css({
	'& .ant-form-item-label > label': {
		width: '100%',
	},
});
