import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

export const useAccessScheduleDelete = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/access-schedule/:accessScheduleId:',
				method: method.delete,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg }) => {
		const [, , accessScheduleId] = key.split(separator);

		return ref.current.fetchData({ accessScheduleId, ...arg });
	};

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessScheduleDeleteKey = ({ customerId, systemId, accessScheduleId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${accessScheduleId}${separator}\
accessScheduleDelete`
		: null;
