import React, { useEffect, useState } from 'react';
import { Input, Col, Form, Row, Select, Cascader } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useLocale } from '~/screens/_shared/AppLocale';
import moment from 'moment-timezone';
import { City, Country } from 'country-state-city';
import { getCurrentAdministrator, currentAdminHasRequiredRole } from '~/screens/_shared/userRoleConstants';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { getLocation } from '../SiteSettingsModal/SiteSettingsModal';

export const SITE_SETTINGS_FORM_NAME = 'site-settings-form';

export const SiteSettingsForm = ({ selectedSite, onFormSubmit, form }) => {
	const { translate, countries, locale } = useLocale();

	const [countryList] = useState(() =>
		Country.getAllCountries().map((country) => ({
			value: country.isoCode,
			label:
				countries.getName(country.isoCode, locale, { select: 'official' }) ||
				countries.getName(country.isoCode, 'en', { select: 'official' }),
			children: [...new Set(City.getCitiesOfCountry(country.isoCode).map((city) => city.name))].map((city) => ({
				value: city,
				label: city,
			})),
		}))
	);

	const {
		data: { system: currentSystem },
	} = useCurrentSystemSite();

	const [isCountrySelectorRendered, setIsCountrySelectorRendered] = useState(true);

	const currentAdmin = getCurrentAdministrator(currentSystem);
	const requiredRoles = ['SYSTEM_OWNER', 'SYSTEM_INSTALLER'];
	const hasRequiredRoles = currentAdminHasRequiredRole(currentAdmin, requiredRoles);

	const [countryZones, setCountryZones] = useState(moment.tz.names());
	const location = Form.useWatch('location', form);

	useEffect(() => {
		setIsCountrySelectorRendered(typeof getLocation(selectedSite?.location) !== 'string');
	}, [selectedSite]);

	useEffect(() => {
		// Added to filter the list on load for editing
		if (location && countryZones.length === moment.tz.names().length) {
			const availableTimezones = location?.[0] ? moment.tz.zonesForCountry(location[0]) : moment.tz.names();
			setCountryZones(availableTimezones || []);
		}
	}, [location, countryZones]);

	const onLocationChange = (newLocation) => {
		const availableTimezones = newLocation?.[0] ? moment.tz.zonesForCountry(newLocation[0]) : moment.tz.names();

		setCountryZones(availableTimezones);
		form.setFieldValue('timeZone', availableTimezones.length === 1 ? availableTimezones?.[0] : undefined);
	};

	return (
		<Form
			form={form}
			preserve={false}
			layout="vertical"
			size="medium"
			name={SITE_SETTINGS_FORM_NAME}
			initialValues={{
				...(selectedSite ?? {}),
				location: getLocation(selectedSite?.location),
			}}
			scrollToFirstError
			onFinish={() => onFormSubmit(form)}
		>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Form.Item
						name="name"
						label={translate.byKey('site_name')}
						rules={[
							{
								required: true,
								whitespace: true,
								message: translate.byKey('name_is_required'),
							},
							{
								validator(rule, value) {
									const temp = (value || '').toLowerCase().trim();
									const otherSite = currentSystem?.sites?.find(
										(site) => site?.name?.trim()?.toLowerCase()?.localeCompare(temp) === 0
									);

									if (otherSite?.siteId === selectedSite?.siteId) {
										return Promise.resolve();
									}

									if (otherSite) {
										return Promise.reject(translate.byKey('there_is_another_site_with_the_same_name_on_the_system'));
									}

									return Promise.resolve();
								},
							},
							{
								max: 50,
								message: translate.byKeyFormatted('field_value_cannot_be_longer_than_message', {
									fieldName: translate.byKey('name'),
									maxLength: 50,
								}),
							},
						]}
					>
						<Input disabled={!hasRequiredRoles} name="name" maxLength={50} showCount />
					</Form.Item>
				</Col>

				<Col span={24}>
					{isCountrySelectorRendered ? (
						<Form.Item
							name="location"
							label={translate.byKey('location')}
							rules={[
								{
									required: true,
									message: translate.byKey('location_is_required'),
								},
							]}
						>
							<Cascader
								key="code"
								name="location"
								onChange={onLocationChange}
								disabled={!hasRequiredRoles}
								placeholder={translate.byKey('select_country')}
								options={countryList ?? []}
								showSearch={true}
							/>
						</Form.Item>
					) : (
						<Form.Item
							name="location"
							label={translate.byKey('location')}
							rules={[
								{
									required: true,
									message: translate.byKey('location_is_required'),
								},
							]}
						>
							<Input name="location" disabled={!hasRequiredRoles} placeholder={translate.byKey('location')} />
						</Form.Item>
					)}
				</Col>

				<Col span={24}>
					<Form.Item
						name="timeZone"
						label={translate.byKey('time_zone')}
						rules={[
							{
								required: true,
								whitespace: true,
								message: translate.byKey('time_zone_is_required'),
							},
						]}
					>
						<Select
							disabled={!hasRequiredRoles || !location?.length}
							options={countryZones?.map((timeZoneName) => ({ value: timeZoneName, label: timeZoneName })) ?? []}
							virtual={false}
						/>
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item name="description" label={translate.byKey('site_description')}>
						<TextArea
							disabled={!hasRequiredRoles}
							maxLength={100}
							allowClear
							autoSize={{
								minRows: 4,
								maxRows: 4,
							}}
							rows={4}
							showCount
						/>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};
