/**
 * Required input values for creating
 * @param {String} portalName
 * @param {Boolean} isEntry
 * @param {String} portId
 * @param {String} portalId
 * @param {String} controllerId

 */

import { translator } from '~/screens/_shared/AppLocale';

/**
 * Required input values for updating
 * @param {String} portalName
 * @param {Boolean} isEntry
 * @param {String} portId
 * @param {String} portalId
 * @param {String} controllerId
 *
 * @param {Boolean} deniedBuzVol
 * @param {String} allowedBuzVol
 * @param {String} deniedLedType
 * @param {String} allowedLedType
 * @param {String} deniedBuzDuration
 *
 * @param {Boolean} deniedLedDuration
 * @param {String} allowedBuzDuration
 * @param {String} allowedLedDuration
 * @param {String} input1DOSType
 * @param {String} input1DOSMode
 * @param {String} doorOpenDuration
 * @param {String} strikeDuration
 * @param {String} input2RTEMode
 * @param {String} fixedAddress
 */

const settingsKeys = [
	'fixedAddress',
	'deniedBuzDuration',
	'deniedBuzVol',
	'allowedBuzDuration',
	'allowedLedDuration',
	'allowedLedType',
	'allowedBuzVol',
	'deniedLedDuration',
	'deniedLedType',
	'doorOpenDuration',
	'strikeDuration',
	'input1DOSType',
	'input1DOSMode',
	'input2RTEMode',
	'cylinderLength',
	'customField',
	'zoneId',
	'zoneName',
	'lockSpecification',
	'macAddress',
	'offlineSync',
];

const peripheralDevice = {
	base: {
		name: ({ portalName, isEntry }) =>
			isEntry
				? `${portalName} (${translator.byKey('entry_reader')})`
				: `${portalName} (${translator.byKey('exit_reader')})`,
		description: ({ portalName, isEntry }) =>
			isEntry
				? `${portalName} (${translator.byKey('entry_reader')})`
				: `${portalName} (${translator.byKey('exit_reader')})`,
		deviceInfo: (data) => {
			if (data.deviceInfo) {
				return data.deviceInfo;
			}

			return {
				vendor: 'HID',
				context: 'ONLINE',
				configurationProtocol: 'HID_READER',
			};
		},
		logicalDevices: (data) => {
			if (data.logicalDevices) {
				return data.logicalDevices;
			}

			return [
				{
					function: data.logicalDeviceFunction || 'RECOGNITION',
					portalSide: data.isEntry ? 'OUTSIDE' : 'INSIDE',
				},
			];
		},
		mapping: ({ portalId, controllerId, portId }, { site, system }) => {
			const defaultMapping = {
				portal: {
					key: {
						systemId: system,
						siteId: site,
						portalId,
					},
				},
			};

			if (portId && controllerId) {
				defaultMapping.port = {
					key: {
						systemId: system,
						siteId: site,
						controllerId,
						portId,
					},
				};
			}

			return defaultMapping;
		},
		configuration: (data) => {
			const config = data.configuration || {};
			config.mountedStatus = config.mountedStatus || 'MOUNTED';
			config.configurationStatus = config.configurationStatus || 'UNKNOWN';

			const settings = settingsKeys.reduce((results, key) => {
				if (key in data) {
					results[key] = data[key];
				}

				return results;
			}, {});

			if (Object.keys(settings).length > 0) {
				config.additionalSettings = settings;
			}

			return config;
		},
	},
	update: {
		name: ({ portalName, isEntry }) =>
			isEntry
				? `${portalName} (${translator.byKey('entry_reader')})`
				: `${portalName} (${translator.byKey('exit_reader')})`,
		description: ({ portalName, isEntry }) =>
			isEntry
				? `${portalName} (${translator.byKey('entry_reader')})`
				: `${portalName} (${translator.byKey('exit_reader')})`,
		deviceInfo: (data) => {
			if (data.deviceInfo) {
				return data.deviceInfo;
			}

			return {
				vendor: 'HID',
				context: 'ONLINE',
				configurationProtocol: 'HID_READER',
			};
		},
		logicalDevices: (data) => {
			if (data.logicalDevices) {
				return data.logicalDevices;
			}

			return [
				{
					function: 'RECOGNITION',
					portalSide: data.isEntry ? 'OUTSIDE' : 'INSIDE',
				},
			];
		},
		mapping: ({ portalId, controllerId, portId }, { site, system }) => {
			const defaultMapping = {
				portal: {
					key: {
						systemId: system,
						siteId: site,
						portalId,
					},
				},
			};

			if (portId && controllerId) {
				defaultMapping.port = {
					key: {
						systemId: system,
						siteId: site,
						controllerId,
						portId,
					},
				};
			}

			return defaultMapping;
		},
		configuration: (data) => {
			const settings = settingsKeys.reduce((results, key) => {
				if (key in data) {
					results[key] = data[key];
				}

				return results;
			}, {});

			if (data.configuration) {
				return data.configuration;
			}

			if (Object.keys(settings).length > 0) {
				return {
					additionalSettings: settings,
				};
			}

			return {};
		},
		version: false,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'site', ':siteId:', 'peripheral-device'],
};

export default peripheralDevice;
