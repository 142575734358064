import React from 'react';
import { Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useLocale } from '~/screens/_shared/AppLocale';
import { Button } from '~/components';

export const UsbDevicesLimitsReachedModal = ({ onClose }) => {
	const { translate } = useLocale();

	return (
		<Modal open title={null} onCancel={onClose} maskClosable={false} width={420} footer={null}>
			<Space size={32} style={{ alignItems: 'start' }}>
				<ExclamationCircleOutlined style={{ fontSize: '22px', color: '#faad14' }} />
				<Space direction="vertical">
					<div style={{ fontWeight: 500, fontSize: '16px' }}>{translate.byKey('warning')}</div>
					<div>
						{translate.byKeyFormatted('entitlement_limit_reached_message', {
							entitlement: translate.byKey('usb_devices'),
						})}
					</div>
				</Space>
			</Space>
			<div style={{ display: 'flex', justifyContent: 'end', marginTop: '16px' }}>
				<Button type="primary" onClick={onClose}>
					{translate.byKey('ok')}
				</Button>
			</div>
		</Modal>
	);
};
