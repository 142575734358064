import { useCalendars } from '~/hooks/features/calendars/useCalendars';
import { getHolidaysKey, useGetHolidays } from '~/hooks/data/holidays/useGetHolidays';
import moment from 'moment';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const useHolidays = () => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();

	const {
		isLoading: isCalendarsLoading,
		isValidating: isCalendarsValidating,
		error: calendarsError,
		defaultCalendarId,
	} = useCalendars();

	const {
		data: holidays,
		isLoading: isHolidaysLoading,
		isValidating: isHolidaysValidating,
		error: holidaysError,
		mutate: mutateHolidays,
	} = useGetHolidays(
		getHolidaysKey({
			customerId,
			systemId,
			calendarId: defaultCalendarId,
		})
	);

	const getTodaysHoliday = () => {
		for (const holiday of holidays?.specialDays ?? []) {
			if (moment().isBetween(moment(holiday.start), moment(holiday.end))) {
				return holiday;
			}

			if (
				holiday.recurring &&
				moment().isBetween(moment(holiday.start).year(moment().year()), moment(holiday.end).year(moment().year()))
			) {
				return holiday;
			}
		}

		return null;
	};

	return {
		data: holidays?.specialDays,
		totalRecords: holidays?.totalRecords,
		isLoading: isCalendarsLoading || isHolidaysLoading,
		isValidating: isHolidaysValidating || isCalendarsValidating,
		error: calendarsError || holidaysError,
		mutate: mutateHolidays,
		getTodaysHoliday,
	};
};
