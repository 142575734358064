import { useState } from 'react';
import mappers from '~/screens/_shared/mappers';
import { useListApi } from '~/screens/_shared/useApi';

export const getPinFromCredentialHolder = (holder) => {
	try {
		if (holder?.description) {
			const pinData = JSON.parse(holder.description);

			if (pinData.pinCode) {
				return { hexValue: pinData.pinCode, recognitionType: 'PIN', identifierFormat: 'SIMPLE_NUMBER16' };
			}
		}
	} catch {
		// if the description was no prepopulated then it means
		// it is an old record which does not have a pin.
	}

	return null;
};

export const useGetPin = () => {
	const [currentStep, setCurrentStep] = useState('');
	const [isLoadingPin, setIsLoadingPin] = useState(false);
	/**
	 * Get the list of credentials belonging to this person.
	 */
	const [getCredentials] = useListApi(mappers.credential);

	/**
	 * Request to lookup the pin code belonging to this person.
	 */
	const getPin = async (data, existingCredentials) => {
		const possiblePin = getPinFromCredentialHolder(data);

		if (possiblePin) {
			return possiblePin;
		}

		setIsLoadingPin(true);
		setCurrentStep('Fetching Cards');

		try {
			const { credentialHolderId } = data;
			let credentials;
			if (existingCredentials) {
				credentials = existingCredentials;
			} else {
				const temp = await getCredentials({
					params: {
						'detail-level': 'FULL',
						credential_holder_id: credentialHolderId,
					},
				});
				credentials = temp.credentials;
			}

			for (const credential of credentials) {
				const pin = credential.credentialIdentifiers.find((cred) => cred.recognitionType === 'PIN');

				if (pin) {
					return pin;
				}
			}
		} finally {
			setIsLoadingPin(false);
			setCurrentStep('');
		}
	};

	return [getPin, isLoadingPin, currentStep];
};
