const calendar = {
	base: {
		name: true,
		description: false,
		ida69: 'ea veniam eu',
		dayTypes: true,
		standardWeek: true,
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'calendar'],
};

export default calendar;
