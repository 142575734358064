import { message, Switch } from 'antd';
import { TableWithFiltersSection } from '~/components/shared/filters/Tags';
import React, { useEffect, useState } from 'react';
import { AddButton, Page, PageHeader, Spin } from '~/components';
import { AccessGroupForm } from './AccessGroupForm';
import createSorter from '~/screens/_shared/useApi/utils/defaultSorter';
import { useLocale } from '~/screens/_shared/AppLocale';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import useGetAccessGroups from '~/screens/AccessGroups/hooks/useGetAccessGroups';
import systemConfig from '~/screens/_shared/systemConfig';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { SearchFilter } from '~/components/shared/filters/SearchFilter/SearchFilter';
import { SearchFilterIcon } from '~/components/shared/filters/SearchFilterIcon/SearchFilterIcon';
import { MultipleChoiceFilter } from '~/components/shared/filters/MultipleChoiceFilter/MultipleChoiceFilter';
import { getScrollHeightOptions } from '~/constants/Table';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const version = systemConfig.devVersion;

export function titleCase(str) {
	str = str.toLowerCase().split(' ');
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
}

function getDefault() {
	return { name: '' };
}

const AccessGroup = () => {
	const {
		data: { site },
	} = useCurrentSystemSite();
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState({});
	const [filteredData, setFilteredData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [getAccessGroups] = useGetAccessGroups();
	const { translate } = useLocale();
	const [pageFilters, setPageFilters] = useState({ accessGroupName: null, accessGroupType: null });
	const [accessGroupTypes, setAccessGroupTypes] = useState([]);
	const [showExtraAccess, setShowExtraAccess] = useState(false);
	const [accessGroupCount, setAccessGroupCount] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

	const onUpdated = () => {
		getAccessGroupsList().catch(console.error);
		setVisible(false);
		setSelected(getDefault());
	};

	/**
	 * Step 1: load list of access groups for system (can't filter by site directly yet).
	 *
	 * We still need to filter out the groups by site using sync state.
	 */

	const getAccessGroupsList = async () => {
		setIsLoading(true);
		try {
			const allAccessGroups = await getAccessGroups('ALL', site);
			let filteredData;

			if (showExtraAccess) {
				filteredData = allAccessGroups;
				setFilteredData(filteredData);
			} else {
				filteredData = allAccessGroups.filter((accessProfile) => accessProfile.accessPolicies.length <= 1);
				setFilteredData(filteredData);
			}

			const accessProfileCount = filteredData.reduce((groups, accessGroup) => {
				const type = accessGroup.type || 'none';

				if (groups[type]) {
					return { ...groups, [type]: groups[type] + 1 };
				} else {
					return { ...groups, [type]: 1 };
				}
			}, {});

			setAccessGroupCount(accessProfileCount);
		} catch (e) {
			console.error(`Error retrieving AccessProfile data: ${e}`);
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * Cancel Success
	 */
	const onCancel = () => {
		setVisible(false);
	};

	/**
	 * adding new record, first get Assets, then decide whether or not to open form
	 */
	const onAddNewAccessGroupClicked = (accessGroupType) => {
		accessGroupType = accessGroupType || 'online';

		loadAccessGroupForm(null, accessGroupType);
	};

	/**
	 * edit row, get available Assets first, then set data for row as selected and open form
	 * @param {*} row
	 */
	const onRowClick = (row) => {
		// No site selected. Can't load assets so block.
		if (!site || (site && !site.siteId)) {
			message.warn(translate.byKey('please_select_a_site'));
		}
		// Site is selected
		else {
			loadAccessGroupForm(row);
		}
	};

	/**
	 * Show the access group form
	 */
	function loadAccessGroupForm(selectedRow, accessGroupType) {
		setVisible(true);
		// Clicked Add New
		if (selectedRow === null) {
			setSelected({
				name: translate.byKey('add_access_group'),
				type: accessGroupType,
			});
		}
		// Clicked a row
		else {
			setSelected(selectedRow);
		}
	}

	const onModalClose = () => {
		setVisible(false);
		setSelected(getDefault());
	};

	useEffect(() => {
		let accessGroups = accessGroupTypes;
		filteredData.forEach((row) => {
			if (!accessGroups.includes(row.type)) {
				accessGroups.push(row.type);
			}
		});
		setAccessGroupTypes(accessGroups);
	}, [filteredData]);

	/**
	 * Make sure we have a system AND site loaded before we attempt to list
	 * Also ensure that we reload the list whenever the system OR site changes
	 */
	useEffect(() => {
		if (site?.siteId) {
			getAccessGroupsList().catch(console.error);
		}
	}, [site?.siteId, showExtraAccess]);

	function tableChanged(pagination, filters, sorter) {
		const accessGroupName = Boolean(filters.name?.length) ? filters.name[0] : null;
		const accessGroupType = Boolean(filters.type?.length) ? filters.type : null;
		setPageFilters({ accessGroupName, accessGroupType });

		setPageSize(pagination.pageSize);
		setCurrentPage(pageSize !== pagination.pageSize ? 1 : pagination.current);

		getAccessGroupsList()
			.then(() => {
				if (accessGroupName) {
					setFilteredData((state) =>
						state.filter((row) => {
							return row.name.toLowerCase().includes(accessGroupName.toLowerCase());
						})
					);
				}
				if (accessGroupType) {
					setFilteredData((state) =>
						state.filter((row) => {
							return !row.type && accessGroupType.includes('none') ? true : accessGroupType.includes(row.type);
						})
					);
				}
			})
			.catch(console.error);
	}

	const typeFilterOptions = accessGroupTypes.map((type) => {
		if (!type) {
			return { label: translate.byKey('none'), value: 'none', count: accessGroupCount?.none };
		}
		return {
			label: titleCase(type),
			value: type,
			count: accessGroupCount[type],
		};
	});

	const columns = [
		{
			title: translate.byKey('name'),
			dataIndex: 'name',
			sortDirections: ['descend', 'ascend', 'descend'],
			sorter: createSorter('name'),
			defaultSortOrder: 'ascend',
			render: (value) => {
				return value;
			},
			filterDropdown: (props) => <SearchFilter antdFilterProps={props} />,
			filteredValue: pageFilters.accessGroupName ? [pageFilters.accessGroupName] : null,
			filterIcon: <SearchFilterIcon />,
		},
		{
			title: translate.byKey('type_label_v2'),
			dataIndex: 'type',
			render: (value, row) => {
				// you have to check if you are getting a valid type back or you get unexpected results in the UI
				if (['online', 'offline'].includes(value)) {
					return translate.byKey(value);
				}
				return value;
			},
			filterDropdown: (props) => (
				<MultipleChoiceFilter antdFilterProps={props} options={typeFilterOptions} showSearch={false} />
			),
			filteredValue: pageFilters.accessGroupType ? pageFilters.accessGroupType : null,
			sortDirections: ['descend', 'ascend', 'descend'],
			sorter: createSorter('type'),
			defaultSortOrder: 'ascend',
			enabledFilterProps: (value) => ({
				getValueLabel: () => typeFilterOptions?.find((x) => x.value === value)?.label ?? value,
			}),
		},
	];

	return (
		<Page>
			<PageHeader title={translate.byKey('access_groups_menu_title')} icon="access-group">
				{localStorage.getItem('extraAccessToggle') === 'true' && version >= 2 ? (
					<Switch
						checked={showExtraAccess}
						onChange={(value) => {
							setShowExtraAccess(value);
						}}
						style={{ marginRight: '1rem', marginTop: '0.25rem', marginBottom: '0.25rem' }}
						checkedChildren={translate.byKey('extra_access_v2')}
						unCheckedChildren={translate.byKey('extra_access_v2')}
					/>
				) : null}
				<AddButton
					hasControllerDependency={false}
					hasSiteDependency={true}
					screen={userConstants.screens.ACCESS_GROUPS}
					onAddNewClick={onAddNewAccessGroupClicked}
				/>
			</PageHeader>
			<TableWithFiltersSection
				loading={{
					spinning: isLoading,
					indicator: <Spin active={true} />,
				}}
				columns={columns}
				scroll={getScrollHeightOptions()}
				dataSource={filteredData}
				onChange={tableChanged}
				onRow={(record) => ({
					onClick: () => onRowClick(record),
				})}
				pagination={{
					showTotal: (total, range) => translate.byKeyFormatted('results_counter_label', [range[0], range[1], total]),
					position: ['bottomCenter'],
					showSizeChanger: true,
					pageSize: pageSize,
					current: currentPage,
				}}
			/>
			<AccessGroupForm
				visible={visible}
				selected={selected}
				onClose={onModalClose}
				onUpdated={onUpdated}
				onCancel={onCancel}
				accessGroups={filteredData}
				onlineAccessGroupCount={accessGroupCount?.online || 0}
			/>
		</Page>
	);
};

export default AccessGroup;
