import { useCalendars } from '~/hooks/features/calendars/useCalendars';
import moment from 'moment';

import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import { usePostApi, useDeleteApi, useGetApi, useListApi, usePutApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';

import mappers from '~/screens/_shared/mappers';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const defAccessGroupName = 'DeFauLT AccESS GroUP'; // We only have 1 per site. Contains all doors for that site.
export const defScheduleName = `${defAccessGroupName}:Sch`;

async function createDefaultAccessGroup({
	defaultCalendarId,
	setCurrentStep,
	createAccessGroup,
	createSchedule,
	getSchedules,
	assets,
	system,
	site,
	translate,
}) {
	const startDateTime = moment().startOf('day').toDate();
	const endDateTime = moment().endOf('day').toDate();

	// Create the schedule
	setCurrentStep(translate.byKey('loading_schedules')); // This step shouldn't be necessary. It's a new group. New schedule.
	const schedules = await getSchedules({ calendarId: defaultCalendarId }, { expiry: moment().add(60, 'minutes') });
	let defGroupSchedule = schedules.find((schedule) => schedule.name === defScheduleName);

	if (!defGroupSchedule) {
		let defaultSchedule = {
			name: defScheduleName,
			customerId: system.customerId,
			systemId: system.systemId,
			calendarId: defaultCalendarId,
			domainKey: {
				domainId: system.systemId,
			},
			description: defScheduleName,
			startDateTime: startDateTime,
			endDateTime: endDateTime,
			defaultState: 'No Access',
			scheduleStates: {
				'No Access': 'No Access',
				Active: 'Allow Access',
			},
			timeIntervals: {
				PublicHoliday: [
					{
						state: 'Active',
						startTime: '00:00', // NA is always all day if day is not selected
						endTime: '23:59',
					},
				],
			},
		};

		setCurrentStep(translate.byKey('create_default_schedule'));
		defGroupSchedule = await createSchedule(defaultSchedule, {
			removeExistingKey: getCacheKey(method.list, mappers.schedule, { calendarId: defaultCalendarId }),
		});
	}

	const policiesObj = [
		{
			systemId: system.systemId,
			calendarId: defaultCalendarId,
			scheduleId: defGroupSchedule.scheduleId,
			assetItems: assets.map((a) => ({ assetId: a.assetId })),
		},
	];

	const description = defAccessGroupName + '|' + site.siteId; // Must have siteId so we can look it up later.
	const createGroupPayload = {
		name: defAccessGroupName,
		description: description,
		policies: policiesObj,
		startDateTime: startDateTime,
		endDateTime: endDateTime,
		domainKey: {
			domainId: system.systemId,
		},
	};

	setCurrentStep(translate.byKey('creating_default_access_group'));
	return await createAccessGroup(createGroupPayload, {
		removeExistingKey: getCacheKey(method.list, mappers.accessProfile, { params: { 'detail-level': 'FULL' } }),
	});
}

async function updateDefaultAccessGroup({
	defaultAccessGroup,
	setCurrentStep,
	updateAccessGroup,
	deleteAccessGroup,
	assets,
	system,
	translate,
	getAccessGroup,
	removeAssets,
}) {
	setCurrentStep(translate.byKey('updating_access_group'));
	const startDateTime = moment().startOf('day');
	const endDateTime = moment().endOf('day');

	const policies = defaultAccessGroup.accessPolicies || [];
	const schedule = policies[0].schedule;
	const defGroupScheduleId = schedule.scheduleId;
	const defGroupCalendarId = schedule.calendarId;
	const existingAssets = policies
		.reduce((assets, policy) => {
			return assets.concat(policy.assets);
		}, [])
		.map((asset) => ({ assetId: asset.assetId }));

	const getPayload = async () => {
		defaultAccessGroup = await getAccessGroup({ accessProfileId: defaultAccessGroup.accessProfileId });
		let updatedAssets;

		if (removeAssets) {
			updatedAssets = existingAssets.filter(
				(existingAsset) => !assets.some((asset) => existingAsset.assetId === asset.assetId)
			);

			if (updatedAssets.length === 0) {
				return null;
			}
		} else {
			updatedAssets = existingAssets.concat(assets);
		}

		const policiesObj = [
			{
				systemId: system.systemId,
				calendarId: defGroupCalendarId,
				scheduleId: defGroupScheduleId,
				assetItems: updatedAssets,
			},
		];

		return {
			name: defAccessGroupName,
			description: defaultAccessGroup.description,
			policies: policiesObj,
			startDateTime: startDateTime,
			endDateTime: endDateTime,
			domainKey: {
				domainId: system.systemId,
			},
			version: defaultAccessGroup.version,
			accessProfileId: defaultAccessGroup.accessProfileId,
		};
	};

	if (removeAssets) {
		const request = await getPayload();

		if (!request) {
			return await deleteAccessGroup(
				{ accessProfileId: defaultAccessGroup.accessProfileId },
				{
					removeExistingKey: getCacheKey(method.list, mappers.accessProfile, { params: { 'detail-level': 'FULL' } }),
				}
			);
		}

		return await updateAccessGroup(request);
	} else if (
		!assets.every((asset) => existingAssets.some((existingAsset) => existingAsset.assetId === asset.assetId))
	) {
		return await updateAccessGroup(await getPayload());
	}
}

export async function createOrUpdateDefaultAccessGroup({
	defaultCalendarId,
	setCurrentStep,
	createSchedule,
	createAccessGroup,
	updateAccessGroup,
	deleteAccessGroup,
	getAccessGroups,
	getAccessGroup,
	getSchedules,
	system,
	site,
	assets,
	translate,
	removeAssets = false,
}) {
	// Check if Default Group exists yet
	setCurrentStep(translate.byKey('fetching_access_group'));
	const accessGroups = await getAccessGroups(
		{ params: { 'detail-level': 'FULL' } },
		{ expiry: moment().add(60, 'minutes') }
	);
	let defaultAccessGroup = accessGroups.find(
		(group) =>
			group.name === defAccessGroupName && group.systemId === system.systemId && group.description.includes(site.siteId)
	);

	// If it does not exist yet, create it.
	if (!defaultAccessGroup) {
		setCurrentStep(translate.byKey('creating_default_access_group'));
		defaultAccessGroup = await createDefaultAccessGroup({
			defaultCalendarId,
			setCurrentStep,
			createAccessGroup,
			createSchedule,
			getSchedules,
			assets,
			system,
			site,
			translate,
		});
	} else {
		setCurrentStep(translate.byKey('updating_access_group'));
		defaultAccessGroup = await updateDefaultAccessGroup({
			defaultAccessGroup,
			setCurrentStep,
			updateAccessGroup,
			assets,
			system,
			site,
			getAccessGroup,
			deleteAccessGroup,
			translate,
			removeAssets,
		});
	}

	return defaultAccessGroup;
}

export function useDefaultAccessGroupUpdate() {
	const {
		data: { system, site },
	} = useCurrentSystemSite();

	const { translate } = useLocale();
	const [createAccessGroup] = usePostApi(mappers.accessProfile);
	const [updateAccessGroup] = usePutApi(mappers.accessProfile);
	const [deleteAccessGroup] = useDeleteApi(mappers.accessProfile);
	const [getAccessGroups] = useListApi(mappers.accessProfile);
	const [createSchedule] = usePostApi(mappers.schedule);
	const [getSchedules] = useListApi(mappers.schedule);
	const [getAccessGroup] = useGetApi(mappers.accessProfile);

	const { defaultCalendarId } = useCalendars();

	return async function (setCurrentStep, assets, removeAssets = false) {
		return await createOrUpdateDefaultAccessGroup({
			setCurrentStep,
			defaultCalendarId,
			createSchedule,
			createAccessGroup,
			updateAccessGroup,
			deleteAccessGroup,
			getAccessGroups,
			getSchedules,
			getAccessGroup,
			system,
			site,
			translate,
			assets,
			removeAssets,
		});
	};
}

export const getBasicAssetParams = () => ({
	params: { 'detail-level': 'FULL', 'page-size': 1000, type: 'BASIC_ASSET' },
});
export const getAccessAreaParams = () => ({ params: { 'detail-level': 'FULL', 'page-size': 50, type: 'ACCESS_AREA' } });

export async function getAssetsForPortal(getAccessPoints, portalId, cacheOptions = {}, getAssets = null) {
	const pendingRequests = [getAccessPoints({ portalId, params: { 'detail-level': 'FULL' } }, cacheOptions)];

	if (getAssets) {
		const cache = {
			shared: true,
			expiry: moment().add(60, 'seconds'),
		};
		pendingRequests.push(getAssets(getBasicAssetParams(), cache));
		pendingRequests.push(getAssets(getAccessAreaParams(), cache));
	} else {
		pendingRequests.push(Promise.resolve(null));
		pendingRequests.push(Promise.resolve(null));
	}

	const [accessPoints, basicAssets, accessAreas] = await Promise.all(pendingRequests);

	let allAssets = null;

	if (basicAssets && accessAreas) {
		allAssets = (basicAssets.assets || []).concat(accessAreas.assets || []);
	}

	return {
		assets: [
			...accessPoints
				.filter((point) => point.portalId === portalId)
				.reduce((results, point) => {
					if (point.fromAsset && !results.has(point.fromAsset.assetId)) {
						let asset = point.fromAsset;

						if (allAssets) {
							asset = allAssets.find((other) => other.assetId === asset.assetId) || point.fromAsset;
						}

						results.set(asset.assetId, asset);
					}

					if (point.toAsset && !results.has(point.toAsset.assetId)) {
						let asset = point.toAsset;

						if (allAssets) {
							asset = allAssets.find((other) => other.assetId === asset.assetId) || point.toAsset;
						}

						results.set(asset.assetId, asset);
					}

					return results;
				}, new Map())
				.values(),
		],
		accessPoints,
	};
}
