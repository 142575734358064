import styled from '@emotion/styled';
import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import { space } from 'styled-system';

const StyledPage = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	${space}
`;

const Page = ({ children }) => (
	<StyledPage>
		<ErrorBoundary>{children}</ErrorBoundary>
	</StyledPage>
);

export default Page;
