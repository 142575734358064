import React, { useMemo, useState } from 'react';
import { Checkbox, Modal, AutoComplete, Space } from 'antd';
import Form from 'antd/lib/form/Form';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';

import { useLocale } from '~/screens/_shared/AppLocale';
import { Button, Item } from '~/components';
import { BlocklistMode, getCredentialsKey, useCredentials } from '~/hooks/data/credentials/useCredentials';
import Spin from '~/components/general/Spin';
import { CredentialState } from '~/constants/Credential';
import { removeDiacritics } from '~/utils/string';
import { mapDataToCredentialHoldersAndCredentials } from '~/components/features/blocklist/modals/BlockCredentialModal/BlockCredentialModal.utils';
import { checkboxGroupStyles } from '~/components/features/blocklist/modals/BlockCredentialModal/BlockCredentialModal.styles';
import { getCredentialStateKey, useCredentialStateUpdate } from '~/hooks/data/credentials/useCredentialStateUpdate';
import { showSuccessToast } from '~/screens/_shared/toast';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getBlockedCredentialsKey } from '~/components/features/blocklist/table/BlocklistTable/BlocklistTable.utils';
import { getCredentialKey, useCredentialUpdate } from '~/hooks/data/credentials/useCredentialUpdate';
import { updateOnlineIdentifier } from '~/utils/credential/credential';
import { systemLimits } from '~/screens/_shared/systemLimits';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const credentialIdSeparator = '-';

export const BlockCredentialModal = ({ onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, mode, sortBy, sortDirection, person },
	} = useQueryParams();
	const { data, isLoading } = useCredentials(
		getCredentialsKey({
			customerId,
			systemId,
			pageSize: 500, // until there is backend support to get items by search
			pageNumber: 1,
			credentialStates: CredentialState.ENABLED,
		})
	);
	const { mutate: reloadBlockedCredentials } = useCredentials(
		getBlockedCredentialsKey({
			customerId,
			systemId,
			pageSize,
			pageNumber,
			mode,
			sortBy,
			sortDirection,
			person,
		}),
		{ revalidateOnMount: false }
	);
	const { trigger: updateCredentialState, isMutating: isUpdatingCredentialState } = useCredentialStateUpdate(
		getCredentialStateKey({
			customerId,
			systemId,
		})
	);
	const { trigger: updateCredential, isMutating: isUpdatingCredential } = useCredentialUpdate(
		getCredentialKey({
			customerId,
			systemId,
		})
	);
	const {
		data: { totalRecords: offlineBlockedCredentialsCount } = {},
		isLoading: isOfflineBlockedCredentialsCountLoading,
		isValidating: isOfflineBlockedCredentialsCountValidating,
	} = useCredentials(
		getCredentialsKey({
			customerId,
			systemId,
			pageSize: 1,
			pageNumber: 1,
			credentialStates: CredentialState.BLOCKED,
			mode: BlocklistMode.OFFLINE,
		})
	);
	const [selectedCredentialHolderId, setSelectedCredentialHolderId] = useState(null);
	const [selectedCredentialIds, setSelectedCredentialIds] = useState([]);

	const isOfflineBlockedCredentialsLimitReached =
		selectedCredentialIds.length &&
		offlineBlockedCredentialsCount + selectedCredentialIds.length > systemLimits.BlockList;
	const isBlockingCredential = isUpdatingCredentialState || isUpdatingCredential;
	const uniqueCredentialHoldersWithCredentials = useMemo(
		() => (data?.credentials ? mapDataToCredentialHoldersAndCredentials(data) : []),
		[data?.credentials]
	);
	const selectOptions = useMemo(
		() =>
			uniqueCredentialHoldersWithCredentials.map((item) => {
				const credentialIds = item.credentials
					.map((credential) => credential.credentialNumber)
					.join(credentialIdSeparator);

				return {
					// Not possible to use object with this version: https://github.com/ant-design/ant-design/issues/5670
					value: [item.credentialHolderName, item.credentialHolderId, credentialIds],
					label: (
						<div>
							{item.credentialHolderName} ({item.credentials.length})
						</div>
					),
				};
			}),
		[uniqueCredentialHoldersWithCredentials]
	);
	const selectedOptionLabel = selectOptions?.find((x) => x.value[1] === selectedCredentialHolderId)?.value?.[0];
	const checkboxOptions = useMemo(
		() =>
			uniqueCredentialHoldersWithCredentials
				.find(({ credentialHolderId }) => credentialHolderId === selectedCredentialHolderId)
				?.credentials.map(({ credentialNumber, credentialId, isPulse }) => ({
					value: credentialId,
					label: (
						<span>
							{credentialNumber} ({translate.byKey(isPulse ? 'pulse_v2' : 'credential')})
						</span>
					),
				})) || [],
		[uniqueCredentialHoldersWithCredentials, selectedCredentialHolderId]
	);

	const onCheckboxChange = (event) => {
		const credentialId = event.target.value;

		setSelectedCredentialIds((prevState) => [
			...(event.target.checked
				? new Set([...prevState, credentialId])
				: prevState.filter((item) => item !== credentialId)),
		]);
	};

	const onBlockButtonClick = async () => {
		try {
			await Promise.all(
				...selectedCredentialIds.map((credentialId, index) => {
					const credentialData = data.credentials.find((credential) => credential.credentialId === credentialId);
					// TODO This is some logic regarding the blocklist and the total count which we couldn't get answer anywhere so we are preserving this during the refactor
					// The index is used to track the total count of the offline blocked credentials.
					// E.g. offlineBlockedCredentialsCount = 99, index = 0, system limits = 100 => (99 + (0 + 1)) <= 100 => true so include
					// E.g. offlineBlockedCredentialsCount = 99, index = 1, system limits = 100 => (99 + (1 + 1)) <= 100 => false so do not include
					const includeInOfflineBlocklist = offlineBlockedCredentialsCount + (index + 1) <= systemLimits.BlockList;

					return [
						updateCredentialState({
							credentialId,
							reason: CredentialState.BLOCKED,
							description: translate.byKey('blocked_credential_number_v2'),
							includeInOfflineBlocklist,
						}),
						updateCredential({
							...credentialData,
							credentialHolderId: credentialData.credentialHolder.credentialHolderId,
							credentialIdentifiers: updateOnlineIdentifier(credentialData.credentialIdentifiers, true),
							description: moment(),
						}),
					];
				})
			);
			showSuccessToast(translate.byKey('blocked_credentials_successfully'));
			reloadBlockedCredentials();
			onClose();
		} catch (error) {
			// handled globally elsewhere atm
		}
	};

	return (
		<Modal
			open
			title={translate.byKey('block_credential')}
			onCancel={onClose}
			maskClosable={false}
			footer={[
				<Button key="cancel" onClick={onClose} disabled={isBlockingCredential}>
					{translate.byKey('cancel')}
				</Button>,
				<Button
					key="block"
					type="primary"
					onClick={onBlockButtonClick}
					disabled={isBlockingCredential || !selectedCredentialIds.length}
				>
					{translate.byKey('block_v2')}
				</Button>,
			]}
		>
			{isLoading || isOfflineBlockedCredentialsCountLoading || isOfflineBlockedCredentialsCountValidating ? (
				<div style={{ height: '50px' }}>
					<Spin active={true} />
				</div>
			) : (
				<>
					<Form component="div" name="block" layout="vertical" size="medium" preserve="false">
						<Item label={translate.byKey('which_credential_do_you_want_to_block_v2')}>
							<AutoComplete
								style={{ width: '350px' }}
								allowClear
								showSearch
								defaultActiveFirstOption
								onClear={() => {
									setSelectedCredentialHolderId(null);
									setSelectedCredentialIds([]);
								}}
								disabled={isBlockingCredential}
								placeholder={translate.byKey('blocklist_block_search')}
								options={selectOptions}
								value={selectedOptionLabel}
								onSelect={([_, credentialHolderId]) => {
									if (credentialHolderId !== selectedCredentialHolderId) {
										setSelectedCredentialHolderId(credentialHolderId);
										setSelectedCredentialIds([]);
									}
								}}
								optionFilterProp="label"
								filterOption={(input, { value }) => {
									const [credentialHolderName, , credentialIdsAsString] = value;
									const credentialIds = credentialIdsAsString.split(credentialIdSeparator);

									return (
										removeDiacritics(credentialHolderName)
											.toLowerCase()
											.includes(removeDiacritics(input).toLowerCase()) ||
										Boolean(
											credentialIds.filter((credentialId) =>
												credentialId.toLowerCase().includes(removeDiacritics(input).toLowerCase())
											).length
										)
									);
								}}
							/>
						</Item>

						{checkboxOptions.length ? (
							<Space css={checkboxGroupStyles} size="" direction="vertical">
								{checkboxOptions.map(({ value, label }) => {
									const checked = Boolean(selectedCredentialIds.find((credentialId) => credentialId === value));
									const isInOfflineList =
										offlineBlockedCredentialsCount +
											selectedCredentialIds.findIndex((credentialId) => credentialId === value) +
											1 <=
										systemLimits.BlockList;
									const colorBasedOnMode = isInOfflineList ? null : '#faad14';

									return (
										<Checkbox
											checked={checked}
											key={value}
											onChange={onCheckboxChange}
											value={value}
											disabled={isBlockingCredential}
											style={{ color: checked ? colorBasedOnMode : null }}
										>
											{label}
										</Checkbox>
									);
								})}
							</Space>
						) : null}

						{isOfflineBlockedCredentialsLimitReached ? (
							<Text style={{ marginTop: '24px', display: 'block' }} type="warning">
								{translate.byKey('blocked_offline_credentials_limit_reached')}
							</Text>
						) : (
							<Text style={{ marginTop: '24px', display: 'block' }} type="secondary">
								{translate.byKeyFormatted('blocked_offline_credentials_limit', [
									offlineBlockedCredentialsCount + selectedCredentialIds.length,
									systemLimits.BlockList,
								])}
							</Text>
						)}
					</Form>
				</>
			)}
		</Modal>
	);
};
