import { useNavigate } from 'react-router-dom';
import { omitBy } from 'lodash/object';

const isExcludedValue = (value) => value === '' || value === undefined;

/**
 * Returns all query params, and has an option to update/remove query param
 *
 * Based on: https://v5.reactrouter.com/web/example/query-parameters
 */
export const useQueryParams = () => {
	const navigate = useNavigate();

	const setQueryParams = (nameValuePairs) => {
		const newQueryParams = new URLSearchParams(
			omitBy(
				{
					...Object.fromEntries(new URLSearchParams(window.location.search)),
					...nameValuePairs,
				},
				isExcludedValue
			)
		);

		navigate({
			search: newQueryParams.toString(),
		});
	};

	const clearAllQueryParams = () => {
		navigate({
			search: '',
		});
	};

	return {
		queryParams: Object.fromEntries(new URLSearchParams(window.location.search)),
		setQueryParams,
		clearAllQueryParams,
	};
};
