import styled from '@emotion/styled';
import React from 'react';
import { background, border, flexbox, layout, size, space, system } from 'styled-system';

const StyledBackgroundImage = styled.div`
	position: ${(props) => props.position || 'relative'};
	top: ${(props) => props.top || ''};
	left: ${(props) => props.left || ''};
	background-position: ${(props) => props.backgroundPosition || 'left'};
	background-repeat: no-repeat;
	background-size: ${(props) => props.backgroundSize || 'cover'};
	${({ themeImage, theme }) => (themeImage ? `background-image: url("${theme.brand.images[themeImage]}");` : '')}
	${layout}
  ${space}
  ${border}
  ${flexbox}
  ${size}
  ${background}
  ${system({
		clipPath: {
			property: 'clipPath',
		},
	})}
`;

const BackgroundImage = (props) => {
	return <StyledBackgroundImage {...props} />;
};

export default BackgroundImage;
