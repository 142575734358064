const asset = {
	paginationType: 'links',
	base: {
		name: true,
		description: (data) => data.description || `Asset: ${data.name}`,
		type: (data) => data.type,
		mainParent: false,
		additionalParents: false,
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'site', ':siteId:', 'asset'],
};

export default asset;
