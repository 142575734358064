import sortBy from 'lodash/sortBy';
import { invisibleTimes } from './labels';

import { translator as translate } from '~/screens/_shared/AppLocale';

const holidayNameKey = 'holiday';
const newTypeKey = 'new_type';
const newTypeFormattedKey = 'new_type_formatted';

/**
 * First, moves a day from one time slot list to another.
 * Then sorts the nested list of days in the destination time slot.
 */
export const move = (action, state) => {
	const {
		to: { slotId: toSlotId },
		from: { slotId: fromSlotId, day, dayIndex },
	} = action.payload;

	let { timeSlots } = state;
	timeSlots = timeSlots.filter((item) => !!item);

	let fromSlot = timeSlots.find((o) => o.id === fromSlotId);
	let toSlot = timeSlots.find((o) => o.id === toSlotId);

	if (!fromSlot || !toSlot) {
		return timeSlots;
	}

	if (fromSlot.days && fromSlot.days.some((day) => day.type === 'HOLIDAY')) {
		return timeSlots;
	}

	if (toSlot.days && toSlot.days.some((day) => day.type === 'HOLIDAY')) {
		return timeSlots;
	}

	toSlot.days.push(day);

	fromSlot.days.splice(dayIndex, 1);

	if (fromSlot.days.length === 0) {
		timeSlots = timeSlots.filter((otherSlot) => otherSlot !== fromSlot);
	}

	timeSlots = timeSlots.map((slot) => {
		let { days } = slot;
		slot.days = sortBy(days, ['id']);
		return slot;
	});

	return timeSlots;
};

/**
 * Adds a new slot with a given day added to the slot's days list.
 */
export const addNew = (action, state) => {
	const {
		slot: { slotId, day, dayIndex },
	} = action.payload;

	let { timeSlots } = state;

	let fromSlot = timeSlots.find((o) => o.id === slotId);

	if (fromSlot.name === translate.byKey(holidayNameKey)) {
		return timeSlots;
	}

	const newTypeName = translate.byKey(newTypeKey);

	const newTypeItems = timeSlots
		.filter((slot) => slot.name.includes(newTypeName))
		.map((slot) => slot.name)
		.reduce((newItems, name) => {
			newItems.add(name);

			return newItems;
		}, new Set());

	let index = 1;
	let possibleNewName = newTypeName;

	do {
		if (!newTypeItems.has(possibleNewName)) {
			break;
		}
		index++;
		possibleNewName = translate.byKeyFormatted(newTypeFormattedKey, [index]);
	} while (newTypeItems.has(possibleNewName));

	let toSlot = {
		id: timeSlots.length,
		name: possibleNewName,
		modeTimeIntervals: [],
		days: [day],
	};

	timeSlots.push(toSlot);

	fromSlot.days.splice(dayIndex, 1);

	if (fromSlot.days.length === 0) {
		timeSlots = timeSlots.filter((otherSlot) => otherSlot !== fromSlot);
	}

	timeSlots = timeSlots.map((slot, index) => {
		let { days } = slot;
		slot.days = sortBy(days, ['id']);
		slot.id = index;

		return slot;
	});

	return timeSlots;
};

/**
 * Renames a slot with a given id.
 */
export const renameSlot = (action, state) => {
	let { slotId, label } = action.payload;

	let { timeSlots } = state;

	let currentSlot = timeSlots.find((o) => o.id === slotId);

	const holidayName = translate.byKey(holidayNameKey);

	if (currentSlot.name === holidayName) {
		return timeSlots;
	}

	if (label === '<br>') {
		label = '';
	}

	if (label === holidayName) {
		return timeSlots;
	}

	if (label && label.length > 0) {
		currentSlot.name = label;
	}

	return timeSlots;
};

/**
 * Update time values for a slot with a given id.
 */
export const updateSingleSlotInterval = (action, timeSlotsState) => {
	const { slotId, intervalData } = action.payload;

	let { timeSlots } = timeSlotsState;
	let currentSlot = timeSlots.find((o) => o.id === slotId);
	let { modeTimeIntervals } = currentSlot;
	const { startTime, endTime, state } = intervalData;
	modeTimeIntervals[intervalData.index] = {
		startTime,
		endTime,
		state,
	};

	return timeSlots;
};

/**
 * Removes a single interval period in a time slot
 */
export const deleteSingleSlotInterval = (action, timeSlotsState) => {
	const { slotId, index } = action.payload;

	let { timeSlots } = timeSlotsState;
	let currentSlot = timeSlots.find((o) => o.id === slotId);
	let { modeTimeIntervals } = currentSlot;

	modeTimeIntervals.splice(index, 1);

	return timeSlots;
};

/**
 * Adds a new single interval period in a time slot
 */
export const addIntervalToSlot = (action, timeSlotsState) => {
	const { slotId, interval } = action.payload;

	let { timeSlots } = timeSlotsState;
	let currentSlot = timeSlots.find((o) => o.id === slotId);
	let { modeTimeIntervals } = currentSlot;

	modeTimeIntervals.push(interval);

	return timeSlots;
};

/**
 * Maps the selected slots to a value array indicating which blocks are filled
 */
export const mapSlotsToValueArray = (slots) => {
	let valueArray = new Array(invisibleTimes.length).fill(0);

	slots.forEach((slot, index) => {
		valueArray.fill(index + 1, invisibleTimes.indexOf(slot.startTime), invisibleTimes.indexOf(slot.endTime));
	});

	return valueArray;
};

/**
 * Calculates the height/size of a time-slot interval block from the start and end times
 */
export const calculateSizeFromTimes = (startTime, endTime) => {
	const oldStartIndex = invisibleTimes.indexOf(startTime);
	const oldEndIndex = invisibleTimes.indexOf(endTime + 1);

	const verticalRowSpan = oldEndIndex - oldStartIndex;

	return Math.abs(verticalRowSpan);
};

export const isInOccupiedSpace = (firstIndex, lastIndex, occupiedBlocks, index = 0) => {
	const firstOccupiedIndex = occupiedBlocks.findIndex((element) => element > 0);

	let lastOccupiedIndex = occupiedBlocks.length - 1;
	let lastBlockedTimeIndex = 0;
	while (lastOccupiedIndex >= 0) {
		if (occupiedBlocks[lastOccupiedIndex] > 0) {
			lastBlockedTimeIndex = lastOccupiedIndex;
			break;
		}
		lastOccupiedIndex--;
	}

	if (firstOccupiedIndex === lastIndex || lastBlockedTimeIndex === firstIndex) {
		return false;
	}

	const firstIndexIsInOccupiedSpace = occupiedBlocks[firstIndex] !== 0 && occupiedBlocks[firstIndex] !== index;
	const lastIndexIsInOccupiedSpace = occupiedBlocks[lastIndex] !== 0 && occupiedBlocks[lastIndex] !== index;

	return firstIndexIsInOccupiedSpace || lastIndexIsInOccupiedSpace;
};
