import { css } from '@emotion/core';

export const weeklyCalendarStyles = ({ rowHeight }) =>
	css({
		display: 'grid',
		gridTemplateRows: `[days] auto
	[hour-start] auto
	[hour-0000] ${Math.round(rowHeight / 4)}px
	[hour-0100] ${Math.round(rowHeight / 4)}px
	[hour-0200] ${Math.round(rowHeight / 4)}px
	[hour-0300] ${Math.round(rowHeight / 4)}px
	[hour-0400] ${Math.round(rowHeight / 4)}px
	[hour-0500] ${Math.round(rowHeight / 4)}px
	[hour-0600] ${Math.round(rowHeight / 4)}px
	[hour-0700] ${Math.round(rowHeight / 4)}px
	[hour-0800] ${Math.round(rowHeight / 4)}px
	[hour-0900] ${Math.round(rowHeight / 4)}px
	[hour-1000] ${Math.round(rowHeight / 4)}px
	[hour-1100] ${Math.round(rowHeight / 4)}px
	[hour-1200] ${Math.round(rowHeight / 4)}px
	[hour-1300] ${Math.round(rowHeight / 4)}px
	[hour-1400] ${Math.round(rowHeight / 4)}px
	[hour-1500] ${Math.round(rowHeight / 4)}px
	[hour-1600] ${Math.round(rowHeight / 4)}px
	[hour-1700] ${Math.round(rowHeight / 4)}px
	[hour-1800] ${Math.round(rowHeight / 4)}px
	[hour-1900] ${Math.round(rowHeight / 4)}px
	[hour-2000] ${Math.round(rowHeight / 4)}px
	[hour-2100] ${Math.round(rowHeight / 4)}px
	[hour-2200] ${Math.round(rowHeight / 4)}px
	[hour-2300] ${Math.round(rowHeight / 4)}px
	[hour-2400] ${Math.round(rowHeight / 4)}px
	[hour-end] auto
	`,
		gridTemplateColumns: `[hours] auto
	[monday-start] 1fr
	[monday-end tuesday-start] 1fr
	[tuesday-end wednesday-start] 1fr
	[wednesday-end thursday-start] 1fr
	[thursday-end friday-start] 1fr
	[friday-end saturday-start] 1fr
	[saturday-end sunday-start] 1fr
	[sunday-end]
	`,
		textAlign: 'center',
	});

export const bodyColumnStyles = ({ day }) =>
	css({
		gridRow: `hour-0000 / hour-2400`,
		gridColumn: day,
		border: 'solid #eee',
		borderWidth: '0px 1px 0 0',
	});

const getBodyRowGridRow = (hourStep, currentRowIndex) => {
	const gridRowStart = `hour-${String(currentRowIndex * hourStep).padStart(2, '0')}00`;
	const gridRowEnd = `hour-${String(currentRowIndex * hourStep + hourStep).padStart(2, '0')}00`;

	return `${gridRowStart} / ${gridRowEnd}`;
};

export const bodyRowStyles = ({ hourStep, currentRowIndex }) =>
	css({
		gridRow: getBodyRowGridRow(hourStep, currentRowIndex),
		gridColumn: 'monday / sunday',
		border: 'solid #eee',
		borderWidth: `1px 1px ${currentRowIndex < Math.floor((24 - 1) / hourStep) ? 0 : '1px'} 1px`,
	});

export const dayStyles = ({ rowHeight, day }) =>
	css({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gridRow: 'days',
		gridColumn: day,
		border: 'solid #eee',
		borderWidth: `1px 1px 0 ${day === 'monday' ? '1px' : 0}`,
		height: rowHeight,
		'> div': {
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	});

const getHourGridRow = (hourStep, currentHour) => {
	const gridRowStart = currentHour === 0 ? 'days' : `hour-${String(currentHour - hourStep).padStart(2, '0')}00`;
	const gridRowEnd = currentHour === 0 ? 'hour-0000' : `hour-${String(currentHour).padStart(2, '0')}00`;

	return `${gridRowStart} / ${gridRowEnd}`;
};

export const hourStyles = ({ hourStep, currentHour }) =>
	css({
		gridColumn: 'hours',
		gridRow: getHourGridRow(hourStep, currentHour),
		fontSize: '12px',
		padding: '0 4px',
		width: '46px',
		height: 'auto',
		position: 'relative',
		'> span': {
			position: 'absolute',
			left: 0,
			top: 'calc(100% - 10px)', // cca
		},
	});

export const weeklyCalendarColors = ['#bae0ff', '#b5f5ec', '#d9f7be', '#ffd6e7', '#ffffb8'];

export const userDataStyles = ({ index }) =>
	css({
		border: '1px solid #999',
		opacity: 0.7,
		background: weeklyCalendarColors[index % weeklyCalendarColors.length],
		div: {
			opacity: 0.4,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	});
