import about from './about';
import card from './card';
import controller from './controller';
import doors from './doors';
import entry from './entry';
import exit from './exit';
import holiday from './holiday';
import key from './key';
import live from './live';
import noData from './no-data';
import people from './people';
import question from './question';
import reports from './reports';
import settings from './settings';
import site from './site';
import trash from './trash';
import calendar from './calendar';
import clock from './clock';
import personProhibited from './person-prohibited';
import accessGroup from './access-group';

const icons = {
	about,
	'access-group': accessGroup,
	calendar,
	card,
	clock,
	controller,
	doors,
	'entry-reader': entry,
	'exit-reader': exit,
	holiday,
	key,
	live,
	'no-data': noData,
	people,
	'person-prohibited': personProhibited,
	reports,
	settings,
	site,
	trash,
	question,
};

export default icons;
