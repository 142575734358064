import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';

import { getEmeaSystemKey, useEmeaSystem } from '~/hooks/data/useEmeaSystem';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const SystemDetails = () => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { data: emeaSystemData, isLoading: isEmeaSystemDataLoading } = useEmeaSystem(
		customerId && systemId ? getEmeaSystemKey({ customerId, systemId }) : null
	);
	const [form] = Form.useForm();

	useEffect(() => {
		if (form && emeaSystemData) {
			form.setFieldsValue({
				systemName: emeaSystemData?.name,
				systemType: emeaSystemData?.type,
				customerName: emeaSystemData?.endCustomerName,
				systemId: emeaSystemData?.id,
			});
		}
	}, [form, emeaSystemData]);

	return isEmeaSystemDataLoading ? (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Spin indicator={<LoadingOutlined style={{ fontSize: '36px' }} spin />} />
		</div>
	) : (
		<Form form={form} name="systemDetails" size="medium" layout="vertical">
			<Form.Item name="systemName" label={translate.byKey('system_name')}>
				<Input disabled />
			</Form.Item>

			<Form.Item name="systemType" label={translate.byKey('system_type')}>
				<Input disabled />
			</Form.Item>

			<Form.Item name="customerName" label={translate.byKey('customer_name')}>
				<Input disabled />
			</Form.Item>

			<Form.Item name="systemId" label={translate.byKey('system_id')}>
				<Input disabled />
			</Form.Item>
		</Form>
	);
};
