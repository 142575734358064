import { removeDiacritics } from '~/utils/string';

export const defaultSorter = (textOne = '', textTwo = '') =>
	removeDiacritics(textOne).toLowerCase().localeCompare(removeDiacritics(textTwo).toLowerCase());

export const API_SORT_MODE = {
	ASCENDING: 'ASCENDING',
	DESCENDING: 'DESCENDING',
};
export const antdToApiSortModes = {
	ascend: API_SORT_MODE.ASCENDING,
	descend: API_SORT_MODE.DESCENDING,
};
export const apiToAntdSortModes = {
	[API_SORT_MODE.ASCENDING]: 'ascend',
	[API_SORT_MODE.DESCENDING]: 'descend',
};
