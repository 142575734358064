export const CredentialState = {
	ENABLED: 'ENABLED',
	DISABLED: 'DISABLED',
	LOCKED_OUT: 'LOCKED_OUT',
	BLOCKED: 'BLOCKED',
	LOST: 'LOST',
	STOLEN: 'STOLEN',
	DAMAGED: 'DAMAGED',
	DESTROYED: 'DESTROYED',
	INACTIVITY: 'INACTIVITY',
	EXPIRED: 'EXPIRED',
	RENEWAL_NEEDED: 'RENEWAL_NEEDED',
	ANTIPASSBACK_VIOLATED: 'ANTIPASSBACK_VIOLATED',
};

export const CredentialRecognitionType = {
	CREDENTIAL_NUMBER: 'CREDENTIAL_NUMBER',
	PIN: 'PIN',
};
