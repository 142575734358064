import React from 'react';
import { Menu } from 'antd';
import { css, Global } from '@emotion/core';

export const NAVIGATION_MENU_POPUP_CLASSNAME = 'assa-ant-navigation-menu-popup';

export const NavigationMenu = (props) => (
	<React.Fragment>
		<Menu
			{...props}
			mode="horizontal"
			overflowedIndicatorPopupClassName={NAVIGATION_MENU_POPUP_CLASSNAME}
			css={menuStyles}
		/>
		<Global styles={globalStyle} />
	</React.Fragment>
);

const globalStyle = (theme) =>
	css(`
		.${NAVIGATION_MENU_POPUP_CLASSNAME} .ant-menu-title-content:hover {
			color: ${theme.colors.brand[500]} !important;
		}

		.${NAVIGATION_MENU_POPUP_CLASSNAME} .ant-menu-item {
			line-height: 30px;
			height: 30px;
		}
`);

const menuStyles = (theme) =>
	css({
		flex: 'auto',
		height: '100%',
		background: 'transparent',
		borderBottom: 'none',
		fontSize: 16,

		'.ant-menu-item, .ant-menu-submenu': {
			marginTop: '0 !important',
			top: '0 !important',
			padding: '0 !important',
			borderBottom: 'none !important',
			color: 'white !important',
		},

		'& > .ant-menu-overflow-item.ant-menu-submenu > .ant-menu-submenu-title, & > .ant-menu-item > .ant-menu-title-content, & > .ant-menu-submenu > .-menu-submenu-title':
			{
				color: 'white !important',
				padding: '0 20px',
				display: 'flex',
				alignItems: 'center',
				height: '100%',
			},

		'.ant-menu-item:hover, .ant-menu-submenu:hover': {
			backgroundColor: `${theme.colors.brand[400]} !important`,
		},

		'.ant-menu-item-selected': {
			backgroundColor: `${theme.colors.brand[700]} !important`,
		},

		'& > .ant-menu-item::after, & > .ant-menu-submenu::after': {
			display: 'none',
		},
	});
