import React from 'react';
import { Typography, Space } from 'antd';
import { getCurrentAdministrator, getHighestPrivilegedRole } from '~/screens/_shared/userRoleConstants';
import { useLocale } from '~/screens/_shared/AppLocale';
import { css } from '@emotion/core';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const { Text } = Typography;

export const UserInfo = () => {
	const { translate } = useLocale();
	const {
		data: { system },
	} = useCurrentSystemSite();

	const admin = getCurrentAdministrator(system) || {};

	const name = admin.firstName && admin.lastName ? `${admin.firstName} ${admin.lastName}` : admin.username;

	const role = getHighestPrivilegedRole(system) || {};

	return (
		<Space size="middle" direction="horizontal">
			<Text strong css={textStyles}>
				{name}
			</Text>
			<Text strong css={textStyles}>
				{role.name ? translate.byKey(role.name) || translate.byValue(role.name) || role.name : ''}
			</Text>
		</Space>
	);
};

const textStyles = css({
	color: 'white',
});
