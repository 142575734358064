import React, { useMemo, useState } from 'react';
import { Button, Form, message, Modal, Spin, Steps } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import Tabs from 'antd/lib/tabs';
import { LoadingOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import { css } from '@emotion/core';

import { useLocale } from '~/screens/_shared/AppLocale';
import { BasicInfo } from '~/components/features/access-profiles/modals/form/BasicInfo/BasicInfo';
import {
	doorGroupsField,
	doorsField,
	locationField,
	nameField,
	peopleField,
} from '~/components/features/access-profiles/modals/form/fields';
import { AddDoorGroups } from '~/components/features/access-profiles/modals/form/AddDoorGroups/AddDoorGroups';
import { AddDoors } from '~/components/features/access-profiles/modals/form/AddDoors/AddDoors';
import { getAccessProfileKey, useAccessProfile } from '~/hooks/data/access-profiles/useAccessProfile';
import { AddPeople } from '~/components/features/access-profiles/modals/form/AddPeople/AddPeople';
import { Summary } from '~/components/features/access-profiles/modals/form/Summary/Summary';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const AccessProfileUpdateModal = ({ selectedAccessProfileId, onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: selectedSite,
		},
	} = useCurrentSystemSite();
	const { siteId } = selectedSite;
	const { data: accessProfile } = useAccessProfile(
		getAccessProfileKey({
			customerId,
			systemId,
			siteId,
			id: selectedAccessProfileId,
		})
	);
	const [form] = useForm();
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [isStepTransitionEnabled, setIsStepTransitionEnabled] = useState(true);
	const values = useWatch([], form);
	const isInitialAndCurrentDataEqual = useMemo(() => isEqual(values, accessProfile), [values, accessProfile]);

	const steps = [
		{
			title: translate.byKey('basic_info'),
			key: 0,
			children: <BasicInfo />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 0,
		},
		{
			title: translate.byKey('add_door_groups'),
			key: 1,
			children: <AddDoorGroups />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 1,
		},
		{
			title: translate.byKey('add_doors'),
			key: 2,
			children: <AddDoors />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 2,
		},
		{
			title: translate.byKey('add_people'),
			key: 3,
			children: <AddPeople onStepTransitionEnable={(value) => setIsStepTransitionEnabled(value)} />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 3,
		},
		{
			title: translate.byKey('summary'),
			key: 4,
			children: <Summary />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 4,
		},
	];
	const isFirstStep = currentStepIndex === 0;
	const isLastStep = currentStepIndex === steps.length - 1;
	const isUpdatingAccessProfile = false;
	const isDeletingAccessProfile = false;

	const onSubmit = async () => {
		form
			.validateFields()
			.then((values) => {
				if (values['name'] === 'unique') {
					form.setFields([
						{
							name: 'name',
							errors: [translate.byKey('duplicated_access_profile_name_message')],
						},
					]);
				} else {
					onClose();
					message.success(translate.byKey('access_profile_updated_successfully'));
					alert(JSON.stringify(values));
				}
			})
			.catch(() => {});
	};

	const onDeleteButtonClick = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('access_profile_delete_confirmation_message'),
			okText: translate.byKey('delete'),
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				onClose();
				message.success(translate.byKey('access_profile_deleted_successfully'));
				alert('deleting...');
			},
		});
	};

	const onModalDismiss = () => {
		if (!isInitialAndCurrentDataEqual) {
			Modal.confirm({
				title: translate.byKey('unsaved_changes'),
				content: translate.byKey('discard_changes_message'),
				okText: translate.byKey('yes'),
				cancelText: translate.byKey('no'),
				onOk: () => {
					onClose();
				},
			});
		} else {
			onClose();
		}
	};

	const onStepChange = (index) => {
		form
			.validateFields()
			.then(() => {
				if (index >= 0 && index < steps.length) {
					setCurrentStepIndex(index);
				}
			})
			.catch(() => {});
	};

	const onBackButtonClick = () => {
		form
			.validateFields()
			.then(() => {
				setCurrentStepIndex((previousStep) => previousStep - 1);
			})
			.catch(() => {});
	};
	const onNextButtonClick = () => {
		form
			.validateFields()
			.then(() => {
				setCurrentStepIndex((previousStep) => previousStep + 1);
			})
			.catch(() => {});
	};

	return (
		<Modal
			open
			width={800}
			title={accessProfile?.name || '...'}
			maskClosable={false}
			onCancel={onModalDismiss}
			footer={
				<div css={footerStyles({ isFirstStep, isStepTransitionEnabled })}>
					{!isFirstStep && isStepTransitionEnabled ? (
						<Button disabled={isUpdatingAccessProfile || isDeletingAccessProfile} onClick={onBackButtonClick}>
							{translate.byKey('back')}
						</Button>
					) : null}

					<span>
						<Button disabled={isUpdatingAccessProfile || isDeletingAccessProfile} onClick={onModalDismiss}>
							{translate.byKey('cancel')}
						</Button>

						{isStepTransitionEnabled ? (
							<Button
								type="primary"
								onClick={onDeleteButtonClick}
								danger
								disabled={isUpdatingAccessProfile || isDeletingAccessProfile}
								loading={isDeletingAccessProfile}
							>
								{translate.byKey('delete')}
							</Button>
						) : null}

						{!isLastStep && isStepTransitionEnabled ? (
							<Button type="primary" onClick={onNextButtonClick}>
								{translate.byKey('next')}
							</Button>
						) : null}

						{isLastStep && isStepTransitionEnabled ? (
							<Button
								type="primary"
								disabled={isUpdatingAccessProfile || isDeletingAccessProfile || isInitialAndCurrentDataEqual}
								loading={isUpdatingAccessProfile}
								onClick={onSubmit}
							>
								{translate.byKey('update')}
							</Button>
						) : null}
					</span>
				</div>
			}
		>
			{accessProfile ? (
				<>
					<Steps css={stepsStyles} current={currentStepIndex} items={steps} size="small" onChange={onStepChange} />

					<Form
						initialValues={{
							[locationField]: selectedSite.id,
							[nameField]: accessProfile.name,
							[doorGroupsField]: accessProfile.doorGroups,
							[doorsField]: accessProfile.doors,
							[peopleField]: accessProfile.people,
						}}
						layout="vertical"
						form={form}
						autoComplete="off"
					>
						<Tabs
							activeKey={currentStepIndex}
							renderTabBar={() => null}
							items={steps.map((step) => ({
								...step,
								forceRender: true,
							}))}
						/>
					</Form>
				</>
			) : (
				<div css={loadingContainerStyles}>
					<Spin indicator={<LoadingOutlined css={loadingIconStyles} spin />} />
				</div>
			)}
		</Modal>
	);
};

const footerStyles = ({ isFirstStep, isStepTransitionEnabled }) =>
	css({ display: 'flex', justifyContent: isFirstStep || !isStepTransitionEnabled ? 'flex-end' : 'space-between' });

const stepsStyles = css({
	marginBottom: '20px',
	'.ant-steps-item-disabled .ant-steps-item-container': {
		opacity: 0.3,
	},
});

const loadingContainerStyles = css({ display: 'flex', justifyContent: 'center', alignItems: 'center' });

const loadingIconStyles = css({ fontSize: '36px' });
