export const cardFormatData = {
	H10302_37: {
		text: 'H10302 (37-bit)',
		name: 'H10302 37 Bit',
		mask: 'PNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNP',
		bitsDecimal: 35,
		hasFacilityCode: false,
	},
	H10301_26: {
		text: 'H10301 (26-bit)',
		name: 'H10301 26 Bit',
		mask: 'PFFFFFFFFNNNNNNNNNNNNNNNNP',
		bitsDecimal: 16,
		hasFacilityCode: true,
	},
	H10304_37: {
		text: 'H10304 (37-bit)',
		name: 'H10304 37 Bit',
		mask: 'PFFFFFFFFFFFFFFFFNNNNNNNNNNNNNNNNNNNP',
		bitsDecimal: 19,
		hasFacilityCode: true,
	},
	Corporate_1000_35: {
		text: 'Corporate 1000 (35-bit)',
		name: 'Corporate 1000 35 Bit',
		mask: 'PPFFFFFFFFFFFFNNNNNNNNNNNNNNNNNNNNP',
		bitsDecimal: 20,
		hasFacilityCode: true,
	},
	Corporate_1000_48: {
		text: 'Corporate 1000 (48-bit)',
		name: 'Corporate 1000 48 Bit',
		mask: 'PPFFFFFFFFFFFFFFFFFFFFFFNNNNNNNNNNNNNNNNNNNNNNNP',
		bitsDecimal: 23,
		hasFacilityCode: true,
	},
	Passthrough: {
		text: 'Passthrough',
		name: 'Passthrough',
		mask: '',
		bitsDecimal: 52,
		hasFacilityCode: false,
	},
};
