export function waitTimeout(callback, timeout) {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			try {
				resolve(callback());
			} catch (ex) {
				reject(ex);
			}
		}, timeout);
	});
}
