import React from 'react';
import { Button } from 'antd';

import { useLocale } from '~/screens/_shared/AppLocale';

export const ResetApplyFilterPanel = ({ resetDisabled, applyDisabled, onReset, onApply }) => {
	const { translate } = useLocale();

	return (
		<div
			style={{
				padding: '8px',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<Button
				style={{
					marginRight: '8px',
				}}
				disabled={resetDisabled}
				size="small"
				onClick={onReset}
				type="primary"
				ghost
			>
				{translate.byKey('reset')}
			</Button>
			<Button disabled={applyDisabled} size="small" onClick={onApply} type="primary">
				{translate.byKey('apply')}
			</Button>
		</div>
	);
};
