import { css } from '@emotion/core';

export const collapsePanelStyles = css({
	'.ant-collapse-content-box': {
		padding: 0,
	},
});

export const collapseStyles = css({
	background: 'transparent',
	'.ant-collapse-item': {
		borderColor: '#f0f0f0',
		':last-child': {
			border: 0,
		},
	},
});

export const generateOtpButtonRowStyles = css({
	display: 'flex',
	alignItems: 'center',
	button: {
		marginRight: '16px',
	},
});

export const validityStyles = css({
	display: 'flex',
	'div:last-child': {
		marginLeft: '8px',
		fontWeight: 'bold',
	},
});
