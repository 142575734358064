import { useListApi } from '~/screens/_shared/useApi';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

const useGetEntitlements = () => {
	const {
		data: { system: currentSystem },
	} = useCurrentSystemSite();
	const [getEntitlements] = useListApi('/customer/:customerId/system/:systemId:/entitlement');

	return [
		async () => {
			try {
				const entitlements = await getEntitlements({
					customerId: currentSystem.customerId,
					systemId: currentSystem.systemId,
					ignoreGlobalHandlers: true,
				});

				return { ...currentSystem, entitlements };
			} catch {}

			return currentSystem;
		},
	];
};

export default useGetEntitlements;
