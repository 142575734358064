import React, { useEffect, useState } from 'react';
import { useCalendars } from '~/hooks/features/calendars/useCalendars';
import { getDeleteHolidayKey, useDeleteHoliday } from '~/hooks/data/holidays/useDeleteHoliday';
import { getPutHolidayKey, usePutHoliday } from '~/hooks/data/holidays/usePutHoliday';
import { Space, Button, message } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Modal } from '~/components';
import { HolidayForm, HOLIDAY_FORM_NAME } from '~/components/features/holidays/HolidayForm/HolidayForm';
import { css } from '@emotion/core';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const EditHolidayModal = ({ selected, open, onClose, onDelete, onUpdate }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { defaultCalendarId } = useCalendars();

	const { trigger: deleteHoliday, isMutating: isDeleteLoading } = useDeleteHoliday(
		getDeleteHolidayKey({
			customerId,
			systemId,
			calendarId: defaultCalendarId,
		})
	);

	const { trigger: updateHoliday, isMutating: isUpdateLoading } = usePutHoliday(
		getPutHolidayKey({
			customerId,
			systemId,
			calendarId: defaultCalendarId,
		})
	);

	const isDeleteButtonShown = hasActionPermissions(user, userConstants.screens.HOLIDAYS, userConstants.actions.DELETE);
	const isSubmitButtonShown = hasActionPermissions(user, userConstants.screens.HOLIDAYS, userConstants.actions.WRITE);

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	useEffect(() => setIsSubmitDisabled(true), [open]);

	const onFormChange = (form) => {
		form
			.validateFields({ validateOnly: true })
			.then(() => setIsSubmitDisabled(false))
			.catch(({ errorFields }) => setIsSubmitDisabled(errorFields.length));
	};

	const onFormSubmit = async (form) => {
		const { name, description, dates, recurring } = form.getFieldsValue();
		await updateHoliday({
			name,
			description: description,
			calendarId: defaultCalendarId,
			start: dates[0].format('YYYY-MM-DDTHH:mm:ss'),
			end: dates[1].format('YYYY-MM-DDTHH:mm:ss'),
			recurring,
			specialDayId: selected?.specialDayId,
			version: selected?.version,
		});

		message.success(translate.byKey('holiday_updated_successfully'));
		onUpdate();
	};

	const onDeleteHoliday = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('are_you_sure_you_want_to_delete_this_holiday'),
			cancelText: translate.byKey('cancel'),
			okText: translate.byKey('delete'),
			onOk: () => {
				deleteHoliday({ calendarId: defaultCalendarId, specialDayId: selected?.specialDayId }).then(() => {
					message.success(translate.byKeyFormatted('holiday_deleted_successfully'));
					onDelete();
				});
			},
		});
	};

	return (
		<Modal
			open={open}
			maskClosable={false}
			forceRender={true}
			destroyOnClose
			loading={isDeleteLoading || isUpdateLoading}
			title={selected?.name}
			onCancel={onClose}
			footer={[
				<Space align="center" css={spaceStyle}>
					{isDeleteButtonShown ? (
						<Button key="delete" type="primary" danger loading={isDeleteLoading} onClick={onDeleteHoliday}>
							{translate.byKey('delete')}
						</Button>
					) : null}

					<Button key="cancel" disabled={isDeleteLoading || isUpdateLoading} onClick={onClose}>
						{translate.byKey('cancel')}
					</Button>

					{isSubmitButtonShown ? (
						<Button
							key="submit"
							htmlType="submit"
							type="primary"
							form={HOLIDAY_FORM_NAME}
							disabled={isSubmitDisabled || isDeleteLoading}
							loading={isUpdateLoading}
						>
							{translate.byKey('update')}
						</Button>
					) : null}
				</Space>,
			]}
		>
			{open ? <HolidayForm selected={selected} onFormChange={onFormChange} onFormSubmit={onFormSubmit} /> : null}
		</Modal>
	);
};

const spaceStyle = css({
	width: '100%',
	justifyContent: 'flex-end',
});
