import mappers from '~/screens/_shared/mappers';
import { method } from '~/screens/_shared/useApi/apiConstants';
import camelCase from 'lodash/camelCase';

export function getKey(apiEntity) {
	return Object.entries(mappers)
		.filter(([, value]) => value === apiEntity)
		.map(([key]) => key)
		.pop();
}

function isSuccessStatusCode(statusCode) {
	return statusCode >= 200 && statusCode <= 299;
}

function mergeKeyAndAttributes(item) {
	if (item === null || item === undefined) {
		return item;
	}

	if ('key' in item && typeof item['key'] === 'object') {
		item = Object.assign(item, item['key']);
		delete item.key;
	}

	if ('attributes' in item && typeof item['attributes'] === 'object') {
		item = Object.assign(item, item['attributes']);
		delete item.attributes;
	}

	for (const key of Object.keys(item)) {
		if (typeof item[key] === 'object') {
			item[key] = mergeKeyAndAttributes(item[key]);
		}
	}

	return item;
}

export function extractIdsFromHref(link) {
	if (!(link && link.href)) {
		return {};
	}

	let lastKey = null;
	return link.href.split('/').reduce((keys, item) => {
		if (lastKey) {
			lastKey = camelCase(`${lastKey}_id`);
			keys[lastKey] = item;
			lastKey = null;
			return keys;
		}

		if (item in mappers) {
			lastKey = item;
		} else if (camelCase(item) in mappers) {
			lastKey = camelCase(item);
		} else {
			lastKey = null;
		}

		return keys;
	}, {});
}

function formatResult(item) {
	if (Array.isArray(item.links)) {
		const self = item.links.find((link) => (link || {}).rel === 'self');

		if (self && self.href) {
			const keys = extractIdsFromHref(self);

			if (Object.keys(keys).length > 0) {
				item.key = Object.assign(keys, item.key || {});
			}
		}
	}

	item = mergeKeyAndAttributes(item);

	if (item.label || item.name) {
		item.label = item.label || item.name;
	}

	return item;
}

function parsePaginationLink(link) {
	const href = link && link.href && link.href.split ? link.href : '';

	const params = (href.split('?').pop() || '').split('&');
	return Object.assign(
		link,
		params.reduce(
			(results, param) => {
				if (param.includes('=')) {
					const splitParam = param.split('=');

					if (splitParam.length === 2) {
						results[camelCase(splitParam[0])] = isNaN(Number(splitParam[1]))
							? splitParam[1]
							: Number(splitParam[1]) || 0;
					}

					if (results.page) {
						results.pageNumber = results.page;
						delete results.page;
					}
				}

				return results;
			},
			{ pageNumber: 0, pageSize: 0 }
		)
	);
}

const formatResponse = (response, apiEntity, apiMethod) => {
	let flattenedData = null;

	apiEntity = getKey(apiEntity) || apiEntity;

	if (response) {
		if (apiMethod === method.list && response.data) {
			let listKey = apiEntity.toLocaleLowerCase && !apiEntity.includes('/') ? `${apiEntity}s` : null;

			const altListKey = Object.entries(response.data)
				.filter(([, entry]) => Array.isArray(entry))
				.map(([key]) => key)[0];

			let data = [];
			if (listKey && listKey in response.data) {
				data = (response.data[listKey] || []).map(formatResult);
			} else if (altListKey && altListKey !== 'links' && altListKey in response.data) {
				listKey = altListKey;
				data = (response.data[altListKey] || []).map(formatResult);
			}
			const mapper = mappers[apiEntity] || apiEntity;
			if (Array.isArray(response.data.links) || (mapper && mapper.paginationType === 'links')) {
				response.data.links = response.data.links || [];
				const first = response.data.links.find((link) => link.rel === 'first');
				const last = response.data.links.find((link) => link.rel === 'last');
				const next = response.data.links.find((link) => link.rel === 'next');

				if (first || last || next || (mapper && mapper.paginationType === 'links')) {
					const pagination = {
						first: parsePaginationLink(Object.assign({}, first || {})),
						last: parsePaginationLink(Object.assign({}, last || {})),
						next: parsePaginationLink(Object.assign({}, next || {})),
					};

					if (data.length > 0 && pagination.first.pageNumber === 0 && pagination.first.pageSize === 0) {
						pagination.first.pageNumber = 1;
						pagination.first.pageSize = data.length;
						pagination.last.pageNumber = 1;
						pagination.last.pageSize = data.length;
						pagination.next.pageNumber = 1;
						pagination.next.pageSize = data.length;
					}

					return {
						[listKey]: data,
						pagination,
						totalRecords: isNaN(Number(response.headers['totalitems']))
							? data.length
							: Number(response.headers['totalitems']),
					};
				}
			}

			return data;
		}

		const item = response.data || {};

		if (typeof item !== 'string') {
			if (!item.links && response.headers && response.headers.location) {
				item.links = [{ rel: 'self', href: response.headers.location }];
			}

			if (method.put || method.delete) {
				item.success = isSuccessStatusCode(response.status);
			}

			if (response.headers?.administratorid) {
				item.administratorId = response.headers.administratorid;
			}

			return formatResult(item);
		}

		return item;
	}

	return flattenedData;
};

export default formatResponse;
