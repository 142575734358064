import React, { useState } from 'react';

import { Page } from '~/components';
import { AccessProfilesTable } from '~/components/features/access-profiles/AccessProfilesTable/AccessProfilesTable';
import { AccessProfilesPageHeader } from '~/components/features/access-profiles/AccessProfilesPageHeader/AccessProfilesPageHeader';
import { AccessProfileCreateModal } from '~/components/features/access-profiles/modals/AccessProfileCreateModal/AccessProfileCreateModal';
import { AccessProfileUpdateModal } from '~/components/features/access-profiles/modals/AccessProfileUpdateModal/AccessProfileUpdateModal';

export const AccessProfilesPage = () => {
	const [isCreatingAccessProfile, setIsCreatingAccessProfile] = useState(false);
	const [selectedAccessProfileId, setSelectedAccessProfileId] = useState(null);

	// TODO MM handle roles per user types
	// TODO MM handle translations
	// TODO MM handle table sorters/filters

	return (
		<Page>
			{isCreatingAccessProfile ? <AccessProfileCreateModal onClose={() => setIsCreatingAccessProfile(false)} /> : null}
			{selectedAccessProfileId ? (
				<AccessProfileUpdateModal
					selectedAccessProfileId={selectedAccessProfileId}
					onClose={() => setSelectedAccessProfileId(null)}
				/>
			) : null}
			<AccessProfilesPageHeader onAddNewButtonClick={() => setIsCreatingAccessProfile(true)} />
			<AccessProfilesTable onRowClick={setSelectedAccessProfileId} />
		</Page>
	);
};
