import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, TimePicker } from 'antd';
import { css } from '@emotion/core';

import { transPlacement2DropdownAlign } from '~/temporary/AntdTemporaryFixUtils';
import { useLocale, translator } from '~/screens/_shared/AppLocale';
import { isMomentTimeBeforeOtherMomentTime } from '~/utils/dates';
import moment from 'moment';

const { RangePicker } = TimePicker;

const toMomentFormatted = (value) => moment(value).format('LT');

export const getTimeRangeFilterValueLabel = ({ startTime, endTime }) => {
	if (Boolean(startTime) && Boolean(endTime)) {
		return `${toMomentFormatted(startTime)} - ${toMomentFormatted(endTime)}`;
	} else if (startTime) {
		return `${translator.byKey('from')} ${toMomentFormatted(startTime)}`;
	} else {
		return `${translator.byKey('until')} ${toMomentFormatted(endTime)}`;
	}
};

// https://github.com/ant-design/ant-design/issues/21189
export const TimeRangeFilter = ({ antdFilterProps }) => {
	const { translate } = useLocale();
	const { setSelectedKeys, selectedKeys, confirm, visible, clearFilters } = antdFilterProps;
	const [isPickerOpened, setIsPickerOpened] = useState(false);
	const containerElement = useRef(null);

	useLayoutEffect(() => {
		// resolving issue with opening calendar immediatelly with different popup container
		setTimeout(() => {
			setIsPickerOpened(visible);
		}, 1);

		if (visible) {
			// forcing specific range; start from 7 am
			setTimeout(() => {
				const hourColumnElement = containerElement?.current?.querySelector('.ant-picker-time-panel-column');
				const initialHourInView = hourColumnElement.getElementsByClassName('ant-picker-time-panel-cell-inner')[7];
				initialHourInView.scrollIntoView();
			}, 100);
		}
	}, [visible]);

	const onTimeUpdate = (newValues) => {
		const newStartTime = newValues[0];
		const newEndTime = newValues[1];

		if (newStartTime && newEndTime) {
			if (isMomentTimeBeforeOtherMomentTime(newStartTime, newEndTime)) {
				setSelectedKeys([{ startTime: newStartTime, endTime: newEndTime }]);
			} else {
				const newStartTimeHours = newStartTime.hours();
				const newStartTimeMinutes = newStartTime.minutes();
				const newEndTimeHours = newEndTime.hours();
				const newEndTimeMinutes = newEndTime.minutes();

				setSelectedKeys([
					{
						startTime: newStartTime.set({ hours: newEndTimeHours, minutes: newEndTimeMinutes }),
						endTime: newEndTime.set({ hours: newStartTimeHours, minutes: newStartTimeMinutes }),
					},
				]);
			}
		} else {
			setSelectedKeys([{ startTime: newStartTime, endTime: newEndTime }]);
		}

		confirm();
	};

	const onReset = () => {
		setSelectedKeys(null);
		clearFilters({ confirm: true, closeDropdown: true });
	};

	return (
		<div
			ref={containerElement}
			css={css`
            padding: 8px;
            .ant-picker-content {
              height: 320px;
            }
            .ant-picker-footer {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .ant-picker-footer-extra { 
                padding: 0;
            } 
            .ant-picker-time-panel-column {
              width: 70px;
              padding-bottom: 0;
            }
            .ant-picker-range-wrapper {
              /* we have only 2 columns (h and m), but the width is for 3 (s), and you can not dismiss
                 the component if you are clicking in this hidden area. And it is an inline style so !important
                 is needed.
               */
              min-width: 156px !important;
            }
            .ant-picker-dropdown {
              /* related to above */
              left: 0 !important;
            }
            .ant-picker-active-bar {
              height: 0px;
            }
            .ant-picker-input-active input {
              font-weight: bold;
              color: #00a0d0;
              font-size: 20px;
            }
            .ant-picker-input input {
              text-align: center;
            }
            .ant-picker-input :not(.ant-picker-input-active) input {
              color: #585959;
            }
            .ant-picker-time-panel-cell-inner {
              height: 24px !important;
            }
        }`}
		>
			<RangePicker
				open={isPickerOpened}
				allowClear={false}
				value={[selectedKeys[0]?.startTime, selectedKeys[0]?.endTime]}
				format="HH:mm"
				minuteStep={15}
				onOk={onTimeUpdate}
				placeholder={[translate.byKey('start_time'), translate.byKey('end_time')]}
				renderExtraFooter={() => (
					<Button size="small" onClick={onReset} type="primary" ghost style={{ marginLeft: 12 }}>
						{translate.byKey('reset')}
					</Button>
				)}
				getPopupContainer={() => containerElement.current}
				// TODO WHEN ANTD IS UPDATED TO AT LEAST 4.6.6 THEN REMOVE THIS FUNCTION AND THIS PROP
				// This handles the issue where the picker dropdown jumped out of the place, it is a reproducible bug in Antd < 4.6.6
				dropdownAlign={transPlacement2DropdownAlign('ltr', 'bottomRight')}
			/>
		</div>
	);
};
