import styled from '@emotion/styled';
import { Tag as ATag } from 'antd';
import React from 'react';

const StyledTag = styled(ATag)`
	height: 30px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 12px;
	font-family: Verdana;
`;
const Tag = (props) => {
	return <StyledTag {...props} />;
};

export default Tag;
