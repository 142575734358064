import { useCreatePerson } from './useCreatePerson';
import { useUpdatePerson } from './useUpdatePerson';
import { useDeletePerson } from './useDeletePerson';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export const useSavePerson = (setIsLoading, onUpdated, onClose) => {
	const [createPerson, creating, createStep] = useCreatePerson();
	const [updatePerson, updating, updateStep] = useUpdatePerson();
	const [deletePerson, deleting, deleteStep] = useDeletePerson();

	const onFinish = (values) => {
		setIsLoading(true);
		try {
			if (values.shouldDelete) {
				if (!isEmpty(values)) {
					deletePerson(values)
						.then(() => {
							onUpdated({ ...values, action: 'delete' });
						})
						.catch(console.error);
				}
				return;
			}

			const newValues = {
				...values,
				startDateTime: values.startDateTime
					? moment(values.startDateTime).format()
					: values.validityDateTime && Array.isArray(values.validityDateTime) && values.validityDateTime[0]
					  ? moment(values.validityDateTime[0]).format()
					  : undefined,
				endDateTime: values.endDateTime
					? moment(values.endDateTime).format()
					: values.validityDateTime && Array.isArray(values.validityDateTime) && values.validityDateTime[1]
					  ? moment(values.validityDateTime[1]).format()
					  : undefined,
				email: values.email?.toLowerCase(),
				pin: values.pin,
			};

			if (!newValues.credentialHolderVersion) {
				createPerson(newValues)
					.then(async (results) => {
						results.action = 'create';
						onUpdated(results);
					})
					.catch((error) => {
						onClose();
					});
			} else {
				updatePerson(newValues)
					.then(async (results) => {
						results.action = 'update';
						onUpdated(results);
					})
					.catch((error) => {
						onClose();
					});
			}
		} finally {
			setIsLoading(false);
		}
	};

	return [onFinish, creating || updating || deleting, createStep || updateStep || deleteStep];
};
