import { getAccessSchedulesKey } from '~/hooks/data/access-schedules/useAccessSchedules';
import { DEFAULT_PAGE_SIZE } from '~/constants/Pagination';
import { API_SORT_MODE } from '~/utils/sort';

export const getDefaultAccessSchedulesKey = ({
	customerId,
	systemId,
	pageSize,
	pageNumber,
	name,
	sortBy,
	sortDirection,
}) =>
	getAccessSchedulesKey({
		customerId,
		systemId,
		pageSize: pageSize || DEFAULT_PAGE_SIZE,
		pageNumber: pageNumber || 1,
		name,
		sortBy: sortBy || 'name',
		sortDirection: sortDirection || API_SORT_MODE.ASCENDING,
	});
