export function getReadableName(name) {
	name = name || '';
	if (name.includes('|')) {
		return name
			.split('|')
			.filter((x) => isNaN(Number(x)))
			.join(' ');
	}

	return name;
}
