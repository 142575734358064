import React, { useState } from 'react';
import { Button, Form, message, Modal, Steps } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import Tabs from 'antd/lib/tabs';
import { css } from '@emotion/core';

import { useLocale } from '~/screens/_shared/AppLocale';
import { BasicInfo } from '~/components/features/access-profiles/modals/form/BasicInfo/BasicInfo';
import { locationField, nameField } from '~/components/features/access-profiles/modals/form/fields';
import { AddDoorGroups } from '~/components/features/access-profiles/modals/form/AddDoorGroups/AddDoorGroups';
import { AddDoors } from '~/components/features/access-profiles/modals/form/AddDoors/AddDoors';
import { AddPeople } from '~/components/features/access-profiles/modals/form/AddPeople/AddPeople';
import { Summary } from '~/components/features/access-profiles/modals/form/Summary/Summary';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const AccessProfileCreateModal = ({ onClose }) => {
	const { translate } = useLocale();
	const [form] = useForm();
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [isStepTransitionEnabled, setIsStepTransitionEnabled] = useState(true);
	const {
		data: { site: selectedSite },
	} = useCurrentSystemSite();
	const name = useWatch([nameField], form);

	const steps = [
		{
			title: translate.byKey('basic_info'),
			key: 0,
			children: <BasicInfo />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 0,
		},
		{
			title: translate.byKey('add_door_groups'),
			key: 1,
			children: <AddDoorGroups />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 1,
		},
		{
			title: translate.byKey('add_doors'),
			key: 2,
			children: <AddDoors />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 2,
		},
		{
			title: translate.byKey('add_people'),
			key: 3,
			children: <AddPeople onStepTransitionEnable={(value) => setIsStepTransitionEnabled(value)} />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 3,
		},
		{
			title: translate.byKey('summary'),
			key: 4,
			children: <Summary />,
			disabled: !isStepTransitionEnabled && currentStepIndex !== 4,
		},
	];
	const isFirstStep = currentStepIndex === 0;
	const isLastStep = currentStepIndex === steps.length - 1;
	const isCreatingAccessProfile = false;
	const isCurrentDataMinimallyFilled = Boolean(name);
	const initialValues = {
		[locationField]: selectedSite?.id || null,
	};

	const onSubmit = async () => {
		form
			.validateFields()
			.then((values) => {
				// TODO MM handle unique when the BE support is done
				if (values['name'] === 'unique') {
					form.setFields([
						{
							name: 'name',
							errors: [translate.byKey('duplicated_access_profile_name_message')],
						},
					]);
				} else {
					onClose();
					message.success(translate.byKey('access_profile_created_successfully'));
					alert(JSON.stringify(values));
				}
			})
			.catch(() => {});
	};

	const onModalDismiss = () => {
		if (isCurrentDataMinimallyFilled) {
			Modal.confirm({
				title: translate.byKey('unsaved_changes'),
				content: translate.byKey('discard_changes_message'),
				okText: translate.byKey('yes'),
				cancelText: translate.byKey('no'),
				onOk: () => {
					onClose();
				},
			});
		} else {
			onClose();
		}
	};

	const onStepChange = (index) => {
		form
			.validateFields()
			.then(() => {
				if (index >= 0 && index < steps.length) {
					setCurrentStepIndex(index);
				}
			})
			.catch(() => {});
	};

	const onBackButtonClick = () => {
		form
			.validateFields()
			.then(() => {
				setCurrentStepIndex((previousStep) => previousStep - 1);
			})
			.catch(() => {});
	};
	const onNextButtonClick = () => {
		form
			.validateFields()
			.then(() => {
				setCurrentStepIndex((previousStep) => previousStep + 1);
			})
			.catch(() => {});
	};

	return (
		<Modal
			open
			width={800}
			title={translate.byKey('new_access_profile')}
			maskClosable={false}
			onCancel={onModalDismiss}
			footer={
				<div css={footerStyles({ isFirstStep, isStepTransitionEnabled })}>
					{!isFirstStep && isStepTransitionEnabled ? (
						<Button disabled={isCreatingAccessProfile} onClick={onBackButtonClick}>
							{translate.byKey('back')}
						</Button>
					) : null}

					<span>
						<Button disabled={isCreatingAccessProfile} onClick={onModalDismiss}>
							{translate.byKey('cancel')}
						</Button>

						{!isLastStep && isStepTransitionEnabled ? (
							<Button type="primary" onClick={onNextButtonClick}>
								{translate.byKey('next')}
							</Button>
						) : null}

						{isLastStep && isStepTransitionEnabled ? (
							<Button
								type="primary"
								disabled={isCreatingAccessProfile}
								loading={isCreatingAccessProfile}
								onClick={onSubmit}
							>
								{translate.byKey('create')}
							</Button>
						) : null}
					</span>
				</div>
			}
		>
			<Steps css={stepsStyles} current={currentStepIndex} items={steps} size="small" onChange={onStepChange} />

			<Form initialValues={initialValues} layout="vertical" form={form} autoComplete="off">
				<Tabs activeKey={currentStepIndex} renderTabBar={() => null} items={steps} />
			</Form>
		</Modal>
	);
};

const footerStyles = ({ isFirstStep, isStepTransitionEnabled }) =>
	css({
		display: 'flex',
		justifyContent: isFirstStep || !isStepTransitionEnabled ? 'flex-end' : 'space-between',
	});

const stepsStyles = css({
	marginBottom: '20px',
	'.ant-steps-item-disabled .ant-steps-item-container': {
		opacity: 0.3,
	},
});
