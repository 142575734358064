import styled from '@emotion/styled';
import { flexbox, grid, layout, position, size, space } from 'styled-system';

const Grid = styled.div`
	display: flex;
	flex: 1;
	${layout}
	${space}
  ${flexbox}
  ${size}
  ${grid}
  ${position}
`;

export default Grid;
