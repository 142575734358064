import React from 'react';
import { Page } from '~/components';
import { HolidaysPageHeader } from '~/components/features/holidays/HolidaysPageHeader/HolidaysPageHeader';
import { HolidaysPageBody } from '~/components/features/holidays/HolidaysPageBody/HolidaysPageBody';

export const HolidaysPage = () => {
	return (
		<Page>
			<HolidaysPageHeader />
			<HolidaysPageBody />
		</Page>
	);
};
