import { Modal } from 'antd';
import { useTheme } from 'emotion-theming';
import React from 'react';
import { useLocale } from '~/screens/_shared/AppLocale';
import { MenuItem } from '../shared/MenuItem';
import { logout } from '~/components/features/auth/hooks/useUserAuthData';
import {
	NavigationMenu,
	NAVIGATION_MENU_POPUP_CLASSNAME,
} from '~/components/shared/layouts/MainLayout/Navigation/shared/NavigationMenu';
import { useModals } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { MODAL_TYPES } from '../../ModalsContainer/constants';

export const UserMenu = () => {
	const theme = useTheme();
	const { translate } = useLocale();

	const { toggleModal } = useModals();

	function openProfileModal() {
		toggleModal(MODAL_TYPES.USER_PROFILE);
	}

	const onLogoutClick = () => {
		Modal.confirm({
			title: translate.byKey('confirm_logout'),
			content: translate.byKey('are_you_sure_you_would_like_to_logout'),
			okText: translate.byKey('logout'),
			okButtonProps: { style: { backgroundColor: theme.colors.brand[500], borderColor: theme.colors.brand[500] } },
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				logout();
			},
		});
	};

	return (
		<NavigationMenu
			selectedKeys={[]}
			items={[
				{
					label: <MenuItem icon={'people'} />,
					popupClassName: NAVIGATION_MENU_POPUP_CLASSNAME,
					children: [
						{
							label: <MenuItem label={translate.byKey('profile')} />,
							key: 'profile',
							onClick: () => {
								openProfileModal();
							},
							'data-testid': 'user-profile-modal-link',
						},
						{
							label: <MenuItem label={translate.byKey('logout')} />,
							key: 'logout',
							onClick: () => {
								onLogoutClick();
							},
							'data-testid': 'logout-modal-link',
						},
					],
				},
			]}
		/>
	);
};
