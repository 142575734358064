import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import { css } from '@emotion/core';

import { isMomentDateAfterToday, isMomentDateBefore2010 } from '~/utils/dates';
import { useLocale, translator } from '~/screens/_shared/AppLocale';

const { RangePicker } = DatePicker;

const disabledDate = (date) => {
	return isMomentDateBefore2010(date) || isMomentDateAfterToday(date);
};

export const getDateTimeRangeFilterValueLabel = (value) => {
	const start = moment(value.start);
	const end = moment(value.end);
	const todayStart = moment().startOf('day');
	const todayEnd = moment().endOf('day');

	if (start.isSame(todayStart) && end.isSame(todayEnd)) {
		return translator.byKey('today');
	}

	const weekStart = moment().startOf('week');
	const weekEnd = moment().endOf('week');

	if (start.isSame(weekStart) && end.isSame(weekEnd)) {
		return translator.byKey('this_week');
	}

	const monthStart = moment().startOf('month');
	const monthEnd = moment().endOf('month');

	if (start.isSame(monthStart) && end.isSame(monthEnd)) {
		return translator.byKey('this_month');
	}

	return `${start.format('L LT')} - ${end.format('L LT')}`;
};

export const DateTimeRangeFilter = ({ antdFilterProps, defaultDateTimes }) => {
	const { translate } = useLocale();
	const { setSelectedKeys, selectedKeys, confirm, visible, clearFilters } = antdFilterProps;
	const [isPickerOpened, setIsPickerOpened] = useState(false);
	const containerElement = useRef();
	const dateTimeRangeElementRef = useRef();

	useLayoutEffect(() => {
		// resolving issue with opening calendar immediatelly with different popup container
		setTimeout(() => {
			setIsPickerOpened(visible);
		}, 1);

		if (visible) {
			// forcing specific range; start from 7 am
			setTimeout(() => {
				const hourColumnElement = containerElement?.current?.querySelector('.ant-picker-time-panel-column');
				const initialHourInView = hourColumnElement.getElementsByClassName('ant-picker-time-panel-cell-inner')[7];
				initialHourInView.scrollIntoView();
			}, 100);
		}
	}, [visible]);

	const onDateTimeUpdate = (newValues) => {
		const newStartDateTime = newValues[0];
		const newEndDateTime = newValues[1];

		setSelectedKeys([
			{
				start: newStartDateTime.isBefore(newEndDateTime) ? newStartDateTime : newEndDateTime,
				end: newEndDateTime.isAfter(newStartDateTime) ? newEndDateTime : newStartDateTime,
			},
		]);
		confirm();
	};

	const onReset = () => {
		setSelectedKeys(defaultDateTimes);
		clearFilters({ confirm: true, closeDropdown: true });
	};

	const value = [
		selectedKeys[0]?.start,
		// it would be a bad UX to end date in future, when future dates are disabled
		Boolean(selectedKeys[0]?.end) && isMomentDateAfterToday(moment(selectedKeys[0]?.end))
			? moment()
			: selectedKeys[0]?.end,
	];

	return (
		<div
			ref={containerElement}
			css={css`
				padding: 8px;
				.ant-picker-active-bar {
					height: 0px;
				}
				.ant-picker-input-active input {
					font-weight: bold;
					color: #00a0d0;
					font-size: 20px;
				}
				.ant-picker-input input {
					text-align: center;
				}
				.ant-picker-input :not(.ant-picker-input-active) input {
					color: #585959;
				}
				.ant-picker-input {
					min-width: 130px;
				}
				.ant-picker-input.ant-picker-input-active {
					min-width: 200px;
				}
				.ant-picker-footer {
				display: flex;
				justify-content: center;
				align-items: center;
				}
				.ant-picker-footer-extra {
					padding: 0;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}`}
		>
			<RangePicker
				ref={dateTimeRangeElementRef}
				open={isPickerOpened}
				allowClear={false}
				disabledDate={disabledDate}
				value={value}
				showTime={{
					format: 'HH:mm',
				}}
				format="L HH:mm"
				onChange={onDateTimeUpdate}
				minuteStep={15}
				renderExtraFooter={() => (
					<>
						<Space style={{ padding: '8px' }}>
							<Button
								size="small"
								type="ghost"
								onClick={() => onDateTimeUpdate([moment().startOf('day'), moment().endOf('day')])}
							>
								{translate.byKey('today')}
							</Button>
							<Button
								size="small"
								type="ghost"
								onClick={() => onDateTimeUpdate([moment().startOf('week'), moment().endOf('week')])}
							>
								{translate.byKey('this_week')}
							</Button>
							<Button
								size="small"
								type="ghost"
								onClick={() => onDateTimeUpdate([moment().startOf('month'), moment().endOf('month')])}
							>
								{translate.byKey('this_month')}
							</Button>
						</Space>

						<Button size="small" onClick={onReset} type="primary" ghost>
							{translate.byKey('reset')}
						</Button>
					</>
				)}
				getPopupContainer={() => containerElement.current}
			/>
		</div>
	);
};
