import { useRef } from 'react';
import useSWRMutation from 'swr/mutation';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '__';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const usePutControllerUpdate = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: 'customer/:customerId/system/:systemId/site/:siteId/controller/:controllerId',
				method: method.put,
				removeDomainKey: true,
			},
			handleDefaultApiError
		)
	);
	const fetcher = (_, { arg: { ignoreGlobalHandlers, controllerId, ...payload } }) =>
		ref.current.fetchData({
			ignoreGlobalHandlers,
			controllerId,
			...omitBy({ ...payload }, isExcludedValue),
			removeProperties: ['ignoreGlobalHandlers', 'controllerId'],
		});

	return useSWRMutation(key, fetcher, { ...defaultConfig, ...config });
};

export const getPutControllerUpdateKey = ({ customerId, systemId, siteId }) =>
	customerId && systemId && siteId
		? `${customerId}${separator}\
${systemId}${separator}\
${siteId}${separator}\
put-controller-update`
		: null;
