import { useState } from 'react';

import { useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import mappers from '~/screens/_shared/mappers';

export function useCanDeleteController() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const { translate } = useLocale();

	const [getAssignedReaders] = useListApi(mappers.peripheralDevice);

	const getPortalsAndGroups = async (controller) => {
		setIsLoading(true);
		let result = false;
		try {
			setCurrentStep(translate.byKey('get_all_portals'));
			const assignedReaders = await getAssignedReaders({
				siteId: controller.siteId,
				params: { assignedToPortal: 'true', 'detail-level': 'FULL' },
			});

			const readersForController = assignedReaders.filter(
				(r) =>
					r.mapping?.port?.controllerId === controller.controllerId && r.configuration?.additionalSettings?.fixedAddress
			);

			if (readersForController.length > 0) {
				const readers = [
					...readersForController.reduce((results, r) => {
						const doorName = r.mapping?.portal?.name || '';
						if (doorName && !results.has(doorName)) {
							results.add(doorName);
						}
						return results;
					}, new Set()),
				].join(', ');

				const message = translate.byKeyFormatted('the_following_doors_have_readers_belonging_to_this_controller', {
					namesOfReaders: readers,
				});

				throw new Error(message);
			}
		} catch (error) {
			if (error.status && error.status === 404) {
				return false;
			}
			throw error;
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}

		return result;
	};

	return [getPortalsAndGroups, isLoading, currentStep];
}
