import React, { useCallback, useEffect, useState } from 'react';
import wsConfig from './wsConfig';
import enrollData from './enrollData';
import { useLocale } from '~/screens/_shared/AppLocale';
import { showErrorToast } from '~/screens/_shared/toast';

/**
 * Local Websocket component for plugin manager enrolment messages for views that need to enrol cards
 * @param {sendEnrolMessage} sendEnrolMessage the message to send to the WS server
 * @param {onMessage} onMessage override for websocket message responses
 * @param {onOpen} onOpen override for websocket open
 * @param {onClosed} onClosed override for websocket closed
 * @param {onError} onError override for websocket errors
 */
const WSEnrolmentService = ({ sendEnrolMessage, onMessage, onOpen, onClosed, onError }) => {
	let timeout = 2000; // timeout duration
	let [wsInstance, setInstance] = useState(undefined);
	const { translate } = useLocale();

	/**
	 * @function connect
	 * establishes the connection with the ws and ensures constant reconnection if connection closes
	 */
	const connect = () => {
		// readyState 3 = CLOSED, 2 = CLOSING, 1 = OPEN, 0 = CONNECTING
		if (wsInstance === undefined) {
			// check https or http, this determines websocket protocol (ws or wss)
			const urlProtocol = window.location.protocol === 'https:' ? wsConfig.WSS_BASE : wsConfig.WS_BASE;
			wsInstance = new WebSocket(urlProtocol);
			// if we fail at this point then the WS server is not running,
			// only recovery here is to reload view (if the WS server is started again only, otherwise there is still no point)
		}
		setInstance(wsInstance);
		var connectInterval;

		// websocket onopen event listener
		wsInstance.onopen = () => {
			clearTimeout(connectInterval); // clear Interval on open of WS
			onOpenWS(true);
		};

		// ws onclose event listener
		wsInstance.onclose = (e) => {
			console.log(
				`Socket is closed. Reconnect will be attempted in ${Math.min(10000 / 1000, timeout / 1000)} second.`,
				e.reason
			);
			connectInterval = setTimeout(check, Math.min(10000, timeout)); // call check function after timeout
			onClosedWS(e);
			onOpenWS(false); // Set to false in case someone is listening
			testOmnikeyURL();
		};

		// ws onerror event listener
		wsInstance.onerror = (error) => {
			console.error('Socket encountered error: ', error.message, 'Closing socket');
			wsInstance.close();
		};

		// ws onerror event listener
		wsInstance.onmessage = (msg) => {
			onMessageReceived(msg.data);
			return false;
		};
	};

	/**
	 * used by the connect function to check if the connection is close, if so attempts to reconnect
	 * this only works if there was a connection to begin with and the WS server is actually running
	 */
	const check = () => {
		//check if websocket instance is closed, if so call `connect` function.
		if (wsInstance === undefined || wsInstance.readyState === WebSocket.CLOSED) connect();
	};

	/**
	 * converts the message coming in to strongly typed class object.
	 * warning: lots of string splits going, keep your wits about you.
	 * @param {*} obj
	 */
	const decodeResult = (obj) => {
		// for structure refer to './enrollData.js'
		enrollData.enrollType = obj.enrollType; // set enrol response type regardless
		// test if validation message first
		var validationType = obj.validatePlugin;
		if (validationType != null) {
			if (validationType === 'valid') {
				return undefined;
			}
		} else {
			var cardData = obj.enrollData.split(';'); // design to be an array of cards for future expansion, split by ';'...
			var card = cardData[0]; // ...but really its only 1, so use the first
			var cardDetails = card.split(':'); // data within this json object is an 'array' of different items split by ':'

			// each 'array' item has a key/value structure split by '=', enrollData.js class about/readme explains the array order by examples.
			// they are always arranged in this order.
			var tagNumberDetails = cardDetails[0].split('='); // card PACS/UID always the first in the array
			var facilityCodeDetails = cardDetails[3].split('=');
			var cardNumberDetails = cardDetails[4].split('=');
			var rawBitsDetails = cardDetails[9].split('=');
			var cardTypeDetails = cardDetails[11].split('=');
			var detectedCardTypeDetails = cardDetails[5].split('=');
			var statusDetails = cardDetails[2].split('=');

			enrollData.statusMsg = statusDetails[1]; // always set the statusMsg in case it is needed for checks in the view
			// set data fields if valid
			if (tagNumberDetails[1] !== '0') {
				enrollData.data = tagNumberDetails[1];
				if (facilityCodeDetails[1] !== undefined) {
					enrollData.facilityCode = facilityCodeDetails[1];
				}
				if (cardNumberDetails[1] !== undefined) {
					enrollData.cardNumber = cardNumberDetails[1];
				}
				if (rawBitsDetails[1] !== undefined) {
					enrollData.rawBits = rawBitsDetails[1];
				}
				if (cardTypeDetails[1] !== undefined) {
					enrollData.cardType = cardTypeDetails[1];
				}
				if (detectedCardTypeDetails[1] !== undefined && detectedCardTypeDetails[1] !== 0) {
					enrollData.detectedType = detectedCardTypeDetails[1];
				}
			} else {
				enrollData.data = undefined;
				enrollData.facilityCode = undefined;
				enrollData.cardNumber = undefined;
				enrollData.rawBits = undefined;
				enrollData.cardType = undefined;
				enrollData.detectedType = undefined;
			}
			return enrollData;
		}
		return undefined;
	};

	/**
	 * websocket events that can be used by client
	 */
	const onMessageReceived = useCallback((value) => {
		if (onMessage) {
			// decode data
			onMessage(decodeResult(JSON.parse(value)));
		}
	});

	const onOpenWS = useCallback((val) => {
		if (onOpen) {
			onOpen(val);
		}
	});

	const onClosedWS = useCallback((e) => {
		if (onClosed) {
			onClosed(e);
		}
	});

	const onErrorWS = useCallback((error) => {
		if (onError) {
			onError(error);
		} else {
			showErrorToast(error);
			console.error(error);
		}
	});

	/**
	 * only once component is mounted attempt to connect
	 */
	useEffect(() => {
		// dont keep connected on every update to the view, only if undefined
		if (
			wsInstance === undefined ||
			(wsInstance.readyState !== undefined &&
				wsInstance.readyState !== WebSocket.CONNECTING &&
				wsInstance.readyState !== WebSocket.OPEN)
		) {
			connect();
		}
	}, [wsInstance]);

	/**
	 * to keep the messages being accepted we need to make sure that the message state remains
	 * changing, so we set it to something that is obviously going to be ignored in the sendEnrolMessage
	 * state change (we must handle this in the state change effect, don't want a loop happening)
	 */

	/**
	 * whenever we update the send value, handle non valid message lengths etc.
	 */
	useEffect(() => {
		// is there actually a valid message here
		if (typeof sendEnrolMessage === 'string' && sendEnrolMessage.length > 5) {
			// make sure we have an instance and the ws is open
			if (wsInstance !== undefined && wsInstance.readyState === WebSocket.OPEN) {
				// send it!
				wsInstance.send(sendEnrolMessage);
			} else {
				onErrorWS(
					translate.byKey('plugin_application_does_not_seem_to_be_running_or_installed_please_add_card_manually')
				);
				testOmnikeyURL();
			}
		}
	}, [sendEnrolMessage]);

	const testOmnikeyURL = () => {
		// const customAxiosInstance = axios.create({
		//   baseURL: "http://192.168.63.99/cjax",
		//   headers: {
		//     "Access-Control-Allow-Origin": "*",
		//     "Content-Type": "application/x-www-form-urlencoded",
		//     Accept: "application/octet-stream",
		//     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
		//   }
		//   // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
		//   // responseType: "text"
		// });
		// delete customAxiosInstance.defaults.headers.common["Authorization"];
		// customAxiosInstance.defaults.timeout = 0;
		// customAxiosInstance
		//   .post("/", "FF6800000C0000000D050302010300050000", { withCredentials: true })
		//   .then(function (response) {
		//     console.log(response);
		//   })
		//   .catch(function (error) {
		//     console.log(error);
		//   });
	};

	return (
		<div /> // nothing really to see here...
	);
};

export default WSEnrolmentService;
