import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import 'moment/locale/fr';
import 'moment/locale/cs';
import 'moment/locale/nl';
import 'moment/locale/it';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesFr from 'i18n-iso-countries/langs/fr.json';
import countriesIt from 'i18n-iso-countries/langs/it.json';
import countriesPl from 'i18n-iso-countries/langs/pl.json';
import countriesCs from 'i18n-iso-countries/langs/cs.json';
import countriesNl from 'i18n-iso-countries/langs/nl.json';

import { getFileWithFetch } from '~/services/translator/getFileWithFetch';
import { getDefaultLanguageCode } from '~/services/translator/defaultLanguageCode';
import { Translator } from '~/services/translator';

moment.updateLocale('en-gb', { longDateFormat: { '[LDM]': 'DD/MM' } });
moment.updateLocale('fr', { longDateFormat: { '[LDM]': 'DD/MM' } });
moment.updateLocale('nl', { longDateFormat: { '[LDM]': 'DD/MM' } });
moment.updateLocale('it', { longDateFormat: { '[LDM]': 'DD/MM' } });
moment.updateLocale('cs', { longDateFormat: { '[LDM]': 'DD.MM' } });
moment.updateLocale('pl', { longDateFormat: { '[LDM]': 'DD.MM' } });

countries.registerLocale(countriesEn);
countries.registerLocale(countriesFr);
countries.registerLocale(countriesIt);
countries.registerLocale(countriesPl);
countries.registerLocale(countriesCs);
countries.registerLocale(countriesNl);

export const translator = new Translator(getFileWithFetch, getDefaultLanguageCode, window.localStorage);

export const LocaleContext = React.createContext({ translate: translator });

export const supportedLanguages = [
	{ name: 'english', value: 'en-GB' },
	{ name: 'french', value: 'fr' },
	{ name: 'dutch', value: 'nl' },
	{ name: 'czech', value: 'cs' },
	{ name: 'polish', value: 'pl' },
	{ name: 'italian', value: 'it' },
];

export function useLocale() {
	return useContext(LocaleContext);
}

const TranslationsContextProvider = (props) => {
	const [locale, setLocale] = useState(getDefaultLanguageCode());

	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	translator.forceUpdate = forceUpdate;

	useEffect(() => {
		localStorage.setItem('selectedLanguage', locale);
		moment.locale(locale);
		translator
			.loadLanguage(locale.split('-')[0])
			.then(() => {
				forceUpdate();
			})
			.catch((ex) => {
				//TODO something useful here.
			});
	}, [locale]);

	return (
		<LocaleContext.Provider value={{ locale, setLocale, translate: translator, countries }}>
			{props.children}
		</LocaleContext.Provider>
	);
};

export default TranslationsContextProvider;
