import { useState } from 'react';

import mappers from '~/screens/_shared/mappers';
import { usePostApi, useListApi, useGetApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { doorTypeLogicalDeviceDefaults, doorTypeReaderDefaults, isOnline } from '~/screens/Doors/hooks/doorDefaults';
import { cacheKeys } from '~/screens/Doors/hooks/doorHelper';
import { createOrGetPort } from '~/screens/Controllers/hooks';

/**
 * Hook for adding a reader for a door.
 *
 * Needs to:
 * 1. Add a peripheralDevice with everything (fixed address, settings, etc.).
 * 2. Get the door record
 * 3. Update door description to include the peripheralDeviceId for looking up the reader.
 */
export function useCreateReader() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');
	const { translate } = useLocale();
	const [createPeripheralDevice] = usePostApi(mappers.peripheralDevice);
	const [createPort] = usePostApi(mappers.port);
	const [getPort] = useGetApi(mappers.port);
	const [getAllPorts] = useListApi(mappers.port);

	async function createPeripheral(formData, controllerId, portId, doorType) {
		const { portal, isEntry, formattedValues } = formData;
		doorType = doorType || 'hid_online';

		if (formattedValues) {
			formattedValues.isEntry = isEntry;
			formattedValues.portalName = portal.name;

			if (isOnline(doorType) && portId) {
				formattedValues.controllerId = controllerId;
				formattedValues.portId = portId;
			} else if (isOnline(doorType)) {
				delete formattedValues.controllerId;
				delete formattedValues.portId;
			}

			formattedValues.portalId = portal.portalId;
			formattedValues.deviceInfo = doorTypeReaderDefaults[doorType];
			formattedValues.logicalDeviceFunction = doorTypeLogicalDeviceDefaults[doorType];
			formattedValues.ignoreGlobalHandlers = true;
			return createPeripheralDevice(formattedValues, {
				removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
			});
		} else {
			const readerData = {
				isEntry: isEntry,
				portalName: portal.name,
				portalId: portal.portalId,
				deviceInfo: doorTypeReaderDefaults[doorType],
				logicalDeviceFunction: doorTypeLogicalDeviceDefaults[doorType],
				ignoreGlobalHandlers: true,
			};

			if (isOnline(doorType) && portId) {
				readerData.controllerId = controllerId;
				readerData.portId = portId;
			}
			return createPeripheralDevice(readerData, {
				removeExistingKey: cacheKeys.peripheralDeviceIndexKey(),
			});
		}
	}

	/**
	 * Repair a door that is missing its peripheralDevice
	 */
	async function createReader(formData, doorType) {
		setIsLoading(true);

		try {
			if (formData.formattedValues.fixedAddress) {
				setCurrentStep(translate.byKey('loading_ports'));

				const currentController = {
					name: formData.formattedValues.controllerName,
					controllerId: formData.formattedValues.controllerId,
				};
				const port = await createOrGetPort({ currentController, createPort, getAllPorts, getPort });

				setCurrentStep(translate.byKey('creating_reader'));
				await createPeripheral(formData, formData.formattedValues.controllerId, port.portId, doorType);
			} else if (doorType !== 'hid_online') {
				setCurrentStep(translate.byKey('creating_reader'));
				await createPeripheral(formData, null, null, doorType);
			}
		} catch (ex) {
			console.error(ex);
			throw ex;
		} finally {
			setCurrentStep('');
			setIsLoading(false);
		}
	}

	return [createReader, isLoading, currentStep];
}
