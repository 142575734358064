import * as userConstants from '~/screens/_shared/userRoleConstants';
import { MODAL_TYPES } from '~/components/shared/layouts/MainLayout/ModalsContainer/constants';

const REPORTS_CHILDREN_CONFIG = [
	{
		title: 'access_transactions_menu_title',
		key: 'access-log',
		link: '/access-log',
		screen: userConstants.screens.REPORT_ACCESS,
		systemLevel: true,
		siteLevel: true,
	},
	{
		title: 'status_transactions_menu_title',
		key: 'device-log',
		link: '/device-log',
		screen: userConstants.screens.REPORT_STATUS,
		systemLevel: true,
		siteLevel: true,
	},
	{
		title: 'audit_translations_menu_title',
		key: 'audit-logs',
		link: '/audit-logs',
		screen: userConstants.screens.REPORT_AUDIT,
		systemLevel: true,
		siteLevel: false,
		forbiddenRoles: ['SYSTEM_GUARD'],
	},
];

const SETTINGS_CHILDREN_CONFIG = [
	{
		title: 'holidays',
		key: 'special-days',
		link: '/holidays',
		screen: userConstants.screens.HOLIDAYS,
		systemLevel: false,
		siteLevel: true,
	},
	{
		title: 'door_groups_title_v2',
		key: 'door-groups',
		link: '/door-groups',
		screen: userConstants.screens.ACCESS_GROUPS,
		systemLevel: false,
		siteLevel: true,
	},
	// {
	// 	// TODO this replaces access groups below entry, so remove access groups and uncomment this section when ready
	// 	title: 'access_schedules',
	// 	icon: 'question',
	// 	key: 'ACCESS_SCHEDULES',
	// 	link: '/access-schedules',
	// 	screen: userConstants.screens.ACCESS_SCHEDULES,
	// 	systemLevel: true,
	// 	siteLevel: true,
	// },
	{
		title: 'access_groups_menu_title',
		key: 'access-groups',
		link: '/access-groups',
		screen: userConstants.screens.ACCESS_GROUPS,
		systemLevel: false,
		siteLevel: true,
	},
	{
		title: 'usb_devices',
		key: 'usb-devices',
		link: '/usb-devices',
		screen: userConstants.screens.CONTROLLERS,
		systemLevel: false,
		siteLevel: true,
	},
	{
		title: 'card_format',
		key: 'wiegand-settings',
		modal: MODAL_TYPES.CARD_FORMAT,
		systemLevel: false,
		siteLevel: true,
	},
	{
		title: 'blocklist_settings_menu_title_v2',
		key: 'blocklist',
		link: '/blocklist',
		screen: userConstants.screens.CONTROLLERS,
		systemLevel: true,
		siteLevel: true,
	},
	{
		title: 'system_settings_v2',
		key: 'system-settings',
		modal: MODAL_TYPES.SYSTEM_SETTINGS,
		systemLevel: true,
		siteLevel: false,
		forbiddenRoles: ['SYSTEM_GUARD'],
	},
	{
		title: 'site_settings_menu_title_v2',
		key: 'edit-site',
		modal: MODAL_TYPES.SITE_SETTINGS,
		systemLevel: false,
		siteLevel: true,
	},
	{
		title: 'add_site_settings_menu_title_v2',
		key: 'add-site',
		modal: MODAL_TYPES.SITE_SETTINGS,
		systemLevel: true,
		siteLevel: false,
		forbiddenRoles: ['SYSTEM_OPERATOR', 'SYSTEM_GUARD'],
	},
];

if (Boolean(localStorage.getItem('showEntitlements'))) {
	SETTINGS_CHILDREN_CONFIG.push({
		title: 'Entitlements',
		key: 'entitlements',
		link: '/entitlements',
		screen: userConstants.screens.DOORS,
		systemLevel: true,
		siteLevel: true,
	});
}

if (Boolean(localStorage.getItem('showTranslations'))) {
	SETTINGS_CHILDREN_CONFIG.push({
		title: 'Translations',
		key: 'translations',
		link: '/translations',
		screen: userConstants.screens.DOORS,
		systemLevel: true,
		siteLevel: true,
	});
}

export const MENU_CONFIG = [
	{
		title: 'live',
		icon: 'live',
		key: 'live',
		link: '/live',
		screen: userConstants.screens.LIVE,
		systemLevel: true,
		siteLevel: true,
		testId: 'live-link',
	},
	// {
	// 	// TODO MM ask for the position this should be on site and system, ask for icon
	// 	title: 'access_profiles',
	// 	icon: 'question',
	// 	key: 'ACCESS_PROFILES',
	// 	link: '/access-profiles',
	// 	screen: userConstants.screens.ACCESS_PROFILES,
	// 	systemLevel: true,
	// 	siteLevel: true,
	// },
	{
		title: 'people',
		icon: 'people',
		key: 'people',
		link: '/people',
		screen: userConstants.screens.PEOPLE,
		systemLevel: false,
		siteLevel: true,
		testId: 'people-link',
	},
	{
		title: 'connected_devices',
		icon: 'controller',
		key: 'controllers',
		link: '/controllers',
		screen: userConstants.screens.CONTROLLERS,
		systemLevel: true,
		siteLevel: true,
		testId: 'connected-devices-link',
	},
	{
		title: 'doors',
		icon: 'doors',
		key: 'doors',
		link: '/doors',
		screen: userConstants.screens.DOORS,
		systemLevel: false,
		siteLevel: true,
		testId: 'doors-link',
	},
	{
		title: 'reports_menu_title',
		icon: 'reports',
		key: 'reports',
		screen: userConstants.screens.REPORTS,
		systemLevel: true,
		siteLevel: true,
		children: REPORTS_CHILDREN_CONFIG,
		testId: 'reports-link',
	},
	{
		title: 'more_menu_title_v2',
		icon: 'settings',
		key: 'more',
		screen: userConstants.screens.SETTINGS,
		systemLevel: true,
		siteLevel: true,
		children: SETTINGS_CHILDREN_CONFIG,
		testId: 'more-menu-link',
	},
	{
		title: 'about_menu_title',
		icon: 'about',
		key: 'about',
		systemLevel: true,
		siteLevel: true,
		modal: MODAL_TYPES.ABOUT,
		testId: 'about-menu-link',
	},
];

export const supportsSystemLevel = (pathname) => {
	return MENU_CONFIG.some((item) => {
		if (Boolean(item.children?.length)) {
			return item.children.some((child) => child.link === pathname && child.systemLevel);
		}
		return item.link === pathname && item.systemLevel;
	});
};

export const supportsSiteLevel = (pathname) => {
	return MENU_CONFIG.some((item) => {
		if (Boolean(item.children?.length)) {
			return item.children.some((child) => child.link === pathname && child.siteLevel);
		}
		return item.link === pathname && item.siteLevel;
	});
};
