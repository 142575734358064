import { translator as translate } from '~/screens/_shared/AppLocale';

const generalHelp = () => `
<p><strong><u> ${translate.byKey('common_functions_header')}</u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('site_selection')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'you_can_switch_between_sites_by_changing_the_drop_down_on_the_top_left_of_the_page_if_all_sites_is_selected_then_on_some_pages_it_may_show_data_from_every_site_on_the_system'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('logout')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'logout_will_exit_the_system__the_system_will_also_automatically_log_out_based_on_the_session_time_limit_'
)} </p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('search')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'typing_in_the_search_field_will_filter_through_the_current_table_for_similar_values_from_what_was_typed'
)} </p>
<p><strong><u> ${translate.byKey('page_specific_functions')} </u></strong></p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('general')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'access_groups_allow_you_to_create_defined_rules_to_a_site_which_can_be_assigned_to_people_on_the_system_for_access_to_specific_doors_for_certain_days_and_times'
)}</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('add_new')} <br /></strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'add_new_will_allow_you_to_add_a_new_access_group_for_the_system'
)}</p>
<p style="padding-left: 30px;"><strong> ${translate.byKey('edit_delete')} </strong></p>
<p style="padding-left: 30px;"> ${translate.byKey(
	'to_edit_a_access_group_click_on_the_access_groups_name_in_the_table_list_deleting_can_be_done_from_within_the_edit_view'
)}</p>
<p>&nbsp;</p>
`;

export default generalHelp;
