import React, { useState, useMemo } from 'react';
import { Modal, message } from 'antd';

import { useLocale } from '~/screens/_shared/AppLocale';
import { EditDoorGroupModalFooter } from '~/components/features/door-groups/modals/edit-door-group/EditDoorGroupModalFooter/EditDoorGroupModalFooter';
import { DoorGroupForm } from '~/components/features/door-groups/DoorGroupForm/DoorGroupForm';
import { getDeleteAssetKey, useDeleteAsset } from '~/hooks/data/assets/useDeleteAsset';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { getUpdateAssetKey, useUpdateAsset } from '~/hooks/data/assets/useUpdateAsset';
import { AssetTypes } from '~/constants/AssetTypes';
import { useProcessDoors } from '~/components/features/door-groups/hooks/useProcessDoors';
import { getPortalsKey, usePortals } from '~/hooks/data/portals/usePortals';
import { getAssetsIdsForDoorGroup } from '~/components/features/door-groups/DoorGroupsAssetTreeSelector/DoorGroupsAssetTreeSelector.utils';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const EditDoorGroupModal = ({ doorGroup, onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, sortBy, sortDirection, doorGroup: doorGroupName, description },
	} = useQueryParams();
	const { trigger: deleteAsset, isMutating: isDeletingAsset } = useDeleteAsset(
		getDeleteAssetKey({
			customerId,
			systemId,
			siteId,
			assetId: doorGroup.assetId,
		})
	);
	const { trigger: updateAsset, isMutating: isUpdatingAsset } = useUpdateAsset(
		getUpdateAssetKey({
			customerId,
			systemId,
			siteId,
			assetId: doorGroup.assetId,
		})
	);
	const { mutate: reloadAssets } = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			siteId,
			pageSize,
			pageNumber,
			sortBy,
			sortDirection,
			assetType: AssetTypes.BASIC_ASSET_GROUP,
			doorGroup: doorGroupName,
			description,
		}),
		{
			revalidateOnMount: false,
		}
	);
	const { data: assets, isLoading: isLoadingAssets } = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			pageSize: 1000,
			sortBy: 'offline_reference_id',
			sortDirection: 'DESCENDING',
			assetType: AssetTypes.BASIC_ASSET,
		})
	);
	const { data: { portals } = { portals: [] }, isLoading: isLoadingPortals } = usePortals(
		getPortalsKey({
			customerId,
			systemId,
		})
	);
	const { processDoors, processDoorsOnDoorGroupDelete, isProcessingDoors, reloadDoorsAssets, reloadPortals } =
		useProcessDoors();

	const [isFormDirtyAndValid, setIsFormDirtyAndValid] = useState(false);
	const [informationMessage, setInformationMessage] = useState('');

	const doors = useMemo(
		() =>
			!isLoadingAssets && !isLoadingPortals ? getAssetsIdsForDoorGroup(doorGroup.assetId, assets.assets, portals) : [],
		[assets, portals]
	);

	const formDefaultValues = {
		name: doorGroup.name,
		description: doorGroup.description,
		doors: doors || [],
		assetId: doorGroup.assetId,
	};

	const onFormChange = async (values, form) => {
		let isAnyFieldDirty = false;
		let isAnyFieldInvalid = false;

		Object.keys(values).forEach((key) => {
			if (values[key] !== formDefaultValues[key]) {
				isAnyFieldDirty = true;
			}
		});

		await form.validateFields().catch(({ errorFields }) => {
			if (errorFields.length) {
				isAnyFieldInvalid = true;
			}
		});

		setIsFormDirtyAndValid(isAnyFieldDirty && !isAnyFieldInvalid && !form.getFieldError('doors').length);
	};

	const onFormSubmit = async ({ name, description, doors }) => {
		const doorsToRemove = formDefaultValues.doors.filter((door) => !doors.includes(door));

		try {
			setInformationMessage(translate.byKey('updating_door_group'));
			await updateAsset({
				name,
				description,
				doors,
				type: AssetTypes.BASIC_ASSET_GROUP,
				version: doorGroup.version,
				assetId: doorGroup.assetId,
				ignoreGlobalHandlers: true, // to ignore default error handling
			});

			if (doorsToRemove.length > 0) {
				await processDoorsOnDoorGroupDelete(doorsToRemove, doorGroup.assetId);
			}

			await processDoors(doors, doorGroup.assetId);

			reloadDoorsAssets();
			reloadAssets();
			reloadPortals();
			onClose();
			message.success(translate.byKey('door_group_updated_successfully'));
		} catch (error) {
			message.error(translate.byKey('an_error_occurred_on_the_server_when_attempting_to_make_a_request'));
		} finally {
			setInformationMessage('');
			onClose();
		}
	};

	const deleteDoorGroup = async () => {
		try {
			setInformationMessage(translate.byKey('deleting_door_group'));
			await processDoorsOnDoorGroupDelete(formDefaultValues.doors, doorGroup.assetId);
			await deleteAsset({
				assetId: doorGroup.assetId,
				ignoreGlobalHandlers: true, // to ignore default error handling
			});
			reloadDoorsAssets();
			reloadAssets();
			reloadPortals();
			onClose();
			message.success(translate.byKey('door_group_deleted_successfully'));
		} catch (error) {
			message.error(translate.byKey('an_error_occurred_on_the_server_when_attempting_to_make_a_request'));
		} finally {
			setInformationMessage('');
			onClose();
		}
	};

	const onDeleteDoorGroup = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('door_group_delete_confirmation'),
			okText: translate.byKey('delete'),
			cancelText: translate.byKey('cancel'),
			onOk: () => {
				deleteDoorGroup();
			},
		});
	};

	return (
		<Modal
			open
			title={doorGroup.name}
			onCancel={onClose}
			maskClosable={false}
			width={600}
			footer={
				<EditDoorGroupModalFooter
					isSubmitDisabled={isUpdatingAsset || isDeletingAsset || !isFormDirtyAndValid}
					isSubmitting={isUpdatingAsset || isProcessingDoors}
					isDeleting={isDeletingAsset}
					onDeleteDoorGroup={onDeleteDoorGroup}
					onClose={onClose}
				/>
			}
		>
			<DoorGroupForm
				message={informationMessage}
				isProcessingDoors={isDeletingAsset || isUpdatingAsset || isProcessingDoors}
				defaultValues={formDefaultValues}
				onFormChange={onFormChange}
				onFormSubmit={onFormSubmit}
			/>
		</Modal>
	);
};
