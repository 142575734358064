import projectIcons from './icons';
import theme from './theme';

const core = {
	...theme,
	shadows: {
		...theme.shadows,
		shadow_outline: `0 0 2px #028DFF`,
		shadow_outline_2: `0 0 8px #028DFF`,
		shadow_error: `0 0 2px #C53030`,
		shadow_1: `0 0 2px 0 #a4a7b5`,
		shadow_2: `0 2px 4px 0 #e3e5ed`,
		shadow_3: `0 0 1px 0 #a4a7b5`,
		shadow_4: `0 1px 2px 0 #e3e5ed`,
		shadow_5: `0 0 3px #EFF1F5`,
		shadow_6: `0 2px 12px 0 #e3e5ed`,
	},
	fonts: {
		...theme.fonts,
		body: 'Verdana, AssaVesta, ' + theme.fonts.body,
		heading: 'Verdana, AssaVesta, ' + theme.fonts.heading,
	},
	icons: {
		...theme.icons,
		...projectIcons,
	},
};

export default core;
