import React, { useState, useEffect } from 'react';

import { Page } from '~/components';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { DoorGroupsHeader } from '~/components/features/door-groups/DoorGroupsHeader/DoorGroupsHeader';
import { DoorGroupsClientDataTable } from '~/components/features/door-groups/table/DoorGroupsClientDataTable/DoorGroupsClientDataTable';
import { DoorGroupsDataTable } from '~/components/features/door-groups/table/DoorGroupsDataTable/DoorGroupsDataTable';
import { AddDoorGroupModal } from '~/components/features/door-groups/modals/add-door-group/AddDoorGroupModal';
import { EditDoorGroupModal } from '~/components/features/door-groups/modals/edit-door-group/EditDoorGroupModal';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { AssetTypes } from '~/constants/AssetTypes';
import { Modal } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

// TODO #1 - HARDCODED LIMIT OF 50 DOOR GROUPS - NO SUPPORT ON BACKEND ATM (CURRENTLY THIS IS NOT INCLUDED IN ENTITLEMENTS)
// Remove this and other #1 todo and refactor when and if backend provides limiting number of door groups (in entitlements)
const MAXIMUM_NUMBER_OF_DOOR_GROUPS_ALLOWED = 50;

export const DoorGroupsPage = () => {
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { translate } = useLocale();
	const {
		queryParams: { pageSize, pageNumber, sortBy, sortDirection, doorGroup, description },
		setQueryParams,
		clearAllQueryParams,
	} = useQueryParams();
	// TODO #1 when door group limit is included included in the entitlements, change this
	const { data, isLoading, isValidating, mutate } = useAssets(
		siteId
			? getAssetsKey({
					customerId,
					systemId,
					siteId,
					pageSize,
					pageNumber,
					sortBy,
					sortDirection,
					assetType: AssetTypes.BASIC_ASSET_GROUP,
					doorGroup,
					description,
			  })
			: null
	);

	const [selectedDoorGroup, setSelectedDoorGroup] = useState(null);
	const [isAddDoorGroupModalVisible, setIsAddDoorGroupModalVisible] = useState(false);

	useEffect(() => {
		const clearSpecificQueryParamsOnCustomerLevelChange = () => {
			if (customerId) {
				setQueryParams({
					pageNumber: '',
					doorGroup: '',
					description: '',
				});
			}
		};

		clearSpecificQueryParamsOnCustomerLevelChange();
	}, [customerId]);

	useEffect(() => {
		clearAllQueryParams();

		return clearAllQueryParams;
	}, []);

	const onReload = () => {
		mutate();
	};

	const onSelectedDoorGroup = (doorGroup) => setSelectedDoorGroup(doorGroup);

	const onAddNewDoorGroup = () => {
		if (data?.assets?.length >= MAXIMUM_NUMBER_OF_DOOR_GROUPS_ALLOWED) {
			return Modal.info({
				title: translate.byKey('door_groups_limit_reached'),
				content: translate.byKeyFormatted('door_groups_limit_reached_message', {
					limit: MAXIMUM_NUMBER_OF_DOOR_GROUPS_ALLOWED,
				}),
				okText: translate.byKey('ok'),
			});
		}
		setIsAddDoorGroupModalVisible(true);
	};

	return (
		<>
			{selectedDoorGroup ? (
				<EditDoorGroupModal doorGroup={selectedDoorGroup} onClose={() => setSelectedDoorGroup(null)} />
			) : null}
			{isAddDoorGroupModalVisible ? <AddDoorGroupModal onClose={() => setIsAddDoorGroupModalVisible(false)} /> : null}
			<Page>
				<DoorGroupsHeader
					onAddNewButtonClick={onAddNewDoorGroup}
					isLoading={isLoading || isValidating}
					onReload={onReload}
				/>
				{doorGroup || description ? (
					<DoorGroupsClientDataTable onRowClick={onSelectedDoorGroup} />
				) : (
					<DoorGroupsDataTable onRowClick={onSelectedDoorGroup} />
				)}
			</Page>
		</>
	);
};
