import React from 'react';

const viewBox = '0 0 512 512';

const path = (
	<g fill="currentColor">
		<path d="M277.332 384c0 11.781-9.55 21.332-21.332 21.332s-21.332-9.55-21.332-21.332 9.55-21.332 21.332-21.332 21.332 9.55 21.332 21.332zm0 0" />
		<path d="M256 512C114.836 512 0 397.164 0 256S114.836 0 256 0s256 114.836 256 256-114.836 256-256 256zm0-480C132.48 32 32 132.48 32 256s100.48 224 224 224 224-100.48 224-224S379.52 32 256 32zm0 0" />
		<path d="M256 314.668c-8.832 0-16-7.168-16-16v-21.547c0-20.308 12.887-38.508 32.043-45.27 25.492-8.98 42.625-36.14 42.625-55.851 0-32.363-26.305-58.668-58.668-58.668S197.332 143.637 197.332 176c0 8.832-7.168 16-16 16s-16-7.168-16-16c0-49.984 40.664-90.668 90.668-90.668s90.668 40.684 90.668 90.668c0 35.586-28.098 73.367-63.98 86.04-6.399 2.237-10.688 8.315-10.688 15.1v21.528c0 8.832-7.168 16-16 16zm0 0" />
	</g>
);

const question = {
	path,
	viewBox,
};

export default question;
