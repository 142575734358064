import { useRef, useState } from 'react';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const maximumPages = 20;
const maximumPageSize = 100;
const contentType = 'application/vnd.hidglobal.ca.event-report-2.0+json';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

/**
 * This is only needed for the PDF/CSV extraction of all the transactions, and this is not good, so check if in the
 * future PDF/CSV can be generated on the backend instead.
 */
export const useAllStatusTransactions = () => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/reports/device-log',
				method: method.post,
				removeDomainKey: true,
			},
			handleDefaultApiError
		)
	);
	const [isLoading, setIsLoading] = useState(false);

	const fetchAllStatusTransactions = async ({
		siteId,
		eventName,
		reasonCode,
		siteName,
		controllerId,
		startTime,
		endTime,
	}) => {
		setIsLoading(true);

		const firstPage = await ref.current.fetchData(
			omitBy(
				{
					pageSize: maximumPageSize,
					pageNumber: 1,
					siteId,
					eventName,
					reasonCode,
					siteName,
					controllerId,
					startTime,
					endTime,
					__contentType: contentType,
				},
				isExcludedValue
			)
		);

		const totalRecordCount = firstPage.totalRecordCount;
		const totalPageNumber = Math.ceil(totalRecordCount / maximumPageSize);
		const limitedTotalPageNumber = totalPageNumber > maximumPages ? maximumPages : totalPageNumber;

		if (limitedTotalPageNumber > 1) {
			const requests = [];

			for (let i = 2; i <= limitedTotalPageNumber; i++) {
				requests.push(async () => {
					// we wait a bit so we do not execute e.g. 10 requests at once
					await new Promise((done) =>
						setTimeout(() => {
							done();
						}, i * 10)
					);

					return ref.current.fetchData(
						omitBy(
							{
								pageSize: maximumPageSize,
								pageNumber: i,
								siteId,
								eventName,
								reasonCode,
								siteName,
								controllerId,
								startTime,
								endTime,
								__contentType: contentType,
							},
							isExcludedValue
						)
					);
				});
			}

			const otherPages = await Promise.all(requests.map((request) => request()));

			setIsLoading(false);

			return [...firstPage?.events, otherPages.flatMap((page) => page.events)].flatMap((event) => event);
		} else {
			setIsLoading(false);

			return [...firstPage?.events];
		}
	};

	return {
		isLoading,
		fetchAllStatusTransactions,
	};
};
