import { useTheme } from 'emotion-theming';
import React from 'react';

import { Flex } from '~/components';

const Logo = (props) => {
	const {
		brand: { logo },
	} = useTheme();
	const width = props.width || logo.width;
	const height = props.height || logo.height;
	const fontSize = props.fontSize || logo.fontSize;
	const lineHeight = fontSize;
	const { color } = props;
	return (
		<Flex {...props}>
			{logo.component({
				...(logo.type === 'image' && { width, height }),
				...(logo.type === 'text' && { fontSize, color, lineHeight }),
			})}
		</Flex>
	);
};

export default Logo;
