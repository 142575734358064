import sortBy from 'lodash/sortBy';
import { standardWeek as standardWeekConstants } from '../entityConstants';
import { securityLevels as securityLevelsConstants } from '../entityConstants';

export const mapAccessPolicies = ({
	scheduleStates,
	systemId,
	calendarId,
	scheduleId,
	securityLevels,
	authenticationModes,
}) => {
	let policies = [];

	for (const key in scheduleStates) {
		if (scheduleStates.hasOwnProperty(key)) {
			policies.push({
				systemId,
				calendarId,
				scheduleId,
				scheduleState: key,
				securityLevelId: securityLevels[key].securityLevelId,
				authenticationMode: authenticationModes.SINGLE_CREDENTIAL,
			});
		}
	}

	return policies;
};

/**
 *
 * @param {*} timeSlots
 */
export const normalizeTimeSlots = (timeSlots) => {
	let standardWeek = {};
	let timeIntervals = {};

	const slotWithHoliday = timeSlots.find((slot) => slot.days.some((day) => day.type === 'HOLIDAY')) || {
		modeTimeIntervals: [],
		days: [],
	};
	const holidaySlot = JSON.parse(JSON.stringify(slotWithHoliday));

	// for slots which have holidays, we want to remove the holiday part,
	// because a holiday does not go into a standard week.
	slotWithHoliday.days = slotWithHoliday.days.filter((day) => day.type !== 'HOLIDAY');

	// we also can't make use of the slot if it was just for the holiday.
	// The copy will be used to add data at the end.
	if (slotWithHoliday.days.length === 0) {
		timeSlots = timeSlots.filter((slot) => slot !== slotWithHoliday);
	} else {
		holidaySlot.name = 'Holiday';
	}

	const dayTypes = timeSlots.reduce((types, slot) => {
		types[slot.name] = {
			description: `${slot.name} description`,
			sortOrder: Object.keys(types).length.toString(),
		};
		return types;
	}, {});

	dayTypes[holidaySlot.name] = {
		description: Array.from(
			holidaySlot.modeTimeIntervals.reduce((items, interval) => {
				const modes = items.has(interval.state) ? items.get(interval.state) : [];
				modes.push(interval);
				items.set(interval.state, modes);
				return items;
			}, new Map()),
			([state, modes]) => ({ state, modes })
		)
			.map((interval) => {
				return `${interval.state}=${interval.modes.map((mode) => mode.startTime + '-' + mode.endTime).join(',')}`;
			})
			.join(';'),
		sortOrder: Object.keys(dayTypes).length.toString(),
	};

	for (const slot of timeSlots) {
		// If the user provided mode time intervals, then use them
		timeIntervals[slot.name] = slot.modeTimeIntervals.map((timeInterval) => {
			return {
				state: timeInterval.state,
				startTime: timeInterval.startTime,
				endTime: timeInterval.endTime,
			};
		});

		// If the time slots are missing however (i.e. new door, no modes yet), then we MUST provide this.
		// Otherwise, the door won't work. Readers will ignore you.
		if (timeIntervals[slot.name].length === 0) {
			timeIntervals[slot.name] = [
				{
					startTime: '00:00',
					endTime: '23:59',
					state: securityLevelsConstants.CARD,
				},
			];
		}

		for (const day of slot.days) {
			standardWeek[day.type] = slot.name;
		}
	}

	return {
		dayTypes,
		standardWeek,
		timeIntervals,
	};
};

export const mapSchedulesAndCalendars = (calendar, schedule) => {
	let timeSlots = [];
	let dayIds = {};
	dayIds[standardWeekConstants.MONDAY] = 0;
	dayIds[standardWeekConstants.TUESDAY] = 1;
	dayIds[standardWeekConstants.WEDNESDAY] = 2;
	dayIds[standardWeekConstants.THURSDAY] = 3;
	dayIds[standardWeekConstants.FRIDAY] = 4;
	dayIds[standardWeekConstants.SATURDAY] = 5;
	dayIds[standardWeekConstants.SUNDAY] = 6;
	dayIds['HOLIDAY'] = 7;

	if (!calendar) {
		return timeSlots;
	}

	for (const dayType in calendar.dayTypes) {
		if (!calendar.dayTypes.hasOwnProperty(dayType)) {
			continue;
		}

		let days = Object.keys(calendar.standardWeek)
			.filter((dayKey) => calendar.standardWeek[dayKey] === dayType)
			.map((dayKey) => {
				return { type: dayKey, id: dayIds[dayKey] };
			});

		days = sortBy(days, ['id']);

		let intervals = [];

		if (Object.values(calendar.standardWeek).some((otherDayType) => otherDayType === dayType)) {
			intervals = schedule.timeIntervals[dayType] || [];
		} else {
			// this is specifically for handling
			// embedded schedule data inside holidays
			const dayTypeObject = calendar.dayTypes[dayType];

			if (dayTypeObject.description && dayTypeObject.description.includes('=')) {
				intervals = dayTypeObject.description
					.split(';')
					.reduce((items, item) => {
						const interval = item.split('=');
						if (interval.length === 2) {
							interval[1].split(',').forEach((times) => {
								times = times.split('-');
								if (times.length === 2) {
									items.push({ state: interval[0].trim(), startTime: times[0], endTime: times[1] });
								}
							});
						}
						return items;
					}, [])
					.filter((item) => item !== null);
			}

			if (days.length === 0) {
				days.push({ type: 'HOLIDAY', id: dayIds['HOLIDAY'] });
			}
		}

		intervals = intervals
			.map((i) => {
				if (i.endTime === '23:59') {
					i.endTime = '24:00';
				}

				return i;
			})
			.filter((i) => !(i.state === 'CARD ONLY' && i.startTime === '00:00' && i.endTime === '24:00'));

		const sortIndex = calendar.dayTypes[dayType].sortOrder;
		timeSlots[sortIndex] = {
			name: dayType,
			id: sortIndex,
			days,
			modeTimeIntervals: intervals,
		};
	}

	const holidaySlot = timeSlots.find((s) => s.days && s.days.length === 1 && s.days[0].type === 'HOLIDAY');

	if (!holidaySlot) {
		timeSlots.push({
			id: timeSlots.length,
			name: 'Holiday',
			modeTimeIntervals: [],
			days: [
				{
					id: 7,
					type: 'HOLIDAY',
					label: 'Holiday',
				},
			],
		});
	}

	return timeSlots;
};
