import React, { useEffect, useMemo, useState } from 'react';
import { TreeSelect } from 'antd';
import isEqual from 'lodash/isEqual';

import {
	SEPARATOR,
	determineTypeOfUncheckedAsset,
	getAllSameGroupAssets,
	handleDoorGroupAssetRemoval,
	handleDoorGroupRemoval,
	isAssetPartOfGroup,
	generateTreeSelectorData,
	getAssetsForDoorGroup,
} from '~/components/features/door-groups/DoorGroupsAssetTreeSelector/DoorGroupsAssetTreeSelector.utils';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { getPortalsKey, usePortals } from '~/hooks/data/portals/usePortals';
import { useLocale } from '~/screens/_shared/AppLocale';
import { AssetTypes } from '~/constants/AssetTypes';
import { css } from '@emotion/core';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

const { SHOW_CHILD } = TreeSelect;

const UngroupedAssetsType = {
	UNGROUPED_APERIO: 'UNGROUPED_APERIO',
	UNGROUPED_PULSE: 'UNGROUPED_PULSE',
};

export const getUniqueAssets = (assets) => {
	const uniqueAssets = new Set();

	assets.forEach((asset) => {
		if (isAssetPartOfGroup(asset)) {
			const [, assetId] = asset.split(SEPARATOR);
			uniqueAssets.add(assetId);
		} else {
			uniqueAssets.add(asset);
		}
	});

	return [...uniqueAssets];
};

const getUngroupedAssets = (assets) => {
	return assets.filter(
		(asset) =>
			asset.value !== UngroupedAssetsType.UNGROUPED_APERIO && asset.value !== UngroupedAssetsType.UNGROUPED_PULSE
	);
};

export const DoorGroupsAssetTreeSelector = ({
	onChange,
	setIsWarningMessageVisible,
	validateDoors,
	doorGroupAssetId,
	disabled,
}) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();

	const {
		data: assets,
		isLoading: isLoadingAssets,
		isValidating: isValidatingAssets,
	} = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			pageSize: 1000,
			sortBy: 'offline_reference_id',
			sortDirection: 'DESCENDING',
			assetType: AssetTypes.BASIC_ASSET,
		})
	);
	const {
		data: { portals } = { portals: [] },
		isLoading: isLoadingPortals,
		isValidating: isValidatingPortals,
	} = usePortals(
		getPortalsKey({
			customerId,
			systemId,
		})
	);
	const [selectedAssets, setSelectedAssets] = useState([]);
	const [initialValues, setInitialValues] = useState([]);

	const isLoading = isLoadingAssets || isValidatingAssets || isLoadingPortals || isValidatingPortals;

	const data = useMemo(() => {
		if (isLoading) return [];

		const assetTreeSelectorData =
			portals?.map((portal) => {
				return {
					name: portal.name,
					assetId: portal.configuration?.additionalSettings?.doorAssetId,
					doorType: portal.configuration?.additionalSettings?.doorType,
				};
			}) || [];

		return generateTreeSelectorData(assets.assets, assetTreeSelectorData);
	}, [isLoading, portals, assets]);

	const ungroupedAssets = useMemo(() => (!isLoading ? getUngroupedAssets(data) : []), [data]);

	const assetsInGroups = useMemo(() => {
		if (isLoading) return [];

		return data
			.filter((asset) => {
				if ([UngroupedAssetsType.UNGROUPED_PULSE, UngroupedAssetsType.UNGROUPED_APERIO].includes(asset.key))
					return false;
				return asset.children;
			})
			.map((asset) => ({
				assetId: asset.key,
				title: asset.title,
				children: asset.children.map((child) => {
					const [, assetId] = child.value.split(SEPARATOR);
					return assetId;
				}),
			}));
	}, [data]);

	useEffect(() => {
		if (doorGroupAssetId && !isLoading) {
			const doorGroupAssets = getAssetsForDoorGroup(doorGroupAssetId, assets.assets, portals);
			setSelectedAssets(doorGroupAssets);
			setInitialValues(doorGroupAssets);
		}
	}, [assets, portals, doorGroupAssetId, isLoading]);

	useEffect(() => {
		if (!isEqual(selectedAssets, initialValues)) {
			setIsWarningMessageVisible(true);
		} else {
			setIsWarningMessageVisible(false);
		}
	}, [selectedAssets, initialValues]);

	useEffect(() => {
		const uniqueAssets = getUniqueAssets(selectedAssets);

		const groupWithSelectedAssets = assetsInGroups.find((asset) => {
			return isEqual(asset.children, uniqueAssets);
		});

		const isEditingOwnDoors = doorGroupAssetId === groupWithSelectedAssets?.assetId;

		if (groupWithSelectedAssets && !isEditingOwnDoors) {
			validateDoors([
				translate.byKeyFormatted('door_group_already_exists', { doorGroupName: groupWithSelectedAssets.title }),
			]);
		} else {
			validateDoors([]);
		}
	}, [selectedAssets, assetsInGroups, doorGroupAssetId]);

	const onTreeChange = (values, _, extra) => {
		const { triggerValue, checked } = extra;

		if (!checked) {
			if (determineTypeOfUncheckedAsset(triggerValue, selectedAssets) === 'DOOR_GROUP_ASSET') {
				const assets = handleDoorGroupAssetRemoval(triggerValue, selectedAssets);
				setSelectedAssets(assets);
				onChange(getUniqueAssets(assets));
				return;
			}

			if (determineTypeOfUncheckedAsset(triggerValue, selectedAssets) === 'DOOR_GROUP') {
				const assets = handleDoorGroupRemoval(triggerValue, selectedAssets);
				setSelectedAssets(assets);
				onChange(getUniqueAssets(assets));
				return;
			}
		}

		const assetsToInclude = values.reduce((accumulator, currentValue) => {
			if (isAssetPartOfGroup(currentValue)) {
				const allSameGroupAssets = getAllSameGroupAssets(currentValue, ungroupedAssets);
				accumulator.push(...allSameGroupAssets);
			} else {
				accumulator.push(currentValue);
			}
			return accumulator;
		}, []);

		setSelectedAssets(assetsToInclude);

		const uniqueAssets = getUniqueAssets(assetsToInclude);
		onChange(uniqueAssets);
	};

	return (
		<TreeSelect
			treeData={data}
			value={selectedAssets}
			onChange={onTreeChange}
			treeCheckable
			filterTreeNode
			treeNodeFilterProp={'tag'}
			treeNodeLabelProp={'title'}
			showCheckedStrategy={SHOW_CHILD}
			placeholder={translate.byKey('select_door_or_doors')}
			style={{
				width: '100%',
			}}
			disabled={disabled || isLoading}
			loading={isLoading}
			virtual={false}
			css={treeSelectTagStyles}
		/>
	);
};

const treeSelectTagStyles = css({
	'& .ant-select-selection-item': {
		border: '1px solid #d9d9d9',
		backgroundColor: '#fafafa',
		fontSize: '12px',
		lineHeight: '20px',
	},
});
