import React from 'react';

export const viewBox = '0 0 128 128';

const path = (
	<g fill="currentColor">
		<path d="M96 114.4H39.413a1.75 1.75 0 01-1.75-1.75v-97.3a1.75 1.75 0 011.75-1.75H96a1.75 1.75 0 011.75 1.75V54a1.75 1.75 0 01-3.5 0V17.1H41.163v93.8H94.25V74a1.75 1.75 0 013.5 0v38.65A1.75 1.75 0 0196 114.4z" />
		<path d="M47.459 121.5L7.652 103.808V24.192L47.459 6.5z" />
		<path d="M121.5 62.25H68.225l9.523-9.523a1.75 1.75 0 00-2.475-2.475L62.765 62.76a1.755 1.755 0 00-.221.271c-.025.038-.042.079-.064.118a1.045 1.045 0 00-.149.352c-.016.052-.036.1-.047.157a1.756 1.756 0 000 .685c.011.054.031.1.046.156a1.7 1.7 0 00.053.17 1.732 1.732 0 00.1.182c.022.039.039.081.065.119a1.755 1.755 0 00.221.271l12.504 12.507a1.75 1.75 0 002.475-2.475l-9.523-9.523H121.5a1.75 1.75 0 000-3.5z" />
		<path d="M13.076 97.083a1.75 1.75 0 01-1.75-1.75V66.667a1.75 1.75 0 013.5 0v28.666a1.75 1.75 0 01-1.75 1.75z" />
	</g>
);

const entry = {
	path,
	viewBox,
};

export default entry;
