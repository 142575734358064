import React from 'react';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { Button, PageHeader } from '~/components';
import { useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useLocale } from '~/screens/_shared/AppLocale';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getDefaultAccessSchedulesKey } from '~/components/features/access-schedules/AccessSchedules.utils';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AccessSchedulesPageHeader = ({ onAddNewButtonClick }) => {
	const { translate } = useLocale();
	const { queryParams } = useQueryParams();
	const { data: user } = useUserAuthData();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		isLoading: isLoadingAccessSchedules,
		isValidating: isValidatingAccessSchedules,
		mutate: refetchAccessSchedules,
	} = useAccessSchedules(
		getDefaultAccessSchedulesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
			sortBy: queryParams?.sortBy,
			sortDirection: queryParams?.sortDirection,
		})
	);

	const onDataRefetch = () => {
		refetchAccessSchedules();
	};

	return (
		<PageHeader title={translate.byKey('access_schedules')} icon="reports">
			<Button
				disabled={isLoadingAccessSchedules || isValidatingAccessSchedules}
				type="primary"
				icon={<ReloadOutlined />}
				onClick={onDataRefetch}
			>
				{translate.byKey('refresh')}
			</Button>

			{hasActionPermissions(user, userConstants.screens.ACCESS_SCHEDULES, userConstants.actions.CREATE) ? (
				<Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddNewButtonClick}>
					{translate.byKey('add_new')}
				</Button>
			) : null}
		</PageHeader>
	);
};
