import { useRef } from 'react';
import useSWR from 'swr';
import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';
import mappers from '~/screens/_shared/mappers';

const separator = '__';

const defaultConfig = {
	revalidateOnFocus: false,
};

export const useGetCalendars = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: mappers.calendar,
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = () => ref.current.fetchData();

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getCalendarsKey = ({ customerId, systemId }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
getCalendars`
		: null;
