export const screens = {
	LIVE: 'LIVE',
	PEOPLE: 'PEOPLE',
	CONTROLLERS: 'CONTROLLERS',
	DOORS: 'DOORS',
	DOOR_GROUPS: 'DOOR_GROUPS',
	DOOR_MODES: 'DOOR_MODES',
	REPORT_STATUS: 'REPORT_STATUS',
	REPORT_ACCESS: 'REPORT_ACCESS',
	REPORT_AUDIT: 'REPORT_AUDIT',
	HOLIDAYS: 'HOLIDAYS',
	ACCESS_GROUPS: 'ACCESS_GROUPS',
	ACCESS_SCHEDULES: 'ACCESS_SCHEDULES',
	ACCESS_PROFILES: 'ACCESS_PROFILES',
	CONTROLLER_ONBOARD: 'CONTROLLER_ONBOARD',
	CARDS: 'CARDS',
	READER: 'READER',
	SITES: 'SITES',
	SETTINGS: 'SETTINGS',
	REPORTS: 'REPORTS',
	CARD_FORMAT: 'CARD_FORMAT',
	BLOCKLIST: 'BLOCKLIST',
	SYSTEM_SETTINGS: 'SYSTEM_SETTINGS',
	ADD_SITE: 'ADD_SITE',
	USB_DEVICES: 'USB_DEVICES',
};

export const actions = {
	READ: 'READ',
	WRITE: 'WRITE',
	CREATE: 'CREATE',
	DELETE: 'DELETE',
};
