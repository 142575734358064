import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

const componentStyle = StyleSheet.create({
	flexDirection: 'row',
	width: '100%',
});

export const TableRow = ({ children, style, ...rest }) => {
	return (
		<View
			style={{
				...componentStyle,
				...style,
			}}
			{...rest}
		>
			{children}
		</View>
	);
};
