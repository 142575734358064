import { useState } from 'react';

import { method } from '~/screens/_shared/useApi/apiConstants';
import { useDeleteApi, useListApi } from '~/screens/_shared/useApi';
import { useLocale } from '~/screens/_shared/AppLocale';
import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import mappers from '~/screens/_shared/mappers';

export function useDelete() {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState('');

	const [deleteAccessProfile] = useDeleteApi(mappers.accessProfile);
	const [deleteSchedule] = useDeleteApi(mappers.schedule);
	const [deleteCalendar] = useDeleteApi(mappers.calendar);
	const [getAccessGroups] = useListApi(mappers.accessProfile);
	const { translate } = useLocale();

	const deleteAccessGroup = async (formData) => {
		setIsLoading(true);
		setCurrentStep(translate.byKey('deleting_access_group'));
		try {
			const accessGroups = await getAccessGroups({ params: { 'detail-level': 'FULL' } });

			const schedules = accessGroups.reduce((results, group) => {
				if (group.accessProfileId !== formData.accessProfileId) {
					const policies = group.accessPolicies || [];
					if (policies.some((p) => !!p.schedule)) {
						return results.concat(policies.filter((p) => !!p.schedule).map((p) => p.schedule));
					}
				}
				return results;
			}, []);

			await deleteAccessProfile(
				{
					accessProfileId: formData.accessProfileId,
					__additionalHeaders: { checkDependencies: false },
				},
				{
					removeExistingKey: getCacheKey(method.list, mappers.accessProfile, { params: { 'detail-level': 'FULL' } }),
				}
			);

			formData.accessPolicies = JSON.parse(formData.accessPolicies || '[]');

			for (const accessPolicy of formData.accessPolicies) {
				if (accessPolicy.schedule) {
					const calendarId = accessPolicy.schedule.calendarId;
					const scheduleId = accessPolicy.schedule.scheduleId;

					if (!schedules.some((i) => i.calendarId === calendarId || i.scheduleId === scheduleId)) {
						setCurrentStep(translate.byKey('deleting_schedule'));
						await deleteSchedule({ scheduleId, calendarId });

						setCurrentStep(translate.byKey('deleting_calendar'));
						await deleteCalendar({ calendarId });
					}
				}
			}
		} finally {
			setIsLoading(false);
			setCurrentStep('');
		}
	};

	return [deleteAccessGroup, isLoading, currentStep];
}
