export const method = {
	get: 'get',
	list: 'list',
	post: 'post',
	put: 'put',
	patch: 'patch',
	delete: 'delete',
};

export const problemCodes = {
	/**
	 * @description Status codes (200-299). No problems
	 */
	NONE: null,

	/**
	 * @description Status codes (400-499). Any non-specific 400 series error
	 */
	CLIENT_ERROR: 'CLIENT_ERROR',

	/**
	 * @description Status codes (500-599). Any 500 series error
	 */
	SERVER_ERROR: 'SERVER_ERROR',

	/**
	 * @description Server didn't respond in time.
	 */
	TIMEOUT_ERROR: 'TIMEOUT_ERROR',

	/**
	 * @description Server not available, bad dns.
	 */
	CONNECTION_ERROR: 'CONNECTION_ERROR',

	/**
	 * @description Network not available.
	 */
	NETWORK_ERROR: 'NETWORK_ERROR',

	/**
	 * @description Request has been cancelled. Only possible if `cancelToken` is provided in config, see axios `Cancellation`.
	 */
	CANCEL_ERROR: 'CANCEL_ERROR',

	/**
	 * @description Network not available.
	 */
	UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};
