import React, { forwardRef } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const ClearButton = forwardRef(({ children, ...rest }, ref) => {
	return (
		<Button ref={ref} type="primary" size="small" {...rest}>
			{children || <CloseOutlined />}
		</Button>
	);
});
