import { useEffect, useState } from 'react';

const oneSecondInMs = 1000;

export const useCountdown = (timeInMs, startValue) => {
	const [countdownTimeInMs, setCountdownTimeInMs] = useState(timeInMs);

	useEffect(() => {
		if (!Boolean(startValue)) {
			return;
		}

		setCountdownTimeInMs(timeInMs);

		const interval = setInterval(() => {
			setCountdownTimeInMs((prevCountdownTimeInMs) => {
				const countdownTimeInMsDecreasedBySecond = prevCountdownTimeInMs - oneSecondInMs;

				return countdownTimeInMsDecreasedBySecond <= 0 ? 0 : countdownTimeInMsDecreasedBySecond;
			});
		}, oneSecondInMs);

		return () => {
			clearInterval(interval);
		};
	}, [timeInMs, startValue]);

	return countdownTimeInMs;
};
