const port = {
	base: {
		name: ({ name }) => name,
		description: ({ name }) => name,
		configuration: {
			portType: 'SERIAL',
			serialConfiguration: {
				baudRate: 38400,
				characterLength: 0,
			},
		},
	},
	update: {
		version: false,
	},
	params: {},
	baseUrlPath: [
		'customer',
		':customerId:',
		'system',
		':systemId:',
		'site',
		':siteId:',
		'controller',
		':controllerId:',
		'port',
	],
};

export default port;
