import { useState } from 'react';
import mappers from '~/screens/_shared/mappers';
import { securityLevels as securityLevelConstants } from '../entityConstants';
import { usePostApi, useListApi } from '~/screens/_shared/useApi';
import keyBy from 'lodash/keyBy';
import has from 'lodash/has';
import isArrayLike from 'lodash/isArrayLike';
import { translator } from '~/screens/_shared/AppLocale';
import { showWarningToast } from '~/screens/_shared/toast';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import useSWR from 'swr';

const fallbackData = [];

export const useSecurityLevels = () => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const [keyedSecurityLevels, setKeyedSecurityLevels] = useState(null);

	const [getSecurityLevels] = useListApi(mappers.securityLevel);
	const [createSecurityLevel] = usePostApi(mappers.securityLevel);

	const fetcher = async () => {
		try {
			const securityLevels = await getSecurityLevels();

			if (securityLevels?.length > 0) {
				const keyedSecurityLevels = keyBy(securityLevels, 'name');
				setKeyedSecurityLevels(keyedSecurityLevels);
				for (const value of Object.values(securityLevelConstants)) {
					if (!has(keyedSecurityLevels, value)) {
						await createSecurityLevel(payloads[value]);
					}
				}
				return keyedSecurityLevels;
			} else if (isArrayLike(securityLevels)) {
				for (const value of Object.values(securityLevelConstants)) {
					await createSecurityLevel(payloads[value]);
				}
			}

			return keyedSecurityLevels;
		} catch (error) {
			const errorMessage = error.message || error.data?.message;
			if (errorMessage) {
				showWarningToast({
					message: translator.byValue(
						errorMessage.replace(
							'Kindly patch the administrator with system to gain access',
							'kindly contact your administrator to update your user and/or system to gain access.'
						)
					),
				});
			}
		}
	};

	return useSWR(customerId && systemId ? `/customer/${customerId}/system/${systemId}/security-level` : null, fetcher, {
		revalidateOnFocus: false,
		revalidateIfStale: false,
		revalidateOnReconnect: false,
		refreshInterval: 1000 * 60 * 10, // 10 minutes
		fallbackData,
	});
};

const payloads = {
	[securityLevelConstants.CARD]: {
		name: securityLevelConstants.CARD,
		description: 'This security level requires card only',
		priority: 3,
		groups: [
			{
				items: [
					{
						type: 'CREDENTIAL_NUMBER',
						order: 1,
					},
				],
			},
		],
	},
	[securityLevelConstants.CARD_AND_PIN]: {
		name: securityLevelConstants.CARD_AND_PIN,
		description: 'This security level requires first card and then PIN code',
		priority: 2,
		groups: [
			{
				items: [
					{
						type: 'CREDENTIAL_NUMBER',
						order: 1,
					},
					{
						type: 'PIN',
						order: 2,
					},
				],
			},
		],
	},
	[securityLevelConstants.LOCKED]: {
		name: securityLevelConstants.LOCKED,
		description: 'No access is allowed',
		priority: 1,
		groups: [
			{
				items: [],
			},
		],
	},
	[securityLevelConstants.UNLOCKED]: {
		name: securityLevelConstants.UNLOCKED,
		description: 'Unlocked',
		priority: 20,
		groups: [],
	},
	[securityLevelConstants.OPEN_ON_FIRST_CARD]: {
		name: securityLevelConstants.OPEN_ON_FIRST_CARD,
		description: 'Open on first card',
		priority: 5,
		groups: [],
	},
	[securityLevelConstants.PAC]: {
		name: securityLevelConstants.PAC,
		description: 'PAC',
		priority: 4,
		groups: [
			{
				items: [
					{
						type: 'PIN',
						order: 1,
					},
				],
			},
		],
	},
	[securityLevelConstants.OFFICE_MODE]: {
		name: securityLevelConstants.OFFICE_MODE,
		description: 'Office Mode',
		priority: 6,
		groups: [],
	},
};
