import { AsYouType, getCountries, getCountryCallingCode, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import React, { useEffect, useState } from 'react';

import { Input, Select } from '~/components';

import { useLocale } from '~/screens/_shared/AppLocale';
import { getCode } from 'country-list';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export function setPhoneInputValue(value, countryDialCode, phoneNumber, countryCodes) {
	const result = { countryDialCode, phoneNumber };
	value = value || '';

	if (Array.isArray(value)) {
		value = value.join(' ');
	}

	value = value.trim();

	if (value) {
		try {
			const phoneData = parsePhoneNumber(value);

			if (countryDialCode !== phoneData.countryCallingCode && phoneNumber !== phoneData.nationalNumber) {
				result.countryDialCode = `+${phoneData.countryCallingCode}`;
				result.phoneNumber = phoneData.nationalNumber;
			}
		} catch {
			if (value && (value.includes(',') || value.includes('-') || value.includes(' '))) {
				const temp = value
					.split(',')
					.map((val) => val.split('-'))
					.flat()
					.map((val) => val.split(' '))
					.flat();

				let dialCode = temp.shift();

				if (dialCode && dialCode.includes('+')) {
					dialCode = `+${dialCode.split('+').pop() || ''}`;
				}

				let realCountryCode = countryCodes.find((code) => code.dial_code === dialCode);

				if (!realCountryCode && dialCode && !dialCode.includes('+')) {
					realCountryCode = countryCodes.find((code) => code.dial_code === `+${dialCode}`);
					dialCode = `+${dialCode}`;
				}

				if (realCountryCode) {
					result.countryDialCode = dialCode;
				}

				result.phoneNumber = temp.join('').split(' ').join('');
			} else if (value !== phoneNumber) {
				result.phoneNumber = value;
			}
		}
	}

	return result;
}

const PhoneInput = (props) => {
	let { onChange, value, ...selectiveProps } = props;

	const {
		data: { site },
	} = useCurrentSystemSite();
	const [mask, setMask] = useState([...Array(16)].map(() => '9').join(''));
	const { locale, translate, countries } = useLocale();

	const [phoneNumber, setPhoneNumber] = useState('');

	const [countryCodes] = useState(
		getCountries()
			.map((countryId) => {
				const { max, significant, mask } = getMaskDetails(countryId);
				return {
					name: countries.getName(countryId, locale) || countries.getName(countryId, 'en'),
					dial_code: `+${getCountryCallingCode(countryId)}`,
					countryId,
					max,
					mask,
					significant,
				};
			})
			.filter((country) => !!country.name)
	);

	let possibleCountry;

	if (site && site.location && site.location.includes && site.location.includes(',')) {
		let countryCode = site.location.split(',')[0] || '';

		if (countryCode) {
			if (countryCode === 'United Kingdom') {
				countryCode = 'United Kingdom of Great Britain and Northern Ireland';
			}
			countryCode = getCode(countryCode);

			if (countryCode) {
				possibleCountry = countryCodes.find(
					(country) => country.countryId.toLowerCase() === countryCode.toLocaleLowerCase()
				);
			}
		}
	}

	if (!possibleCountry) {
		possibleCountry = countryCodes.find(
			(country) => country.countryId.toLowerCase() === (locale || 'gb').toLocaleLowerCase()
		);
	}

	if (!possibleCountry) {
		possibleCountry = countryCodes.find((country) => country.countryId.toLowerCase() === 'gb');
	}

	const [countryDialCode, setCountryDialCode] = useState(possibleCountry ? possibleCountry.dial_code : '');

	useEffect(() => {
		const result = setPhoneInputValue(value, countryDialCode, phoneNumber, countryCodes);

		if (result.countryDialCode !== countryDialCode) {
			setCountryDialCode(result.countryDialCode);
		}

		if (result.phoneNumber !== phoneNumber.trim()) {
			setPhoneNumber(result.phoneNumber);
		}
	}, [value]);

	function getFinalNumber(countryDialCode, phoneNumber) {
		phoneNumber = phoneNumber.trim();
		if (!countryDialCode) {
			return phoneNumber;
		}

		if (countryDialCode && !phoneNumber) {
			return '';
		}

		return `${countryDialCode} ${phoneNumber}`;
	}

	function onEitherValueChanged(countryDialCode, phoneNumber) {
		phoneNumber = phoneNumber || '';
		phoneNumber = phoneNumber.split(' ').join('');

		value = value || '';

		if (Array.isArray(value)) {
			value = value.join(' ');
		}

		value = value.trim();

		if (value) {
			try {
				const phoneData = parsePhoneNumber(value);

				if (countryDialCode !== phoneData.countryCallingCode || phoneNumber !== phoneData.nationalNumber) {
					onChange(getFinalNumber(countryDialCode, phoneNumber));
				}
			} catch {
				onChange(getFinalNumber(countryDialCode, phoneNumber));
			}
		} else {
			onChange(getFinalNumber(countryDialCode, phoneNumber));
		}
	}

	useEffect(() => {
		updateMaskAndMax(countryDialCode);
	}, [countryDialCode]);

	const onTextChanged = (e) => {
		const { value: textValue } = e.target;
		setPhoneNumber(textValue);
		onEitherValueChanged(countryDialCode, textValue);
	};

	function getMaskDetails(countryId) {
		const asYouType = new AsYouType(countryId);
		let num = getExampleNumber(countryId, examples).number;
		if (countryId === 'AT') {
			num = '+4366412345678';
		}
		let phoneNumber = parsePhoneNumber(num);
		let formattedNationalNumber = phoneNumber.formatNational().replace(/[^\d]/g, '');
		asYouType.input(formattedNationalNumber);

		let mask = asYouType.getTemplate().replace(/x/g, '9');

		asYouType.reset();

		return mask;
	}

	const updateMaskAndMax = (countryDialCode) => {
		const countryInfo = countryCodes.find((country) => country.dial_code === countryDialCode);

		if (countryInfo) {
			setMask(getMaskDetails(countryInfo.countryId));
		}
	};

	return (
		<Input
			{...selectiveProps}
			autoComplete="off"
			aria-autocomplete="none"
			onChange={onTextChanged}
			value={phoneNumber}
			mask={mask}
			maskChar=" "
			style={{ borderRadius: '2px' }}
			addonBefore={
				<Select
					value={countryDialCode}
					onChange={(country) => {
						setCountryDialCode(country);
						onEitherValueChanged(country, phoneNumber);
					}}
					showSearch
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					optionLabelProp="label"
					placeholder={translate.byKey('search')}
					dropdownMatchSelectWidth={250}
					isAddon
					disabled={props.disabled}
				>
					{countryCodes.map((item, index) => {
						return (
							<Select.Option value={item.dial_code} key={index} label={item.dial_code}>
								{`${item.name} (${item.dial_code})`}
							</Select.Option>
						);
					})}
				</Select>
			}
		/>
	);
};

export default PhoneInput;
