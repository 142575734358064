import React from 'react';
import { Flex } from '~/components';
import LoadingScreen from '~/LoadingScreen';
import Header from '~/components/shared/layouts/MainLayout/Header/Header';
import { Footer } from '~/components/shared/layouts/MainLayout/Footer/Footer';
import { css } from '@emotion/core';
import Navigation from '~/components/shared/layouts/MainLayout/Navigation/Navigation';
import { EmergencyAlert } from '~/components/shared/layouts/MainLayout/EmergencyAlert/EmergencyAlert';
import { HolidayAlert } from '~/components/shared/layouts/MainLayout/HolidayAlert/HolidayAlert';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import {
	supportsSiteLevel,
	supportsSystemLevel,
} from '~/components/shared/layouts/MainLayout/Navigation/AppMenu/menuConfig';
import { ModalsContainer } from '~/components/shared/layouts/MainLayout/ModalsContainer/ModalsContainer';
import { SiteControllersEmergencyRecordsProvider } from '~/screens/_shared/emergency';
import { useMainLayoutData, usePopulateDatabase } from './MainLayout.hooks';

export const MainLayout = () => {
	const location = useLocation();

	const {
		data: { tokens },
	} = useUserAuthData();

	usePopulateDatabase();

	const { isSiteInitiallySet, isSiteSet, isSystemSet } = useMainLayoutData();

	return (
		<Flex height="100svh" width="100%" flexDirection="column" padding="16px 0 0 0">
			{!tokens?.length ? <Navigate to="/auth" replace /> : null}
			{isSystemSet && isSiteInitiallySet && isSiteSet && !supportsSiteLevel(location.pathname) ? (
				<Navigate to="/live" replace />
			) : null}
			{isSystemSet && isSiteInitiallySet && !isSiteSet && !supportsSystemLevel(location.pathname) ? (
				<Navigate to="/live" replace />
			) : null}
			<SiteControllersEmergencyRecordsProvider>
				<ModalsContainer>
					<LoadingScreen />
					<Header />
					<Navigation />
					<EmergencyAlert />
					<HolidayAlert />
					<main css={mainStyles}>
						<Outlet />
					</main>
					<Footer />
				</ModalsContainer>
			</SiteControllersEmergencyRecordsProvider>
		</Flex>
	);
};

const mainStyles = css({
	paddingInline: '16px',
	flex: 1,
	display: 'flex',
	borderRadius: '2px',
});
