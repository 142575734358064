import React, { useMemo } from 'react';
import { Button, message, Modal, Spin } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { LoadingOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';

import { useLocale } from '~/screens/_shared/AppLocale';
import { WeeklyCalendar } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar';
import {
	AccessScheduleForm,
	allowAccessOnHolidaysField,
	nameField,
	weekPeriodsField,
} from '~/components/features/access-schedules/AccessScheduleForm/AccessScheduleForm';
import { getAccessScheduleKey, useAccessSchedule } from '~/hooks/data/access-schedules/useAccessSchedule';
import {
	adjustAPISchedulesToWeekPeriods,
	adjustWeekPeriodsToAPISchedules,
} from '~/utils/access-schedule/AccessSchedule.utils';
import {
	getAccessScheduleDeleteKey,
	useAccessScheduleDelete,
} from '~/hooks/data/access-schedules/useAccessScheduleDelete';
import { useAccessSchedules } from '~/hooks/data/access-schedules/useAccessSchedules';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import {
	getAccessScheduleUpdateKey,
	useAccessScheduleUpdate,
} from '~/hooks/data/access-schedules/useAccessScheduleUpdate';
import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { getDefaultAccessSchedulesKey } from '~/components/features/access-schedules/AccessSchedules.utils';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const AccessScheduleUpdateModal = ({ selectedAccessScheduleId, onClose }) => {
	const { translate } = useLocale();
	const { data: user } = useUserAuthData();
	const { queryParams } = useQueryParams();
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const {
		data: accessSchedule,
		isLoading,
		isValidating,
	} = useAccessSchedule(
		getAccessScheduleKey({
			customerId,
			systemId,
			id: selectedAccessScheduleId,
		})
	);
	const { trigger: updateAccessSchedule, isMutating: isUpdatingAccessSchedule } = useAccessScheduleUpdate(
		getAccessScheduleUpdateKey({ customerId, systemId, accessScheduleId: selectedAccessScheduleId })
	);
	const { trigger: deleteAccessSchedule, isMutating: isDeletingAccessSchedule } = useAccessScheduleDelete(
		getAccessScheduleDeleteKey({ customerId, systemId, accessScheduleId: selectedAccessScheduleId })
	);
	const { mutate: reloadAccessSchedules } = useAccessSchedules(
		getDefaultAccessSchedulesKey({
			customerId,
			systemId,
			pageSize: queryParams?.pageSize,
			pageNumber: queryParams?.pageNumber,
			name: queryParams?.name,
			sortBy: queryParams?.sortBy,
			sortDirection: queryParams?.sortDirection,
		}),
		{ revalidateOnMount: false }
	);
	const [form] = useForm();
	const {
		[nameField]: name,
		[allowAccessOnHolidaysField]: allowAccessOnHolidays,
		[weekPeriodsField]: weekPeriods,
	} = useWatch([], form) || {};
	const initialValues = useMemo(() => {
		if (!accessSchedule?.name) {
			return null;
		}

		return {
			[nameField]: accessSchedule.name,
			[allowAccessOnHolidaysField]: accessSchedule.allowAccessOnHolidays,
			[weekPeriodsField]: adjustAPISchedulesToWeekPeriods(accessSchedule.schedules),
		};
	}, [accessSchedule?.name, accessSchedule?.allowAccessOnHolidays, accessSchedule?.schedules]);

	const isInitialAndCurrentDataEqual =
		name === accessSchedule?.name &&
		allowAccessOnHolidays === accessSchedule?.allowAccessOnHolidays &&
		isEqual(weekPeriods, adjustAPISchedulesToWeekPeriods(accessSchedule?.schedules));

	const onSubmit = async () => {
		form
			.validateFields()
			.then(async (values) => {
				try {
					await updateAccessSchedule(
						omitBy(
							{
								name: values[nameField] !== initialValues[nameField] ? values[nameField] : undefined,
								allowAccessOnHolidays:
									values[allowAccessOnHolidaysField] !== initialValues[allowAccessOnHolidaysField]
										? values[allowAccessOnHolidaysField]
										: undefined,
								schedules: !isEqual(values[weekPeriodsField], initialValues[weekPeriodsField])
									? adjustWeekPeriodsToAPISchedules(values[weekPeriodsField])
									: undefined,
								version: accessSchedule.version,
							},
							(value) => value === undefined
						)
					);

					onClose();
					message.success(translate.byKey('access_schedule_updated_successfully'));
					reloadAccessSchedules();
				} catch (error) {
					message.error(`${translate.byKey('an_error_occurred')}: ${JSON.stringify(error?.data?.message)}`);
				}
			})
			.catch(() => {});
	};

	const onDelete = () => {
		Modal.confirm({
			title: translate.byKey('confirm_delete'),
			content: translate.byKey('access_schedule_delete_confirmation_message'),
			okText: translate.byKey('delete'),
			cancelText: translate.byKey('cancel'),
			onOk: async () => {
				try {
					await deleteAccessSchedule(selectedAccessScheduleId);

					onClose();
					message.success(translate.byKey('access_schedule_deleted_successfully'));
					reloadAccessSchedules();
				} catch (error) {
					message.error(`${translate.byKey('an_error_occurred')} ${JSON.stringify(error?.data?.message)}`);
				}
			},
		});
	};

	const onModalDismiss = () => {
		if (!isInitialAndCurrentDataEqual) {
			Modal.confirm({
				title: translate.byKey('unsaved_changes'),
				content: translate.byKey('discard_changes_message'),
				okText: translate.byKey('yes'),
				cancelText: translate.byKey('no'),
				onOk: () => {
					onClose();
				},
			});
		} else {
			onClose();
		}
	};

	return (
		<Modal
			open
			width={590}
			title={isLoading || isValidating ? '...' : accessSchedule?.name}
			maskClosable={false}
			onCancel={onModalDismiss}
			footer={[
				<Button key="cancel" onClick={onModalDismiss} disabled={isUpdatingAccessSchedule || isDeletingAccessSchedule}>
					{translate.byKey('cancel')}
				</Button>,
				hasActionPermissions(user, userConstants.screens.ACCESS_SCHEDULES, userConstants.actions.DELETE) ? (
					<Button
						key="delete"
						type="primary"
						onClick={onDelete}
						danger
						disabled={isUpdatingAccessSchedule || isDeletingAccessSchedule}
						loading={isDeletingAccessSchedule}
					>
						{translate.byKey('delete')}
					</Button>
				) : null,
				<Button
					key="update"
					type="primary"
					onClick={onSubmit}
					disabled={isUpdatingAccessSchedule || isDeletingAccessSchedule || isInitialAndCurrentDataEqual}
					loading={isUpdatingAccessSchedule}
				>
					{translate.byKey('update')}
				</Button>,
			]}
		>
			{isLoading || isValidating ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '435px' }}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: '36px' }} spin />} />
				</div>
			) : (
				<>
					<AccessScheduleForm initialValues={initialValues} form={form} />

					<div style={{ marginTop: '32px' }}>
						<WeeklyCalendar periods={weekPeriods} />
					</div>
				</>
			)}
		</Modal>
	);
};
