import React from 'react';

export const viewBox = '0 -22 512 512';

const path = (
	<g fill="currentColor">
		<path d="M463.285 0H126.23a7.604 7.604 0 100 15.207h337.055c18.477 0 33.508 15.031 33.508 33.504V319.3c0 18.476-15.031 33.504-33.508 33.504H48.711c-18.473 0-33.504-15.028-33.504-33.504V48.71c0-18.473 15.031-33.504 33.504-33.504h47.102a7.604 7.604 0 100-15.207H48.71C21.85 0 0 21.852 0 48.71V319.3c0 26.86 21.852 48.712 48.71 48.712h122.274L156.09 416.68h-27.922c-14.258 0-25.852 11.597-25.852 25.851 0 14.258 11.594 25.852 25.852 25.852h255.664c14.254 0 25.852-11.594 25.852-25.852 0-14.254-11.598-25.851-25.852-25.851H355.91l-14.894-48.668h122.27c26.862 0 48.714-21.852 48.714-48.711V48.71C512 21.85 490.148 0 463.285 0zm-68.808 442.535c0 5.867-4.778 10.645-10.645 10.645H128.168c-5.871 0-10.645-4.778-10.645-10.645 0-5.87 4.774-10.644 10.645-10.644h255.664c5.867 0 10.645 4.773 10.645 10.644zm-54.473-25.855H171.996l14.89-48.664h138.227zm0 0" />
		<path d="M463.844 247.531a7.604 7.604 0 007.605-7.605V48.715c0-4.504-3.66-8.164-8.164-8.164H48.707c-4.492 0-8.148 3.66-8.148 8.164v247.828a7.6 7.6 0 007.601 7.602h415.684a7.601 7.601 0 007.605-7.602v-26.2c0-4.198-3.402-7.6-7.605-7.6a7.6 7.6 0 00-7.602 7.6v18.594H55.766V55.757h400.476v184.169a7.601 7.601 0 007.602 7.605zm0 0M262.32 322.895c-1.664-2.532-4.843-3.829-7.808-3.235-3.242.649-5.762 3.422-6.075 6.719-.656 6.875 7.844 11.012 12.833 6.223 2.632-2.532 3.054-6.668 1.05-9.707zm0 0" />
	</g>
);

const live = {
	path,
	viewBox,
};

export default live;
