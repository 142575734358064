import React, { useEffect } from 'react';
import Text from 'antd/lib/typography/Text';
import { Button, Divider, Form, Input, Space, Switch, Tag, Tooltip } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';

import { WeekPeriodPicker } from '~/components/shared/calendars/WeekPeriodPicker/WeekPeriodPicker';
import { getDaysAndTimesOverlappingIndexes } from '~/utils/dates';
import { useLocale } from '~/screens/_shared/AppLocale';
import { weeklyCalendarColors } from '~/components/shared/calendars/WeeklyCalendar/WeeklyCalendar.styles';

const WEEK_PERIODS_LIMIT = 5;
export const nameField = 'name';
export const allowAccessOnHolidaysField = 'allowAccessOnHolidays';
export const weekPeriodsField = 'weekPeriods';

export const AccessScheduleForm = ({ initialValues, form }) => {
	const { translate } = useLocale();
	const { [weekPeriodsField]: weekPeriods } = useWatch([], form) || {};

	useEffect(() => {
		// https://4x.ant.design/components/form/ - Note that initialValues cannot be updated by setState dynamically, you should use setFieldsValue in that situation.
		if (form && initialValues) {
			form.setFieldsValue(initialValues);
		}
	}, [form, initialValues]);

	const requiredWeekPeriodValidator = async (rule, value) => {
		const isPeriodInfoMissing = value?.days?.length === 0 || !Boolean(value?.times?.[0]) || !Boolean(value?.times?.[1]);

		return isPeriodInfoMissing
			? Promise.reject(
					weekPeriods?.length > 1
						? translate.byKey('period_info_missing_message')
						: translate.byKeyFormatted('field_is_required_message', { fieldName: translate.byKey('period') })
			  )
			: Promise.resolve();
	};

	const overlapWeekPeriodsValidator = async ({ field }) => {
		const weekPeriodsOverlappingIndexes = getDaysAndTimesOverlappingIndexes(form.getFieldValue(weekPeriodsField));
		const fieldIndex = Number.parseInt(field.split('.')[1]);

		return weekPeriodsOverlappingIndexes.includes(fieldIndex)
			? Promise.reject(translate.byKey('period_overlap_message'))
			: Promise.resolve();
	};

	const getWeekPeriodPickerKey = (fieldKey) => {
		const { days, times } = form.getFieldValue([weekPeriodsField, fieldKey]) || { days: [], times: [] };

		return `${days.join('-')}-${times.join('-')} `;
	};

	return (
		<Form
			form={form}
			name="create-access-schedule-form"
			layout="vertical"
			initialValues={initialValues}
			autoComplete="off"
		>
			<Space size={80}>
				<Form.Item
					name={nameField}
					label={translate.byKey('name')}
					rules={[
						{
							required: true,
							whitespace: true,
							message: translate.byKeyFormatted('field_is_required_message', {
								fieldName: translate.byKey('name'),
							}),
						},
					]}
				>
					<Input maxLength={50} placeholder={translate.byKey('name')} style={{ width: '250px' }} />
				</Form.Item>

				<Form.Item
					name={allowAccessOnHolidaysField}
					valuePropName="checked"
					label={translate.byKey('allow_access_on_holidays')}
				>
					<Switch />
				</Form.Item>
			</Space>

			<Form.List name={weekPeriodsField}>
				{(fields, { add, remove }) => (
					<>
						<div style={{ marginTop: '16px', marginBottom: '8px' }}>
							<Text>* {translate.byKey('periods')}</Text>
						</div>

						{fields.map(({ key, name, ...rest }, index) => {
							return (
								<Space key={rest?.fieldKey} align="baseline">
									<Form.Item
										{...rest}
										name={name}
										dependencies={fields.map((field) => [weekPeriodsField, field.name])}
										rules={[
											{
												validator: overlapWeekPeriodsValidator,
											},
											{
												validator: requiredWeekPeriodValidator,
												validateTrigger: 'onSubmit',
											},
										]}
									>
										<WeekPeriodPicker
											key={getWeekPeriodPickerKey(rest?.fieldKey)}
											prefix={
												<Tag css={prefixStyles} color={weeklyCalendarColors[index]}>
													{index + 1}
												</Tag>
											}
										/>
									</Form.Item>

									{fields.length > 1 ? (
										<Tooltip title={translate.byKey('remove')}>
											<MinusCircleOutlined
												style={{
													marginLeft: '16px',
													fontSize: '20px',
													display: 'block',
													position: 'relative',
													top: '5px',
												}}
												onClick={() => {
													remove(name);

													setTimeout(() => {
														form.validateFields(
															form.getFieldValue(weekPeriodsField).map((_, index) => [weekPeriodsField, index])
														);
													}, 1);
												}}
											/>
										</Tooltip>
									) : null}
								</Space>
							);
						})}

						{fields.length > 0 && fields.length < WEEK_PERIODS_LIMIT ? (
							<Divider style={{ margin: '0 0 16px 0' }} />
						) : null}

						{fields.length < WEEK_PERIODS_LIMIT ? (
							<div>
								<Button size="small" type="text" onClick={() => add()} icon={<PlusOutlined />}>
									{translate.byKey('add_another_period')}
								</Button>
							</div>
						) : null}
					</>
				)}
			</Form.List>
		</Form>
	);
};

const prefixStyles = css({
	color: 'black',
	marginRight: '16px',
	opacity: 0.7,
	border: '1px solid #999',
});
