import { useRef } from 'react';
import useSWR from 'swr';
import { omitBy } from 'lodash/object';

import { ApiRequester } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import handleDefaultApiError from '~/screens/_shared/useApi/ApiErrorHandler';

const defaultConfig = {
	revalidateOnFocus: false,
};

const separator = '**';

// filter values are strings on purpose since key contains 'undefined' and not undefined
const isExcludedValue = (value) => value === '' || value === 'undefined' || value === 'null';

export const useAccessSchedules = (key, config) => {
	const ref = useRef(
		new ApiRequester(
			{
				entity: '/customer/:customerId:/system/:systemId:/access-schedule',
				method: method.list,
			},
			handleDefaultApiError
		)
	);
	const fetcher = async (key) => {
		const [, , pageSize, pageNumber, name, sortBy, sortDirection] = key.split(separator);

		return ref.current.fetchData({
			params: omitBy(
				{
					'page-size': parseInt(pageSize),
					page: parseInt(pageNumber),
					name,
					'sort-by': sortBy,
					'sort-order': sortDirection,
				},
				isExcludedValue
			),
		});
	};

	return useSWR(key, fetcher, { ...defaultConfig, ...config });
};

export const getAccessSchedulesKey = ({ customerId, systemId, pageSize, pageNumber, name, sortBy, sortDirection }) =>
	customerId && systemId
		? `${customerId}${separator}\
${systemId}${separator}\
${pageSize}${separator}\
${pageNumber}${separator}\
${name}${separator}\
${sortBy}${separator}\
${sortDirection}${separator}\
accessSchedules`
		: null;
