import { getCacheKey } from '~/screens/_shared/useApi/ApiRequester';
import { method } from '~/screens/_shared/useApi/apiConstants';
import mappers from '~/screens/_shared/mappers';

export const doorConstants = {
	SUFFIX_AUTHENTICATION_PROFILE: 'Authentication Profile',
	NAME_SCHEDULE: 'Door Schedule',
	DESC_SCHEDULE: 'Test schedule',
	SUFFIX_CALENDAR: 'Door Calendar',
	SUFFIX_INNER_ASSET: 'Inner Zone',
	SUFFIX_OUTER_ASSET: 'Outer Zone',
};

export const getInnerAssetName = (portalName) => {
	return `${portalName} ${doorConstants.SUFFIX_INNER_ASSET}`;
};

export const getCalendarName = (portalName) => {
	return `${portalName} ${doorConstants.SUFFIX_CALENDAR}`;
};

export const getCalendarDesc = (portalName) => {
	return `${portalName} ${doorConstants.SUFFIX_CALENDAR}`;
};

export const getScheduleName = () => {
	return doorConstants.NAME_SCHEDULE;
};

export const getScheduleDesc = () => {
	return doorConstants.DESC_SCHEDULE;
};

export const getAuthenticationProfileName = (portalName) => {
	return `${portalName} ${doorConstants.SUFFIX_AUTHENTICATION_PROFILE}`;
};

export const getAuthenticationProfileDesc = (portalName) => {
	return `${portalName} ${doorConstants.SUFFIX_AUTHENTICATION_PROFILE}`;
};

export const getAccessPointName = (portalName) => {
	return `${portalName}`;
};

export const getPortalName = (portalName) => {
	return `${portalName}`;
};

export const cacheKeys = {
	portalIndexKey: () => getCacheKey(method.list, mappers.portal, { params: { 'detail-level': 'FULL' } }),
	peripheralDeviceIndexKey: () =>
		getCacheKey(method.list, mappers.peripheralDevice, {
			params: {
				assignedToPortal: 'true',
				'detail-level': 'FULL',
			},
		}),
	calendarItemKey: (calendarId) => getCacheKey(method.get, mappers.calendar, { calendarId }),
	scheduleItemKey: (calendarId, scheduleId) => getCacheKey(method.get, mappers.schedule, { calendarId, scheduleId }),
};
