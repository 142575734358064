import React, { useState } from 'react';
import { Modal, message } from 'antd';

import { AddDoorGroupModalFooter } from '~/components/features/door-groups/modals/add-door-group/AddDoorGroupModalFooter/AddDoorGroupModalFooter';
import { useLocale } from '~/screens/_shared/AppLocale';
import { DoorGroupForm } from '~/components/features/door-groups/DoorGroupForm/DoorGroupForm';
import { getCreateAssetKey, useCreateAsset } from '~/hooks/data/assets/useCreateAsset';
import { getAssetsKey, useAssets } from '~/hooks/data/assets/useAssets';
import { useQueryParams } from '~/hooks/utils/useQueryParams';
import { useProcessDoors } from '~/components/features/door-groups/hooks/useProcessDoors';
import { AssetTypes } from '~/constants/AssetTypes';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';

export const AddDoorGroupModal = ({ onClose }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const {
		queryParams: { pageSize, pageNumber, sortBy, sortDirection, doorGroup, description },
	} = useQueryParams();
	const { trigger: createAsset, isMutating: isCreatingAsset } = useCreateAsset(
		getCreateAssetKey({
			customerId,
			systemId,
			siteId,
		})
	);
	const { mutate: reloadAssets } = useAssets(
		getAssetsKey({
			customerId,
			systemId,
			siteId,
			pageSize,
			pageNumber,
			sortBy,
			sortDirection,
			assetType: AssetTypes.BASIC_ASSET_GROUP,
			doorGroup,
			description,
		}),
		{
			revalidateOnMount: false,
		}
	);
	const { processDoors, isProcessingDoors, reloadDoorsAssets, reloadPortals } = useProcessDoors();

	const [isFormValid, setIsFormValid] = useState(false);
	const [isCreatingDoorGroupMessageVisible, setIsCreatingDoorGroupMessageVisible] = useState(false);

	const onFormChange = async (_, form) => {
		let isAnyFieldInvalid = false;

		await form.validateFields().catch(({ errorFields }) => {
			if (errorFields.length) {
				isAnyFieldInvalid = true;
			}
		});

		setIsFormValid(!isAnyFieldInvalid && !form.getFieldError('doors').length);
	};

	const onFormSubmit = async ({ name, description, doors }) => {
		setIsCreatingDoorGroupMessageVisible(true);

		try {
			const createdAsset = await createAsset({
				name: name.trim(),
				description: description?.trim(),
				doors,
				type: AssetTypes.BASIC_ASSET_GROUP,
				ignoreGlobalHandlers: true,
			});

			if (doors?.length > 0) {
				await processDoors(doors, createdAsset.assetId);
			}

			reloadDoorsAssets();
			reloadAssets();
			reloadPortals();
			onClose();
			message.success(translate.byKey('door_group_created_successfully'));
		} catch (error) {
			message.error(translate.byKey('an_error_occurred_on_the_server_when_attempting_to_make_a_request'));
		} finally {
			setIsCreatingDoorGroupMessageVisible(false);
		}
	};

	return (
		<Modal
			open
			title={translate.byKey('new_door_group_title')}
			onCancel={onClose}
			maskClosable={false}
			width={600}
			footer={
				<AddDoorGroupModalFooter
					isSubmitDisabled={isCreatingAsset || isProcessingDoors || !isFormValid}
					isSubmitting={isCreatingAsset || isProcessingDoors}
					onClose={onClose}
				/>
			}
		>
			<DoorGroupForm
				message={isCreatingDoorGroupMessageVisible ? translate.byKey('creating_door_group') : null}
				isProcessingDoors={isCreatingAsset || isProcessingDoors}
				onFormChange={onFormChange}
				onFormSubmit={onFormSubmit}
			/>
		</Modal>
	);
};
