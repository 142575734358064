import { useTheme } from 'emotion-theming';
import capitalize from 'lodash/capitalize';
import React, { useCallback, useContext } from 'react';
import { useDrag } from 'react-dnd';
import { List } from 'react-virtualized';

import { Box, Flex, Text } from '~/components';

import ItemTypes from './ItemTypes';
import { SchedulePickerContext } from '.';
import { css } from '@emotion/core';
import { useLocale } from '~/screens/_shared/AppLocale';

const DayColumn = ({ day, slot, index }) => {
	const theme = useTheme();
	const { translate } = useLocale();
	const { rowCount, rowHeight, colWidth, dayHeaderHeight } = useContext(SchedulePickerContext);

	const [{ isDragging }, drag, preview] = useDrag({
		item: { type: ItemTypes.DayColumn, slotId: slot.id, day, dayIndex: index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;
	const width = isDragging ? 0 : colWidth;

	const renderRow = useCallback(({ key, style }) => {
		return (
			<Flex center key={key} style={style} borderTop={`1px solid ${theme.colors.gray[100]}`}>
				{null}
			</Flex>
		);
	}, []);

	return (
		<Box
			ref={preview}
			opacity={opacity}
			border="1px solid"
			borderRight="none"
			css={css`
				&:last-of-type {
					border-right: 1px solid ${theme.colors.gray[200]};
				}
			`}
			borderColor={theme.colors.gray[200]}
			background={theme.colors.white}
			width={width}
			zIndex={10}
		>
			<Box ref={drag}>
				<Flex
					center
					height={`${dayHeaderHeight}px`}
					background={theme.colors.gray[50]}
					style={{
						cursor: 'move',
					}}
				>
					<Text color="gray.400" fontSize={12}>
						{day.label || translate.byValue(capitalize(day.type))}
					</Text>
				</Flex>
				<List
					height={rowCount * rowHeight}
					width={colWidth}
					rowCount={rowCount}
					rowHeight={rowHeight}
					rowRenderer={renderRow}
				/>
			</Box>
		</Box>
	);
};

export default DayColumn;
