import { useEffect } from 'react';
import { useCurrentSystemSite } from '~/components/features/site-selection/hooks/useCurrentSystemSite';
import { useCalendars } from '~/hooks/features/calendars/useCalendars';
import { DEFAULT_CALENDAR_NAME } from '~/constants/Calendars';
import { getPostCalendarKey, usePostCalendar } from '~/hooks/data/calendars/usePostCalendar';
import { useSecurityLevels } from '~/screens/Doors/hooks/useSecurityLevels';
import { useCustomers } from '~/components/features/site-selection/hooks/useCustomers';
import { useTreeSelectorData } from '~/components/features/site-selection/hooks/useTreeSelectorData';
import { logout } from '~/components/features/auth/hooks/useUserAuthData';
import { Modal } from 'antd';
import { useLocale } from '~/screens/_shared/AppLocale';

export const usePopulateDatabase = () => {
	const {
		data: {
			system: { customerId, systemId },
		},
	} = useCurrentSystemSite();
	const { data: calendars, defaultCalendarId, mutate: mutateCalendars } = useCalendars();

	const { trigger: createCalendar } = usePostCalendar(
		getPostCalendarKey({
			customerId,
			systemId,
		})
	);

	const { data: securityLevels, mutate: mutateSecurityLevels } = useSecurityLevels();

	const createDefaultCalendar = async () => {
		await createCalendar({
			name: DEFAULT_CALENDAR_NAME,
			description: 'Default Calendar',
			dayTypes: {
				PublicHoliday: {
					description: 'A Holiday',
					sortOrder: 1,
				},
			},
			standardWeek: {
				MONDAY: 'PublicHoliday',
				TUESDAY: 'PublicHoliday',
				WEDNESDAY: 'PublicHoliday',
				THURSDAY: 'PublicHoliday',
				FRIDAY: 'PublicHoliday',
				SATURDAY: 'PublicHoliday',
				SUNDAY: 'PublicHoliday',
			},
		});

		mutateCalendars();
	};

	useEffect(() => {
		if (calendars && !defaultCalendarId) {
			createDefaultCalendar();
		}
	}, [calendars, defaultCalendarId]);

	useEffect(() => {
		if (systemId && !securityLevels.length) {
			mutateSecurityLevels();
		}
	}, [systemId, securityLevels]);
};

export const useMainLayoutData = () => {
	const { translate } = useLocale();

	const { data: customers, mutate: mutateCustomers } = useCustomers();
	const { data: treeSelectorData, mutate: mutateTreeSelectorData } = useTreeSelectorData();
	const {
		data: { system, site },
	} = useCurrentSystemSite();

	const isSiteInitiallySet = typeof site?.siteId === 'string';
	const isSiteSet = Boolean(site?.siteId?.length);
	const isSystemSet = Boolean(system?.systemId?.length);

	useEffect(() => {
		if (!customers.length) {
			mutateCustomers();
		}
		if (customers.length && !treeSelectorData.length) {
			mutateTreeSelectorData();
		}
	}, [customers, treeSelectorData]);

	useEffect(() => {
		if (treeSelectorData.length) {
			const treeHasSystem = treeSelectorData.some((customer) => customer.children?.length);

			if (!treeHasSystem) {
				logout();
				Modal.error({
					title: translate.byKey('account_not_setup_title'),
					content: translate.byKey('account_not_setup_message'),
				});
			}
		}
	}, [treeSelectorData]);

	return { isSiteInitiallySet, isSiteSet, isSystemSet };
};
