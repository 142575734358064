import React from 'react';
import { useTheme } from 'emotion-theming';
import { BackgroundImage, Flex, Image } from '~/components';

import { LoginForm } from '~/components/features/auth/LoginForm/LoginForm';

export const AuthPage = () => {
	const theme = useTheme();

	return (
		<Flex grow>
			<Flex center width="445px" p={'40px'} backgroundColor={theme.colors.authBkg} zIndex={4}>
				<Flex grow flexDirection="column" px="20px">
					<Flex grow center mb={8} position={theme.brand.auth.additionalLogo.parent.position}>
						<Image
							position={theme.brand.auth.additionalLogo.position}
							width={theme.brand.auth.additionalLogo.width}
							height={theme.brand.auth.additionalLogo.height}
							top={theme.brand.auth.additionalLogo.top}
							themeImage={'additionalLogo'}
						/>
					</Flex>
					<Flex grow center mb={12}>
						<Image width={theme.brand.auth.logo.width} height={theme.brand.auth.logo.height} themeImage={'logo'} />
					</Flex>
					<LoginForm />
				</Flex>
			</Flex>
			<Flex grow>
				<BackgroundImage
					width={theme.brand.auth.backgroundImg.width}
					height={theme.brand.auth.backgroundImg.height}
					position={theme.brand.auth.backgroundImg.position}
					backgroundPosition={theme.brand.auth.backgroundImg.backgroundPosition}
					backgroundSize={theme.brand.auth.backgroundImg.backgroundSize}
					top={theme.brand.auth.backgroundImg.top}
					left={theme.brand.auth.backgroundImg.left}
					themeImage={'authBackground'}
				/>
			</Flex>
		</Flex>
	);
};
