import React, { useContext, useCallback, useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

import { Flex, Box, Text } from '~/components';
import { useDrop } from 'react-dnd';

import ItemTypes from './ItemTypes';
import DayColumn from './DayColumn';
import { useTheme } from 'emotion-theming';
import { SchedulePickerContext } from '.';
import { css } from '@emotion/core';
import { SelectableGroup, createSelectable } from 'react-selectable';
import { timesWithHalfHour } from './labels';
import SelectionBox from './SelectionBox';
import { securityLevels } from '../entityConstants';
import { mapSlotsToValueArray, isInOccupiedSpace } from './helpers';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { hasActionPermissions } from '~/screens/_shared/getUserRoles';
import { translator } from '~/screens/_shared/AppLocale';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';

const SelectableComponent = createSelectable(({ key, height, width, selected }) => {
	return (
		<Flex
			center
			key={key}
			height={`${height}px`}
			width={`${width}px`}
			backgroundColor={selected ? 'blue.400' : 'transparent'}
			opacity={0.1}
		>
			{null}
		</Flex>
	);
});

const TimeSlot = ({ slot, dispatch, isActiveTab }) => {
	const theme = useTheme();
	const { data: user } = useUserAuthData();

	const maxNumberOfIntervals = 10;

	const { timeSlotHeaderHeight, height, timeSlotSpacing, rowHeight, colWidth, dayHeaderHeight, state } =
		useContext(SchedulePickerContext);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [currentSlot, setCurrentSlot] = useState({
		name: '',
		id: 0,
		modeTimeIntervals: [],
		days: [],
	});
	const [occupiedBlocks, setOccupiedBlocks] = useState(new Array(timesWithHalfHour.length).fill(0));
	const [enableOfficeModeWarning, setEnableOfficeModeWarning] = useState(() =>
		localStorage.getItem('disableOfficeModeWarning') === 'true' ? false : true
	);

	function getColor(index) {
		const colors = [theme.colors.green, theme.colors.blue, theme.colors.orange, theme.colors.purple];
		return colors[index] || theme.colors.purple;
	}

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ItemTypes.DayColumn,
		drop: ({ slotId, day, dayIndex }) => {
			if (slotId === currentSlot.id) {
				return;
			}
			dispatch({
				type: 'MOVE_DAY',
				payload: {
					from: { slotId, day, dayIndex },
					to: { slotId: currentSlot.id },
				},
			});
		},
		canDrop: ({ slotId }) => {
			return (
				slotId !== currentSlot.id &&
				hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.WRITE) === true
			);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	const onLabelChange = (e) => {
		if (hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.WRITE) === true) {
			const text = e.target.value;
			dispatch({
				type: 'UPDATE_TIME_SLOT_NAME',
				payload: {
					slotId: currentSlot.id,
					label: text,
				},
			});
		}
	};

	const isActive = canDrop && isOver;

	const handleSelection = useCallback(
		(newSelectedKeys) => {
			if (currentSlot.modeTimeIntervals.length < maxNumberOfIntervals) {
				setSelectedKeys(newSelectedKeys);
			}
		},
		[currentSlot.modeTimeIntervals, occupiedBlocks]
	);

	const handleSelectionEnd = useCallback(
		(items) => {
			const firstIndex = timesWithHalfHour.indexOf(items[0]);
			const lastIndex = timesWithHalfHour.indexOf(items[items.length - 1]);

			let endTime = timesWithHalfHour[lastIndex + 1];

			if (!endTime) {
				endTime = '24:00';
			}

			if (
				currentSlot.modeTimeIntervals.length < maxNumberOfIntervals &&
				!isInOccupiedSpace(firstIndex, lastIndex, occupiedBlocks) &&
				hasActionPermissions(user, userConstants.screens.DOOR_MODES, userConstants.actions.CREATE) === true
			) {
				dispatch({
					type: 'ADD_NEW_INTERVAL_TO_SLOT',
					payload: {
						slotId: currentSlot.id,
						interval: {
							startTime: timesWithHalfHour[firstIndex],
							endTime: endTime,
							state: securityLevels.CARD_AND_PIN,
						},
					},
				});
			}

			setSelectedKeys([]);
		},
		[timesWithHalfHour, currentSlot.modeTimeIntervals, occupiedBlocks]
	);

	useEffect(() => {
		setOccupiedBlocks(mapSlotsToValueArray(currentSlot.modeTimeIntervals));
		setCurrentSlot(state.timeSlots.find((o) => o && o.id === slot.id) || {});
	}, [state, currentSlot, setCurrentSlot]);

	const slotName = translator.byKey(slot.name.toLowerCase());

	return (
		<Flex
			data-test="time-selector-time-slot"
			css={css`
				transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
				position: relative;
				&:last-of-type {
					margin-left: ${timeSlotSpacing}px;
				}
				&:first-of-type {
					margin-right: ${timeSlotSpacing}px;
				}
			`}
			flexDirection={'column'}
			height={height}
			borderColor={isActive ? theme.colors.green[200] : theme.colors.gray[200]}
			background={getColor(slot.id)[50]}
			zIndex={18}
			ref={drop}
		>
			<Flex center height={`${timeSlotHeaderHeight}px`} borderTop={`4px solid ${getColor(slot.id)[400]}`}>
				<Text color="gray.400" fontSize={12} px="4px">
					<ContentEditable
						data-test="time-selector-time-slot-name"
						html={slotName}
						disabled={false}
						onChange={onLabelChange}
					/>
				</Text>
			</Flex>
			<Flex
				data-test="time-selector-time-slot-selection-bounds"
				position="absolute"
				top={dayHeaderHeight + timeSlotHeaderHeight}
				bottom={0}
				left={0}
				right={0}
				zIndex="100"
				className={`time-selection-bounds-${currentSlot.name.split(' ').join('-')}`}
			>
				{currentSlot.modeTimeIntervals &&
					currentSlot.modeTimeIntervals.map((item, index) => {
						return (
							<SelectionBox
								key={index + currentSlot.name}
								rowHeight={rowHeight}
								width={colWidth * currentSlot.days.length}
								interval={item}
								slotName={currentSlot.name}
								slotId={currentSlot.id}
								index={index}
								enableOfficeModeWarning={enableOfficeModeWarning}
								setEnableOfficeModeWarning={setEnableOfficeModeWarning}
								isActiveTab={isActiveTab}
							/>
						);
					})}

				<SelectableGroup
					onSelection={handleSelection}
					onEndSelection={handleSelectionEnd}
					css={css`
						display: flex;
						flex-direction: column;
						flex: 1;
					`}
				>
					{timesWithHalfHour.map((item, index) => {
						const selected = selectedKeys.indexOf(item) > -1;
						return (
							<SelectableComponent
								key={item}
								index={index}
								selected={selected}
								selectableKey={item}
								height={rowHeight}
								width={colWidth * currentSlot.days.length}
							/>
						);
					})}
				</SelectableGroup>
			</Flex>
			<Flex>
				{currentSlot.days.map((day, index) => {
					return <DayColumn key={day.id} index={index} day={day} slot={currentSlot} dispatch={dispatch} />;
				})}
			</Flex>

			{isActive && (
				<Box
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						opacity: isActive ? 0.4 : 0,
						background: getColor(currentSlot.id)[50],
						zIndex: 50,
					}}
				/>
			)}
		</Flex>
	);
};

export default TimeSlot;
