import React from 'react';
import { Dropdown } from 'antd';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { hasActionPermissions } from '~/screens/_shared/userRoleConstants';
import * as userConstants from '~/screens/_shared/userRoleConstants';
import { Button, PageHeader } from '~/components';
import { useLocale } from '~/screens/_shared/AppLocale';
import { useSiteControllers } from '~/hooks/data/controllers/useSiteControllers';
import { getUsbDevicesKey } from '~/components/features/usb-devices/UsbDevicesTable/UsbDevicesTable.utils';
import { ControllerTypes } from '~/constants/ControllerTypes';
import { hasOfflineFunctionality } from '~/screens/_shared/entitlement';
import { useUserAuthData } from '~/components/features/auth/hooks/useUserAuthData';
import { useCurrentSystemSite } from '../../site-selection/hooks/useCurrentSystemSite';

export const UsbDevicesHeader = ({ onAddNewButtonClick }) => {
	const { translate } = useLocale();
	const {
		data: {
			system: { customerId, systemId },
			site: { siteId },
		},
	} = useCurrentSystemSite();
	const { data: user } = useUserAuthData();
	const { isLoading, isValidating, mutate } = useSiteControllers(
		getUsbDevicesKey({
			customerId,
			systemId,
			siteId,
		})
	);

	return (
		<PageHeader title={translate.byKey('usb_devices')} icon="controller" showHelp={false}>
			<Button disabled={isLoading || isValidating} type="primary" icon={<ReloadOutlined />} onClick={() => mutate()}>
				{translate.byKey('refresh')}
			</Button>

			{hasActionPermissions(user, userConstants.screens.USB_DEVICES, userConstants.actions.CREATE) ? (
				<Dropdown
					menu={{
						onClick: ({ key }) => onAddNewButtonClick(key),
						items: [
							{
								key: ControllerTypes.DESKTOP_UPDATER,
								label: translate.byKey('desktop_updater'),
							},
							...(hasOfflineFunctionality()
								? [
										{
											key: ControllerTypes.CLOUD_UPDATER,
											label: translate.byKey('idc_updater'),
										},
								  ]
								: []),
						],
					}}
				>
					<Button type="primary" icon={<PlusCircleOutlined />}>
						{translate.byKey('add_new')}
					</Button>
				</Dropdown>
			) : null}
		</PageHeader>
	);
};
