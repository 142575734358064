export const FeatureTypes = {
	HID_ONLINE: 'hid_online',
	APERIO_ONLINE: 'aperio_online',
	APERIO_OFFLINE: 'aperio_offline',
	PULSE_OFFLINE: 'pulse_offline',
	USB_DEVICE: 'usb_device',
	IMPROX: 'improx',
	PASSTHROUGH: 'passthrough',
	CREDENTIAL_HOLDER: 'credential_holder',
	ACCESS_GROUP: 'access_group',
};
