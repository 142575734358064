import { useMemo } from 'react';

import { useLocale } from '~/screens/_shared/AppLocale';
import { formatEventSource } from '~/components/features/live/LiveEventsTable/LiveEventsTable.utils';
import { useLiveEvents } from '~/components/features/live/LiveEvents.hooks';

export const useLiveEventsFilters = () => {
	const { translate } = useLocale();
	const { data: liveEvents } = useLiveEvents();

	const filters = useMemo(() => {
		const eventName = [...new Set(liveEvents.map(({ eventName }) => eventName))].map((eventName) => ({
			label: translate.byKey(eventName.toLocaleLowerCase()),
			value: eventName,
		}));
		const eventType = [...new Set(liveEvents.map(({ reasonCode }) => reasonCode))]
			.filter((eventType) => Boolean(eventType))
			.map((eventType) => ({
				label: translate.byKey(eventType.toLocaleLowerCase()),
				value: eventType,
			}));
		const site = [...new Set(liveEvents.map(({ siteName }) => siteName))].map((siteName) => ({
			label: siteName,
			value: siteName,
		}));
		const controllers = [...new Set(liveEvents.map(({ controllerName }) => controllerName))].map((controllerName) => ({
			label: controllerName,
			value: controllerName,
		}));
		const eventSources = [...new Set(liveEvents.map((event) => formatEventSource(event)))].map((eventSource) => ({
			label: eventSource,
			value: eventSource,
		}));

		return {
			eventName,
			eventType,
			site,
			controllers,
			eventSources,
		};
	}, [liveEvents]);

	return {
		filters,
	};
};
