export const credentialHolder = {
	paginationType: 'links',
	base: {
		description: true,
		name: (data) => {
			const { firstName, lastName, name } = data;

			if (name) {
				return name;
			}

			return `${firstName} ${lastName}`;
		},
		user: (data, extra = null) => {
			if (data.userId) {
				extra = extra || {};
				return {
					key: {
						organizationId: `${extra.system}-org`,
						userId: data.userId,
					},
				};
			}

			if (data.user) {
				return {
					key: {
						organizationId: data.user.organizationId,
						userId: data.user.userId,
					},
				};
			}

			return null;
		},
		validity: (data) => {
			if (data.validity) {
				return data.validity;
			}

			return { startDateTime: data.startDateTime, endDateTime: data.endDateTime };
		},
		accessProfiles: (data, { system }) => {
			if (data.groups) {
				return data.groups.map((group) => {
					const { accessProfileId } = group;
					return {
						key: {
							systemId: system,
							accessProfileId,
						},
					};
				});
			}

			const profiles = data.accessProfiles || [];

			return profiles.map((profile) => {
				return {
					key: {
						systemId: profile.systemId,
						accessProfileId: profile.accessProfileId,
					},
				};
			});
		},
		exemptions: {
			antipassbackViolated: 'true',
			maxOccupancy: 'false',
			minOccupancy: 'false',
			dualCredentialAccess: 'true',
			blockedPortal: 'false',
		},
		extendedGrantTime: 'false',
	},
	update: {
		version: true,
	},
	params: {},
	baseUrlPath: ['customer', ':customerId:', 'system', ':systemId:', 'credential-holder'],
};
